<template>
    <div class="diabetesbehandling-lista">
        <admin-lock-border />
        <div class="header">
            <h1>Diabetesbehandling</h1>
            <CustomButton type="button" variant="hollow" @click="openCreateDiabetesbehandling">
                <img src="@/assets/plus_circle_blue.svg" />
                <span>Skapa ny diabetesbehandling</span>
            </CustomButton>
        </div>

        <kliniken-table
            v-if="diabetesbehandlingList.results"
            :items="diabetesbehandlingList.results"
            paginationStyle="paging"
            :columns="columns"
            :count="count"
            :limit="limit"
            :currentPage="currentPage"
            @loadPage="loadPage"
            @changeLimit="changeLimit"
            @loadMore="loadMore"
            :showBottom="true"
            :showLimitDropdown="true"
            :has-dropdown="true"
            :dropdown-actions="dropdownActions"
            @editDiabetesbehandling="editDiabetesbehandlingFromTable"
        />
    </div>
</template>

<script>
    import tabIcon from "@/assets/blank.png";

    export default {
        name: "ListDiabetesbehandling",
        tabName: "Diabetesbehandling",
        icon: tabIcon,
    };
</script>

<script setup>
    import { onMounted, ref, onBeforeUnmount } from "vue";
    import emitter from "tiny-emitter/instance";
    import { openDialog } from "@/utils";
    import AdminLockBorder from "@/components/AdminLockBorder.vue";
    import { getConfig, klinikenApi } from "@/api";
    import KlinikenTable from "@/components/Table/KlinikenTable.vue";
    import CustomButton from "@/components/Button.vue";
    import Diabetesbehandling from "@/tabs/Admin/Diabetesbehandling.vue";
    import store from "@/store";

    const diabetesbehandlingList = ref([]);
    const count = ref(0);
    const columns = ref([
        {
            title: "Namn",
            name: "namn",
        },
        {
            title: "Ordningsnummer",
            name: "ordning",
        },
    ]);
    const dropdownActions = ref([
        {
            name: "editDiabetesbehandling",
            title: "Redigera diabetesbehandling",
            show: () => true,
        },
    ]);

    const limit = ref(20);
    const offset = ref(0);
    const fetchBatch = ref(1);
    const params = ref({});

    const currentPage = ref(1);
    const loadPage = async (page) => {
        currentPage.value = page;
        offset.value = (page - 1) * limit.value;
        await fetchdiabetesbehandlingList();
    };
    const loadMore = async () => {
        fetchBatch.value++;
        await this.fetchdiabetesbehandlingList();
    };

    const changeLimit = async (dropdownLimit) => {
        fetchBatch.value = 1;
        limit.value = dropdownLimit;
        await fetchdiabetesbehandlingList();
    };

    const fetchdiabetesbehandlingList = async () => {
        params.value = { ...params.value, limit: limit.value, offset: offset.value };
        if (limit.value > 0) {
            params.value["limit"] = limit.value * fetchBatch.value;
        }
        try {
            const response = await klinikenApi.get(
                "/operation/diabetesbehandlingsform/",
                getConfig({ params: { ...params.value } })
            );
            diabetesbehandlingList.value = response.data;
            count.value = response.data.count;
        } catch (error) {
            openDialog("Kunde inte hämta Diabetesbehandling.", "warning");
        }
    };
    const openCreateDiabetesbehandling = () => {
        store.dispatch("tabs/openTab", {
            component: Diabetesbehandling,
            parameters: { redigera: false },
        });
    };
    const editDiabetesbehandlingFromTable = (diabetesbehandling) => {
        store.dispatch("tabs/openTab", {
            component: Diabetesbehandling,
            parameters: {
                redigera: true,
                ...diabetesbehandling,
            },
        });
    };

    onMounted(() => {
        fetchdiabetesbehandlingList();
        emitter.on("Diabetesbehandling-table-uppdaterad", fetchdiabetesbehandlingList);
    });

    onBeforeUnmount(() => {
        emitter.off("Diabetesbehandling-table-uppdaterad", fetchdiabetesbehandlingList);
    });
</script>
<style lang="scss">
    .diabetesbehandling-lista {
        .header {
            h1 {
                margin: 0;
            }

            display: flex;
            gap: 20px;
            align-items: center;
        }
    }
</style>
