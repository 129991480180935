<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import HollowButton from "@/components/widgets/HollowButton.vue";
import PlusCircleBlue from "@/assets/plus_circle_blue.svg";
import { mapActions, mapGetters } from "vuex";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import FormAktivitetstyp from "@/tabs/Skreg/Aktivitetstyper/FormAktivitetstyp.vue";
import tabIcon from "@/assets/narvarostatistik.svg";

const noNull = (str) => {
    return str || "";
};

const TABLE_COLUMNS = [
    {
        name: "namn",
        title: "Namn",
        template: (item) => {
            return noNull(item.namn);
        },
    },
    {
        name: "typ",
        title: "Typ",
        template: (item) => {
            return noNull(item.typ);
        },
    },
];

const TABLE_ACTIONS = [
    {
        name: "edit",
        title: "Redigera",
        img: "edit.svg",
        condition: () => {
            return true;
        },
    },
];

export default {
    extends: BaseTab,
    name: "ListAktivitetstyper",
    tabName: "Aktivitetstyper",
    icon: tabIcon,

    components: {
        HollowButton,
        CustomActionTable,
    },

    data() {
        return {
            PlusCircleBlue,
            TableColumns: TABLE_COLUMNS,
            TableActions: TABLE_ACTIONS,
        };
    },

    computed: {
        ...mapGetters("skreg/aktivitetstyper", ["aktivitetstyper"]),
    },

    methods: {
        ...mapActions("skreg/aktivitetstyper", ["load"]),
        async createAktivitetstyp() {
            await this.$store.dispatch("tabs/openTab", { component: FormAktivitetstyp });
        },
        async updateAktivitetstyp(aktivitetstyp) {
            await this.$store.dispatch("tabs/closeTab", FormAktivitetstyp);
            await this.$store.dispatch("tabs/openTab", {
                component: FormAktivitetstyp,
                parameters: { aktivitetstyp: aktivitetstyp },
            });
        },
    },
    async created() {
        await this.load();
        this.$on("edit", async (aktivitetstyp) => {
            await this.updateAktivitetstyp(aktivitetstyp);
        });
    },
};
</script>

<template>
    <div>
        <h2>
            Aktivitetstyper
            <hollow-button @click="createAktivitetstyp" :icon="PlusCircleBlue" label="Skapa ny aktivitetstyp" />
        </h2>
        <custom-action-table
            class="no-top"
            :selectableRows="false"
            :items="aktivitetstyper"
            :columns="TableColumns"
            :actions="TableActions"
        />
    </div>
</template>

<style lang="scss" scoped>
.hollow-button {
    margin-left: 50px;
}
</style>
