import Vue from "vue";

export default new Vue();

/**
 * Event bus for use for linked components (components dependant of each other's value)
 * The component should $emit component.name+"_changed" with the new value.
 * The component should listen to "update_"+component.name to update its values.
 * Logic for event handling is hardcoded in the parent at the moment.
 */
