<template>
    <textarea
        :ref="name"
        :name="name"
        @focus="hasFocus(true)"
        @blur="hasFocus(false)"
        @keydown="autoExpand()"
        @paste="autoExpand()"
        @change="parseContent()"
        :tabindex="tabindex"
        :required="required"
        :maxlength="maxlength"
        class="text-area"
        v-model="value_"
        :disabled="disabled"
    ></textarea>
</template>

<script>
import autosize from "autosize";
import TextAreaWidget from "@/components/widgets/TextAreaWidget.vue";
import { flashMessage } from "@/utils";
import linkEvents from "@/linkEvents";

export default {
    extends: TextAreaWidget,
    name: "ScalableTextArea",
    data: function () {
        return {
            focus: false,
            el: null,
        };
    },
    methods: {
        parseContent() {
            if (this.filter) {
                let lengthBefore = this.value_.length;
                this.value_ = this.value_.replace(this.filter, "");
                if (lengthBefore !== this.value_.length) flashMessage("Otillåtna tecken har tagits bort från texten.");
            }
            this.update();
        },
        update() {
            linkEvents.$emit(this.name + "_changed", this.value_);
        },
        hasFocus(focus) {
            if (focus)
                // first time input gets focus
                this.focus = true;
            if (!focus && this.focus)
                // every time input loses focus
                linkEvents.$emit(this.name + "_focus");
        },
        autoExpand() {
            this.el.style.display = "none";
            autosize(this.el);
            this.el.style.display = "block";
            autosize.update(this.el);
        },
        updateValue({ value }) {
            this.autoExpand();
            this.value_ = value;
            this.$nextTick(() => {
                autosize.update(this.el);
            });
        },
    },
    mounted() {
        this.el = this.$refs[this.name];
        this.autoExpand();
    },
    created() {
        linkEvents.$on("update_" + this.name, this.updateValue);
    },
    watch: {},
};
</script>
<style lang="sass" scoped>
textarea
    padding: 5px
    border-radius: 4px
    border: 1px solid #95A1B1
</style>
