<template>
    <div class="dialog-anmalan-info">
        <div class="datum">
            <strong>Datum</strong>
            {{ anmalaninfo.datum ?? "-" }}
        </div>
        <div class="tid">
            <strong>Tid</strong>
            {{ anmalaninfo.tid ?? "-" }}
        </div>

        <div class="huvudoperator">
            <strong>Operatör</strong>
            {{ anmalaninfo.huvudoperator }}
        </div>
        <div class="huvudoperationskod">
            <strong>Operationskod</strong>
            {{ anmalaninfo.huvudoperationskod }}
        </div>
    </div>
</template>

<script>
export default {
    name: "DialogAnmalaninfo",
    props: {
        anmalaninfo: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";
.dialog-anmalan-info {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);
    column-gap: 25px;
    row-gap: 15px;

    .datum {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    .tid {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    .huvudoperator {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
    }

    .huvudoperationskod {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
    }

    strong {
        display: block;
        font-weight: 500;
        line-height: 30px;
        color: $color-label-grey;
    }
}
</style>
