export class FullCalenderEvent {
    id = "";
    backgroundColor = "";
    textColor = "";
    start = null;
    end = null;
    title = "";
    startEditable = new Boolean();
    durationEditable = new Boolean();
    extendedProps = {
        preparationTime: NaN,
        decommissionTime: NaN,
    };
    resourceId = "";
    resourceEditable = new Boolean();
    constructor({
        id = "",
        backgroundColor = "",
        textColor = "",
        start = null,
        end = null,
        title = "",
        startEditable = true,
        durationEditable = true,
        extendedProps = {
            preparationTime: NaN,
            decommissionTime: NaN,
        },
        resourceId = "",
        resourceEditable = true,
    }) {
        this.id = id;
        this.backgroundColor = backgroundColor;
        this.textColor = textColor;
        this.start = start;
        this.end = end;
        this.title = title;
        this.startEditable = startEditable;
        this.durationEditable = durationEditable;
        this.extendedProps = extendedProps;
        this.resourceId = resourceId;
        this.resourceEditable = resourceEditable;

        this._toJSON();
    }

    // private

    _toJSON() {
        const {
            id,
            backgroundColor,
            textColor,
            start,
            end,
            title,
            startEditable,
            durationEditable,
            extendedProps,
            resourceId,
        } = this;
        return {
            id,
            backgroundColor,
            textColor,
            start,
            end,
            title,
            startEditable,
            durationEditable,
            extendedProps,
            resourceId,
        };
    }
}
