<template>
    <div class="select-action-table">
        <button v-if="collapseLabel" class="btn btn-default btn-block full-width" @click="collapse = !collapse">
            <div class="flex-group">
                <checkbox-widget
                    class="checkbox-widget"
                    v-if="actions || groupActions"
                    :disabled="!containsSelectable"
                    :name="'checkbox-' + id"
                    :value="allSelected"
                    :handler="toggleAll"
                    role="presentation"
                />
                <!-- note: collapseLabel needs to be either passed as a prop to checkbox-widget, or defined in a span role="presentation" -->
                <span role="presentation"> {{ collapseLabel }} </span>
            </div>
            <div class="flex-group">
                <span v-if="label" class="label-status" :style="'background-color: ' + label.colour">
                    <a v-if="label.tooltip" href="#" data-toggle="tooltip" :title="label.tooltip"> {{ label.text }}</a>
                    <template v-else>{{ label.text }}</template>
                </span>
                <actions-dropdown
                    v-if="labelActions"
                    :id="'dropdown-' + id"
                    class="message-dropdown"
                    :actions="labelActions"
                    :item="data"
                    :current-profile="currentProfile"
                    @dropdownAction="dropdownAction"
                ></actions-dropdown>
                <span class="caret" :class="rotateCaret"></span>
            </div>
        </button>
        <collapse-transition>
            <div v-if="collapse">
                <table class="table full-width">
                    <thead>
                        <tr>
                            <th
                                v-for="col in columns"
                                :key="col.name"
                                :class="{
                                    sortable: sortMethod && col.sortable,
                                    'is-sorted': orderBy === col.name,
                                    reversed: orderReversed,
                                }"
                                @click="setOrder(col)"
                            >
                                {{ col.title }}
                                <img v-if="sortMethod && col.sortable" src="@/assets/dropdown-arrow-blue.svg" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" :key="item.id">
                            <td v-for="(col, i) in columns" :key="col.name">
                                <checkbox-widget
                                    v-if="i === 0 && actions"
                                    :disabled="!disabledRowHandler(item)"
                                    :name="item.id"
                                    :labelHtml="col.renderer(item, Q)"
                                    :q="q"
                                    :value="isSelected(item)"
                                    :handler="() => toggleItem(item)"
                                />
                                <span v-if="col.renderer && !(i === 0 && actions)" v-html="col.renderer(item, Q)" />
                                <span v-if="!col.renderer && !(i === 0 && actions)">{{ item[col.name] }}</span>
                                <span class="missing" v-if="isRequiredField(col) && item[col.name] === ''">Saknas</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="btn-group action-btn" v-if="actions">
                    <Button
                        v-for="action in actions"
                        :key="action.name"
                        :disabled="!selected.length && !action.alwaysActive"
                        @click="onButtonClick(action.name, selected)"
                    >
                        <img v-if="action.img" :src="action.img" alt="" /> {{ action.title }}
                    </Button>
                </div>
            </div>
        </collapse-transition>
    </div>
</template>

<script>
import ActionTable from "@/components/generic/ActionTable.vue";
import CheckboxWidget from "@/components/widgets/CheckboxWidget.vue";
import Button from "@/components/Button.vue";

export default {
    extends: ActionTable,
    components: { CheckboxWidget, Button },
    data() {
        return {
            selected: [],
            collapse: false,
        };
    },
    props: {
        currentProfile: String,
    },
    computed: {
        rotateCaret() {
            return this.collapse ? "rotatingCaret rotateonedegree" : "rotatingCaret rotateoneeighty";
        },
        disabledRows() {
            if (this.groupActions) return [];
            else
                return this.items.filter((item) => {
                    return !this.disabledRowHandler(item);
                });
        },
        allSelected() {
            if (this.disabledRowHandler)
                return (
                    this.selected.length === this.items.length - this.disabledRows.length && this.selected.length !== 0
                );
            else return this.selected.length === this.items.length && this.selected.length !== 0;
        },
        containsSelectable() {
            if (this.disabledRowHandler) return this.items.length - this.disabledRows.length > 0;
            else return this.items.length > 0;
        },
        isIE: function () {
            var ua = navigator.userAgent;
            /* MSIE used to detect old browsers and Trident used to newer ones*/
            return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
        },
        Q() {
            if (this.q) {
                if (this.q.length > 2) return this.q.toLowerCase();
                else return "";
            } else return "";
        },
    },
    methods: {
        isSelected(item) {
            return this.selected.includes(item);
        },
        toggleItem(item) {
            if (this.selected.includes(item)) {
                this.selected.splice(this.selected.indexOf(item), 1);
            } else {
                this.selected.push(item);
            }
        },
        toggleAll() {
            const value = !this.allSelected;
            this.selected = this.items.filter((item) => {
                if (this.disabledRowHandler) return value && this.disabledRowHandler(item);
                else return value;
            });
        },
        onButtonClick(actionName, selected) {
            console.log(actionName, selected);
            this.$emit(actionName, selected);
            this.selected = [];
        },
        dropdownAction(actionName, item) {
            this.$emit(actionName, item);
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/style/variables";
@import "@/style/deprecated_main";

.full-width {
    width: 100% !important;
}

.select-action-table {
    .checkbox-widget {
        top: -6px;
    }

    .btn-block {
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #e4e8f0;

        .flex-group {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    table {
        background-color: #f3f5f9;
    }

    th {
        padding: 0.75rem;
        color: #277692;
        font-size: 14px;
        font-weight: 500;
    }

    td {
        padding: 0.75rem;
    }

    tr {
        border-top: 1px solid #dee2e6;
    }
}

::v-deep .label-status {
    min-width: 60px;
    text-align: center;
    font-weight: normal;
    border-radius: 2px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 10px;
    float: right;
    margin-right: 22px;
    color: #ffffff;
    border: none;
    max-height: 20px;
    line-height: 20px;

    a {
        font-size: 10px;
        color: #ffffff;
        text-decoration: none;
    }
}

.smsSent {
    font-size: 14px;
    font-weight: normal;
    margin-left: 50px;
}

.btn-primary {
    height: 60px;
    padding-left: 36px;
    padding-right: 36px;
    background-color: $primary !important;
    color: white;

    img {
        margin-right: 10px;
        margin-top: -2px;
    }
}

.btn-group {
    padding-left: 48px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.rotatingCaret {
    background-image: url("@/assets/dropdown-arrow.svg");
    background-size: 14px;
    width: 14px;
    height: 14px;
    display: inline-block;
    transform: rotate(180deg);
    transition: transform 0.2s;
}

.rotateonedegree {
    transform: rotate(180deg);
}

.rotateoneeighty {
    transform: rotate(0deg);
}
</style>
