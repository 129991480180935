<template>
    <ModalOverlay v-if="isVisible">
        <div class="modal-basic" :class="[variant]">
            <div class="head">
                <div class="icon">
                    <img :src="icon" />
                </div>
            </div>
            <div class="body">
                <p class="title">{{ title }}</p>
                <p class="message" v-if="message">{{ message }}</p>
                <ButtonGroup>
                    <Button v-if="cancelButton" variant="contour" size="secondary" @click="_cancel">
                        {{ cancelButton }}
                    </Button>
                    <Button v-if="okButton" variant="standard" size="primary" @click="_confirm">
                        {{ okButton }}
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    </ModalOverlay>
</template>

<script>
import ModalOverlay from "@/components/generic/ModalOverlay.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";
import InformationIcon from "@/assets/dialogs/information.svg";
import SuccessIcon from "@/assets/dialogs/success.svg";
import WarningIcon from "@/assets/dialogs/warning.svg";
import AlertIcon from "@/assets/dialogs/alert.svg";

const BasicModalVariant = Object.freeze({
    INFORMATION: "information",
    SUCCESS: "success",
    WARNING: "warning",
    ALERT: "alert",
});

export { BasicModalVariant };

export default {
    name: "ModalBasic",
    components: {
        ModalOverlay,
        ButtonGroup,
        Button,
        InformationIcon,
        SuccessIcon,
        WarningIcon,
        AlertIcon,
    },
    data() {
        return {
            isVisible: false,
            variant: BasicModalVariant.INFORMATION,
            title: undefined,
            message: undefined,
            okButton: undefined,
            cancelButton: undefined,
            resolvePromise: undefined,
            rejectPromise: undefined,
        };
    },
    computed: {
        icon() {
            switch (this.variant) {
                case BasicModalVariant.INFORMATION:
                    return InformationIcon;
                case BasicModalVariant.SUCCESS:
                    return SuccessIcon;
                case BasicModalVariant.WARNING:
                    return WarningIcon;
                case BasicModalVariant.ALERT:
                    return AlertIcon;
            }
        },
    },
    methods: {
        show: function (opts = {}) {
            this.variant = opts.variant ?? BasicModalVariant.INFORMATION;
            this.title = opts.title;
            this.message = opts.message;
            this.okButton = opts.okButton;
            this.cancelButton = opts.cancelButton;
            // Once we set our config, we tell the popup modal to open
            this.isVisible = true;
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },

        _confirm() {
            this.resolvePromise(true);
            this.isVisible = false;
        },

        _cancel() {
            this.resolvePromise(false);
            this.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
@import "@/style/variables";

.modal-basic {
    box-sizing: border-box;
    width: 585px;
    max-width: 585px;

    .head {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 141px;
        padding: 21px 28px;
        display: flex;

        .icon {
            border-radius: 50%;
            background-color: #ffffff;
            width: 100px;
            height: 100px;
            margin: auto;
            display: flex;
            align-items: center;

            img {
                margin: auto;
            }
        }
    }

    .body {
        margin: 33px 53px;
        font-weight: 400;

        .title {
            font-size: 35px;
            line-height: 47px;
        }

        .message {
            margin-top: 20px;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .button-group {
        margin-top: 34px;
        gap: 20px;
        justify-content: flex-end;
        align-items: flex-end;
    }

    &.information {
        .head {
            background-color: $color-dialog-information-background;
        }
    }

    &.success {
        .head {
            background-color: $color-dialog-success-background;
        }
    }

    &.warning {
        .head {
            background-color: $color-dialog-warning-background;
        }
    }

    &.alert {
        .head {
            background-color: $color-dialog-alert-background;
        }
    }
}
</style>
