import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

/**
 * method: the HTTP/axios method used in the request. Defaults to PUT since it's most common, but notably login is a POST.
 * showPopup: show bankid/siths popup. Should be false for login, since that handles its own login.
 */
const init = ({ apiUrl, params, config, responseHandler }) => {
    const defaultConfig = getConfig();
    return klinikenApi
        .post(apiUrl, params, config || defaultConfig)
        .then((response) => {
            responseHandler(response.data);
        })
        .catch((e) => {
            openDialog(getErrorMessage(e), "warning");
        });
};

/**
 * Arguments
 * state: State in store module caller. Note that all responeHandlers need to set their state.polling to false, or loop will run for 5 minutes.
 * apiUrl: hopefully self-explanatory, and self-explanatory could shouldn't be given obvious explanations in comments.
 * responseHandler: callback Function to handle result.
 */
async function poll({ state, apiUrl, responseHandler }) {
    let failsafeCounter = 100; // Hard timeout of 5 minutes/300 seconds
    while (state.polling && failsafeCounter--) {
        await pollTimeout();
        await doPoll({ state, apiUrl, responseHandler });
    }
    return new Promise((resolve) => resolve());
}

const pollTimeout = () => {
    return new Promise((resolve) => setTimeout(resolve, 3000));
};

const doPoll = ({ state, apiUrl, responseHandler }) => {
    return klinikenApi
        .post(apiUrl, { sessionId: state.sessionId }, getConfig())
        .then((response) => {
            let status = response.data.status;
            if (status) {
                if (status === "failed") {
                    responseHandler("failed", response.data.message);
                }
            } else responseHandler("succeeded", response.data);
        })
        .catch((e) => {
            responseHandler("error", getErrorMessage(e));
        });
};

export { init, poll };
