<template>
    <div
        class="journalanteckningar-list-item"
        :class="{ minimized: journalanteckning.collapsed, makulerad: journalanteckning.is_nullified }"
    >
        <div class="header">
            <button class="chevron" @click.prevent="collapse = !collapse" v-if="!historisk">
                <img src="@/assets/dropdown-arrow-blue.svg" alt="" :class="{ rotate: !collapse }" />
            </button>
            <div class="info">
                <div v-if="!historisk">
                    {{ moment(journalanteckning.created_at).format("YYYY-MM-DD [kl.] HH:mm") }}
                </div>
                <div v-else>
                    Senast redigerad
                    {{ moment(journalanteckning.edited_at).format("YYYY-MM-DD [kl.] HH:mm") }}
                </div>
                <div>
                    {{ journalanteckning.anteckningstyp.namn }}
                </div>
                <div>
                    {{ ansvarigsNamn() }}, <small>{{ ansvarigsYrkeskategori() }}</small
                    >,
                    <small>{{ ansvarigsOrgUnit() }}</small>
                    <small class="edited" v-if="journalanteckning.is_edited">(redigerad)</small>
                </div>
                <div>
                    <status-widget v-if="status()" :status="status()" />
                </div>
            </div>
            <actions-dropdown
                v-if="!journalanteckning.is_nullified && !historisk"
                :actions="dropdownActions"
                :journalanteckning="journalanteckning"
                :id="'actions-dropdown-' + journalanteckning.pk"
                @dropdownAction="dropdownAction"
            />
        </div>

        <collapse-transition>
            <div v-show="!collapse">
                <div class="body">
                    <div class="nullified-wrong-patient" v-if="journalanteckning.nullifiedReason === 'Fel patient'">
                        [ Anteckning makulerad pga fel patient ]
                    </div>

                    <recursive-section
                        v-for="sektion in journalanteckning.sektioner"
                        :key="sektion.pk"
                        :sektion="sektion"
                        :depth="1"
                        :historisk="historisk"
                    />
                </div>

                <div class="footer">
                    <div>Upprättad av {{ journalanteckning.created_by.user.name }}</div>
                    <div v-if="journalanteckning.is_signed">
                        Signerad av
                        {{ journalanteckning.legalAuthenticator.user.name }}
                        {{ moment(journalanteckning.signatureTime).format("YYYY-MM-DD [kl.] HH:mm") }}
                    </div>
                    <div v-else-if="journalanteckning.accountableHealthcareProfessional">
                        Signeringsansvarig
                        {{ journalanteckning.accountableHealthcareProfessional.user.name }}
                    </div>
                    <div v-if="!historisk && journalanteckning.is_edited">
                        Senast redigerad av
                        {{ journalanteckning.edited_by.user.name }}
                        {{ moment(journalanteckning.edited_at).format("YYYY-MM-DD [kl.] HH:mm") }}
                    </div>
                    <div v-if="historisk">
                        Skapad {{ moment(journalanteckning.created_at).format("YYYY-MM-DD [kl.] HH:mm") }}
                    </div>
                    <div v-if="!historisk && journalanteckning.is_edited">
                        <a @click.prevent="visaTidigare" href="#">Tidigare versioner</a>
                    </div>
                    <div v-if="!journalanteckning.approvedForPatient || !journalanteckning.anteckningstyp.visas_pa_npo">
                        Ej 1177
                    </div>
                </div>
            </div>
        </collapse-transition>
    </div>
</template>

<script>
    import { CollapseTransition } from "@ivanv/vue-collapse-transition";
    import moment from "moment";
    import StatusWidget from "@/components/widgets/StatusWidget.vue";
    import ActionsDropdown from "@/components/generic/ActionsDropdown.vue";
    import { mapGetters } from "vuex";
    import RecursiveSection from "@/tabs/Journalanteckningar/RecursiveSection.vue";

    export default {
        components: {
            RecursiveSection,
            ActionsDropdown,
            StatusWidget,
            CollapseTransition,
        },
        props: {
            journalanteckning: {
                type: Object,
            },
            historisk: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                collapse: false,
            };
        },
        computed: {
            ...mapGetters("userData", ["enhetsadministrator"]),
            moment() {
                return moment;
            },
            dropdownActions() {
                if (this.enhetsadministrator)
                    return [
                        { name: "redigera", title: "Redigera" },
                        { name: "makulera", title: "Makulera" },
                    ];
                return [{ name: "redigera", title: "Redigera" }];
            },
        },
        methods: {
            ansvarigsNamn() {
                if (this.journalanteckning.legalAuthenticator) {
                    return this.journalanteckning.legalAuthenticator.user.name;
                } else if (this.journalanteckning.created_by) {
                    return this.journalanteckning.created_by.user.name;
                } else {
                    return "[Saknas]";
                }
            },
            ansvarigsYrkeskategori() {
                const profile = this.journalanteckning.legalAuthenticator || this.journalanteckning.created_by;
                if (profile && profile.yrkeskategori && profile.yrkeskategori.displayName)
                    return profile.yrkeskategori.displayName;
                return "Annat";
            },
            ansvarigsOrgUnit() {
                if (this.journalanteckning.legalAuthenticator) {
                    return this.journalanteckning.legalAuthenticator.orgUnit.name;
                } else if (this.journalanteckning.created_by) {
                    return this.journalanteckning.created_by.orgUnit.name;
                }
            },
            status() {
                if (this.journalanteckning.is_nullified) return "makulerad";
                if (this.journalanteckning.is_signed) return "signerad";
                return null;
            },
            dropdownAction(action) {
                this.$emit(action, this.journalanteckning);
            },
            visaTidigare() {
                this.$emit("historik", this.journalanteckning);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/style/variables";
    @import "@/style/button";

    .journalanteckningar-list-item {
        .header,
        .footer {
            background-color: #f3f5f9;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            padding: 8px 16px;

            .chevron {
                @include button;

                .rotate {
                    transform: rotate(180deg);
                }
            }

            .info {
                flex: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                color: $color-table-header;

                div:first-child {
                    padding-right: 17px;
                }

                div + div {
                    border-left: 2px solid $color-greyline;
                    padding-left: 17px;
                    padding-right: 17px;
                }

                div:last-child {
                    border-left: none;
                }
            }
        }

        .body {
            padding: 0 44px 50px;

            .nullified-wrong-patient {
                font-weight: bold;
            }
        }

        .footer {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 5px 30px;
            color: $color-text-grey;
            font-size: 14px;

            div:first-child {
                padding-right: 17px;
            }

            div + div {
                border-left: 1px solid $color-greyline;
                padding-left: 17px;
                padding-right: 17px;
            }
        }
    }
</style>
