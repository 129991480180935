<template>
    <div class="FilhanteringPerson colspan c8">
        <input-wrapper :component="DisplayTextWidget" label="Person-id" v-model="personId" />

        <input-wrapper :component="DisplayTextWidget" label="Typ av person-id" v-model="personIdTypeName" />

        <input-wrapper :component="DisplayTextWidget" label="Patientnamn" v-model="patientNamn" />
    </div>
</template>

<script>
import DisplayTextWidget from "@/components/widgets/inputs/DisplayTextWidget.vue";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import FilhanteringPersonIdWidget from "./FilhanteringPersonIdWidget.vue";

export default {
    name: "FilhanteringPersonInlastFil",

    components: {
        InputWrapper,
    },
    props: { index: Number },
    data() {
        return {
            personIdType2Name: {
                "1.2.752.129.2.1.3.1": "Personnummer",
                "1.2.752.129.2.1.3.3": "Samordningsnummer",
                "1.2.752.97.3.1.3": "SLL reservnummer",
                kliniken2: "Kliniken 2",
                skreg: "Skaderegistret",
            },

            formErrors: [],
            DisplayTextWidget,

            FilhanteringPersonIdWidget,
        };
    },

    computed: {
        personIdTypeName() {
            //  Lista ut vårt personIdTypeName
            const personIdType = this.$store.state.filhantering.redigering.metaData.personIdType;
            let result = this.personIdType2Name[personIdType];

            // Om vi inte får någon träff returnerar vi koden som den är
            if (result === undefined) {
                result = personIdType;
            }
            return result;
        },

        patientNamn() {
            const fornamn = this.$store.state.filhantering.redigering.metaData["fornamn"];
            const efternamn = this.$store.state.filhantering.redigering.metaData["efternamn"];
            return `${fornamn} ${efternamn}`;
        },

        personId() {
            return this.$store.state.filhantering.redigering.metaData.personId;
        },
    },
};
</script>

<style lang="scss" scoped>
div.FilhanteringPerson {
    display: flex;
    flex-direction: row;
}
</style>
