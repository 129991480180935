import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

const getDefaultState = () => {
    return {
        anteckningstyper: [],
        count: 0,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        anteckningstyper(state) {
            return state.anteckningstyper;
        },
        count(state) {
            return Math.ceil(state.count);
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setAnteckningstyper: (state, data) => {
            state.anteckningstyper = data;
        },
        setCount(state, count) {
            state.count = count;
        },
    },
    actions: {
        async save(context, data) {
            try {
                const response = await klinikenApi.post(`anteckning/typer/`, data.anteckningstyp, getConfig());
                return response;
            } catch (e) {
                openDialog("Kunde inte spara anteckningstyp: " + getErrorMessage(e), "warning");
            }
        },
        async update(context, data) {
            try {
                const response = await klinikenApi.put(
                    `anteckning/typer/${data.anteckningstyp.pk}/`,
                    data.anteckningstyp,
                    getConfig()
                );
                return response;
            } catch (e) {
                openDialog("Kunde inte uppdatera anteckningstyp: " + getErrorMessage(e), "warning");
            }
        },
        async load({ commit }, offset, failSilently = false) {
            try {
                const response = await klinikenApi.get(`anteckning/typer/?limit=100&offset=${offset}`, getConfig());
                await commit("setAnteckningstyper", response.data.results);
                await commit("setCount", response.data.count);
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta typ." + getErrorMessage(e), "warning");
                }
            }
        },
    },
};
