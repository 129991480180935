<template>
    <div class="anvandare">
        <admin-lock-border />
        <div class="header">
            <h1>Användare</h1>
            <button type="button" @click="openAnvandare">
                <img src="@/assets/plus_circle_blue.svg" />
                <span>Skapa ny användare</span>
            </button>
        </div>

        <search-filter v-model="filterValues" @input="handleSearch" :fields="filterFields">
            <template v-slot:header-filters>
                <FormulateInput class="search-field" name="q" placeholder="Sök användare" />
            </template>
            <template v-slot:fields>
                <FormulateInput type="text" name="user__first_name" label="Förnamn" />
                <FormulateInput type="text" name="user__last_name" label="Efternamn" />

                <FormulateInput
                    name="yrkeskategori"
                    label="Yrkeskategori"
                    type="vueselect"
                    :options="yrkeskategorier"
                />

                <FormulateInput
                    name="signeringsansvarig"
                    label="Signeringsansvarig"
                    type="vueselect"
                    :options="[
                        { label: 'Ja', value: 'true' },
                        { label: 'Nej', value: 'false' },
                    ]"
                />

                <FormulateInput
                    name="enhetsadministrator"
                    label="Enhetsadministratör"
                    type="vueselect"
                    :options="[
                        { label: 'Ja', value: 'true' },
                        { label: 'Nej', value: 'false' },
                    ]"
                />

                <FormulateInput name="orgUnit" label="Vårdenhet" type="vueselect" :options="orgUnits" />
            </template>
        </search-filter>

        <div class="subheader">
            <FormulateInput @change="handleInactive" type="checkbox" label="Visa även inaktiva användarprofiler" />
        </div>

        <kliniken-table
            paginationStyle="paging"
            :items="patchedItems"
            :columns="columns"
            :count="count"
            :limit="limit"
            :has-dropdown="true"
            :dropdown-actions="dropdownActions"
            :currentPage="currentPage"
            @loadPage="loadPage"
            @changeLimit="changeLimit"
            @loadMore="loadMore"
            @editUser="editUser"
            @addProfile="addProfile"
            @setInactiveProfile="setInactiveProfile"
            @setActiveProfile="setActiveProfile"
        />
    </div>
</template>

<script>
    import KlinikenTable from "@/components/Table/KlinikenTable.vue";
    import BaseTab from "@/tabs/mixins/BaseTab";
    import tabIcon from "@/assets/anvandare.svg";
    import Anvandare from "@/tabs/Admin/Anvandare.vue";
    import SearchFilter from "@/components/SearchFilter.vue";
    import { getConfig, getErrorMessage, klinikenApi } from "@/api";
    import { openDialog } from "@/utils";
    import StatusInaktivIcon from "@/assets/actionIcons/statusinaktiv.svg";
    import dialogEvents from "@/dialogEvents";
    import AdminLockBorder from "@/components/AdminLockBorder.vue";
    import { mapState } from "vuex";
    import { cloneDeep } from "lodash";

    export default {
        extends: BaseTab,
        name: "ListAnvandare",
        tabName: "Användare",
        icon: tabIcon,
        props: ["tabId"],
        components: {
            KlinikenTable,
            SearchFilter,
            AdminLockBorder,
        },

        data() {
            return {
                filterValues: {},
                showInactive: false,
                yrkeskategorier: [],
                orgUnits: [],
                sortField: "user__name,orgUnitRoll",
                items: [],
                count: 0,
                fetchBatch: 1,
                limit: 20,
                currentPage: 1,
                offset: 0,
                columns: [
                    {
                        title: "Namn",
                        name: "user.name",
                    },
                    {
                        title: "PersonId",
                        name: "user.personId",
                    },
                    {
                        title: "Användarnamn",
                        name: "user.username",
                    },
                    {
                        title: "Yrkeskategori",
                        name: "yrkeskategori.displayName",
                    },
                    {
                        title: "Roll i Kliniken",
                        name: "roll.displayName",
                    },
                    {
                        title: "Vårdenhet",
                        name: "orgUnit.name",
                    },
                    {
                        title: "Status",
                        name: "is_active_text",
                        type: "icon",
                        icons: {
                            Inaktiv: StatusInaktivIcon,
                        },
                    },
                ],
                dropdownActions: [
                    {
                        name: "editUser",
                        title: "Redigera användare",
                        show: () => {
                            return true;
                        },
                    },
                    {
                        name: "addProfile",
                        title: "Lägg till ytterligare profil",
                        show: () => {
                            return true;
                        },
                    },
                    {
                        name: "setInactiveProfile",
                        title: "Inaktivera profil",
                        show: (item) => {
                            return item.is_active;
                        },
                    },
                    {
                        name: "setActiveProfile",
                        title: "Aktivera profil",
                        show: (item) => {
                            return !item.is_active;
                        },
                    },
                ],
            };
        },

        computed: {
            ...mapState("userAdmin", ["profilesSearchResult"]),
            filterFields() {
                return {
                    user__first_name: { label: "Förnamn" },
                    user__last_name: { label: "Efternamn" },
                    yrkeskategori: { label: "Yrkeskategori", options: this.yrkeskategorier },
                    signeringsansvarig: {
                        label: "Signeringsansvarig",
                        options: [
                            { label: "Ja", value: "true" },
                            { label: "Nej", value: "false" },
                        ],
                    },
                    enhetsadministrator: {
                        label: "Enhetsadministratör",
                        options: [
                            { label: "Ja", value: "true" },
                            { label: "Nej", value: "false" },
                        ],
                    },
                    orgUnit: { label: "Vårdenhet", options: this.orgUnits },
                    q: { label: "Sök " },
                };
            },

            patchedItems() {
                /**
                 * Gömmer användardetaljer på profiler som redan har visats och lägger till textparameter för inaktiv
                 */
                const seen = new Set();

                return this.profilesSearchResult.map((item) => {
                    let patchedItem = cloneDeep(item);

                    const { name, personId } = patchedItem.user;
                    const identifier = `${name}-${personId}`;

                    if (seen.has(identifier)) {
                        patchedItem.user.name = "";
                        patchedItem.user.personId = "";
                    } else {
                        seen.add(identifier);
                    }

                    patchedItem.is_active_text = patchedItem.is_active ? "" : "Inaktiv";

                    return patchedItem;
                });
            },
        },

        methods: {
            openAnvandare() {
                this.$store.dispatch("tabs/openTab", { component: Anvandare });
            },

            async handleSearch(filterData) {
                this.filterValues = filterData;
                this.currentPage = 1;
                await this.fetchProfiles();
            },

            async handleInactive() {
                this.showInactive = !this.showInactive;
                await this.fetchProfiles();
            },

            async fetchProfiles() {
                let params = {
                    ...this.filterValues,

                    ...{
                        ordering: this.sortField,
                        limit: this.limit,
                        offset: this.offset,
                    },
                };

                if (!this.showInactive) {
                    params["is_active"] = true;
                }

                if (this.limit > 0) {
                    params["limit"] = this.limit * this.fetchBatch;
                }

                try {
                    const response = await this.$store.dispatch("userAdmin/fetchProfilesSearchResults", params);
                    this.count = response.data.count;
                } catch (error) {
                    openDialog(getErrorMessage(error), "error");
                }
            },

            async loadPage(page) {
                this.currentPage = page;
                this.offset = (page - 1) * this.limit;
                await this.fetchProfiles();
            },

            async loadMore() {
                this.fetchBatch++;
                await this.fetchProfiles();
            },

            async changeLimit(limit) {
                this.currentPage = 1;
                this.fetchBatch = 1;
                this.limit = limit;
                this.offset = 0;
                await this.fetchProfiles();
            },

            async editUser(profile) {
                try {
                    await this.$store.dispatch("userAdmin/editUser", profile);
                } catch (error) {
                    openDialog("Något gick fel. Var god försök igen. Om felet kvarstår, kontakta supporten.", "error");
                }
            },

            async addProfile(profile) {
                await this.$store.dispatch("userAdmin/addProfile", profile);
            },

            async setInactiveProfile(profile) {
                dialogEvents.$emit("open", {
                    type: "warning",
                    description: "Är du säker på att du vill inaktivera profilen?",
                    buttons: [
                        {
                            title: "Nej",
                            type: "secondary",
                        },
                        {
                            title: "Ja",
                            type: "primary",
                            action: async () => {
                                try {
                                    await this.$store.dispatch("userAdmin/setInactiveProfile", profile);
                                } catch (error) {
                                    openDialog(
                                        "Något gick fel. Var god försök igen. Om felet kvarstår, kontakta supporten.",
                                        "error"
                                    );
                                }
                            },
                        },
                    ],
                });
            },

            async setActiveProfile(profile) {
                try {
                    await this.$store.dispatch("userAdmin/setActiveProfile", profile);
                } catch (error) {
                    openDialog("Något gick fel. Var god försök igen. Om felet kvarstår, kontakta supporten.", "error");
                }
            },

            async _getYrkeskategorier() {
                try {
                    const response = await klinikenApi.get("/core/kodverk/yrkeskategorier/", getConfig());

                    if (response.data?.results) {
                        this.yrkeskategorier = response.data.results.map(({ id, displayName }) => ({
                            value: id,
                            label: displayName,
                        }));
                    } else {
                        this.yrkeskategorier = [];
                    }
                } catch (error) {
                    openDialog(getErrorMessage(error), "warning");
                }
            },

            async _getOrgUnits() {
                try {
                    const response = await klinikenApi.get("/core/orgunits/", getConfig());

                    if (response.data?.results) {
                        this.orgUnits = response.data.results.map(({ id, name }) => ({
                            value: id,
                            label: name,
                        }));
                    } else {
                        this.orgUnits = [];
                    }
                } catch (error) {
                    openDialog(getErrorMessage(error), "warning");
                }
            },
        },

        async created() {
            await this._getYrkeskategorier();
            await this._getOrgUnits();

            await this.fetchProfiles({ is_active: true });
        },
        beforeDestroy() {},
    };
</script>

<style lang="scss" scoped>
    .anvandare {
        .header {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 26px;

            h1 {
                margin: 0;
            }

            button-hollow:hover {
                background-color: transparent;
            }

            button {
                background: transparent;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    margin-right: 8px;
                }

                span {
                    color: #6c8188;
                    font-family: Roboto Medium, serif;
                    font-size: 16px;
                    line-height: 0px;
                    white-space: nowrap;
                }
            }
        }

        .subheader {
            display: flex;
            justify-content: space-between;
            margin: 20px 0px;
            flex-direction: row-reverse;
        }
    }
</style>
