<template>
    <LoginButtonGroup class="select-profile">
        <LoginButton
            v-for="profile in profiles"
            :key="profile.pk"
            @click="selectProfile(profile)"
            :img="require('@/assets/profil.svg')"
        >
            <span>
                <b>Roll:</b> {{ profile.roll_display.displayName }}&nbsp;&nbsp; <b>Yrke:</b>
                {{ profile.yrkeskategori_display.displayName }}&nbsp;&nbsp; <b>Vårdenhet:</b> {{ profile.orgUnit.name }}
            </span>
        </LoginButton>
    </LoginButtonGroup>
</template>

<script setup>
    import { computed } from "vue";
    import { useStore } from "@/store";
    import LoginButtonGroup from "@/components/Auth/LoginButtonGroup.vue";
    import LoginButton from "@/components/Auth/LoginButton.vue";

    const store = useStore();

    const emit = defineEmits(["select"]);

    const profiles = computed(() => store.getters["userData/activeProfiles"]);

    const selectProfile = (profile) => {
        emit("select", profile);
    };
</script>
