<template>
    <form class="filhantering-redigera-fil-form">
        <h2>Redigera fil</h2>
        <div class="form-group k3-form fullscreen">
            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">1</div>
                    </div>
                    <div class="vr"></div>
                </div>
                <div style="width: 100%">
                    <div class="left-pad" @click="togglePaneOne()">
                        <h3>
                            Fildata
                            <img
                                :class="['k3-dropdown-icon', paneOneOpen ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>

                    <collapse-transition>
                        <div class="k3-sub-form" v-if="paneOneOpen">
                            <FilhanteringForhandsvisning
                                class="margin-align"
                                v-bind:url="urlTillFilAttForhandsvisa"
                                v-bind:metaData="forhandsvisningMetaData"
                                v-bind:uf="ursprungligtFilnamn"
                            ></FilhanteringForhandsvisning>

                            <FilhanteringPersonInlastFil class="whole" />

                            <input-wrapper
                                class="input-grid-1-col"
                                :component="DisplayTextWidget"
                                label="Inläsningsdatum"
                                name="inmatningsDatum"
                                v-model="inmatningsDatum"
                            />

                            <input-wrapper
                                class="input-grid-1-col"
                                :component="widgets.DateWidget"
                                label="Dokumentets datum"
                                name="dokumentDatum"
                                v-model="dokumentDatum"
                                :required="false"
                            />

                            <input-wrapper
                                class="input-grid-1-col"
                                :component="DisplayTextWidget"
                                label="Inläst av"
                                name="createdByFullName"
                                v-model="createdByFullName"
                            />

                            <input-wrapper
                                class="input-grid-1-col"
                                :component="FilhanteringSuggestWidget"
                                label="Dokumenttyp"
                                name="dokumentTyp"
                                v-model="dokumentTyp"
                                apisearch="/filhantering/dokumenttyp/"
                                :required="true"
                                :map="
                                    (el) => {
                                        return {
                                            id: el.id,
                                            code: el.id,
                                            displayName: el.namn,
                                        };
                                    }
                                "
                            />
                            <input-wrapper
                                class="input-grid-2-col"
                                :component="widgets.ScalableTextArea"
                                label="Kommentar"
                                name="kommentar"
                                v-model="kommentar"
                                :maxlength="255"
                            />
                        </div>
                    </collapse-transition>
                </div>
            </div>
        </div>
        <div class="button-row">
            <ButtonGroup>
                <Button size="primary" type="button" @click="save" :disabled="!formIsValid"> Spara och stäng</Button>

                <Button size="secondary" variant="contour" type="button" @click="cancel">Avbryt</Button>
            </ButtonGroup>

            <ButtonGroup>
                <Button size="secondary" variant="alert" type="button" role="button" @click="clearFields">
                    Rensa alla fält
                </Button>
            </ButtonGroup>
        </div>
    </form>
</template>

<script>
import { CollapseTransition } from "@ivanv/vue-collapse-transition";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";
import widgets from "@/components/widgets/inputs";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import { getConfig, klinikenApi } from "@/api";
import FilhanteringPersonInlastFil from "./FilhanteringPersonInlastFil.vue";
import FilhanteringForhandsvisning from "./Forhandsvisning/FilhanteringForhandsvisning.vue";
import FilhanteringSuggestWidget from "./FilhanteringSuggestWidget.vue";
import DisplayTextWidget from "@/components/widgets/inputs/DisplayTextWidget.vue";
import { bytesToSize } from "./Utils.js";
import dialogEvents from "@/dialogEvents";
import { mapGetters } from "vuex";

const metaDataGetterAndSetter = (key) => {
    return {
        get() {
            return this.$store.state.filhantering.redigering.metaData[key];
        },

        set(value) {
            this.$store.commit("filhantering/redigeringAngeMetaData", { key, value });
            this.$store.commit("filhantering/redigeringSetFormDirty");
        },
    };
};

export default {
    name: "FilhanteringRedigeraFilForm",

    components: {
        InputWrapper,
        CollapseTransition,
        FilhanteringPersonInlastFil,
        FilhanteringForhandsvisning,
        ButtonGroup,
        Button,
    },

    data() {
        return {
            paneOneOpen: true,
            paneTwoOpen: false,
            personIdIsValid: false,
            personIdIsUnique: false,
            formErrors: [],
            widgets,
            FilhanteringPersonInlastFil,
            DisplayTextWidget,
            FilhanteringSuggestWidget,

            forhandsvisningMetaData: {
                ursprungligtFilnamn: this.$store.state.filhantering.redigering.metaData.ursprungligtFilnamn,
                filstorlek: bytesToSize(this.$store.state.filhantering.redigering.metaData.filstorlek),
                filformat: this.$store.state.filhantering.redigering.metaData.filformat,
            },
        };
    },

    computed: {
        accountaWbleHealthcareProfessional: metaDataGetterAndSetter("accountableHealthcareProfessional"),
        kommentar: metaDataGetterAndSetter("kommentar"),
        inmatningsDatum: metaDataGetterAndSetter("inmatningsDatum"),
        dokumentDatum: metaDataGetterAndSetter("dokumentDatum"),
        legalAuthenticator: metaDataGetterAndSetter("legalAuthenticator"),
        //createdBy: metaDataGetterAndSetter("created_by"),
        createdBy: metaDataGetterAndSetter("createdBy"),
        createdByFullName: metaDataGetterAndSetter("createdByFullName"),
        dokumentTyp: metaDataGetterAndSetter("dokumentTyp"),
        patientUUID: metaDataGetterAndSetter("patientUUID"),
        fileId: metaDataGetterAndSetter("fileId"),
        ursprungligtFilnamn: metaDataGetterAndSetter("ursprungligtFilnamn"),

        ...mapGetters("filhantering", ["redigeringFormIsClean"]),

        formIsValid() {
            let result = true;

            // Check date, TODO check that it is actually valid
            // if (!this.dokumentDatum) {
            //    result = false;
            // }

            // Check that dokumentTyp is set. TODO check that it is valid
            if (!this.dokumentTyp) {
                result = false;
            }

            return result;
        },

        urlTillFilAttForhandsvisa() {
            const patientUUID = this.$store.state.filhantering.redigering.metaData.patientUUID;
            const fileId = this.$store.state.filhantering.redigering.metaData.fileId;
            return `/filhantering/${patientUUID}/sendfile/${fileId}/`;
        },
    },

    methods: {
        copyFromPrevious() {
            this.$emit("copyFromPrevious");
        },

        togglePaneOne() {
            this.paneOneOpen = !this.paneOneOpen;
        },

        togglePaneTwo() {
            this.paneTwoOpen = !this.paneTwoOpen;
        },

        mapDokumentTypSearchResults(el) {
            return { id: el.id, code: el.id, displayName: el.namn };
        },

        clearFields() {
            this.dokumentDatum = undefined;
            this.kommentar = undefined;
            this.dokumentTyp = { code: "", id: "", displayName: "" };
        },

        async save() {
            await this.updateFileMetaData();
            this.$root.$emit("file-changed");
            this.$emit("closetab");
        },

        async cancel() {
            if (!this.redigeringFormIsClean) {
                dialogEvents.$emit("open", {
                    type: "warning",
                    description: "Du har inte sparat. Vill du stänga utan att spara?",
                    buttons: [
                        {
                            title: "Fortsätt redigera",
                            type: "secondary",
                        },
                        {
                            title: "Ja, stäng utan att spara",
                            type: "primary",
                            action: () => {
                                this.$emit("closetab");
                            },
                        },
                    ],
                });
            } else {
                this.$emit("closetab");
            }
        },

        // Denna används när vi redigerar metaData för en existerande fil
        async updateFileMetaData() {
            const formData = new FormData();

            formData.append("patient", this.patientUUID);

            // Signeringsanvarig
            // formData.append("legalAuthenticator", this.metaData.legalAuthenticator);

            formData.append("documentTime", this.dokumentDatum);
            formData.append("documentTitle", this.kommentar);

            // Specifika för Filer
            formData.append("dokument_typ", this.dokumentTyp);

            const url = `/filhantering/${this.patientUUID}/fil/${this.fileId}/`;
            // TODO handle errors

            await klinikenApi.put(url, formData, getConfig({}, "Filer"));
        },

        showWarning(message) {
            this.$emit("warning", message);
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables";

.filhantering-redigera-fil-form {
    .margin-align {
        margin-left: 10px;
        margin-right: 10px;
    }

    h2 {
        margin: 0 0 20px 10px;
    }

    h3 {
        height: 28px;
        color: #277692;
        font-family: Roboto;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 28px;
        display: inline-block;
        margin-left: 7px;
    }

    h4 {
        color: #277692;
        font-family: Roboto;
        font-size: 17px;
        letter-spacing: 0;
        line-height: 28px;
    }

    .fixed-width {
        max-width: 700px;
        margin-bottom: 29px;
    }

    .circle {
        height: 26px;
        width: 26px;
        background-color: #277692;
        border-radius: 13px;
    }

    .number {
        height: 26px;
        width: 100%;
        color: #ffffff;
        font-family: Roboto;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 26px;
        text-align: center;
    }

    .vr {
        border-left: 1px solid #8ebaca;
        height: calc(100% - 60px);
        min-height: 10px;
        margin-left: 13px;
        margin-top: 10px;
        margin-bottom: 10px;

        &.vr-no-min-height {
            min-height: 0px;
        }
    }

    .k3-lock-border {
        height: 26px;
        width: 350px;
        background: linear-gradient(134.72deg, #c2e6e6 0%, rgba(194, 230, 228, 0) 100%);
        color: #277692;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.46px;
        line-height: 26px;
        margin-bottom: 18px;
        margin-left: 10px;

        img {
            margin: -2px 6px 0px 17px;
        }
    }

    .k3-sub-form-flex-container {
        display: flex;
        flex-direction: row;
        margin-left: -20px;
    }

    .k3-sub-form {
        display: grid;
        width: 100%;
        grid-template-columns: 25% 25% 25% 25%;

        .whole {
            grid-column: 1 / span 4;
        }

        .input-grid-1-col {
            grid-column: span 1;
            min-width: 100%;
        }

        .input-grid-2-col {
            grid-column: span 2;
            min-width: 100%;
        }
    }

    .k3-dropdown-icon {
        margin-left: 15px;
    }

    .k3-img-rotate {
        transform: rotate(180deg);
    }

    .button-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>
