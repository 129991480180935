<template>
    <ModalContent v-if="showPopup" title="Välj idrott" class="idrotter-popup">
        <FormulateInput label="Välj idrott" v-model="idrott" :options="idrotter" type="vueselect" />
        <ButtonGroup>
            <Button type="button" size="primary" @click.prevent="chooseIdrott">Välj</Button>
        </ButtonGroup>
    </ModalContent>
</template>
<script>
import ModalContent from "@/components/generic/ModalContent.vue";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
export default {
    components: {
        ModalContent,
        Button,
        ButtonGroup,
    },
    props: {
        idrotter: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            idrott: "",
            show: true,
        };
    },
    computed: {
        showPopup() {
            return  this.idrotter && this.idrotter.length > 1 && this.show;
        },
    },
    methods: {
        async chooseIdrott() {
            if(this.idrott) {
                this.show = false;
                this.$emit("chooseIdrott", this.idrott);
            }
        },
    },
};
</script>
