<template>
    <div>
        <template v-if="patientSelected">
            <h2>Filer för {{ personId }} {{ name }}</h2>
            <CustomActionTable
                class="action-table"
                :selectableRows="false"
                :columns="TableColumns"
                :items="decoratedFiles"
                :actions="RowActions"
            />
        </template>
        <div v-else>
            <h1>Du måste sätta en aktiv patient för att se en fillistning.</h1>
        </div>
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import fileIcon from "@/assets/files.svg";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { mapGetters } from "vuex";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import { bytesToSize } from "./Utils.js";
import { openDialog } from "@/utils";
import FilhanteringRedigeraFil from "@/tabs/Filhantering/FilhanteringRedigeraFil.vue";

const TABLE_COLUMNS = [
    {
        name: "ikon",
        title: "",
        // template: function (val) {
        //     return "";
        // },
        cssClass: "filIkon",
    },
    {
        name: "dokument_typ_namn",
        title: "Typ av fil",
        cssClass: "fil",
    },
    {
        name: "decoratedAuthorDate",
        title: "Inläsningsdatum",
        template: "",
    },
    {
        name: "decoratedDocumentDate",
        title: "Dokumentets datum",
        template: "",
    },
    {
        name: "filformat",
        title: "Filformat",
        template: "",
    },
    {
        name: "decoratedSize",
        title: "Storlek",
        template: "",
        cssClass: "filstorlek",
    },
    {
        name: "ursprungligt_filnamn",
        title: "Filnamn",
        template: "",
    },
    {
        name: "decoratedComment",
        title: "Kommentar",
        template: "",
        cssClass: "kommentar",
    },
];

const ROW_ACTIONS = [
    {
        name: "edit",
        title: "Redigera fildata",
        img: "edit.svg",
        condition: () => {
            return true;
        },
    },
];

export default {
    extends: BaseTab,
    name: "FilhanteringFiler",
    tabName: "Filer",
    icon: fileIcon,
    patientBound: true,

    components: {
        CustomActionTable,
    },

    data() {
        return {
            TableColumns: TABLE_COLUMNS,
            RowActions: ROW_ACTIONS,
            files: [],
        };
    },

    computed: {
        ...mapGetters("patientData", ["patient", "personId", "name"]),
        ...mapGetters("tabs", ["tabs"]),

        patientSelected() {
            return Object.keys(this.patient).length !== 0;
        },

        decoratedFiles() {
            let filesCopy = [];
            Object.assign(filesCopy, this.files);
            filesCopy.forEach((file) => {
                // documentTime is optional
                try {
                    file.decoratedDocumentDate = file.documentTime.substring(0, 10);
                } catch (error) {
                    file.decoratedDocumentDate = "";
                }

                file.decoratedAuthorDate = file.authorTime.substring(0, 10);
                file.decoratedSize = bytesToSize(file.filstorlek);
                if (file.documentTitle) {
                    file.decoratedComment = file.documentTitle.substring(0, 240);
                } else {
                    file.decoratedComment = "";
                }
            });

            // Sort items by most recent first
            filesCopy.sort((a, b) => {
                if (a.authorTime < b.authorTime) {
                    return 1;
                } else if (a.authorTime > b.authorTime) {
                    return -1;
                } else {
                    return 0;
                }
            });

            return filesCopy;
        },
    },

    watch: {
        patient: function () {
            this.updateFilesFromBackend();
        },
    },

    methods: {
        async updateFilesFromBackend() {
            const config = getConfig({}, "Filer");
            if (this.patientSelected) {
                try {
                    const response = await klinikenApi.get(`filhantering/${this.patient.id}/fil/list/`, config);
                    this.files = response.data;
                } catch (error) {
                    openDialog(getErrorMessage(error), "Kunde inte ladda filer");
                }
            }
        },
    },

    created() {
        if (this.patientSelected) {
            this.updateFilesFromBackend();
        }
        this.$on("edit", async (data) => {
            // Figure out if this tab is already open. If so we need to inform that tab that a change is gonna come.
            const editingTabIsOpen = this.tabs
                .map((tab) => tab.component.name)
                .includes("FilhanteringRedigeraInlastFil");

            if (editingTabIsOpen) {
                // Inform FilhanteringRedigeraInlastFil that it needs to display another file
                this.$root.$emit("file-in-editing-tab-exchanged", { parameters: data });
            }

            await this.$store.dispatch("tabs/openTab", { component: FilhanteringRedigeraFil, parameters: data });
        });

        this.$root.$on("file-changed", () => {
            // TODO Here we could possibly start an animation that
            // informs us that the data is changing
            // Or we could do the harder thing and figure out how to
            // keep data in sync on the client with out going back to
            // the source of truth (the backend)
            this.updateFilesFromBackend();
        });
    },
};
</script>

<style lang="sass" scoped>
@import "@/style/variables"

/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

/* Bootstrap - End */

@import "@/style/deprecated_main"

div
    .action-table
        margin-top: 30px

        td.filstorlek
            span
                white-space: nowrap

        td.kommentar
            max-width: 8rem

            span
                width: 100%
                white-space: nowrap
                display: inline-block
                overflow: hidden
                text-overflow: ellipsis

        td.filIkon
            border-right: none !important
            background-image: url('@/assets/files_blue.svg')
            background-repeat: no-repeat
            background-position: center center
</style>
