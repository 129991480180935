<template>
    <div>
        <admin-lock-border />
        <h2>
            Anteckningstyper
            <hollow-button :icon="PlusCircleBlueIcon" label="Skapa ny anteckningstyp" @click="openForm()" />
        </h2>
        <br />
        <custom-action-table
            class="no-top"
            :selectableRows="false"
            :columns="TableColumns"
            :items="anteckningstyper"
            :actions="RowActions"
        >
        </custom-action-table>

        <pagination
            class="pagination mt-5"
            v-model="page"
            :per-page="100"
            :records="this.count"
            :options="paginationOptions"
            @paginate="paginate"
        />
    </div>
</template>

<script>
import Anteckningstyp from "@/tabs/Admin/Anteckningstyp.vue";
import BaseTab from "@/tabs/mixins/BaseTab";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import Pagination from "vue-pagination-2";
import PaginationTemplate from "@/components/widgets/PaginationTemplate.vue";
import { mapActions, mapGetters } from "vuex";
import tabIcon from "@/assets/blank.png";
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import HollowButton from "@/components/widgets/HollowButton.vue";
import PlusCircleBlueIcon from "@/assets/plus_circle_blue.svg";

const noNull = (str) => {
    return str || "";
};

const TABLE_COLUMNS = [
    {
        name: "name",
        title: "Namn",
        template: (item) => {
            return noNull(item.namn);
        },
    },
    {
        name: "sortering",
        title: "Sortering",
        template: (item) => {
            return item.sortering.toString();
        },
    },
    {
        name: "status",
        title: "Status",
        template: (item) => {
            if (item.is_valbar && item.is_active) return `<status-widget status="valbar" />`;
            else if (item.is_active) return `<status-widget status="aktiv" />`;
            else return `<status-widget status="inaktiv" />`;
        },
    },
    {
        name: "visas_pa_npo",
        title: "Visas på NPÖ",
        template: (item) => {
            if (item.visas_pa_npo == null) return "";
            else return item.visas_pa_npo ? "Ja" : "Nej";
        },
    },
    {
        name: "visas_pa_1177",
        title: "Visas som standard på 1177",
        template: (item) => {
            if (item.visas_pa_1177 !== null) {
                if (item.visas_pa_npo === false) return "-";
                else return item.visas_pa_1177 ? "Ja" : "Nej";
            } else {
                return "";
            }
        },
    },
];

const ROW_ACTIONS = [
    {
        name: "edit",
        title: "Redigera",
        img: "edit.svg",
        condition: () => {
            return true;
        },
    },
];

export default {
    extends: BaseTab,
    name: "ListAnteckningstyp",
    tabName: "Anteckningstyper",
    icon: tabIcon,
    components: {
        HollowButton,
        AdminLockBorder,
        CustomActionTable,
        Pagination,
    },
    props: ["tabId"],
    data() {
        return {
            PlusCircleBlueIcon,
            TableColumns: TABLE_COLUMNS,
            RowActions: ROW_ACTIONS,
            page: 1,
            paginationOptions: {
                theme: "bootstrap4",
                chunk: 10,
                template: PaginationTemplate,
                texts: {
                    count: "Visar {from} till {to} av {count} typer|{count} typer|En typ",
                },
            },
        };
    },
    computed: {
        ...mapGetters("anteckningstyp", ["anteckningstyper", "count"]),
    },
    methods: {
        ...mapActions("anteckningstyp", ["load"]),
        openForm() {
            this.$store.dispatch("tabs/openTab", { component: Anteckningstyp });
        },
        async paginate(page) {
            let offset = page * 100 - 100;
            await this.load(offset);
        },
    },
    async created() {
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
        try {
            await this.load(0);
        } catch (e) {
            //eslint-disable-next-line
            console.error(e);
        }
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });

        this.$on("edit", async (data) => {
            await this.$store.dispatch("tabs/openTab", { component: Anteckningstyp, parameters: data });
        });
    },
    beforeDestroy() {
        this.$store.commit("anteckningstyp/reset");
    },
};
</script>
