/**
 * Recognizes shorthand dosages, and returns their verbose form. If a shorthand is not recognized,
 * returns null.
 */
let variableDosage = new RegExp("^\\d+(\\+\\d+){1,3}$");
let regularDosage = new RegExp("^\\d+x\\d+$");

//This RegExp captures X(+X, repeated Y (0-7) times, X must be the same token). This is to be translated to "X st Y gånger dagligen"
let gangerDosage = new RegExp("^(\\d+)(\\+\\1){0,7}$");
// Catch any string that ends in " vb", case insensitive
let vbDosage = new RegExp("\\s(vb)$", "i");

function getDosageTextK2(lastWord) {
    var dosage = null;
    var dosageText = null;
    if (vbDosage.test(lastWord)) {
        dosageText = lastWord.slice(0, -3);
        dosageText += " vid behov";
    }

    if (gangerDosage.test(lastWord)) {
        dosage = lastWord.split("+");
        dosageText = dosage[0] + " st " + dosage.length + " gånger dagligen";
    }

    return dosageText;
}

function getDosageText(lastWord) {
    var dosage = null;
    var dosageText = null;

    if (regularDosage.test(lastWord)) {
        dosage = lastWord.split("x");
        dosageText = dosage[0] + " st " + dosage[1] + " gånger dagligen";
    } else if (variableDosage.test(lastWord)) {
        dosage = lastWord.split("+");
        dosageText = "";

        // TODO: göra dynamiskt? "Officiellt" får man bara ha max 4n i n+n+n+n eftersom det ska motsvara facken i en dosett.
        // TODO: ersätta 'st' med annan enhet om det är annan beredningsform?
        if (dosage.length === 2) dosageText = dosage[0] + " st på morgonen, " + dosage[1] + " st på kvällen";
        else if (dosage.length === 3)
            dosageText =
                dosage[0] + " st på morgonen, " + dosage[1] + " st till lunch, " + dosage[2] + " st på kvällen";
        // Regexp matchar bara 2-4 siffror
        else
            dosageText =
                dosage[0] +
                " st på morgonen, " +
                dosage[1] +
                " st till lunch, " +
                dosage[2] +
                " st på kvällen, " +
                dosage[3] +
                " st till natten";
    }
    return dosageText;
}

export { getDosageText, getDosageTextK2 };
