<template>
    <div class="suggest-wrap">
        <!--
    Important! keyup.esc.stop and keydown.enter.prevent necessary to prevent these keys from propagating to form
-->
        <template v-if="!preview">
            <input
                v-model="q"
                :required="required"
                :name="name + 'Display'"
                @input="updateValue()"
                v-bind="$attrs"
                autocomplete="off"
                class="text-input"
                type="search"
                @keyup.esc.stop="validate()"
                @keydown.down="moveDown"
                @keydown.up="moveUp"
                @keydown.enter.prevent="settle"
                @blur="
                    validate();
                    $emit('blur');
                "
            />
            <input type="hidden" :name="name" :value="id" />
            <div class="suggest-ul-div">
                <ul ref="suggest-list" class="suggest-list" v-show="isOpen" @wheel.prevent.stop="mouseWheel($event)">
                    <li
                        v-for="(item, index) in this.results"
                        :key="index"
                        @mouseenter="highlightedPosition = index"
                        @mousedown="select"
                        :name="name + '-suggest'"
                        :class="['suggest-item', { highlighted: index === highlightedPosition }]"
                    >
                        <slot name="item"
                            ><strong v-if="displayCode">{{ item.code }}</strong> {{ item.displayName }}
                        </slot>
                    </li>
                </ul>
            </div>
        </template>
        <span v-else class="input-preview">
            {{ q }}
        </span>
    </div>
</template>

<script>
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

const SEARCH_WAIT = 300;
const isInteger = /^[0-9]+$/;
const uuid = /^[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export default {
    name: "SuggestWidget",
    props: {
        name: String,
        required: Boolean,
        maxlength: String,
        apisearch: String,
        extraParams: Object, // An object specifying additional parameters to be passed to backend, such as filters
        value: undefined, // Should be Object, but can be Number until backend catches up
        displayCode: Boolean, // if code should be shown or not
        /**
         * In case the data from backend is NOT a JSON object with the format
         * { id, code, displayName }, a map Function must be supplied by the parent
         */
        map: {
            type: Function,
            default: (item) => {
                return {
                    id: item.id,
                    code: item.code,
                    displayName: item.displayName,
                };
            },
        },
        preview: Boolean,
    },
    data() {
        return {
            API_SEARCH: this.apisearch,
            id: "",
            displayName: "",
            code: "",
            q: "",
            searchTimeout: false,
            loading: false,
            results: [],
            isOpen: false,
            highlightedPosition: NaN,
            itemCache: {},
        };
    },
    computed: {
        Q() {
            if (this.displayCode && this.code !== "") {
                if (this.q.includes(this.code)) {
                    return this.code;
                } else return this.q || "";
            } else return this.q || "";
        },
    },
    methods: {
        setValues({ id, code, displayName } = { id: "", code: "", displayName: "" }) {
            this.q = ((this.displayCode ? code + " " : "") + displayName).trim();

            // This will get triggered if we try to clear this component of it's input data
            // by updating the props higher up in the component tree. We don't want users
            // to see "undefined" in their input fields
            if (this.q === "undefined") {
                this.q = "";
            }

            this.id = id;
            this.code = code;
            this.displayName = displayName;
        },
        getValues() {
            return {
                id: this.id,
                code: this.code,
                displayName: this.displayName,
            };
        },
        validate() {
            this.isOpen = false;
            if (this.results.length === 0) this.setValues();
            else this.setValues(this.results[0]);
            this.emitValue();
        },
        emitValue(emitUpdateOnly = false) {
            if (!emitUpdateOnly) this.$emit("input", this.id);
            this.$emit("update", this.getValues());
        },
        search() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            if (this.loading) {
                return setTimeout(this.search, SEARCH_WAIT);
            }
            if (this.Q.length >= 1) {
                if (this.API_SEARCH) this.searchTimeout = setTimeout(this.fetchResults, SEARCH_WAIT);
                else if (this.dataList) this.searchTimeout = setTimeout(this.fetchData, SEARCH_WAIT);
            } else {
                this.results = [];
            }
        },
        fetchResults() {
            this.loading = true;
            return klinikenApi
                .get(this.API_SEARCH, getConfig({ params: { ...this.extraParams, ...{ q: this.Q } } }))
                .then((response) => {
                    this.results = response.data.results.map(this.map);
                    this.results.forEach((item) => {
                        this.itemCache[item.id] = item;
                    });
                    if (this.results.length === 1) this.highlightedPosition = 0;
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                })
                .then(() => {
                    this.loading = false;
                    this.searchTimeout = false;
                });
        },
        moveDown() {
            if (!this.isOpen) return;
            if (isNaN(this.highlightedPosition)) this.highlightedPosition = 0;
            else this.highlightedPosition = (this.highlightedPosition + 1) % this.results.length;
        },
        moveUp() {
            if (!this.isOpen) return;
            this.highlightedPosition =
                this.highlightedPosition - 1 < 0 ? this.results.length - 1 : this.highlightedPosition - 1;
        },
        select() {
            this.isOpen = false;
            let emitValue = true;
            if (this.id === this.results[this.highlightedPosition].id) emitValue = false;
            this.setValues(this.results[this.highlightedPosition]);
            this.results = [this.results[this.highlightedPosition]];
            this.emitValue(emitValue);
        },
        settle(event) {
            if (this.isOpen && !isNaN(this.highlightedPosition)) this.select();
            event.stopPropagation();
        },
        updateValue() {
            this.isOpen = true;
            this.highlightedPosition = NaN;
        },
        initialize(value) {
            if (!value) {
                this.setValues();
                this.emitValue(false);
                return;
            }
            /**
             * Initialize values.
             * Case 0: value is a number in String format -> convert to Int
             */
            if (isInteger.test(value)) value = parseInt(value);

            /**
             * Case: 1 value is a number in Int format
             */
            if (Number.isInteger(value)) {
                // Value is already stored in cache
                if (this.itemCache[value]) {
                    this.setValues(this.itemCache[value]);
                    this.emitValue(true);
                    return;
                }
                klinikenApi
                    .get(this.API_SEARCH + value + "/", getConfig())
                    .then((response) => {
                        value = response.data;
                        value = this.map(value);
                        this.itemCache[value.id] = value;
                        this.setValues(this.itemCache[value.id]);
                        this.emitValue(true);
                    })
                    .catch((error) => {
                        throw new Error(error, "error");
                    });
            } else if (typeof value === "string" && value !== "") {
                /**
                 * Case 2: value is String
                 */
                if (this.displayCode)
                    // value is a string with both code and text, used in JournalAnteckning.vue
                    value = value.split(" ")[0];

                // Value (as code) is already stored in cache
                for (const [cacheKey, cacheValue] of Object.entries(this.itemCache)) {
                    if (cacheValue.code === value || cacheValue.id == value || cacheValue.value === value) {
                        this.setValues(this.itemCache[cacheKey]);
                        // Send back normalized id
                        this.emitValue();
                        return;
                    }
                }

                (uuid.test(value)
                    ? klinikenApi.get(this.API_SEARCH + value + "/", getConfig())
                    : klinikenApi.get(this.API_SEARCH, getConfig({ params: { q: value } }))
                )
                    .then((response) => {
                        if (response.data.results) value = response.data.results[0];
                        else value = response.data;
                        if (!value) {
                            this.setValues();
                            this.emitValue();
                            return;
                        }

                        value = this.map(value);
                        this.itemCache[value.id] = value;
                        this.setValues(value);
                        // Send back normalized id
                        this.emitValue();
                    })
                    .catch((e) => {
                        openDialog(getErrorMessage(e), "error");
                    });
            } else if (typeof value === "object" && value !== null) {
                /**
                 * Case 3: value in a Object
                 */
                // value is in format matching database (such as when intially read)
                value = this.map(value);
                this.itemCache[value.id] = value;
                this.setValues(value);
                // Send back normalized id
                this.emitValue();
            }
        },
        async mouseWheel(event) {
            let hasScroll = false;
            let el = this.$refs["suggest-list"];
            await this.$nextTick(() => {
                hasScroll = el.scrollHeight > el.clientHeight;
            });
            if (!hasScroll) {
                event.preventDefault();
                event.stopPropagation();
                if (event.deltaY > 0) this.moveDown();
                else this.moveUp();
            }
        },
    },
    mounted() {
        this.initialize(this.value);
    },
    watch: {
        q() {
            let q = this.q.trim();
            if (!this.q) {
                this.results = [];
                return;
            }
            if (this.displayCode)
                // value is a string with both code and text, used in JournalAnteckning.vue
                q = q.split(" ")[0];
            for (const [key, value] of Object.entries(this.itemCache)) {
                if (value.code === q || value.id === q || value.displayName === q) {
                    this.results = [this.itemCache[key]];
                    return;
                }
            }
            this.search();
        },
        /**
         * value (a prop) can change as a result of ExpandableSuggest changing indeces. When that happens, re-initialize values.
         */
        value() {
            this.initialize(this.value);
        },
    },
};
</script>

<style lang="sass" scoped>
.suggest-wrap
    position: relative

.suggest-ul-div
    max-height: 400px
    background: #eee

.suggest-list
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
    position: absolute
    left: 0
    top: 48px
    width: 100%
    z-index: 101
    background: #ffffff
    padding: 0px !important
    overflow: scroll
    max-height: 399px

.suggest-item
    list-style: none
    border: 1px solid #EEE
    margin: 0
    padding: 0
    border-width: 0 1px 1px 1px
    padding: .4rem

    &:first-of-type
        border-top: 1px solid #EEE

.highlighted
    background: #eee
    cursor: pointer
</style>
