<script>
    import InputText from "@/components/InputText.vue";
    export default {
        extends: InputText,
        name: "InputNumber",
        props: {
            value: {
                type: [Number, String], // Used both types to make sure that if backend has saved and returning different types
                default: "",
                validator(value) {
                    return value === "" || (!isNaN(value) && isFinite(value));
                },
            },
            min: {
                type: Number,
                default: 0,
            },
            max: {
                type: Number,
                default: Infinity,
            },
        },
        data() {
            return {
                count: this.value,
            };
        },
        watch: {
            // Without watcher the count value is not updating from this.value.
            value: function (newValue) {
                this.count = newValue;
            },
        },
        computed: {
            isMinReached() {
                return this.count <= this.min;
            },
            isMaxReached() {
                return this.count >= this.max;
            },
        },
        methods: {
            stepUp() {
                if (!this.isMaxReached) {
                    this.count++;
                    this.updateValue();
                }
            },
            stepDown() {
                if (!this.isMinReached) {
                    this.count--;
                    this.updateValue();
                }
            },
            updateValue() {
                this.$emit("input", this.count);
            },
        },
    };
</script>
<template>
    <div class="input-number" :class="klass">
        <input type="text" v-model="count" @input="updateValue" :class="klass" :min="min" :max="max" v-bind="$attrs" />
        <div class="buttons" :class="klass">
            <button type="button" class="step-up" @click="stepUp" :disabled="$attrs['disabled'] || isMaxReached">
                +
            </button>
            <button type="button" class="step-down" @click="stepDown" :disabled="$attrs['disabled'] || isMinReached">
                -
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "@/style/mixins/input";

    .input-number {
        display: inline-flex;

        width: 100%;

        input {
            -moz-appearance: textfield;
            appearance: textfield;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            @include input-mixin;
            padding-right: 0;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            &.error {
                border-bottom: $input-border-error;
            }
        }

        .buttons {
            width: 22px;
            display: flex;
            flex-direction: column;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            border-top: $input-border;
            border-right: $input-border;
            border-bottom: $input-border;

            &.error {
                border-bottom: $input-border-error;
            }

            &:has(button:disabled) {
                opacity: $disabled-opacity;
            }

            button {
                height: 23px;
                border: 0;
                background-color: transparent;
                cursor: pointer;
                text-align: center;

                &:disabled {
                    cursor: default;
                }

                &.step-up {
                    border-bottom: $input-border;
                }

                &.step-down {
                }
            }
        }
    }
</style>
