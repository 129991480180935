<template>
    <div>
        <textarea
            v-if="!preview"
            :ref="name"
            :name="name"
            @blur="$emit('blur')"
            @keydown="autoExpand()"
            @paste="autoExpand()"
            @change="parseContent()"
            :maxlength="maxlength"
            class="text-area"
            v-model="_value"
            :disabled="disabled"
            :required="required"
        ></textarea>
        <span v-else class="input-preview">
            {{ _value }}
        </span>
    </div>
</template>

<script>
import TextAreaWidget from "@/components/widgets/inputs/TextAreaWidget.vue";
import { flashMessage } from "@/utils";

import autosize from "autosize";
export default {
    extends: TextAreaWidget,
    name: "ScalableTextArea",
    data: function () {
        return {
            el: null,
        };
    },
    methods: {
        parseContent() {
            if (this.filter) {
                let lengthBefore = this._value.length;
                this._value = this._value.replace(this.filter, "");
                if (lengthBefore !== this._value.length) flashMessage("Otillåtna tecken har tagits bort från texten.");
            }
            this.$emit("input", this._value);
        },
        autoExpand() {
            this.el.style.display = "none";
            autosize(this.el);
            this.el.style.display = "block";
            autosize.update(this.el);
        },
    },
    mounted() {
        this.el = this.$refs[this.name];
        this.autoExpand();
    },
    watch: {
        value() {
            this.autoExpand();
            this._value = this.value;
            this.$nextTick(() => {
                autosize.update(this.el);
            });
        },
    },
};
</script>
<style lang="sass" scoped>
textarea
    padding: 5px
    border-radius: 4px
    border: 1px solid #95A1B1
    width: 98.2%
</style>
