import { flashMessage, openDialog } from "@/utils";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { formatMessage, NonFieldErrors } from "@/store/utils";
import linkEvents from "@/linkEvents";
import emitter from "tiny-emitter/instance";

const STATUS_EMPTY = "empty";
const STATUS_MODIFIED = "modified";
const STATUS_SAVING = "saving";
const STATUS_SAVED = "saved";
const STATUS_SENDING = "sending";
const SEND_STATUSES = [STATUS_MODIFIED, STATUS_SAVED];
const WORKING_STATUSES = [STATUS_SENDING, STATUS_SAVING];

const getDefaultState = () => {
    return {
        currentId: null,
        status: STATUS_EMPTY,
        operations: [],
        currentOpAnmalan: null,
        alreadybooked: {},
        params: {},
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setStatus(state, status) {
            state.status = status;
        },
        setCurrentId(state, id) {
            state.currentId = id;
        },
        setPk(state, pk) {
            state.pk = pk;
        },
        setOpAnmalans(state, payload) {
            state.operations = payload;
        },
        setOpAnmala(state, item) {
            const index = state.operations.findIndex((i) => i.id === item.id);
            if (index !== -1) {
                state.operations[index] = item;
                state.operations = [...state.operations];
            }
        },
        setCurrentOpAnmalan(state, item) {
            state.currentOpAnmalan = item;
        },
        setPreanestesibedomningar(state, payload) {
            state.preanestesibedomningar = payload;
        },
        setAlreadyBooked(state, payload) {
            state.alreadybooked = payload;
        },
        setParams: (state, params) => (state.params = params),
    },
    getters: {
        opAnmalans(state) {
            return state.operations;
        },
        currentOpAnmalan(state) {
            return state.currentOpAnmalan;
        },
        preanestesibedomningar(state) {
            return state.preanestesibedomningar;
        },
        currentOpAnmalanId(state) {
            return state.currentId;
        },
        isSendable(state) {
            return SEND_STATUSES.includes(state.status);
        },
        isWorking(state) {
            return WORKING_STATUSES.includes(state.status);
        },
        isModified(state) {
            return state.status === STATUS_MODIFIED;
        },
        getAlreadyBooked: (state) => state.alreadybooked,
        getParams: (state) => state.params,
    },
    actions: {
        updateParams({ commit }, params) {
            commit("setParams", params);
        },
        async openOpAnmalan({ dispatch, rootState }, { component, id, patient, mode }) {
            await dispatch("tabs/closeTab", component, { root: true });
            if (patient !== rootState.patientData.currentId)
                await dispatch("patientData/loadFromBackend", { id: patient }, { root: true });
            await dispatch("tabs/openTab", { component, parameters: { id, mode } }, { root: true });
        },

        async previewOpAnmalan({ dispatch, rootState }, { component, id, patient }) {
            await dispatch("tabs/closeTab", component, { root: true });
            if (patient !== rootState.patientData.currentId)
                await dispatch("patientData/loadFromBackend", { id: patient }, { root: true });
            await dispatch("tabs/openSideTab", { component, parameters: { id, mode: "preview" } }, { root: true });
        },

        async fetchOpAnmalans({ commit, state }) {
            await klinikenApi
                .get("operation/koordinering/", getConfig({ params: state.params }))
                .then((response) => {
                    response.data.results;
                    commit("setOpAnmalans", response.data.results);
                })
                .catch((e) => {
                    flashMessage("Kunde inte uppdatera operationsregistreringar från servern. " + getErrorMessage(e));
                });
        },
        async save({ rootState, dispatch, commit, state }, details) {
            details.patient = rootState.patientData.currentId;

            const config = getConfig({}, "Operationsanmälan");
            if (!details.id) {
                await klinikenApi
                    .post("/operation/anmalan/", details, config)
                    .then(() => {
                        flashMessage("Din anmälan har skickats.", 5000);
                    })
                    .catch((error) => {
                        openDialog("Misslyckades med att skicka anmälan. ", "error");
                        throw error;
                    });
            } else {
                //delete details.created_by;
                //delete details.updated_by;
                await klinikenApi
                    .put(`/operation/anmalan/${details.id}/`, details, config)
                    .then((response) => {
                        commit("setCurrentOpAnmalan", response.data);
                        const item = state.operations.find((item) => item.id === details.id);
                        const newitem = Object.assign({}, item, response.data);
                        commit("setOpAnmala", newitem);
                        flashMessage("Operationsanmälan uppdaterad", 5000);
                        emitter.emit("operationsanmalan-uppdaterad", newitem);
                    })
                    .catch((error) => {
                        openDialog("Misslyckades med att redigera anmälan. ", "error");
                        throw error;
                    });
            }
            dispatch("fetchOpAnmalans");
        },

        async bokaOpAnmalan({ commit, state }, payload) {
            let response;
            try {
                response = await klinikenApi.patch(`operation/anmalan/${payload.id}/boka/`, payload, getConfig());

                const item = state.operations.find((item) => item.id === payload.id);
                const newitem = Object.assign({}, item, response.data);
                commit("setOpAnmala", newitem);
                commit("setCurrentOpAnmalan", newitem);
                flashMessage("Operationen bokad", 5000);
                emitter.emit("operationsanmalan-bokad", newitem);
                return newitem;
            } catch (error) {
                const { non_field_errors } = error.response ? error.response.data : {};
                const { list } = NonFieldErrors(non_field_errors, "overbokningar");
                const errorList = formatMessage(list, "overbokningar");

                if (list.length) {
                    commit("setAlreadyBooked", {
                        overbokad: true,
                        errorList: errorList,
                    });
                } else {
                    openDialog(`Kunde inte boka operationen. ${getErrorMessage(error)}`, "error");
                }
            }
        },
        resetOverbokning({ commit }) {
            commit("setAlreadyBooked", {
                overbokad: false,
                errorList: [],
            });
        },
        resetOpAnmalan({ commit }) {
            commit("setOpAnmalans", []);
        },
        async avbokaOperationAnmalan({ commit, state }, payload) {
            try {
                const response = await klinikenApi.patch(
                    `operation/anmalan/${payload.id}/avboka/`,
                    payload,
                    getConfig()
                );

                flashMessage(payload.avfors_vantelista === "NEJ" ? "Operation avbokad" : "Operation struken");

                const item = state.operations.find((item) => item.id === payload.id);
                const newitem = Object.assign({}, item, response.data);
                commit("setOpAnmala", newitem);
                linkEvents.$emit("Avbokat", newitem);
                emitter.emit("operationsanmalan-avbokad", newitem);
            } catch (error) {
                openDialog("Kunde inte avboka operationen. ", "error");
            }
        },
        async avvaktaOperationAnmalan({ commit, state }, payload) {
            try {
                const response = await klinikenApi.patch(
                    `operation/anmalan/${payload.id}/avvakta/`,
                    payload,
                    getConfig()
                );

                flashMessage("Operationen avvaktad");

                const item = state.operations.find((item) => item.id === payload.id);
                const newitem = Object.assign({}, item, response.data);
                commit("setOpAnmala", newitem);
            } catch (error) {
                openDialog("Kunde inte avvakta operationen. ", "error");
            }
        },
    },
};
