<template>
    <div class="button-group" :class="align">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "ButtonGroup.vue",
        props: {
            align: {
                type: String,
                default: "left",
                validator: function (value) {
                    return ["left", "center", "right"].indexOf(value) !== -1;
                },
            },
        },
    };
</script>

<style lang="scss" scoped>
    .button-group {
        display: flex;
        align-items: flex-end;
        gap: 18px;
        margin: 18px 0;
    }

    .center {
        justify-content: space-around;
    }

    .right {
        justify-content: flex-end;
    }
</style>
