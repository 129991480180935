<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import dialogEvents from "@/dialogEvents";
import { openDialog } from "@/utils";
import { mapActions } from "vuex";
import { cloneDeep, isEqual } from "lodash";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";

const defaultAktivitetstyp = {
    id: undefined,
    namn: "",
    typ: "",
};
export default {
    extends: BaseTab,
    name: "FormAktivitetstyp",
    tabName: "Aktivitetstyp",
    components: { ButtonGroup, Button },

    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            defaultAktivitetstyp: cloneDeep(defaultAktivitetstyp),
            aktivitetstyp: cloneDeep(defaultAktivitetstyp),
            formErrors: [],
            inputErrors: {},
        };
    },

    computed: {
        hasUnsavedData() {
            return !isEqual(this.defaultAktivitetstyp, this.aktivitetstyp);
        },
        heading() {
            return this.aktivitetstyp.id === undefined ? "Skapa ny aktivitetstyp" : "Ändra på aktivitetstyp";
        },
    },

    methods: {
        ...mapActions("skreg/aktivitetstyper", ["save"]),
        async submit(data) {
            try {
                await this.save(data);
                await this.$store.dispatch("tabs/closeTab", this.tabId);
            } catch (e) {
                if (e.response) {
                    this.formErrors = e.response.data["non_field_errors"];
                    this.inputErrors = e.response.data;
                }
                openDialog("Kunde inte spara aktivitetstyp", "error");
            }
        },
        cancel() {
            this.closeTab(this.hasUnsavedData);
        },
    },

    async created() {
        if (this.parameters !== undefined && "aktivitetstyp" in this.parameters) {
            this.defaultAktivitetstyp = cloneDeep(this.parameters["aktivitetstyp"]);
            this.aktivitetstyp = cloneDeep(this.parameters["aktivitetstyp"]);
        }
    },
};
</script>

<template>
    <FormulateForm v-model="aktivitetstyp" :form-errors="formErrors" :errors="inputErrors" @submit="submit">
        <h2>{{ heading }}</h2>

        <FormulateInput name="namn" label="Namn" type="text" validation="required" />

        <FormulateInput
            name="typ"
            label="Typ"
            type="select"
            validation="required"
            :options="['', 'Träning', 'Match']"
        />

        <ButtonGroup>
            <Button size="primary" role="button" type="submit" :disabled="!hasUnsavedData">Spara och stäng</Button>
            <Button @click="cancel()" size="secondary" type="button">Avbryt</Button>
        </ButtonGroup>
    </FormulateForm>
</template>

<style lang="scss" scoped>
.control {
    span {
        display: block;
    }

    &.invalid {
        label,
        input,
        span {
            color: #9e354b;
        }

        input {
            border: 1px #9e354b solid;
        }
    }

    &.required {
        label:after {
            content: " *";
        }
    }
}
</style>
