import CheckboxWidget from "@/components/widgets/inputs/CheckboxWidget.vue";
import DateWidget from "@/components/widgets/inputs/DateWidget.vue";
import ExpandableInfiniteWidget from "@/components/widgets/inputs/ExpandableInfiniteWidget.vue";
import ExpandableSelect from "@/components/widgets/inputs/ExpandableSelect.vue";
import ExpandableSuggest from "@/components/widgets/inputs/ExpandableSuggest.vue";
import HiddenWidget from "@/components/widgets/inputs/HiddenWidget.vue";
import InfiniteWidget from "@/components/widgets/inputs/InfiniteWidget.vue";
import NumberWidget from "@/components/widgets/inputs/NumberWidget.vue";
import PersonIdWidget from "@/components/widgets/inputs/PersonIdWidget.vue";
import PopoutExpandableSuggestWidget from "@/components/widgets/inputs/PopoutExpandableSuggestWidget.vue";
import PopoutSuggestWidget from "@/components/widgets/inputs/PopoutSuggestWidget.vue";
import RadioButtonWidget from "@/components/widgets/inputs/RadioButtonWidget.vue";
import ReadOnlyTextWidget from "@/components/widgets/inputs/ReadOnlyTextWidget.vue";
import ScalableTextArea from "@/components/widgets/inputs/ScalableTextArea.vue";
import SelectWidget from "@/components/widgets/inputs/SelectWidget.vue";
import SocialSecurityNumber from "@/components/widgets/inputs/SocialSecurityNumber.vue";
import SuggestTextArea from "@/components/widgets/inputs/SuggestTextArea.vue";
import SuggestWidget from "@/components/widgets/inputs/SuggestWidget.vue";
import TextAreaWidget from "@/components/widgets/inputs/TextAreaWidget.vue";
import TextWidget from "@/components/widgets/inputs/TextWidget.vue";
import SimpleCheckboxWidget from "@/components/widgets/inputs/SimpleCheckboxWidget.vue";
import SimpleSuggestWidget from "@/components/widgets/inputs/SimpleSuggestWidget.vue";

export default {
    CheckboxWidget,
    DateWidget,
    ExpandableInfiniteWidget,
    ExpandableSelect,
    ExpandableSuggest,
    HiddenWidget,
    InfiniteWidget,
    NumberWidget,
    PersonIdWidget,
    PopoutExpandableSuggestWidget,
    PopoutSuggestWidget,
    RadioButtonWidget,
    ReadOnlyTextWidget,
    ScalableTextArea,
    SelectWidget,
    SocialSecurityNumber,
    SuggestTextArea,
    SuggestWidget,
    TextAreaWidget,
    TextWidget,
    SimpleCheckboxWidget,
    SimpleSuggestWidget,
};
