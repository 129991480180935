import dialogEvents from "@/dialogEvents";
import { getConfig } from "@/api";
import OperationsjournalSign from "@/tabs/Operation/OperationsjournalSign.vue";

const STATUS_EMPTY = "empty";
const STATUS_SIGNED = "signed";
const STATUS_SIGNING = "signing";
const STATUS_SIGN_FAILED = "sign failed";

const waitForPoll = async ({ state }) => {
    let failsafeCounter = 300; // Hard timeout of 5 minutes/300 seconds
    while (state.polling && failsafeCounter--) {
        await pollTimeout();
    }
    return new Promise((resolve) => resolve());
};

const pollTimeout = () => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
};

const getDefaultState = () => {
    return {
        status: STATUS_EMPTY,
        polling: false,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setPolling(state, polling) {
            state.polling = polling;
        },
        setStatus(state, status) {
            state.status = status;
        },
    },
    getters: {
        status(state) {
            return state.status;
        },
    },

    actions: {
        async waitForPoll({ state, dispatch }) {
            let failsafeCounter = 300; // Hard timeout of 5 minutes/300 seconds
            while (state.polling && failsafeCounter--) {
                await dispatch("pollTimeout");
            }

            return new Promise((resolve) => resolve());
        },

        pollTimeout() {
            return new Promise((resolve) => setTimeout(resolve, 1000));
        },
        async sign({ commit, state, dispatch }, { url, data }) {
            const config = getConfig({}, "OperationsUppgifter");
            commit("setStatus", STATUS_SIGNING);
            let apiUrl = url;

            let errors = {};
            let responseData = {};

            commit("setPolling", true);

            dialogEvents.$emit("openPopup", {
                title: "Signera operationsjournal",
                component: OperationsjournalSign,
                dismissable: false,
                data: {
                    config: config,
                    params: data,
                    url: apiUrl,
                    callback: (data) => {
                        commit("setPolling", false);
                        commit("setStatus", STATUS_SIGNED);
                        if (data.is_signed) {
                            let component = OperationsjournalSign;
                            dispatch("tabs/closeTab", component, { root: true });
                            responseData = data;
                        }
                    },
                    errorCallback: (e) => {
                        commit("setPolling", false);
                        commit("setStatus", STATUS_SIGN_FAILED);
                        errors = e.response.data;
                    },
                },
            });

            await waitForPoll({ state });
            return new Promise((resolve, reject) => {
                try {
                    if (state.status === STATUS_SIGNED) {
                        return resolve(responseData);
                    } else if (state.status === STATUS_SIGN_FAILED) {
                        return reject(errors);
                    }
                } catch (error) {
                    throw new Error(error);
                }
            });
        },
    },
};
