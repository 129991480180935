<template>
    <div class="range">
        <!--
            Problem: IE10 does not support spinners in input type number at all, not even custom CSS.
            If value changes due to using spinners, this will _not_ trigger onchange or oninput.
            Added code to emit onclick with event.target.value set on spinner use.
            Listening to spinner clicks in parent can work as well, but the DOM parent/child relationship
            between the buttons and the input must be fixed first or everything given unique names.
            This code is more flexible but less elegant.
            TODO: replace anonymous custom object with CustomEvent()?
        -->
        <input
            :disabled="disabled_"
            :tabindex="tabindex"
            type="number"
            :min="min_"
            :max="max_"
            :name="name"
            :step="step"
            :required="required_"
            v-model.number="value_"
            @focus="hasFocus(true)"
            @blur="hasFocus(false)"
            @keydown.down="decrease()"
            @keydown.up="increase()"
            @change="validateRange()"
            @keydown="inputTypeNumber($event)"
        />
        <span class="increase-decrease">
            <button type="button" @click="increase" tabindex="-1"></button>
            <button type="button" @click="decrease" tabindex="-1"></button>
        </span>
    </div>
</template>
<script>
import linkEvents from "@/linkEvents";

export default {
    name: "RangeWidget",
    props: {
        name: String,
        min: Number,
        max: Number,
        required: Boolean,
        step: undefined,
        value: undefined, // Can be String or Number
        tabindex: Number,
        disabled: Boolean,
    },
    data() {
        return {
            value_: isNaN(parseFloat(this.value)) ? undefined : parseFloat(this.value),
            step_: this.step ? parseFloat(this.step) : 1,
            min_: this.min,
            max_: this.max,
            focus: false,
            disabled_: this.disabled,
            required_: this.required,
        };
    },
    computed: {
        stepDecimals() {
            return this.step_ % 1 ? this.step_.toString().split(".")[1].length : 0;
        },
    },
    methods: {
        increase() {
            if (this.value_ == "" || isNaN(this.value_)) {
                this.value_ = 0;
            }
            this.value_ += this.step_;
            this.validateRange();
            /*
            this.valueChanged({
                target: {
                    value: this.value_
                }
            });
            */
        },
        decrease() {
            if (this.value_ == "" || isNaN(this.value_)) this.value_ = 0;
            this.value_ -= this.step_;
            this.validateRange();
            /*
            this.valueChanged({
                target: {
                    value: this.value_
                }
            });
            */
        },

        /*
            Emulate number field in IE10, not necessary in Chrome.
            IE10 removes illegal characters on blur, this will prevent their input like Chrome.
        */
        inputTypeNumber(event) {
            var keyChar = event.charCode || event.keyCode;
            if (keyChar == 13 || keyChar == 9 || keyChar == 8 || keyChar == 46 || keyChar == 37 || keyChar == 39)
                // enter or tab, delete or backspace, left or right arrow
                return true;
            else if (keyChar == 188 || keyChar == 190) {
                // if '.' or ','
                if (this.stepDecimals === 0)
                    // check for whole number
                    event.preventDefault();
                else return true;
            } else if (keyChar >= 48 || keyChar <= 57) {
                return true;
            } else if (keyChar >= 96 || keyChar <= 105) {
                return true;
            } else {
                event.preventDefault();
            }
        },
        validateRange() {
            this.value_ = parseFloat(this.value_);

            if (isNaN(this.value_)) {
                this.value_ = "";
                this.valueChanged({ target: { value: this.value_ } });
                return true;
            }

            if (this.value_ < this.min_) this.value_ = this.min_;
            if (this.value_ > this.max_) this.value_ = this.max_;

            // TODO: add check to only allow multiples of step_
            var intermediateValue = this.value_;
            if (this.stepDecimals > 0) intermediateValue = parseFloat(intermediateValue.toFixed(this.stepDecimals));
            this.value_ = intermediateValue;

            this.valueChanged({ target: { value: this.value_ } });
        },
        update({ required, min, max, value, disabled }) {
            if (value !== undefined && value !== null) this.value_ = value;
            if (min !== undefined && min !== null) this.min_ = min;
            if (max !== undefined && max !== null) this.max_ = max;
            if (disabled !== undefined && disabled !== null) this.disabled_ = disabled;
            if (required) this.required_ = true;
            this.validateRange();
        },
        valueChanged(event) {
            linkEvents.$emit(this.name + "_changed", event.target.value);
        },
        hasFocus(focus) {
            if (focus)
                // first time input gets focus
                this.focus = true;
            if (!focus && this.focus)
                // every time input loses focus
                linkEvents.$emit(this.name + "_focus");
        },
    },
    created() {
        linkEvents.$on("update_" + this.name, this.update);
    },
};
</script>

<style lang="sass" scoped>
.range
    display: flex

    input
        border-top-right-radius: 0px !important
        border-bottom-right-radius: 0px !important
        border-right: none

    .increase-decrease
        display: flex
        flex-direction: column
        background-image: url('@/assets/plus_minus.svg')
        border: 1px solid #95A1B1
        border-left: none
        border-bottom-right-radius: 4px !important
        border-top-right-radius: 4px !important
        background-color: #FFFFFF

    button
        opacity: 0
        //background-color: white
        //border: 1px solid #95A1B1
        //border-left: none
        height: 22px
        width: 22px
        flex: 0 0 auto
        border: none
        //&:first-of-type
        //    border-top-right-radius: 4px !important
        //&:last-of-type
        //    border-bottom-right-radius: 4px !important
        //    border-top: 0px

//Supposed to only target IE10, but unfortunately targets IE11 as well.
@media screen and (min-width:0/0)
    .range
        margin-right: 22px

// Hide spinners in Firefox
input[type=number]
    -moz-appearance: textfield

// Hide spinners in other browsers
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0
</style>
