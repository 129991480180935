<template>
    <div :class="`col-${span}`">
        <slot></slot>
    </div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";

interface GridItemProps {
    span?: number;
}

const props = withDefaults(defineProps<GridItemProps>(), {
    span: 1,
});

const { span } = props;
</script>

<style lang="scss" scoped>
/* Base grid row styles */
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    display: grid;
}

.col-1 {
    grid-column: span 1;
}
.col-2 {
    grid-column: span 2;
}
.col-3 {
    grid-column: span 3;
}
.col-4 {
    grid-column: span 4;
}
.col-5 {
    grid-column: span 5;
}
.col-6 {
    grid-column: span 6;
}
.col-7 {
    grid-column: span 7;
}
.col-8 {
    grid-column: span 8;
}
.col-9 {
    grid-column: span 9;
}
.col-10 {
    grid-column: span 10;
}
.col-11 {
    grid-column: span 11;
}
.col-12 {
    grid-column: span 12;
}
</style>
