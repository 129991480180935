<template>
    <div>
        <admin-lock-border />
        <h2>
            Resurser
            <hollow-button :icon="PlusCircleBlueIcon" label="Skapa ny resurs" @click="openForm()" />
        </h2>

        <custom-action-table
            class="no-top"
            :selectableRows="false"
            :columns="TableColumns"
            :items="resurser"
            :actions="RowActions"
        >
        </custom-action-table>
    </div>
</template>

<script>
    import BaseTab from "@/tabs/mixins/BaseTab";
    import CustomActionTable from "@/components/generic/CustomActionTable.vue";
    import Resurs from "@/tabs/Operation/Admin/Resurs.vue";
    import { mapActions, mapGetters } from "vuex";
    import tabIcon from "@/assets/blank.png";
    import AdminLockBorder from "@/components/AdminLockBorder.vue";
    import HollowButton from "@/components/widgets/HollowButton.vue";
    import PlusCircleBlueIcon from "@/assets/plus_circle_blue.svg";

    const TABLE_COLUMNS = [
        {
            name: "namn",
            title: "Namn",
            template: (item) => {
                return item.namn || "";
            },
        },
        {
            name: "totalt_antal",
            title: "Totalt antal",
            template: (item) => {
                return `${item.totalt_antal}`;
            },
        },
    ];

    const ROW_ACTIONS = [
        {
            name: "edit",
            title: "Redigera",
            img: "edit.svg",
            condition: () => {
                return true;
            },
        },
    ];

    export default {
        extends: BaseTab,
        name: "ListResurs",
        tabName: "Resurser",
        icon: tabIcon,
        components: {
            HollowButton,
            AdminLockBorder,
            CustomActionTable,
        },
        props: ["tabId"],
        data() {
            return {
                PlusCircleBlueIcon,
                TableColumns: TABLE_COLUMNS,
                RowActions: ROW_ACTIONS,
            };
        },
        computed: {
            ...mapGetters("resurs", ["resurser"]),
        },
        methods: {
            ...mapActions("resurs", ["load"]),
            openForm() {
                this.$store.dispatch("tabs/openTab", { component: Resurs });
            },
        },
        async created() {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            try {
                await this.load();
            } catch (e) {
                //eslint-disable-next-line
                console.error(e);
            }
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });

            this.$on("edit", async (data) => {
                await this.$store.dispatch("tabs/openTab", {
                    component: Resurs,
                    parameters: data,
                });
            });
        },
        beforeDestroy() {
            this.$store.commit("resurs/reset");
        },
    };
</script>

<style lang="scss" scoped>
    h2 {
        .hollow-button {
            margin-left: 50px;
        }
    }
</style>
