<template>
    <form @submit.prevent="save" class="samtyckesform">
        <h2>{{ heading }}</h2>
        <div v-if="typerAvSamtycke.length > 0">
            <div class="typavsamtycke-wrapper">
                <input-wrapper
                    :component="widgets.SelectWidget"
                    :disabled="editMode"
                    :required="editMode"
                    :options="getTyperAvSamtycke"
                    name="typavsamtycke"
                    label="Typ av samtycke"
                    v-model="patientsamtycke.typavsamtycke"
                    @update="typAvSamtyckeChanged"
                />
            </div>

            <div v-if="patientsamtycke.typavsamtycke">
                <div class="samtycke">
                    <h3>{{ valdTypAvSamtycke?.namn }}</h3>

                    <p class="beskrivning" v-if="valdTypAvSamtycke?.beskrivning">
                        {{ valdTypAvSamtycke?.beskrivning }}
                    </p>

                    <p class="formulering" v-if="valdTypAvSamtycke?.samtyckesformulering">
                        {{ valdTypAvSamtycke?.samtyckesformulering }}
                    </p>
                </div>

                <input-wrapper
                    class="input-double-size"
                    :component="widgets.SelectWidget"
                    name="svar"
                    label="Patientens samtycke"
                    :disabled="editMode"
                    :required="true"
                    :options="svarOptions"
                    v-model="patientsamtycke.svar"
                    @update="svarChanged"
                />

                <input-wrapper
                    class="input-double-size"
                    :component="widgets.SelectWidget"
                    name="inhamtningssatt"
                    label="Inhämtningssätt"
                    :disabled="!patientsamtycke.svar || patientsamtycke.svar === 'ej informerad'"
                    :required="patientsamtycke.svar !== 'ej informerad'"
                    :options="inhamtningssattOptions"
                    v-model="patientsamtycke.inhamtningssatt"
                />

                <input-wrapper
                    :component="widgets.DateWidget"
                    name="inhamtat_datum"
                    label="Inhämtat datum"
                    :disabled="!patientsamtycke.svar || patientsamtycke.svar === 'ej informerad'"
                    :required="patientsamtycke.svar !== 'ej informerad'"
                    v-model="patientsamtycke.inhamtat_datum"
                />

                <input-wrapper
                    :component="widgets.DateWidget"
                    name="giltigt_till"
                    label="Giltigt till datum"
                    :required="valdTypAvSamtycke?.giltigt_till_kravd"
                    v-model="patientsamtycke.giltigt_till"
                    not-before="today"
                    v-if="valdTypAvSamtycke?.giltigt_till_visa"
                />

                <div class="inhamtat">
                    <input-wrapper
                        :component="widgets.InfiniteWidget"
                        name="inhamtat_av_profil"
                        label="Inhämtat av"
                        :disabled="
                            !patientsamtycke.svar || inhamtat_av_annan || patientsamtycke.svar === 'ej informerad'
                        "
                        :required="!inhamtat_av_annan && patientsamtycke.svar !== 'ej informerad'"
                        v-model="patientsamtycke.inhamtat_av_profil"
                        key="inhamtatAv"
                        apisearch="/core/profiles/"
                        :extraParams="{ is_active: true }"
                        :map="(el) => ({ id: el.pk, code: el.user.name, displayName: el.user.name })"
                    />

                    <input-wrapper
                        :component="widgets.CheckboxWidget"
                        name="annan"
                        label="Inhämtat av annan"
                        :disabled="!patientsamtycke.svar || patientsamtycke.svar === 'ej informerad'"
                        :required="false"
                        v-model="inhamtat_av_annan"
                    />
                </div>

                <input-wrapper
                    class="input-double-size"
                    :component="widgets.TextAreaWidget"
                    label="Ange vem som inhämtat"
                    name="inhamtat_av_annan"
                    :required="inhamtat_av_annan"
                    :maxlength="140"
                    :filter="/[^\u0020-\u007E\u00A1-\u00FF]+/g"
                    v-model="patientsamtycke.inhamtat_av_annan"
                    v-if="inhamtat_av_annan"
                />
            </div>
        </div>

        <ButtonGroup>
            <Button size="primary" type="submit" :disabled="!hasUnsavedData">
                <img src="@/assets/send_white.svg" alt="" /> Spara
            </Button>
            <Button size="secondary" variant="contour" type="button" @click="cancel()">Avbryt</Button>
        </ButtonGroup>
    </form>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import { mapActions, mapGetters } from "vuex";
import { flashMessage } from "@/utils";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import widgets from "@/components/widgets/inputs";
import { cloneDeep, isEqual } from "lodash";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";
import moment from "moment";

const defaultPatientsamtycke = {
    id: "",
    typavsamtycke: null,
    anvandarkonfirmation: true,
    inhamtningssatt: null,
    inhamtat_datum: "",
    svar: "",
    inhamtat_av_profil: null,
    inhamtat_av_annan: null,
};

const AKTIVA_SVAR = [
    { text: "Samtycker", value: "samtycker" },
    { text: "Samtycker ej", value: "samtycker ej" },
];

const PASSIVA_SVAR = [
    { text: "Ej informerad", value: "ej informerad" },
    { text: "Informerad", value: "informerad" },
    { text: "Motsätter sig", value: "motsätter sig" },
];

const AKTIVA_INHAMTNINGSSATT = [
    { text: "Skriftligen", value: 1 },
    { text: "Muntligen", value: 2 },
    { text: "Enkät", value: 3 },
];

export default {
    extends: BaseTab,
    patientBound: true,
    name: "SamtyckesForm",
    tabName: "Lägg till samtycke",

    components: {
        Button,
        ButtonGroup,
        InputWrapper,
        ...widgets,
    },

    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            widgets: widgets,
            defaultPatientsamtycke: cloneDeep(defaultPatientsamtycke),
            patientsamtycke: cloneDeep(defaultPatientsamtycke),
            svarOptions: [],
            inhamtningssattOptions: [],
            inhamtat_av_annan: false,
        };
    },

    computed: {
        ...mapGetters("patientData", ["patient"]),
        ...mapGetters("samtycke", ["typerAvSamtycke"]),
        hasUnsavedData() {
            return !isEqual(this.defaultPatientsamtycke, this.patientsamtycke);
        },
        heading() {
            return this.parameters === undefined ? "Lägg till ett samtycke" : "Redigera ett samtycke";
        },
        editMode() {
            return this.parameters?.editMode;
        },
        getTyperAvSamtycke() {
            return this.typerAvSamtycke.map((el) => ({ text: el.namn, value: el.id, beskrivning: el.beskrivning }));
        },
        valdTypAvSamtycke() {
            return this.typerAvSamtycke.find((item) => item.id === this.patientsamtycke.typavsamtycke);
        },
    },

    methods: {
        ...mapActions("samtycke", ["fetchTyperAvSamtycke", "savePatientsamtycke", "updatePatientsamtycke"]),
        cancel() {
            this.closeTab(this.hasUnsavedData);
        },
        async save() {
            if (this.patientsamtycke.inhamtat_datum === "") {
                this.patientsamtycke.inhamtat_datum = null;
            }

            if (this.inhamtat_av_annan) {
                this.patientsamtycke.inhamtat_av_profil = null;
            } else {
                this.patientsamtycke.inhamtat_av_annan = null;
            }

            let data = cloneDeep(this.patientsamtycke);
            data.patient = this.patient.id;

            let response;
            if (this.parameters) {
                response = await this.updatePatientsamtycke(data);
            } else {
                response = await this.savePatientsamtycke(data);
            }

            if (!response) return;
            if (response.status === 201 || response.status === 200) {
                await this.$store.dispatch("samtycke/loadPatientsamtycken", this.patient.id);
                await this.$store.dispatch("tabs/closeTab", this.tabId);
                flashMessage("Samtycke sparat");
            }
        },
        typAvSamtyckeChanged() {
            // Nollställ valt svar, inhämtningssätt, datum och inhämtat av
            this.patientsamtycke.svar = null;
            this.patientsamtycke.inhamtningssatt = null;
            this.patientsamtycke.inhamtat_datum = null;
            this.patientsamtycke.inhamtat_av_profil = null;
            this.inhamtat_av_annan = false;
            this.patientsamtycke.inhamtat_av_annan = null;
            if (this.valdTypAvSamtycke.samtyckestyp === "passivt") {
                this.svarOptions = PASSIVA_SVAR;
            } else {
                this.svarOptions = AKTIVA_SVAR;
            }
            this.inhamtningssattOptions = [];
        },
        svarChanged() {
            if (this.patientsamtycke.svar === "ej informerad") {
                this.patientsamtycke.inhamtningssatt = null;
                this.inhamtningssattOptions = [];
                this.patientsamtycke.inhamtat_datum = null;
                this.patientsamtycke.inhamtat_av_profil = null;
                this.inhamtat_av_annan = false;
                this.patientsamtycke.inhamtat_av_annan = null;
            } else if (this.patientsamtycke.svar === "informerad") {
                this.inhamtningssattOptions = [{ text: "Enligt rutin", value: 4 }];
                this.patientsamtycke.inhamtningssatt = null;
                // Sätt föreslaget inhämtat datum till dagen då patienten skapades
                this.patientsamtycke.inhamtat_datum = moment(this.patient.created_at).format("YYYY-MM-DD");
                // Sätt inhämtat av till Automatiskt
                this.patientsamtycke.inhamtat_av_profil = null;
                this.inhamtat_av_annan = true;
                this.patientsamtycke.inhamtat_av_annan = "Automatiskt";
            } else {
                this.inhamtningssattOptions = AKTIVA_INHAMTNINGSSATT;
                // Sätt föreslaget inhämtat datum till idag
                this.patientsamtycke.inhamtat_datum = moment().format("YYYY-MM-DD");
                // Nollställ inhämtat av
                this.patientsamtycke.inhamtat_av_profil = null;
                this.inhamtat_av_annan = false;
                this.patientsamtycke.inhamtat_av_annan = null;
            }
        },
    },

    async created() {
        await this.fetchTyperAvSamtycke();
        if (this.parameters) {
            // Hämtar svarsalternativ beroende på typ av samtycke
            const typAvSamtycke = this.typerAvSamtycke.find((item) => item.id === this.parameters.typavsamtycke);
            if (typAvSamtycke.samtyckestyp === "passivt") {
                this.svarOptions = PASSIVA_SVAR;
            } else {
                this.svarOptions = AKTIVA_SVAR;
            }
            // Hämtar inhämtningssätt beroende på svar
            if (this.parameters.svar === "ej informerad") {
                this.inhamtningssattOptions = [];
            } else if (this.parameters.svar === "informerad") {
                this.inhamtningssattOptions = [{ text: "Enligt rutin", value: 4 }];
            } else {
                this.inhamtningssattOptions = AKTIVA_INHAMTNINGSSATT;
            }

            this.defaultPatientsamtycke = cloneDeep(this.parameters);
            this.patientsamtycke = cloneDeep(this.parameters);
            this.inhamtat_av_annan = !this.patientsamtycke.inhamtat_av_profil;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/deprecated_main";

.samtyckesform {
    max-width: 686px;

    .typavsamtycke-wrapper {
        background-color: #ebf6f8;
        padding: 15px 10px;
        margin-top: 30px;
    }

    .samtycke {
        margin: 30px 0;

        .beskrivning {
            white-space: pre-line;
            margin-bottom: 30px;
        }

        .formulering {
            white-space: pre-line;
            margin-bottom: 15px;
        }
    }

    .input-double-size {
        width: 66.7%;
    }

    .inhamtat {
        display: flex;
        gap: 20px;
    }
}
</style>
