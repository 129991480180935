<template>
    <NetIDForm @submit="submit" @cancel="cancel" />
</template>

<script setup>
    import { getConfig, klinikenApi } from "@/api";
    import { defineEmits } from "vue";
    import NetIDForm from "@/components/Auth/NetIDForm.vue";

    const emit = defineEmits(["poll", "cancel"]);

    const submit = async () => {
        try {
            const response = await klinikenApi.post("auth/netid/init", {}, getConfig());
            window.location = response.data.redirectUrl;
            emit("poll", {
                message:
                    "Net ID Access-inloggningen öppnas i ett nytt fönster. Du kan behöva godkänna en förfrågan från webbläsaren om att öppna fönstret. Glöm inte att sätta i ditt kort för att legitimera dig.",
                url: "auth/netid/poll",
                data: response.data,
                type: "netid",
            });
        } catch (error) {
            if (error.response.status === 400) {
                errors.value = error.response.data;
            } else {
                emit("error", "Det gick inte att logga in.");
            }
        }
    };

    const cancel = () => {
        emit("cancel");
    };
</script>

<style lang="scss">
    .netid-form {
        h3 {
            color: #354052;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 28px;
            text-align: center;
        }
    }
</style>
