<template>
    <div class="admin-lock-border"><img src="@/assets/admin_lock_dark.svg" />Admin</div>
</template>

<script>
export default {
    name: "AdminLockBorder",
};
</script>

<style lang="scss" scoped>
.admin-lock-border {
    height: 26px;
    width: 350px;
    margin-bottom: 18px;
    background: linear-gradient(134.72deg, #c2e6e6 0%, rgba(194, 230, 228, 0) 100%);
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    color: #277692;
    letter-spacing: 0.46px;
    line-height: 26px;
    text-transform: uppercase;

    img {
        margin: -2px 6px 0px 17px;
    }
}
</style>
