<template>
    <section class="prescription-set-action-table-wrapper">
        <div class="recipe-header" v-if="activeView === 0">
            <svg v-if="isIE" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="24" class="svg-recipe-icon">
                <image
                    x="0"
                    y="0"
                    xlink:href="@/assets/tidigare_recept.svg"
                    class="recipe-icon"
                    width="18"
                    height="24"
                />
            </svg>
            <img v-else class="recipe-icon" src="@/assets/tidigare_recept.svg" />
            <h2>Tidigare recept</h2>
        </div>
        <div class="product-selection">
            <div class="active">
                <prescription-set-action-table
                    v-for="prescriptionSet in prescriptionSets"
                    :q="q"
                    :key="prescriptionSet.pk + '-' + prescriptionSet.teknisk_version"
                    :columns="columns"
                    :data="prescriptionSet"
                    :id="'prescription-set-' + prescriptionSet.pk"
                    class="table-with-wrapper group"
                    :actions="actions"
                    :group-actions="groupActions"
                    :label-actions="getLabelActions(prescriptionSet)"
                    :label="getLabel(prescriptionSet)"
                    :required-fields="requiredFields"
                    :disabled-row-handler="disabledRowHandler"
                    :collapse-label="getStatusText(prescriptionSet)"
                    :current-profile="currentProfile"
                    @prescription-set-read="addToPrintCache($event)"
                    :ref="prescriptionSet.pk"
                    @setPrescriptionSetSelected="setPrescriptionSetSelected"
                    @prescriptionItemDetailsFetched="prescriptionItemDetailsFetched"
                />
            </div>
        </div>
        <button
            role="button"
            class="btn btn-outline-secondary show-all-button"
            @click="$emit('show-all-previous')"
            v-if="previousPrescriptionsLength > 5 && activeView === 0"
        >
            Visa alla tidigare recept ({{ previousPrescriptionsLength }})
        </button>
        <ButtonGroup v-if="activeView == 2">
            <Button type="button" size="secondary" variant="standard" @click="printReceptlistaSelected()">
                Skriv ut markerade recept som lista
            </Button>
            <Button type="button" size="secondary" variant="standard" @click="printReceptlistaAll()">
                Skriv ut alla recept som lista
            </Button>
        </ButtonGroup>
    </section>
</template>
<script>
    import { openDialog } from "@/utils";
    import PrescriptionSetActionTable from "@/tabs/PrescriptionSet/PrescriptionSetActionTable.vue";
    import { mapActions, mapGetters } from "vuex";
    import Button from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import dialogEvents from "@/dialogEvents";

    export default {
        components: {
            PrescriptionSetActionTable,
            Button,
            ButtonGroup,
        },
        props: {
            activeView: Number,
            prescriptionSets: Array,
            q: String,
            columns: Array,
            actions: Array,
            groupActions: Array,
            labelActions: Array,
            label: String,
            requiredFields: Array,
            disabledRowHandler: Function,
            collapseLabel: String,
            currentProfile: String,
            previousPrescriptionsLength: Number,
            itemsLength: Number,
            sentLabelActions: Array,
            cancelledLabelActions: Array,
            previousLabelActions: Array,
            actionTableAddHandler: Function,
            actionTableEditHandler: Function,
            actionTableViewHandler: Function,
            actionTableCancelHandler: Function,
            actionTableHistoryHandler: Function,
            tabId: Number,
        },
        data() {
            return {
                printCache: {},
                selectedPrescriptionSets: [],
            };
        },
        computed: {
            ...mapGetters("patientData", ["patient"]),
            isIE() {
                var ua = navigator.userAgent;
                /* MSIE used to detect old browsers and Trident used to newer ones*/
                return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
            },
            allPrescriptionSetsRead() {
                return Object.keys(this.printCache).length === this.prescriptionSets.length;
            },
        },
        methods: {
            ...mapActions("prescriptionSet", ["sendSMSOnPrescription"]),
            prescriptionItemDetailsFetched(responseData) {
                this.$emit("prescriptionItemDetailsFetched", responseData);
            },
            setPrescriptionSetSelected(pk, selected) {
                if (selected) {
                    if (!this.selectedPrescriptionSets.includes(pk)) {
                        this.selectedPrescriptionSets.push(pk);
                    }
                } else {
                    let i = this.selectedPrescriptionSets.indexOf(pk);
                    if (i > -1) {
                        this.selectedPrescriptionSets.splice(i, 1);
                    }
                }
            },
            addToPrintCache(prescriptionSet) {
                this.$set(this.printCache, prescriptionSet.pk, prescriptionSet);
            },
            getStatusText(prescriptionSet) {
                // Case 1: PrescriptionSet, identified by patient since PrescriptionSet always have that value
                if (prescriptionSet.patient) {
                    let returnString = prescriptionSet.sent_at
                        ? "Skickat " +
                          prescriptionSet.sent_at.split("T")[0] +
                          " av " +
                          prescriptionSet.sent_by.user.name
                        : "Sparat " +
                          prescriptionSet.authorTime.split("T")[0] +
                          " av " +
                          (prescriptionSet.accountableHealthcareProfessional
                              ? prescriptionSet.accountableHealthcareProfessional.user.name
                              : "[Saknas]");
                    returnString += " (" + prescriptionSet.prescriptionItem_count + ")";

                    return returnString;
                }
                // Case 2: Favorit
                else {
                    return (
                        prescriptionSet.namn +
                        " skapad av " +
                        prescriptionSet.anvandare.name +
                        " (" +
                        prescriptionSet.favoritrader.length +
                        ")"
                    );
                }
            },
            getLabel(prescriptionSet) {
                let label = {};
                label.text = "SPARAT";
                label.colour = "#859BA3";
                if (prescriptionSet.is_signed) {
                    label.text = "SIGNERAT";
                    label.colour = "#34AC8D";
                }
                if (prescriptionSet.is_sent) {
                    label.text = "SKICKAT";
                    label.colour = "#34AC8D";
                }
                let cancelled = parseInt(prescriptionSet.cancelled);
                if (cancelled === 1) {
                    label.text = "PART MAKULERAT";
                    label.colour = "#ff9933";
                } else if (cancelled === 2 || prescriptionSet.is_nullified) {
                    label.text = "MAKULERAT";
                    label.colour = "#CB1339";
                }
                if (prescriptionSet.is_printed) {
                    label.text = "UTSKRIVET";
                    label.colour = "#3399ff";
                }
                return label;
            },
            getLabelActions(prescriptionSet) {
                let sent = prescriptionSet.is_sent;
                let cancelled = prescriptionSet.cancelled;
                let printed = prescriptionSet.is_printed;
                let nullified = prescriptionSet.is_nullified;
                if ((sent || printed) && (cancelled !== 2 || !nullified))
                    // TODO: Dubbelkolla den här. Cancelled är om skickad, nullified är om inte.
                    return this.itemsLength === 0 ? this.sentLabelActions : this.sentLabelActions.slice(0, 1);
                else if (cancelled === 2 || nullified)
                    return this.itemsLength === 0 ? this.cancelledLabelActions : this.cancelledLabelActions.slice(0, 1);
                else return this.itemsLength === 0 ? this.previousLabelActions : this.previousLabelActions.slice(0, 1);
            },
            async printReceptlistaAll() {
                this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
                for (let i = 0; i < this.prescriptionSets.length; i++) {
                    this.$refs[this.prescriptionSets[i].pk][0].fetchAndToggle();
                }
                let failsafeCounter = 60;
                while (!this.allPrescriptionSetsRead && failsafeCounter--) {
                    await this.waitTimeout();
                }

                if (!this.allPrescriptionSetsRead) {
                    this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
                    openDialog("Kunde inte läsa alla recept, avbryter utskrift", "error");
                    return;
                }

                await this.$store.dispatch("prescriptionSet/printReceptlista", {
                    ids: Object.keys(this.printCache),
                    patient: this.patient,
                });
                this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
            },
            async printReceptlistaSelected() {
                this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
                await this.$store.dispatch("prescriptionSet/printReceptlista", {
                    ids: Object.keys(this.printCache),
                    patient: this.patient,
                });
                this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
            },
            waitTimeout() {
                return new Promise((resolve) => setTimeout(resolve, 1000));
            },
        },
        mounted() {
            this.$on("actionTable::select", (item) => {
                this.actionTableSelectHandler(item);
            });
            this.$on("previous::add", (selected) => {
                this.actionTableAddHandler(selected);
            });
            this.$on("previous::edit", (payload) => {
                if (payload.prescriptionSet) this.actionTableEditHandler(payload.prescriptionSet);
            });
            this.$on("previous::view", (payload) => {
                if (payload.prescriptionSet) this.actionTableViewHandler(payload.prescriptionSet);
            });
            this.$on("previous::cancel", (payload) => {
                if (payload.selectedItems.length === 0) {
                    // If no items where selected assume all items in prescriptionSet
                    this.actionTableCancelHandler({
                        prescriptionSet: payload.prescriptionSet,
                        selectedItems: payload.prescriptionSet.prescriptionItemDetails,
                    });
                } else {
                    if (payload.prescriptionSet) this.actionTableCancelHandler(payload);
                }
            });
            this.$on("previous::history", (payload) => {
                if (payload.prescriptionSet) this.actionTableHistoryHandler(payload.prescriptionSet);
            });
            this.$on("previous::sms", (payload) => {
                if (payload.prescriptionSet) {
                    if (payload.prescriptionSet.patient_has_phonenumber) {
                        if (payload.prescriptionSet.patient_onskar_sms) {
                            this.sendSMSOnPrescription(payload.prescriptionSet.pk);
                        } else {
                            dialogEvents.$emit("open", {
                                type: "warning",
                                description: "Sms kan inte skickas eftersom patienten inte önskar det.",
                                buttons: [
                                    {
                                        title: "OK",
                                        type: "primary",
                                    },
                                ],
                            });
                        }
                    } else {
                        dialogEvents.$emit("open", {
                            type: "warning",
                            description: "Sms kan inte skickas eftersom mobilnummer till patienten saknas.",
                            buttons: [
                                {
                                    title: "OK",
                                    type: "primary",
                                },
                            ],
                        });
                    }
                }
            });
        },
    };
</script>
<style scoped lang="scss">
    @import "@/style/variables";

    .prescription-set-action-table-wrapper {
        .recipe-header {
            margin-top: 40px;
            margin-bottom: 5px;
            display: flex;

            .recipe-icon {
                height: 24px;
                width: 18px;
                margin-right: 10px;
                margin-left: 24px;
                float: left;
                filter: brightness(0) saturate(100%) invert(85%) sepia(39%) saturate(5683%) hue-rotate(163deg)
                    brightness(85%) contrast(89%);
            }
        }

        .show-all-button {
            background-color: #ffffff;
            border: 1px solid $color-primary;
            color: $color-primary;
            height: 47px;
            border-radius: 4px;
        }
    }
</style>
