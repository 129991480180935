<template>
    <span
        :style="{
            height,
            width: computedWidth,
            margin: margin,
            borderRadius: radius,
            backgroundColor: bgcolor,
            opacity: opacity,
        }"
        class="SkeletonBox"
    />
</template>

<!-- Wrappa skeletonkomponenten i en div för att applicera stylings i parentkomponent. display: flex och flex-direction: column för
    att skapa listvyer

    Exempel på skeleton: <skeleton-box height="30px" width="200px" margin="0 10px 15px 0" bgcolor="#FFFFFF" radius="5px"></skeleton-box>-->

<script>
export default {
    name: "SkeletonBox",
    props: {
        maxWidth: {
            // Ange högsta % längd för random bredd
            default: 100,
            type: Number,
        },
        minWidth: {
            // Ange lägsta % längd för random bredd
            default: 80,
            type: Number,
        },
        height: {
            // default höjd
            default: "1em",
            type: String,
        },
        width: {
            // Ange en bestämd width om du vill kringgå random
            default: null,
            type: String,
        },
        bgcolor: {
            // Ange färg på skeletonboxar, default är en ljus grå
            default: "#DDDBDD",
            type: String,
        },
        opacity: {
            // Ange opacity ifall man vill ha starkare skeletons vid t.ex rubriker osv.
            default: 0.7,
            type: Number,
        },
        margin: {
            // Ange margins för att simulera radavstånd vid t.ex listvyer
            default: "0 0 0 0",
            type: String,
        },
        radius: {
            // Ange radius för att skapa rundade kanter, 50% är en cirkel.
            default: "0px",
            type: String,
        },
    },
    computed: {
        computedWidth() {
            // Här räknas random-siffrorna ut för att skapa mer dynamiska listvyer.
            return this.width || `${Math.floor(Math.random() * (this.maxWidth - this.minWidth) + this.minWidth)}%`;
        },
    },
};
</script>

<style lang="scss" scoped>
.SkeletonBox {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    background-color: #dddbdd;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: "";
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
</style>
