<template>
    <form @submit.prevent="submit">
        <admin-lock-border />
        <h2 v-if="parameters">Redigera sökord</h2>
        <h2 v-else>Skapa nytt sökord</h2>
        <div class="form-group k3-form">
            <input-wrapper
                :component="widgets.TextWidget"
                label="Namn"
                name="namn"
                required="true"
                v-model="sokord.namn"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.CheckboxWidget"
                label="Aktiv"
                name="aktiv"
                v-model="sokord.aktiv"
                @input="updateFormHasBeenEdited"
            />
        </div>
        <ButtonGroup>
            <Button size="primary" role="button" type="submit" :disabled="!formHasBeenEdited">Spara och stäng</Button>
            <Button @click="cancel()" size="secondary" type="button">Avbryt</Button>
        </ButtonGroup>
    </form>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import dialogEvents from "@/dialogEvents";
import tabIcon from "@/assets/receptforskrivning.svg";
import widgets from "@/components/widgets/inputs";
import { cloneDeep } from "lodash";
import { flashMessage } from "@/utils";
import { mapActions } from "vuex";
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";

export default {
    extends: BaseTab,
    name: "Sokord",
    tabName: "Sökord",
    icon: tabIcon,
    components: {
        ButtonGroup,
        Button,
        AdminLockBorder,
        ...widgets,
        InputWrapper,
    },
    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            widgets: widgets,
            formHasBeenEdited: false,
            sokord: {
                id: "",
                namn: "",
                aktiv: true,
            },
        };
    },
    computed: {
        hasUnsavedData() {
            return this.formHasBeenEdited;
        },
    },
    methods: {
        ...mapActions("sokord", ["save", "update", "load"]),
        updateFormHasBeenEdited() {
            this.formHasBeenEdited = true;
        },
        async submit() {
            let response, msg, status;

            if (this.sokord.pk) {
                this.sokord.aktiv = Boolean(this.sokord.aktiv);
                response = await this.update({ sokord: this.sokord });
                msg = "Sökordet har uppdaterats";
                status = 200;
            } else {
                this.sokord.aktiv = Boolean(this.sokord.aktiv);
                response = await this.save({ sokord: this.sokord });
                msg = "Sökordet har sparats";
                status = 201;
            }

            if (!response) return;
            if (response.status === status) {
                await this.$store.dispatch("sokord/load");
                this.$root.$emit("saveOrEdit");
                await this.$store.dispatch("tabs/closeTab", this.tabId);
                flashMessage(msg);
            }
        },
        cancel() {
            this.closeTab(this.hasUnsavedData);
        },
    },
    async created() {
        if (this.parameters) this.sokord = cloneDeep(this.parameters);
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/deprecated_main";
</style>
