<template>
    <div id="popup-overlay" :class="{ open: active }" @mousedown="dismiss">
        <div class="popup" :class="options.type" :style="options.position">
            <div class="popuprow">
                <div class="head" v-if="options.title" @clickaway="away">
                    <div class="filler" v-if="options.dismissable"></div>
                    <h1 class="title">{{ options.title }}</h1>
                    <img
                        class="img"
                        v-if="options.dismissable"
                        @click="dismiss"
                        src="@/assets/close_white.svg"
                        alt="cross"
                    />
                </div>
            </div>
            <div class="body">
                <component
                    :is="options.component"
                    v-bind="{ response: options.data }"
                    :attachedProps="options.attachedProps"
                />
            </div>
        </div>
    </div>
</template>
<script>
import $ from "jquery";
import { mixin as clickaway } from "vue-clickaway";
import { mapGetters } from "vuex";
import dialogEvents from "@/dialogEvents";
import FocusControlMixin from "@/mixins/FocusControlMixin";

const DEFAULT_OPTIONS = {
    dismissable: true,
    position: { left: "50%", top: "50%", transform: "translate(-50%, -50%)" },
};

const keys = [9, 37, 38, 39, 40];

function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
}

export default {
    extends: FocusControlMixin,
    name: "Popup",
    mixins: [clickaway],
    data() {
        return {
            options: {},
            active: false,
        };
    },
    computed: {
        ...mapGetters("userData", ["currentProfileId"]),
    },
    watch: {
        currentProfileId(newValue) {
            if (newValue === null) {
                dialogEvents.$emit("closePopup");
            }
        },
    },
    methods: {
        open(options) {
            this.setOptions(options);
            this.active = true;
            $("body").addClass("popup-takeover");
            if (!this.options.allowScroll) this.disableScroll();
        },
        setOptions(options) {
            this.options = $.extend({}, DEFAULT_OPTIONS, options || {});
        },
        close() {
            // Reset style added from jQuery fadeOut.
            $(this.$el).removeAttr("style");
            $("body").removeClass("popup-takeover");
            this.active = false;
            this.options = {};
            if (!this.options.allowScroll) this.enableScroll();
        },
        dismiss(event) {
            if (
                this.options.dismissable &&
                (!(event instanceof MouseEvent) ||
                    event.target === this.$el ||
                    (event.target.localName === "img" && event.target.className === "img"))
            ) {
                dialogEvents.$emit("closePopup");
            }
        },
        away() {
            dialogEvents.$emit("closePopup");
        },
        keydown(e) {
            if (this.active) {
                // This check shouldn't be necessary since this listener will only be registered when popup is active, but it still interfered elsewhere
                if (
                    e.target instanceof HTMLInputElement ||
                    e.target instanceof HTMLTextAreaElement ||
                    e.target instanceof HTMLSelectElement
                )
                    return;
                if (keys.includes(e.keyCode)) {
                    preventDefault(e);
                    return;
                }
            }
        },
        wheel(e) {
            preventDefault(e);
        },
        disableScroll() {
            if (window.addEventListener) {
                window.addEventListener("wheel", this.wheel, {
                    passive: false,
                });
                window.addEventListener("keydown", this.keydown, {
                    passive: false,
                });
            }
        },
        enableScroll() {
            if (window.removeEventListener) {
                window.removeEventListener("wheel", this.wheel, {
                    passive: false,
                });
                window.removeEventListener("keydown", this.wheel, {
                    passive: false,
                });
            }
        },
    },
    mounted() {
        dialogEvents.$on("openPopup", this.open);
        dialogEvents.$on("closePopup", this.close);
    },
};
</script>
<style lang="sass" scoped>
@import "@/style/variables"

/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

@import "bootstrap/scss/buttons"
@import "bootstrap/scss/modal"
/* Bootstrap - End */

@import "@/style/deprecated_main"

body.takeover
  overflow: hidden

#popup-overlay
  z-index: 1030
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: none
  background-color: rgba(#000, 0)
  transition: background-color .2s

  body.popup-takeover &
    display: block
    background-color: rgba(#000, .25)

  .popuprow
    display: flex
    flex-direction: row
    justify-content: center

  .popup
    z-index: 1032
    position: absolute
    width: $modal_content_width
    box-shadow: 10px 10px 40px -12px rgba(#000, .5)
    display: inline-block

    &.wide
      width: 953px
      max-width: 953px

    .head
      width: 100%
      display: flex
      flex-direction: row
      background-color: #277692
      height: 86px
      text-align: center
      padding: 21px 28px 21px 28px

      .filler
        width: 10%
        margin-top: auto
        margin-bottom: auto

      h1
        height: 42px
        color: #ffffff
        line-height: 42px
        font-size: 38px
        margin: auto

      .img
        width: 10%
        text-align: center
        height: 30px
        margin-top: auto
        margin-bottom: auto
        color: #ffffff
        cursor: pointer


    .body
      background-color: #fff
      padding: 2rem
      overflow: auto
      max-height: 80vh

      h2
        margin: 20px

    .btn-group
      float: right
</style>
