<template>
    <div class="labportalen">
        <iframe :src="url" frameBorder="0" />
    </div>
</template>

<script>
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { mapGetters } from "vuex";
import { openDialog } from "@/utils";

export default {
    name: "Labportalen",

    props: {
        endpoint: {
            type: String,
            required: true,
        },
        tabId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            url: "",
        };
    },

    computed: {
        ...mapGetters("userData", ["currentProfile"]),
    },

    async created() {
        if (this.currentProfile.labbportalid) {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            klinikenApi
                .get(this.endpoint, getConfig())
                .then((response) => {
                    this.url = response.data.url;
                })
                .catch((e) => {
                    getErrorMessage(e);
                });
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        } else {
            openDialog("Du saknar behörighet för Labportalen. Kontakta din verksamhetschef.", "warning");
            await this.$store.dispatch("tabs/closeTab", this.tabId);
        }
    },
};
</script>

<style lang="scss">
.labportalen {
    width: 100%;
    height: 100vh;

    iframe {
        width: 100%;
        height: 100%;
        padding-top: 30px;
    }
}
</style>
