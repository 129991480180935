<template>
    <div>
        <admin-lock-border />
        <h2>
            Sökordsstrukturer
            <hollow-button
                :icon="PlusCircleBlueIcon"
                label="Skapa ny sökordsstruktur"
                @click="createSokordsstruktur()"
            />
        </h2>
        <custom-action-table
            class="no-top"
            :selectableRows="false"
            :columns="TableColumns"
            :items="sokordsstrukturer"
            :actions="TableActions"
        >
        </custom-action-table>
        <pagination
            class="pagination mt-5"
            v-model="page"
            :per-page="100"
            :records="count"
            :options="paginationOptions"
            @paginate="paginate"
        />
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import Sokordsstruktur from "@/tabs/Admin/Sokordsstruktur.vue";
import Pagination from "vue-pagination-2";
import { mapActions, mapGetters } from "vuex";
import tabIcon from "@/assets/blank.png";
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import HollowButton from "@/components/widgets/HollowButton.vue";
import PlusCircleBlueIcon from "@/assets/plus_circle_blue.svg";

const noNull = (str) => {
    return str || "";
};

const TABLE_COLUMNS = [
    {
        name: "pk",
        title: "PK",
    },
    {
        name: "sokord",
        title: "Sökord (namn)",
        template: (item) => {
            return noNull(item.sokord.namn);
        },
    },
    {
        name: "typ_display",
        title: "Typ",
        template: (item) => {
            let returnString = item.typ_display;
            if (item.om_sa) returnString += ", Om-så";
            return returnString;
        },
    },
    {
        name: "foralder",
        title: "Förälder ID",
    },
    {
        name: "sokordsstruktur",
        title: "Barn",
        template: (item) => {
            let returnString = "";
            item.sokordsstruktur.forEach((el) => {
                returnString += noNull(el.pk) + " (" + noNull(el.sokord.namn) + "), ";
            });
            return returnString.slice(0, -2);
        },
    },
    {
        name: "atyp_reference_namn",
        title: "Anteckningstyp",
        template: (item) => {
            return noNull(item?.atyp_reference_namn);
        },
    },
    {
        name: "status",
        title: "Status",
        template: (item) => {
            if (!item.aktiv) return `<status-widget status="inaktiv" />`;
            else return `<status-widget status="aktiv" />`;
        },
    },
];

const TABLE_ACTIONS = [
    {
        name: "edit",
        title: "Redigera",
        img: "edit.svg",
        condition: () => {
            return true;
        },
    },
];

export default {
    extends: BaseTab,
    name: "ListSokordsstruktur",
    tabName: "Sökordsstrukturer",
    icon: tabIcon,
    components: {
        HollowButton,
        AdminLockBorder,
        CustomActionTable,
        Pagination,
    },
    props: ["tabId"],
    computed: {
        ...mapGetters("sokord", ["sokordsstrukturer", "count", "limit", "offset", "parameters"]),
    },
    data() {
        return {
            PlusCircleBlueIcon,
            TableColumns: TABLE_COLUMNS,
            TableActions: TABLE_ACTIONS,
            searchParameters: {},
            page: 1,
            paginationOptions: {
                format: true,
                chunk: 10,
                chunksNavigation: "fixed",
                edgeNavigation: true,
                theme: "bootstrap4",
                texts: {
                    count: "Visar {from} till {to} av {count} Sokordsstruktur|{count} Sokordsstrukturer|En Sokordsstruktur",
                    first: "Första",
                    last: "Sista",
                    nextChunk: ">>",
                    prevChunk: "<<",
                },
            },
        };
    },
    methods: {
        ...mapActions("sokord", ["fetchSokordsstrukturer", "setOffset", "setParameters", "resetParameters"]),
        createSokordsstruktur() {
            this.$store.dispatch("tabs/openTab", { component: Sokordsstruktur });
        },

        async paginate(page) {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            this.setOffset(page * 100 - 100);
            await this.fetchSokordsstrukturer();
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },
        async load() {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            await this.fetchSokordsstrukturer();
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },
    },
    async created() {
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
        this.setOffset(0);
        this.setParameters(this.searchParameters);
        await this.load();
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });

        this.$on("edit", async (data) => {
            await this.$store.dispatch("tabs/openTab", { component: Sokordsstruktur, parameters: data });
        });
    },
};
</script>

<style lang="scss" scoped>
h2 {
    .hollow-button {
        margin-left: 50px;
    }
}
</style>
