import { getConfig, klinikenApi } from "@/api";
import { cloneDeep } from "lodash";

const getDefaultParameters = () => {
    return {};
};

const getDefaultState = () => {
    return {
        lag: [],
        parameters: getDefaultParameters(),
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        lag(state) {
            return state.lag;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setLag: (state, lag) => {
            const index = state.lag.findIndex((l) => l.id === lag.id);

            if (index !== -1) {
                state.lag[index] = lag;
            } else {
                state.lag.push(lag);
            }

            state.lag = [...state.lag];
        },
        setLista: (state, payload) => (state.lag = payload),
    },
    actions: {
        async save({ dispatch, commit }, lag) {
            let response;
            if (lag.id === undefined) {
                response = await dispatch("create", lag);
            } else {
                response = await dispatch("update", lag);
            }
            commit("setLag", cloneDeep(response.data));
            return response;
        },
        async create({ commit }, lag) {
            return await klinikenApi.post(`skreg/lag/`, lag, getConfig());
        },
        async update({}, lag) {
            return await klinikenApi.put(`skreg/lag/${lag.id}/`, lag, getConfig());
        },
        async load({ commit }) {
            const response = await klinikenApi.get(`skreg/lag/`, getConfig());
            commit("setLista", response.data);
        },
    },
};
