<template>
    <div class="prescription-set-action-table">
        <button
            @click.self="checkTarget($event)"
            v-if="collapseLabel"
            class="btn btn-default btn-block full-width"
            type="button"
        >
            <div class="flex-group">
                <checkbox-widget
                    v-if="actions || groupActions"
                    class="checkbox-widget"
                    :name="'checkbox-' + id"
                    @input="
                        fetchAndToggle();
                        setAllSelected();
                        setPrescriptionSetSelected($event);
                    "
                    v-model="allSelected"
                    role="presentation"
                />
                <!-- note: collapseLabel needs to be either passed as a prop to checkbox-widget, or defined in a span role="presentation" -->
                <span role="presentation"> {{ collapseLabel }} </span>
                <a @click="fetchItemsAndEmit('previous::history')" class="edited" v-if="data.is_edited">(redigerad)</a>
                <span v-if="data.is_sms_sent" class="smsSent"><img src="@/assets/samtycker.svg" /> Sms skickat</span>
            </div>
            <div class="flex-group">
                <span v-if="label" class="label-status" :style="'background-color: ' + label.colour">
                    <a v-if="label.tooltip" href="#" data-toggle="tooltip" :title="label.tooltip"> {{ label.text }}</a>
                    <template v-else>{{ label.text }}</template>
                </span>
                <actions-dropdown
                    v-if="labelActions"
                    :id="'dropdown-' + id"
                    class="message-dropdown"
                    :actions="labelActions"
                    :item="data"
                    :current-profile="currentProfile"
                    @dropdownAction="dropdownAction"
                />
                <span @click="fetchAndToggle(!collapse)" :class="rotateCaret"></span>
            </div>
        </button>
        <collapse-transition>
            <div v-show="collapse">
                <table class="table">
                    <thead>
                        <tr>
                            <th
                                v-for="col in columns"
                                :key="col.name"
                                :class="{
                                    sortable: sortMethod && col.sortable,
                                    'is-sorted': orderBy === col.name,
                                    reversed: orderReversed,
                                }"
                                @click="setOrder(col)"
                            >
                                {{ col.title }}
                                <img v-if="sortMethod && col.sortable" src="@/assets/dropdown-arrow-blue.svg" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in prescriptionItems" :key="item.id">
                            <td v-for="(col, i) in columns" :key="col.name">
                                <checkbox-widget
                                    v-if="i === 0 && actions"
                                    :disabled="!disabledRowHandler(item)"
                                    :name="item.id"
                                    :labelHtml="col.renderer(item, Q)"
                                    :q="q"
                                    @input="checkAllSelected"
                                    v-model="selected[index]"
                                />
                                <span v-if="col.renderer && !(i === 0 && actions)" v-html="col.renderer(item, Q)" />
                                <span v-if="!col.renderer && !(i === 0 && actions)">{{ item[col.name] }}</span>
                                <span class="missing" v-if="isRequiredField(col) && item[col.name] === ''">Saknas</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="btn-group action-btn" v-if="actions">
                    <button
                        v-for="action in actions"
                        :key="action.name"
                        class="btn"
                        :class="'btn-' + (action.type || 'default')"
                        :disabled="!anyItemSelected.length && !action.alwaysActive"
                        @click="onButtonClick(action.name, getSelectedItems())"
                    >
                        <img v-if="action.icon" :src="action.icon" /> {{ action.title }}
                    </button>
                </div>
            </div>
        </collapse-transition>
    </div>
</template>

<script>
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";
import ActionTable from "@/components/generic/ActionTable.vue";
import CheckboxWidget from "@/components/widgets/inputs/CheckboxWidget.vue";

export default {
    extends: ActionTable,
    components: { CheckboxWidget },
    data() {
        return {
            selected: [],
            allSelected: false,
            prescriptionItems: [],
            prescriptionItemsRead: false,
            readingPrescriptionItems: false,
            data_: this.data,
            collapse: false,
        };
    },
    props: {
        currentProfile: String,
        perscriptonOrListFavorit: String,
    },
    computed: {
        rotateCaret() {
            return this.collapse ? "rotatingCaret rotateonedegree" : "rotatingCaret rotateoneeighty";
        },
        disabledRows() {
            if (this.groupActions) return [];
            else
                return this.prescriptionItems.filter((item) => {
                    return !this.disabledRowHandler(item);
                });
        },
        anyItemSelected() {
            return this.selected.filter((item) => item);
        },
        Q() {
            if (this.q) {
                if (this.q.length > 2) return this.q.toLowerCase();
                else return "";
            } else return "";
        },
    },
    methods: {
        setPrescriptionSetSelected(event) {
            this.$emit("setPrescriptionSetSelected", this.$props.data.pk, event);
        },
        async fetchAndToggle(toggle = true) {
            this.collapse = toggle;
            if (!this.prescriptionItemsRead) {
                await this.fetchPrescriptionItems();
            }
        },
        getSelectedItems() {
            let selectedItems = [];
            for (let i = 0; i < this.prescriptionItems.length; i++) {
                if (this.selected[i]) selectedItems.push(this.prescriptionItems[i]);
            }
            return selectedItems;
        },
        setAllSelected() {
            for (let i = 0; i < this.selected.length; i++) {
                if (this.disabledRowHandler) {
                    if (this.disabledRowHandler(this.prescriptionItems[i])) {
                        this.$set(this.selected, i, this.allSelected);
                    }
                } else this.$set(this.selected, i, this.allSelected);
            }
        },
        checkAllSelected() {
            let anyItemDeSelected = false;
            for (let i = 0; i < this.selected.length; i++) {
                if (this.selected[i] === false) anyItemDeSelected = true;
            }
            this.allSelected = !anyItemDeSelected;
        },
        async fetchPrescriptionItems() {
            return await klinikenApi
                .get("/recept/prescriptionsets/" + this.data.pk + "/", getConfig({}, "Receptförskrivning"))
                .then((response) => {
                    this.data_ = response.data;
                    this.prescriptionItems = response.data.prescriptionItemDetails;
                    this.selected.length = this.prescriptionItems.length;
                    this.setAllSelected();
                    this.prescriptionItemsRead = true;
                    this.$emit("prescriptionItemDetailsFetched", response.data);
                    this.$emit("prescription-set-read", response.data);
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
        },
        checkTarget() {
            this.collapse = !this.collapse;
            if (!this.prescriptionItemsRead) this.fetchPrescriptionItems();
        },
        onButtonClick(actionName, selected) {
            this.$parent.$emit(actionName, selected);
        },
        async fetchItemsAndEmit(name) {
            await this.fetchAndToggle();

            this.$parent.$emit(name, {
                prescriptionSet: this.data_,
                selectedItems: this.getSelectedItems(),
            });
        },
        async dropdownAction(name) {
            await this.fetchItemsAndEmit(name);
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/buttons";
@import "bootstrap/scss/tables";
/* Bootstrap - End */

@import "@/style/deprecated_main";

.prescription-set-action-table {
    margin: 10px 0;

    .checkbox-widget {
        top: -5px;
    }

    .btn-block {
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #e4e8f0;

        .flex-group {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    table {
        background-color: #f3f5f9;
        //margin: 0 12px !important;
    }

    th {
        color: #277692;
        font-size: 14px;
        font-weight: 500;
    }

    ::v-deep .label {
        color: #212529;

        .label {
            border-radius: 2px;
            padding-left: 2px;
            padding-right: 2px;
            font-weight: bold;
            font-size: 12px;
            margin-top: 2px;
            color: $color-label-grey;
            border: 1px solid $color-border-grey;
            max-height: 17px;
            line-height: 17px;
        }
    }

    .smsSent {
        font-size: 14px;
        font-weight: normal;
        margin-left: 50px;
    }

    ::v-deep .label-status {
        min-width: 60px;
        text-align: center;
        font-weight: normal;
        border-radius: 2px;
        padding: 5px 7px;
        font-size: 10px;
        margin-right: 22px;
        color: #ffffff;
        border: none;
        max-height: 20px;
        line-height: 11px;

        a {
            font-size: 10px;
            color: #ffffff;
            text-decoration: none;
        }
    }

    .rotatingCaret {
        background-image: url("@/assets/dropdown-arrow.svg");
        background-size: 14px;
        width: 14px;
        height: 14px;
        display: inline-block;
        transform: rotate(180deg);
        transition: transform 0.2s;
    }

    .rotateonedegree {
        transform: rotate(180deg);
    }

    .rotateoneeighty {
        transform: rotate(0deg);
    }
}
</style>
