<template>
    <form @submit.prevent="submit">
        <admin-lock-border />
        <h2 v-if="parameters">Redigera KVÅKod</h2>
        <h2 v-else>Skapa ny KVÅKod</h2>
        <div class="form-group k3-form">
            <input-wrapper
                :component="widgets.TextWidget"
                label="Code"
                name="code"
                v-model="kvakod.code"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="displayName"
                name="displayname"
                required="true"
                v-model="kvakod.displayName"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.SelectWidget"
                label="Klassdel"
                name="klassdel"
                v-model="kvakod.klassdel"
                @input="updateFormHasBeenEdited"
                :options="[
                    { text: 'Icke-kirurgiska åtgärder', value: 0 },
                    { text: 'Kirurgiska åtgärder', value: 1 },
                ]"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                :maxlength="1"
                label="Kapitel"
                name="kapitel"
                required="true"
                v-model="kvakod.kapitel"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                :maxlength="1"
                label="Avsnitt"
                name="avsnitt"
                required="true"
                v-model="kvakod.avsnitt"
                @input="updateFormHasBeenEdited"
            />
        </div>
        <div class="btn-group form-group">
            <button class="btn btn-primary" role="button" type="submit" :disabled="!formHasBeenEdited">
                Spara och stäng
            </button>
            <button @click="cancel()" class="btn btn-secondary" type="button">Avbryt</button>
        </div>
    </form>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import dialogEvents from "@/dialogEvents";
import tabIcon from "@/assets/receptforskrivning.svg";
import widgets from "@/components/widgets/inputs";
import { cloneDeep } from "lodash";
import { flashMessage } from "@/utils";
import { mapActions } from "vuex";
import AdminLockBorder from "@/components/AdminLockBorder.vue";

export default {
    extends: BaseTab,
    name: "KVAKod",
    tabName: "KVÅKoder",
    icon: tabIcon,
    components: {
        AdminLockBorder,
        ...widgets,
        InputWrapper,
    },
    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            widgets: widgets,
            formHasBeenEdited: false,
            kvakod: {
                id: "",
                code: "",
                displayname: "",
                klassdel: 0,
                kapitel: "",
                avsnitt: "",
            },
        };
    },
    computed: {
        hasUnsavedData() {
            return this.formHasBeenEdited;
        },
    },
    methods: {
        ...mapActions("kvakod", ["save", "update", "load"]),
        updateFormHasBeenEdited() {
            this.formHasBeenEdited = true;
        },
        async submit() {
            let response, msg, status;

            if (this.kvakod.id) {
                response = await this.update({ kvakod: this.kvakod });
                msg = "KVÅKoden har uppdaterats";
                status = 200;
            } else {
                response = await this.save({ kvakod: this.kvakod });
                msg = "KVÅKoden har sparats";
                status = 201;
            }

            if (!response) return;
            if (response.status === status) {
                await this.$store.dispatch("kvakod/load");
                this.$root.$emit("saveOrEdit");
                await this.$store.dispatch("tabs/closeTab", this.tabId);
                flashMessage(msg);
            }
        },
        cancel() {
            if (this.formHasBeenEdited) {
                dialogEvents.$emit("open", {
                    type: "warning",
                    description: "Om du avbryter kommer all information i formuläret att försvinna, är du säker?",
                    buttons: [
                        {
                            title: "Nej",
                            type: "secondary",
                        },
                        {
                            title: "Ja",
                            type: "primary",
                            action: () => {
                                this.$store.dispatch("tabs/closeTab", this.tabId);
                            },
                        },
                    ],
                });
            } else {
                this.$store.dispatch("tabs/closeTab", this.tabId);
            }
        },
    },
    async created() {
        if (this.parameters) this.kvakod = cloneDeep(this.parameters);
    },
};
</script>
<style lang="sass" scoped>
@import "../../style/variables"

/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

@import "bootstrap/scss/buttons"
@import "bootstrap/scss/button-group"
@import "bootstrap/scss/forms"
@import "bootstrap/scss/utilities"
/* Bootstrap - End */

@import "../../style/deprecated_main"

.k3-lock-border
    height: 26px
    width: 350px
    background: linear-gradient(134.72deg, #C2E6E6 0%, rgba(194, 230, 228, 0) 100%)
    color: #277692
    font-family: Roboto
    font-size: 13px
    font-weight: 500
    letter-spacing: 0.46px
    line-height: 26px
    margin-bottom: 18px
    margin-left: 10px

    img
        margin: -2px 6px 0px 17px
</style>
