<template>
    <div class="sign-details">
        <div class="top-text">
            <p>Observera att makulering enbart bör användas för felaktigt dokumenterade patientsamtycken.</p>
            <p>Efter att du makulerat ett patientsamtycke kommer den ej längre att synas i listan.</p>
        </div>
        <div class="text-area-div">
            <input-wrapper
                :component="widgets.TextAreaWidget"
                :required="true"
                name="nullifiedReason"
                v-model="nullifiedReason"
                label="Ange orsak till makuleringen"
            />
        </div>
        <div class="buttonField">
            <button @click="setAsMakulerad" class="btn btn-primary btn-alert mr-2">Makulera</button>
            <button @click="cancel" class="btn btn-normal btn-outline-primary ml-2">Avbryt</button>
        </div>
    </div>
</template>

<script>
import dialogEvents from "@/dialogEvents";
import { flashMessage, openDialog } from "@/utils";
import { getErrorMessage } from "@/api";
import { mapActions, mapGetters } from "vuex";
import widgets from "@/components/widgets/inputs";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";

export default {
    name: "MakuleraSamtycke",
    props: ["attachedProps"],
    components: {
        InputWrapper,
    },
    data() {
        return {
            nullifiedReason: "",
            widgets,
        };
    },
    computed: {
        ...mapGetters("patientData", ["patient"]),
        ...mapGetters("userData", ["currentProfile"]),
    },
    methods: {
        ...mapActions("samtycke", ["makuleraSamtycke"]),
        cancel() {
            dialogEvents.$emit("closePopup");
        },
        async setAsMakulerad() {
            try {
                const patientsamtycke = {
                    id: this.attachedProps.id,
                    reason: this.nullifiedReason,
                };
                const response = await this.makuleraSamtycke({
                    patientsamtycke,
                    user: this.patient.id,
                });
                if (response.status === 200) {
                    await this.reloadStoreAfterUpdates();
                    flashMessage("Makulering av samtycke utförd");
                    dialogEvents.$emit("closePopup");
                }
            } catch (e) {
                openDialog(getErrorMessage(e), "error");
            }
        },
        async reloadStoreAfterUpdates() {
            await this.$store.dispatch("samtycke/loadPatientsamtycken", this.patient.id);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/utilities";
/* Bootstrap - End */

@import "@/style/deprecated_main";

.sign-details {
    display: flex;
    flex-direction: column;
    opacity: 1;
    background-color: white;
    width: 769px;
    min-height: 410px;
    padding: 23px 90px 0 99px;
}

.top-text {
    width: 576px;
}

.buttonField {
    text-align: left;
    align-items: baseline;
    align-content: baseline;
    display: block !important;
}

.descriptionBlock {
    margin-top: 50px;
    text-align: left;
}

.text-area-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 31px;
}

::v-deep .makuleraText {
    width: 576px;
    height: 111px;
    resize: none;
}
</style>
