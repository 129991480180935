<template>
    <input :value="value_" type="hidden" :name="name" />
</template>

<script>
import linkEvents from "@/linkEvents";

export default {
    name: "HiddenWidget",
    data() {
        return {
            value_: this.value,
        };
    },
    props: {
        name: String,
        value: String,
    },
    methods: {
        update({ value }) {
            this.value_ = value;
        },
    },
    created() {
        linkEvents.$on("update_" + this.name, this.update);
    },
    watch: {
        value() {
            this.value_ = this.value;
        },
    },
};
</script>
