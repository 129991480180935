<template>
    <div class="intygsmall-wrapper">
        <FormulateForm
            v-model="formData"
            autocomplete="off"
            @submit-raw="submitForm"
            class="form"
            style="max-width: 1100px"
            #default="{ hasErrors }"
            :errors="formErrors"
        >
            <div class="intygsmallMain">
                <h2 class="intygsmall">{{ headerText }}</h2>
            </div>
            <h4>Namn på mallen när den visas i lista</h4>
            <FormulateInput
                label="Namn på mallen när den visas i lista"
                type="text"
                name="namn"
                @input="removeError('namn')"
                validation="required"
                :validation-messages="{
                    required: 'Det här fältet får inte vara blankt.',
                }"
            />
            <hr />
            <h4>Smittbärarpenning</h4>
            <h4>Avstängning enligt smittskyddslagen på grund av smitta. (Fortsätt till frågorna "Diagnos").</h4>
            <FormulateInput
                name="svar_id27"
                :options="[
                    { value: 'inget', label: '' },
                    { value: 'ja', label: 'Ja' },
                    { value: 'nej', label: 'Nej' },
                ]"
                type="select"
                label=""
            />
            <div v-if="formData.svar_id27 === 'inget' || formData.svar_id27 === 'nej'">
                <hr />
                <h4>Grund för medicinskt underlag</h4>
                <FormulateInput
                    type="select"
                    name="svar_id1_1"
                    key="svar_id1_1"
                    label="Intyget är baserat på"
                    :options="[
                        { value: null, label: '' },
                        { value: 'UNDERSOKNING', label: 'Undersökning' },
                        { value: 'TELEFONKONTAKT', label: 'Telefonkontakt' },
                        { value: 'JOURNALUPPGIFTER', label: 'Journaluppgifter' },
                        { value: 'ANNAT', label: 'Annat' },
                    ]"
                />
                <FormulateInput
                    type="textarea"
                    name="svar_id1_3"
                    key="svar_id1_3"
                    label="Beskrivning"
                    v-if="formData.svar_id1_1 === 'ANNAT'"
                />
            </div>
            <hr />
            <div class="wrapper">
                <h4>Diagnos/diagnoser för sjukdom som orsakar nedsatt arbetsförmåga</h4>
                <FormulateInput
                    label="Diagnos"
                    type="vueselect"
                    name="svar_id6_1"
                    key="svar_id6_1"
                    :outer-class="['full-width']"
                    @search="onSearchICDkoder1"
                    :options="optionsICDkoder1"
                />
                <FormulateInput
                    label="Diagnos"
                    type="vueselect"
                    name="svar_id6_2"
                    key="svar_id6_2"
                    :outer-class="['full-width']"
                    @search="onSearchICDkoder2"
                    :options="optionsICDkoder2"
                />
                <FormulateInput
                    label="Diagnos"
                    type="vueselect"
                    name="svar_id6_3"
                    key="svar_id6_3"
                    :outer-class="['full-width']"
                    @search="onSearchICDkoder3"
                    :options="optionsICDkoder3"
                />
            </div>

            <template v-if="formData.svar_id27 === 'inget' || formData.svar_id27 === 'nej'">
                <hr />
                <h4>Beskriv undersökningsfynd, testresultat och observationer</h4>
                <FormulateInput
                    type="textarea"
                    name="svar_id35"
                    key="svar_id35"
                    label="Funktionsnedsättning"
                    :element-class="['textarea-full-width']"
                />

                <hr />
                <h4>Aktivitetsbegränsning</h4>
                <h4>Beskriv vad patienten inte kan göra på grund av sin sjukdom. Ange vad uppgiften grundas på.</h4>
                <FormulateInput
                    type="textarea"
                    name="svar_id17"
                    key="svar_id17"
                    label=""
                    :element-class="['textarea-full-width']"
                />

                <hr />
                <h4>Medicinsk behandling</h4>
                <h4>
                    Pågående medicinska behandlingar/åtgärder. Ange vad syftet är och om möjligt tidplan samt ansvarig
                    vårdenhet.
                </h4>
                <FormulateInput
                    type="textarea"
                    name="svar_id19"
                    key="svar_id19"
                    label=""
                    :element-class="['textarea-full-width']"
                />
                <h4>
                    Planerade medicinska behandlingar/åtgärder. Ange vad syftet är och om möjligt tidplan samt ansvarig
                    vårdenhet.
                </h4>
                <FormulateInput
                    type="textarea"
                    name="svar_id20"
                    key="svar_id20"
                    label=""
                    :element-class="['textarea-full-width']"
                />
                <h4>
                    Resor till och från arbetet med annat färdmedel än normalt kan göra det möjligt för patienten att
                    återgå till arbetet under sjukskrivningsperioden.
                </h4>
                <FormulateInput
                    type="checkbox"
                    name="svar_id34"
                    key="svar_id34"
                    label="Resor till och från arbetet med annat färdmedel än normalt kan göra det möjligt för patienten att återgå till arbetet under sjukskrivningsperioden."
                />
            </template>
            <div v-if="formData.svar_id27 === 'inget' || formData.svar_id27 === 'nej'" class="svar_id33">
                <h4>
                    Finns det medicinska skäl att förlägga arbetstiden på något annat sätt än att minska arbetstiden
                    lika mycket varje dag?
                </h4>
                <FormulateInput
                    type="select"
                    name="svar_id33_1"
                    key="svar_id33_1"
                    label=""
                    :options="[
                        { value: 'inget', label: '' },
                        { value: 'ja', label: 'Ja, fyll i nedan' },
                        { value: 'nej', label: 'Nej' },
                    ]"
                />
                <div v-if="formData.svar_id33_1 === 'ja'">
                    <h4>Beskriv medicinska skäl till annan förläggning av arbetstiden</h4>
                    <FormulateInput
                        name="svar_id33_2"
                        type="textarea"
                        key="svar_id33_2"
                        label=""
                        :element-class="['textarea-full-width']"
                    />
                </div>
            </div>

            <!-- <FormulateInput  type="group" name="svar_id33" key="svar_id33"></FormulateInput> -->

            <template v-if="formData.svar_id27 === 'inget' || formData.svar_id27 === 'nej'">
                <h4>Prognos för arbetsförmåga utifrån aktuellt undersökningstillfälle</h4>

                <FormulateInput
                    type="select"
                    name="svar_id39_1"
                    key="svar_id39_1"
                    :options="[
                        { value: null, label: '' },
                        {
                            value: 'STOR_SANNOLIKHET',
                            label: 'Patienten förväntas kunna återgå helt i nuvarande sysselsättning efter denna sjukskrivning.',
                        },
                        {
                            value: 'ATER_X_ANTAL_DGR',
                            label: 'Patienten förväntas kunna återgå helt i nuvarande sysselsättning inom',
                        },
                        {
                            value: 'SANNOLIKT_INTE',
                            label: 'Patienten förväntas inte kunna återgå helt i nuvarande sysselsättning inom 12 månader.',
                        },
                        {
                            value: 'PROGNOS_OKLARA',
                            label: 'Återgång i nuvarande sysselsättning är oklar.',
                        },
                    ]"
                />
                <div v-if="formData.svar_id39_1 === 'ATER_X_ANTAL_DGR'">
                    <h4>Prognos för arbetsförmåga utifrån aktuellt undersökningstillfälle</h4>
                    <FormulateInput
                        type="select"
                        name="svar_id39_3"
                        key="svar_id39_3"
                        :options="[
                            { value: null, label: '' },
                            { value: 'TRETTIO_DGR', label: '1 månad' },
                            { value: 'SEXTIO_DGR', label: '2 månader' },
                            { value: 'NITTIO_DGR', label: '3 månader' },
                            { value: 'HUNDRAATTIO_DAGAR', label: '6 månader' },
                            {
                                value: 'TREHUNDRASEXTIOFEM_DAGAR',
                                label: '12 månader',
                            },
                        ]"
                    />
                </div>
            </template>
            <template>
                <div v-if="formData.svar_id27 === 'inget' || formData.svar_id27 === 'nej'">
                    <hr />
                    <h4>
                        Här kan du ange åtgärder som du tror skulle göra det lättare för patienten att återgå i arbete
                    </h4>
                    <FormulateInput
                        :element-class="['column-checkbox-group']"
                        type="checkbox"
                        name="svar_id40"
                        v-if="!kollaeftrEJAKTUELLT"
                        :options="[
                            { value: 'ARBETSTRANING', label: 'Arbetsträning' },
                            { value: 'ARBETSANPASSNING', label: 'Arbetsanpassning' },
                            { value: 'SOKA_NYTT_ARBETE', label: 'Söka nytt arbete' },
                            { value: 'BESOK_ARBETSPLATS', label: 'Besök på arbetsplatsen' },
                            { value: 'ERGONOMISK', label: 'Ergonomisk bedömning' },
                            { value: 'HJALPMEDEL', label: 'Hjälpmedel' },
                            { value: 'KONFLIKTHANTERING', label: 'Konflikthantering' },
                            { value: 'KONTAKT_FHV', label: 'Kontakt med företagshälsovård' },
                            { value: 'OMFORDELNING', label: 'Omfördelning av arbetsuppgifter' },
                            { value: 'OVRIGA_ATGARDER', label: 'Övrigt' },
                        ]"
                        :disabled="kollaeftrEJAKTUELLT"
                    />
                    <FormulateInput
                        type="checkbox"
                        name="svar_id40_ejaktuellt"
                        :options="[{ value: 'EJ_AKTUELLT', label: 'Inte aktuellt' }]"
                    />
                    <h4>
                        Här kan du ange fler åtgärder. Du kan också beskriva hur åtgärderna kan underlätta återgång i
                        arbete.
                    </h4>
                    <FormulateInput
                        :element-class="['textarea-full-width']"
                        type="textarea"
                        name="svar_id44"
                        key="svar_id44"
                        :disabled="!kollaeftrOVRIGAATGARDER"
                        v-if="kollaeftrOVRIGAATGARDER"
                    />
                </div>
            </template>
            <hr />
            <h4>Övriga upplysningar</h4>
            <FormulateInput type="textarea" name="svar_id25" key="svar_id25" :element-class="['textarea-full-width']" />
            <hr />
            <div v-if="formData.svar_id27 === 'inget' || formData.svar_id27 === 'nej'">
                <h4>Kontakt med Försäkringskassan</h4>
                <FormulateInput
                    type="checkbox"
                    name="svar_id26_1"
                    key="svar_id26_1"
                    checkbox-label="Jag önskar att Försäkringskassan kontaktar mig."
                />
                <FormulateInput
                    :element-class="['textarea-full-width']"
                    type="textarea"
                    name="svar_id26_2"
                    key="svar_id26_2"
                    label="Ange gärna varför du vill ha kontakt."
                    v-if="formData.svar_id26_1"
                />
            </div>

            <h4>Gemensam mall</h4>
            <FormulateInput
                type="checkbox"
                name="is_public"
                label="Denna mall ska vara gemensam för alla på kliniken."
            />

            <ButtonGroup>
                <Button variant="standard" size="primary" type="submit">
                    <img src="@/assets/send_white.svg" alt="send arrow" />
                    <span v-if="editMode">Uppdatera</span>
                    <span v-else>Spara</span>
                </Button>
                <Button variant="contour" size="secondary" type="button" @click="cancel()">Avbryt</Button>
            </ButtonGroup>
        </FormulateForm>
        <ModalBasic ref="dialog" />
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import tabIcon from "@/assets/operationsanmalan.svg";
import { getConfig, klinikenApi } from "@/api";
import { mapActions, mapGetters } from "vuex";
import { flashMessage } from "@/utils";
import PlusCircleBlueIcon from "@/assets/plus_circle_blue.svg";
import DeleteIcon from "@/assets/actionIcons/delete.svg";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import { cloneDeep, debounce, isEqual } from "lodash";
import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";

import { isNull, parseError } from "@/tabs/Operation/utils";

const defaultFormData = {
    svar_id27: "inget",
    svar_id6: [],
    svar_id33_1: "inget",
    svar_id34: false,
    svar_id40_ejaktuellt: [],
    svar_id26_1: false,
    is_public: false,
};

export default {
    extends: BaseTab,
    patientBound: true,
    icon: tabIcon,
    name: "IntygsmallForm",
    tabName: "Ny intygsmall",
    props: {
        tabId: Number,
        parameters: Object,
    },
    components: {
        Button,
        ButtonGroup,
        ModalBasic,
        BasicModalVariant,
    },
    data() {
        return {
            showError: true,
            PlusCircleBlueIcon,
            DeleteIcon,
            intygsmallar: [],
            editMode: false,
            defaultFormData: cloneDeep(defaultFormData),
            formData: cloneDeep(defaultFormData),
            laggtillCount: 3,
            typAvIntyg: null,
            formErrors: null,
            inputErrors: {},
            recievedFormData: {},
            optionsICDkoder1: [],
            optionsICDkoder2: [],
            optionsICDkoder3: [],
        };
    },
    computed: {
        ...mapGetters("intyg", ["intygstyp", "intygsmall"]),

        hasUnsavedData() {
            return !isEqual(this.removeEmpty(this.defaultFormData), this.removeEmpty(this.formData));
        },
        headerText() {
            return this.editMode ? "Redigera intygsmall" : "Skapa ny intygsmall";
        },
        kollaeftrEJAKTUELLT() {
            if (this.formData.svar_id40_ejaktuellt) {
                return this.formData.svar_id40_ejaktuellt.includes("EJ_AKTUELLT");
            }
            return false;
        },
        kollaeftrOVRIGAATGARDER() {
            if (this.formData.svar_id40) {
                return this.formData.svar_id40.includes("OVRIGA_ATGARDER");
            }
            return false;
        },
    },
    methods: {
        ...mapActions("intyg", ["getIntygsTyp"]),
        removeError(name) {
            if (!isNull(this.formErrors) || this.formErrors === {}) {
                delete this.formErrors[name];
                this.formErrors = parseError(this.formErrors);
            } else return;
        },
        removeEmpty(obj) {
            return Object.fromEntries(
                Object.entries(obj)
                    // eslint-disable-next-line no-unused-vars
                    .filter(([_, v]) => v != null && v !== "")
                    .map(([k, v]) => [k, v === Object(v) ? this.removeEmpty(v) : v], this)
            );
        },
        onSearchICDkoder1(search, loading) {
            if (search.length >= 3) {
                loading(true);
                this.searchICDkoder1(search, loading, this);
            }
        },
        searchICDkoder1: debounce((search, loading, vm) => {
            return klinikenApi.get(`/intyg/kodverk/icdkoder/all/?q=${escape(search)}`, getConfig()).then((res) => {
                vm.optionsICDkoder1 = res.data.map((obj) => {
                    return {
                        value: obj.id,
                        label: `${obj.code} ${obj.displayName}`,
                        ...obj,
                    };
                });
                loading(false);
            });
        }, 350),
        onSearchICDkoder2(search, loading) {
            if (search.length >= 3) {
                loading(true);
                this.searchICDkoder2(search, loading, this);
            }
        },
        searchICDkoder2: debounce((search, loading, vm) => {
            return klinikenApi.get(`/intyg/kodverk/icdkoder/all/?q=${escape(search)}`, getConfig()).then((res) => {
                vm.optionsICDkoder2 = res.data.map((obj) => {
                    return {
                        value: obj.id,
                        label: `${obj.code} ${obj.displayName}`,
                        ...obj,
                    };
                });
                loading(false);
            });
        }, 350),
        onSearchICDkoder3(search, loading) {
            if (search.length >= 3) {
                loading(true);
                this.searchICDkoder3(search, loading, this);
            }
        },
        searchICDkoder3: debounce((search, loading, vm) => {
            return klinikenApi.get(`/intyg/kodverk/icdkoder/all/?q=${escape(search)}`, getConfig()).then((res) => {
                vm.optionsICDkoder3 = res.data.map((obj) => {
                    return {
                        value: obj.id,
                        label: `${obj.code} ${obj.displayName}`,
                        ...obj,
                    };
                });
                loading(false);
            });
        }, 350),

        scrollToError($formEl) {
            const errorWrapperEl = $formEl.querySelector('[data-has-errors="true"]');
            const errorWrapperInput = errorWrapperEl && errorWrapperEl.querySelector("input,select,textarea");
            if (errorWrapperInput) {
                errorWrapperInput.focus();
                errorWrapperInput.scrollIntoView({ block: "center", behavior: "smooth" });
            }
        },
        checkAndShowErrorInputfield(data) {
            data.hasValidationErrors().then((hasErrors) => {
                // hasErrors returning true or false based on if input field validation="required", backend validation is not working
                if (hasErrors) {
                    this.scrollToError(data.form.$el);
                } else {
                    data.values().then((data) => {
                        // if field is not set to required, this part willl always be executed
                        // form is validated and all required fields are filled
                    });
                }
            });
        },
        setId6(svar1, svar2, svar3) {
            let svar_id6 = [];
            const arr = [svar1, svar2, svar3];

            for (var i = 0; i < arr.length; i++) {
                if (arr[i]) {
                    svar_id6.push({
                        icdkod: arr[i],
                    });
                }
            }

            return svar_id6;
        },

        async saveIntygsmall(intygsmall) {
            return await klinikenApi.post(
                `intyg/mallar/${this.typAvIntyg.code.toLowerCase()}/`,
                intygsmall,
                getConfig()
            );
        },
        async updateIntygsmall(intygsmall) {
            const response = await klinikenApi.put(
                `/intyg/mallar/${this.typAvIntyg.code.toLowerCase()}/${intygsmall.id}/`,
                intygsmall,
                getConfig()
            );
            return response;
        },

        async submitForm(data) {
            const { svar_id6_1, svar_id6_2, svar_id6_3 } = data.form.$props.formulateValue;
            let _data = { ...data.form.$props.formulateValue, ...this.formData };
            if (_data.svar_id27 === "ja") {
                delete _data.svar_id1_1;
                delete _data.svar_id33;
                delete _data.svar_id39;
                delete _data.svar_id26;
                delete _data.svar_id40;
            }

            if (_data.svar_id27 === "inget") {
                _data.svar_id27 = null;
            } else if (_data.svar_id27 === "ja") {
                _data.svar_id27 = true;
            } else if (_data.svar_id27 === "nej") {
                _data.svar_id27 = false;
            }

            if (_data.svar_id39_1 !== "") {
                _data.svar_id39 = {
                    svar_id39_1: _data.svar_id39_1,
                    svar_id39_3: _data.svar_id39_3,
                };
            }
            if (_data.svar_id39 && _data.svar_id39_1 !== "ATER_X_ANTAL_DGR") {
                _data.svar_id39.svar_id39_3 = null;
            }
            delete _data.svar_id39_1;
            delete _data.svar_id39_3;

            if (_data.svar_id26 && _data.svar_id26.svar_id26_1 == "false") {
                delete _data.svar_id26;
            }

            if (_data.svar_id33_1 === "inget") {
                _data.svar_id33_1 = null;
            } else if (_data.svar_id33_1 === "ja") {
                _data.svar_id33_1 = true;
            } else if (_data.svar_id33_1 === "nej") {
                _data.svar_id33_1 = false;
            }
            if (!_data.svar_id33_1) {
                _data.svar_id33_2 = null;
            }
            _data.svar_id33 = {
                svar_id33_1: _data.svar_id33_1,
                svar_id33_2: _data.svar_id33_2,
            };
            delete _data.svar_id33_2;
            delete _data.svar_id33_1;
            if (_data.svar_id26_1) {
                _data.svar_id26 = {
                    svar_id26_1: _data.svar_id26_1,
                    svar_id26_2: _data.svar_id26_2,
                };
            } else {
                _data.svar_id26 = { svar_id26_1: false, svar_id26_2: null };
            }
            delete _data.svar_id26_1;
            delete _data.svar_id26_2;
            if (_data.svar_id40 === [] && _data.svar_id40_ejaktuellt === []) {
                delete _data.svar_id40;
            } else {
                if (_data.svar_id40_ejaktuellt && _data.svar_id40_ejaktuellt.length > 0) {
                    _data.svar_id40 = [{ svar_id40_1: "EJ_AKTUELLT" }];
                } else {
                    _data.svar_id40 = _data.svar_id40
                        ? _data.svar_id40.map((value) => {
                              return { svar_id40_1: value };
                          })
                        : [];
                }
            }
            delete _data.svar_id40_ejaktuellt;
            _data.svar_id6 = [];
            _data.svar_id6 = this.setId6(
                _data.svar_id6_1?.value ? _data.svar_id6_1.value : _data.svar_id6_1,
                _data.svar_id6_2?.value ? _data.svar_id6_2.value : _data.svar_id6_2,
                _data.svar_id6_3?.value ? _data.svar_id6_3.value : _data.svar_id6_3
            );

            delete _data.svar_id6_1;
            delete _data.svar_id6_2;
            delete _data.svar_id6_3;

            if (!this.kollaeftrOVRIGAATGARDER) {
                _data.svar_id44 = null;
            }
            _data.typ = this.typAvIntyg.id;

            let response;
            try {
                if (this.editMode) {
                    response = await this.updateIntygsmall(_data);
                } else {
                    response = await this.saveIntygsmall(_data);
                }
                if (response && response.status === 201) {
                    this.$store.dispatch("intyg/loadIntygsmallar", this.typAvIntyg.id);
                    this.$store.dispatch("tabs/closeTab", this.tabId);
                    flashMessage("Intygsmall sparad");
                }
                if (response && response.status === 200) {
                    this.$store.dispatch("intyg/loadIntygsmallar", this.typAvIntyg.id);
                    this.$store.dispatch("tabs/closeTab", this.tabId);
                    flashMessage("Intygsmall uppdaterad");
                }
            } catch (e) {
                if (e.response) {
                    await this.$refs.dialog.show({
                        title: this.editMode ? "Kunde inte uppdatera intygsmall" : "Kunde inte spara intygsmall",
                        variant: "warning",
                        okButton: "OK",
                    });
                    this.formErrors = ["e.response.data"];
                    this.inputErrors = parseError(e.response.data);
                    this.checkAndShowErrorInputfield(data);
                }
            }
        },

        cancel() {
            this.closeTab(this.hasUnsavedData);
        },

        close() {
            this.$store.dispatch("tabs/closeTab", this.tabId);
            this.closeTab(this.hasUnsavedData);
        },
    },

    async created() {
        if (this.parameters) {
            if (this.parameters.typ) {
                this.typAvIntyg = await this.getIntygsTyp(this.parameters.typ);
            }
            if (this.parameters.id) {
                this.editMode = true;
                await this.$store.dispatch("intyg/loadIntygsmall", this.parameters);
                let formData = cloneDeep(this.intygsmall);

                let newArray = [];
                if (formData.svar_id40 && formData.svar_id40.length > 0) {
                    for (let i = 0; i < formData.svar_id40.length; i++) {
                        if (formData.svar_id40[i].svar_id40_1 === "EJ_AKTUELLT") {
                            formData.svar_id40_ejaktuellt = [formData.svar_id40[i].svar_id40_1];
                            formData.svar_id40 = [];
                        } else {
                            newArray.push(formData.svar_id40[i].svar_id40_1);
                        }
                    }
                    formData.svar_id40 = newArray;
                }
                if (formData.svar_id33 !== null) {
                    formData.svar_id33_1 = formData.svar_id33.svar_id33_1;
                    formData.svar_id33_2 = formData.svar_id33.svar_id33_2;
                }
                delete formData.svar_id33;
                if (formData.svar_id39) {
                    formData.svar_id39_1 = formData.svar_id39.svar_id39_1;
                    formData.svar_id39_3 = formData.svar_id39.svar_id39_3;
                }
                if (formData.svar_id26) {
                    formData.svar_id26_1 = formData.svar_id26.svar_id26_1;
                    formData.svar_id26_2 = formData.svar_id26.svar_id26_2;
                }

                if (formData.svar_id6.length > 0) {
                    formData.svar_id6_1 = {
                        value: formData.svar_id6[0].icdkod_display.id,
                        label: `${formData.svar_id6[0].icdkod_display.code} ${formData.svar_id6[0].icdkod_display.displayName}`,
                        ...formData.svar_id6[0].icdkod_display,
                    };
                }
                if (formData.svar_id6.length > 1) {
                    formData.svar_id6_2 = {
                        value: formData.svar_id6[1].icdkod_display.id,
                        label: `${formData.svar_id6[1].icdkod_display.code} ${formData.svar_id6[1].icdkod_display.displayName}`,
                        ...formData.svar_id6[1].icdkod_display,
                    };
                }
                if (formData.svar_id6.length > 2) {
                    formData.svar_id6_3 = {
                        value: formData.svar_id6[2].icdkod_display.id,
                        label: `${formData.svar_id6[2].icdkod_display.code} ${formData.svar_id6[2].icdkod_display.displayName}`,
                        ...formData.svar_id6[2].icdkod_display,
                    };
                }

                if (formData.svar_id27 === null) {
                    formData.svar_id27 = "inget";
                } else if (formData.svar_id27 === true) {
                    formData.svar_id27 = "ja";
                } else if (formData.svar_id27 === false) {
                    formData.svar_id27 = "nej";
                }

                if (formData.svar_id33_1 === null) {
                    formData.svar_id33_1 = "inget";
                } else if (formData.svar_id33_1 === true) {
                    formData.svar_id33_1 = "ja";
                } else if (formData.svar_id33_1 === false) {
                    formData.svar_id33_1 = "nej";
                }

                this.formData = formData;
                this.$store.commit("tabs/setTabName", {
                    id: this.tabId,
                    name: "Redigera intygsmall",
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_variables";

::v-deep {
    .formulate-input[data-classification="box"] > div {
        display: flex;
        position: relative;
        margin-top: 5px;

        .formulate-input-label--after {
            margin-left: 10px;
        }
    }

    .vs__selected {
        position: absolute;
        left: 35px;
        top: 8px;
    }

    .formulate-input-element-decorator {
        display: block;
        width: 24px;
        height: 24px;
        border: 1px solid $color-input-border;
        border-radius: 3px;
    }

    .formulate-input[data-has-value] > .formulate-input-wrapper > div > .formulate-input-element-decorator {
        color: #ffffff;
        background: $color-choosen
            url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+)
            50% 40% no-repeat;
        border: 1px solid $color-input-border;
    }

    .formulate-input[data-has-value] > .formulate-input-wrapper > div > label {
        background: red;
    }

    // start hide the original browser default checkbox
    input[type="checkbox"] {
        position: absolute;
        left: -9999px;
    }

    // end hide the original browser default checkbox

    .textarea-full-width {
        max-width: 100% !important;
    }

    .btn-group {
        display: flex;
    }

    .sendBtn {
        background-color: $primary;
        padding: 20px 30px;
        max-height: 100px !important;
        display: flex;
        border: none;
        border-radius: 4px;
        margin-right: 5px;
    }

    .sendBtn > span {
        color: white;
    }

    button {
        background: transparent;
        border: none;
    }

    .full-width {
        width: 100%;
    }

    .vs__dropdown-toggle {
        padding-bottom: 0px;
    }

    .vs__search {
        margin-top: 0px;
    }

    .relative {
        position: relative;
    }

    .wrapper {
        position: relative;
    }

    .formulate-input-wrapper {
        margin-bottom: 10px;
    }

    .formulate-input-group {
        display: flex;
        align-items: flex-start;
    }

    .formulate-input-grouping {
        flex-grow: 1;
    }

    .formulate-input-group-repeatable {
        display: flex;
        position: relative;
    }

    .remove-button {
        order: 100;
        z-index: 1000;
        top: 0;
        right: 0;
        transform: translateX(100%);
        display: flex;
        position: absolute;
    }

    .column-checkbox-group {
        display: flex;
        flex-direction: column;
    }
}
</style>
