<template>
    <div id="app">
        <StartView v-if="hasCurrentProfile" />
        <KlinikenFrame v-else />
        <ConfirmDialog />
        <Popup />
    </div>
</template>

<script setup>
    import { useStore } from "@/store";
    import { computed, onMounted } from "vue";
    import StartView from "@/components/StartView.vue";
    import KlinikenFrame from "@/components/KlinikenFrame.vue";
    import ConfirmDialog from "@/components/ConfirmDialog.vue";
    import Popup from "@/components/Popup.vue";

    const store = useStore();
    const hasCurrentProfile = computed(() => !store.state.userData.currentProfile);

    onMounted(async () => {
        await store.dispatch("systemData/loadFromBackend", {
            failSilently: true,
        });
    });
</script>

<style lang="scss">
    @import "normalize.css";
    @import "@/style/app";
    @import "@/style/tooltips/klinikentooltip";
</style>
