import dialogEvents from "@/dialogEvents";
import Samtycke from "@/tabs/Samtycke/Samtycke.vue";
import { openDialog } from "@/utils";
import getUrlParams from "@/utils/getUrlParams";
import tabComponents from "@/tabs";
import { useStore } from "@/store";

export default function useHandleEntryParameters() {
    const store = useStore();

    const openTab = (tab) => store.dispatch("tabs/openTab", tab);

    async function handleEntryParameters() {
        try {
            const urlParams = getUrlParams();

            // Tömer location.search
            history.replaceState({}, document.title, window.location.pathname);

            if (urlParams.patient) {
                await store.dispatch("patientData/_loadFromBackend", { id: urlParams.patient });
                delete urlParams.patient;
            }

            if (urlParams.tab || urlParams.modul) {
                let tab = urlParams.tab ?? urlParams.modul;
                delete urlParams.tab;
                delete urlParams.modul;

                // Recept ligger både som standalone-app och tabb. Den förra har inte tillgång till parameters via
                // tabs. Man behöver därför sätta parametrar i store istället.
                if (tab.toLowerCase() === "prescriptionset")
                    await store.commit("prescriptionSet/setExternalParameters", urlParams);

                // Fulhack för att akut hotfixa att uthoppsadressen från Skreg inte refaktoriserats samtidigt som
                // övriga journalmodulen utan fortfarande pekar på "&modul=note".
                if (tab.toLowerCase() === "note") tab = "journalanteckning";

                if (tab.toLowerCase() === "journalanteckning") {
                    let samtyckeExists = await checkSamtycke();
                    if (!samtyckeExists) {
                        dialogEvents.$emit("open", {
                            type: "warning",
                            description: "Den här patienten har inte registrerat samtycke. Vill du registrera ett nu?",
                            buttons: [
                                {
                                    title: "Nej",
                                    type: "secondary",
                                },
                                {
                                    title: "Ja",
                                    type: "primary",
                                    action: () => {
                                        openTab({ component: Samtycke });
                                    },
                                },
                            ],
                        });
                    }
                }

                const component = getComponent(tab);

                openTab({ component, parameters: urlParams });
            }
        } catch (e) {
            openDialog("Något gick fel vid inhoppet, kontakta support.", "error");
        }
    }

    async function checkSamtycke() {
        let patientId = store.getters["patientData/currentId"];
        await store.dispatch("samtycke/loadPatientsamtycken", patientId);
        await store.dispatch("samtycke/fetchTyperAvSamtycke");
        let samtyckesTyper = store.getters["samtycke/typerAvSamtycke"];
        let samtyckesTypExists = false;
        for (let i = 0; i < samtyckesTyper.length; i++) {
            if (samtyckesTyper[i].kod.indexOf("utlamning_skaderegistret") !== -1) samtyckesTypExists = true;
        }
        if (!samtyckesTypExists) return true;
        let samtycken = store.getters["samtycke/patientsamtycken"];
        for (let i = 0; i < samtycken.length; i++) {
            let samtyckesTyp = samtycken[i].typavsamtycke_display.kod.toLowerCase();
            let valid = samtycken[i].is_current;
            if (samtyckesTyp.indexOf("utlamning_skaderegistret") !== -1 && valid) return true;
        }
        return false;
    }

    function getComponent(name) {
        let component;
        const entries = Object.entries(tabComponents);
        entries.forEach((entry) => {
            if (name.toLowerCase() === entry[0].toLowerCase()) component = entry[1];
        });
        return component;
    }

    return {
        handleEntryParameters,
    };
}
