import { VueRenderer } from "@tiptap/vue-2";
import tippy from "tippy.js";

import FrasList from "@/components/Editor/FrasList.vue";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

export default async ({ frasApiUrl = "/core/templates/" } = {}) => {
    let options = [];

    try {
        const response = await klinikenApi.get(frasApiUrl, getConfig());

        if (response.status === 200) {
            const { data } = response;

            if (data.length > 0) {
                options = data.map(({ description, field_name }) => {
                    return {
                        value: field_name,
                        text: description,
                    };
                });
            }
        }
    } catch (error) {
        openDialog(getErrorMessage(error), "error");
    }

    return {
        items: ({ query }) => {
            return options
                .filter(({ value }) => value.toLowerCase().includes(query.toLowerCase()))
                .map(({ value, text }) => ({
                    text,
                    value,
                    command: async ({ editor }) => {
                        const content = {
                            type: "fras",
                            content: [{
                                type: "text",
                                text: value,
                            }],
                        };

                        const anchorPosition = editor.state.selection.$anchor.pos;
                        const previousText = editor.state.selection.$anchor.nodeBefore.text;
                        const indexOfTrigger = previousText.lastIndexOf("$");

                        await editor.commands.deleteRange({
                            from: anchorPosition - (previousText.length - indexOfTrigger),
                            to: anchorPosition,
                        });
                        await editor.commands.insertContentAt(editor.state.selection.$cursor.pos, content);
                        await editor.commands.focus();
                    },
                }));
        },

        render: () => {
            let component;
            let popup;

            return {
                onStart: (props) => {
                    component = new VueRenderer(FrasList, {
                        // using vue 2:
                        // parent: this,
                        // propsData: props,
                        // using vue3: props directly on object
                        propsData: props,
                    });

                    if (!props.clientRect) {
                        return;
                    }

                    popup = tippy("body", {
                        getReferenceClientRect: props.clientRect,
                        appendTo: () => document.body,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: "manual",
                        placement: "bottom-start",
                    });
                },

                onUpdate(props) {
                    component.updateProps(props);

                    if (!props.clientRect) {
                        return;
                    }

                    popup[0].setProps({
                        getReferenceClientRect: props.clientRect,
                    });
                },

                async onExit({ editor, text, range, query }) {
                    if (query !== "" && options.find(({ value }) => value === text)) {
                        editor.commands.insertContentAt({
                            from: range.from,
                            to: range.to,
                        }, [{
                            type: "fras",
                            content: [{
                                type: "text",
                                text: text,
                            }],
                        }]);
                    }

                    popup[0].destroy();
                    component.destroy();
                },
            };
        },
    };
};
