<template>
    <div class="alertInformation vit-bg">
        <alert-information-main :alertInformationSymbol="alertInformationSymbol"></alert-information-main>
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import { mapActions, mapGetters } from "vuex";

import AlertInformationMain from "@/tabs/AlertInformation/AlertInformationMain.vue";
import tabEvents from "@/tabEvents";

export default {
    namespaced: true,
    extends: BaseTab,
    patientBound: true,
    name: "AlertInformation",
    tabName: "Uppmärksamhetssignaler",
    components: {
        "alert-information-main": AlertInformationMain,
    },
    computed: {
        ...mapGetters("patientData", ["patient"]),
        ...mapGetters("alertInformation", ["alertInformationSymbol", "alertInformationList"]),
    },
    methods: {
        ...mapActions("alertInformation", ["loadAlertInformationSymbol", "loadAlertInformationList"]),
    },
    created() {
        this.loadAlertInformationSymbol({ patientId: this.patient.id });
        this.loadAlertInformationList({ patientId: this.patient.id });
        tabEvents.$emit("openingAlertInformationTab", {});
    },
    beforeDestroy() {
        tabEvents.$emit("closingAlertInformationTab", {});
    },
};
</script>

<style scoped lang="scss">
@import "@/style/_variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/forms";
/* Bootstrap - End */

@import "@/style/_deprecated_main";
</style>
