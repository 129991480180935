<template>
    <div class="kallelsemallar">
        <modal-basic ref="dialog" />
        <div class="header">
            <h1>Kallelsemallar</h1>
            <button type="button" @click="openCreateKallelseTab">
                <img src="@/assets/plus_circle_blue.svg" />
                <span>Skapa kallelsemall</span>
            </button>
        </div>

        <search-filter v-model="filterValues" :fields="filterFields">
            <template v-slot:header-filters>
                <FormulateInput class="search-field" name="q" placeholder="Sök" />
            </template>
            <template v-slot:fields>
                <FormulateInput type="text" name="namn__icontains" label="Mall namn" />
                <FormulateInput type="text" name="typ" label="Typ av kallelsemall " />
                <FormulateInput
                    type="vueselect"
                    class="autocomplete"
                    name="created_by"
                    label="Skapad av"
                    :options="createdBy"
                />
            </template>
        </search-filter>

        <kliniken-table
            :items="items"
            :columns="columns"
            :count="kallelsemallarCount"
            :dropdown-actions="dropdownActions"
            :has-dropdown="hasDropdown"
            @redigeraKallelsemall="redigeraKallelsemall"
            @dupliceraKallelsemall="dupliceraKallelsemall"
            @raderaKallelsemall="raderaKallelsemall"
        />
    </div>
</template>

<script>
    import BaseTab from "@/tabs/mixins/BaseTab.js";
    import KlinikenTable from "@/components/Table/KlinikenTable.vue";
    import SearchFilter from "@/components/SearchFilter.vue";
    import KallelsemallForm from "@/tabs/Operation/Kallelser/KallelsemallForm.vue";
    import { getConfig, getErrorMessage, klinikenApi } from "@/api";
    import { flashMessage, openDialog } from "@/utils";
    import EditIcon from "@/assets/actionIcons/edit.svg";
    import DeleteIcon from "@/assets/actionIcons/delete.svg";
    import DuplicateIcon from "@/assets/actionIcons/duplicate.svg";
    import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
    import emitter from "tiny-emitter/instance";

    export default {
        extends: BaseTab,
        name: "Kallelsemallar",
        tabName: "Kallelsemallar",
        components: {
            KlinikenTable,
            SearchFilter,
            ModalBasic,
        },
        data() {
            return {
                filterValues: {},
                sortField: "",
                items: [],
                columns: [
                    {
                        title: "Mall namn",
                        name: "namn",
                        sort: "namn",
                    },
                    {
                        title: "Typ av kallelsemall",
                        name: "typ_display",
                        sort: "typ",
                    },
                    {
                        title: "Senast använd",
                        name: "updated_at",
                        type: "date",
                    },
                    {
                        title: "Skapat av",
                        name: "created_by_display.user_display.name",
                        sort: "created_by",
                    },
                ],
                dropdownActions: [
                    {
                        name: "redigeraKallelsemall",
                        title: "Redigera",
                        icon: EditIcon,
                        show: () => {
                            return true;
                        },
                    },
                    {
                        name: "dupliceraKallelsemall",
                        title: "Duplicera",
                        icon: DuplicateIcon,
                        show: () => {
                            return true;
                        },
                    },
                    {
                        name: "raderaKallelsemall",
                        title: "Radera",
                        icon: DeleteIcon,
                        show: () => {
                            return true;
                        },
                    },
                ],
                hasDropdown: true,
                kallelsemallarCount: 0,
                createdBy: [],
            };
        },
        computed: {
            filterFields() {
                return {
                    namn__icontains: { label: "Namn " },
                    typ: { label: "Typ " },
                    created_by: { label: "Skapat av ", options: this.createdBy },
                    q: { label: "Sök " },
                };
            },
        },

        methods: {
            async openCreateKallelseTab() {
                await this.$store.dispatch("tabs/closeTabWithUnsavedDataCheck", KallelsemallForm);
                this.$store.dispatch("tabs/openTab", {
                    component: KallelsemallForm,
                });
            },

            async redigeraKallelsemall(options = {}) {
                await this.$store.dispatch("tabs/closeTabWithUnsavedDataCheck", KallelsemallForm);
                this.$store.dispatch("tabs/openTab", {
                    component: KallelsemallForm,
                    parameters: { ...options, isEditing: true },
                });
            },

            async raderaKallelsemall({ id }) {
                if (!id) {
                    throw new Error("Missing id");
                }

                const confirm = await this.$refs.dialog.show({
                    variant: BasicModalVariant.WARNING,
                    title: "Vill du verkligen radera hela kallelsemallen?",
                    okButton: "Ja, radera",
                    cancelButton: "Nej, avbryt",
                });

                if (!confirm) {
                    return;
                }

                await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });

                try {
                    const response = await klinikenApi.delete(`/kallelser/mallar/${id}/`, getConfig());
                    if (response.status === 204) {
                        flashMessage("Kallelsemall raderad");
                        this.items = this.items.filter((item) => item.id === id);
                        await this.fetchMallar();

                        emitter.emit("kallelsemall");
                    }
                } catch (error) {
                    openDialog(getErrorMessage(error), "error");
                } finally {
                    await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
                }
            },

            async dupliceraKallelsemall(options = {}) {
                const { id, ...rest } = options;

                await this.$store.dispatch("tabs/closeTabWithUnsavedDataCheck", KallelsemallForm);
                this.$store.dispatch("tabs/openTab", {
                    component: KallelsemallForm,
                    parameters: { ...rest, isDuplicating: true },
                });
            },

            async fetchMallar() {
                await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });

                const response = await klinikenApi.get("/kallelser/mallar/", getConfig());

                if (response.status === 200) {
                    this.items = response.data.results;
                }
                await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
            },
        },
        async created() {
            await this.fetchMallar();
            emitter.on("kallelsemall", this.fetchMallar);
        },
    };
</script>

<style lang="scss" scoped>
    .kallelsemallar {
        .header {
            display: flex;
            align-items: center;
            gap: 40px;
            margin-bottom: 26px;

            h1 {
                margin: 0 !important;
                font-family: Roboto;
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 34px;
                color: #277692;
            }

            .button-hollow:hover {
                background-color: transparent;
            }

            button {
                background: transparent;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    margin-right: 8px;
                }

                span {
                    color: #6c8188;
                    font-family: "Roboto Medium", serif;
                    font-size: 16px;
                    line-height: 0;
                    white-space: nowrap;
                }
            }
        }

        .search-field {
            width: 384px;
        }
        .kliniken-table {
            margin-top: 1rem;

            ::v-deep {
                thead,
                tbody {
                    background-color: #f6f8fb;
                }
            }
        }
    }
</style>
