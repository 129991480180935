const KlinikenToolTip = {
    "kliniken-tooltip": {
        $extend: "tooltip",
        placement: "bottom",
        delay: { show: 500 },
        distance: 10,
        skidding: 50,
        arrowPadding: 10,
    },
};

export { KlinikenToolTip };
