<template>
    <div class="recept-favoriter">
        <section class="personliga-favoriter">
            <h2>Personliga Favoriter</h2>
            <SelectActionTable
                v-for="fav in personligaFavoriter()"
                :key="fav.id"
                :columns="favouriteTableColumns"
                :items="fav.favoritrader"
                :data="fav"
                :id="'fav-' + fav.id"
                class="favorite-group"
                :label-actions="favouriteLabelActions"
                :required-fields="requiredFields"
                :disabled-row-handler="rightToPrescribeItem"
                :collapse-label="getStatusText(fav)"
                @favoritRedigera="favoritRedigera"
                @favoritRadera="favoritRadera"
            />
            <Button
                variant="contour"
                size="secondary"
                v-if="getPersonligaFavoriter.length > 5 && allPrivateShow"
                @click="allPrivateShow = false"
            >
                Visa alla ({{ getPersonligaFavoriter.length }})
            </Button>
        </section>
        <section class="organisationiska-favoriter">
            <h2>Gemensamma Favoriter</h2>
            <SelectActionTable
                v-for="fav in gemensamaFavaoriter()"
                :key="fav.id"
                :columns="favouriteTableColumns"
                :items="fav.favoritrader"
                :data="fav"
                :id="'fav-' + fav.id"
                class="favorite-group"
                :label-actions="favouriteLabelActions"
                :required-fields="requiredFields"
                :disabled-row-handler="rightToPrescribeItem"
                :collapse-label="getStatusText(fav)"
                @favoritRedigera="favoritRedigera"
                @favoritRadera="favoritRadera"
            />
            <Button
                variant="contour"
                size="secondary"
                v-if="getGemensammaFavoriter.length > 5 && allGemensamaShow"
                @click="allGemensamaShow = false"
            >
                Visa alla ({{ getGemensammaFavoriter.length }})
            </Button>
        </section>
        <section
            v-show="currentProfile.user.is_superuser || currentProfile.user.is_verksamhetsadmin"
            class="andras-personliga-favoriter"
        >
            <h2>Andras Favoriter</h2>
            <SelectActionTable
                v-for="fav in andrasPersonligaFavoriter()"
                :key="fav.id"
                :columns="favouriteTableColumns"
                :items="fav.favoritrader"
                :data="fav"
                :id="'fav-' + fav.id"
                class="favorite-group"
                :label-actions="favouriteLabelActions"
                :required-fields="requiredFields"
                :disabled-row-handler="rightToPrescribeItem"
                :collapse-label="getStatusText(fav)"
                @deleteFavorit="deleteFavorit"
            />
            <Button
                variant="contour"
                size="secondary"
                v-if="getAndrasPersonligaFavoriter.length > 5 && allOthersShow"
                @click="allOthersShow = false"
            >
                Visa alla ({{ getAndrasPersonligaFavoriter.length }})
            </Button>
        </section>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseTab from "@/tabs/mixins/BaseTab";
import tabIcon from "@/assets/receptforskrivning.svg";
import Favorit from "./Favorit.vue";
import SelectActionTable from "@/components/generic/SelectActionTable.vue";
import dialogEvents from "@/dialogEvents";
import Button from "@/components/Button.vue";

const SIL_APPROVED = [
    "MEP", // läkemedel
    "VBL", // växtbaserade läkemedel
    "ALL", // allergen
    "NLM", // naturläkemedel
    "VUM", // vissa utvärtes läkemedel
    "RAP", // radiofarmaka
];

const FAVOURITE_LABEL_ACTIONS = [
    { name: "favoritRedigera", title: "Redigera", img: "edit.svg" },
    { name: "favoritRadera", title: "Radera", img: "delete.svg" },
];

const FAVOURITE_COLUMNS = [
    {
        name: "drugArticle.distributedTradeName",
        title: "Namn",
        renderer: (item) => {
            let returnString = item.drugArticle.distributedTradeName;
            if (item.is_withdrawn) returnString = "<strike>" + returnString + "</strike>";
            if (item.drugArticle.isRecommended) returnString += ' <span class="label">REK</span>';
            if (!SIL_APPROVED.includes(item.drugArticle.productTypeCode))
                returnString += ' <span class="label">IGL</span>';
            returnString +=
                '<br/><span class="manufacturer">' +
                (item.drugArticle.localRepresentative || item.drugArticle.marketingAuthHolder || "") +
                "</span>";
            return returnString;
        },
    },
    {
        name: "drugArticle.strengthNumeric",
        title: "Styrka",
        renderer: (item) => {
            return item.drugArticle.strengthText;
        },
    },
    {
        name: "numberOfPackages",
        title: "Förpackning(ar)",
        renderer: (item) => {
            if (item.medicinalProduct === "00000000000000") return "";
            return (
                (item.numberOfPackages === null || item.numberOfPackages === undefined
                    ? "ej angivet antal"
                    : item.numberOfPackages) +
                " förp. à " +
                item.drugArticle.packSizeText
            );
        },
    },
    {
        name: "dispensingRepeatNumber",
        title: "Antal uttag",
        renderer: (item) => {
            return item.medicinalProduct !== "00000000000000" ? item.dispensingRepeatNumber : "";
        },
    },
    {
        name: "unstructuredDosageAdmin",
        title: "Ordination",
        renderer: (item) => {
            return item.medicinalProduct !== "00000000000000" ? item.unstructuredDosageAdmin : "";
        },
    },
];
const FAVOURITE_ACTIONS = [
    {
        name: "favourite::add",
        title: "Lägg till i beställning",
        type: "primary",
        // img: addToOrder,
    },
];

export default {
    extends: BaseTab,
    name: "Favoriter",
    icon: tabIcon,
    tabName: "Receptfavoriter",
    patientBound: false,
    components: {
        SelectActionTable,
        Button,
    },
    data() {
        return {
            favouriteTableColumns: FAVOURITE_COLUMNS,
            favouriteLabelActions: FAVOURITE_LABEL_ACTIONS,
            requiredFields: [],
            listMenyActions: FAVOURITE_LABEL_ACTIONS,
            collapse: false,
            favouriteActions: FAVOURITE_ACTIONS,
            allGemensamaShow: true,
            allPrivateShow: true,
            allOthersShow: true,
        };
    },
    computed: {
        ...mapGetters("prescriptionSet", [
            "items",
            "favourites",
            "getGemensammaFavoriter",
            "getPersonligaFavoriter",
            "getAndrasPersonligaFavoriter",
        ]),
        ...mapGetters("userData", ["currentProfile"]),
    },
    methods: {
        ...mapActions("prescriptionSet", ["fetchBothFavoriter", "deleteFavoritItem"]),
        personligaFavoriter() {
            if (this.allPrivateShow && this.getPersonligaFavoriter.length > 5) {
                return this.getPersonligaFavoriter.slice(0, 5);
            } else {
                return this.getPersonligaFavoriter;
            }
        },

        andrasPersonligaFavoriter() {
            if (this.allOthersShow && this.getAndrasPersonligaFavoriter.length > 5) {
                return this.getAndrasPersonligaFavoriter.slice(0, 5);
            } else {
                return this.getAndrasPersonligaFavoriter;
            }
        },
        gemensamaFavaoriter() {
            if (this.allGemensamaShow && this.getGemensammaFavoriter.length > 5) {
                return this.getGemensammaFavoriter.slice(0, 5);
            } else {
                return this.getGemensammaFavoriter;
            }
        },
        rightToPrescribeItem: () => true,
        getStatusText(favorit) {
            let anvandare = " Personlig favorit skapad av " + (favorit.anvandare ? favorit.anvandare.name : "okänd");
            let gemensam =
                " Gemensam favorit skapad av " +
                (favorit.created_by && favorit.created_by.user && favorit.created_by.user.name
                    ? favorit.created_by.user.name
                    : "okänd");
            return (
                favorit.namn +
                " - " +
                (favorit.anvandare ? anvandare : gemensam) +
                " (" +
                favorit.favoritrader.length +
                ")"
            );
        },
        async favoritRedigera(item) {
            this.$store.commit("prescriptionSet/setFavorit", item);
            await this.$store.dispatch("tabs/openTab", { component: Favorit, parameters: item });
        },
        async favoritRadera(item) {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Är du säker på att du vill radera favoriten?",
                buttons: [
                    {
                        title: "Nej",
                        type: "secondary",
                    },
                    {
                        title: "Ja",
                        type: "primary",
                        action: async () => {
                            await this.deleteFavoritItem(item);
                            await this.fetchBothFavoriter(this.currentProfile.user);
                        },
                    },
                ],
            });
        },
    },
    async created() {
        await this.fetchBothFavoriter(this.currentProfile.user);
    },
};
</script>

<style lang="scss">
@import "@/style/variables";

.recept-favoriter {
    section {
        margin-top: 30px;
    }

    .favorite-group {
        margin: 10px 0;
    }

    img {
        margin-right: 8px;
    }

    .manufacturer {
        font-weight: normal;
        font-size: 12px;
        display: inline-block;
        color: inherit;
    }

    .favorite-group {
        .label {
            border-radius: 2px;
            padding-left: 2px;
            padding-right: 2px;
            font-weight: bold;
            font-size: 12px;
            margin-top: 2px;
            color: $color-label-grey;
            border: 1px solid $color-border-grey;
            max-height: 17px;
            line-height: 17px;
        }
    }
}
</style>
