<template>
    <FormulateForm v-model="formData" @submit="submit" autocomplete="off" class="form">
        <div class="top-div">
            <div class="aktiv-patient">Aktiv patient</div>
            <div class="logo-div">
                <div class="patient-div">
                    <img src="@/assets/dialogs/patient.svg" />
                </div>
                <div class="patient-details">
                    <div>
                        <h1>{{ patient.personId }}</h1>
                        <h1>{{ patient.namn }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 40px"></div>
        <div class="first-row">
            <FormulateInput type="datepicker" label="Datum" name="date" />
            <FormulateInput type="timepicker" useNow label="Tid" name="time" />
        </div>
        <div style="height: 40px"></div>
        <div class="second-row">
            <FormulateInput type="vueselect" label="Sal" name="sal" :options="salar" />
            <FormulateInput type="vueselect" label="Operatör" name="huvudoperator" :options="operatorer" />
        </div>
        <div style="height: 60px"></div>
        <ButtonGroup class="buttongroup">
            <Button type="submit">
                <span>{{ uppdaterar ? "Redigera bokning" : "Boka operation" }}</span>
            </Button>
            <Button type="button" variant="contour" size="secondary" @click="cancel()">Avbryt</Button>
        </ButtonGroup>
        <KlinikenPopup
            v-if="showpopupmodal"
            :popupProp="showpopupmodal"
            typofPopup="warning"
            @cancelPopup="cancelPopup"
            @closePopup="closeTabAndclean"
            @closeAndReturnTrue="closeAndReturnTrue"
            componentName="boka operation"
            :popupMessage="popupFields"
        >
        </KlinikenPopup>
    </FormulateForm>
</template>
<script>
    import dialogEvents from "@/dialogEvents";
    import { mapActions, mapGetters } from "vuex";
    import Button from "@/components/Button.vue";
    import { fetchData, getHourMinuteFromTime, getNullIfEmpty, profileOptions, salarOptions } from "./utils";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import KlinikenPopup from "@/components/Popup/KlinikenPopup.vue";
    import { getConfig, klinikenApi } from "@/api";

    export default {
        name: "BokaOperation",
        components: { Button, ButtonGroup, KlinikenPopup },
        props: {
            value: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                params: this.$attrs.response,
                operationsanmalan: {},
                patient: {
                    personId: "",
                    namn: "",
                },
                salar: [],
                operatorer: [],
                uppdaterar: false,
                formData: {
                    date: "",
                    time: "",
                    sal: "",
                    huvudoperator: "",
                },
                showpopupmodal: false,
                payload: {},
                popupFields: {},
            };
        },
        computed: {
            ...mapGetters("operation/operationsanmalan", ["getAlreadyBooked"]),
        },
        methods: {
            ...mapActions("operation/operationsanmalan", ["bokaOpAnmalan", "resetOverbokning"]),
            cancelPopup(isOpen) {
                this.showpopupmodal = false;
                this.cancel();
            },
            async closeAndReturnTrue(_boolean) {
                this.showpopupmodal = false;
                this.payload.overboka = true;
                await this.bokaOpAnmalan(this.payload);
                this.cancel();
            },
            cancel() {
                dialogEvents.$emit("closePopup");
                this.$emit("closePopup");
                this.$emit("close");
            },
            async submit() {
                this.payload.overboka = true;
                this.payload = {
                    id: this.operationsanmalan.id,
                    bokad_operation_datum: this.formData.date,
                    bokad_operation_tid: getNullIfEmpty(this.formData.time),
                    bokad_sal: this.formData.sal,
                    bokad_huvudoperator: this.formData.huvudoperator,
                };
                await this.bokaOpAnmalan(this.payload);
                if (this.getAlreadyBooked.overbokad) {
                    this.popupFields = {
                        message: "Operationsresurser som du vill använda är redan bokade på denna tid.",
                        buttonsTitles: {
                            cancel: "Avbryt",
                            action: "Gå vidare med bokning ändå",
                        },
                        typofAction: "donotremoveTab",
                        errorList: this.getAlreadyBooked.errorList,
                    };
                    this.showpopupmodal = true;
                }
                this.resetOverbokning();
                if (!this.showpopupmodal) {
                    this.cancel();
                }
            },
        },

        async created() {
            this.salar = salarOptions(await fetchData({ url: "/operation/salar/" }));
            const lista_personal = await klinikenApi.get(
                "/core/profiles/",
                getConfig({
                    params: { roll__code: "kirurg" },
                })
            );

            this.operationsanmalan = this.params?.listItem ?? this.value;

            this.uppdaterar = this.operationsanmalan.status === "Bokad";

            this.patient.personId = this.operationsanmalan.patient_display.personId;
            this.patient.namn = this.operationsanmalan.patient_display.namn;

            this.formData.date = this.operationsanmalan.current_bokad_operation_datum
                ? this.operationsanmalan.current_bokad_operation_datum
                : "";

            this.formData.time = this.operationsanmalan.current_bokad_operation_tid
                ? getHourMinuteFromTime(this.operationsanmalan.current_bokad_operation_tid)
                : "";

            this.formData.sal = this.operationsanmalan.current_bokad_sal
                ? `${this.operationsanmalan.current_bokad_sal}`
                : "";
            this.formData.huvudoperator = this.operationsanmalan.current_bokad_huvudoperator
                ? this.operationsanmalan.current_bokad_huvudoperator
                : "";
            this.operatorer = lista_personal.data.results
                .filter((personal) => {
                    return personal.is_active === true || this.formData.huvudoperator === personal.pk;
                })
                .map((personal) => {
                    return {
                        value: personal.pk,
                        label: personal.user_display.name,
                    };
                });
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/style/variables";
    @import "@/style/modal.scss";

    .form {
        padding: 0 50px;

        .top-div {
            .aktiv-patient {
                margin-left: 70px;
                color: $color-text-black;
            }

            .logo-div {
                display: flex;

                .patient-details {
                    margin-left: 5px;

                    div {
                        margin-left: 8px;

                        h1 {
                            color: $dialog-information;
                            margin: 0;
                            font-size: 20px; /* Roboto Regular */
                            line-height: 28px;
                            letter-spacing: normal;
                            align-items: end;
                        }
                    }
                }

                .patient-div {
                    width: 60px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $operationImageBackground;
                    border-radius: 50px;

                    img {
                        width: 30px;
                        height: 30px;
                        //filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
                    }
                }
            }
        }

        .first-row {
            display: flex;
            justify-content: space-between;
        }

        .second-row {
            display: flex;
            justify-content: space-between;
        }

        .formulate-input {
            color: grey;
            width: 45%;
            height: 46px;
        }
    }
</style>
