<template>
    <div>
        <textarea
            v-if="!preview"
            v-model="_value"
            v-bind="$attrs"
            :name="name"
            :required="required"
            :maxlength="maxlength"
            :minlength="minlength"
        />
        <span v-else class="input-preview">
            {{ _value }}
        </span>
    </div>
</template>

<script>
export default {
    name: "TextAreaWidget",
    props: {
        name: String,
        value: String,
        required: Boolean,
        maxlength: Number,
        minlength: Number,
        preview: Boolean,
        disabled: Boolean,
    },
    computed: {
        _value: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit("input", v);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/forms";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
/* Bootstrap - End */

@import "@/style/deprecated_main";

.text-area {
    resize: none;
}

form .error input,
form .error textarea,
form .error select,
form input.error,
form select.error,
form textarea.error {
    border-bottom: 4px solid #9e354b !important;
}
</style>
