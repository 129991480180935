<script setup>
import { computed, ref } from "vue";

const props = defineProps({
    headerHeight: {
        type: String,
        default: "0px",
    },
    footerHeight: {
        type: String,
        default: "0px",
    },
});

const printing = ref(false);

const print = () => {
    printing.value = true;
    setTimeout(window.print, 1000);
    setTimeout(() => {
        printing.value = false;
    }, 1000);
};

const headerHeightSyle = computed(() => {
    return `height: ${props.headerHeight}`;
});

const footerHeightSyle = computed(() => {
    return `height: ${props.footerHeight}`;
});

defineExpose({
    print,
});
</script>

<template>
    <div class="printable-content" :class="{ printing: printing }">
        <div class="page-header" :style="headerHeightSyle">
            <slot name="header"></slot>
        </div>

        <div class="page-footer" :style="footerHeightSyle">
            <slot name="footer"></slot>
        </div>

        <table>
            <thead>
                <tr>
                    <td class="page-content">
                        <!--place holder for the fixed-position header-->
                        <div class="page-header-space" :style="headerHeightSyle">&nbsp;</div>
                    </td>
                </tr>
            </thead>

            <tfoot>
                <tr>
                    <td>
                        <!--place holder for the fixed-position footer-->
                        <div class="page-footer-space" :style="footerHeightSyle">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>

            <tbody>
                <tr>
                    <td>
                        <slot></slot>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
.printable-content {
    table {
        width: 100%;
    }

    .page-header,
    .page-header-space,
    .page-footer,
    .page-footer-space {
        display: none;
    }

    .page-header {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .page-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}

@media print {
    .printable-content.printing {
        height: 100%;

        .page-header,
        .page-header-space,
        .page-footer,
        .page-footer-space {
            display: block;
        }
    }
}
</style>
