<template>
    <FormulateForm v-show="false" @submit="submit" :errors="errors" autocomplete="off">
        <h3>Föbered din BankID-app</h3>
        <ButtonGroup align="center">
            <Button type="submit" role="button" size="secondary">{{ submitButton }}</Button>
            <Button type="button" @click="cancel" size="secondary" variant="contour">Avbryt</Button>
        </ButtonGroup>
    </FormulateForm>
</template>

<script setup>
    import { toRefs, computed, ref } from "vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import Button from "@/components/Button.vue";

    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({}),
        },
        submitButton: {
            type: String,
            default: "Logga in",
        },
        personnummer: {
            type: String,
            default: null,
        },
    });

    const { errors, submitButton, personnummer } = toRefs(props);

    const disabled = computed(() => {
        return personnummer.value;
    });

    const error = ref(null);

    const emit = defineEmits(["error", "cancel"]);

    const submit = async (formData) => {
        emit("submit", formData);
    };

    const cancel = () => {
        emit("cancel");
    };
</script>
