<template>
    <div class="suggest-wrap">
        <!--
    Important! keyup.esc.stop and keydown.enter.prevent necessary to prevent these keys from propagating to form
-->
        <input
            v-model="q"
            :required="required"
            :name="name + 'Display'"
            v-on:input="updateValue"
            autocomplete="off"
            class="text-input"
            type="search"
            @keyup.esc.stop="validate"
            @keydown.down="moveDown"
            @keydown.up="moveUp"
            @keydown.enter.prevent="settle"
            @blur="
                validate();
                hasFocus(false);
            "
            @focus="hasFocus(true)"
            @wheel.prevent.stop="mouseWheel($event)"
            v-bind="$attrs"
        />
        <input type="hidden" :name="name" :value="value_" />

        <ul class="suggest-list" v-show="isOpen" @wheel.prevent.stop="mouseWheel($event)">
            <li
                v-for="(item, index) in this.results"
                :key="index"
                @mouseenter="highlightedPosition = index"
                @mousedown="select"
                :name="name + '-suggest'"
                :class="['suggest-item', { highlighted: index === highlightedPosition }]"
            >
                <slot name="item"
                    ><strong v-if="displayCode">{{ item.code }}</strong> {{ item.displayName }}
                </slot>
            </li>
        </ul>
    </div>
</template>

<script>
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";
import linkEvents from "@/linkEvents";

const SEARCH_WAIT = 200;
const isInteger = RegExp("^[0-9]+$");

export default {
    name: "AutoCompleteWidget",
    props: {
        name: String,
        required: Boolean,
        maxlength: String,
        apisearch: String,
        extraParams: Object, // An object specifying additional parameters to be passed to backend, such as filters
        value: undefined, // Should be Object, but can be Number until backend catches up
        displayCode: Boolean, // if code should be shown or not
        /**
         * In case the data from backend is NOT a JSON object with the format
         * { id, code, displayName }, a map Function must be supplied by the parent
         */
        map: Function,
        uid: Number, // Will only be passed if parent is an ExpandableSuggest
    },
    data() {
        return {
            API_SEARCH: this.apisearch,
            id: this.value ? this.value.id : "",
            displayName: this.value ? this.value.displayName : "",
            code: this.value ? this.value.code : "",
            searchTimeout: false,
            loading: false,
            results: [],
            isOpen: false,
            highlightedPosition: NaN,
            q: this.displayName,
            focus: false,
        };
    },
    computed: {
        value_() {
            if (this.id)
                return JSON.stringify({
                    id: this.id,
                    code: this.code,
                    displayName: this.displayName,
                });
            else return null;
        },
        Q() {
            if (this.displayCode && this.code !== "") {
                if (this.q.includes(this.code)) {
                    return this.code;
                } else return this.q || "";
            } else return this.q || "";
        },
    },
    methods: {
        validate() {
            this.isOpen = false;
            if (this.results.length === 0) {
                this.q = "";
                this.id = "";
                this.code = "";
                this.displayName = "";
            } else {
                this.id = this.results[0].id;
                this.code = this.results[0].code;
                this.displayName = this.results[0].displayName;
                this.q = (this.displayCode ? this.results[0].code + " " : "") + this.results[0].displayName;
            }
            this.valueChanged();
        },
        search() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            if (this.Q.length >= 1) {
                if (this.API_SEARCH) this.searchTimeout = setTimeout(this.fetchResults, SEARCH_WAIT);
                else if (this.dataList) this.searchTimeout = setTimeout(this.fetchData, SEARCH_WAIT);
            } else {
                this.results = [];
            }
        },
        fetchResults() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            return klinikenApi
                .get(
                    this.API_SEARCH,
                    getConfig({
                        params: {
                            ...this.extraParams,
                            ...{
                                // merge basic params with extraParams property
                                q: this.Q,
                                limit: 10,
                            },
                        },
                    })
                )
                .then((response) => {
                    if (!this.map) this.results = response.data.results;
                    else this.results = response.data.results.map(this.map);

                    if (this.results.length === 1) this.highlightedPosition = 0;
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                })
                .then(() => {
                    this.loading = false;
                    this.searchTimeout = false;
                });
        },
        moveDown() {
            if (!this.isOpen) return;
            if (isNaN(this.highlightedPosition)) this.highlightedPosition = 0;
            else this.highlightedPosition = (this.highlightedPosition + 1) % this.results.length;
        },
        moveUp() {
            if (!this.isOpen) return;
            this.highlightedPosition =
                this.highlightedPosition - 1 < 0 ? this.results.length - 1 : this.highlightedPosition - 1;
        },
        select() {
            this.id = this.results[this.highlightedPosition].id;
            this.displayName = this.results[this.highlightedPosition].displayName;
            this.code = this.results[this.highlightedPosition].code;
            this.q = ((this.displayCode ? this.code + " " : "") + this.displayName).trim();
            this.results = [this.results[this.highlightedPosition]];
            this.isOpen = false;
            this.valueChanged();
        },
        settle(event) {
            if (this.isOpen && !isNaN(this.highlightedPosition)) this.select();
            event.stopPropagation();
        },
        updateValue() {
            this.isOpen = true;
            this.highlightedPosition = NaN;
        },
        update(update) {
            if (!update.value) {
                this.id = "";
                this.code = "";
                this.displayName = "";
                this.q = "";
                this.isOpen = false;
                return;
            } else {
                this.initialize(update.value);
            }
        },
        valueChanged() {
            /**
             * If parent is an ExpandableSuggest (ie index is defined), pass event only to parent. Otherwise, pass to global eventbus like any other component
             */
            if (this.uid)
                this.$emit("autocomplete_changed", {
                    uid: this.uid,
                    value: this.value_,
                });
            else linkEvents.$emit(this.name + "_changed", this.value_);
        },
        initialize(value) {
            const config = getConfig();
            if (import.meta.JEST_WORKER_ID) {
                return;
            }
            // Hard coded api to check if the user is editing language
            // When serching for languages fetchResults methods fires

            if (this.API_SEARCH === "/core/kodverk/iso_639_1/") {
                // To save lekemedel with default language is not saveing svenska. To make sure the value is an object so we take code from it otherwise it the choosen language
                if (typeof value === "object") {
                    value = value.code;
                }
                klinikenApi
                    .get(this.API_SEARCH + value, getConfig())
                    .then((response) => {
                        value = response.data;
                        if (this.map) value = this.map(value);
                        this.code = value.code;
                        this.displayName = value.displayName;
                        this.q = this.displayName;
                    })
                    .catch((e) => {
                        openDialog(getErrorMessage(e), "error");
                    });
            } else {
                /**
                 * Initialize values.
                 * Case 0: value is a number in String format -> convert to Int
                 */
                if (isInteger.test(value)) value = parseInt(value);
                /**
                 * Case: 1 value is a number in Int format
                 */
                if (Number.isInteger(value)) {
                    klinikenApi
                        .get(this.API_SEARCH + value + "/", config)
                        .then((response) => {
                            value = response.data;
                            if (this.map) value = this.map(value);
                            this.id = value.id;
                            this.code = value.code;
                            this.displayName = value.displayName;
                            if (this.displayCode) this.q = (this.code + " " + this.displayName).trim();
                            else this.q = this.displayName;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.dir(e);
                        });
                } else if (typeof value === "string" && value !== "") {
                    /**
                     * Case 2: value is String
                     */
                    if (this.displayCode)
                        // value is a string with both code and text, used in JournalAnteckning.vue
                        value = value.split(" ")[0];
                    // text only is currently only languageOfLabel

                    klinikenApi
                        .get(this.API_SEARCH, getConfig({ params: { q: value } }))
                        .then((response) => {
                            value = response.data.results[0];
                            if (this.map) value = this.map(value);
                            this.id = value.id;
                            this.code = value.code;
                            this.displayName = value.displayName;
                            if (this.displayCode) this.q = (this.code + " " + this.displayName).trim();
                            else this.q = this.displayName;
                        })
                        .catch((e) => {
                            openDialog(getErrorMessage(e), "error");
                        });
                } else if (typeof value === "object" && value !== null) {
                    /**
                     * Case 3: value in a Object
                     */
                    // value is in format matching database (such as when intially read)
                    if (this.map && !(value.id && value.code && value.displayName)) {
                        value = this.map(value);
                    }
                    this.id = value.id;
                    this.code = value.code;
                    this.displayName = value.displayName;
                    if (this.displayCode) this.q = (this.code + " " + this.displayName).trim();
                    else this.q = this.displayName;
                }
            }
        },
        mouseWheel(event) {
            if (event.deltaY > 0) this.moveDown();
            else this.moveUp();
        },
        hasFocus(focus) {
            if (focus)
                // first time input gets focus
                this.focus = true;
            if (!focus && this.focus)
                // every time input loses focus
                linkEvents.$emit(this.name + "_focus");
        },
    },
    created() {
        linkEvents.$on("update_" + this.name, this.update);
    },
    mounted() {
        this.initialize(this.value);
    },
    watch: {
        q() {
            this.search();
        },
        /**
         * value (a prop) can change as a result of ExpandableSuggest changing indeces. When that happens, re-initialize values.
         */
        value() {
            this.initialize(this.value);
        },
    },
};
</script>

<style lang="sass" scoped>
.suggest-wrap
    position: relative

.suggest-list
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
    position: absolute
    left: 0
    top: 48px
    width: 100%
    z-index: 100
    background: #ffffff
    padding: 0px !important

.suggest-item
    list-style: none
    border: 1px solid #EEE
    margin: 0
    padding: 0
    border-width: 0 1px 1px 1px
    padding: .4rem

    &:first-of-type
        border-top: 1px solid #EEE

.highlighted
    background: #eee
    cursor: pointer
</style>
