<template>
    <div class="suggest-wrap">
        <!--
        Important! keyup.esc.stop and keydown.enter.prevent necessary to prevent these keys from propagating to form
    -->
        <template v-if="!preview">
            <input
                v-model="q"
                :required="required"
                :name="name + 'Display'"
                @input="updateValue()"
                v-bind="$attrs"
                autocomplete="off"
                class="text-input"
                type="search"
                @keyup.esc.stop="validate()"
                @keydown.down="moveDown"
                @keydown.up="moveUp"
                @keydown.enter.prevent="settle"
                @blur="
                    validate();
                    $emit('blur');
                "
                :displayCode="displayCode"
            />
            <input type="hidden" :name="name" :value="id" />

            <ul ref="suggest-list" class="suggest-list" v-show="isOpen" @wheel="mouseWheel($event)">
                <li
                    v-for="(item, index) in this.results"
                    :key="index"
                    @mouseenter="highlightedPosition = index"
                    @mousedown="select"
                    :name="name + '-suggest'"
                    :class="['suggest-item', { highlighted: index === highlightedPosition }]"
                >
                    <slot name="item"
                        ><strong v-if="displayCode">{{ item.code }}</strong> {{ item.displayName }}
                    </slot>
                </li>

                <div v-if="loading && results.length > 0">
                    <span style="color: #277692">Loading...</span>
                </div>
            </ul>
        </template>
        <span v-else class="input-preview">
            {{ q }}
        </span>
    </div>
</template>

<script>
import SuggestWidget from "./SuggestWidget.vue";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

export default {
    name: "InfiniteWidget",
    extends: SuggestWidget,
    methods: {
        fetchResults(url = this.API_SEARCH, scroll = false) {
            if (this.loading) {
                return;
            }
            this.loading = true;

            const objectParams = {
                ...this.extraParams,
                ...{
                    q: this.Q,
                },
            };

            let params;
            if (url === this.API_SEARCH) {
                this.results = [];
                params = objectParams;
            } else if (scroll === true) {
                params = {};
            } else {
                params = objectParams;
            }

            return klinikenApi
                .get(url, getConfig({ params }))
                .then((response) => {
                    // this.results = response.data.results.map(this.map);
                    this.results.push(...response.data.results.map(this.map));
                    this.nextStep = response.data.next;
                    this.backStep = response.data.previous;
                    this.results.forEach((item) => {
                        this.itemCache[item.id] = item;
                    });
                    if (this.results.length === 1) this.highlightedPosition = 0;
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                })
                .then(() => {
                    this.loading = false;
                    this.searchTimeout = false;
                });
        },
        async mouseWheel(event) {
            let hasScroll = false;
            let el = this.$refs["suggest-list"];
            const { clientHeight, scrollHeight, scrollTop } = el;

            await this.$nextTick(() => {
                hasScroll = el.scrollHeight > el.clientHeight;
            });
            if (!hasScroll) {
                event.preventDefault();
                event.stopPropagation();
                if (event.deltaY > 0) this.moveDown();
                else this.moveUp();
            }

            if (hasScroll) {
                // scrollen när den ligger högst upp
                if (clientHeight + scrollTop >= scrollHeight) {
                    if (this.nextStep == null) {
                        return;
                    }
                    this.fetchResults(this.nextStep, hasScroll);
                }
            }
        },
    },
};
</script>

<style lang="sass" scoped>
@import "@/style/mixins/_input"

input
    @include input-mixin
    width: 100%
    background-image: url("@/assets/search.svg")
    background-origin: padding-box
    background-repeat: no-repeat
    background-position: right 10px center
    padding-right: 20px

.suggest-wrap
    position: relative

.suggest-list
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
    position: absolute
    left: 0
    top: 48px
    width: 100%
    z-index: 1000
    background: #F8FAFC
    padding: 0px !important
    max-height: 390px
    overflow-y: auto

.suggest-item
    list-style: none
    border: 1px solid #EEE
    margin: 0
    padding: 0
    border-width: 0 1px 1px 1px
    padding: .4rem

    &:first-of-type
        border-top: 1px solid #EEE

.highlighted
    background: #eee
    cursor: pointer
</style>
