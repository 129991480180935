<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import StatistikSearchBar from "@/tabs/Skreg/StatistikSearchBar.vue";
import KlinikenDropDownMenu from "@/components/Table/KlinikenDropDownMenu.vue";
import CircularProgressBar from "@/components/CircularProgressBar.vue";
import ActionIconOpanmalan from "@/assets/actionIcons/show.svg";
import SummaryTable from "@/tabs/Skreg/Statistik/Narvarostatistik/SummaryTable.vue";

export default {
    extends: BaseTab,
    patientBound: true,
    name: "NarvarostatistikTableRow",
    tabName: "Närvarostatistik lag",

    components: {
        StatistikSearchBar,
        KlinikenDropDownMenu,
        CircularProgressBar,
        SummaryTable,
        ActionIconOpanmalan,
    },

    props: {
        headers: { type: Array },
        rowIndex: 0,
        rowData: { type: Object },
        toggleRow: false,
        toggleable: false,
        dropdownActions: { type: Array },
        dropdownOption: { type: String },
        index: { type: Number },
        s_num: null,
    },

    data() {
        return {
            visibleRows: [],
            isShown: this.toggleRow,
        };
    },

    methods: {
        toggleDiv() {
            this.isShown = !this.isShown;
        },
        dropdownaction({ action, item }) {
            this.$emit("action", { action, item });
        },
        parseToInteger(value) {
            return parseInt(value);
        },
        hasSpecialCharacter(str) {
            var specialCharRegex = /[%]/;
            return specialCharRegex.test(str);
        },
    },
    computed: {},
};
</script>

<template>
    <tbody
        v-if="rowData.data?.total"
        @click="$emit('tableRowClicked', rowData.spelare)"
        class="narvarostatistik-table-row"
    >
        <tr class="header-row lightline-border-bottom">
            <td colspan="1" class="col-1 td-display line-height">
                <div class="patient-name-div padding-left-20">
                    <img
                        v-if="toggleable"
                        src="@/assets/dropdown-arrow.svg"
                        :class="{
                            rotated180: isShown,
                            rotateBackTo0: !isShown,
                        }"
                        alt=""
                        @click="toggleDiv(rowIndex)"
                    />
                    <span class="name-div" @click="toggleDiv(rowIndex)">
                        {{ rowData.spelare?.spelarelag[0]?.trojnummer }}.
                        <p class="name mobile">{{ rowData.spelare?.patient_display.namn }}</p>
                    </span>
                </div>
                <div class="data-titles">
                    <h4>Totalt</h4>
                </div>
            </td>
            <td v-for="(totalCellValue, totalIndex) in rowData.data?.total" class="">
                <div class="td-display line-height align-right" :key="totalIndex">
                    <CircularProgressBar
                        v-if="hasSpecialCharacter(totalCellValue)"
                        :progressProps="parseToInteger(totalCellValue)"
                        :height="1.2"
                        :width="1.2"
                    />

                    <div class="align-right" v-else>{{ totalCellValue }}</div>
                </div>
            </td>
            <td class="skadad">
                <span v-if="rowData.data?.sista_oavstamd_skadekort !== null" class="">
                    <div class="content-right"><img class="" src="@/assets/skadekortjournalred.svg" /> Skadad</div>
                </span>
                <p v-else></p>
            </td>
            <td colspan="1" class="padding-right-20">
                <kliniken-drop-down-menu :dropdownActions="dropdownActions" :item="rowData" @action="dropdownaction" />
            </td>
        </tr>
        <tr v-if="isShown || toggleRow || !toggleable" class="match-row nested-row-border">
            <td colspan="1" class="col-1 td-display line-height">
                <div class="data-titles">
                    <h4 class="">Match</h4>
                </div>
            </td>
            <td class="" v-for="(matchCellValue, matchCellIndex) in rowData.data?.match" :key="matchCellIndex">
                <div colspan="10" class="td-display line-height">
                    <CircularProgressBar
                        v-if="hasSpecialCharacter(matchCellValue)"
                        :progressProps="parseToInteger(matchCellValue)"
                        :height="1.2"
                        :width="1.2"
                    />
                    <p v-else class="align-right">{{ matchCellValue }}</p>
                </div>
            </td>
        </tr>
        <tr v-if="isShown || toggleRow || !toggleable" class="traning-row nested-row-border">
            <td colspan="1" class="col-1 td-display line-height">
                <div class="data-titles">
                    <h4 class="">Träning</h4>
                </div>
            </td>
            <td class="" v-for="(trainingCellValue, trainingCellIndex) in rowData.data?.traning">
                <div colspan="10" class="">
                    <CircularProgressBar
                        v-if="hasSpecialCharacter(trainingCellValue)"
                        :progressProps="parseToInteger(trainingCellValue)"
                        :height="1.2"
                        :width="1.2"
                    />
                    <div v-else class="td-display line-height align-right">{{ trainingCellValue }}</div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.narvarostatistik-table-row {
    tr {
        color: $color-text-black;
        line-height: 1rem;
        font-size: 14px;
        h4 {
            font-family: Roboto Medium;
            font-style: normal;
            line-height: 16px;
            font-size: 14px;
        }
    }
    h4 {
        margin: 0;
    }
    .padding-top {
        padding-top: 0.5rem;
    }
    .padding-left-20 {
        padding-left: 1rem;
    }
    .padding-right-20 {
        padding-right: 1rem;
    }
    .lightline-border-bottom {
        border-top: 0.05rem solid $color-table-row-line;
    }
    .content-right {
        display: flex;
        justify-content: flex-end;
    }
    .align-right {
        text-align: right;
    }
    .line-height {
        height: 3.25rem;
    }
    .td-display {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .nested-row-border {
        border-top: 0.025rem solid $divider-color;
    }
    .col-1 {
        position: relative;
        display: flex;
        justify-content: flex-end;
        min-width: 18.75rem;
        .patient-name-div {
            width: 7.9rem;
            position: absolute;
            left: 0;
            .name-div {
                display: flex;
                .name {
                    white-space: nowrap;
                }
                @media only screen and (max-width: 120rem) {
                    .name {
                        white-space: normal;
                    }
                }
            }
        }
        .data-titles {
            position: absolute;
            right: 0;
            width: 3.7rem;
            @media only screen and (max-width: 120rem) {
            }
        }
    }
    .header-row {
        .col-1 .patient-name-div {
            display: flex;
            img {
                width: 0.75rem;
                margin-right: 10px;
            }
            img:hover,
            span:hover {
                cursor: pointer;
            }
        }
        .skadad {
            img {
                margin-right: 0.2rem;
            }
        }
        .rotated180 {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        .rotateBackTo0 {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }
}
</style>
