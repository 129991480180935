<script>
import { v4 as uuidv4 } from "uuid";

export default {
    name: "InputCheckbox",
    data() {
        return {
            id: null,
        };
    },
    mounted() {
        this.id = uuidv4();
    },
};
</script>

<template>
    <label class="checkbox">
        <input :id="id" type="checkbox" v-bind="$attrs" v-on="$listeners" />
        <label :for="id"></label>
    </label>
</template>

<style lang="scss" scoped>
@import "@/style/variables";
@import "@/style/mixins/input";

.checkbox {
    input[type="checkbox"] {
        position: absolute;
        left: -999px;
        opacity: 0;

        & ~ label {
            display: inline-block;
            border-radius: 3px;
            width: 24px;
            height: 24px;
            border: 1px solid $color-input-border;
            background-color: #ffffff;
            position: relative;
            top: -2px;
        }

        &:disabled ~ label {
            opacity: $disabled-opacity;
        }

        &:focus ~ label {
            border: 1px solid $color-input-border;
        }

        &:checked ~ label {
            background: $color-choosen
                url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+)
                50% 40% no-repeat;
        }
    }
}
</style>
