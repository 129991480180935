<template>
    <div class="display-text-widget">
        <p :value="value" v-bind="$attrs" :name="name" class="text-input">{{ value }}</p>
    </div>
</template>

<script>
export default {
    name: "DisplayTextWidget",
    props: {
        name: String,
        value: String,
        preview: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.display-text-widget {
    height: 3rem;
    line-height: 3rem;
}
</style>
