import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";
import { mapGetters } from "vuex";

const getDefaultState = () => {
    return {
        typerAvSamtycke: [],
        patientsamtycken: [],
        inhamtningssatt: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    computed: {
        ...mapGetters("patientData", ["patient"]),
    },
    getters: {
        patientsamtycken(state) {
            return state.patientsamtycken;
        },
        typerAvSamtycke(state) {
            return state.typerAvSamtycke;
        },
        inhamtningssatt(state) {
            return state.inhamtningssatt;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setPatientsamtycken: (state, data) => {
            state.patientsamtycken = data;
        },
        setTyperAvSamtycke: (state, data) => {
            state.typerAvSamtycke = data;
        },
        setInhamtningssatt: (state, data) => {
            state.inhamtningssatt = data;
        },
        setPolling(state, polling) {
            state.polling = polling;
        },
        setStatus(state, status) {
            state.status = status;
        },
    },
    actions: {
        async savePatientsamtycke(context, data) {
            try {
                return await klinikenApi.post(`samtycke/patientsamtycken/${data.patient}/`, data, getConfig());
            } catch (e) {
                openDialog("Kunde inte spara samtycke: " + getErrorMessage(e), "warning");
            }
        },

        async updatePatientsamtycke(context, data) {
            try {
                return await klinikenApi.put(`samtycke/patientsamtycken/${data.id}/`, data, getConfig());
            } catch (e) {
                openDialog("Kunde inte uppdatera samtycke: " + getErrorMessage(e), "warning");
            }
        },

        async loadPatientsamtycken({ commit }, patient, failSilently = false) {
            const response = await klinikenApi.get(`samtycke/patientsamtycken/${patient}/`, getConfig());
            let patientsamtycken = response.data.results;

            try {
                commit("setPatientsamtycken", patientsamtycken);
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta uppgifter om patientsamtycken." + getErrorMessage(e), "warning");
                }
            }
        },

        async fetchTyperAvSamtycke({ commit }, failSilently = false) {
            try {
                const typerAvSamtyckeFetch = await klinikenApi.get(`samtycke/typer/`, getConfig());
                const typerAvSamtycke = typerAvSamtyckeFetch.data;
                if (typerAvSamtycke.length > 0) {
                    commit("setTyperAvSamtycke", typerAvSamtycke);
                } else {
                    openDialog("Den finns inga typer av samtycke", "warning");
                }
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta typer av samtycke." + getErrorMessage(e), "warning");
                }
            }
        },

        async fetchInhamtningssatt({ commit }, failSilently = false) {
            try {
                const response = await klinikenApi.get("samtycke/inhamtningssatt/", getConfig());
                const inhamtningssatt = response.data;
                if (inhamtningssatt.length > 0) {
                    commit("setInhamtningssatt", inhamtningssatt);
                } else {
                    openDialog("Inga inhämtningssätt finns.", "warning");
                }
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta inhämtningssätt." + getErrorMessage(e), "warning");
                }
            }
        },

        async makuleraSamtycke(context, data) {
            return await klinikenApi.put(
                `samtycke/patientsamtycken/${data.patientsamtycke.id}/nullify/`,
                {
                    nullifiedReason: data.patientsamtycke.reason,
                },
                getConfig()
            );
        },

        async aterkallaSamtycke(context, data) {
            return await klinikenApi.put(
                `samtycke/patientsamtycken/${data.patientsamtycke.id}/recall/`,
                {},
                getConfig()
            );
        },
    },
};
