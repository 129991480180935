<template>
    <div class="lista-sokord">
        <admin-lock-border />
        <h2>
            Sökord
            <hollow-button :icon="PlusCircleBlueIcon" label="Skapa nytt sökord" @click="createSokord()" />
        </h2>

        <custom-action-table
            class="no-top"
            :selectableRows="false"
            :columns="TableColumns"
            :items="sokords"
            :actions="TableActions"
        >
        </custom-action-table>

        <pagination
            class="pagination mt-5"
            v-model="page"
            :per-page="100"
            :records="count"
            :options="paginationOptions"
            @paginate="paginate"
        />
    </div>
</template>

<script>
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import BaseTab from "@/tabs/mixins/BaseTab";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import HollowButton from "@/components/widgets/HollowButton.vue";
import Pagination from "vue-pagination-2";
import PaginationTemplate from "@/components/widgets/PaginationTemplate.vue";
import PlusCircleBlueIcon from "@/assets/plus_circle_blue.svg";
import Sokord from "@/tabs/Admin/Sokord.vue";
import tabIcon from "@/assets/blank.png";
import { mapActions, mapGetters } from "vuex";

const noNull = (str) => {
    return str || "";
};

const TABLE_COLUMNS = [
    {
        name: "name",
        title: "Namn",
        template: (item) => {
            return noNull(item.namn);
        },
    },
    {
        name: "status",
        title: "Status",
        template: (item) => {
            if (!item.aktiv) return `<status-widget status="inaktiv" />`;
            else return `<status-widget status="aktiv" />`;
        },
    },
];

const TABLE_ACTIONS = [
    {
        name: "edit",
        title: "Redigera",
        img: "edit.svg",
        condition: () => {
            return true;
        },
    },
];

export default {
    extends: BaseTab,
    name: "ListSokord",
    tabName: "Sökord",
    icon: tabIcon,
    components: {
        AdminLockBorder,
        HollowButton,
        CustomActionTable,
        Pagination,
    },
    props: ["tabId"],
    data() {
        return {
            PlusCircleBlueIcon,
            TableColumns: TABLE_COLUMNS,
            TableActions: TABLE_ACTIONS,
            searchParameters: {},
            page: 1,
            paginationOptions: {
                theme: "bootstrap4",
                chunk: 10,
                template: PaginationTemplate,
                texts: {
                    count: "Visar {from} till {to} av {count} sökord|{count} sökord|Ett sökord",
                },
            },
        };
    },
    computed: {
        ...mapGetters("sokord", ["sokords", "count", "limit", "offset", "parameters"]),
    },
    methods: {
        ...mapActions("sokord", ["load", "setOffset", "setParameters", "resetParameters"]),
        createSokord() {
            this.$store.dispatch("tabs/openTab", { component: Sokord });
        },
        async paginate(page) {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            this.setOffset(page * 100 - 100);
            await this.load();
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },
    },
    async created() {
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
        this.setOffset(0);
        this.setParameters(this.searchParameters);
        await this.load();
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });

        this.$on("edit", async (data) => {
            await this.$store.dispatch("tabs/openTab", {
                component: Sokord,
                parameters: data,
            });
        });
        this.$root.$on("saveOrEdit", () => {
            this.page = 1;
        });
    },
    beforeDestroy() {
        this.$store.commit("sokord/reset");
    },
};
</script>

<style lang="scss" scoped>
h2 {
    .hollow-button {
        margin-left: 50px;
    }
}
</style>
