<template>
    <div class="operationskoordinering">
        <div class="operationskoordinering-headers">
            <h2>Operationskoordinering</h2>
            <CustomButton variant="link" @click="openKallelserOchSMS" :disabled="!canOpenKallelser">
                Kallelser och sms
            </CustomButton>
        </div>
        <filter-widget
            class="filter-widget"
            :queryInputs="queryInputs"
            :topSearchBar="true"
            label="Sök operationsanmälan"
            v-model="filterData"
            @search="search"
        />

        <div class="subheader">
            <span class="visar-antal-anmalningar">
                Visar <strong class="strong">{{ opAnmalans.length }}</strong> av
                <strong>{{ totalAnmalanCount }}</strong> träffar
            </span>
            <div class="subheader-right-div">
                <FormulateInput
                    type="checkbox"
                    @change="showStruknaOperationer"
                    v-model="showStrukna"
                    label="Visa strukna operationer"
                />
            </div>
        </div>

        <div ref="tabellWrapperAnmalningar" class="tabell-wrapper-anmalningar" id="tabell-wrapper-anmalningar">
            <custom-action-table
                :selectedItem="selectedItem"
                :selectableRows="true"
                @actionTable::select="selectPatient"
                @sortingButton="sort"
                :columns="opAnmalanTableColumns"
                :items="opAnmalans"
                :actions="opAnmalanListActions"
                :caption="tableCaption"
            />
        </div>
    </div>
</template>

<script>
    import AvbokaOperation from "@/tabs/Operation/AvbokaOperation.vue";
    import AvvaktaOperation from "@/tabs/Operation/AvvaktaOperation.vue";
    import SMSSend from "@/tabs/Operation/SMS/SMSSend.vue";
    import BaseTab from "@/tabs/mixins/BaseTab";
    import BokaOperation from "@/tabs/Operation/BokaOperation.vue";
    import CustomActionTable from "@/components/generic/CustomActionTable.vue";
    import FilterWidget from "@/components/widgets/FilterWidget.vue";
    import Operationsanmalan from "@/tabs/Operation/Operationsanmalan.vue";
    import OrsakTillAvbokningOchStrykning from "@/tabs/Operation/OrsakTillAvbokningOchStrykning.vue";
    import OrsakTillAvvaktan from "./OrsakTillAvvaktan.vue";
    import PlusCircleBlueIcon from "@/assets/plus_circle_blue.svg";
    import dialogEvents from "@/dialogEvents";
    import tabIcon from "@/assets/operationskoordinator.svg";
    import widgets from "@/components/widgets/inputs";
    import { Draggable } from "@fullcalendar/interaction";
    import { flashMessage, openDialog } from "@/utils";
    import { getConfig, getErrorMessage, klinikenApi } from "@/api";
    import { mapActions, mapGetters } from "vuex";
    import { statusar, statusPreanestesibedomningar } from "./utils.js";
    import { ModalsContainer, VueFinalModal } from "vue-final-modal";
    import DialogAktivPatient from "@/tabs/Operation/DialogAktivPatient.vue";
    import ModalContent from "@/components/generic/ModalContent.vue";
    import KallelseSend from "@/tabs/Operation/Kallelser/KallelseSend.vue";
    import Preanestesibedomning from "@/tabs/Operation/Preanestesibedomning.vue";
    import PreanestesibedomningVisa from "@/tabs/Operation/PreanestesibedomningVisa.vue";
    import CustomButton from "@/components/Button.vue";
    import SMSKallelser from "@/tabs/Operation/KallelserOchSms.vue";

    /**
     * @param {string} str
     * @returns {string}
     * Simple util function add "-" inbetween the social security number
     */
    const queryInputs = [
        {
            name: "preliminart_datum__gte",
            component: widgets.DateWidget,
            label: "Önskatdatum från",
            colspan: "2",
        },
        {
            name: "preliminart_datum__lte",
            component: widgets.DateWidget,
            label: "Önskatdatum till",
            colspan: "2",
        },
        {
            name: "patient__personId",
            component: widgets.PersonIdWidget,
            label: "PersonID",
            colspan: "2",
        },
        {
            name: "patient__namn",
            component: widgets.TextWidget,
            label: "Patientnamn",
            colspan: "2",
        },
        {
            name: "created_at__date__gte",
            component: widgets.DateWidget,
            label: "Skapat datum från",
            colspan: "2",
        },
        {
            name: "created_at__date__lte",
            component: widgets.DateWidget,
            label: "Skapat datum till",
            colspan: "2",
        },
        {
            name: "current_bokad_huvudoperator",
            component: widgets.SuggestWidget,
            label: "Operatör",
            parameters: {
                apisearch: "/core/profiles/",
                extraParams: { operator: true, is_active: true },
                map: (el) => {
                    return {
                        id: el.pk,
                        code: el.healthCareProviderHsaId,
                        displayName: el.user.name,
                    };
                },
            },
            colspan: "2",
        },
        {
            name: "status",
            component: widgets.SelectWidget,
            label: "Status",
            colspan: "2",
            parameters: {
                options: statusar.map((i) => {
                    return { text: i.label, value: i.value };
                }),
            },
        },
        {
            name: "typoperation",
            component: widgets.SelectWidget,
            label: "Typoperation",
            colspan: "2",
            parameters: {
                apisearch: "/operation/typoperationer/",
                map: (item) => {
                    return { value: item.id, text: item.namn };
                },
            },
        },
        {
            name: "huvudoperationskod",
            component: widgets.PopoutSuggestWidget,
            label: "Huvudoperationskod",
            colspan: "2",
            parameters: {
                apisearch: "/core/kodverk/kvakoder/",
                displayCode: true,
            },
        },
        {
            name: "huvuddiagnos",
            component: widgets.PopoutSuggestWidget,
            label: "Huvuddiagnos",
            colspan: "2",
            parameters: {
                apisearch: "/core/kodverk/icdkoder/",
                displayCode: true,
            },
        },
        {
            name: "prio",
            component: widgets.SelectWidget,
            label: "Prioritet",
            colspan: "2",
            parameters: { apisearch: "/operation/kodverk/prioriteter/" },
        },
        {
            name: "kort_varsel",
            component: widgets.CheckboxWidget,
            label: "Kort varsel",
            colspan: "2",
        },
        {
            name: "optid",
            component: widgets.NumberWidget,
            label: "Optid(min)",
            colspan: "2",
        },
        {
            name: "planerad_vardform",
            component: widgets.SelectWidget,
            label: "Vårdform",
            colspan: "2",
            parameters: {
                options: [
                    { text: "Sluten vård", value: "SLUTENVÅRD" },
                    { text: "Öppen vård", value: "ÖPPENVÅRD" },
                ],
            },
        },
        {
            name: "avtal__namn",
            component: widgets.SelectWidget,
            label: "Avtal",
            colspan: "2",
            parameters: {
                noResults: true,
                apisearch: "/core/avtal/",
                map: ({ namn }) => {
                    return { value: namn, text: namn };
                },
            },
        },
    ];

    const noNull = (str) => {
        return str || "";
    };

    const smsStatus = [
        {
            value: "Ej sms",
            label: "Ej sms",
            icon: "status_ej_sms",
        },
        {
            value: "Sms skickat",
            label: "Sms skickat",
            icon: "status_sms_send",
        },
        {
            value: "Ej skickat",
            label: "Ej skickat",
            icon: "status_sms_not_send",
        },
        {
            value: "Okänd",
            label: "Okänd",
            icon: "revoke",
        },
    ];

    function getSMSStatusTemplate(operationBokad = false, status) {
        if (!operationBokad) return "";
        let s = smsStatus.find((i) => i.value === status);
        if (!s) s = smsStatus.find((i) => i.value === "Okänd");
        return `<div style="display: flex; white-space: nowrap;"><img style="margin-right: 7px; min-width: 20px; height: 20px"
    :src="fetchImg('actionIcons/${s.icon}.svg')" alt="${s.label}"/> ${s.label}</div>`;
    }

    function getStatusTemplate(status) {
        const s = statusar.find((i) => i.value === status);
        if (!s) return;
        return `<div style="display: flex; align-items: center;"><img style="margin-right: 7px; min-width: 20px; height: 20px"
            :src="fetchImg('actionIcons/${s.icon}.svg')" alt="${s.label}"/> <span>${s.label}</span></div>`;
    }

    function getStatusPreanestesibedomningTemplate(status) {
        const s = statusPreanestesibedomningar.find((i) => i.value === status);
        if (!s) return "";
        return `<div style="display: flex; align-items: center;"><img style="margin-right: 7px; min-width: 20px; height: 20px"
            :src="fetchImg('actionIcons/statuspreanestesibedomning/${s.icon}.svg')" alt="${s.label}"/> <span>${s.label}</span></div>`;
    }

    const OP_REGISTRATION_TABLE_COLUMNS = [
        {
            name: "patient__personId",
            // name: "draggable", // Viktigt för drop funktionalitet i fullCalendar
            title: "PersonID",
            template: (item) => {
                // FullCalendar vill ha duration i formatet HH:MM
                let duration =
                    `${Math.floor(item.optid / 60)}`.padStart(2, "0") + ":" + `${item.optid % 60}`.padStart(2, "0");
                let classes = "person-id";

                if (item.status === "Klar för op") {
                    classes += " fc-event";
                } else if (item.status === "Bokad") {
                    classes += " booked";
                }

                let dataEvent = `{
                    &quot;title&quot;: &quot;&quot;,
                    &quot;id&quot;: &quot;${item.id}&quot;,
                    &quot;huvudoperator&quot;: &quot;${item.huvudoperator}&quot;,
                    &quot;duration&quot;: &quot;${duration}&quot;,
                    &quot;create&quot;: true
                }`;

                return `
                <div class="${classes}" id="${item.id}" data-event="${dataEvent}">
                    <div style=""><span style="margin: 0 8px">${item.patient_display.personId}</span></div>
                </div>
                `;
            },
            sortOrder: 2,
            hasSorting: true,
        },
        {
            name: "patient__namn",
            title: "Patientnamn",
            template: (item) => {
                return noNull(item.patient_display.fornamn) + " " + noNull(item.patient_display.efternamn);
            },
            hasSorting: true,
        },
        {
            name: "created_at",
            title: "Skapad",
            template: (item) => {
                return item.created_at ? new Date(item.created_at).toLocaleDateString("sv-SE") : "";
            },
            sortOrder: 1,
            hasSorting: true,
        },
        {
            name: "prio__ordning",
            title: "Prioritet",
            template: (item) => {
                return "<span style='white-space: nowrap;'>" + item.prio_display.displayName + "</span>";
            },
            hasSorting: true,
        },
        {
            name: "lateralitet",
            title: "Sida",
            template: (item) => {
                return (
                    (item.lateralitet === "HÖGER_SIDA" && "Höger") ||
                    (item.lateralitet === "VÄNSTER_SIDA" && "Vänster") ||
                    (item.lateralitet === "BILATERALT" && "Bilateralt") ||
                    (item.lateralitet === "EJ_AKTUELLT" && "")
                );
            },
            hasSorting: true,
        },
        {
            name: "kort_varsel",
            title: "Kort varsel",
            template: (item) => {
                return item.kort_varsel ? "Ja" : "";
            },
            hasSorting: true,
        },
        // {
        //     name: "preliminart_datum",
        //     title: "Önskat opdatum",
        //     template: (item) => {
        //         return item && item.preliminart_datum ? item.preliminart_datum : "";
        //     },
        //     hasSorting: true,
        // },
        {
            name: "current_bokad_huvudoperator",
            title: "Operatör",
            template: (item) => {
                return item.current_bokad_huvudoperator_display?.user_display?.name ?? "";
            },
            hasSorting: true,
        },

        {
            name: "optid",
            title: "Optid(min)",
            template: (item) => {
                return item.optid?.toString() ?? "";
            },
            hasSorting: true,
        },

        {
            name: "info_till_opplaneringen",
            title: "Info oplan",
            template: (item) => {
                if (item.info_till_opplaneringen) {
                    const info =
                        item.info_till_opplaneringen.length > 20
                            ? `${item.info_till_opplaneringen.slice(0, 20)}...`
                            : item.info_till_opplaneringen;
                    return info;
                }
                return "";
            },
            hasSorting: true,
        },
        {
            name: "typoperation__namn",
            title: "Typoperation",
            template: (item) => {
                return item && item.typoperation_display && item.typoperation_display.namn
                    ? item.typoperation_display.namn
                    : "";
            },
            hasSorting: true,
        },
        {
            name: "planerad_vardform",
            title: "Vårdform",
            template: (item) => {
                return item?.planerad_vardform_display ?? "";
            },
            hasSorting: true,
        },
        {
            name: "avtal__namn",
            title: "Avtal",
            template: (item) => {
                return item?.avtal_display?.namn ?? "";
            },
            hasSorting: true,
        },
        {
            name: "bokad_operation_datum",
            title: "Bokat opdatum",
            template: (item) => {
                return item && item.bokad_operation_datum ? item.bokad_operation_datum : "";
            },
            hasSorting: true,
        },
        {
            name: "bokad_sal__ordning",
            title: "Bokad sal",
            template: (item) => {
                return item && item.bokad_sal_display ? item.bokad_sal_display.namn : "";
            },
            hasSorting: true,
        },
        {
            name: "",
            title: "Kallelser och sms",
            template: (item) => {
                return getSMSStatusTemplate(item?.status === "Bokad", item?.sms_status);
            },
        },
        {
            name: "preanestesibedomning_godkannande__sortering",
            title: "Preanestesibedömning",
            template: (item) => {
                return getStatusPreanestesibedomningTemplate(item.preanestesibedomning_godkannande);
            },
            hasSorting: false,
        },
        {
            name: "status__sortering",
            title: "Status",
            template: (item) => {
                return getStatusTemplate(item.status);
            },
            hasSorting: true,
        },
    ];

    const OP_REGISTRATION_ROW_ACTIONS = [
        {
            title: "Redigera operationsanmälan",
            disabled: (item) =>
                !(
                    item.status !== undefined &&
                    [
                        "Att hantera",
                        "Väntar på preanestesibedömning",
                        "Väntar på viktnedgång",
                        "Väntar på rökstopp",
                        "Väntar på prover/undersökning",
                        "Preanestesibedömning genomförd",
                        "Klar för op",
                        "Bokad",
                        "Avbokad",
                        "Struken",
                        "Patientvald väntan",
                        "Medicinsk väntan",
                        "Administrativ väntan",
                        "Pågående",
                    ].includes(item.status) &&
                    actionDropdownMenuActionConditions.canEditAnmalan
                ),
            name: "openOpAnmalan",
            img: "edit.svg",
        },
        {
            title: "Avvakta operationen",
            disabled: (item) =>
                !(
                    item.status !== undefined &&
                    [
                        "Att hantera",
                        "Väntar på viktnedgång",
                        "Väntar på rökstopp",
                        "Väntar på prover/undersökning",
                        "Klar för op",
                        "Avbokad",
                        "Preanestesibedömning genomförd",
                    ].includes(item.status) &&
                    actionDropdownMenuActionConditions.canAvvaktaAnmalan
                ),
            name: "avvaktaOperation",
            img: "avvakta.svg",
        },
        {
            title: "Visa orsak till avvaktan",
            disabled: (item) =>
                !(
                    item.status !== undefined &&
                    ["Patientvald väntan", "Medicinsk väntan", "Administrativ väntan"].includes(item.status)
                ),
            name: "visaOrsaktillAvvaktan",
            img: "show.svg",
        },
        {
            title: "Boka operationen",
            disabled: (item) =>
                !(
                    item.status !== undefined &&
                    ["Klar för op"].includes(item.status) &&
                    actionDropdownMenuActionConditions.canBokaAnmalan
                ),
            name: "bokaOperation",
            img: "bokaoperation.svg",
        },
        {
            title: "Redigera bokningen",
            disabled: (item) =>
                !(
                    item.status !== undefined &&
                    ["Bokad"].includes(item.status) &&
                    actionDropdownMenuActionConditions.canBokaAnmalan
                ),
            name: "redigeraBokning",
            img: "edit.svg",
        },
        {
            title: "Avboka eller Stryk operationen",
            disabled: (item) =>
                !(
                    item.status !== undefined &&
                    [
                        "Att hantera",
                        "Väntar på viktnedgång",
                        "Väntar på rökstopp",
                        "Väntar på prover/undersökning",
                        "Klar för op",
                        "Bokad",
                        "Avbokad",
                        "Patientvald väntan",
                        "Medicinsk väntan",
                        "Administrativ väntan",
                        "Preanestesibedömning genomförd",
                    ].includes(item.status) &&
                    actionDropdownMenuActionConditions.canAvbokaAnmalan
                ),
            name: "avbokaOperation",
            img: "avboka.svg",
        },
        {
            title: "Visa orsak till avbokning och strykning",
            disabled: (item) =>
                !(
                    item.status !== undefined &&
                    ["Avbokad", "Struken"].includes(item.status) &&
                    actionDropdownMenuActionConditions.canVisaOrsakTillAvbokningOchStrykning
                ),
            name: "visaorsaktillavvaktanellerstryk",
            img: "show.svg",
        },
        {
            title: "Skapa kallelse",
            disabled: (item) =>
                !(
                    item.status !== undefined &&
                    ["Bokad"].includes(item.status) &&
                    actionDropdownMenuActionConditions.canSkapaKallelse
                ),
            name: "kallelseSend",
            img: "send.svg",
        },
        {
            title: "Skapa sms",
            disabled: ({ status, patient_display }) =>
                !(
                    status &&
                    status === "Bokad" &&
                    patient_display?.onskar_sms === true &&
                    actionDropdownMenuActionConditions.canSkapaSms
                ),
            name: "smsSend",
            img: "sms.svg",
        },
        {
            title: "Skapa preanestesibedömning",
            disabled: ({ status }) =>
                !(
                    status &&
                    status === "Väntar på preanestesibedömning" &&
                    actionDropdownMenuActionConditions.canSkapaPreanestesibedomning
                ),
            name: "skapaPreanestesibedomning",
            img: "skapapreanestesibedomning.svg",
        },
        {
            title: "Visa preanestesibedömning",
            disabled: (item) =>
                !(
                    item.aktuell_preanestesibedomning !== null &&
                    item.aktuell_preanestesibedomning !== undefined &&
                    actionDropdownMenuActionConditions.canVisaPreanestesibedomning
                ),
            name: "visaPreanestesibedomning",
            img: "skapapreanestesibedomning.svg",
        },
    ];
    const actionDropdownMenuActionConditions = {
        canEditAnmalan: false,
        canAvbokaAnmalan: false,
        canAvvaktaAnmalan: false,
        canVisaOrsakTillAvvaktan: false,
        canBokaAnmalan: false,
        canRedigeraBokning: false,
        canVisaOrsakTillAvbokningOchStrykning: false,
        canSkapaKallelse: false,
        canSkapaSms: false,
        canSkapaPreanestesibedomning: false,
        canVisaPreanestesibedomning: false,
    };

    export default {
        extends: BaseTab,
        name: "Operationskoordinering",
        tabName: "Operationskoordinering",
        icon: tabIcon,
        components: {
            ModalContent,
            CustomActionTable,
            FilterWidget,
            PlusCircleBlueIcon,
            OrsakTillAvbokningOchStrykning,
            VueFinalModal,
            ModalsContainer,
            DialogAktivPatient,
            AvbokaOperation,
            CustomButton,
        },
        computed: {
            ...mapGetters("tabs", ["newTabId"]),
            ...mapGetters("patientData", ["currentId"]),
            ...mapGetters("operation/operationsanmalan", ["opAnmalans"]),
            ...mapGetters("userData", ["fullName", "currentProfile"]),
            ...mapGetters("ability", ["can"]),

            canOpenKallelser() {
                return this.can({ action: "kallelse", subject: "anmalan" });
            },
        },
        watch: {
            currentId() {
                if (this.currentId !== this.selectedItem?.patient) {
                    this.selectedItem = null;
                }
            },
        },
        data() {
            return {
                opAnmalanListActions: OP_REGISTRATION_ROW_ACTIONS,
                actionDropdownMenuActionConditions: actionDropdownMenuActionConditions,
                opAnmalanTableColumns: OP_REGISTRATION_TABLE_COLUMNS,
                formComponents: null,
                filterData: {},
                sortingOptions: {
                    column: "",
                    order: "",
                },
                queryInputs: queryInputs,
                tableCaption: "",
                salar: [],
                operatorer: [],
                anmalningar: [],
                redigerar: false,
                orsakViewCheckString: "",
                filterValues: {},
                sortField: "",
                opAnmalansCount: null,
                showStrukna: false,
                toggleswitchValue: false,
                selectedItem: null,
                totalAnmalanCount: 0,
            };
        },
        methods: {
            ...mapActions("operation/operationsanmalan", ["resetOpAnmalan", "updateParams"]),
            openKallelserOchSMS() {
                this.$store.dispatch("tabs/openTab", {
                    component: SMSKallelser,
                });
            },
            async showStruknaOperationer(event) {
                await this.fetchOpAnmalansWithParams();
            },
            getImgUrl(pic) {
                return new URL(`@/assets/actionIcons/${pic}.svg`, import.meta.url).href;
            },
            openOpAnmalan(opId, patient) {
                this.$store.dispatch("operation/operationsanmalan/openOpAnmalan", {
                    component: Operationsanmalan,
                    id: opId,
                    patient: patient,
                    mode: "opcoordinator",
                });
            },
            previewOpAnmalan(opId, patient) {
                this.$store.dispatch("operation/operationsanmalan/previewOpAnmalan", {
                    component: Operationsanmalan,
                    id: opId,
                    patient: patient,
                });
            },
            async fetchOpAnmalansWithParams(params = {}, tableCaption = "") {
                params.q = this.filterData.searchBarData ? this.filterData.searchBarData.search : null;
                if (this.filterData.filterData) {
                    let filters = Object.keys(this.filterData.filterData);
                    filters.forEach((key) => {
                        if (this.filterData.filterData[key].id) {
                            params[key] = this.filterData.filterData[key].id;
                        } else {
                            params[key] = this.filterData.filterData[key];
                        }
                    });
                }

                params.show_strukna = this.showStrukna;

                this.updateParams(params);
                this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
                const { column, order } = this.sortingOptions;
                const ordering = `${order}${column}`;
                if (ordering) {
                    params["ordering"] = ordering;
                }
                await this.fetchOpAnmalans({ params: params, tableCaption: tableCaption });
            },
            async fetchOpAnmalans({ params: params, tableCaption: tableCaption }) {
                await klinikenApi
                    .get("operation/koordinering/", getConfig({ params: params }))
                    .then((response) => {
                        this.anmalningar = response.data.results;
                        this.$store.commit("operation/operationsanmalan/setOpAnmalans", this.anmalningar);
                        if (tableCaption === "") {
                            this.tableCaption = `Visar ${this.anmalningar.length} träffar`;
                            this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
                        } else {
                            this.tableCaption = tableCaption;
                        }
                    })
                    .catch((e) => {
                        flashMessage(
                            "Kunde inte uppdatera operationsregistreringar från servern. " + getErrorMessage(e)
                        );
                    });
            },
            async search() {
                await this.fetchOpAnmalansWithParams();
            },
            async sort(sortingOptions) {
                this.sortingOptions = sortingOptions;
                await this.fetchOpAnmalansWithParams();
            },
            setupDraggable() {
                const domElement = this.$refs.tabellWrapperAnmalningar;

                new Draggable(domElement, {
                    itemSelector: ".fc-event",
                    appendTo: document.getElementById("tabell-wrapper-anmalningar"),
                });
            },
            bokaOperation(listItem) {
                dialogEvents.$emit("openPopup", {
                    title: listItem.status !== "Bokad" ? "Boka operation" : "Redigera bokning",
                    component: BokaOperation,
                    dismissable: true,
                    data: {
                        listItem: listItem,
                    },
                });
            },
            avbokaOperation(listItem) {
                dialogEvents.$emit("openPopup", {
                    title: "Avboka eller stryk operation",
                    component: AvbokaOperation,
                    dismissable: true,
                    data: {
                        listItem: listItem,
                    },
                    allowScroll: true,
                });
            },
            avvaktaOperation(listItem) {
                dialogEvents.$emit("openPopup", {
                    title: "Avvakta operation",
                    component: AvvaktaOperation,
                    dismissable: true,
                    data: {
                        listItem: listItem,
                    },
                    allowScroll: true,
                });
            },

            visaOrsakTilAvbokningellerStryk(listItem) {
                dialogEvents.$emit("openPopup", {
                    title: "Orsak till avbokning och strykning",
                    component: OrsakTillAvbokningOchStrykning,
                    dismissable: true,
                    data: listItem,
                });
            },

            visaOrsaktillAvvaktan(listItem) {
                dialogEvents.$emit("openPopup", {
                    title: "Orsak till avvaktan",
                    component: OrsakTillAvvaktan,
                    dismissable: true,
                    data: listItem,
                });
            },
            selectPatient(item) {
                if (item.patient) {
                    this.selectedItem = item;
                    this.$store.dispatch("patientData/loadFromBackend", {
                        id: item.patient,
                        failSilently: false,
                    });

                    this.$store.dispatch("alertInformation/loadAlertInformationSymbol", { patientId: item.patient });
                    this.$store.dispatch("alertInformation/loadAlertInformationList", { patientId: item.patient });
                }
            },
            smsSend(item) {
                this.$store.dispatch("tabs/openTab", {
                    component: SMSSend,
                    parameters: { receivers: [item], baseUrl: "/operation/anmalan/", smsPath: "/sms/" },
                });
            },
            kallelseSend(item) {
                this.$store.dispatch("tabs/openTab", {
                    component: KallelseSend,
                    parameters: { receiver: item, baseUrl: "/operation/anmalan/", path: "/kallelse/" },
                });
            },
            skapaPreanestesibedomning(item) {
                this.$store.dispatch("tabs/openTab", {
                    component: Preanestesibedomning,
                    parameters: {
                        operationsanmalan: item,
                    },
                });
            },
            visaPreanestesibedomning(operation) {
                this.$store.dispatch("tabs/openTab", {
                    component: PreanestesibedomningVisa,
                    parameters: {
                        preanestesibedomningId: operation.aktuell_preanestesibedomning,
                    },
                });
            },
        },
        beforeDestroy() {
            this.resetOpAnmalan();
        },
        async created() {
            if (!this.can({ action: "read", subject: "operationskoordinering" })) {
                await this.$store.dispatch("tabs/closeTab", this.tabId);
                return;
            }

            try {
                let response = await klinikenApi.get("/operation/salar/", getConfig());
                this.salar = response.data.results;
            } catch (e) {
                openDialog("Kunde inte hämta salar från servern. ", "error");
            }

            try {
                let response = await klinikenApi.get(
                    "/core/profiles/",
                    getConfig({
                        params: { operator: true },
                    })
                );
                this.operatorer = response.data.results;
            } catch (e) {
                openDialog("Kunde inte hämta operatörer från servern. ", "error");
            }

            this.$on("openOpAnmalan", (item) => {
                this.openOpAnmalan(item.id, item.patient);
            });
            this.$on("previewOpAnmalan", (item) => {
                this.previewOpAnmalan(item.id, item.patient.id);
            });
            this.$on("bokaOperation", (item) => {
                this.bokaOperation(item);
            });
            this.$on("redigeraBokning", (item) => {
                this.bokaOperation(item);
            });
            this.$on("avbokaOperation", (item) => {
                this.avbokaOperation(item);
            });
            this.$on("avvaktaOperation", (item) => {
                this.avvaktaOperation(item);
            });
            this.$on("visaOrsaktillAvvaktan", (item) => {
                this.visaOrsaktillAvvaktan(item);
            });
            this.$on("visaorsaktillavvaktanellerstryk", (item) => {
                this.visaOrsakTilAvbokningellerStryk(item);
            });

            this.$on("smsSend", (item) => {
                this.smsSend(item);
            });

            this.$on("skapaPreanestesibedomning", (item) => {
                this.skapaPreanestesibedomning(item);
            });

            this.$on("visaPreanestesibedomning", (item) => {
                this.visaPreanestesibedomning(item);
            });

            this.$on("kallelseSend", (item) => {
                this.kallelseSend(item);
            });

            this.$on("actionTable::select", this.selectPatient);

            await this.fetchOpAnmalansWithParams();
            this.totalAnmalanCount = this.anmalningar.length;

            this.setupDraggable();
            actionDropdownMenuActionConditions.canEditAnmalan = this.can({ action: "update", subject: "anmalan" });
            actionDropdownMenuActionConditions.canAvbokaAnmalan = this.can({ action: "avboka", subject: "anmalan" });
            actionDropdownMenuActionConditions.canAvvaktaAnmalan = this.can({ action: "avvakta", subject: "anmalan" });

            actionDropdownMenuActionConditions.canBokaAnmalan = this.can({ action: "boka", subject: "anmalan" });

            actionDropdownMenuActionConditions.canRedigeraBokning = this.can({ action: "update", subject: "anmalan" });

            actionDropdownMenuActionConditions.canSkapaKallelse = this.can({ action: "kallelse", subject: "anmalan" });

            actionDropdownMenuActionConditions.canSkapaPreanestesibedomning = this.can({
                action: "create",
                subject: "preanestesibedomning",
            });
            actionDropdownMenuActionConditions.canSkapaSms = this.can({ action: "sms", subject: "anmalan" });

            actionDropdownMenuActionConditions.canVisaOrsakTillAvbokningOchStrykning = this.can({
                action: "read",
                subject: "operationskoordinering",
            });

            actionDropdownMenuActionConditions.canVisaOrsakTillAvvaktan = this.can({
                action: "read",
                subject: "operationskoordinering",
            });

            actionDropdownMenuActionConditions.canVisaPreanestesibedomning = this.can({
                action: "read",
                subject: "preanestesibedomning",
            });
        },
    };
</script>
<style lang="sass" scoped>
    @import "@/style/variables"
    @import "@/style/modal.scss"

    .subheader
        display: flex
        min-width: 1364px
        position: relative
        overflow: hidden
        padding: 1rem 0

        .subheader-right-div
            position: absolute
            right: 0

        .visar-antal-anmalningar
            font-size: 1.5rem

            strong
                font-weight: 700
                font-size: 1.5rem


    @-ms-keyframes spin
        0%
            -ms-transform: rotate(0deg)
        100%
            -ms-transform: rotate(360deg)

    @-moz-keyframes spin
        0%
            -moz-transform: rotate(0deg)
        100%
            -moz-transform: rotate(360deg)

    @-webkit-keyframes spin
        0%
            -webkit-transform: rotate(0deg)
        100%
            -webkit-transform: rotate(360deg)

    @keyframes spin
        0%
            transform: rotate(0deg)
        100%
            transform: rotate(360deg)

    .overlay
        z-index: 1
        pointer-events: none
        height: calc(100vh - 159px)
        overflow: hidden
        position: relative
        opacity: 0.5

        .spinner-wrapper
            left: 50%
            top: 50%
            position: absolute
            transform: translate(-50%, -50%)

            .spin
                -webkit-animation-name: spin
                -webkit-animation-duration: 4000ms
                -webkit-animation-iteration-count: infinite
                -webkit-animation-timing-function: linear
                -moz-animation-name: spin
                -moz-animation-duration: 4000ms
                -moz-animation-iteration-count: infinite
                -moz-animation-timing-function: linear
                -ms-animation-name: spin
                -ms-animation-duration: 4000ms
                -ms-animation-iteration-count: infinite
                -ms-animation-timing-function: linear
                animation-name: spin
                animation-duration: 4000ms
                animation-iteration-count: infinite
                animation-timing-function: linear
</style>
<style lang="scss">
    @import "@/style/variables.scss";

    .operationskoordinering {
        .operationskoordinering-headers {
            display: flex;
            gap: 40px;
        }

        .filter-widget {
            margin: 1.6rem 0;
        }

        .tabell-wrapper-anmalningar {
            .table th {
                padding: 0.75rem 0;
            }

            .person-id {
                border-radius: 4px;
                border: 1px dashed transparent;
                padding: 4px;

                div {
                    background-color: transparent;
                    height: 30px;
                    border-radius: 4px;
                    border-left: 6px solid transparent;
                    color: $color-input-text;
                    padding: 4.5px;
                }

                &.fc-event {
                    border-color: #7cbed6;
                    background: white;

                    div {
                        color: $color-active-dark;
                        height: 100%;
                        background-color: rgba(38, 148, 188, 0.2);
                        border-left-color: rgba(38, 148, 188, 0.5);
                    }
                }

                &.booked {
                    position: relative;

                    &::before {
                        position: absolute;
                        content: url(@/assets/check_circle_black.svg);
                        padding-top: 3px;
                    }
                }
            }
        }
    }
</style>
