<template>
    <div class="sign-details">
        <div class="top-text">
            <p>
                Patientjournaler får enbart tas bort om det är på fel patient alternativt har blivit en dublett. Av
                vilken anledning ska dokumentet raderas?
            </p>
        </div>
        <div class="text-area-div">
            <input-wrapper
                :component="widgets.SelectWidget"
                name="nullifiedReason"
                v-model="nullifiedReason"
                label="Ange orsak till makuleringen"
                :options="[
                    { text: 'Fel patient', value: 'Fel patient' },
                    { text: 'Dublett', value: 'Dublett' },
                    { text: 'Ej patientjournal', value: 'Ej patientjournal' },
                ]"
            />
        </div>
        <ButtonGroup>
            <Button @click="makuleraAnteckning" variant="alert" class="btn-primary" :disabled="nullifiedReason === ''">
                Makulera
            </Button>
            <Button @click="cancel" variant="contour" size="secondary" class="btn-outline-secondary">Avbryt</Button>
        </ButtonGroup>
    </div>
</template>

<script>
import dialogEvents from "@/dialogEvents";
import widgets from "@/components/widgets/inputs";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import tabEvents from "@/tabEvents";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

export default {
    name: "MakuleraSamtycke",
    props: ["attachedProps"],
    components: {
        InputWrapper,
        Button,
        ButtonGroup,
    },
    data() {
        return {
            nullifiedReason: "",
            widgets,
        };
    },
    methods: {
        cancel() {
            dialogEvents.$emit("closePopup");
        },
        async makuleraAnteckning() {
            const parameters = {
                anteckning: this.attachedProps.anteckning,
                nullifiedReason: this.nullifiedReason,
            };
            await this.$store.dispatch("journalanteckningar/makuleraAnteckning", { parameters });
            tabEvents.$emit("refreshJournalanteckningar", this.$store.getters["journalanteckningar/anteckning"]);
        },
    },
};
</script>

<style lang="scss" scoped>
.sign-details {
    display: flex;
    flex-direction: column;
    opacity: 1;
    background-color: white;
    min-height: 410px;
    padding: 23px 90px 0 90px;
}

.button-field {
    text-align: left;
    align-items: baseline;
    align-content: baseline;
    display: block !important;
}

.descriptionBlock {
    margin-top: 50px;
    text-align: left;
}

.text-area-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 31px;
}

.makuleraText {
    width: 576px;
    height: 111px;
    resize: none;
}
</style>
