<script>
import BaseTab from "@/tabs//mixins/BaseTab";
import dialogEvents from "@/dialogEvents";
import { openDialog } from "@/utils";
import { mapActions } from "vuex";
import { cloneDeep, isEqual } from "lodash";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";

const defaultUnderlag = {
    id: undefined,
    namn: "",
};
export default {
    extends: BaseTab,
    name: "FormUnderlag",
    tabName: "Underlag",
    components: { ButtonGroup, Button },

    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            defaultUnderlag: cloneDeep(defaultUnderlag),
            underlag: cloneDeep(defaultUnderlag),
            formErrors: [],
            inputErrors: {},
        };
    },

    computed: {
        hasUnsavedData() {
            return !isEqual(this.defaultUnderlag, this.underlag);
        },
        heading() {
            return this.underlag.id === undefined ? "Skapa nytt underlag" : "Ändra på underlag";
        },
    },

    methods: {
        ...mapActions("skreg/underlag", ["save"]),
        async submit(data) {
            try {
                await this.save(data);
                await this.$store.dispatch("tabs/closeTab", this.tabId);
            } catch (e) {
                if (e.response) {
                    this.formErrors = e.response.data["non_field_errors"];
                    this.inputErrors = e.response.data;
                }
                openDialog("Kunde inte spara underlaget.", "error");
            }
        },
        cancel() {
            this.closeTab(this.hasUnsavedData);
        },
    },

    async created() {
        if (this.parameters !== undefined && "underlag" in this.parameters) {
            this.defaultUnderlag = cloneDeep(this.parameters["underlag"]);
            this.underlag = cloneDeep(this.parameters["underlag"]);
        }
    },
};
</script>

<template>
    <FormulateForm v-model="underlag" :form-errors="formErrors" :errors="inputErrors" @submit="submit">
        <h2>{{ heading }}</h2>

        <FormulateInput name="namn" label="Namn" type="text" validation="required" />

        <ButtonGroup>
            <Button size="primary" role="button" type="submit" :disabled="!hasUnsavedData">Spara och stäng</Button>
            <Button @click="cancel()" size="secondary" type="button">Avbryt</Button>
        </ButtonGroup>
    </FormulateForm>
</template>

<style lang="scss" scoped>
.control {
    span {
        display: block;
    }

    &.invalid {
        label,
        input,
        span {
            color: #9e354b;
        }

        input {
            border: 1px #9e354b solid;
        }
    }

    &.required {
        label:after {
            content: " *";
        }
    }
}
</style>
