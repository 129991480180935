import Vue from "vue";
import { getConfig, klinikenApi } from "@/api";
import { isArray, uniqWith } from "lodash";
import moment from "moment";

export const statusar = [
    { value: "Att hantera", label: "Att hantera", icon: "statusatthantera", disabled: false },
    {
        value: "Väntar på preanestesibedömning",
        label: "Väntar på preanestesibedömning",
        icon: "statuspreanestesibedomning",
        disabled: false,
    },
    {
        value: "Preanestesibedömning",
        label: "Preanestesibedömning",
        icon: "statuspreanestesibedomning",
        disabled: false,
    },
    {
        value: "Preanestesibedömning klar",
        label: "Preanestesibedömning klar",
        icon: "statuspreanestesibedomning",
        disabled: false,
    },
    { value: "Väntar på viktnedgång", label: "Väntar på viktnedgång", icon: "statuswaiting", disabled: false },
    { value: "Väntar på rökstopp", label: "Väntar på rökstopp", icon: "statuswaiting", disabled: false },
    {
        value: "Väntar på prover/undersökning",
        label: "Väntar på prover/undersökning",
        icon: "statuswaiting",
        disabled: false,
    },
    { value: "Klar för op", label: "Klar för op", icon: "statusklarop", disabled: false },
    {
        value: "Preanestesibedömning genomförd",
        label: "Preanestesibedömning genomförd",
        icon: "statuspreanestesibedomning",
        disabled: true,
    },
    { value: "Patientvald väntan", label: "Patientvald väntan", icon: "statusavvaktan", disabled: true },
    { value: "Medicinsk väntan", label: "Medicinsk väntan", icon: "statusavvaktan", disabled: true },
    {
        value: "Administrativ väntan",
        label: "Administrativ väntan",
        icon: "statusavvaktan",
        disabled: true,
    },
    { value: "Bokad", label: "Bokad", icon: "statusbokad", disabled: true },
    { value: "Avbokad", label: "Avbokad", icon: "statusavbokad", disabled: true },
    { value: "Struken", label: "Struken", icon: "statusstruken", disabled: true },
    { value: "Genomförd", label: "Genomförd", icon: "statusgenomford", disabled: true },
    { value: "Pågående", label: "Pågående", icon: "statuspagaende", disabled: true },
    { value: "Signerad", label: "Signerad", icon: "statussignerad", disabled: true },
];

export const statusPreanestesibedomningar = [
    {
        label: "Godkänd med krav",
        value: "Godkänd med krav",
        icon: "godkand_med_krav",
    },
    { label: "Godkänd", value: "Godkänd", icon: "godkand" },
    { label: "Ej godkänd", value: "Ej godkänd", icon: "ej_godkand" },
    { label: "Återkallad", value: "Återkallad", icon: "aterkallad" },
    { label: "Utgången", value: "Utgången", icon: "utgangen" },
    { label: "Att göra", value: "Att göra", icon: "att_gora" },
];

export const RYGGKOTOR = [
    { value: null, text: "" },
    { value: 1, text: "Atlas" },
    { value: 2, text: "C1" },
    { value: 3, text: "C2" },
    { value: 4, text: "C3" },
    { value: 5, text: "C4" },
    { value: 6, text: "C5" },
    { value: 7, text: "C6" },
    { value: 8, text: "C7" },
    { value: 9, text: "Th1" },
    { value: 10, text: "Th2" },
    { value: 11, text: "Th3" },
    { value: 12, text: "Th4" },
    { value: 13, text: "Th5" },
    { value: 14, text: "Th6" },
    { value: 15, text: "Th7" },
    { value: 16, text: "Th8" },
    { value: 17, text: "Th9" },
    { value: 18, text: "Th10" },
    { value: 19, text: "Th11" },
    { value: 20, text: "Th12" },
    { value: 21, text: "L1" },
    { value: 22, text: "L2" },
    { value: 23, text: "L3" },
    { value: 24, text: "L4" },
    { value: 25, text: "L5" },
    { value: 26, text: "S1" },
    { value: 27, text: "S2" },
    { value: 28, text: "S3" },
    { value: 29, text: "S4" },
    { value: 30, text: "S5" },
    { value: 31, text: "Coccyg" },
];

export const changeTextToDisplayName = (array) => {
    return array.map(({ text, ...rest }) => {
        return { ...rest, displayName: text };
    });
};

export const cvOptions = (list) => {
    const transformer = ({ id, code, displayName }) => {
        const text = `${code} ${displayName}`;

        return {
            value: `${id}`,
            label: truncateText(text),
        };
    };

    return list.map((i) => {
        return transformer(i);
    });
};

export const cvOptionsShort = (list) => {
    const transformer = ({ id, code, displayName }) => {
        const text = `${code} ${displayName}`;

        return {
            value: `${id}`,
            label: truncateTextWithoutbullets(text, 7),
        };
    };

    return list.map((i) => {
        return transformer(i);
    });
};

export const cvSimpleOptions = (list) => {
    const transformer = ({ id, displayName }) => {
        return {
            value: `${id}`,
            label: `${displayName}`,
        };
    };

    return list.map((i) => {
        return transformer(i);
    });
};

export const typoperationerOptions = (list) => {
    const transformer = (obj) => {
        return {
            value: `${obj.id}`,
            label: obj.namn,
            ...obj,
        };
    };

    return list.map((i) => {
        return transformer(i);
    });
};

export const salarOptions = (list) => {
    const transformer = ({ id, namn }) => {
        return {
            value: `${id}`,
            label: namn,
        };
    };

    return list.map((i) => {
        return transformer(i);
    });
};

export const profileOptions = (list) => {
    const transformer = ({ pk, user_display }) => {
        return {
            value: `${pk}`,
            label: user_display.name,
        };
    };

    return list.map((i) => {
        return transformer(i);
    });
};

export const avtalOptions = (list) => {
    const transformer = ({ id, namn }) => {
        return {
            value: `${id}`,
            label: `${namn}`,
        };
    };

    return list.map((i) => {
        return transformer(i);
    });
};

export const materialOptions = (list) => {
    const transformer = (obj) => {
        return {
            value: `${obj.id}`,
            label: `${obj.artikelnummer}`,
            ...obj,
        };
    };

    return list.map((i) => {
        return transformer(i);
    });
};

export const anestesikomplikationerKategorierOptions = (list) => {
    const transformer = ({ kapitel, kapitelnamn }) => {
        return {
            value: kapitel,
            label: kapitelnamn,
        };
    };

    return uniqWith(
        list.map((i) => {
            return transformer(i);
        }),
        function (arrVal, othVal) {
            return arrVal.value === othVal.value;
        }
    );
};

export const anestesikomplikationerOptions = (list) => {
    const transformer = ({ id, displayName }) => {
        return {
            value: id,
            label: displayName,
        };
    };

    return list.map((i) => {
        return transformer(i);
    });
};

export const changeTimeToDate = (date, timeField) => {
    if (!propertyExistsNotNull(timeField) && typeof timeField !== "boolean") {
        return;
    } else {
        return moment(`${date} ${timeField}:00`);
    }
};

export const getTimeFromDatetime = (datetime) => {
    if (!isNullOrUndefined(datetime)) {
        return extractHourMinuteFromDateTime(datetime);
    }
    return;
};

export const extractHourMinuteFromDateTime = (datetime = "") => {
    if (!isNullOrUndefined(datetime) && moment.isDate(new Date(datetime))) {
        return moment(datetime).format("HH:mm").toString();
    }
};

export const getHourMinuteFromTime = (time = "") => {
    if (isNullOrUndefined(time)) {
        return "";
    }
    return time.substring(0, 5);
};

export const isNotEmptyObject = (obj) => obj !== null && Object.keys(obj).length > 0;
export const isEmptyObject = (obj) => obj !== null && Object.keys(obj).length === 0;
export const isNotEmptyArray = (arr) => arr !== null && arr.length > 0;
export const isNull = (arg) => arg === null;
export const isNullOrUndefined = (arg) => arg === null || arg === undefined;
export const getPropertyValue = (obj) => Object.values(obj)[0];
export const truncateText = (text = "", maxLength = 100) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}…` : text;
export const truncateTextWithoutbullets = (text = "", maxLength = 100) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}` : text;
export const propertyExists = (property) => property !== undefined;
export const propertyExistsNotNull = (property) => property !== undefined && property !== null;
export const arrayPropertyExistsNotEmpty = (arrayProperty) => {
    return propertyExists(arrayProperty) && isNotEmptyArray(arrayProperty);
};
export const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
};
export const isNotEmptyString = (obj) => {
    return obj !== "";
};

export const objectHasNullOrFalseValues = (obj = {}, propertyName = "") => {
    let hasNullOrFalse = [];
    let ObjectClone = { ...obj };
    let hasEmptyValue;

    delete ObjectClone[propertyName];

    for (const [_, value] of Object.entries(ObjectClone)) {
        if (value === "null" || value === false) {
            hasNullOrFalse.push("hasFalseOrEmpyValues");
        } else if (value === true || value === "true") {
            hasNullOrFalse.push("hasValues");
        }
    }

    if (hasNullOrFalse.includes("hasValues")) {
        hasEmptyValue = false;
    } else {
        hasEmptyValue = true;
    }
    return hasEmptyValue;
};

export const getNullIfEmpty = (text = "") => (text === "" ? null : text);

export const transformList = (data) => {
    let list = [];
    if (data.length > 0) {
        for (const item of data) {
            if (isNotEmptyObject(item)) list.push(Object.values(item)[0]);
        }
    }
    return list;
};

export const removeListWithEmptyObjects = (data) => {
    let list = [];
    if (data.length > 0) {
        for (const item of data) {
            if (isEmptyObject(item));
            return list;
        }
    }
    return list;
};

/**
 * Removes empty objects from an array.
 * @param {Array} array - The array to be filtered.
 * @returns {Array} - The filtered array.
 */
export const removeEmptyObjectsFromArray = (array = []) => {
    if (!Array.isArray(array)) {
        throw new Error(`${array} is not an array`);
    }

    const value = array.filter(function (obj) {
        if (obj) return Object.keys(obj).length !== 0;
        else return false;
    });

    return value;
};

export const removeEmptyPropertiesFromObject = (Obj = {}) => {
    Object.keys(Obj).forEach((k) => Obj[k] == null || (Obj[k] === "null" && delete Obj[k]));
    return Obj;
};

/**
 * Injects a custom header into an axios config object.
 *
 * @param {String} propertyKey - Top level object keys name
 * @param {Number} index - Index of the group
 * @param {String} nestedPropertyKey - After the indexGroup the other object key name.
 * @param {String} errorMessage - Custom errorMessage
 * @example
 *  const { errorObject } = customErrorMessageForGroup({
 *      propertyKey: "anestesikomplikationer",
 *      index,
 *      nestedPropertyKey: "kod",
 *      errorMessage: "paying too much for this stock",
 *  });
 * errorObject = {'anestesikomplikationer.0.kod': 'You’re paying too much for this stock'}
 */

export const customErrorMessageForGroup = ({
    propertyKey = "",
    index = NaN,
    nestedPropertyKey = "",
    errorMessage = "",
}) => {
    let errorObject = {};
    let errorObjectPropertyKey = "";
    const stringObject = `{ "${propertyKey}.${index}.${nestedPropertyKey}": "${errorMessage}" }`;
    errorObject = JSON.parse(stringObject);

    for (const property in errorObject) {
        errorObjectPropertyKey = property;
    }
    return { errorObject, errorObjectPropertyKey };
};

export const removeCustomErrorMessage = ({ ErrorObject = {}, errorObjectPropertyKey = "" }) => {
    ErrorObject[errorObjectPropertyKey] = null;
    delete ErrorObject[errorObjectPropertyKey];
    errorObjectPropertyKey = null;
};

export const hasDuplicatedObjectsInArray = ({ list = [], propertyFieldName = "" }) => {
    let hasDuplicates = false;
    let newArr = [];
    let indexofDuplicated = NaN;

    list.forEach((item, index) => {
        if (isEmptyObject(item)) return;

        if (!item[propertyFieldName]) {
            return;
        }

        if (newArr.includes(item[propertyFieldName]) === false) {
            newArr.push(item[propertyFieldName]);
        } else {
            indexofDuplicated = index;
            hasDuplicates = true;
        }
    });

    return { hasDuplicates, indexofDuplicated };
};

export const SetField = ({ value = "", label = "", formData = {}, objectPropertyKey = "", hasBoolean = false }) => {
    let lists = [
        {
            value,
            label,
        },
    ];

    if (hasBoolean) {
        formData[objectPropertyKey] = value;
    } else {
        formData[objectPropertyKey] = `${value}`;
    }

    return lists;
};

export const SetFields = ({
    lists = [],
    listProperty = "",
    data = Vue,
    dataProperty = "",
    formData = {},
    formField = "",
    nestedformFieldPropertyKey = "",
}) => {
    lists[listProperty].forEach(function (obj, index) {
        if (!obj) throw new Error(`Missing display Object`);

        if (data) {
            data.$set(data[dataProperty], index, [
                {
                    value: obj.id,
                    label: `${obj.code} ${obj.displayName}`,
                },
            ]);

            const stringObject = `{ "${nestedformFieldPropertyKey}": "${obj.id}" }`;

            data.$set(formData[formField], index, JSON.parse(stringObject));
        }
    });
};

const handlingErrorGroup = (key, value) => {
    let propertyKey = "";
    let nestedPropertyKey = "";
    let errorMessage = "";

    propertyKey = key;

    // Rad för gruppen
    let groupIndex = 0;
    let errorObjectPropertyKey;
    let errorObjectPropertyValue;
    let errorObjectList = [];
    for (const FieldErrorObject of value) {
        if (FieldErrorObject instanceof Object) {
            for (const [fieldErrorProperty, fieldErrorPropertyValue] of Object.entries(FieldErrorObject)) {
                nestedPropertyKey = `${fieldErrorProperty}`;

                if (isArray(fieldErrorPropertyValue)) {
                    errorMessage = fieldErrorPropertyValue[0];
                }

                errorObjectPropertyKey = `${propertyKey}.${Number(groupIndex)}.${nestedPropertyKey}`;
                errorObjectPropertyValue = `${errorMessage}`;
                errorObjectList.push({ errorObjectPropertyKey, errorObjectPropertyValue });
            }

            groupIndex++;
        }
    }

    return { errorObjectList };
};

export const parseError = (ResponseObject = {}) => {
    let ErrorObject = {};

    Object.entries(ResponseObject).forEach(([key, value]) => {
        const isGroup = (value) => typeof value[0] !== "string";

        if (!isGroup(value)) {
            ErrorObject[key] = value;
        } else {
            const { errorObjectList } = handlingErrorGroup(key, value);

            for (let errorObject of errorObjectList) {
                const { errorObjectPropertyKey, errorObjectPropertyValue } = errorObject;
                Object.assign(ErrorObject, createErrorObject({ errorObjectPropertyKey, errorObjectPropertyValue }));
            }
        }
    });
    return ErrorObject;
};

export const createErrorObject = ({ errorObjectPropertyKey = "", errorObjectPropertyValue = "" }) => {
    let Obj = {};
    Obj[errorObjectPropertyKey] = errorObjectPropertyValue;
    return Obj;
};

export const fetchData = async ({ url, params, tabbnamn = null }) => {
    try {
        const response = await klinikenApi.get(url, getConfig({ params: params }, tabbnamn));
        return response?.data?.results ?? response.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const fetchObj = async ({ url, params, tabbnamn = null }) => {
    let obj = {};
    try {
        const response = await klinikenApi.get(url, getConfig({ params: params }, tabbnamn));
        obj = response.data ?? {};
    } catch (error) {
        throw new Error(error);
    }
    return obj;
};

export const postData = async ({ url = "", params = {}, tabbnamn = null }) => {
    let data = {};
    let error = {};
    let hasData = false;
    let hasError = false;
    try {
        const response = await klinikenApi.post(url, params, getConfig());
        data = response?.data ?? {};
        if (isNotEmptyObject(data)) {
            hasData = true;
        }
    } catch (e) {
        hasError = true;
        error = e.response.data;
    }

    return { data, error, hasData, hasError };
};

export const putData = async ({ url = "", params = {}, tabbnamn = null }) => {
    let data = {};
    let error = {};
    let hasData = false;
    try {
        const response = await klinikenApi.put(url, params, getConfig({}, tabbnamn));
        data = response?.data ?? {};
        if (isNotEmptyObject(data)) {
            hasData = true;
        }
    } catch (e) {
        error = e.response.data;
    }

    return { data, error, hasData };
};

export const patchData = async ({ url = "", params = {}, tabbnamn = "" }) => {
    let data = {};
    let error;
    let hasData = false;
    try {
        const response = await klinikenApi.patch(url, params, getConfig({}, tabbnamn));
        data = response?.data ?? {};
        if (isNotEmptyObject(data)) {
            hasData = true;
        }
    } catch (e) {
        error = e.response.data;
    }

    return { data, error, hasData };
};

/**
 * Normalizes the provided data object by converting all `null` values to empty strings, ensuring
 * arrays containing `null` or empty strings are initialized with a single empty string, and
 * applying the same normalization rules recursively to nested objects. This function aims to
 * prepare data structures, particularly for comparing values before form submissions, where consistent data types are required.
 *
 * @param {Object|Array} data The data object or array to normalize.
 * @return {Object|Array} The normalized data with adjustments made easier for comparing values.
 */
export const normalizeData = (data) => {
    // Check if the input is an array
    if (Array.isArray(data)) {
        // Normalize each element of the array. If the array is empty or contains only null or empty strings,
        // initialize it with a single empty string.
        if (data.length === 0 || data.every((item) => item === null || item === "")) {
            return [""];
        } else {
            return data.map((item) => normalizeData(item));
        }
    }
    // Check if the input is an object and not null
    else if (typeof data === "object" && data !== null) {
        return Object.keys(data).reduce((acc, key) => {
            acc[key] = normalizeData(data[key]); // Apply normalization recursively for each property
            return acc;
        }, {});
    }
    // For all other cases, including null values, return an empty string. Otherwise, return the data as is.
    return data === null ? "" : data;
};
