<template>
    <div id="app">
        <div class="panel panel-default">
            <div class="panel-body">
                <label class="switch">
                    <input type="checkbox" v-model="checkbox" @click="toggleCheckbox" />
                    <div class="slider round"></div>
                </label>
                <!-- <p>{{ checkbox }}</p> -->
            </div>
            <span>Dra & släpp på</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "ToggleSwitch",
    props: ["propcheckbox"],
    data() {
        return {
            checkbox: null,
        };
    },
    methods: {
        toggleCheckbox() {
            this.checkbox = !this.checkbox;
            this.$emit("setCheckboxVal", this.checkbox);
        },
    },
};
</script>
<style lang="scss" scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
    margin-right: 10px;
}
.panel {
    display: flex;
    span {
        margin-top: 3px;
    }
}
.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #38a7cf;
}

input:focus + .slider {
    box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
