<template>
    <form @submit.prevent="submit">
        <admin-lock-border />
        <h2 v-if="parameters">Redigera resurs</h2>
        <h2 v-else>Skapa ny resurs</h2>
        <div class="form-group k3-form">
            <input-wrapper
                :component="TextWidget"
                label="Namn"
                name="namn"
                v-model="resurs.namn"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="NumberWidget"
                label="Totalt antal"
                name="totalt_antal"
                v-model="resurs.totalt_antal"
                @input="updateFormHasBeenEdited"
            />
        </div>
        <ButtonGroup>
            <Button size="primary" role="button" type="submit" :disabled="!formHasBeenEdited">Spara och stäng</Button>
            <Button @click="cancel()" size="secondary" type="button">Avbryt</Button>
        </ButtonGroup>
    </form>
</template>

<script>
    import BaseTab from "@/tabs/mixins/BaseTab";
    import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
    import tabIcon from "@/assets/blank.png";
    import TextWidget from "@/components/widgets/inputs/TextWidget.vue";
    import NumberWidget from "@/components/widgets/inputs/NumberWidget.vue";
    import { flashMessage } from "@/utils";
    import { mapActions } from "vuex";
    import cloneDeep from "lodash/cloneDeep";
    import AdminLockBorder from "@/components/AdminLockBorder.vue";
    import Button from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import { openDialog } from "@/utils";
    import { getErrorMessage } from "@/api";

    export default {
        extends: BaseTab,
        name: "Resurs",
        icon: tabIcon,
        tabName: "Resurs",
        components: {
            Button,
            ButtonGroup,
            AdminLockBorder,
            InputWrapper,
        },
        props: {
            tabId: Number,
            parameters: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                TextWidget: TextWidget,
                NumberWidget: NumberWidget,
                formHasBeenEdited: false,
                resurs: {
                    id: "",
                    namn: "",
                    totalt_antal: 0,
                },
            };
        },
        computed: {
            hasUnsavedData() {
                return this.formHasBeenEdited;
            },
        },
        methods: {
            ...mapActions("resurs", ["create", "update", "load"]),
            updateFormHasBeenEdited() {
                this.formHasBeenEdited = true;
            },
            async submit() {
                let response;
                let msg;
                let status;

                if (this.resurs.id) {
                    response = await this.update({ resurs: this.resurs });
                    if (response.error) {
                        openDialog("Kunde inte uppdatera resurs: " + getErrorMessage(response.error), "warning");
                        return;
                    }
                    msg = "Resursen har uppdaterats";
                    status = 200;
                } else {
                    response = await this.create({ resurs: this.resurs });
                    if (response.error) {
                        openDialog("Kunde inte spara resurs: " + getErrorMessage(response.error), "warning");
                        return;
                    }
                    msg = "Resursen har sparats";
                    status = 201;
                }

                if (response.status === status) {
                    await this.$store.dispatch("resurs/load");
                    await this.$store.dispatch("tabs/closeTab", this.tabId);
                    flashMessage(msg);
                }
            },
            cancel() {
                this.closeTab(this.hasUnsavedData);
            },
        },
        async created() {
            if (this.parameters) {
                this.resurs = cloneDeep(this.parameters);
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/style/deprecated_main";
</style>
