import { NEUTRALCOLOR, getEventColor } from "./colors-utils";
import { arrayPropertyExistsNotEmpty, propertyExistsNotNull, isNotEmptyObject } from "./utils";

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
const convertDateToSwedishFormat = (dateString = "") => {
    let value = "";
    if (typeof dateString === "string") {
        if (propertyExistsNotNull(dateString) && dateString.length > 0) {
            const date = new Date(dateString);
            const weekDaysFromEnglishToSwedish = capitalizeFirstLetter(
                date.toLocaleString("sv-SE", { weekday: "long" })
            );
            const monthsFromEnglishToSwedish = date.toLocaleString("sv-SE", { month: "long" });
            const year = date.getFullYear();
            const day = date.toLocaleString("default", { day: "numeric" });

            value = `${weekDaysFromEnglishToSwedish} ${day} ${monthsFromEnglishToSwedish} ${year}`;
        }
    }
    return value;
};

const listOfOperatorer = (ass_operatorer = [], huvudoperator = "") => {
    let list = [];

    if (huvudoperator.length > 0) list.push(huvudoperator);

    if (arrayPropertyExistsNotEmpty(ass_operatorer)) {
        ass_operatorer.forEach((operator) => {
            list.push(operator.user_display?.name);
        });
    }

    return list.join(", ");
};

const huvudoperationskodText = (Obj = {}) => {
    let text = "";
    if (propertyExistsNotNull(Obj) && isNotEmptyObject(Obj)) {
        text = `${Obj.code} ${Obj.displayName}`;
    }
    return text;
};

const createEventStyle = (id) => {
    const { backgroundColor, textColor } = getEventColor(id);

    return [backgroundColor, textColor];
};

const createSparradTidEvent = (sparradTid) => {
    const event = {
        start: sparradTid.starttid_tidpunkt,
        end: sparradTid.sluttid_tidpunkt,
        resourceId: sparradTid.sal,
        classNames: ["sparrad-tid-event"],
        extendedProps: {
            id: sparradTid.id,
            sparradTid: true,
            datum: sparradTid.datum,
            starttid: sparradTid.starttid,
            sluttid: sparradTid.sluttid,
            sal: sparradTid.sal_display,
            orsak: sparradTid.orsak_display ?? "",
            duration: sparradTid.duration,
        },
    };

    return event;
};

const createEvent = (anmalan, colorId, show = true) => {
    const [backgroundColor, textColor] = createEventStyle(colorId);

    const event = {
        allDay: anmalan.bokad_operation_tid ? false : true,
        date: anmalan.bokad_operation_datum,
        classNames: [],
        start: anmalan.salstid_tidpunkt_start
            ? new Date(anmalan.salstid_tidpunkt_start)
            : new Date(anmalan.bokad_operation_datum),
        end: anmalan.salstid_tidpunkt_slut ? new Date(anmalan.salstid_tidpunkt_slut) : null,
        resourceId: anmalan.bokad_sal ?? "0",
        backgroundColor,
        textColor,
        extendedProps: {
            id: anmalan.id,
            salstid: anmalan.salstid,
            forberedelser: anmalan.forberedelser ?? 0,
            optid: anmalan.optid ?? 0,
            avveckling: anmalan.avveckling ?? 0,
            personId: anmalan.patient_display?.personId ?? "",
            patientId: anmalan.patient ?? null,
            opkod: anmalan.huvudoperationskod_display?.code ?? "",
            showPopup: false,
            personNamn: anmalan.patient_display?.namn ?? "",
            sal: anmalan.bokad_sal_display ?? null,
            huvudoperator: anmalan.current_bokad_huvudoperator_display?.pk ?? "",
            lateralitet: anmalan.lateralitet,
            lateralitetDisplay: anmalan.lateralitet_display,
            operatorer: listOfOperatorer(
                anmalan.ass_operatorer_display,
                anmalan.current_bokad_huvudoperator_display?.user_display?.name ?? ""
            ),
            huvudoperation: huvudoperationskodText(anmalan.huvudoperationskod_display),
            startDateInSwedish: convertDateToSwedishFormat(anmalan.bokad_operation_datum ?? ""),
        },
    };

    if (!anmalan.bokad_operation_tid) {
        event.classNames.push("all-day-event");
    }
    if (!show) {
        event.classNames.push("hidden-event");
    }

    return event;
};

const setEventInfo = (event, anmalan, colorId, onlyBookingInfo, show = true) => {
    setEventColors(event, colorId);

    // Gå igenom - behövs allt det här sättas eller sätts vissa props automatiskt?
    if (anmalan.salstid_tidpunkt_start) {
        event.setDates(new Date(anmalan.salstid_tidpunkt_start), new Date(anmalan.salstid_tidpunkt_slut), {
            allDay: false,
        });
        event.setProp(
            "classNames",
            event.classNames.filter((name) => name !== "all-day-event")
        );
    } else {
        event.setDates(anmalan.bokad_operation_datum, null, { allDay: true });
        event.setProp("classNames", [...event.classNames, "all-day-event"]);
    }

    if (anmalan.bokad_sal_display) {
        event.setExtendedProp("sal", anmalan.bokad_sal_display);
        event.setResources([anmalan.bokad_sal]);
    } else {
        event.setExtendedProp("sal", null);
        event.setResources(["0"]);
    }

    event.setExtendedProp("startDateInSwedish", convertDateToSwedishFormat(anmalan.bokad_operation_datum ?? ""));
    if (!onlyBookingInfo) {
        event.setExtendedProp("id", anmalan.id);
        event.setExtendedProp("salstid", anmalan.salstid);
        event.setExtendedProp("forberedelser", anmalan.forberedelser);
        event.setExtendedProp("optid", anmalan.optid);
        event.setExtendedProp("avveckling", anmalan.avveckling);
        event.setExtendedProp("personId", anmalan.patient_display.personId);
        event.setExtendedProp("patientId", anmalan.patient);
        event.setExtendedProp("opkod", anmalan.huvudoperationskod_display.code);
        event.setExtendedProp("showPopup", false);
        event.setExtendedProp("personNamn", anmalan.patient_display.namn);
        event.setExtendedProp(
            "operatorer",
            listOfOperatorer(
                anmalan.ass_operatorer_display,
                anmalan.current_bokad_huvudoperator_display?.user_display?.name ?? ""
            )
        );
        event.setExtendedProp("huvudoperation", huvudoperationskodText(anmalan.huvudoperationskod_display));
    }
};

const setEventColors = (event, salId) => {
    const [backgroundColor, textColor] = createEventStyle(salId);
    event.setProp("backgroundColor", backgroundColor);
    event.setProp("textColor", textColor);
};

export {
    createEvent,
    createSparradTidEvent,
    convertDateToSwedishFormat,
    huvudoperationskodText,
    listOfOperatorer,
    setEventInfo,
    setEventColors,
    createEventStyle,
};
