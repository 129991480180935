<template>
    <div :class="context.classes.element" :data-type="context.type">
        <FormulateSlot name="prefix" :context="context">
            <component :is="context.slotComponents.prefix" v-if="context.slotComponents.prefix" :context="context" />
        </FormulateSlot>
        <input-number v-model="context.model" v-bind="context.attributes" @blur="context.blurHandler" />

        <FormulateSlot name="suffix" :context="context">
            <component :is="context.slotComponents.suffix" v-if="context.slotComponents.suffix" :context="context" />
        </FormulateSlot>
    </div>
</template>

<script>
    import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
    import InputNumber from "@/components/InputNumber.vue";

    export default {
        name: "FormulateNumber",
        mixins: [FormulateInputMixin],
        components: {
            InputNumber,
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/style/variables";

    ::v-deep {
        input {
            color: $dark-text-color;
        }
        .buttons {
            a {
                color: $color-icons-light;
            }
            a:nth-child(2) {
                font-size: x-large;
            }
        }
    }
</style>
