<template>
    <div class="ReadOnlyTextWidget">
        <span>
            {{ value }}
        </span>
    </div>
</template>

<script>
export default {
    name: "ReadOnlyTextWidget",
    props: {
        name: String,
        value: String,
        required: Boolean,
        type: String,
        maxlength: Number,
        minlength: Number,
        preview: Boolean,
    },
};
</script>

<style scoped lang="scss">
.ReadOnlyTextWidget {
    padding-top: 12px;
    span {
        color: black;
    }
}
</style>
