/*
Currently a static dictionary.
- Presumably will need to be customized by user and/or workplace.
- Separate dictionaries for Prescriptions, Operations and other tabs?
*/

const dictionary = {
    bfs: "blodfettsänkande",
    bft: "blodförtunnande",
    bpf: "blodproppsförebyggande",
    bss: "blodsockersänkande",
    bts: "blodtryckssänkande",
    da: "dagligen",
    dr: "droppe",
    drr: "droppar",
    dtf: "dosering enligt tryckt föreskrift",
    ea: "enligt anvisning",
    eb: "efter beredning",
    eba: "enligt bruksanvisning",
    ef: "enligt föreskrift",
    eo: "enligt ordination",
    eso: "enligt särskild ordination",
    eto: "enligt tidigare ordination",
    fb: "för blodtrycket",
    fbc: "för blodcirkulationen",
    fh: "för hjärtat",
    fi: "för injektion",
    fmk: "förebyggande mot kärlkramp",
    fs: "för sockret",
    fä: "för ämnesomsättningen",
    gd: "gånger dagligen",
    gå: "gånger",
    ihd: "i högst 10 dagar",
    im: "intramuskulärt",
    inh: "inhalation",
    inr: "inhalationer",
    ins: "för injektion dagligen enligt föreskrift. Blodsockersänkande.",
    ism: "i samband med måltid",
    ivh: "i vardera hörselgången",
    ivn: "i vardera näsborren",
    ivö: "i vardera ögat",
    ka: "kapsel",
    kar: "kapslar",
    lv: "luftrörsvidgande",
    lvi: "luftvägsinfektion",
    ma: "mot allergi",
    mas: "mot astma",
    md: "mot depression",
    mf: "mot förstoppning",
    mh: "mot hosta",
    mhb: "mot högt blodtryck",
    mhv: "mot huvudvärk",
    mi: "mot infektion",
    mif: "mot inflammation",
    mk: "mot kärlkramp",
    mo: "mot oro",
    mok: "morgon och kväll",
    msb: "mot sömnbesvär",
    mss: "mot sköra slemhinnor",
    mtm: "mot trög mage",
    mv: "mot värk",
    mvi: "mot värk och inflammation",
    ndr: "näsdroppar",
    pd: "per dygn",
    pu: "pulver",
    påt: "påstrykes tunt",
    sha: "slemhinneavsvällande",
    sk: "subkutant",
    sl: "slemlösande",
    sp: "sprayning",
    spr: "sprayningar",
    su: "stolpiller",
    sut: "smältes under tungan",
    ta: "tablett",
    tar: "tabletter",
    tk: "till kvällen",
    tn: "till natten",
    utv: "utvärtes",
    uvi: "urinvägsinfektion",
    vad: "vattendrivande",
    vb: "vid behov",
    vg: "vagitorium",
    vh: "vid hosta",
    vo: "vid oro",
    vsb: "vid sömnbesvär",
    vv: "vid värk",
    väd: "vätskedrivande",
    ögd: "ögondroppar",
    örd: "örondroppar",
};

const getDefaultState = () => {
    return {
        dictionary: dictionary,
        selected: null,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setSelected(state, selected) {
            state.selected = selected;
        },
    },
    actions: {
        updateSelected({ commit }, selected) {
            commit("setSelected", selected);
        },
    },
};
