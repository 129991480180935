import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";
import URLSearchParams from "@ungap/url-search-params";

const getDefaultParams = () => {
    return {};
};

const getDefaultState = () => {
    return {
        results: [],
        count: 0,
        limit: 100,
        offset: 0,
        params: getDefaultParams(),
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        results(state) {
            return state.results;
        },
        count(state) {
            return Math.ceil(state.count);
        },
        limit(state) {
            return state.limit;
        },
        offset(state) {
            return state.offset;
        },
        params(state) {
            return state.params;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setResults: (state, results) => {
            state.results = results;
        },
        setCount: (state, count) => {
            state.count = count;
        },
        setLimit: (state, limit) => {
            state.limit = limit;
        },
        setOffset: (state, offset) => {
            state.offset = offset;
        },
        setParams: (state, params) => {
            state.params = params;
        },
        resetParams(state) {
            state.params = getDefaultParams();
        },
    },
    actions: {
        async load({ commit, state }, failSilently = false) {
            try {
                const qs = new URLSearchParams(state.params);
                qs.set("limit", state.limit);
                qs.set("offset", state.offset);

                const response = await klinikenApi.get(`core/journalloggar/?${qs}`, getConfig());
                commit("setResults", response.data.results);
                commit("setCount", response.data.count);
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta journallogg." + getErrorMessage(e), "warning");
                }
            }
        },
        setOffset: ({ commit }, offset) => {
            commit("setOffset", offset);
        },
        setParams: ({ commit }, params) => {
            if (params.patient__personId) {
                const filteredArray = params.patient__personId.split("").filter((ch) => ch !== "-");
                params.patient__personId = filteredArray.join("");
            }
            commit("setParams", params);
        },
        resetParams({ commit }) {
            commit("resetParams");
        },
    },
};
