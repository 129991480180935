export default function getUrlParams() {
    let urlParams = {};
    for (const [key, value] of new URLSearchParams(location.search)) {
        // om key redan existrar har variabeln flera värden
        if (urlParams[key]) {
            // konverterar till array om den inte redan är det
            if (!Array.isArray(urlParams[key])) {
                urlParams[key] = [urlParams[key]];
            }
            urlParams[key].push(value);
        } else {
            urlParams[key] = value;
        }
    }
    return urlParams;
}
