<template>
    <div class="popup-modal">
        <vue-final-modal
            v-model="popupProp"
            @click-outside="clickedOutside"
            classes="modal-container"
            content-class="popup-modal"
        >
            <div class="modal-view">
                <div :class="popupType">
                    <div>
                        <img class="header-image" src="@/assets/dialog_warning.svg" />
                    </div>
                    <button type="button" class="header-cancel" @click.prevent="cancelPopup">
                        <img src="@/assets/actionIcons/Close.svg" />
                    </button>
                </div>
                <div class="list">
                    {{ popupMessage.message }}
                    <div>
                        <ol>
                            <li
                                style="
                                     {
                                        list-style: none;
                                        list-style-type: none;
                                    }
                                "
                                v-for="(item, index) in popupMessage.errorList"
                                :key="index"
                            >
                                <span>{{ item }}</span>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="button-group">
                    <ButtonGroup class="button">
                        <Button @click="cancelPopup()" class="columns-1" variant="contour" size="secondary">{{
                            popupMessage.buttonsTitles.cancel
                        }}</Button>
                        <div class="" v-if="popupMessage.buttonsTitles.action">
                            <Button v-if="popupMessage.typofAction === 'removeTab'" @click="closeTabAndclean()">{{
                                popupMessage.buttonsTitles.action
                            }}</Button>
                            <Button
                                v-if="popupMessage.typofAction === 'donotremoveTab'"
                                @click="closeAndReturnTrue()"
                                >{{ popupMessage.buttonsTitles.action }}</Button
                            >
                        </div>
                    </ButtonGroup>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import { mapActions } from "vuex";
export default {
    name: "KlinikenPopupExport",
    components: {
        Button,
        ButtonGroup,
        VueFinalModal,
        ModalsContainer,
    },
    props: {
        popupProp: { type: Boolean, default: true },
        typofPopup: { type: String, default: "" },
        componentName: { type: String, default: "" },
        tabId: {
            type: Number,
            default: null,
        },
        popupMessage: {
            type: Object,
            default: {
                message: "MESSAGE",
                buttonsTitles: {
                    cancel: "CANCEL",
                    action: "",
                },
                typofAction: "withRemovingTab",
                errorList: [],
            },
        },
    },
    data() {
        return {
            popupBoolean: false,
        };
    },
    computed: {
        popupType() {
            switch (this.typofPopup) {
                case "warning":
                    return "warning-header popup-header";
                case "success":
                    return "success-header popup-header";
                case "danger":
                    return "danger-header popup-header";
                default:
                    return "";
            }
        },
    },
    methods: {
        ...mapActions("tabs", ["closeTab"]),
        cancelPopup() {
            this.$emit("cancelPopup", false);
        },
        async closeTabAndclean() {
            await this.closeTab(this.tabId);
            this.$emit("closePopup", false);
        },
        closeAndReturnTrue() {
            this.$emit("closeAndReturnTrue", true);
        },
        clickedOutside() {
            this.cancelPopup();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.popup-modal {
    .modal-view {
        width: 585px;
        min-height: 443px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        ::v-deep .list {
            margin: 0 30px;
            font-size: 35px;
            font-weight: 200;
            line-height: 47px;
            color: $color-text-popup;
            ol {
                margin: 10px 5px;
                padding-left: 0;
                li {
                    font-size: 20px;
                }
            }
        }

        .popup-header {
            height: 150px;
            margin-bottom: 15px;
            div {
                position: absolute;
                left: 50%;
                top: 50%;
                margin: auto;
                transform: translate(-50%, -50%);
            }
            .header-cancel {
                position: absolute;
                right: 0;
                top: 0;
                background-color: transparent;
                border: none;
                padding: 10px;
            }
        }
        .button-group {
            margin-right: 30px;
            margin-bottom: 10px;
            justify-content: flex-end;
            button {
                margin-left: 15px;
                align-self: flex-end;
            }
        }
    }
}
</style>
