import { getConfig, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

export default {
    namespaced: true,
    state: {
        operationsAnmalningar: [],
        operationAnmalningarCount: 0,
        operation_id: "",
    },
    getters: {
        getAnmalningar: (state) => state.operationsAnmalningar,
        getAnmalningarCount: (state) => state.operationAnmalningarCount,
        getOperationID: (state) => state.operation_id,
    },
    mutations: {
        setAnmalningar: (state, operationsoversikter) => (state.operationsAnmalningar = operationsoversikter),
        setAnmalningarCount: (state, operationAnmalningarCount) =>
            (state.operationAnmalningarCount = operationAnmalningarCount),
        setOpOversikt: (state, { index, operationoversikt }) => {
            state.operationsAnmalningar[index] = operationoversikt;
        },
        setOperationID: (state, id) => (state.operation_id = id),
    },
    actions: {
        updateAnmalningarList({ commit, state }, oversikt) {
            const oversikter = state.operationsAnmalningar.map((listItem, index) => {
                if (listItem.id === oversikt.id) {
                    return oversikt;
                } else {
                    return listItem;
                }
            });
            commit("setAnmalningar", oversikter);
        },
        // I am useing _getData function to be only responsible for fetching data. We can make it more flexible to use it for all types of axios get events.
        _getData: async ({ commit }, payloadObj) => {
            const { url, errorMessage } = payloadObj;
            try {
                const response = await klinikenApi.get(url, getConfig());
                if (response) {
                    commit("setAnmalningarCount", response.data.count);
                    return response.data.results;
                }
            } catch (error) {
                console.log("error: " + error);
                openDialog(errorMessage, "error");
            }
        },
        setOperationId({ commit }, id) {
            commit("setOperationID", id);
        },
        fetchAnmalningar: ({ commit, dispatch }, _oversikter) => {
            commit("setAnmalningar", _oversikter);
        },
    },
};
