<template>
    <div class="FilhanteringInlasning">
        <FilhanteringSteg />
        <template v-for="displayIndex in lokalUppladdningAntal">
            <FilhanteringInlasningForm
                @continuetonextfile="lokalUppladdningNastaFil"
                @closetab="closeTab"
                :key="displayIndex + 100"
                :index="displayIndex - 1"
                v-show="lokalUppladdningIndex === displayIndex - 1"
            />
        </template>
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import FilhanteringInlasningForm from "./FilhanteringInlasningForm.vue";
import FilhanteringSteg from "./FilhanteringSteg.vue";
import fileIcon from "@/assets/files.svg";
import { mapActions, mapGetters } from "vuex";

export default {
    extends: BaseTab,
    name: "FilhanteringInlasning",
    tabName: "Läs in fil",
    icon: fileIcon,
    patientBound: true,
    components: {
        FilhanteringInlasningForm,
        FilhanteringSteg,
    },

    data() {
        return {
            value: {
                files: [],
                index: 0,
                queueIndex: 0,
                queues: ["Personlig kö"],
            },
        };
    },

    computed: {
        ...mapGetters("filhantering", [
            "lokalUppladdningAntal",
            "lokalUppladdningIndex",
            "lokalUppladdningDisplayIndex",
            "lokalUppladdningAktuellFil",
            "lokalUppladdningVisaNastaKnapp",
            "lokalUppladdningVisaForegaendeKnapp",
            "lokalUppladdningFormsIsClean",
        ]),
        hasUnsavedData() {
            return !this.lokalUppladdningFormsIsClean;
        },
    },

    methods: {
        ...mapActions("filhantering", ["lokalUppladdningNastaFil"]),
    },

    created() {
        this.$store.dispatch("filhantering/laddaFilerFranBrowser", { files: this.parameters.grundfiler });
    },
};
</script>
