<template>
    <div class="input-wrapper" :id="'input-wrapper-' + inputName">
        <template v-if="!collapsable && !checkboxLabel">
            <span v-if="label_" :class="{ error: error_, warning: warning_ }">{{ label_ }}</span>
            <span v-else>&nbsp;</span>
        </template>
        <button
            v-else-if="!checkboxLabel"
            type="button"
            @click="
                expanded = !expanded;
                collapsable();
            "
        >
            <img :class="{ rotate: !expanded }" src="@/assets/dropdown-arrow-blue.svg" />
            {{ label_ }}
        </button>
        <span v-if="definition" class="definition">{{ definition }}</span>
        <component
            :required="required"
            :tabindex="tabindex"
            :class="{ error: error_, warning: warning_ }"
            :is="component"
            v-bind="$attrs"
            :label="checkboxLabel"
        />
        <span class="error" v-if="error_">{{ error_ }}</span>
        <template v-if="warning_ && !expandedWarning">
            <img src="@/assets/warning_dark_contour.svg" style="float: left; margin-right: 3px; margin-top: 1px" /><span
                :class="{ warning: true, suggest: suggest }"
                >{{ warning_ }}</span
            >
        </template>
        <div
            class="expanded-warning suggest"
            v-if="warning_ && expandedWarning"
            @click="collapseWarning = !collapseWarning"
        >
            <div>
                <span class="expanded-warning-icon">!</span>
            </div>
            <div>
                {{ warning_.header }}
                <span v-if="!collapseWarning">{{ warning_.message }}</span>
            </div>
            <div><img :class="{ rotate: !collapseWarning }" src="@/assets/dropdown-arrow.svg" /></div>
        </div>
    </div>
</template>
<script>
/**
 * InputWrapper is a wrapper around generic widgets for use in forms. It contains CSS for displaying widgets in a flexbox and
 * logic to change appearance to show error messages if e.g. a component is required and empty.
 */
import linkEvents from "@/linkEvents";

export default {
    name: "InputWrapper",
    inheritAttrs: false,
    props: {
        label: String,
        component: Object,
        error: String,
        warning: String,
        highlightColor: String,
        onChange: Function,
        tabindex: Number,
        collapsable: undefined, //should be Function, but if defined as function will not return undefined even if it is undefined
        definition: String,
        checkboxLabel: String,
        sokordParameters: Object, // Used in journalanteckningar to avoid destructuring parameters breaking reactivity.
        required: undefined, // String or boolean
    },
    data() {
        return {
            expanded: true,
            inputName: this.$attrs.name,
            required_: this.required,
            value: this.$attrs.value,
            defaultErrorMessage: "Ange text",
            customErrorMessage: null,
            customWarningMessage: null,
            inputHasHadFocus: false,
            expandedWarning: false,
            collapseWarning: true,
            suggest: false,
        };
    },
    computed: {
        /**
         * For some reason, required breaks reactivity when passed as an attr. Quick and dirty fix.
         */
        /*
        required() {
            return this.$attrs.required || false;
        },*/
        error_() {
            if (this.customErrorMessage) return this.customErrorMessage;
            else if (this.error) return this.error;
            else if (
                this.required_ &&
                (this.value === null || this.value === undefined || this.value === "") &&
                this.inputHasHadFocus
            )
                // value can be "false"
                return this.defaultErrorMessage;
            return null;
        },
        warning_() {
            if (this.customWarningMessage) return this.customWarningMessage;
            else if (this.warning) return this.warning;
            return null;
        },
        label_() {
            var label = this.label;
            if (this.required_) label += " *";
            return label;
        },
        valueAttr() {
            return this.$attrs.value;
        },
    },
    methods: {
        setInputHasHadFocus() {
            this.inputHasHadFocus = true;
        },
        valueChanged(value) {
            this.value = value;
        },
        updateValues({ value, required }) {
            if (typeof required !== "undefined") this.required_ = required;
            if (typeof value !== "undefined") this.value = value;
        },
        setErrorMessage(value) {
            this.customErrorMessage = value;
        },
        setWarningMessage(value) {
            this.customWarningMessage = value;
        },
        setBackground(e) {
            let targetElement = e.target.parentNode;
            while (targetElement.classList.contains("colspan") !== true) {
                targetElement = targetElement.parentNode;
            }
            targetElement.style.backgroundColor = this.highlightColor;
        },
        clearBackground(e) {
            let targetElement = e.target.parentNode;
            while (targetElement.classList.contains("colspan") !== true) {
                targetElement = targetElement.parentNode;
            }
            targetElement.style.backgroundColor = "";
        },
        setFocus(e) {
            e.preventDefault();
            document.getElementsByName(this.inputName)[0].focus();
        },
        setFocusDisplay(e) {
            e.preventDefault();
            document.getElementsByName(this.inputName + "Display")[0].focus();
        },
    },
    mounted() {
        switch (this.component.name) {
            case "RangeWidget":
                this.defaultErrorMessage = "Ange värde";
                break;
            case "SelectWidget":
                this.defaultErrorMessage = "Välj alternativ";
                break;
            case "DosageSuggestTextArea":
                this.expandedWarning = true;
                this.suggest = true;
                break;
            case "SuggestTextArea":
                this.suggest = true;
                break;
        }
        linkEvents.$on(this.inputName + "_changed", this.valueChanged);
        linkEvents.$on("update_" + this.inputName, this.updateValues);
        linkEvents.$on(this.inputName + "_error", this.setErrorMessage);
        linkEvents.$on(this.inputName + "_warning", this.setWarningMessage);
        linkEvents.$on(this.inputName + "_focus", this.setInputHasHadFocus);
        if (this.highlightColor) {
            document.getElementById("input-wrapper-" + this.inputName).addEventListener("focusin", this.setBackground);
            document
                .getElementById("input-wrapper-" + this.inputName)
                .addEventListener("focusout", this.clearBackground);
        }
        if (
            this.component.name == "PersonIdWidget" ||
            this.component.name == "SelectWidget" ||
            this.component.name == "AutoCompleteWidget"
        )
            // These components utilizes hidden inputs with the actual name and name+'Display' for the visible input
            document.getElementsByName(this.inputName + "Display")[0].addEventListener("invalid", this.setFocusDisplay);
        else {
            let input = document.getElementsByName(this.inputName)[0];
            if (input) {
                input.addEventListener("invalid", this.setFocus);
            }
        }
    },
    beforeDestroy() {
        linkEvents.$off(this.inputName + "_changed", this.valueChanged);
        linkEvents.$off("update_" + this.inputName, this.updateValues);
        linkEvents.$off(this.inputName + "_error", this.setErrorMessage);
        linkEvents.$off(this.inputName + "_warning", this.setWarningMessage);
        linkEvents.$off(this.inputName + "_focus", this.setInputHasHadFocus);
    },
    watch: {
        required() {
            this.required_ = this.required;
        },
        valueAttr() {
            this.value = this.$attrs.value;
        },
    },
};
</script>

<style lang="sass">
.input-wrapper
    @import "@/style/variables"

    /* Bootstrap - Start */
    @import "bootstrap/scss/functions"
    @import "bootstrap/scss/variables"
    @import "bootstrap/scss/mixins"
    @import "bootstrap/scss/root"
    @import "bootstrap/scss/reboot"

    @import "bootstrap/scss/forms"
    /* Bootstrap - End */


    padding-bottom: 12px
    margin-bottom: 20px
    position: relative

    .definition
        height: 21px
        color: #7F8FA4
        font-size: 14px
        letter-spacing: 0
        line-height: 21px
        display: block
        margin-bottom: 5px

    .expanded-warning
        box-sizing: border-box
        border: 1px solid #EFC2D4
        border-radius: 3px
        background-color: #F7E1E9
        color: #354052
        display: flex
        padding: 10px 0px 10px 0px

        &.suggest
            width: calc(100% - 150px)

        span
            font-size: 14px
            color: #354052
            white-space: normal
            display: block

        .expanded-warning-icon
            padding-left: 5.5px
            padding-top: 2px
            color: #FFF
            font-size: 12px
            line-height: 12px
            font-weight: bold
            height: 14px
            width: 14px
            border-radius: 7px
            border: none
            background-color: #354052
            margin-top: 5px
            margin-left: 5px
            margin-right: 10px

        img
            margin-top: 25px
            margin-left: 5px
            margin-right: 10px

            &.rotate
                transform: rotate(180deg)

    > button
        img
            height: 12px
            width: 12px
            margin-right: 5px
            margin-top: -5px

            &.rotate
                transform: rotate(180deg)

    input, textarea, select
        -webkit-border-radius: 4px
        -moz-border-radius: 4px
        border-radius: 4px
        border: 1px solid #95A1B1
        //margin-bottom: 32px
        padding: 10px

    .error
        input, textarea, select
            border-bottom: 4px solid #9E354B !important

    input, select, textarea
        &.error
            border-bottom: 4px solid #9E354B !important

    span
        color: #728296
        font-size: 16px
        font-weight: 500
        line-height: 21px
        display: block
        margin-bottom: 2px
        position: relative
        //white-space: nowrap
        white-space: normal

        &.error
            color: #9E354B

        &.error:not(:first-of-type)
            font-size: 14px
            line-height: 21px
            font-weight: 400
            //margin-top: -32px
            margin-left: 16px

            &::before
                content: '!'
                padding-left: 5.5px
                padding-top: 2px
                color: #FFF
                font-size: 12px
                line-height: 12px
                font-weight: bold
                height: 14px
                width: 14px
                border-radius: 7px
                border: none
                background-color: #9E354B
                position: absolute
                left: -16px
                top: 2px

        &.warning:not(:first-of-type)
            color: #354052
            font-size: 14px
            line-height: 21px
            font-weight: 400

            &.suggest
                white-space: normal
                width: calc(100% - 150px)
    //overflow: hidden
    //text-overflow: ellipsis

    /* Input sizes */

    input, select
        height: 46px !important
        width: 100%

    textarea
        //min-height: 81px
        width: 100%

    div[contenteditable="true"]
        border-radius: 4px
        border: 1px solid #95A1B1
        padding: 5px
        width: 60em
        min-height: 5em
        overflow: auto
        background-color: #FFFFFF !important

.k3-form
    textarea
        min-height: 81px
</style>
