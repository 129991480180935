import { getConfig, klinikenApi } from "@/api";
import { cloneDeep } from "lodash";

const getDefaultState = () => {
    return {
        spelare: {
            id: null,
            spelarelag: [],
            patient: null,
            created_at: null,
            created_by: null,
            updated_at: null,
            updated_by: null,
        },
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        spelare(state) {
            return state.spelare;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setSpelare: (state, spelare) => {
            state.spelare = spelare;
        },
    },
    actions: {
        async save({ commit }, spelare) {
            let response = await klinikenApi.put(`skreg/spelare/${spelare.patient}/`, spelare, getConfig());
            commit("setSpelare", cloneDeep(response.data));
            return response;
        },
        async load({ commit }, patient) {
            let response = await klinikenApi.get(`skreg/spelare/${patient.id}`, getConfig());
            commit("setSpelare", response.data);
        },
    },
};
