<script>
export default {
    name: "ButtonLink",
};
</script>

<template>
    <button class="button button-link" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </button>
</template>

<style lang="scss" scoped>
@import "@/style/button";
</style>
