<template>
    <Labportalen :tab-id="this.tabId" :endpoint="endpoint" />
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import tabIcon from "@/assets/labPortal/patientoversikt.svg";
import Labportalen from "@/tabs/LabPortalen/Labportalen.vue";
import { mapGetters } from "vuex";

export default {
    components: { Labportalen },
    extends: BaseTab,
    patientBound: true,
    name: "LabPortalenPatientoversikt",
    icon: tabIcon,
    tabName: "Labportalen patientöversikt",

    computed: {
        ...mapGetters("patientData", ["patient"]),
        endpoint() {
            return `/labportalen/webapplication/patienter/${this.patient.id}/patientoversikt/`;
        },
    },
    props: ["parameters", "tabId"],
};
</script>
