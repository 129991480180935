<template>
    <ModalContent title="Kommentera" :showCloseButton="true" @close="cancel">
        <div class="kommentera-popup">
            <div class="content">
                <div class="comment-info">
                    <img class="info-img" src="@/assets/info_dark.svg" alt="operation" />
                    &nbsp;Kommentaren visas i dagsöversikten
                </div>
                <FormulateInput
                    type="textarea"
                    label="Kommentar (max 120 tecken)"
                    v-model="kommentar"
                    :readonly="readonly"
                />
            </div>
            <ButtonGroup>
                <Button @click="submit" :disabled="readonly">Spara kommentar</Button>
                <Button size="secondary" variant="contour" @click="cancel">Avbryt</Button>
            </ButtonGroup>
        </div>
    </ModalContent>
</template>
<script>
    import ModalContent from "@/components/generic/ModalContent.vue";
    import InputTextArea from "@/components/InputTextArea.vue";
    import InputCheckbox from "@/components/InputCheckbox.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import Button from "@/components/Button.vue";

    export default {
        name: "Kommentera",
        components: {
            ModalContent,
            InputTextArea,
            InputCheckbox,
            ButtonGroup,
            Button,
        },
        props: {
            value: {
                type: String,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                kommentar: null,
            };
        },
        methods: {
            cancel() {
                this.$emit("cancel");
            },
            submit() {
                this.$emit("submit", this.kommentar);
            },
        },
        created() {
            this.kommentar = this.value;
        },
    };
</script>
<style lang="scss">
    @import "@/style/modal.scss";
    .kommentera-popup {
        min-height: 374px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .comment-info {
            margin: 14px 0 28px 0;
        }
    }
</style>
