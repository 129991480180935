<script>
export default {
    name: "SectionDivider",
};
</script>

<template>
    <hr />
</template>

<style lang="scss" scoped>
hr {
    margin-left: 1.5rem;
    border: 0;
    border-top: 1px solid #b9d8e3;
    margin-bottom: 30px;
}
</style>
