<template>
    <input
        :name="name"
        :required="required"
        :maxlength="maxlength"
        :minlength="minlength"
        class="text-input"
        disabled
        v-model="value_"
    />
</template>

<script>
import TextWidget from "@/components/widgets/TextWidget.vue";
import linkEvents from "@/linkEvents";

export default {
    extends: TextWidget,
    name: "StaticTextWidget",
    data() {
        return {
            value_: this.value,
        };
    },
    methods: {
        update({ value }) {
            this.value_ = value;
        },
    },
    created() {
        linkEvents.$on("update_" + this.name, this.update);
    },
};
</script>
