<template>
    <!-- <input type="date" :name="name" :required="required" v-model="value" class="date" /> -->

    <!-- NOTE: Input type="date" doesn't work in IE - who'd a thunk it? -->

    <date-picker
        @change="valueChanged"
        :show-week-number="true"
        :show-second="false"
        type="datetime"
        :input-attr="{ name: name }"
        @input-error="showWarning"
        :formatter="customFormat"
        v-model="value_"
        :lang="sv"
        :value="value"
    >
        <img slot="icon-clear" src="@/assets/cross_circle.svg" />
        <img slot="icon-calendar" src="@/assets/calendar.svg" />
    </date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/scss/index.scss";
import linkEvents from "@/linkEvents";
import $ from "jquery";

const numericDateTime = new RegExp(
    "^([0-9]{2}){1,2}[-]?((0[0-9])|(10|11|12))[-]?(([0-2][0-9])|(3[0-1]))[ ](([0-9]{2})[:]?){2}$"
); // Matches date in (YY)YYMMDD HHMM(SS) with spaces, dashes and colon.

export default {
    name: "DateWidget",
    props: {
        name: String,
        value: String,
        required: Boolean,
        notBefore: String,
        notAfter: String,
    },
    data: function () {
        return {
            value_: this.value,
            sv: {
                days: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
                months: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
                pickers: ["nästa 7 dagar", "nästa 30 dagar", "föregående 7 dagar", "föregående 30 dagar"],
                placeholder: {
                    date: "", // 'Välj datum'
                    dateRange: "", // 'Välj datumintervall'
                },
                formatLocale: {
                    firstDayOfWeek: 1,
                },
            },
            customFormat: {
                stringify: (date) => {
                    //return date ? new Date(date).toLocaleString('default', { day: 'numeric', year: 'numeric', month: 'long' }) : null;
                    return date ? date.toLocaleString("sv-SE").slice(0, -3) : null;
                },

                /**
                 * Custom parse, takes any numericalDate or naturalDate (see regexp above) and transforms them into a date if possible.
                 */
                parse: (value) => {
                    return this.customParse(value);
                },
            },
        };
    },
    components: {
        DatePicker,
    },
    computed: {
        isIE: function () {
            var ua = navigator.userAgent;
            /* MSIE used to detect old browsers and Trident used to newer ones*/
            return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
        },
    },
    methods: {
        valueChanged(payload) {
            linkEvents.$emit(this.name + "_changed", this.customFormat.stringify(payload));
        },
        customParse(value) {
            let dateArray = [];

            // If format matches the above regexp, it's a date. DatePicker uses autocorrection, Date.parse() does not, at least not in Chrome.
            if (value.match(numericDateTime)) {
                let rawDate = value.replace(/\D/g, "");
                dateArray[0] = parseInt(rawDate.slice(0, -8));
                if (dateArray[0] < 100) dateArray[0] += 2000; //Convert 19 to 2019
                dateArray[1] = parseInt(rawDate.slice(-8, -6)) - 1;
                dateArray[2] = parseInt(rawDate.slice(-6, -4));
                dateArray[3] = parseInt(rawDate.slice(-4, -2));
                dateArray[4] = parseInt(rawDate.slice(-2));
            } else return NaN;
            return new Date(dateArray[0], dateArray[1], dateArray[2], dateArray[3], dateArray[4]);
        },
        showWarning() {
            linkEvents.$emit(this.name + "_error", "Felaktigt datum");
            let name = this.name;
            // Can't capture @input of Datepicker, so can't make the error message change reactively upon new input. Using setTimeout instead.
            setTimeout(
                () => {
                    linkEvents.$emit(name + "_error", null);
                },
                5000,
                name
            );
        },
        update({ value }) {
            this.value_ = value;
        },
    },
    created() {
        linkEvents.$on("update_" + this.name, this.update);
    },
    mounted: function () {
        /* Move text 25 down in IE due to different SVG implementations */
        if (this.isIE) $("text").attr("y", 160);
    },
};
</script>
