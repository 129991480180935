<template>
    <basic-form :errors="errors" @submit="submit" @cancel="cancel" />
</template>

<script setup>
    import { ref } from "vue";
    import { getConfig, klinikenApi } from "@/api";
    import BasicForm from "@/components/Auth/BasicForm.vue";

    const errors = ref({});

    const emit = defineEmits(["success", "error", "cancel"]);

    const submit = async (formData) => {
        try {
            const response = await klinikenApi.post(
                "auth/basic/",
                { username: formData.username, password: formData.password },
                getConfig()
            );
            emit("success", response.data);
        } catch (error) {
            if (error.response.status === 401) {
                emit("error", "Det gick inte att logga in.");
            } else if (error.response.status === 400) {
                errors.value = error.response.data;
            } else {
                emit("error", "Det gick inte att logga in.");
            }
        }
    };

    const cancel = () => {
        emit("cancel");
    };
</script>
