<template>
    <input
        v-model="value_"
        :disabled="disabled_"
        :type="type"
        :name="name"
        :autocomplete="autocomplete_"
        :required="required"
        :maxlength="maxlength"
        :minlength="minlength"
        class="text-input"
        @focus="hasFocus(true)"
        @blur="hasFocus(false)"
        @change="valueChanged($event)"
    />
</template>

<script>
import linkEvents from "@/linkEvents";

export default {
    name: "TextWidget",
    data() {
        return {
            value_: this.value,
            autocomplete_: this.autocomplete || new Date().getTime().toString(),
            focus: false,
            disabled_: this.disabled,
        };
    },
    props: {
        name: String,
        value: String,
        required: Boolean,
        definition: Object,
        type: String,
        maxlength: undefined,
        minlength: undefined,
        autocomplete: String,
        disabled: Boolean,
    },
    methods: {
        valueChanged(event) {
            linkEvents.$emit(this.name + "_changed", event.target.value);
        },
        update({ value, disabled }) {
            if (value !== null && value !== undefined) this.value_ = value;
            if (disabled !== null && disabled !== undefined) this.disabled_ = disabled;
        },
        hasFocus(focus) {
            if (focus)
                // first time input gets focus
                this.focus = true;
            if (!focus && this.focus)
                // every time input loses focus
                linkEvents.$emit(this.name + "_focus");
        },
    },
    created() {
        linkEvents.$on("update_" + this.name, this.update);
    },
    watch: {
        /**
         * value (a prop) can change as a result of ExpandableSelect changing indeces. When that happens, re-initialize values.
         */
        value() {
            this.value_ = this.value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
/* Bootstrap - End */

@import "@/style/deprecated_main";
</style>
