<template>
    <ModalOverlay>
        <div class="modal-content" :class="{ wide: wide }">
            <div class="head">
                <slot name="head">
                    <div class="title">{{ title }}</div>
                    <button class="close-button" v-if="showCloseButton" @click="$emit('close')">
                        <img src="@/assets/close_modal.svg" alt="Stäng" />
                    </button>
                </slot>
            </div>
            <div class="body">
                <slot></slot>
            </div>
        </div>
    </ModalOverlay>
</template>

<script>
import ModalOverlay from "@/components/generic/ModalOverlay.vue";

export default {
    name: "ModalContent",
    components: {
        ModalOverlay,
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        showCloseButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        wide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss">
@import "@/style/variables";

.modal-content {
    max-height: 80vh;
    overflow: hidden;

    width: 770px;
    max-width: 770px;

    &.wide {
        width: 953px;
        max-width: 953px;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;

    .head {
        position: relative;
        width: 100%;
        height: 86px;
        padding: 21px 28px;
        display: flex;
        align-items: center;
        background-color: $color-active-dark;

        .title {
            line-height: 42px;
            color: #ffffff;
            font-size: 38px;
            margin: auto;
        }

        button {
            all: unset;
            position: absolute;
            right: 28px;
            cursor: pointer;
        }
    }

    .body {
        padding: 42px 96px;
        overflow: auto;
    }
}
</style>
