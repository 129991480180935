import { getConfig, klinikenApi } from "@/api";
import { cloneDeep } from "lodash";

const getDefaultState = () => {
    return {
        platser: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        platser(state) {
            return state.platser;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setPlats: (state, plats) => {
            const index = state.platser.findIndex((i) => i.id === plats.id);

            if (index !== -1) {
                state.platser[index] = plats;
            } else {
                state.platser.push(plats);
            }

            state.platser = [...state.platser];
        },
        setPlatser: (state, payload) => (state.platser = payload),
    },
    actions: {
        async save({ dispatch, commit }, plats) {
            let response;
            if (plats.id === undefined) {
                response = await dispatch("create", plats);
            } else {
                response = await dispatch("update", plats);
            }
            commit("setPlats", cloneDeep(response.data));
            return response;
        },
        async create({}, plats) {
            return await klinikenApi.post(`skreg/platser/`, plats, getConfig());
        },
        async update({}, plats) {
            return await klinikenApi.put(`skreg/platser/${plats.id}/`, plats, getConfig());
        },
        async load({ commit }) {
            const response = await klinikenApi.get(`skreg/platser/`, getConfig());
            commit("setPlatser", response.data);
        },
    },
};
