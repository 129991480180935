<template>
    <div class="FilhanteringForhandsvisning colspan whole">
        <FilhanteringForhandsvisningModal
            v-if="modalForevisa"
            v-bind:file="file"
            v-bind:url="url"
            v-bind:metaData="metaData"
        ></FilhanteringForhandsvisningModal>
        <FilhanteringForhandsvisningContainer v-bind:file="file" v-bind:url="url" v-bind:metaData="metaData">
        </FilhanteringForhandsvisningContainer>
        <div class="footer">
            <div class="info">
                <span class="filnamn"> {{ metaData.ursprungligtFilnamn }}</span>
                <span>{{ metaData.filstorlek }} </span>
                <span>{{ metaData.filformat }} </span>
            </div>
            <span class="zoomicon" role="button" @click="modalVisa"></span>
        </div>
    </div>
</template>

<script>
import FilhanteringForhandsvisningModal from "./FilhanteringForhandsvisningModal.vue";
import FilhanteringForhandsvisningContainer from "./FilhanteringForhandsvisningContainer.vue";

import { mapMutations, mapGetters } from "vuex";

export default {
    name: "FilhanteringForhandsvisning",
    props: ["file", "url", "metaData"],

    computed: {
        ...mapGetters("filhantering", ["modalForevisa"]),
    },
    components: {
        FilhanteringForhandsvisningContainer,
        FilhanteringForhandsvisningModal,
    },

    methods: {
        ...mapMutations("filhantering", ["modalVisa"]),
    },
};
</script>

<style lang="scss" scoped>
div.FilhanteringForhandsvisning {
    padding: 1rem;

    padding-bottom: 1rem;
    margin-bottom: 2rem;

    background-color: #f0f3f8;

    div.footer {
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;

        span {
            display: inline-block;
            margin-right: 0.5rem;
        }

        span.filnamn {
            margin-right: 3rem;
        }

        span.zoomicon {
            $side: 1rem;
            background-image: url("@/assets/external_link_menu.svg");
            background-repeat: no-repeat;
            width: $side;
            height: $side;

            background-size: cover;
            margin-right: 1rem;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
</style>
