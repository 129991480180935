<template>
    <div class="suggest-wrap">
        <!--
            Important! keyup.esc.stop and keydown.enter.prevent necessary to prevent these keys from propagating to form
        -->
        <input
            v-model="q"
            :required="required"
            :name="name + 'Display'"
            v-on:input="updateValue"
            autocomplete="off"
            class="text-input"
            type="search"
            @keyup.esc.stop="validate"
            @keydown.down="moveDown"
            @keydown.up="moveUp"
            @keydown.enter.prevent="settle"
            @blur="
                isOpen = false;
                q = '';
            "
            @focus="isOpen = true"
            @wheel.prevent.stop="mouseWheel($event)"
            v-bind="$attrs"
            @keydown.enter="openPatientSearch(q)"
        />
        <input type="hidden" :name="name" :value="value_" />

        <ul class="suggest-list" v-show="isOpen" @wheel.prevent.stop="mouseWheel($event)">
            <div>
                <span>Sökträffar</span>
            </div>
            <li
                v-for="(item, index) in this.results"
                :key="index"
                @mouseleave="highlightedPosition = NaN"
                @mouseenter="highlightedPosition = index"
                @mousedown="select"
                :name="name + '-suggest'"
                :class="['suggest-item', { highlighted: index === highlightedPosition }]"
            >
                <div>{{ item.code }}</div>
                {{ item.displayName }}
            </li>
            <div>
                <Button
                    class="search-results-button"
                    type="button"
                    role="button"
                    @mousedown="openPatientSearch(q)"
                    :disabled="!q || q.length === 0"
                    size="secondary"
                >
                    Visa alla sökträffar
                </Button>
                <button class="hollow-button" type="button" role="button" @mousedown="openPatientSearch()">
                    <img class="gray" src="@/assets/patient_filled.svg" />Avancerad patientsök
                </button>
            </div>
        </ul>
        <svg xmlns:svg="<http://www.w3.org/2000/svg>" version="1.1" height="0" width="0" style="display: none">
            <defs>
                <filter id="gray">
                    <feColorMatrix
                        color-interpolation-filters="sRGB"
                        type="matrix"
                        values="0.52 0   0   0   0
                            0   0.61  0   0   0
                            0   0   0.64  0   0
                            0   0   0   1   0 "
                    />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script>
import AutoCompleteWidget from "@/components/widgets/AutoCompleteWidget.vue";
import PatientSearch from "@/tabs/Patient/PatientSearch.vue";
import Button from "@/components/Button.vue";

export default {
    extends: AutoCompleteWidget,
    name: "CustomSuggestWidget",
    components: {
        Button,
    },
    methods: {
        async openPatientSearch(q) {
            await this.$store.dispatch("tabs/openTab", {
                component: PatientSearch,
                parameters: { q },
            });
        },
    },
};
</script>
<style lang="sass" scoped>
@import @/style/variables

/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

@import "bootstrap/scss/type"
@import "bootstrap/scss/buttons"
@import "bootstrap/scss/forms"
/* Bootstrap - End */

@import @/style/deprecated_main

.suggest-wrap
    position: relative

.suggest-list
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
    position: absolute
    left: 0
    top: 48px
    width: 434px
    z-index: 100
    background: #ffffff
    padding: 30px !important
    box-shadow: 2px 2px 9px rgba(211, 216, 218, 0.52)
    border-radius: 4px

    span
        color: #728296
        font-size: 14px
        line-height: 21px
        margin-left: 20px
        margin-bottom: 10px
        display: inline-block

.hollow-button
    height: 28px
    margin-top: 28px
    color: #6C8188
    font-size: 16px
    font-weight: 500
    line-height: 28px
    border: none
    background-color: #FFFFFF
    float: right

    .gray
        height: 22px
        width: 25px
        filter: url('#gray')

.search-results-button
    display: block
    width: 100%

.suggest-item
    list-style: none
    margin: 0
    border-bottom: 1px solid #D3D8DA
    padding: 12px 0px 12px 20px

    div
        width: 150px
        display: inline-block

    &:first-of-type
        border-top: 1px solid #D3D8DA

.highlighted
    background: #eee
    cursor: pointer
</style>
