<template>
    <!-- Added CSS support for empty label -->
    <div style="display: inline-block; margin-top: 12px">
        <label class="radiobutton-container" v-for="option in options" :key="option.text"
            >{{ option.text }}
            <input
                type="radio"
                :checked="value === option.value"
                :required="required"
                :name="name"
                :disabled="disabled"
                v-bind:value="option.value"
                @change="$emit('input', option.value)"
            />
            <span :class="{ checkmark: true, disabled: disabled }"></span>
        </label>
    </div>
</template>
<script>
export default {
    props: {
        options: Array, // Array with [{ text, value }, ... ]
        value: undefined,
        name: String,
        handler: Function, // Handler passed by parent in case radiobutton has additional functionality. TODO: move to InputWrapper?
        disabled: Boolean,
        required: Boolean,
    },
    methods: {
        handle(event) {
            if (this.handler) this.handler(event);
        },
    },
};
</script>
<style lang="sass">
/* Customize the label (the container) */
.radiobutton-container
    //display: block
    position: relative
    padding-left: 35px
    //margin-bottom: 12px
    margin-right: 12px
    cursor: pointer
    //font-size: 22px
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    /* Hide the browser's default radio button */
    input
        position: absolute
        opacity: 0
        cursor: pointer
        height: 0
        width: 0

    /* Create a custom radio button */
    .checkmark
        position: absolute
        top: 0
        left: 0
        height: 25px
        width: 25px
        background-color: #eee
        border-radius: 50%
        border: 1px solid #55676D

        /* Create the indicator (the dot/circle - hidden when not checked) */
        &:after
            content: ""
            position: absolute
            display: none
            /* Style the indicator (dot/circle) */
            top: 8px
            left: 8px
            width: 8px
            height: 8px
            border-radius: 50%
            background: white

/* On mouse-over, add a grey background color */
.radiobutton-container:hover input ~ .checkmark
    background-color: #ccc

/* When the radio button is checked, add a blue background */
.radiobutton-container input:checked ~ .checkmark
  background-color: #CA346F

/* Show the indicator (dot/circle) when checked */
.radiobutton-container input:checked ~ .checkmark:after
  display: block
</style>
