<template>
    <!-- <input type="date" :name="name" :required="required" v-model="value" class="date" /> -->

    <!-- NOTE: Input type="date" doesn't work in IE - who'd a thunk it? -->

    <date-picker
        @change="valueChanged"
        :show-week-number="true"
        :disabled-date="disabledDate"
        @input-error="showWarning"
        :formatter="customFormat"
        v-model="value_"
        :lang="sv"
        :disabled="disabled"
        @focus="hasFocus(true)"
        @blur="hasFocus(false)"
        :input-attr="{ name: name }"
    >
        <img slot="icon-clear" src="@/assets/cross_circle.svg" />
        <img slot="icon-calendar" src="@/assets/calendar.svg" />
    </date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/scss/index.scss";
import linkEvents from "@/linkEvents";
import $ from "jquery";

const numericDate = new RegExp("^([0-9]{2}){1,2}[- ]?((0[0-9])|(10|11|12))[- ]?(([0-2][0-9])|(3[0-1]))$"); // Matches date in YYMMDD or YYYYMMDD with spaces and dashes.
const naturalDate = new RegExp("^(([0-2]?[0-9])|(3[0-1]))[ ][a-zA-Z]{3,}[ ]([0-9]{2}){1,2}$"); // Matches the format { day: 'numeric', year: 'numeric', month: 'long' } in display

export default {
    name: "DateWidget",
    props: {
        name: String,
        value: String,
        required: Boolean,
        notBefore: String,
        notAfter: String,
        disabled: Boolean,
    },
    data: function () {
        return {
            value_: this.value ? this.customParse(this.value) : null,
            sv: {
                days: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
                months: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
                pickers: ["nästa 7 dagar", "nästa 30 dagar", "föregående 7 dagar", "föregående 30 dagar"],
                placeholder: {
                    date: "", // 'Välj datum'
                    dateRange: "", // 'Välj datumintervall'
                },
                formatLocale: {
                    firstDayOfWeek: 1,
                },
            },
            customFormat: {
                stringify: (date) => {
                    //return date ? new Date(date).toLocaleString('default', { day: 'numeric', year: 'numeric', month: 'long' }) : null;
                    return date ? date.toLocaleString("sv-SE").split(" ")[0] : null;
                },

                /**
                 * Custom parse, takes any numericalDate or naturalDate (see regexp above) and transforms them into a date if possible.
                 */
                parse: (value) => {
                    return this.customParse(value);
                },
            },
        };
    },
    components: {
        DatePicker,
    },
    computed: {
        isIE: function () {
            var ua = navigator.userAgent;
            /* MSIE used to detect old browsers and Trident used to newer ones*/
            return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
        },
    },
    methods: {
        disabledDate(date) {
            let now = new Date();
            let before = false;
            let after = false;
            if (this.notBefore === "today") before = date.toLocaleString("sv-SE") < now.toLocaleString("sv-SE");
            if (this.notAfter === "year") {
                now.setFullYear(now.getFullYear() + 1);
                after = date.toLocaleString("sv-SE") > now.toLocaleString("sv-SE");
            }
            return before || after;
        },
        valueChanged(payload) {
            linkEvents.$emit(this.name + "_changed", this.customFormat.stringify(payload));
        },
        customParse(value) {
            let dateArray = [];

            // If format matches the above regexp, it's a date. DatePicker uses autocorrection, Date.parse() does not, at least not in Chrome.
            if (value.match(numericDate)) {
                let rawDate = value.replace(/\D/g, "");
                dateArray[0] = parseInt(rawDate.slice(0, -4));
                if (dateArray[0] < 100) dateArray[0] += 2000; //Convert 19 to 2019
                dateArray[1] = parseInt(rawDate.slice(-4, -2)) - 1;
                dateArray[2] = parseInt(rawDate.slice(-2));
            }
            // If it matches the format for natural date, replace any month whose first 3 letters does not match in Swedish and English with the English name, and parse normally
            else if (value.match(naturalDate)) {
                let reversedDateArray = value.split(" ");
                dateArray[0] = parseInt(reversedDateArray[2]);
                dateArray[1] = reversedDateArray[1];
                dateArray[2] = parseInt(reversedDateArray[0]);
                let month = dateArray[1].slice(0, 3).toLowerCase();
                switch (month) {
                    case "jan":
                        dateArray[1] = 0;
                        break;
                    case "feb":
                        dateArray[1] = 1;
                        break;
                    case "mar":
                        dateArray[1] = 2;
                        break;
                    case "apr":
                        dateArray[1] = 3;
                        break;
                    case "may":
                        dateArray[1] = 4;
                        break;
                    case "maj":
                        dateArray[1] = 4;
                        break;
                    case "jun":
                        dateArray[1] = 5;
                        break;
                    case "jul":
                        dateArray[1] = 6;
                        break;
                    case "aug":
                        dateArray[1] = 7;
                        break;
                    case "sep":
                        dateArray[1] = 8;
                        break;
                    case "okt":
                        dateArray[1] = 9;
                        break;
                    case "oct":
                        dateArray[1] = 9;
                        break;
                    case "nov":
                        dateArray[1] = 10;
                        break;
                    case "dec":
                        dateArray[1] = 11;
                        break;
                }
            } else return NaN;
            return new Date(dateArray[0], dateArray[1], dateArray[2]);
        },
        showWarning() {
            linkEvents.$emit(this.name + "_error", "Felaktigt datum");
            let name = this.name;
            // Can't capture @input of Datepicker, so can't make the error message change reactively upon new input. Using setTimeout instead.
            setTimeout(
                () => {
                    linkEvents.$emit(name + "_error", null);
                },
                5000,
                name
            );
        },
        update({ value }) {
            this.value_ = this.customParse(value);
        },
        hasFocus(focus) {
            if (focus)
                // first time input gets focus
                this.focus = true;
            if (!focus && this.focus)
                // every time input loses focus
                linkEvents.$emit(this.name + "_focus");
        },
    },
    created() {
        linkEvents.$on("update_" + this.name, this.update);
    },
    mounted() {
        // Set required on input
        let el = $('input[name="' + this.name + '"]');
        if (this.required) el.attr("required", true);
    },
    watch: {
        value() {
            this.value_ = this.value ? this.customParse(this.value) : null;
        },
    },
};
</script>

<style lang="scss">
@import "@/style/variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/forms";
/* Bootstrap - End */

.mx-datepicker-main {
    font-family: "Roboto Medium";
}

.mx-calendar-header {
    height: 48px;
    line-height: 48px;
}

.mx-calendar {
    width: 311px;
}

.mx-calendar-content {
    height: 100%;
}

.mx-calendar-content .disabled {
    color: #6c8188;
    background-color: #e4e8f0;
    padding-left: 0px;
    padding-right: 0px;
}

.mx-date-row {
    height: 38px;

    td.cell {
        color: #000000;
        font-size: 16px;
        padding-left: 4px;
        padding-right: 4px;

        &.not-current-month {
            padding-left: 0px;
            padding-right: 0px;
        }

        &.today div {
            border: 1px solid #38a7cf;
        }
    }
}

.mx-btn.mx-btn-text.mx-btn-current-year,
.mx-btn.mx-btn-text.mx-btn-current-month {
    color: #000000;
    font-size: 16px;
}

.mx-calendar-content .cell:hover {
    color: #000000;
    background-color: #efc2d4;
}
</style>
