<template>
    <div class="expand-flex">
        <div class="expand-flex-main" v-for="checkbox in checkboxes" :key="checkbox.name">
            <checkbox-widget v-bind="checkbox"></checkbox-widget>
        </div>
    </div>
</template>

<script>
import CheckboxWidget from "@/components/widgets/CheckboxWidget.vue";

export default {
    name: "CheckboxGroup",
    components: {
        CheckboxWidget,
    },
    props: {
        checkboxes: Array,
    },
};
</script>

<style lang="sass" scoped>
.expand-flex
    display: flex
    min-height: 46px

.expand-flex-main
    flex-grow: 1
</style>
