import FormulateAutocomplete from "@/components/formulate/FormulateAutocomplete.vue";
import FormulateNumber from "@/components/formulate/FormulateNumber.vue";
import FormulateVueSelect from "@/components/formulate/FormulateVueSelect.vue";
import FormulateDatePicker from "@/components/formulate/FormulateDatePicker.vue";
import FormulateNow from "@/components/formulate/FormulateNow.vue";
import FormulateFrasregister from "@/components/formulate/FormulateFrasregister.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import FormulateTimePicker from "@/components/formulate/FormulateTimePicker.vue";

export function FormulateAutocompletePlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateAutocomplete,
        },
        library: {
            autocomplete: {
                classification: "text",
                component: "FormulateAutocomplete",
                slotProps: {
                    component: ["url"],
                },
            },
        },
    });
}

export function FormulateNumberPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateNumber,
        },
        library: {
            knumber: {
                classification: "text",
                component: "FormulateNumber",
            },
        },
    });
}

export function FormulateNowPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateNow,
        },
        library: {
            now: {
                classification: "text",
                component: "FormulateNow",
            },
        },
    });
}

export function FormulateTimePickerPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateTimePicker,
        },
        library: {
            timepicker: {
                classification: "text",
                component: "FormulateTimePicker",
            },
        },
    });
}

export function FormulateVueSelectPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateVueSelect,
        },
        library: {
            vueselect: {
                classification: "text",
                component: "FormulateVueSelect",
            },
        },
    });
}

export function FormulateDatePickerPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateDatePicker,
        },
        library: {
            datepicker: {
                classification: "text",
                component: "FormulateDatePicker",
            },
        },
    });
}

export function FormulateToggleSwitchPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            ToggleSwitch,
        },
        library: {
            toggleswitch: {
                classification: "toggle-switch",
                component: "ToggleSwitch",
            },
        },
    });
}

export function FormulateFrasregisterPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateFrasregister,
        },
        library: {
            frasregister: {
                classification: "frasregister",
                component: "FormulateFrasregister",
                props: {
                    options: {
                        type: Array,
                        required: true,
                    },
                },
            },
        },
    });
}
