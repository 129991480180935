<template>
    <div class="FilhanteringSteg">
        <p>
            <span class="fileCounter">{{ lokalUppladdningDisplayIndex }}/{{ lokalUppladdningAntal }}</span>

            <span class="queueName">{{ queueName }}</span>
            <span class="fileName">{{ lokalUppladdningAktuellFil.file.name }}</span>
        </p>

        <ButtonGroup class="buttons">
            <Button
                variant="contour"
                size="slim"
                @click="wrapLokalUppladdningForegaendeFil"
                role="button"
                class="btn btn-outline-primary btn-sm"
                v-if="lokalUppladdningVisaForegaendeKnapp"
            >
                Föregående fil
            </Button>

            <Button
                size="slim"
                @click="wrapLokalUppladdningNastaFil"
                role="button"
                class="btn btn-primary btn-sm nextButton"
                v-if="lokalUppladdningVisaNastaKnapp"
            >
                Nästa fil
            </Button>
        </ButtonGroup>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import dialogEvents from "@/dialogEvents";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";

export default {
    name: "FilhanteringSteg",

    components: {
        ButtonGroup,
        Button,
    },

    computed: {
        ...mapGetters("filhantering", [
            "lokalUppladdningAntal",
            "lokalUppladdningDisplayIndex",
            "lokalUppladdningAktuellFil",
            "lokalUppladdningVisaNastaKnapp",
            "lokalUppladdningVisaForegaendeKnapp",
            "lokalUppladdningFormIsClean",
        ]),

        queueName() {
            // TODO
            return "Personlig kö";
        },
    },

    methods: {
        ...mapActions("filhantering", ["lokalUppladdningNastaFil", "lokalUppladdningForegaendeFil"]),

        wrapLokalUppladdningNastaFil() {
            if (!this.lokalUppladdningFormIsClean) {
                this.displayUnsavedDataWarning(this.lokalUppladdningNastaFil);
            } else {
                this.lokalUppladdningNastaFil();
            }
        },

        wrapLokalUppladdningForegaendeFil() {
            if (!this.lokalUppladdningFormIsClean) {
                this.displayUnsavedDataWarning(this.lokalUppladdningForegaendeFil);
            } else {
                this.lokalUppladdningForegaendeFil();
            }
        },

        displayUnsavedDataWarning(action) {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Du har osparad data. Vill du fortsätta?",
                buttons: [
                    {
                        title: "Nej",
                        type: "secondary",
                    },
                    {
                        title: "Ja",
                        type: "primary",
                        action,
                    },
                ],
            });
        },
    },
};
</script>

<style lang="sass" scoped>
@import "../../style/variables"
div.FilhanteringSteg
  display: flex
  flex-direction: row
  $height: 4rem
  align-items: center
  padding-left: 30px
  box-shadow: 0px 10px 10px $color-blue-light
  background-color: white
  height: $height
  line-height: $height
  margin-bottom: 30px
  p
    flex-grow: 1
    span.fileCounter
      font-weight: bold
      margin-left: 2rem

    span.queueName
      margin-left: 2rem

    span.fileName
      margin-left: 2rem

  div.buttons
    margin-right: 2rem
    button.nextButton
      margin-left: 1rem
</style>
