import { cloneDeep } from "lodash";

const getDefaultState = () => {
    return {
        journalanteckningsmall: {},
        journalanteckningsmallar: [],
        journalanteckningsmallarCount: 0,
    };
};
export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getJournalanteckningsmall: (state) => state.journalanteckningsmall,
        getJournalanteckningsmallar: (state) => state.journalanteckningsmallar,
        getJournalanteckningsmallarCount: (state) => state.journalanteckningsmallarCount,
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setJournalanteckningsmall(state, journalanteckningsmall) {
            state.journalanteckningsmall = journalanteckningsmall;
        },
        setJournalanteckningsmallar(state, journalanteckningsmallar) {
            state.journalanteckningsmallar = journalanteckningsmallar;
        },
        setJournalanteckningsmallarCount(state, journalanteckningsmallarCount) {
            state.journalanteckningsmallarCount = journalanteckningsmallarCount;
        },
    },
    actions: {
        setJournalanteckningsmall({ commit }, journalanteckningsmall) {
            commit("setJournalanteckningsmall", journalanteckningsmall);
        },
        setJournalanteckningsmallar({ commit }, journalanteckningsmallar) {
            commit("setJournalanteckningsmallar", journalanteckningsmallar);
        },
        setJournalanteckningsmallarCount({ commit }, journalanteckningsmallarCount) {
            commit("setJournalanteckningsmallarCount", journalanteckningsmallarCount);
        },
        addJournalanteckningsmall({ commit, getters }, journalanteckningsmall) {
            let journalanteckningsmallar = cloneDeep(getters.getJournalanteckningsmallar);
            journalanteckningsmallar.push(journalanteckningsmall);
            commit("setJournalanteckningsmallar", journalanteckningsmallar);
        },
    },
};
