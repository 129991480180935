import { Extension } from "@tiptap/core";
import Suggestion from "@tiptap/suggestion";

export default Extension.create({
    name: "frasExtension",

    addOptions() {
        return {
            frasSuggestion: {
                char: "$",
                command: async ({ editor, props }) => {
                    props.command({ editor });
                },
            },
        };
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                // null tillåter att en fras kan läggas på valfri plats i content (t.ex "text$fras") utan krav på t.ex whitespace raden innan
                allowedPrefixes: null,
                ...this.options.frasSuggestion,
            }),
        ];
    },
});