<template>
    <div :class="[status, minimized ? 'minimized' : 'frame']" v-tooltip="{ content: tooltip, placement: placement }">
        <span v-if="!minimized" :class="status + 'Text'" class="textInFrame">{{ getText(status) }}</span>
    </div>
</template>

<!--
    Lyft in StatusWidget för att visa badges för statusar.

    <status-widget status="signerad" tooltip="signerad av Bengan 2017-13-32" placement="bottom"></status-widget>

    -status: String- eg. 'Här är ett tooltip'
    -tooltip: String- eg. 'makulerad'; se listan nedan
    -placement: String- eg. 'bottom', 'left'
-->

<script>
export default {
    name: "StatusWidget",
    props: [
        "status",
        "tooltip",
        "placement",
        "minimized", // I journalanteckningar finns ett kompakt listläge där man vill visa statusen som en liten färgad ruta utan text.
    ],
    methods: {
        getText() {
            switch (this.status) {
                case "aktiv":
                    return "AKTIV";
                case "aktuell":
                    return "AKTUELL";
                case "attSignera":
                    return "ATT SIGNERA";
                case "ejForskrivningsratt":
                    return "EJ FÖRSKRIVNINGSRÄTT";
                case "klar":
                    return "KLAR";
                case "makulerad":
                    return "MAKULERAD";
                case "makuleradeRader":
                    return "MAKULERADE RADER";
                case "oppen":
                    return "ÖPPEN";
                case "oppenVard":
                    return "ÖPPEN VÅRD";
                case "osignerad":
                    return "OSIGNERAD";
                case "raderad":
                    return "RADERAD";
                case "receptblankettUtskriven":
                    return "RECEPTBLANKETT UTSKRIVEN";
                case "rehab":
                    return "REHAB";
                case "signerad":
                    return "SIGNERAD";
                case "skickad":
                    return "SKICKAD";
                case "slutenVard":
                    return "SLUTEN VÅRD";
                case "sparad":
                    return "SPARAD";
                case "utganget":
                    return "UTGÅNGET";
                case "utkast":
                    return "UTKAST";
                case "vantar":
                    return "VÄNTAR";
                case "aterkallad":
                    return "ÅTERKALLAD";
                case "giltighetstidPasserad":
                    return "GILTIGHETSTID PASSERAD";
                default:
                    return this.status.replace(/([A-Z]+)/g, " $1").toUpperCase(); //Inserts ' ' before any capital letter, and coverts to uppercase. Works for any case without 'åäö'.
            }
        },
    },
};
</script>

<style scoped>
.minimized {
    display: inline-block;
    height: 20px;
    width: 6px;
    border-radius: 2px;
}

.frame {
    height: 20px;
    padding: 0 13px 0 13px;
    border-radius: 4px;
    background-color: darkgray;
    text-align: center;
    width: fit-content;
    justify-content: space-evenly;
    line-height: 20px;
}

.textInFrame {
    color: #ffffff;
    vertical-align: middle;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    white-space: nowrap;
}

.aktuell,
.aktiv,
.klar,
.receptblankettUtskriven,
.signerad,
.skickad {
    background-color: #34ac8d;
}

.attSignera,
.giltighetstidPasserad,
.inaktivEnhet {
    background-color: #f7981c;
}

.ejForskrivningsratt,
.makulerad,
.makuleradeRader,
.inaktiv {
    background-color: #cb1339;
}

.utganget,
.vantar,
.aterkallad {
    background-color: #e86781;
}

.lastForSignering {
    background-color: #ffd1c2;
}

.oppen,
.slutenVard {
    background-color: #ded5fd;
}

.oppenVard {
    background-color: #f4c6e2;
}

.osignerad {
    background-color: #a5bbc3;
}

.raderad {
    background-color: #e86781;
}

.receptblankettUtskriven {
    background-color: #2d9367;
}

.rehab {
    background-color: #e7c98f;
}

.sparad {
    background-color: #859ba3;
}

.utkast {
    background-color: #95a1b1;
}
</style>
