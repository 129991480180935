<template>
    <form @submit.prevent="submit">
        <admin-lock-border />
        <h2 v-if="parameters">Redigera anteckningstyp</h2>
        <h2 v-else>Skapa en ny anteckningstyp</h2>
        <div class="form-group k3-form">
            <input-wrapper
                :component="widgets.TextWidget"
                label="Namn"
                name="namn"
                required="true"
                v-model="anteckningstyp.namn"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.NumberWidget"
                label="Sortering"
                name="sortering"
                required="true"
                v-model="anteckningstyp.sortering"
                @input="updateFormHasBeenEdited"
                min="0"
            />
            <input-wrapper
                :component="widgets.SelectWidget"
                label="Visa på NPÖ"
                name="visas_pa_npo"
                :options="[
                    { text: 'Ja', value: true },
                    { text: 'Nej', value: false },
                ]"
                v-model="anteckningstyp.visas_pa_npo"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.SelectWidget"
                label="NPÖ-typ"
                name="npotyp"
                :required="anteckningstyp.visas_pa_npo"
                v-model="anteckningstyp.npotyp"
                :disabled="!anteckningstyp.visas_pa_npo"
                :options="[
                    { text: 'Utredning', value: 'utr' },
                    { text: 'Åtgärd/Behandling', value: 'atb' },
                    { text: 'Sammanfattning', value: 'sam' },
                    { text: 'Samordning', value: 'sao' },
                    { text: 'Inskrivning', value: 'ins' },
                    { text: 'Slutanteckning', value: 'slu' },
                    { text: 'Anteckning utan fysiskt möte', value: 'auf' },
                    { text: 'Slutenvårdsanteckning', value: 'sva' },
                    { text: 'Besöksanteckning', value: 'bes' },
                ]"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.SelectWidget"
                label="Visa som standard på 1177"
                name="visas_pa_1177"
                :options="[
                    { text: 'Ja', value: true },
                    { text: 'Nej', value: false },
                ]"
                v-model="anteckningstyp.visas_pa_1177"
                :disabled="!anteckningstyp.visas_pa_npo"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.CheckboxWidget"
                label="Aktiv"
                name="aktiv"
                v-model="anteckningstyp.is_active"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                v-if="anteckningstyp.is_active"
                :component="widgets.CheckboxWidget"
                label="Valbar"
                name="valbar"
                v-model="anteckningstyp.is_valbar"
                @input="updateFormHasBeenEdited"
            />
        </div>
        <div class="warning-box">
            <span class="warning-icon">!</span>
            <span>
                Observera att inställningen att anteckningar ska visas på 1177 endast gäller framtida
                journalanteckningar. För att inkludera historiska journalanteckningar behöver kryssrutan "Visas på 1177"
                aktiveras för varje enskild historisk anteckning manuellt.
            </span>
        </div>
        <ButtonGroup>
            <Button size="primary" role="button" type="submit" :disabled="!formHasBeenEdited">Spara och stäng</Button>
            <Button @click="cancel()" size="secondary" variant="contour" type="button">Avbryt</Button>
        </ButtonGroup>
    </form>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import dialogEvents from "@/dialogEvents";
import tabIcon from "@/assets/receptforskrivning.svg";
import widgets from "@/components/widgets/inputs";
import { cloneDeep } from "lodash";
import { flashMessage } from "@/utils";
import { mapActions } from "vuex";
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

export default {
    extends: BaseTab,
    name: "Anteckningstyp",
    icon: tabIcon,
    tabName: "Anteckningstyp",
    components: {
        Button,
        ButtonGroup,
        AdminLockBorder,
        ...widgets,
        InputWrapper,
    },
    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            widgets: widgets,
            formHasBeenEdited: false,
            anteckningstyp: {
                id: "",
                namn: "",
                sortering: "",
                is_active: true,
                is_valbar: true,
            },
        };
    },
    computed: {
        hasUnsavedData() {
            return this.formHasBeenEdited;
        },
    },
    methods: {
        ...mapActions("anteckningstyp", ["save", "update", "load"]),
        updateFormHasBeenEdited() {
            this.formHasBeenEdited = true;
        },
        async submit() {
            let response, msg, status;

            if (this.anteckningstyp.pk) {
                this.anteckningstyp.is_active = Boolean(this.anteckningstyp.is_active);
                response = await this.update({ anteckningstyp: this.anteckningstyp });
                msg = "Typen har uppdaterats";
                status = 200;
            } else {
                this.anteckningstyp.is_active = Boolean(this.anteckningstyp.is_active);
                response = await this.save({ anteckningstyp: this.anteckningstyp });
                msg = "Typen har sparats";
                status = 201;
            }

            if (!response) return;
            if (response.status === status) {
                await this.$store.dispatch("anteckningstyp/load");
                await this.$store.dispatch("tabs/closeTab", this.tabId);
                flashMessage(msg);
            }
        },
        cancel() {
            this.closeTab(this.hasUnsavedData);
        },
    },
    async created() {
        if (this.parameters) this.anteckningstyp = cloneDeep(this.parameters);
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/deprecated_main";

.warning-box {
    box-sizing: border-box;
    border: 1px solid #efc2d4;
    border-radius: 3px;
    background-color: #f7e1e9;
    color: #354052;
    display: flex;
    padding: 20px;
    margin-bottom: 30px;
    max-width: 720px;

    .warning-icon {
        padding-left: 5px;
        padding-top: 2px;
        color: #fff;
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        height: 14px;
        min-width: 14px;
        border-radius: 7px;
        background-color: #354052;
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 10px;
    }
}
</style>
