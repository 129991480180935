export function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export function mimeToFormat(mime) {
    const translate = {
        "image/png": "PNG",
        "image/jpeg": "JPG",
        "image/tiff": "TIFF",
        "image/gif": "GIF",
        "image/bmp": "BMP",
        "audio/wav": "WAV",
        "audio/mpeg": "MP3",
        "video/mp4": "MP4",
        "application/pdf": "PDF",
    };

    let result = translate[mime];
    if (result === undefined) {
        result = "Okänt format";
    }

    return result;
}

export function log(msg) {
    //eslint-disable-next-line
    console.log(msg);
}
