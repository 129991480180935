import emitter from "tiny-emitter/instance";

/**
 * Displays a flash message with the provided description and optional timeout.
 * If a timeout is specified, the flash message will automatically close after the specified duration.
 * If no timeout is provided (or set to 1500), the flash message will remain open until closed manually.
 *
 * @param {string} description - The description text for the flash message.
 * @param {number} [timeout=1500] - Optional. The timeout value in milliseconds before the flash message is automatically closed.
 *                                  If set to 1500, the flash message will not close automatically.
 * @returns {void}
 */
const flashMessage = (description, timeout = 1500) => {
    emitter.emit("open-dialog", {
        type: "flash",
        description,
        timeout,
    });
};

/**
 * Displays a flash warning message with the provided description and optional timeout.
 * If a timeout is specified, the flash warning message will automatically close after the specified duration.
 * If no timeout is provided (or set to 1500), the flash warning message will remain open until closed manually.
 *
 * @param {string} description - The description text for the flash warning message.
 * @param {number} [timeout=1500] - Optional. The timeout value in milliseconds before the flash warning message is automatically closed.
 *                                  If set to 1500, the flash warning message will not close automatically.
 * @returns {void}
 */
const flashWarning = (description, timeout = 1500) => {
    emitter.emit("open-dialog", {
        type: "flashwarning",
        description,
        timeout,
    });
};

const openDialog = (options, type = "default") => {
    if (typeof options === "object") {
        emitter.emit("open-dialog", options);
    } else {
        emitter.emit("open-dialog", {
            type,
            description: options,
        });
    }
};

export { flashMessage, flashWarning, openDialog };
