<template>
    <form @submit.prevent class="form-active" id="recipe-details">
        <div class="form-group k3-form">
            <!-- eslint-disable-next-line -->
            <input-wrapper
                :class="'colspan  c' + (formComponent.colspan ? Math.min(formComponent.colspan, colspanMax) : '2')"
                v-for="(formComponent, index) in formComponents"
                :key="'wrapper-' + index"
                :component="formComponent.component"
                v-bind="formComponent.parameters"
                v-on="formComponent.events"
                :label="formComponent.label"
            />
        </div>
        <ButtonGroup class="form-group">
            <Button v-if="data.pk || data.tempId" @click="submitOrder"> Spara beställning </Button>
            <Button v-else @click="submitOrder">
                <img src="@/assets/plus_circle_white.svg" />Lägg till i {{ orderButtonText }}
            </Button>
            <Button variant="contour" size="secondary" @click="$emit('cancel-order')">Avbryt</Button>
        </ButtonGroup>
    </form>
</template>
<script>
//import $ from "jquery";
import linkEvents from "@/linkEvents";
import InputWrapper from "@/components//widgets/InputWrapper.vue";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

const DEFAULT_VALUES = {
    pro: false,
    // eHm fält EBY betyder "Får ej bytas mot generika", men FE visar ""Får bytas"
    eby: false,
    languageOfLabel: { id: "sv", code: "sv", displayName: "svenska" },
};

export default {
    components: {
        InputWrapper,
        Button,
        ButtonGroup,
    },
    props: ["data", "requiredfields", "formcomponents", "favorit"],
    data() {
        return {
            formComponents: this.formcomponents,
        };
    },
    computed: {
        values() {
            return { ...DEFAULT_VALUES, ...this.data };
        },
        colspanMax() {
            return 8;
        },
        orderButtonText() {
            return this.favorit ? "favorit" : "beställning";
        },
    },
    methods: {
        submitOrder() {
            let validated = this.validateForm("recipe-details");
            if (!validated) return;
            else this.$emit("submit-order");
        },
        validateForm(formName) {
            var form = document.getElementById(formName);
            if (form.checkValidity() === true) return true;
            else {
                var list = form.querySelectorAll(":invalid");
                for (var item of list) {
                    item.focus();
                }
                form.querySelector(":invalid").focus();
            }
            return false;
        },
        isRequired(name) {
            if (this.requiredfields.indexOf(name) !== -1) return true;
            else return false;
        },
        getComponentByName(name) {
            for (const formComponent of this.formComponents)
                if (formComponent.parameters.name === name) return formComponent;
        },
        timeValueChanged(value) {
            linkEvents.$emit("update_timeUnit", {
                required: value === "" ? false : true,
            });
        },
        timeUnitChanged(value) {
            let min = 1;
            let max = 365;
            switch (value) {
                case "V":
                    max = 52;
                    break;
                case "Mn":
                    max = 12;
                    break;
            }
            linkEvents.$emit("update_timeValue", {
                required: value !== "",
                min: min,
                max: max,
            });
        },
    },
    /**
     * Updates formComponents with values and required fields. Must be run before mounted() hook.
     */
    created() {
        // TODO: This needs to be moved to a separate component, if the test suite is going to work.
        this.formComponents.forEach((formComponent) => {
            formComponent.parameters.required = this.isRequired(formComponent.parameters.name);
            formComponent.parameters.value = this.values[formComponent.parameters.name];
        }, this);

        /**
         * Change allowed values for controlled drugs. TODO: Maybe move to component logic?
         */
        if (this.data.drugArticle.controlledDrugFlag === "Y") {
            let formComponent = this.getComponentByName("pro");
            formComponent.parameters.options = [{ text: "Nej", value: false }];
        } else {
            let formComponent = this.getComponentByName("pro");
            formComponent.parameters.options = [
                { text: "Ja", value: true },
                { text: "Nej", value: false },
            ];
        }
        linkEvents.$on("timeValue_changed", this.timeValueChanged);
        linkEvents.$on("timeUnit_changed", this.timeUnitChanged);
    },
    mounted() {
        if (this.data.drugArticle.limitedReimbursement !== null) {
            linkEvents.$emit("unstructuredDosageAdmin_warning", {
                header: "Observera att varan är förmånsbegränsad. Ange i doseringsanvisningen om förmånen uppfylls eller ej.",
                message: this.data.drugArticle.limitedReimbursement,
            });
        } else if (this.data.drugArticle.packageText === "Licensförpackning") {
            linkEvents.$emit("unstructuredDosageAdmin_warning", {
                header: "Information om förpackningsstorlek saknas. Dosering och behandlingstid anges i doseringsanvisningar.",
                message:
                    "För licensförpackningar där förpackningsstorlek saknas: Ange tydligt dosering och behandlingstid här under doseringsanvisning. Önskar du uppdelning på fler antal uttag bör även det förtydligas under doseringsanvisning. Under antal förpackningar anger du antal 1.",
            });
        }
    },
    beforeDestroy() {
        linkEvents.$off("timeValue_changed", this.timeValueChanged);
        linkEvents.$off("timeUnit_changed", this.timeUnitChanged);
    },
};
</script>
<style lang="scss" scoped>
@import "@/style/deprecated_main";
</style>
