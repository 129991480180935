<!-- :label-actions="favouriteLabelActions" -->
<template>
    <header class="pad">
        <div class="recipe-header">
            <h2>Receptfavoriter</h2>
        </div>
        <button role="button" @click="openNyFavorit" class="hollow-button">
            <img src="@/assets/plus_circle_blue.svg" />Skapa ny favoritsamling
        </button>
        <ReceptFavoriter />
    </header>
</template>
<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import tabIcon from "@/assets/receptforskrivning.svg";
import Favorit from "./Favorit.vue";
import ReceptFavoriter from "./ReceptFavoriter.vue";

export default {
    extends: BaseTab,
    name: "ListaFavoriter",
    tabName: "Receptfavoriter",
    icon: tabIcon,
    patientBound: false,
    components: {
        ReceptFavoriter,
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        openNyFavorit() {
            this.$store.dispatch("tabs/openTab", { component: Favorit });
        },
    },
};
</script>
<style lang="scss" scoped>
.pad {
    .hollow-button {
        margin-top: 8px;
        height: 28px;
        color: #6c8188;
        font-family: Roboto Medium;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        border: none;
        background-color: #ffffff;
    }

    img {
        margin-right: 8px;
    }
}
</style>
