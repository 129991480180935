<template>
    <form @submit.prevent="submit.prevent" id="op-registration-details">
        <h2>{{ editingExistingAnmalan ? "Redigera" : "Skapa" }} operationsanmälan</h2>
        <div :class="'form-group k3-form ' + (colspanMax === 8 ? 'fullscreen' : '')">
            <div class="colspan whole"><h3>Tidsplaneringsdata</h3></div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutSuggestWidget"
                label="Operationsenhet"
                name="operationsenhet"
                v-model="formData.operationsenhet"
                apisearch="/core/orgunits/"
                :extraParams="{ vardenhetstyp: 'operationsenhet', is_active: true, only_permitted: true }"
                @input="operationsenhetChanged($event)"
                :map="
                    (el) => {
                        return {
                            id: el.id,
                            code: el.id,
                            displayName: el.name,
                        };
                    }
                "
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutSuggestWidget"
                label="Huvudoperatör"
                name="operator"
                v-model="formData.huvudoperator"
                apisearch="/core/profiles/"
                :extraParams="{ operator: true, orgUnit: formData.operationsenhet, is_active: true }"
                :map="
                    (el) => {
                        return {
                            id: el.pk,
                            code: el.healthCareProviderHsaId,
                            displayName: el.user?.name,
                        };
                    }
                "
                required="true"
                :disabled="!hasOperationsenhet"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                label="Assisterande operatörer"
                name="ass_operatorer"
                v-model="formData.ass_operatorer"
                apisearch="/core/profiles/"
                :extraParams="{ operator: true, is_active: true }"
                :map="
                    (el) => {
                        return {
                            id: el.pk,
                            code: el.healthCareProviderHsaId,
                            displayName: el.user.name,
                        };
                    }
                "
                display-name="ass operatör"
                colspan="4"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.ReadOnlyTextWidget"
                label="Skapad av"
                name="created_by"
                :value="createdBy"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Planerad vårdform"
                required="true"
                name="planerad_vardform"
                v-model="formData.planerad_vardform"
                @input="planeradVardformChanged($event)"
                :options="[
                    { text: 'Sluten vård', value: 'SLUTENVÅRD' },
                    { text: 'Öppen vård', value: 'ÖPPENVÅRD' },
                ]"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Prioritet"
                required="true"
                name="prio"
                v-model="formData.prio"
                apisearch="/operation/kodverk/prioriteter/"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.CheckboxWidget"
                :show-empty-label="true"
                checkbox-label="Kort varsel"
                name="kort_varsel"
                v-model="formData.kort_varsel"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Avtal"
                name="avtal"
                v-model="formData.avtal"
                :options="avtalOptions"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Kontaktorsak"
                name="kontaktorsak"
                v-model="formData.kontaktorsak"
                apisearch="/operation/kodverk/kontaktorsaker/"
                :map="
                    (item) => {
                        return { value: item.id, text: item.displayName };
                    }
                "
            />
            <template v-if="mode === 'opcoordinator'">
                <input-wrapper
                    :preview="mode === 'preview'"
                    :component="widgets.SelectWidget"
                    label="Prel debiteringskod"
                    name="prel_debit_kod"
                    v-model="formData.prel_debit_kod"
                />
                <input-wrapper
                    :preview="mode === 'preview'"
                    :component="widgets.NumberWidget"
                    label="Pris"
                    name="manuellt_pris"
                    v-model="formData.manuellt_pris"
                    :min="0"
                    :step="0.01"
                />
                <input-wrapper
                    :preview="mode === 'preview'"
                    :component="widgets.TextAreaWidget"
                    label="Kommentar till pris"
                    :colspan="4"
                    :maxlength="1048"
                    name="manuellt_pris_kommentar"
                    v-model="formData.manuellt_pris_kommentar"
                />
            </template>

            <div class="colspan whole"><h3>Operationsdata</h3></div>

            <div class="colspan whole">
                <typoperation-select-widget
                    @setTypoperation="setTypoperation"
                    @purgeTypoperation="purgeTypoperation"
                    :preselectedTypoperation="preselectedTypoperation"
                />
            </div>

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutSuggestWidget"
                label="Huvuddiagnos"
                name="huvuddiagnos"
                @input="huvuddiagnosChanged"
                v-model="formData.huvuddiagnos"
                apisearch="/core/kodverk/icdkoder/"
                colspan="6"
                :displayCode="true"
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                label="Bidiagnoser"
                name="bidiagnoser"
                display-name="bidiagnos"
                v-model="formData.bidiagnoser"
                apisearch="/core/kodverk/icdkoder/"
                colspan="8"
                :displayCode="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutSuggestWidget"
                label="Huvudoperationskod"
                name="huvudoperationskod"
                @input="huvudopkodChanged"
                v-model="formData.huvudoperationskod"
                apisearch="/operation/kodverk/operationskoder/"
                colspan="6"
                :displayCode="true"
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                label="Bioperationskoder"
                name="bioperationskoder"
                display-name="bioperationskod"
                v-model="formData.bioperationskoder"
                apisearch="/operation/kodverk/operationskoder/"
                colspan="8"
            />

            <div class="colspan whole"></div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SimpleSuggestWidget"
                label="Nivå från"
                name="niva_fran"
                v-model="formData.niva_fran"
                :options="ryggkotor"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SimpleSuggestWidget"
                label="Nivå till"
                name="niva_till"
                v-model="formData.niva_till"
                :options="ryggkotor"
            />
            <input-wrapper
                id="nivaer"
                :preview="mode === 'preview'"
                :component="widgets.ReadOnlyTextWidget"
                label="Nivåer"
                name="nivaer"
                :value="`${nivaer}`"
                :error="nivaerError"
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                label="Resurser"
                name="resurser"
                display-name="resurs"
                v-model="formData.resurser"
                :map="
                    (item) => {
                        return {
                            id: item.id,
                            code: item.id,
                            displayName: `${item.namn} (Totalt ${item.totalt_antal} st)`,
                        };
                    }
                "
                apisearch="/operation/resurser/"
                colspan="4"
            />

            <div class="colspan whole"></div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Förberedelsetid (min)"
                name="forberedelser"
                v-model="formData.forberedelser"
                :min="1"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Operationstid (min)"
                name="optid"
                v-model="formData.optid"
                :min="1"
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Avvecklingstid (min)"
                name="avveckling"
                v-model="formData.avveckling"
                :min="1"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                label="Implantat"
                name="implantat"
                colspan="8"
                v-model="formData.implantat"
                apisearch="/operation/implantat/"
                :map="
                    (item) => {
                        return {
                            id: item.id,
                            code: item.id,
                            displayName: item.namn,
                        };
                    }
                "
            />
            <template v-if="mode === 'opcoordinator'">
                <input-wrapper
                    :preview="mode === 'preview'"
                    :component="widgets.SelectWidget"
                    label="Patientläge"
                    name="oplage"
                    v-model="formData.oplage"
                    :apisearch="
                        formData.oplage
                            ? '/operation/kodverk/oplagen/?include=' + formData.oplage
                            : '/operation/kodverk/oplagen/'
                    "
                    :extraParams="{ include: formData.oplage }"
                    :map="
                        (item) => {
                            return {
                                value: item.id,
                                text: item.displayName,
                                enabled: item.enabled,
                            };
                        }
                    "
                    required="true"
                />
            </template>
            <template v-else>
                <input-wrapper
                    :preview="mode === 'preview'"
                    :component="widgets.SelectWidget"
                    label="Patientläge"
                    name="oplage"
                    v-model="formData.oplage"
                    apisearch="/operation/kodverk/oplagen/"
                    :map="
                        (item) => {
                            return {
                                value: item.id,
                                text: item.displayName,
                                enabled: item.enabled,
                            };
                        }
                    "
                    required="true"
                />
            </template>

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Opsida"
                name="lateralitet"
                v-model="formData.lateralitet"
                apisearch="/operation/kodverk/lateralitet/"
                required="true"
                :map="
                    (item) => {
                        return { value: item.code, text: item.displayName };
                    }
                "
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Anestesi"
                name="anestesi"
                v-model="formData.anestesi"
                @update="anestesiUpdated"
                apisearch="/operation/kodverk/anestesier/"
                required="true"
                :map="
                    (item) => {
                        return { value: item.id, text: item.displayName };
                    }
                "
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Blockad"
                name="blockad"
                v-model="formData.blockad"
                apisearch="/operation/kodverk/blockader/"
                :disabled="!anestesiIsBlockad"
                :map="
                    (item) => {
                        return { value: item.id, text: item.displayName };
                    }
                "
                :required="anestesiIsBlockad"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Preanestesibedöming via"
                name="anestesibedomning_via"
                v-model="formData.anestesibedomning_via"
                apisearch="/operation/kodverk/anestesibedomningar/"
                :map="
                    (item) => {
                        return { value: `${item.id}`, text: item.displayName };
                    }
                "
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.CheckboxWidget"
                :show-empty-label="true"
                checkbox-label="Garantioperation"
                name="garantiop"
                v-model="formData.garantiop"
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.CheckboxWidget"
                :show-empty-label="true"
                checkbox-label="Reoperation"
                name="reop"
                v-model="formData.reop"
            />

            <div class="colspan whole"><h3>Patientdata</h3></div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Rökare"
                name="rokare"
                @input="rokareChanged($event)"
                v-model="formData.rokare"
                :options="[
                    { text: 'Ja', value: 'JA' },
                    { text: 'Nej', value: 'NEJ' },
                    { text: 'Okänt', value: 'OKÄNT' },
                ]"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Rökavvänjning har erbjudits"
                :disabled="!isRokare"
                name="rokavvanjning"
                v-model="formData.rokavvanjning"
                :options="[
                    { text: 'Ja', value: 'JA' },
                    { text: 'Nej', value: 'NEJ' },
                    { text: 'Okänt', value: 'OKÄNT' },
                ]"
                :required="formData.rokare === 'JA'"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Tolk"
                name="tolk"
                v-model="formData.tolk"
                @input="tolkChanged($event)"
                :options="[
                    { text: 'Ja', value: 'JA' },
                    { text: 'Nej', value: 'NEJ' },
                ]"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutSuggestWidget"
                label="Språk"
                name="sprak"
                :disabled="!hasTolk"
                v-model="formData.sprak"
                apisearch="/core/kodverk/iso_639_1/"
                :map="
                    (item) => {
                        return { id: item.code, code: item.code, displayName: item.displayName };
                    }
                "
                :required="formData.tolk === 'JA'"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Längd (cm)"
                name="langd"
                v-model="formData.langd"
                :min="30"
                :max="240"
                :step="0.1"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Vikt (kg)"
                name="vikt"
                v-model="formData.vikt"
                :min="0.5"
                :max="350"
                :step="0.1"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.ReadOnlyTextWidget"
                label="BMI"
                name="bmi"
                :value="bmi"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Invägning"
                name="invagning"
                v-model="formData.invagning"
                :options="[
                    { text: 'Ja', value: true },
                    { text: 'Nej', value: false },
                ]"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Önskad viktminskning (kg)"
                name="viktminskning"
                v-model="formData.viktminskning"
                :min="0.1"
                :step="0.1"
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Diabetes"
                name="diabetes"
                v-model="formData.diabetes"
                :options="[
                    { text: '', value: null },
                    { text: 'Ja', value: true },
                    { text: 'Nej', value: false },
                ]"
                @input="diabetesChanged($event)"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Behandlingsform för Diabetes"
                :disabled="!formData.diabetes"
                name="diabetes_lakemedelsform"
                v-model="formData.diabetes_lakemedelsform"
                :required="formData.diabetes === true"
                apisearch="/operation/diabetesbehandlingsform/"
                :map="
                    (item) => {
                        return { value: item.id, text: item.namn };
                    }
                "
            />

            <div class="colspan whole">
                <h3>Operationsförberedande ordinationer</h3>
            </div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Trombosprofylax"
                name="trombosprofylax"
                v-model="formData.trombosprofylax"
                apisearch="/operation/kodverk/trombosprofylaxer/"
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Antibiotikaprofylax"
                name="antibiotikaprofylax"
                v-model="formData.antibiotikaprofylax"
                apisearch="/operation/kodverk/antibiotikaprofylaxer/"
                required="true"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Antikoagulantia sedan tidigare"
                name="antikoagulantia"
                v-model="formData.antikoagulantia"
                @input="antikoagulantiaChanged($event)"
                :options="[
                    { text: 'Ja', value: 'JA' },
                    { text: 'Nej', value: 'NEJ' },
                    { text: 'Okänt', value: 'OKÄNT' },
                ]"
                required="true"
                v-tooltip="{
                    content: 'Ange om patienten står på Antikoagulantia sedan tidigare',
                    theme: 'kliniken-tooltip',
                    placement: 'bottom',
                }"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.NumberWidget"
                label="Sätts ut dagar före op"
                name="satts_ut_dagar_innan"
                :disabled="formData.antikoagulantia !== 'JA'"
                v-model="formData.satts_ut_dagar_innan"
                :min="1"
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Röntgenbilder till op"
                name="rontgenbilder_till_op"
                v-model="formData.rontgenbilder_till_op"
                apisearch="/operation/kodverk/rontgen/"
                :map="
                    (item) => {
                        return { value: item.id, text: item.displayName };
                    }
                "
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Ny röntgen inför op"
                name="ny_rontgen_infor_op"
                v-model="formData.ny_rontgen_infor_op"
                :options="[
                    { text: 'Beställd', value: 'BESTALLD' },
                    { text: 'Behövs ej', value: 'BEHOVS_EJ' },
                ]"
                :map="
                    (item) => {
                        return { value: item.code, text: item.displayName };
                    }
                "
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Blodgruppering"
                name="blodgruppering"
                v-model="formData.blodgruppering"
                :options="[
                    { text: 'Ordineras', value: 'ORDINERAS' },
                    { text: 'Behövs ej', value: 'BEHOVS_EJ' },
                ]"
                :map="
                    (item) => {
                        return { value: item.code, text: item.displayName };
                    }
                "
            />
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Bastest"
                name="bastest"
                v-model="formData.bastest"
                :options="[
                    { text: 'Ordineras', value: 'ORDINERAS' },
                    { text: 'Behövs ej', value: 'BEHOVS_EJ' },
                ]"
                :map="
                    (item) => {
                        return { value: item.code, text: item.displayName };
                    }
                "
            />

            <div class="colspan whole"><h3>Postoperativ information</h3></div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.PopoutExpandableSuggestWidget"
                displayName="förband"
                label="Förband"
                name="forband"
                v-model="formData.forband"
                apisearch="/operation/kodverk/forband/"
                colspan="4"
            />

            <div class="colspan whole"><h3>Övrig information</h3></div>
            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.TextAreaWidget"
                label="Info till operationskoordinator"
                colspan="4"
                :maxlength="1048"
                name="info_till_opplaneringen"
                v-model="formData.info_till_opplaneringen"
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.TextAreaWidget"
                label="Info till operation"
                colspan="4"
                :maxlength="1048"
                name="operationsinformation"
                v-model="formData.operationsinformation"
            />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.TextAreaWidget"
                label="Info till vårdavdelningen"
                colspan="4"
                :maxlength="1048"
                name="info_till_vardavdelningen"
                v-model="formData.info_till_vardavdelningen"
            />
            <br />

            <div class="colspan c4" />

            <input-wrapper
                :preview="mode === 'preview'"
                :component="widgets.SelectWidget"
                label="Status på operationsanmälan"
                :disabled="formData.status === 'Bokad' || !this.isEditing"
                name="status"
                v-model="formData.status"
                :options="statusar"
                colspan="4"
            />
        </div>

        <div class="btn-group form-group">
            <button class="btn btn-primary" role="button" type="button" @mousedown="save">
                {{ editingExistingAnmalan ? "Uppdatera operationsanmälan" : "Skicka opanmälan till opkoordinator" }}
            </button>
            <button class="btn btn-secondary" type="button" @click="close">Avbryt</button>
        </div>
    </form>
</template>
<script lang="js">
    import BaseTab from "@/tabs/mixins/BaseTab";
    import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
    import TypoperationSelectWidget from "@/tabs/Operation/TypoperationSelectWidget.vue";
    import { cloneDeep, isEqual } from "lodash";
    import tabIcon from "@/assets/operationsanmalan.svg";
    import widgets from "@/components/widgets/inputs";
    import { getConfig, getErrorMessage, klinikenApi } from "@/api";
    import { mapActions, mapGetters } from "vuex";
    import { openDialog } from "@/utils";
    import { VTooltip } from "floating-vue";
    import { changeTextToDisplayName, RYGGKOTOR, statusar, normalizeData } from "./utils";


    export default {
            extends: BaseTab,
            name: "Operationsanmalan",
            tabName: "Operationsanmälan",
            icon: tabIcon,
            patientBound: true,
            props: {
                uid: Number,
                tabId: Number,
                parameters: Object,
                editing: Boolean,
            },
            components: {
                InputWrapper,
                TypoperationSelectWidget,
                VTooltip,
            },
            data() {
                return {
                    currentTypOperation: null,
                    clientWidth: 0,
                    widgets: widgets,
                    defaultFormData: {
                        status: "",
                        newStatus: [],
                    },
                    formData: {
                        status: "",
                        newStatus: [],
                    },
                    formDataObject: {},
                    id: this.parameters?.id ?? null,
                    mode: this.parameters?.mode ?? null,
                    beskrivningManuallyChanged: false,
                    displayPurgeTypoperation: false,
                    preselectedTypoperation: null,
                    isEditing: false,
                    statusar: statusar
                            .filter(i => i.value !== "Preanestesibedömning" && i.value !== "Preanestesibedömning klar")
                            .map(i => ({
                                value: i.value,
                                text: i.label,
                                icon: i.icon,
                                disabled: i.disabled,
                            })),
                    avtal: [],
                    anestesier: [],
                    ryggkotor: changeTextToDisplayName(RYGGKOTOR),
                    nivaerError: "",
                };
            },
            computed: {
                ...mapGetters("userData", ["fullName"]),
                ...mapGetters("ability", ["can"]),

                hasUnsavedData() {
                    return !isEqual(this.defaultFormData, this.formData);
                },

                colspanMax() {
                    if (this.clientWidth < 650) return 4;
                    else if (this.clientWidth < 900) return 6;
                    else return 8;
                },
                planeradVardformIsOppenvard() {
                    return this.formData.planerad_vardform === "ÖPPENVÅRD";
                },
                isRokare() {
                    return this.formData.rokare === "JA";
                },
                hasTolk() {
                    return this.formData.tolk === "JA";
                },
                hasAntikoagulantia() {
                    return this.formData.antikoagulantia === "JA";
                },
                bmi() {
                    if (this.formData.langd && this.formData.vikt) {
                        return Number.parseFloat(this.formData.vikt / Math.pow(this.formData.langd / 100, 2))
                                .toFixed(1)
                                .toString()
                                .replace(".", ",");
                    }
                    return "-";
                },
                // "Varna om “Inläggning antal dagar före” är lika med “Antal dagar på avdelning”. Förhindra om antal dagar före är större.""
                inlaggningDagarForeEqualsDagarPaAvdelning() {
                    if (
                            this.formData.inlaggning_antal_dagar_fore &&
                            this.formData.inlaggning_antal_dagar_fore === this.formData.antal_dagar_pa_avdelning
                    ) {
                        return "Antal dagar på avdelning är samma som antal dagar före inläggning";
                    } else {
                        return "";
                    }
                },

                editingExistingAnmalan() {
                    return Boolean(this.id);
                },

                avtalOptions() {
                    return this.avtal.map(({ id, namn }) => ({ value: id, text: namn }));
                },

                hasOperationsenhet() {
                    return this.formData.operationsenhet !== "";
                },

                anestesiIsBlockad() {
                    const anestesi = this.anestesier.find(anestesi => anestesi.id === this.formData?.anestesi);
                    return anestesi && anestesi.is_blockad;
                },

                nivaer() {
                    this.nivaerError = "";
                    if (this.formData.niva_fran && this.formData.niva_till) {
                        return this.formData.niva_till - this.formData.niva_fran;
                    }
                    return "-";
                },

                createdBy() {
                    if(this.isEditing === true) {
                        return this.formData.created_by_display?.user_display?.name ?? ""
                    } else {
                        return this.fullName ?? "";
                    }
                }
            },

            methods: {
                ...mapActions("operation/anmalan", ["resetStatusar"]),
                // Nollställer disablade fält. Oklart om detta behövs, men kan ev påverka BE-validering
                planeradVardformChanged(value) {
                    if (value === "ÖPPENVÅRD") {
                        delete this.formData.antal_dagar_pa_avdelning;
                        delete this.formData.inlaggning_antal_dagar_fore;
                    }
                },

                operationsenhetChanged(value) {
                    if (value === "") {
                        this.formData.huvudoperator = [{ code: "", id: "", displayName: "" }];
                    }
                },

                rokareChanged(value) {
                    if (value !== "JA") delete this.formData.rokavvanjning;
                },
                tolkChanged(value) {
                    if (value !== "JA") delete this.formData.sprak;
                },
                antikoagulantiaChanged(value) {
                    if (value !== "JA") delete this.formData.satts_ut_dagar_innan;
                },
                diabetesChanged(value) {
                    if (!value) delete this.formData.diabetes_lakemedelsform;
                },

                purgeTypoperation() {
                    this.formData.typoperation = null;

                    let purge = {
                        namn: null,
                        beskrivning: "",
                        lateralitet: null,
                        optid: null,
                        forberedelser: null,
                        avveckling: null,
                        huvuddiagnos: { code: "", id: "", displayName: "" },
                        huvudoperationskod: { code: "", id: "", displayName: "" },
                        oplage: null,
                        anestesi: null,
                        blockad: null,
                        anestesibedomning_via: null,
                        bidiagnoser: [{ code: "", id: "", displayName: "" }],
                        bioperationskoder: [{ code: "", id: "", displayName: "" }],
                        niva_fran: null,
                        niva_till: null,
                        resurser: [{ code: "", id: "", displayName: "" }],
                        opkoder: [{ code: "", id: "", displayName: "" }],
                        lateralitet_display: null,
                    };

                    this.currentTypOperation = purge;
                    this.formData = { ...this.formData, ...purge };
                    this.displayPurgeTypoperation = false;
                },

                async setTypoperation({ id }) {
                    let data;
                    const config = getConfig();
                    await klinikenApi
                            .get("/operation/typoperationer/" + id + "/", config)
                            .then((response) => {
                                data = response.data;
                                this.currentTypOperation = data;
                            })
                            .catch((e) => {
                                openDialog("Kunde inte uppdatera typoperationer från servern. " + getErrorMessage(e), "error");
                            });
                    delete data.id; // Typoperation has an id, prevent it from overwriting formData.id;
                    this.formData = { ...this.formData, ...data };
                    this.formData.typoperation = id;
                    this.beskrivningManuallyChanged = true;
                },

                huvudopkodChanged(value) {
                    if (!this.currentTypOperation) return;
                    if (value !== this.currentTypOperation.huvudoperationskod) this.formData.typoperation = null;
                },

                huvuddiagnosChanged(value) {
                    if (!this.currentTypOperation) {
                        return;
                    }

                    if (value !== this.currentTypOperation.huvuddiagnos) {
                        this.formData.typoperation = null;
                    }
                },

                anestesiUpdated() {
                    if (!this.anestesiIsBlockad) this.formData.blockad = null;
                },

                validateNivaer() {
                    this.nivaerError = "";
                    if (this.formData.niva_fran && this.formData.niva_till) {
                        if (this.formData.niva_till - this.formData.niva_fran < 0) {
                            this.nivaerError = "Värdet för nivåer kan inte vara negativt.";
                            return false;
                        }
                    }
                    return true;
                },

            validateForm(formName) {
                var form = document.getElementById(formName);
                if (form.checkValidity() === true && this.validateNivaer()) return true;
                else {
                    var list = form.querySelectorAll(":invalid");
                    for (var item of list) {
                        item.focus();
                    }
                    const field = form.querySelector(":invalid");
                    if (field) {
                        field.focus();
                    } else {
                        document.getElementById("nivaer").scrollIntoView({ behavior: "instant", block: "center" });
                    }
                }
                return false;
            },
            close() {
                this.closeTab(this.hasUnsavedData);
            },
            async save() {
                if (!this.can({ action: "create", subject: "anmalan" })) return;
                if (!this.validateForm("op-registration-details")) return;

                    let cleanFormData = cloneDeep(this.formData);

                    // Backend doesn't accept "" as an empty value, so we need to remove it. It _does_ accept empty arrays, but removing those too for cleaner props.
                    // TODO: this should probably be done in store, but this particular component doesn't have a store binding yet.
                    for (var key in cleanFormData) {
                        // Should allow null values
                        if (/* cleanFormData[key] === null || */ cleanFormData[key] === undefined || cleanFormData[key] === "")
                            delete cleanFormData[key];
                        else if (Array.isArray(cleanFormData[key])) {
                            cleanFormData[key] = cleanFormData[key].filter((el) => {
                                return el !== "";
                            });
                            if (cleanFormData[key].length === 0) {
                                delete cleanFormData[key];
                            }
                        }
                    }

                    try {
                        await this.$store.dispatch("operation/operationsanmalan/save", cleanFormData);
                        await this.$store.dispatch("tabs/closeTab", this.tabId);
                    } catch (e) {
                        openDialog(getErrorMessage(e), "error");
                    }

                },

                async loadOpAnmalan(pk) {
                    const config = getConfig({}, "Operationsanmälan");
                    return klinikenApi
                            .get("/operation/anmalan/" + pk + "/", config)
                            .then((response) => {
                                const normalizedData = normalizeData(response.data);
                                this.formData = normalizedData;
                                this.defaultFormData = cloneDeep(normalizedData);

                        if (response.data.typoperation) {
                            const typoperationId = response.data.typoperation;
                            klinikenApi
                                .get("/operation/typoperationer/" + typoperationId + "/", config)
                                .then((response) => {
                                    this.preselectedTypoperation = {
                                        id: response.data.id,
                                        displayName: response.data.namn,
                                    };
                                })
                                .catch((e) => {
                                });
                        }

                        if (response.data.huvudoperator_display) {
                            this.formData.operationsenhet = response.data.huvudoperator_display.orgUnit;
                        }

                    })
                    .catch((e) => {
                        openDialog(
                            "Kunde inte uppdatera operationsregistreringar från servern. " + getErrorMessage(e),
                            "warning",
                        );
                    });
            },
        },
        async created() {
            if (!this.can({ action: "read", subject: "anmalan" })) {
                await this.$store.dispatch("tabs/closeTab", this.tabId);
                this.close();
                return;
            }
            const avtalResponse = await klinikenApi.get(`core/avtal/`, getConfig());
            this.avtal = avtalResponse.data;

                const anestesierResponse = await klinikenApi.get("operation/kodverk/anestesier/", getConfig());
                this.anestesier = anestesierResponse.data?.results || [];

                if (this.id) {
                    this.loadOpAnmalan(this.id);
                    this.isEditing = true;
                } else {
                    this.isEditing = false;
                    this.formData.status = "Att hantera";
                }

                this.defaultFormData = cloneDeep(this.formData);
            },
        };
</script>

<style lang="scss" scoped>
    @import "@/style/variables";
    @import "@/style/vendors/bootstrap";
    @import "@/style/deprecated_main";

    #op-registration-details {
        position: relative;
        max-width: 1100px;
    }

    h2 {
        padding-left: 10px;
    }

    .form-group-border {
        border-top: 1px solid #6fb8d2;
        border-bottom: 1px solid #6fb8d2;
        padding-top: 10px;
    }

    .form-group-first {
        border-left: 1px solid #6fb8d2;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .form-group-last {
        border-right: 1px solid #6fb8d2;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .typoperationer-info-image {
        margin-right: 2px;
        margin-left: 6px;
        margin-top: -6px;
        opacity: 0.65;
    }

    .formulate-input {
        width: 485px;
        margin: 0 10px;

        ::v-deep label {
            font-size: 20px;
            font-weight: 500;
            line-height: 22px;
            color: #277692;
        }
    }
</style>
