<template>
    <button type="button" class="removeButton" @click="onRemove" :style="{ style }">
        <img
            class="bi d-block mx-auto mb-1 move-button"
            fill="red"
            src="@/assets/actionIcons/delete.svg"
            :height="height"
            :width="width"
        />
        <slot>{{ label }}</slot>
    </button>
</template>

<script>
export default {
    name: "remove-button",
    props: {
        width: {
            type: Number,
            required: false,
            default: 24,
        },
        height: {
            type: Number,
            required: false,
            default: 24,
        },
        label: {
            type: String,
            default: "",
            required: false,
        },
        style: {
            type: Object,
            default: {},
            required: false,
        },
        // position: {
        //     type: String,
        //     default: "",
        //     required: false,
        // },
        // bottom: {
        //     type: String,
        //     default: "",
        //     required: false,
        // },
        // top: {
        //     type: String,
        //     default: "",
        //     required: false,
        // },
        // right: {
        //     type: String,
        //     default: "",
        //     required: false,
        // },
        // left: {
        //     type: String,
        //     default: "",
        //     required: false,
        // },
    },
    methods: {
        onRemove() {
            this.$emit("click");
        },
    },
};
</script>

<style scoped>
.removeButton {
    font-family: Roboto Medium;
    color: black;
    /* line-height: 32px; */
    /* font-size: 16px; */
}
.move-button {
    margin-top: -6px;
    margin-right: 8px;
}
</style>
