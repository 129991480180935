import lag from "./lag.js";
import spelare from "./spelare.js";
import aktiviteter from "./aktiviteter.js";
import underlag from "./underlag.js";
import platser from "./platser.js";
import aktivitetstyper from "./aktivitetstyper.js";
import statistik from "./statistik.js";

export default {
    namespaced: true,
    modules: {
        lag: lag,
        spelare: spelare,
        aktiviteter: aktiviteter,
        underlag: underlag,
        platser: platser,
        aktivitetstyper: aktivitetstyper,
        statistik: statistik,
    },
    mutations: {
        reset(state) {},
    },
};
