<template>
    <div class="FilhanteringRedigera">
        <form id="user-registration-details">
            <h2>Läs in fil</h2>
            <div class="form-group k3-form fullscreen">
                <div class="k3-sub-form-flex-container">
                    <div>
                        <div class="circle">
                            <div class="number">1</div>
                        </div>
                        <div class="vr"></div>
                    </div>
                    <div style="width: 100%">
                        <div class="left-pad" @click="togglePaneOne()">
                            <h3>
                                Fildata
                                <img
                                    :class="['k3-dropdown-icon', paneOneOpen ? 'k3-img-rotate' : '']"
                                    src="@/assets/dropdown-arrow-blue.svg"
                                />
                            </h3>
                        </div>

                        <collapse-transition>
                            <div class="k3-sub-form" v-if="paneOneOpen">
                                <FilhanteringForhandsvisning
                                    class="margin-align"
                                    v-bind:file="file"
                                    v-bind:metaData="forhandsvisningMetaData"
                                />
                                <div class="margin-align input-grid-2-col" v-if="displayCopyFromPrevious">
                                    <Button
                                        size="secondary"
                                        variant="contour"
                                        class="copy-from-previous"
                                        type="button"
                                        @click="lokalUppladdningKopieraDataFranForegaendeFil"
                                    >
                                        <img src="@/assets/duplicate_blue.svg" />
                                        Kopiera data från föregående fil till tomma fält.
                                    </Button>
                                </div>

                                <FilhanteringPerson class="whole" v-bind:index="index" />

                                <input-wrapper
                                    class="input-grid-1-col"
                                    :component="DisplayTextWidget"
                                    label="Inläsningsdatum"
                                    name="inmatningsDatum"
                                    v-model="lokalUppladdningInmatningsdatum"
                                />

                                <input-wrapper
                                    class="input-grid-1-col"
                                    :component="widgets.DateWidget"
                                    label="Dokumentets datum"
                                    name="dokumentDatum"
                                    v-model="dokumentDatum"
                                    :required="false"
                                />

                                <input-wrapper
                                    class="input-grid-1-col"
                                    :component="DisplayTextWidget"
                                    label="Inläst av"
                                    name="fullName"
                                    v-model="fullName"
                                />

                                <input-wrapper
                                    class="input-grid-1-col"
                                    :component="FilhanteringSuggestWidget"
                                    label="Dokumenttyp"
                                    name="dokumentTyp"
                                    v-model="dokumentTyp"
                                    apisearch="/filhantering/dokumenttyp/"
                                    :required="true"
                                    :map="dokumentTypSuggestFilter"
                                />

                                <!-- <input-wrapper
                    :component="widgets.SuggestWidget"
                    label="Signeringsansvarig"
                    name="accountableHealthcareProfessional"
                    v-model="accountableHealthcareProfessional"
                    apisearch="/core/profiles/?journalskrivningsratt=true"
                    :map="profileSuggestFilter"
                    />
                -->

                                <input-wrapper
                                    class="input-grid-2-col"
                                    :component="widgets.ScalableTextArea"
                                    label="Kommentar"
                                    name="kommentar"
                                    v-model="kommentar"
                                    :maxlength="255"
                                />

                                <!-- EFTER DOKUMENTDATUM  -->
                            </div>
                        </collapse-transition>
                    </div>
                </div>
            </div>

            <div class="button-row">
                <ButtonGroup>
                    <Button
                        type="button"
                        size="primary"
                        @click="saveAndContinue"
                        :disabled="!formIsValid"
                        v-if="!atLastFile"
                    >
                        Spara och gå till nästa
                    </Button>

                    <Button size="secondary" @click="save" :disabled="!formIsValid" type="button">
                        {{ saveButtonText }}
                    </Button>

                    <Button size="secondary" variant="contour" type="button" @click="cancel">Avbryt</Button>
                </ButtonGroup>

                <ButtonGroup>
                    <Button size="secondary" variant="alert" type="button" role="button" @click="clearFields">
                        Rensa alla fält
                    </Button>
                </ButtonGroup>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";
import widgets from "@/components/widgets/inputs";
import FilhanteringSuggestWidget from "./FilhanteringSuggestWidget.vue";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import DisplayTextWidget from "@/components/widgets/inputs/DisplayTextWidget.vue";
import { getConfig, klinikenApi } from "@/api";

import FilhanteringPerson from "./FilhanteringPerson.vue";
import FilhanteringForhandsvisning from "./Forhandsvisning/FilhanteringForhandsvisning.vue";

import { bytesToSize, mimeToFormat } from "./Utils.js";

// const metaDataGetter = (key) => {
//     return {
//         get() {
//             const index = this.index;
//             return this.$store.state.filhantering.lokalUppladdning.filer[index].metaData[key];
//         },
//     };
// };

// Generate two way computed properties into
// state.filhantering.lokalUppladdning.filer[this.index].metaData;
// And update  dirty state
const metaDataGetterAndSetter = (key) => {
    return {
        get() {
            const index = this.index;
            return this.$store.state.filhantering.lokalUppladdning.filer[index].metaData[key];
        },

        set(value) {
            const index = this.index;
            this.$store.commit("filhantering/lokalUppladdingAngeMetaData", { index, key, value });
            this.$store.commit("filhantering/lokalUppladdningSetFormDirty", { index });
        },
    };
};

// Generate two way computed properties into
// state.filhantering.lokalUppladdning.filer[this.index].metaData;
// Without updating dirty state
const metaDataGetterAndSetterWithoutDirtyState = (key) => {
    return {
        get() {
            const index = this.index;
            return this.$store.state.filhantering.lokalUppladdning.filer[index].metaData[key];
        },

        set(value) {
            const index = this.index;
            this.$store.commit("filhantering/lokalUppladdingAngeMetaData", { index, key, value });
        },
    };
};

export default {
    name: "FilhanteringRedigeraNyFil",
    components: {
        InputWrapper,
        CollapseTransition,
        FilhanteringPerson,
        FilhanteringForhandsvisning,
        ButtonGroup,
        Button,
    },

    props: {
        index: Number,
    },

    data() {
        return {
            paneOneOpen: true,
            paneTwoOpen: false,
            formErrors: [],
            widgets,
            FilhanteringPerson,
            FilhanteringSuggestWidget,
            forhandsvisningMetaData: {
                ursprungligtFilnamn: this.$store.state.filhantering.lokalUppladdning.filer[this.index].file.name,
                filstorlek: bytesToSize(this.$store.state.filhantering.lokalUppladdning.filer[this.index].file.size),
                filformat: mimeToFormat(this.$store.state.filhantering.lokalUppladdning.filer[this.index].file.type),
            },

            profileSuggestFilter: (el) => {
                return {
                    id: el.pk,
                    code: el.pk,
                    displayName: el.user.name,
                };
            },

            dokumentTypSuggestFilter: (el) => {
                return {
                    id: el.id,
                    code: el.id,
                    displayName: el.namn,
                };
            },
            DisplayTextWidget,
            personIdIsValid: true,
        };
    },

    computed: {
        fileId: metaDataGetterAndSetter("fileId"),
        accountableHealthcareProfessional: metaDataGetterAndSetter("accountableHealthcareProfessional"),
        createdBy: metaDataGetterAndSetterWithoutDirtyState("createdBy"),
        dokumentDatum: metaDataGetterAndSetter("dokumentDatum"),
        dokumentTyp: metaDataGetterAndSetter("dokumentTyp"),
        inlast: metaDataGetterAndSetter("inlast"),
        kommentar: metaDataGetterAndSetter("kommentar"),
        legalAuthenticator: metaDataGetterAndSetter("legalAuthenticator"),
        patientUUID: metaDataGetterAndSetter("patientUUID"),
        // personIdIsValid: metaDataGetter("personIdIsValid"),

        ...mapGetters("userData", ["fullName", "currentProfile"]),
        ...mapGetters("filhantering", [
            "lokalUppladdningAntal",
            "lokalUppladdningInmatningsdatum",
            "lokalUppladdningFormIsClean",
        ]),

        formIsValid() {
            let result = true;
            let errors = [];

            if (!this.personIdIsValid) {
                result = false;
                errors.push("Inget giltigt person-id");
            }

            if (!this.dokumentTyp) {
                result = false;
                errors.push("Ingen giltig 'dokumentTyp'.");
            }
            return result;
        },

        displayCopyFromPrevious() {
            return this.index > 0;
        },

        file: {
            get() {
                return this.$store.state.filhantering.lokalUppladdning.filer[this.index].file;
            },
        },

        atLastFile: {
            get() {
                return this.index === this.lokalUppladdningAntal - 1;
            },
        },

        saveButtonText: {
            get() {
                if (this.atLastFile) {
                    return "Spara och stäng";
                } else {
                    return "Spara";
                }
            },
        },
    },

    watch: {
        hasUnsavedData(value) {
            this.$emit("hasUnsavedData", value);
        },
        patientUUID() {
            const index = this.index;
            this.lokalUppladdningSetFormDirty({ index });
        },
    },

    methods: {
        ...mapActions("filhantering", ["lokalUppladdningKopieraDataFranForegaendeFil"]),
        ...mapMutations("filhantering", ["lokalUppladdningSetFormDirty"]),

        togglePaneOne() {
            this.paneOneOpen = !this.paneOneOpen;
        },

        togglePaneTwo() {
            this.paneTwoOpen = !this.paneTwoOpen;
        },

        mapDokumentTypSearchResults(el) {
            return { id: el.id, code: el.id, displayName: el.namn };
        },

        async save() {
            await this.uploadNewFileAndMetaData();
            this.$root.$emit("file-changed");
            if (this.atLastFile) {
                // Close this tab
                this.$emit("closetab");
            }
        },

        async cancel() {
            this.$emit("closetab", !this.lokalUppladdningFormIsClean);
        },

        // Denna används när vi laddar upp en ny fil
        async uploadNewFileAndMetaData() {
            const formData = new FormData();

            // patientUUID
            formData.append("patient", this.patientUUID);

            // Inläst av
            formData.append("created_by", this.createdBy.pk);

            // TODO Updating prop. Not OK?
            // this.value.metaData.accountableHealthcareProfessional = this.metaData.accountableHealthcareProfessional;

            // Signeringsanvarig
            formData.append("accountableHealthcareProfessional", this.accountableHealthcareProfessional);

            formData.append("documentTime", this.dokumentDatum);

            formData.append("documentTitle", this.kommentar);

            // Specifika för Filer
            formData.append("grundfil", this.$store.state.filhantering.lokalUppladdning.filer[this.index].file);

            formData.append("dokument_typ", this.dokumentTyp);

            // Now upload the file
            const uploadUrl = `/filhantering/${this.patientUUID}/fil/`;

            const response = await klinikenApi.post(uploadUrl, formData, getConfig({}, "Filer"));

            // Hold on to the created fileId
            this.fileId = response.data.fileId;
            // Markera denna fil som inläst, vilket låser personid från redigering
            this.inlast = true;
        },

        saveAndContinue() {
            this.save();
            this.$emit("continuetonextfile", this.index);
        },

        clearFields() {
            this.dokumentDatum = undefined;
            this.kommentar = undefined;

            this.dokumentTyp = { code: "", id: "", displayName: "" };

            const index = this.index;
            const dict = {
                personId: "",
                patientNamn: "",
                patientUUID: "",
                personIdType: "",
                personIdIsValid: false,
                personIdIsUnique: false,
            };

            for (const [key, value] of Object.entries(dict)) {
                this.$store.commit("filhantering/lokalUppladdingAngeMetaData", { index, key, value });
            }
        },

        showWarning(message) {
            this.$emit("warning", message);
        },
    },

    mounted() {
        // Set "Inlast av" to the currently logged in user
        this.createdBy = this.currentProfile;
    },
};
</script>
<style lang="scss" scoped>
@import "../../style/variables";

.margin-align {
    margin-left: 10px;
    margin-right: 10px;
}

.copy-from-previous {
    min-width: fit-content;
    padding-left: 17px;
    padding-right: 17px;
    margin-bottom: 28px;
}

h2 {
    margin: 0 0 20px 10px;
}

h3 {
    height: 28px;
    color: #277692;
    font-family: Roboto;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    display: inline-block;
    margin-left: 7px;
}

h4 {
    color: #277692;
    font-family: Roboto;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 28px;
}

.fixed-width {
    max-width: 700px;
    margin-bottom: 29px;
}

.circle {
    height: 26px;
    width: 26px;
    background-color: #277692;
    border-radius: 13px;
}

.number {
    height: 26px;
    width: 100%;
    color: #ffffff;
    font-family: Roboto;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
}

.vr {
    border-left: 1px solid #8ebaca;
    height: calc(100% - 60px);
    min-height: 10px;
    margin-left: 13px;
    margin-top: 10px;
    margin-bottom: 10px;

    &.vr-no-min-height {
        min-height: 0px;
    }
}

.k3-lock-border {
    height: 26px;
    width: 350px;
    background: linear-gradient(134.72deg, #c2e6e6 0%, rgba(194, 230, 228, 0) 100%);
    color: #277692;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.46px;
    line-height: 26px;
    margin-bottom: 18px;
    margin-left: 10px;

    img {
        margin: -2px 6px 0px 17px;
    }
}

.k3-sub-form-flex-container {
    display: flex;
    flex-direction: row;
    margin-left: -20px;
}

.k3-sub-form {
    display: grid;
    width: 100%;
    grid-template-columns: 25% 25% 25% 25%;

    .whole {
        grid-column: 1 / span 4;
    }

    .input-grid-1-col {
        grid-column: span 1;
        min-width: 100%;
    }

    .input-grid-2-col {
        grid-column: span 2;
        min-width: 100%;
    }
}

.k3-dropdown-icon {
    margin-left: 15px;
}

.k3-img-rotate {
    transform: rotate(180deg);
}

.button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>
