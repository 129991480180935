<template>
    <button class="login-button" type="button" v-on="$listeners">
        <img :src="img" /> <slot>{{ value }}</slot>
    </button>
</template>

<script setup>
    import { toRefs } from "vue";

    const props = defineProps({
        img: {
            type: String,
            required: true,
        },
    });

    const { img, value } = toRefs(props);
</script>

<style lang="scss">
    @import "@/style/variables";

    .login-button {
        all: unset;
        box-sizing: border-box;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;

        min-height: 78px;
        height: 78px;
        max-height: 78px;
        border: 1px solid $color-primary;
        border-radius: 2px;
        padding: 17px 45px 17px 45px;

        color: #2694bc;

        font-size: 16px;
        font-weight: 500;
        line-height: 21px;

        &:hover {
            background-color: #c8e6ec;
        }
    }
</style>
