import axios from "axios";
import { openDialog } from "@/utils";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const api = import.meta.env.VITE_KLINIKEN_API || "";
const baseTimeout = 30000;

const klinikenApi = axios.create({
    baseURL: api + "/api/",
    timeout: baseTimeout,
});

const klinikenApiNoTimeout = axios.create({
    baseURL: api + "/api/",
    timeout: NaN,
});

let authenticationMethod = null;
let fallbackSignMethod = null;
let logoutMethod = null;
let refreshToken = null;
let accessToken = null;

const setLogoutMethod = (logout) => {
    logoutMethod = logout;
};
// TODO: part of a hack to display better error messages for validation errors. Should be replaced down the line.
//let errData = null;

/**
 * Injects a custom header into an axios config object.
 *
 * @param {Object} config - an axios config object that will change the default of axios config klinikenApi instance. Most notably "params" is part of a config object.
 * @param {String} tabbnamn - Human readable name of the view
 *
 * See https://github.com/axios/axios#request-config
 */

const getConfig = function (config = {}, tabbnamn = null) {
    let headers = { ...config.headers, ...axios.defaults.headers.common };
    if (tabbnamn) {
        headers = { ...headers, "x-kliniken-tabbnamn": tabbnamn };
    }
    config.headers = headers;
    return config;
};

const recursiveParse = function (node, parentName) {
    let errorString = "";
    if (Array.isArray(node)) {
        for (let i = 0; i < node.length; i++) {
            errorString += recursiveParse(node[i], parentName);
        }
    } else if (typeof node === "object") {
        let keys = Object.keys(node);
        keys.forEach((key) => {
            errorString += recursiveParse(node[key], key);
        });
    } else if (typeof node === "string") {
        errorString += (parentName && parentName != "non_field_errors" ? parentName + ": " : "") + node + "\n";
    }
    return errorString;
};

const getErrorMessage = function (error) {
    //eslint-disable-next-line
    console.dir(error);
    let data = "";

    // Request timeouts and such errors where there is no HttpResponse
    if (!error.response) {
        data = "Kunde inte kontakta servern. Var god försök igen senare. Om felet kvarstår, kontakta supporten.";
    }

    // 401 errors indicates invalid authorization. Token_not_valid errors should trigger an immediate logout.
    else if (error.response.status === 401) {
        data = error.response.data;
        if (data && data.code === "token_not_valid") {
            if (typeof logoutMethod === "function") {
                data = "Misslyckades med autentisering. Du kommer automatiskt loggas ut.";
                logoutMethod();
            } else data = "Autentiseringsfel.";
        } else data = "Autentiseringsfel.";
    }

    // 403 errors indicates the user is not allowed to perform the action
    else if (error.response.status === 403) {
        data = "Du har inte tillåtelse att utföra denna åtgärd.";
    }

    // Two very specific errors indicating the client has too many concurrent requests. Doesn't necessarily mean anything is broken.
    else if (error.response.status === 408 || error.response.status === 429) {
        data = "Servern är upptagen. Var god försök igen om en stund.";
    }

    // Other errors where error text is provided by backend, typically 400 validation errors
    else if (error.response.data) {
        if (error.response.data.message) {
            // GrandID style errors
            data = error.response.data.message;
        } else if (error.response.data.non_field_errors) {
            // Generic Django REST errors
            let nonFieldErrors = error.response.data.non_field_errors;
            nonFieldErrors.forEach((el) => {
                data += el + "\n";
            });
        } else {
            // Django REST validation errors
            let errorData = error.response.data;
            data = recursiveParse(errorData);
        }
    }

    // All other errors, notably 402-599 and 3XX errors which axios can't handle itself
    else {
        data = "Ett oväntat fel har uppstått. Om felet kvarstår, kontakta supporten.";
    }

    //if (data.length > hur mycket? kapa strängen)
    return data;
};

const setAuthenticationMethod = (method) => {
    if (process.env.NODE_ENV === "development") {
        sessionStorage.setItem("AuthenticationMethod", method);
    }
    authenticationMethod = method;
};

const setFallbackSignMethod = (method) => {
    fallbackSignMethod = method;
};

const getFallbackSignMethod = () => {
    return fallbackSignMethod;
};

const getAuthenticationMethod = () => {
    return authenticationMethod;
};

const getTimeout = () => {
    return baseTimeout;
};

// jwt must be in serialized format, as it should appear in the header.
const setJWT = (jwt, firstCall = false) => {
    if (jwt === null) delete axios.defaults.headers.common["Authorization"];
    else {
        axios.defaults.headers.common = { ...axios.defaults.headers.common, ...{ Authorization: "Bearer " + jwt } };
        accessToken = jwt;
        /*
        // Removing the dynamic setting of refresh time. This could potentially trigger an error if local time is wrong.
        let claims = getJWTPayload(jwt);
        let expirationTime = new Date(claims.exp*1000) - new Date().getTime();
        let timeOut = (firstCall) ? 0 : (expirationTime - (baseTimeout * 2));
        */
        let timeOut = firstCall ? 0 : 240000; // use hard coded 4 minutes
        setTimeout(refreshJWT, timeOut); // refresh before session expires, use a grace period of twice baseTimeout (or maybe always 60s?)
    }
};

const getAccessToken = () => {
    return accessToken;
};

const setRefreshToken = (token) => {
    if (process.env.NODE_ENV === "development") {
        sessionStorage.setItem("Refresh", token);
    }
    refreshToken = token;
};

const refreshJWT = async () => {
    if (!refreshToken) return;

    try {
        const response = await klinikenApi.post("auth/token/refresh/", { refresh: refreshToken });
        setJWT(response.data.access);
    } catch (e) {
        setJWT(null);
        openDialog(getErrorMessage(e), "error");
    }
};

const getJWTPayload = (jwt) => {
    if (!jwt) return {};
    let jwtPayload = jwt.split(".")[1];
    let decodedPayload = atob(jwtPayload);
    let payload = JSON.parse(decodedPayload);

    return payload;
};

const setProfileHeader = (pk) => {
    if (pk === null) delete axios.defaults.headers.common["X-Kliniken-Profile"];
    else
        axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            ...{ "X-Kliniken-Profile": pk },
        };
};

const staticFiles = axios.create({
    baseURL: api + "/static/",
});

klinikenApi.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 403) {
            openDialog(getErrorMessage(error), "error");
        }
        return Promise.reject(error);
    }
);

export {
    klinikenApi,
    klinikenApiNoTimeout,
    setFallbackSignMethod,
    getFallbackSignMethod,
    staticFiles,
    setRefreshToken,
    getAccessToken,
    setProfileHeader,
    setAuthenticationMethod,
    getAuthenticationMethod,
    getConfig,
    getJWTPayload,
    getErrorMessage,
    setJWT,
    refreshJWT,
    setLogoutMethod,
    getTimeout,
};
