<template>
    <div>
        <Button role="button" @click="uploadLocalFile">Ladda upp lokala filer</Button>
        <input
            id="fileInput"
            type="file"
            @change="handleFileChange"
            multiple
            accept=".bmp, .dib, .gif, .jfif, .jif, .jpe, .jpeg, .jpg, .m4a, .m4b, .m4p, .m4r, .m4v, .mp3, .mp4, .pdf, .png, .tif, .tiff, .wav, .wave"
        />
    </div>
</template>

<script>
import FilhanteringInlasning from "./FilhanteringInlasning.vue";
import Button from "@/components/Button.vue";
import { mapGetters } from "vuex";

export default {
    name: "FilhanteringUploadLocalFiles",

    components: {
        Button,
    },

    computed: {
        ...mapGetters("patientData", ["currentId"]),
    },

    methods: {
        uploadLocalFile() {
            // Use the native browser file input field that we've hidden with css
            // sending a click event to it.
            document.getElementById("fileInput").click();
        },

        // Denna körs när användaren valt en eller flera filer
        // Då vill vi öppnar en ny flik för att administrera filinläsningen
        //  async handleFileChange(event) {
        handleFileChange(event) {
            // TODO, check that there is acutually a file selected
            // Öppna en ny flik för att redigera fildata

            // Find files that do not exceed our limit of 100MB
            const grundfiler = Array.from(event.target.files).filter((file) => {
                return file.size <= 100 * 1024 * 1024;
            });

            this.$store.dispatch("tabs/openTab", {
                component: FilhanteringInlasning,
                parameters: { grundfiler },
            });
        },
    },
};
</script>

<style lang="sass" scoped>
@import "@/style/variables"
@import "@/style/deprecated_main"

#fileInput
    display: none
</style>
