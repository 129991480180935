import { getConfig, klinikenApi } from "@/api";
import { cloneDeep } from "lodash";

const getDefaultState = () => {
    return {
        typoperationer: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        typoperationer: (state) => state.typoperationer,
        count: (state) => state.count,
        next: (state) => state.next,
        previous: (state) => state.previous,
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setTypoperation: (state, item) => {
            const index = state.typoperationer.findIndex((i) => i.id === item.id);

            if (index !== -1) {
                state.typoperationer[index] = item;
            } else {
                state.typoperationer.push(item);
            }

            state.typoperationer = [...state.typoperationer];
        },
        resetTypoperationer: (state) => (state.typoperationer = []),
        setTypoperationer: (state, payload) => (state.typoperationer = payload),
        setCount: (state, payload) => (state.count = payload),
        setNext: (state, payload) => (state.next = payload),
        setPrevious: (state, payload) => (state.previous = payload),
    },
    actions: {
        async save({ dispatch, commit }, typoperation) {
            let response;
            if (typoperation.id === undefined) {
                response = await dispatch("create", typoperation);
            } else {
                response = await dispatch("update", typoperation);
            }
            commit("setTypoperation", cloneDeep(response.data));
            return response;
        },
        async create({}, typoperation) {
            return await klinikenApi.post(`operation/typoperationer/`, typoperation, getConfig());
        },
        async update({}, typoperation) {
            return await klinikenApi.put(`operation/typoperationer/${typoperation.id}/`, typoperation, getConfig());
        },
        async _load({ commit }, url) {
            const response = await klinikenApi.get(url, getConfig());
            commit("setTypoperationer", response.data.results);
            commit("setCount", response.data.count);
            commit("setNext", response.data.next);
            commit("setPrevious", response.data.previous);
        },
        async load({ commit, dispatch }) {
            commit("resetTypoperationer");
            await dispatch("_load", `operation/typoperationer/`);
        },
        async loadNext({ state, dispatch }) {
            if (state.next !== null) {
                await dispatch("_load", state.next);
            }
        },
    },
};
