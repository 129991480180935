<template>
    <div class="TypoperationSelectWidget colspan whole">
        <h4>Typoperation</h4>
        <SimpleSuggestWidget
            v-if="dataIsLoaded"
            :options="options"
            :optionsIsOpen="false"
            :preselectedOption="typoperation"
            :customFilterFunction="customFilterFunction"
            ref="simpleSuggestWidget"
            @setValue="setTypoperation"
        />
        <div class="typoperationer-info-wrapper">
            <span class="typoperationer-info-text"
                ><img class="typoperationer-info-image" src="@/assets/info_dark.svg" /> Observera att val av
                typoperation kan ersätta data du fyllt i formulärfält under Operationsdata</span
            >
        </div>
    </div>
</template>

<script lang="js">
import SimpleSuggestWidget from "@/components/widgets/inputs/SimpleSuggestWidget.vue";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

const ERASE_ROW_ID = 0;

export default {
    name: "TypoperationSelectWidget",

    props: {
        preselectedTypoperation: Object, // {id: number, displayName: string}
    },

    components: {
        SimpleSuggestWidget,
    },

    data() {
        return {
            typoperation: undefined,
            dataIsLoaded: false,
            options: [],
        };
    },

    computed: {
        displayEraseRow() {
            return this.typoperation !== null && this.typoperation !== undefined && this.typoperation.id != null && this.typoperation.displayName != "";
        },
    },

    async mounted() {
        await this.loadData();
        this.typoperation = this.preselectedTypoperation;
        this.dataIsLoaded = true;
    },

    methods: {
        async loadData() {

            const config = getConfig();
            await klinikenApi
                .get("operation/typoperationer/", config)
                .then((response) => {
                    this.options = response.data.results.map(
                        (item) => {
                            return { id: item.id, displayName: item.namn };
                        },
                    );

                })
                .catch((e) => {
                    openDialog(
                        "Kunde inte hämta typoperationer från servern. " + getErrorMessage(e),
                        "warning",
                    );
                });

            return true;

        },

        customFilterFunction(options, query) {
            let result = [];
            try {
                // By default we try to do a case insensitive regexp query
                const pattern = new RegExp(query, "i");
                result = options.filter(item => pattern.test(item.displayName));
            } catch (error) {
                // If that fails we fall back to string comparison
                result = options.filter(item => item.displayName.includes(query));
            }

            // Injicera rensningsrad
            if (this.displayEraseRow) {
                result.unshift(
                    { id: ERASE_ROW_ID, displayName: "Töm typoperation" },
                );

            }
            return result;
        },

        setTypoperation(value) {
            this.purgeTypoperation();
            if (value.id === ERASE_ROW_ID) {
                this.$refs.simpleSuggestWidget.clearQuery();
            } else {
                this.typoperation = value;
                this.$emit("setTypoperation", value);

            }
        },

        purgeTypoperation() {
            this.$emit("purgeTypoperation");
            this.typoperation = { id: null, displayName: "" };
        },

    },
};
</script>

<style scoped lang="scss">
@import "@/style/_deprecated_main";

div.TypoperationSelectWidget {
    background: #ebf6f8;
    padding: 16px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    .typoperationer-info-wrapper {
        opacity: 0.6;

        .typoperationer-info-text {
            margin-top: 10px;
            font-size: 15px;
        }
    }
}
</style>

<style lang="sass" scoped>
div.TypoperationSelectWidget
    @import "@/style/_variables.scss"

    /* Bootstrap - Start */
    @import "bootstrap/scss/functions"
    @import "bootstrap/scss/variables"
    @import "bootstrap/scss/mixins"
    @import "bootstrap/scss/root"
    @import "bootstrap/scss/reboot"

    @import "bootstrap/scss/forms"
    /* Bootstrap - End */

    @import "@/style/_deprecated_main.scss"

    padding-bottom: 12px
    margin-bottom: 20px
    position: relative

    .definition
        height: 21px
        color: #7F8FA4
        font-size: 14px
        letter-spacing: 0
        line-height: 21px
        display: block
        margin-bottom: 5px

    .expanded-warning
        box-sizing: border-box
        border: 1px solid #EFC2D4
        border-radius: 3px
        background-color: #F7E1E9
        color: #354052
        display: flex
        padding: 10px 0px 10px 0px

        &.suggest
            width: calc(100% - 150px)

        span
            font-size: 14px
            color: #354052
            white-space: normal
            display: block

        .expanded-warning-icon
            padding-left: 5.5px
            padding-top: 2px
            color: #FFF
            font-size: 12px
            line-height: 12px
            font-weight: bold
            height: 14px
            width: 14px
            border-radius: 7px
            border: none
            background-color: #354052
            margin-top: 5px
            margin-left: 5px
            margin-right: 10px

        img
            margin-top: 25px
            margin-left: 5px
            margin-right: 10px

            &.rotate
                transform: rotate(180deg)

    > button
        img
            height: 12px
            width: 12px
            margin-right: 5px
            margin-top: -5px

            &.rotate
                transform: rotate(180deg)

    input, textarea, select
        -webkit-border-radius: 4px
        -moz-border-radius: 4px
        border-radius: 4px
        border: 1px solid #95A1B1
        //margin-bottom: 32px
        padding: 10px

    .error
        input, textarea, select
            border-bottom: 4px solid #9E354B !important

    input, select, textarea
        &.error
            border-bottom: 4px solid #9E354B !important

    span
        color: #728296
        font-size: 16px
        font-weight: 500
        line-height: 21px
        display: block
        margin-bottom: 2px
        position: relative
        //white-space: nowrap
        white-space: normal

        &.error
            color: #9E354B

        &.error:not(:first-of-type)
            font-size: 14px
            line-height: 21px
            font-weight: 400
            //margin-top: -32px
            margin-left: 16px

            &::before
                content: '!'
                padding-left: 5.5px
                padding-top: 2px
                color: #FFF
                font-size: 12px
                line-height: 12px
                font-weight: bold
                height: 14px
                width: 14px
                border-radius: 7px
                border: none
                background-color: #9E354B
                position: absolute
                left: -16px
                top: 2px

        &.warning:not(:first-of-type)
            color: #354052
            font-size: 14px
            line-height: 21px
            font-weight: 400

            &.suggest
                white-space: normal
                width: calc(100% - 150px)
    //overflow: hidden
    //text-overflow: ellipsis

    /* Input sizes */



    input, select
        height: 46px !important
        width: 100%

    textarea
        //min-height: 81px
        width: 100%

    div[contenteditable="true"]
        border-radius: 4px
        border: 1px solid #95A1B1
        padding: 5px
        width: 60em
        min-height: 5em
        overflow: auto
        background-color: #FFFFFF !important

    .k3-form
        textarea
            min-height: 81px
</style>
