<template>
    <div id="send-receipt">
        <div class="center-group">
            <img height="120px" width="120px" src="@/assets/error_big.svg" />
        </div>
        <div class="center-span">
            <div class="center-info">
                <label class="headline">Kvittens från E-hälsomyndigheten</label><br />
                Status: {{ messageStatusDisplay(response.messageStatus) }}<br />
                <template v-for="item in response.statusInformation">
                    <!-- eslint-disable-next-line -->
                    Statuskod: {{ item.statusCode }} <br />
                    <!-- eslint-disable-next-line -->
                    {{ item.description }} <br />
                </template>
            </div>
            <div class="btn-group">
                <button type="button" @click="close()" class="btn btn-primary">Stäng</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import dialogEvents from "@/dialogEvents";

export default {
    name: "SendReceipt",
    props: ["standalone"], //Remove when PrescriptionSet.vue is deprecated
    data() {
        return {
            response: this.$attrs.response,
        };
    },
    computed: {
        ...mapGetters("patientData", ["personId", "name"]),
    },
    methods: {
        close() {
            dialogEvents.$emit("closePopup");
        },
        messageStatusDisplay(messageStatus) {
            let displayName = "";
            switch (messageStatus) {
                case "0":
                    displayName = "Accepterat utan upptäckta fel";
                    break;
                case "1":
                    displayName = "Accepterat med varningar";
                    break;
                case "2":
                    displayName = "Förkastat, kan ej hanteras av mottagaren";
                    break;
            }
            return displayName;
        },
    },
};
</script>
<style lang="sass" scoped>
#send-receipt
    position: relative
    max-width: 1100px
    width: 953px

    .center-group
        width: 100%
        text-align: center
        margin-top: 16px

    .center-span
        display: block
        width: 722px
        margin-top: 28px
        margin-left: auto
        margin-right: auto

    .headline
        color: #277692
        font-weight: bold

    .center-info
        width: 100%
        border-radius: 6px
        background-color: #E4E8F0
        padding: 27px
        color: #354052
        font-size: 16px
        letter-spacing: 0
        line-height: 23px
        margin-bottom: 28px

    .btn-group
        float: left
        margin-bottom: 28px
</style>
