<template>
    <basic-form submit-button="Signera" :errors="errors" @submit="submit" @cancel="cancel" />
</template>

<script setup>
    import { ref, toRefs } from "vue";
    import { getConfig, klinikenApi } from "@/api";
    import BasicForm from "@/components/Auth/BasicForm.vue";

    const props = defineProps({
        url: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    });

    const { data, url } = toRefs(props);

    const errors = ref({});

    const emit = defineEmits(["success", "error", "cancel"]);

    const submit = async (formData) => {
        formData = { ...formData, ...data.value };
        try {
            const response = await klinikenApi.post(`${url.value}sign/basic/`, formData, getConfig());
            emit("success", response.data);
        } catch (error) {
            if (error.response.status === 403) {
                emit("error", "Det gick inte att signera.");
            } else if (error.response.status === 400) {
                emit("error", error.response.data);
            } else {
                emit("error", "Det gick inte att logga in.");
            }
        }
    };

    const cancel = () => {
        emit("cancel");
    };
</script>
