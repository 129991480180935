<template>
    <div :class="context.classes.element" :data-type="context.type">
        <input-time-picker v-model="context.model" v-bind="attributes" v-on="$listeners" :context="context" />
        <FormulateSlot name="prefix" :context="context">
            <component :is="context.slotComponents.prefix" v-if="context.slotComponents.prefix" :context="context" />
        </FormulateSlot>

        <FormulateSlot name="suffix" :context="context">
            <component :is="context.slotComponents.suffix" v-if="context.slotComponents.suffix" :context="context" />
        </FormulateSlot>
    </div>
</template>

<script>
import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
import InputTimePicker from "@/components/InputTimePicker.vue";

export default {
    name: "FormulateTimePicker",
    mixins: [FormulateInputMixin],
    components: {
        InputTimePicker,
    },
};
</script>
