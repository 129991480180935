<template>
    <form @submit.prevent="submit">
        <admin-lock-border />
        <h2 v-if="parameters">Redigera sökordsstruktur</h2>
        <h2 v-else>Skapa ny sökordsstruktur</h2>
        <div class="form-group k3-form">
            <input-wrapper
                :component="widgets.SelectWidget"
                label="Anteckningstyp"
                v-model="formData.anteckningstyp"
                :options="anteckningstypOptions"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.SelectWidget"
                label="Sökord"
                required="true"
                v-model="formData.sokord"
                :options="
                    allSokords
                        .filter((el) => el.aktiv === true)
                        .map((el) => {
                            return { text: el.namn, value: el.pk };
                        })
                "
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.SelectWidget"
                label="Typ"
                required="true"
                v-model="formData.typ"
                :options="sokordsstrukturTyper"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper :component="widgets.NumberWidget" label="Sortering" v-model="formData.sortering" />
            <input-wrapper
                :component="widgets.TextAreaWidget"
                colspan="4"
                label="Beskrivning"
                v-model="formData.beskrivning"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextAreaWidget"
                colspan="4"
                label="Alternativ"
                v-model="formData.alternativ"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper :component="widgets.TextAreaWidget" colspan="8" label="Om så" v-model="formData.om_sa" />
            <input-wrapper
                :component="widgets.SelectWidget"
                label="Förälder"
                v-model="formData.foralder"
                :options="foralderOptions"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.CheckboxWidget"
                label="Aktiv"
                v-model="formData.aktiv"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.CheckboxWidget"
                label="Skrivskyddad"
                v-model="formData.skrivskyddad"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.CheckboxWidget"
                label="Obligatorisk"
                v-model="formData.obligatorisk"
                @input="updateFormHasBeenEdited"
            />
        </div>
        <ButtonGroup>
            <Button size="primary" role="button" type="submit" :disabled="!formHasBeenEdited">Spara och stäng</Button>
            <Button @click="cancel()" size="secondary" type="button">Avbryt</Button>
        </ButtonGroup>
    </form>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import tabIcon from "@/assets/receptforskrivning.svg";
import widgets from "@/components/widgets/inputs";
import { cloneDeep } from "lodash";
import { flashMessage, openDialog } from "@/utils";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { mapGetters } from "vuex";
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";

export default {
    extends: BaseTab,
    name: "Sokordsstruktur",
    tabName: "Sökordsstruktur",
    icon: tabIcon,
    components: {
        Button,
        ButtonGroup,
        AdminLockBorder,
        ...widgets,
        InputWrapper,
    },
    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            widgets: widgets,
            formHasBeenEdited: false,
            formData: {},
            sokordsstrukturTyper: [],
        };
    },
    computed: {
        ...mapGetters("sokord", ["allSokords", "sokordsstrukturer"]),
        ...mapGetters("anteckningstyp", ["anteckningstyper"]),
        hasUnsavedData() {
            return this.formHasBeenEdited;
        },
        anteckningstypOptions() {
            let anteckningstyper = [];
            // if creating a new Sokordostruktur, do show only active anteckningstyper
            this.parameters
                ? (anteckningstyper = this.anteckningstyper)
                : (anteckningstyper = this.anteckningstyper.filter((anteckningstyp) => anteckningstyp.is_active));
            let anteckningstypOptions = anteckningstyper.map((el) => {
                return { text: el.namn, value: el.pk };
            });
            anteckningstypOptions.splice(0, 0, { text: "", value: "" });
            return anteckningstypOptions;
        },
        foralderOptions() {
            let foralderOptions = this.sokordsstrukturer.map((el) => {
                return { text: el.pk + ": " + el.sokord.namn, value: el.pk };
            });
            foralderOptions.splice(0, 0, { text: "", value: "" });
            return foralderOptions;
        },
    },
    methods: {
        updateFormHasBeenEdited() {
            this.formHasBeenEdited = true;
        },
        async submit() {
            const config = getConfig();
            var details = this.formData;
            details.aktiv = Boolean(details.aktiv);
            details.skrivskyddad = Boolean(details.skrivskyddad);
            details.obligatorisk = Boolean(details.obligatorisk);
            await (details.pk
                ? klinikenApi.put("/anteckning/sokordsstrukturer/" + details.pk + "/", details, config)
                : klinikenApi.post("/anteckning/sokordsstrukturer/", details, config)
            )
                .then(async () => {
                    await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
                    await this.$store.dispatch("sokord/fetchSokordsstrukturer");
                    await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
                    flashMessage("Sökordsstruktur sparad.");
                    this.close();
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
        },
        cancel() {
            //Add stuff here
            this.close();
        },
        close() {
            this.$store.dispatch("tabs/closeTab", this.tabId);
        },
    },

    async created() {
        if (this.parameters) {
            let formData = cloneDeep(this.parameters);
            if (formData.sokord) formData.sokord = formData.sokord.pk;
            this.formData = formData;
        }
        this.$store.dispatch("sokord/loadAll");
        this.$store.dispatch("anteckningstyp/load", 0);

        await klinikenApi
            .get("/anteckning/sokordsstrukturer/typer/", getConfig())
            .then((response) => {
                this.sokordsstrukturTyper = response.data;
            })
            .catch((e) => {
                flashMessage("Kunde inte läsa sökordsstruktur typer" + getErrorMessage(e));
            });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/deprecated_main";
</style>
