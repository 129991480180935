<script>
import { CollapseTransition } from "@ivanv/vue-collapse-transition";

export default {
    name: "CollapsibleSection",

    components: {
        CollapseTransition,
    },

    props: {
        number: {
            type: String,
            required: true,
        },
        heading: {
            type: String,
            required: true,
        },
        grid: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            show: true,
        };
    },
};
</script>

<template>
    <div class="collapsible-section">
        <div class="header">
            <h3>
                <div class="circle">
                    <div class="number">{{ number }}</div>
                </div>
                <div class="toggle" @click.prevent="show = !show">
                    {{ heading }}
                    <img src="@/assets/dropdown-arrow-blue.svg" class="chevron" :class="{ rotate: show }" />
                </div>
            </h3>
        </div>
        <div class="outer">
            <collapse-transition>
                <div class="inner" :class="{ grid: grid }" v-show="show">
                    <slot></slot>
                </div>
            </collapse-transition>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.collapsible-section {
    margin-bottom: 15px;

    .header {
        margin-bottom: 10px;

        div {
            display: inline-block !important;
        }

        .circle {
            height: 26px;
            width: 26px;
            background-color: #277692;
            border-radius: 13px;
            position: relative;
            left: -13px;

            .number {
                height: 26px;
                width: 100%;
                color: #ffffff;
                font-family: Roboto;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 26px;
                text-align: center;
            }
        }

        .toggle {
            cursor: pointer;
        }
    }

    .outer {
        border-left: 1px solid #8ebaca;
        min-height: 10px;

        .inner {
            padding-left: 26px;
            padding-bottom: 15px;

            &.grid {
                display: grid;
                width: 1100px;
                grid-template-columns: repeat(12, 68px);
                grid-row-gap: 36px;
                grid-column-gap: 26px;
            }
        }
    }

    img {
        &.chevron {
            margin-left: 15px;
        }

        &.rotate {
            transform: rotate(180deg);
        }
    }
}
</style>
