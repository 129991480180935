<script setup lang="ts">
    import { ref, toRefs } from "vue";
    import { useIntervalFn } from "@vueuse/core";
    import ModalOverlay from "@/components/generic/ModalOverlay.vue";
    import ModalContent from "@/components/generic/ModalContent.vue";

    const props = defineProps<{
        maxIdleFor: number;
        countdownTime: number;
    }>();
    const emit = defineEmits(["close"]);

    const { maxIdleFor, countdownTime } = toRefs(props);
    const maxIdleForMinutes = Math.floor(maxIdleFor.value / 1000 / 60);

    let countdown = ref<number>(countdownTime.value / 1000);

    useIntervalFn(() => {
        countdown.value -= 1;
    }, 1000);

    window.addEventListener("mousemove", () => {
        emit("close");
    });
</script>

<template>
    <ModalOverlay class="modal-idle">
        <ModalContent>
            <template #head>
                <img class="idleImg" src="@/assets/kliniken-logo.svg" alt="Kliniken patientsystem" />
            </template>
            <template #default>
                <h1 class="title">Sessionen håller på att avslutas</h1>
                <div class="text">
                    <p>Du har varit inaktiv i mer än {{ maxIdleForMinutes }} minuter.</p>
                    <p>Rör på musen för att inte loggas ut automatiskt.</p>
                    <p>
                        Du kommer att loggas ut automatiskt om
                        <strong>{{ countdown }}</strong>
                        sekunder.
                    </p>
                </div>
            </template>
        </ModalContent>
    </ModalOverlay>
</template>

<style lang="scss">
    @import "@/style/variables";

    .modal-idle {
        h1 {
            color: $color-primary-darken;
            font-weight: 500;
        }

        .text {
            color: $color-input-text;
            font-family: Roboto, serif;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 30px;
            font-weight: 500;

            strong {
                color: $color-input-text;
                font-family: Roboto Medium, serif;
                font-size: 20px;
                letter-spacing: 0;
                line-height: 30px;
                font-weight: 500;
            }

            p:last-child {
                margin-top: 20px;
            }
        }
    }
</style>
