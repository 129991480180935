<template>
    <div>
        <div
            :class="{
                highlightHeader: toggle && item.degreeOfSeverity !== 3,
                highlightHighSeverity: toggle && item.degreeOfSeverity === 3,
                highSeverity: item.degreeOfSeverity === 3,
            }"
            v-tooltip="{
                content: item.is_nullified ? getMakuleradText(item) : '',
                placement: 'right',
            }"
            class="rectangle-copy-8 rectangle-copy-8-alert row mt-1 container container-alert padding-left"
        >
            <img
                v-if="!item.is_nullified"
                @click="toggleTextbox"
                alt="dropdown arrow"
                :class="{
                    disabledAlertInformation: !item.is_actual,
                    rotate: toggle,
                }"
                class="dropdownpil"
                src="@/assets/Shape.svg"
            />
            <p v-else></p>
            <p class="datum textIKort" :class="{ disabledAlertInformation: !item.is_actual }">
                {{ item.documentTime.substring(0, 10) }}
            </p>
            <p
                v-if="!item.is_nullified"
                class="alertSign textIKort"
                :class="{ disabledAlertInformation: !item.is_actual }"
            >
                {{ item.degreeOfSeverity === 3 ? "!" : " " }}
            </p>
            <p v-else class="alertSign textIKort"></p>
            <span
                v-if="!item.is_nullified"
                class="typ textIKort"
                :class="{ disabledAlertInformation: !item.is_actual }"
            >
                {{ translateAlertInformation(item.typeOfAlertInformation)
                }}<span v-if="item.degreeOfSeverity !== null" class="thinText"
                    >: {{ item.degreeOfSeverity !== null ? getDegreeText(item.degreeOfSeverity) : "" }}</span
                >
            </span>
            <p v-else class="typ textIKort">MAKULERAD</p>
            <p
                v-if="!item.is_nullified"
                class="ansvarig textIKort"
                :class="{ disabledAlertInformation: !item.is_actual }"
            >
                {{ item.accountableHealthcareProfessional.user.name }}
            </p>
            <p v-else class="ansvarig textIKort"></p>
            <p v-if="!item.is_nullified" class="enhet textIKort" :class="{ disabledAlertInformation: !item.is_actual }">
                ...
            </p>
            <p v-else class="enhet textIKort"></p>
            <p
                v-if="!item.is_nullified"
                class="aktuell textIKort"
                :class="{ disabledAlertInformation: !item.is_actual }"
            >
                {{ item.is_actual ? "Aktuell" : "Inaktuell" }}
            </p>
            <p v-else class="aktuell textIKort"></p>

            <status-widget
                v-if="item.is_nullified"
                status="makulerad"
                :class="{ disabledAlertInformation: !item.is_actual }"
            ></status-widget>
            <status-widget
                v-if="item.is_signed && !item.is_nullified"
                status="signerad"
                :class="{ disabledAlertInformation: !item.is_actual }"
            ></status-widget>
            <status-widget
                v-if="!item.is_nullified && !item.is_signed"
                status="osignerad"
                :class="{ disabledAlertInformation: !item.is_actual }"
            ></status-widget>

            <actions-dropdown
                v-if="!item.is_nullified"
                :id="item.pk"
                class="moreinfo"
                :actions="getActions(item)"
                @dropdownAction="dropdownAction"
            />
        </div>
        <transition name="slide">
            <component
                :class="{ disabledAlertInformation: !item.is_actual }"
                v-if="toggle && !item.is_nullified"
                class="textBoxComponent dropdownBody"
                :item="item"
                :key="item.pk"
                v-bind:is="item.typeOfAlertInformation"
            ></component>
        </transition>
    </div>
</template>

<script>
import communicableDiseaseTextbox from "@/tabs/AlertInformation/Textboxes/communicableDiseaseTextbox.vue";
import hyperSensitivityTextbox from "@/tabs/AlertInformation/Textboxes/hyperSensitivityTextbox.vue";
import restrictionOfCareTextbox from "@/tabs/AlertInformation/Textboxes/restrictionOfCareTextbox.vue";
import seriousDiseaseTextbox from "@/tabs/AlertInformation/Textboxes/seriousDiseaseTextbox.vue";
import treatmentTextbox from "@/tabs/AlertInformation/Textboxes/treatmentTextbox.vue";
import unstructuredTextbox from "@/tabs/AlertInformation/Textboxes/unstructuredTextbox.vue";
import ActionsDropdown from "@/components/generic/ActionsDropdown.vue";
import { mapActions, mapGetters } from "vuex";
import dialogEvents from "@/dialogEvents";
import MakuleraAlertInformation from "@/tabs/AlertInformation/MakuleraAlertInformation.vue";
import { flashMessage, openDialog } from "@/utils";
import { getErrorMessage } from "@/api";

import StatusWidget from "@/components/widgets/StatusWidget.vue";

export default {
    name: "AlertListComponent",
    props: ["item"],
    components: {
        CommunicableDisease: communicableDiseaseTextbox,
        HyperSensitivity: hyperSensitivityTextbox,
        RestrictionOfCare: restrictionOfCareTextbox,
        SeriousDisease: seriousDiseaseTextbox,
        Treatment: treatmentTextbox,
        UnstructuredAlertInformation: unstructuredTextbox,
        actionsDropdown: ActionsDropdown,
        StatusWidget,
    },
    data() {
        return {
            toggle: false,
        };
    },
    computed: {
        ...mapGetters("patientData", ["patient"]),
        ...mapGetters("userData", ["currentProfile"]),
    },
    methods: {
        ...mapActions("alertInformation", [
            "loadAlertInformationList",
            "setTimePeriodEndAlertInformation",
            "makuleraAlertInformation",
            "loadDetailsInformation",
        ]),
        toggleTextbox() {
            this.toggle = !this.toggle;
        },
        getActions(item) {
            let actions = [
                { name: "sign", title: "Signera", img: "sign.svg" },
                {
                    name: "not_actual",
                    title: "Markera som inaktuell",
                    img: "inaktuell.svg",
                },
                { name: "makulera", title: "Makulera", img: "delete.svg" },
            ];
            if (!item.is_actual) actions.splice(1, 1);
            return actions;
        },
        async loadDetails() {
            try {
                const response = await this.loadDetailsInformation({
                    pk: this.pk,
                    type: this.type,
                });
                if (response) {
                    this.details = response;
                }
            } catch (e) {
                openDialog(getErrorMessage(e), "error");
            }
        },
        async checkSensitivity() {
            if (this.item.typeOfSensitivity !== null) {
                switch (this.item.typeOfSensitivity) {
                    case "PharmaceuticalHyperSensitivity":
                        return "phs";
                    case "OtherHyperSensitivity":
                        return "ohs";
                }
            } else {
                return this.item.typeOfAlertInformation;
            }
        },
        async setAsInactual() {
            let type = await this.checkSensitivity();

            const data = {
                patient: this.patient.id,
                pk: this.item.pk,
                type: type,
            };
            const response = await this.setTimePeriodEndAlertInformation(data);
            if (response.status === 200) {
                flashMessage("Uppmärksamhetssignalen har satts som inaktuell");
                await this.reloadStoreAfterUpdates();
            }
        },
        async reloadStoreAfterUpdates() {
            try {
                await this.$store.dispatch("alertInformation/loadAlertInformationList", { patientId: this.patient.id });
                await this.$store.dispatch("alertInformation/loadAlertInformationSymbol", {
                    patientId: this.patient.id,
                });
            } catch (e) {
                // eslint-disable-next-line
                console.error(e);
            }
        },
        translateAlertInformation(name) {
            switch (name) {
                case "CommunicableDisease":
                    return "Smitta";
                case "RestrictionOfCare":
                    return "Avsteg från vårdrutin";
                case "HyperSensitivity":
                    return "Överkänslighet";
                case "Treatment":
                    return "Behandling";
                case "SeriousDisease":
                    return "Allvarlig sjukdom";
                case "UnstructuredAlertInformation":
                    return "Övrigt";
                default:
                    return name;
            }
        },
        getDegreeText(degree) {
            switch (degree) {
                case 1:
                    return "Besvärande";
                case 2:
                    return "Allvarlig";
                case 3:
                    return "Livshotande";
                default:
                    return "";
            }
        },
        getMakuleradText(item) {
            return `Makulerad av ${
                item.nullified_by_display.user.name ? item.nullified_by_display.user.name : "[Användare saknas]"
            } ${item.nullified_at ? item.nullified_at.substring(0, 10) : "[tid saknas]"} av orsaken: ${
                item.nullifiedReason ? item.nullifiedReason : "[Orsak saknas]"
            }`;
        },
        async dropdownAction(name) {
            let type = await this.checkSensitivity();
            switch (name) {
                case "sign":
                    if (this.item.is_signed) {
                        openDialog("Den här uppmärksamhetssignalen är redan signerad", "warning");
                    } else {
                        this.$emit("signListItem", this.item);
                    }
                    break;
                case "makulera":
                    dialogEvents.$emit("openPopup", {
                        title: "Makulera uppmärksamhetssignal",
                        component: MakuleraAlertInformation,
                        attachedProps: {
                            pk: this.item.pk,
                            type,
                        },
                    });
                    break;
                case "not_actual":
                    if (this.item.is_actual === false) {
                        openDialog("Den här uppmärksamhetssignalen är redan satt som inaktuell", "warning");
                        return;
                    } else {
                        dialogEvents.$emit("open", {
                            type: "warning",
                            description: "Är du säker på att du vill sätta uppmärksamhetssignalen som inaktuell?",
                            buttons: [
                                {
                                    title: "Ja, sätt som inaktuell!",
                                    type: "alert btn-primary",
                                    action: () => this.setAsInactual(),
                                },
                                {
                                    title: "Avbryt",
                                    type: "outline-secondary",
                                },
                            ],
                        });
                    }
                    break;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/style/_variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/buttons";
@import "bootstrap/scss/tables";
/* Bootstrap - End */

@import "@/style/_deprecated_main";

.textBoxComponent {
    width: 1015px;
}

.rectangle-copy-8-alert {
    align-items: center;
    height: 54px;
    background-color: #f3f5f9;
    transition: background-color 0.3s ease;
}

.rectangle-copy-8-alert:hover {
    background-color: lightblue;
}

.textIKort {
    height: 16px;
    color: #354052;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}

.thinText {
    font-family: Roboto;
    font-weight: 400;
}

.disabled {
    opacity: 0.7;
}

.disabledAlertInformation {
    opacity: 0.4;
}

.highSeverity {
    background-color: #faecf1;
}

.highlightHighSeverity {
    background-color: #f2d7e2;
}

.highSeverity:hover {
    background-color: #f7e3eb;
}

.highlightHeader {
    background-color: #e4e8f0;
}

.rotate {
    transform: rotate(180deg);
}

.container-alert {
    display: grid !important;
    height: 54px;
    grid-template-columns: 16px 83px 16px 206px 209px 205px 64px 73px 30px;
    grid-template-rows: 54px;
    column-gap: 8px;
}

.padding-left {
    padding-left: 8px;
}

.dropdownpil {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
    cursor: pointer;
}

.datum {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.alertSign {
    color: #ed1c24;
    font-family: Roboto Medium;
    font-size: 18px;
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.typ {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
    font-family: Roboto Medium;
}

.ansvarig {
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.enhet {
    grid-column-start: 6;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.aktuell {
    grid-column-start: 7;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.signeradcell {
    grid-column-start: 8;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 0;
}

.moreinfo {
    cursor: pointer;
    margin-left: 20px;
    grid-column-start: 9;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-bottom: 0;
}

.makuleradTableRow {
    background-color: lightgrey;
    text-decoration: line-through;
}

.makuleradRam {
    margin-left: 5px;
    height: 20px;
    width: 73px;
    border-radius: 4px;
    background-color: darkred;
    text-align: center;
}

.signedRam {
    margin-left: 5px;
    height: 20px;
    width: 73px;
    border-radius: 4px;
    background-color: #34ac8d;
    text-align: center;
}

.unsignedRam {
    margin-left: 5px;
    height: 20px;
    width: 73px;
    border-radius: 4px;
    background-color: #9bb3bb;
    text-align: center;
}

.makulerad {
    height: 19px;
    text-align: center;
    color: #ffffff;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
}

.signed {
    height: 19px;
    text-align: center;
    color: #ffffff;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
}

.unsigned {
    height: 19px;
    text-align: center;
    color: #ffffff;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
}

.slide-enter-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
    max-height: 250px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 2px 2px 7px 0 rgba(157, 166, 187, 0.5);
    border: 1px solid #999c9e !important;
    border-radius: 4px;
    flex: 1;
    margin: 0.125rem -20px 0 0 !important;
}

.dropdown-item {
    color: #6c8188 !important;
    padding: 17px 39px 18px 23px !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    line-height: 21px !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: #cee9f3 !important;
}

.dropdown-item > img {
    margin-right: 18px;
}

.textContainer {
    margin: auto;
}

::v-deep .newbox {
    margin-left: 16px;
    padding-top: 16px;
}
</style>
