const getDefaultState = () => {
    return {
        // En struktur för filer vi läser in via webbläsaren.
        lokalUppladdning: {
            index: 0,
            filer: [],
        },

        // En struktur för fil som vi hämtar från Backend
        redigering: {
            metaData: {
                accountableHealthcareProfessional: "",
                createdBy: "",
                dokumentDatum: "",
                dokumentTyp: 0,
                fileId: "",
                inlast: true,
                kommentar: "",
                legalAuthenticator: "",
                patientNamn: "",
                patientUUID: "",
                personId: "",
                personIdIsUnique: false,
                personIdIsValid: false,
            },
            formClean: true,
        },

        // Förevisas vår fullskärms-modal?
        modal: {
            forevisa: false,
        },
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        laggTillFilFranBrowser(state, file, index) {
            const data = {
                file,
                index,
                metaData: {
                    accountableHealthcareProfessional: "",
                    createdBy: "",
                    inmatningsDatum: new Date(),
                    dokumentDatum: "",
                    dokumentTyp: 0,
                    fileId: "",
                    index: index,
                    inlast: false, // indikerar inläst / inte inläst
                    kommentar: "",
                    legalAuthenticator: "",
                    patientNamn: "",
                    patientUUID: "",
                    personId: "",
                    personIdIsUnique: false,
                    personIdIsValid: false,
                },
                formClean: true,
            };
            state.lokalUppladdning.filer.push(data);
        },

        lokalUppladdningAngeIndex(state, index) {
            state.lokalUppladdning.index = index;
        },

        tomFilerFranBrowser(state) {
            state.lokalUppladdning.filer = [];
            state.lokalUppladdning.index = 0;
        },

        lokalUppladdingAngeDokumentDatum(state, { index, value }) {
            state.lokalUppladdning.filer[index].metaData.dokumentDatum = value;
        },

        lokalUppladdingAngeMetaData(state, { index, key, value }) {
            state.lokalUppladdning.filer[index].metaData[key] = value;
        },

        redigeringAngeMetaData(state, { key, value }) {
            state.redigering.metaData[key] = value;
        },

        modalVisa(state) {
            state.modal.forevisa = true;
        },
        modalDolj(state) {
            state.modal.forevisa = false;
        },

        lokalUppladdningSetFormDirty(state, { index }) {
            state.lokalUppladdning.filer[index].formClean = false;
        },

        lokalUppladdningSetFormClean(state, { index }) {
            state.lokalUppladdning.filer[index].formClean = true;
        },

        redigeringSetFormDirty(state) {
            state.redigering.formClean = false;
        },
        redigeringSetFormClean(state) {
            state.redigering.formClean = true;
        },
    },

    getters: {
        redigering: (state) => {
            return state.redigering.metaData;
        },

        lokalUppladdningIndex: (state) => {
            return state.lokalUppladdning.index;
        },

        lokalUppladdningDisplayIndex: (state) => {
            return state.lokalUppladdning.index + 1;
        },

        // lokalUppladdningFil: (state, index) => {
        //     return state.lokalUppladdning.filer[index];
        // },

        lokalUppladdningFormIsClean: (state) => {
            const index = state.lokalUppladdning.index;
            if (state.lokalUppladdning && state.lokalUppladdning.filer && state.lokalUppladdning.filer[index]) {
                return state.lokalUppladdning.filer[index].formClean;
            }
        },

        lokalUppladdningFormsIsClean: (state) => {
            if (state.lokalUppladdning && state.lokalUppladdning.filer) {
                return state.lokalUppladdning.filer.some((item) => item.formClean);
            }
            return true;
        },

        lokalUppladdningAntal: (state) => {
            return state.lokalUppladdning.filer.length;
        },

        lokalUppladdningAktuellFil: (state) => {
            return state.lokalUppladdning.filer[state.lokalUppladdning.index];
        },

        lokalUppladdningAktuellFilDok: (state) => {
            return state.lokalUppladdning.filer[state.lokalUppladdning.index];
        },

        lokalUppladdningInmatningsdatum: (state) => {
            return state.lokalUppladdning.filer[state.lokalUppladdning.index].metaData.inmatningsDatum
                .toISOString()
                .split("T")[0];
        },

        lokalUppladdningVisaNastaKnapp: (state) => {
            return state.lokalUppladdning.index < state.lokalUppladdning.filer.length - 1;
        },

        lokalUppladdningVisaForegaendeKnapp: (state) => {
            return state.lokalUppladdning.index > 0;
        },

        modalForevisa: (state) => {
            return state.modal.forevisa;
        },

        redigeringFormIsClean: (state) => {
            return state.redigering.formClean;
        },
    },

    actions: {
        // lokalUppladdingAngeDokumentDatum({ commit }, { index, value }) {
        //     commit("lokalUppladdningAngeDokumentDatum", { index, value });
        //     commit("lokalUppladdningSetFormDirty");
        // },

        laddaFilerFranBrowser({ commit }, { files }) {
            commit("tomFilerFranBrowser");
            Array.from(files).forEach((file, index) => {
                commit("laggTillFilFranBrowser", file, index);
            });
        },

        lokalUppladdningNastaFil({ commit, state }) {
            const newValue = Math.min(state.lokalUppladdning.index + 1, state.lokalUppladdning.filer.length - 1);
            commit("lokalUppladdningAngeIndex", newValue);
        },

        lokalUppladdningForegaendeFil({ commit, state }) {
            const newValue = Math.max(0, state.lokalUppladdning.index - 1);
            commit("lokalUppladdningAngeIndex", newValue);
        },

        lokalUppladdningKopieraDataFranForegaendeFil({ commit, state }) {
            const index = state.lokalUppladdning.index;
            // If we are at the first file do nothing.

            if (index > 0) {
                const previousMetadata = state.lokalUppladdning.filer[index - 1].metaData;
                const currentMetadata = state.lokalUppladdning.filer[index].metaData;

                const keys = [
                    "accountableHealthcareProfessional",
                    "dokumentDatum",
                    "dokumentTyp",
                    "kommentar",
                    "legalAuthenticator",
                    "personId",
                    "patientUUID",
                ];

                keys.forEach((key) => {
                    const currentValue = currentMetadata[key];
                    const previousValue = previousMetadata[key];

                    // Only copy from non empty fields to empty fields
                    if (previousValue && !currentValue) {
                        const value = previousValue;
                        commit("lokalUppladdingAngeMetaData", { index, key, value });
                    }
                });
            } else {
                // eslint-disable-next-line
            }
        },
    },
};
