<script>
export default {
    name: "NumberedSection",

    props: {
        number: {
            type: String,
            required: true,
        },
        heading: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<template>
    <div class="section">
        <div class="header">
            <h3>
                <div class="circle" :class="{ disabled: disabled }">
                    <div class="number">{{ number }}</div>
                </div>
                <div>
                    {{ heading }}
                </div>
            </h3>
        </div>
        <div class="outer">
            <div class="inner">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.section {
    .header {
        margin-bottom: 10px;

        div {
            display: inline-block !important;
        }

        .circle {
            height: 26px;
            width: 26px;
            background-color: #277692;
            border: 1px solid #277692;
            border-radius: 13px;
            position: relative;
            left: -13px;

            .number {
                height: 26px;
                width: 100%;
                color: #ffffff;
                font-family: Roboto;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 26px;
                text-align: center;
            }

            &.disabled {
                background-color: #ffffff;

                .number {
                    color: #277692;
                }
            }
        }
    }

    .outer {
        border-left: 1px solid #b9d8e3;

        .inner {
            padding-left: 26px;
        }
    }
}
</style>
