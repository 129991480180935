<template>
    <thead class="kliniken-table-headers">
        <tr>
            <th v-if="showCheckboxes">
                <InputCheckbox :checked="allChecked" @change="checkAll($event.target.checked)" class="input-checkbox" />
            </th>
            <th v-for="(item, index) in columns" :key="index">
                <template v-if="item.sort">
                    <span
                        @mouseover="mouseoverFunc(item.sort)"
                        @mouseleave="mouseleaveFunc(item.sort)"
                        @click="sort(item.sort)"
                    >
                        {{ item.title }}
                        <img :src="getIcon(item.sort)" />
                    </span>
                </template>
                <template v-else>
                    <span>{{ item.title }}</span>
                </template>
            </th>
            <th v-if="hasDropdown"></th>
        </tr>
    </thead>
</template>

<script>
import SortTableAscending from "@/assets/sort_folder/sort_table_ascending.svg";
import SortTableDescending from "@/assets/sort_folder/sort_table_descending.svg";
import SortTableHover from "@/assets/sort_folder/sort_table_hover.svg";
import SortTableEmpty from "@/assets/sort_folder/sort_table_empty.svg";
import InputCheckbox from "@/components/InputCheckbox.vue";

export default {
    name: "KlinikenTableHeaders",
    components: { InputCheckbox },

    props: {
        columns: {
            type: Array,
            required: true,
        },
        hasDropdown: {
            type: Boolean,
            default: false,
        },
        showCheckboxes: {
            type: Boolean,
            required: false,
            default: false,
        },
        allChecked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            sortField: "",
            direction: null,
            hover: false,
            iconHoverable: SortTableEmpty,
        };
    },

    methods: {
        mouseoverFunc(field) {
            this.sortField = field;
            this.hover = true;
        },
        mouseleaveFunc(field) {
            this.hover = false;
        },
        sort(field) {
            if (this.sortField === field) {
                this.direction = this.direction === "" ? "-" : "";
            } else {
                this.sortField = field;
                this.direction = "";
            }
            this.$emit("sort", `${this.direction}${this.sortField}`);
        },

        getIcon(field) {
            let icon = SortTableEmpty;
            if (this.sortField === field) {
                if (this.hover && this.direction !== "" && this.direction !== "-") {
                    icon = SortTableHover;
                } else if (this.direction === "") {
                    icon = SortTableDescending;
                } else if (this.direction === "-") {
                    icon = SortTableAscending;
                }
            }
            return icon;
        },

        checkAll(value) {
            this.$emit("checkAll", value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.kliniken-table-headers {
    tr {
        th {
            text-align: left;
            border-bottom: 0.5px solid $list-border-color;
            padding: 10px 0;

            .input-checkbox {
                margin: 8px;
            }

            span {
                margin-left: 8px;
            }
        }
    }

    span {
        white-space: nowrap;
        font-size: 14px;
        line-height: 16px;
        color: $color-text-header;
        align-items: center;
        padding: 2px;
        cursor: pointer;

        &:hover {
            background-color: $color-theah-background;
            border-radius: 5px;
            width: max-content;
        }

        img {
            margin-left: 5px;
        }
    }
}
</style>
