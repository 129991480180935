import Vue from "vue";

/**
 * Unlike linkEvents and dialogEvents, this eventBus also stores global data. Eventually the internal queue handling
 * of requiring confirmation from user before closing tabs and opening a new will be handled here.
 */

/**
 * When a user tries to open a patientbound tab without any patient selected, s/he can get redirected to PatientSearch.
 * If s/he selects a patient, he gets redirected back to the patientbound tab.
 * This is accomplished storing the initial tab selected here.
 */
let queue = null;

// Get queue and set to null. Replace with stack?
const getQueue = () => {
    let q = queue;
    queue = null;
    return q;
};
const setQueue = (q) => {
    queue = q;
};

export { setQueue, getQueue };

export default new Vue();
