import { klinikenApi, getErrorMessage } from "@/api";
import { openDialog } from "@/utils";

export class Services {
    klinikenApi = klinikenApi;
    getErrorMessage = getErrorMessage;
    openDialog = openDialog;

    constructor() {
        this.klinikenApi = klinikenApi;
        this.getErrorMessage = getErrorMessage;
        this.openDialog = openDialog;
    }

    async hämtaAllaPlaneradOperationer(patient) {
        let planerade_operationer = [];

        try {
            const response = await this.klinikenApi.get("/operation/planeringar/", {
                patient,
            });

            planerade_operationer = response.data.results;
        } catch (error) {
            this.openDialog(this.getErrorMessage(error), "error");
        }

        return planerade_operationer;
    }

    async hämtaAllaOplaneradeOperationer() {
        let oplaneradOperationer = [];

        await this.klinikenApi
            .get("/operation/anmalan/")
            .then((response) => {
                oplaneradOperationer = response.data.results;
            })
            .catch((e) => {
                this.openDialog(this.getErrorMessage(e), "error");
            });
        return oplaneradOperationer;
    }

    async postEnPlaneradOperation(patient, operationstid_start, operationstid_slut, anmalan, sal) {
        try {
            const response = await this.klinikenApi.post("/operation/planeringar/", {
                patient,
                operationstid_start,
                operationstid_slut,
                anmalan,
                sal,
            });
            return response;
        } catch (error) {
            this.openDialog(this.getErrorMessage(error), "error");
        }
    }

    async uppdateraExisterandePlaneradOperation(id, operationstid_start, operationstid_slut, sal, patient) {
        try {
            const response = await this.klinikenApi.put(`/operation/planeringar/${id}/`, {
                operationstid_start,
                operationstid_slut,
                sal,
                patient,
            });
            return response;
        } catch (error) {
            this.openDialog(this.getErrorMessage(error), "error");
        }
    }
}
