<template>
    <div class="suggest-wrap">
        <!--
    Important! keyup.esc.stop and keydown.enter.prevent necessary to prevent these keys from propagating to form
-->
        <input
            class="text-input"
            :class="{ 'disabled-option': disabledOption }"
            type="select"
            autocomplete="off"
            v-bind:value="text_"
            v-bind="$attrs"
            :required="required"
            :name="name + 'Display'"
            @click="isOpen = !isOpen"
            @keyup.esc.stop="isOpen = false"
            @keydown="prevent($event)"
            @keydown.down.prevent="moveDown"
            @keydown.up.prevent="moveUp"
            @keydown.enter.prevent="settle"
            @blur="
                isOpen = false;
                $emit('blur');
            "
            :disabled="disabled"
        />
        <!--  @wheel.prevent.stop="mouseWheel($event)" -->
        <input type="hidden" :name="name" v-bind:value="value_" />
        <ul ref="suggest-list" class="suggest-list" v-show="isOpen" @wheel="mouseWheel($event)">
            <li
                v-for="(option, index) in options_"
                :key="index"
                @mouseenter="highlightedPosition = index"
                @mousedown="select"
                :name="name + '-suggest'"
                class="suggest-item"
                :class="{
                    highlighted: index === highlightedPosition && option.disabled === false,
                    disabled: option.disabled === true,
                }"
            >
                {{ option.text || "&nbsp;" }}
            </li>
        </ul>
    </div>
</template>

<script>
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

export default {
    name: "SelectWidget",
    props: {
        options: Array, // Accepts an Array with format [{ value, text }, ... ]
        tabindex: Number,
        name: String,
        required: Boolean,
        maxlength: String,
        apisearch: String,
        extraParams: Object, // An object specifying additional parameters to be passed to backend, such as filters
        value: undefined, // Should be Object, but can be Number until backend catches up
        displayCode: Boolean, // if code should be shown or not
        /**
         * In case the data from backend is NOT a JSON object with the format
         * { id, code, displayName }, a map Function must be supplied by the parent
         */
        map: {
            type: Function,
            default: (item) => {
                return {
                    value: item.id,
                    text: item.displayName,
                };
            },
        },
        noResults: Boolean, // Whether backend response array wrapped in results or not
        eventHub: Object,
        disabled: Boolean,
    },
    data() {
        return {
            API_SEARCH: this.apisearch,
            searchTimeout: false,
            loading: false,
            isOpen: false,
            highlightedPosition: NaN,
            options_: this.options,
            value_: this.value, //TODO: this is potentially a problem when component is initialized with an object value, should be normalized
            firstLetter: "",
            indexOfElementFinded_: NaN,
        };
    },
    computed: {
        text_() {
            if (!this.options_) return "";
            let option = this.options_.find((el) => {
                return el.value === this.value_;
            });
            if (option) {
                return option.text;
            } else {
                return "";
            }
        },
        disabledOption() {
            if (this.options_ && this.options_.length > 0) {
                const option = this.options_.find((item) => {
                    return item.value === this.value_;
                }, this);

                return option ? option.disabled : false;
            }
        },
    },
    methods: {
        /**
         * @returns {boolean}
         */
        highlightedPositionNotEmpty() {
            return !isNaN(this.highlightedPosition);
        },

        /**
         * @returns {number}
         */
        indexOfElementFinded() {
            return this.indexOfElementFinded_;
        },

        /**
         * @param {number} value
         */
        setIndexOfElementFinded(value) {
            this.indexOfElementFinded_ = value;
        },

        /**
         * Option definition
         * @typedef {Object} Option
         * @property {string} text
         * @property {number} value
         */

        /**
         * @returns {Option}
         */
        highlightedOption() {
            return this.options_[this.highlightedPosition];
        },

        /**
         * @param {number} indexNum
         * @param {Event} event
         */
        toggleHighlightedMatchingOption(indexNum, event) {
            if (indexNum !== -1) {
                // togglehighlightingmatchingoption()

                /**
                 *  Should return an array with two objects if two words starts with same letters
                 *  allOptionsThatStartWithFirstLetter.length === 2 in that case
                 */
                const allOptionsThatStartWithFirstLetter = this.options_.filter((el) =>
                    el.text.startsWith(this.firstLetter)
                );

                /**
                 *  This condition is only true if you press the same keyboard key twice
                 *
                 */
                if (allOptionsThatStartWithFirstLetter.length > 1 && this.highlightedPositionNotEmpty()) {
                    if (this.highlightedOption().text.startsWith(this.firstLetter)) {
                        /**
                         *  returns 0 should also return the first object in allOptionsThatStartWithFirstLetter
                         *
                         */
                        this.setIndexOfElementFinded(
                            allOptionsThatStartWithFirstLetter.indexOf(this.options_[this.highlightedPosition])
                        );

                        if (this.indexOfElementFinded() == allOptionsThatStartWithFirstLetter.length - 1) {
                            this.setIndexOfElementFinded(0);
                        } else {
                            this.setIndexOfElementFinded(this.indexOfElementFinded() + 1);
                        }

                        this.highlightedPosition = this.options_.indexOf(
                            allOptionsThatStartWithFirstLetter[this.indexOfElementFinded()]
                        );
                        event.preventDefault();
                        return this.highlightedPosition;
                    }
                }
                this.highlightedPosition = indexNum;
            }
        },

        prevent(event) {
            /**
             * Should return 65 when pressing A keyboard key
             */
            const keyChar = event.charCode || event.keyCode;

            /**
             * Parses so that it returns the correct letter based on the keyChar'\n'
             * keyChar === 65 return letter A
             */
            this.firstLetter = String.fromCharCode(keyChar);

            /**
             *  returns 0 === options_[0]
             *  findIndex returns the index position of the chosen elements
             */
            const indexOfMatchedOption = this.options.findIndex((option) => option.text.startsWith(this.firstLetter));

            this.toggleHighlightedMatchingOption(indexOfMatchedOption, event);

            if (keyChar !== 9) {
                // Allow tab
                event.preventDefault();
            } else {
                return true;
            }
        },
        validate() {
            this.isOpen = false;
        },
        async fetchData() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            let options = [];
            await klinikenApi
                .get(this.API_SEARCH, getConfig({ params: this.extraParams }))
                .then((response) => {
                    options = this.noResults ? response.data.map(this.map) : response.data.results.map(this.map);
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                    return [];
                })
                .then(() => {
                    this.loading = false;
                    this.searchTimeout = false;
                });
            return options;
        },
        moveDown() {
            if (!this.isOpen) this.isOpen = true;
            if (isNaN(this.highlightedPosition)) this.highlightedPosition = 0;
            else this.highlightedPosition = (this.highlightedPosition + 1) % this.options_.length;
        },
        moveUp() {
            if (!this.isOpen) this.isOpen = true;
            this.highlightedPosition =
                this.highlightedPosition - 1 < 0 ? this.options_.length - 1 : this.highlightedPosition - 1;
        },
        select() {
            let selectedOption = this.options_[this.highlightedPosition];
            if (selectedOption.disabled === true) {
                return;
            }
            this.value_ = selectedOption.value;
            this.$emit("input", this.value_);
            this.$emit("update", selectedOption);
            this.isOpen = false;
        },
        settle(event) {
            if (this.isOpen && !isNaN(this.highlightedPosition)) {
                this.select();
            }
            event.stopPropagation();
        },
        updateValue() {
            this.isOpen = true;
            this.highlightedPosition = NaN;
        },
        async mouseWheel(event) {
            let hasScroll = false;
            let el = this.$refs["suggest-list"];
            await this.$nextTick(() => {
                hasScroll = el.scrollHeight > el.clientHeight;
            });
            if (!hasScroll) {
                event.preventDefault();
                event.stopPropagation();
                if (event.deltaY > 0) this.moveDown();
                else this.moveUp();
            }
        },
        normalizeValue(value) {
            if (typeof value === "object" && value !== null) {
                this.value_ = this.map(value).value;
                // update normalized value
                this.$emit("input", this.value_);
                this.$emit("update", this.map(value));
            } else this.value_ = value;
        },
    },
    async created() {
        if (this.API_SEARCH) {
            this.options_ = await this.fetchData();
        }
        this.normalizeValue(this.value);
    },
    watch: {
        // Since the value prop is decoupled from the input, this restores the reactive data binding to value prop.
        value() {
            this.normalizeValue(this.value);
        },
        options() {
            this.options_ = this.options;
            this.normalizeValue(this.value);
        },
        async apisearch() {
            if (this.apisearch) {
                this.options_ = await this.fetchData();
                this.normalizeValue(this.value);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/forms";
/* Bootstrap - End */

@import "@/style/deprecated_main";

input[type="select"] {
    background: url("@/assets/select_arrow.svg") no-repeat calc(100% - 18px),
        center linear-gradient(0deg, #f7fafa 2.81%, #ffffff 100%);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: default;
    caret-color: transparent;

    &.disabled-option {
        color: rgba(0, 0, 0, 0.4);
    }

    // &:disabled {
    //     background-color: #F0F3F8
    // }
}

.suggest-wrap {
    position: relative;
}

.suggest-list {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 48px;
    width: 100%;
    z-index: 101;
    background: #ffffff;
    padding: 0 !important;
    max-height: 390px;
    overflow-y: auto;
}

.suggest-item {
    list-style: none;
    margin: 0;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #eee;
    padding: 0.4rem;

    &:first-of-type {
        border-top: 1px solid #eee;
    }

    &.disabled {
        opacity: 0.4;
    }
}

.highlighted {
    background: #eee;
    cursor: pointer;
}

form .error input,
form .error textarea,
form .error select,
form input.error,
form select.error,
form textarea.error {
    border-bottom: 4px solid #9e354b !important;
}
</style>
