<template>
    <div class="filhantering-redigera-fil">
        <FilhanteringRedigeraFilForm v-if="dataLoaded" @closetab="closeTab" />
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import FilhanteringRedigeraFilForm from "./FilhanteringRedigeraFilForm.vue";
import { getConfig, klinikenApi } from "@/api";
import { mapGetters } from "vuex";

export default {
    extends: BaseTab,
    name: "FilhanteringRedigeraFil",
    tabName: "Redigera fil",
    patientBound: true,
    components: {
        FilhanteringRedigeraFilForm,
    },

    data() {
        return {
            dataLoaded: false,
        };
    },

    computed: {
        ...mapGetters("filhantering", ["redigeringFormIsClean"]),
        hasUnsavedData() {
            return !this.redigeringFormIsClean;
        },
    },

    methods: {
        closeTab() {
            this.$store.dispatch("tabs/closeTab", this.tabId);
        },

        async loadFileFromBackend(patientUUID, fileId) {
            // Hämta metadata från backend
            const metadataUrl = `filhantering/${patientUUID}/fil/${fileId}/`;
            const metadataResponse = await klinikenApi.get(metadataUrl, getConfig({}, "Filer"));

            const backendKeyToFrontend = {
                id: "fileId",
                patient: "patientUUID",
                documentTitle: "kommentar",
                // authorTime: "inmatningsDatum",
                created_by: "createdBy",
                created_by_full_name: "createdByFullName",
                // documentTime: "dokumentDatum",
                // authorTime: ""
                accountableHealthcareProfessional: "accountableHealthcareProfessional",
                legalAuthenticator: "legalAuthenticator",
                dokument_typ: "dokumentTyp",
                ursprungligt_filnamn: "ursprungligtFilnamn",
                filstorlek: "filstorlek",
                filformat: "filformat",
            };
            const backendKeys = [
                "authorTime",
                "accountableHealthcareProfessional",
                "created_by",
                "created_by_full_name",
                "documentTime",
                "documentTitle",
                "dokument_typ",
                "id",
                "legalAuthenticator",
                "patient",
                "ursprungligt_filnamn",
                "filstorlek",
                "filformat",
            ];

            backendKeys.forEach((backendKey) => {
                const key = backendKeyToFrontend[backendKey];
                const value = metadataResponse.data[backendKey];
                this.$store.commit("filhantering/redigeringAngeMetaData", { key, value });
            });

            // dokumentTime -> dokumentDatum
            if (metadataResponse.data.documentTime) {
                const value = metadataResponse.data.documentTime.substring(0, 10);
                this.$store.commit("filhantering/redigeringAngeMetaData", { key: "dokumentDatum", value });
            }

            // authorTime -> inmatningsDatum
            if (metadataResponse.data.authorTime) {
                const value = metadataResponse.data.authorTime.substring(0, 10);
                this.$store.commit("filhantering/redigeringAngeMetaData", { key: "inmatningsDatum", value });
            }

            // Hämta patient data from backend
            const patientUrl = `core/patienter/${patientUUID}/`;
            const patientResponse = await klinikenApi.get(patientUrl, getConfig());

            const patientKeys = ["personId", "personIdType", "fornamn", "efternamn"];

            patientKeys.forEach((key) => {
                this.$store.commit("filhantering/redigeringAngeMetaData", {
                    key,
                    value: patientResponse.data[key],
                });
            });

            // Förevisa komponenten
            this.dataLoaded = true;
        },
        async loadData(data) {
            this.dataLoaded = false;
            await this.loadFileFromBackend(data.patient, data.id);
            this.$store.commit("filhantering/redigeringSetFormClean");
        },
    },

    async created() {
        if (this.parameters) {
            await this.loadData(this.parameters);
        }

        // Listen for events from FilhanteringFiler telling us
        // that we need to display another file
        this.$root.$on("file-in-editing-tab-exchanged", (data) => {
            this.loadData(data.parameters);
        });
    },
};
</script>
