<template>
    <div class="hover">
        <svg
            width="35px"
            height="33px"
            viewBox="0 0 35 33"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <g
                v-if="
                    !alertInformationSymbol.unstructured &&
                    !alertInformationSymbol.communicableDisease &&
                    !alertInformationSymbol.treatment &&
                    !alertInformationSymbol.seriousDisease &&
                    !alertInformationSymbol.restrictionOfCare &&
                    !alertInformationSymbol.hyperSensitivity
                "
                id="Uppmärksamhetssignal"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linejoin="round"
            >
                <g id="Uppmärksamhetssignaler" transform="translate(-721.000000, -694.000000)" stroke="#FFFFFF">
                    <g id="Group" transform="translate(721.000000, 693.000000)">
                        <g id="vit-fyllning-copy" transform="translate(0.970252, 2.000000)">
                            <polygon
                                points="11.3614301 7 11.3614301 0 21.8204409 0 21.8204409 7.11953733 28.4321742 3.44605859 33.0297481 11.9556857 27.1592163 15.5 33.0297481 19 28.4321742 27.3293156 21.8838675 23.7158313 21.8838675 31 11.3614301 31 11.3614301 23.7158313 4.81689719 27.3293156 0.460175894 19 6.34898392 15.5 7.69838905e-12 11.9556857 4.81689719 3.44605859"
                            ></polygon>
                        </g>
                    </g>
                </g>
            </g>

            <g v-else id="Uppmärksamhetssignal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Uppmärksamhetssignaler" transform="translate(-801.000000, -694.000000)">
                    <g transform="translate(801.000000, 693.000000)">
                        <g
                            class="bakgrund"
                            id="vit-fyllning-copy"
                            transform="translate(0.970252, 2.000000)"
                            fill="#FFFFFF"
                        >
                            <polygon
                                points="11.3614301 7 11.3614301 0 21.8204409 0 21.8204409 7.11953733 28.4321742 3.44605859 33.0297481 11.9556857 27.1592163 15.5 33.0297481 19 28.4321742 27.3293156 21.8838675 23.7158313 21.8838675 31 11.3614301 31 11.3614301 23.7158313 4.81689719 27.3293156 0.460175894 19 6.34898392 15.5 7.69838905e-12 11.9556857 4.81689719 3.44605859"
                            ></polygon>
                        </g>
                        <g
                            class="kontur"
                            id="Group"
                            transform="translate(0.970252, 2.000000)"
                            stroke="#FFFFFF"
                            stroke-linejoin="round"
                        >
                            <g id="vit-fyllning-copy">
                                <polygon
                                    points="11.3614301 7 11.3614301 0 21.8204409 0 21.8204409 7.11953733 28.4321742 3.44605859 33.0297481 11.9556857 27.1592163 15.5 33.0297481 19 28.4321742 27.3293156 21.8838675 23.7158313 21.8838675 31 11.3614301 31 11.3614301 23.7158313 4.81689719 27.3293156 0.460175894 19 6.34898392 15.5 7.69838905e-12 11.9556857 4.81689719 3.44605859"
                                ></polygon>
                            </g>
                        </g>
                        <g
                            v-if="alertInformationSymbol.unstructured"
                            class="unstructured"
                            id="Rectangle-Copy-5"
                            transform="translate(1.918221, 7.182441)"
                            fill="#F01616"
                        >
                            <polygon
                                transform="translate(6.500000, 5.372507) rotate(-60.000000) translate(-6.500000, -5.372507) "
                                points="3 1 10 1 10 7.52614211 6.44128868 9.7450136 3 7.52614211"
                            ></polygon>
                        </g>
                        <g
                            v-if="alertInformationSymbol.communicableDisease"
                            class="communicableDisease"
                            id="Rectangle-Copy-7"
                            transform="translate(1.918221, 16.844836)"
                            fill="#F7A71C"
                        >
                            <polygon
                                transform="translate(6.500000, 5.372507) scale(1, -1) rotate(-60.000000) translate(-6.500000, -5.372507) "
                                points="3 1 10 1 10 7.52614211 6.44128868 9.7450136 3 7.52614211"
                            ></polygon>
                        </g>
                        <g
                            v-if="alertInformationSymbol.treatment || alertInformationSymbol.seriousDisease"
                            class="treatmentAndDiseases"
                            id="Rectangle-Copy-6"
                            transform="translate(20.336701, 7.182441)"
                            fill="#ED1C24"
                        >
                            <polygon
                                transform="translate(6.500000, 5.372507) scale(-1, 1) rotate(-60.000000) translate(-6.500000, -5.372507) "
                                points="3 1 10 1 10 7.52614211 6.44128868 9.7450136 3 7.52614211"
                            ></polygon>
                        </g>
                        <g
                            v-if="alertInformationSymbol.restrictionOfCare"
                            class="restrictionOfCare"
                            id="Rectangle-Copy-8"
                            transform="translate(20.336701, 16.844836)"
                            fill="#517EEA"
                        >
                            <polygon
                                transform="translate(6.500000, 5.372507) scale(-1, -1) rotate(-60.000000) translate(-6.500000, -5.372507) "
                                points="3 1 10 1 10 7.52614211 6.44128868 9.7450136 3 7.52614211"
                            ></polygon>
                        </g>
                        <!-- degree 1? -->
                        <g
                            v-if="alertInformationSymbol.hyperSensitivity"
                            class="hyperSensitivity"
                            id="Oval"
                            transform="translate(14.000000, 24.000000)"
                            fill="#F01616"
                        >
                            <circle cx="3.5" cy="3.5" r="3.5"></circle>
                        </g>
                        <!-- degree of severity här -->
                        <!-- degree 2? -->
                        <g
                            v-if="
                                alertInformationSymbol.degreeOfSeverity === 2 ||
                                alertInformationSymbol.degreeOfSeverity === 3
                            "
                            class="sensDegree2"
                            id="Group"
                            transform="translate(14.000000, 15.000000)"
                            fill="#F01616"
                        >
                            <rect id="Rectangle" x="0" y="6" width="7" height="2"></rect>
                            <rect id="Rectangle-Copy-9" x="0" y="3" width="7" height="2"></rect>
                            <rect id="Rectangle-Copy-10" x="0" y="0" width="7" height="2"></rect>
                        </g>
                        <!-- degree 3? -->
                        <g
                            v-if="alertInformationSymbol.degreeOfSeverity === 3"
                            class="sensDegree3"
                            id="Rectangle-Copy-11"
                            transform="translate(14.000000, 4.000000)"
                            fill="#F01616"
                        >
                            <rect x="0" y="0" width="7" height="10"></rect>
                        </g>
                        <!-- degree of severity slutar här -->
                    </g>
                </g>
            </g>
        </svg>
    </div>
    <!-- <div v-else>
       <svg width="35px" height="33px" viewBox="0 0 35 33" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
         <g id="Uppmärksamhetssignal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
            stroke-linejoin="round">
           <g id="Uppmärksamhetssignaler" transform="translate(-721.000000, -694.000000)" stroke="#FFFFFF">
             <g id="Group" transform="translate(721.000000, 693.000000)">
               <g id="vit-fyllning-copy" transform="translate(0.970252, 2.000000)">
                 <polygon
                     points="11.3614301 7 11.3614301 0 21.8204409 0 21.8204409 7.11953733 28.4321742 3.44605859 33.0297481 11.9556857 27.1592163 15.5 33.0297481 19 28.4321742 27.3293156 21.8838675 23.7158313 21.8838675 31 11.3614301 31 11.3614301 23.7158313 4.81689719 27.3293156 0.460175894 19 6.34898392 15.5 7.69838905e-12 11.9556857 4.81689719 3.44605859"></polygon>
               </g>
             </g>
           </g>
         </g>
       </svg>
     </div>-->
</template>

<script>
export default {
    name: "AlertInformationWidget",
    props: ["alertInformationSymbol", "patientData"],
};
</script>

<style scoped></style>
