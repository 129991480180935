import { setJWT, setProfileHeader, setRefreshToken } from "@/api";

const noNull = (str) => {
    return str || "";
};

const getDefaultState = () => {
    return {
        currentProfileId: null,
        currentProfile: null,
        userData: {},
        user: {},
        profiles: [],
        loginMethod: null,
        permissions: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setProfiles(state, profiles) {
            state.profiles = profiles;
        },
        setUserData(state, userData) {
            state.userData = userData;
        },
        setCurrentProfileId(state, id) {
            state.currentProfileId = id;
            state.currentProfile = state.profiles.find((el) => state.currentProfileId === el.pk);
            setProfileHeader(id);
            if (process.env.NODE_ENV === "development") {
                sessionStorage.setItem("ProfileId", id);
            }
        },
        setLoginMethod(state, method) {
            state.setLoginMethod = method;
        },
        setUser(state, user) {
            state.user = user;
        },
        setPermissions(state, permissions) {
            state.permissions = permissions;
        },
    },
    getters: {
        personId(state) {
            return state.userData.personId || "[Saknas]";
        },
        fullName(state) {
            return state.userData.first_name || state.userData.last_name
                ? noNull(state.userData.first_name) + " " + noNull(state.userData.last_name)
                : "Användare saknas";
        },
        initials(state) {
            return state.userData.first_name || state.userData.last_name
                ? noNull(state.userData.first_name).slice(0, 1) + noNull(state.userData.last_name).slice(0, 1)
                : "--";
        },
        username(state) {
            return state.userData.username;
        },
        HSAId(state) {
            return state.userData.HSAId;
        },
        arbKod(state) {
            return state.currentProfile.orgUnit.arbetsplatskod;
        },
        currentProfileId(state) {
            return state.currentProfileId;
        },
        currentProfile(state) {
            return state.currentProfile;
        },
        /**
         * Occupation of the current profile
         */
        occupation(state) {
            return state.currentProfile.yrkeskategori ? state.currentProfile.yrkeskategori.displayName : "Annat";
        },
        is_superuser(state) {
            return state.user.is_superuser;
        },
        is_verksamhetsadmin(state) {
            return state.user.is_verksamhetsadmin;
        },
        enhetsadministrator(state) {
            return state.currentProfile.enhetsadministrator;
        },
        profiles(state) {
            return state.profiles;
        },
        activeProfiles(state) {
            return state.profiles.filter((el) => el.is_active);
        },
        user(state) {
            return state.userData;
        },
    },
    actions: {
        loadProfiles({ commit }, { profiles }) {
            commit("setProfiles", profiles);
            if (profiles.length > 0) commit("setUserData", profiles[0].user);
            if (profiles.length > 0) commit("setUser", profiles[0]);
        },
        logout({ commit, rootState }) {
            sessionStorage.removeItem("Refresh");
            sessionStorage.removeItem("ProfileId");
            sessionStorage.removeItem("AuthenticationMethod");
            sessionStorage.removeItem("PatientId");
            setRefreshToken(null);
            setJWT(null);
            setProfileHeader(null);

            let modules = Object.keys(rootState);
            modules.forEach((el) => {
                commit(el + "/reset", null, { root: true });
            });
        },
    },
};
