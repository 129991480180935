import { cloneDeep } from "lodash";
import { getConfig, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

const getDefaultStatistikState = () => {
    return {
        internalSkadekort: [],
        externalSkadekort: [],
        allaSkadekort: [],
        idrotter: [],
        ligor: [],
        idrott: "",

        lokalisationDataObject: {},
        skademekanismDataObject: {},
        skadetypDataObject: {},

        liga: "Alla ligor",
    };
};
export default {
    namespaced: true,
    state: getDefaultStatistikState(),
    getters: {
        getInternalSkadekort: (state) => state.internalSkadekort,
        getExternalSkadekort: (state) => state.externalSkadekort,
        getAllaSkadekort: (state) => state.allaSkadekort,
        getIdrotter: (state) => state.idrotter,
        getLigor: (state) => state.ligor,
        getIdrott: (state) => state.idrott,
        getLiga: (state) => state.liga,

        //get olika skadekortobject för charts
        getLokalisationDataObject: (state) => state.lokalisationDataObject,
        getSkademekanismDataObject: (state) => state.skademekanismDataObject,
        getSkadetypDataObject: (state) => state.skadetypDataObject,
    },
    mutations: {
        setInternalSkadekort: (state, payload) => (state.internalSkadekort = payload),
        setExternalSkadekort: (state, payload) => (state.externalSkadekort = payload),
        setAllaSkadekort: (state, payload) => (state.allaSkadekort = payload),
        setIdrotter: (state, payload) => (state.idrotter = payload),
        setLigor: (state, payload) => (state.ligor = payload),
        setIdrott: (state, payload) => (state.idrott = payload),
        setLiga: (state, payload) => (state.liga = payload),

        // set DataObject
        setLokalisationDataObject: (state, payload) => (state.lokalisationDataObject = payload),
        setSkademekanismDataObject: (state, payload) => (state.skademekanismDataObject = payload),
        setSkadetypDataObject: (state, payload) => (state.skadetypDataObject = payload),
        setClearStatistik: (state) => Object.assign(state, getDefaultStatistikState()),
    },
    actions: {
        async fetchIdrotter({ commit }) {
            try {
                const _idrotterResponser = await klinikenApi.get(`/skreg/statistik/idrotter/`, getConfig());
                commit("setIdrotter", _idrotterResponser.data);
            } catch (error) {
                openDialog("Det gick inte att hämta skadekort.", "error");
            }
        },
        async fetchLigor({ commit, state }, idrott) {
            try {
                const response = await klinikenApi.get(
                    `skreg/statistik/idrotter/${idrott}/skadekort/falt/Aktuell--liga/alternativ/`,
                    getConfig()
                );
                commit("setLigor", [...response.data, "Ej angiven"]);
            } catch (error) {
                openDialog("Det gick inte att hämta skadekort.", "error");
            }
        },
        async setIdrott({ commit }, idrott) {
            commit("setIdrott", idrott);
        },
        async setLiga({ commit }, liga) {
            commit("setLiga", liga);
        },
        async fetchSkadekort({ dispatch, commit, state }, { from, to, idrott, liga, chartOptions }) {
            let _lokalisation;
            let _skademekanism;
            let _skadetyper;
            let _ext_lokalisation;
            let _ext_skademekanism;
            let _ext_skadetyper;
            const _liga = liga == "Alla ligor" ? "" : liga;

            try {
                const response = await klinikenApi.get(
                    `/skreg/statistik/idrotter/${idrott}/skadekort/internal/`,
                    getConfig({
                        params: {
                            startDate: from !== null ? from : "",
                            endDate: to !== null ? to : "",
                            liga: _liga,
                        },
                    })
                );
                const externaResponse = await klinikenApi.get(
                    `/skreg/statistik/idrotter/${idrott}/skadekort/external/`,
                    getConfig({
                        params: {
                            startDate: from !== null ? from : "",
                            endDate: to !== null ? to : "",
                            liga: _liga,
                        },
                    })
                );
                commit("setLiga", liga);
                response.data.forEach((report) => {
                    if (report.statistiknamn === "Lokalisation") {
                        _lokalisation = cloneDeep(report);
                    } else if (report.statistiknamn === "Skademekanism") {
                        _skademekanism = cloneDeep(report);
                    } else if (report.statistiknamn === "Skadetyp") {
                        _skadetyper = cloneDeep(report);
                    }
                });
                externaResponse.data.forEach((report) => {
                    if (report.statistiknamn === "Lokalisation") {
                        _ext_lokalisation = cloneDeep(report);
                    } else if (report.statistiknamn === "Skademekanism") {
                        _ext_skademekanism = cloneDeep(report);
                    } else if (report.statistiknamn === "Skadetyp") {
                        _ext_skadetyper = cloneDeep(report);
                    }
                });
                const [ext_l_a, ext_l_b] = _ext_lokalisation.datasets;
                const [ext_o_a, ext_o_b] = _ext_skademekanism.datasets;
                const [ext_t_a, ext_t_b] = _ext_skadetyper.datasets;

                _lokalisation.datasets.push(ext_l_a);
                _lokalisation.datasets.push(ext_l_b);

                _skademekanism.datasets.push(ext_o_a);
                _skademekanism.datasets.push(ext_o_b);

                _skadetyper.datasets.push(ext_t_a);
                _skadetyper.datasets.push(ext_t_b);

                dispatch("sortDataObjects", {
                    lokalisation: _lokalisation,
                    skademekanism: _skademekanism,
                    skadetyper: _skadetyper,
                    chartOptions: chartOptions,
                });
            } catch (error) {
                openDialog("Det gick inte att hämta skadekort.", "error");
            }
        },
        async sortDataObjects({ dispatch, commit }, { lokalisation, skademekanism, skadetyper, chartOptions }) {
            let _lokalisationDataObject = {};
            let _skademekanismDataObject = {};
            let _skadetyperDataObject = {};

            _lokalisationDataObject = await dispatch("makeChartDataObject", {
                dataobject: lokalisation,
                chartOptions: chartOptions,
            });
            _skademekanismDataObject = await dispatch("makeChartDataObject", {
                dataobject: skademekanism,
                chartOptions: chartOptions,
            });
            _skadetyperDataObject = await dispatch("makeChartDataObject", {
                dataobject: skadetyper,
                chartOptions: chartOptions,
            });

            commit("setLokalisationDataObject", _lokalisationDataObject);
            commit("setSkademekanismDataObject", _skademekanismDataObject);
            commit("setSkadetypDataObject", _skadetyperDataObject);
        },
        async makeChartDataObject({}, { dataobject, chartOptions }) {
            let _chartData = {
                chartData: {
                    labels: dataobject.alternativ,
                    datasets: [],
                },
            };

            dataobject.datasets.forEach((dataset, index) => {
                _chartData.chartData.datasets.push({ data: dataset.antal, ...chartOptions[index] });
                _chartData.dates = { from: dataobject.skadedatum_min, to: dataobject.skadedatum_max };
                _chartData.statistiknamn = dataobject.statistiknamn;
                _chartData.chartDataArr = dataobject.datasets;
            });
            return _chartData;
        },
        clearStatistik({ commit }) {
            commit("setClearStatistik");
        },
    },
};
