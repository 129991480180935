<template>
    <div class="operationskalender">
        <div class="sidebar-div" :style="toggleSideBar === false ? 'display: none' : 'display: block'">
            <div class="close-sidebar-row">
                <img
                    class="close-sidebar-icon"
                    src="@/assets/close_grey.svg"
                    alt="close icon"
                    @click="toggleSideBar = !toggleSideBar"
                />
            </div>
            <div class="sidebar-section sidebar-title-row">
                <span class="sidebar-title">Filtrering</span>
                <hr class="sidebar-divider" />
            </div>
            <div class="sidebar-section sidebar-opsalar">
                <label class="filter-option">
                    <input
                        type="radio"
                        :checked="currentFilterOption === 'SALAR' && allFilterResourceOptionsAreShown(opsalar)"
                        @change="toggleFilterOption('SALAR')"
                    />
                    <div class="checkbox-frame">
                        <img class="checkmark-dark" src="@/assets/checkmark_dark.svg" />
                    </div>
                    <span class="section-title">Operationssalar</span>
                </label>
                <hr class="sidebar-divider" />
                <template v-if="currentFilterOption === 'SALAR'">
                    <label v-for="sal in opsalar" class="filter-resource-option">
                        <input type="checkbox" :checked="sal.show" @change="toggleShowResourceOption(sal)" />
                        <div class="checkbox-frame" :style="{ backgroundColor: sal.backgroundColor }">
                            <img class="checkmark-light" src="@/assets/checkmark_light.svg" />
                        </div>
                        <span class="checkbox-label">{{ sal.title }}</span>
                    </label>
                </template>
            </div>
            <div class="sidebar-section sidebar-operatorer">
                <label class="filter-option">
                    <input
                        type="radio"
                        :checked="
                            currentFilterOption === 'OPERATORER' && allFilterResourceOptionsAreShown(huvudoperatorer)
                        "
                        @change="toggleFilterOption('OPERATORER')"
                    />
                    <div class="checkbox-frame">
                        <img class="checkmark-dark" src="@/assets/checkmark_dark.svg" />
                    </div>
                    <span class="section-title">Operatörer</span>
                </label>
                <hr class="sidebar-divider" />
                <template v-if="currentFilterOption === 'OPERATORER'">
                    <label v-for="operator in huvudoperatorer" class="filter-resource-option">
                        <input type="checkbox" :checked="operator.show" @change="toggleShowResourceOption(operator)" />
                        <div class="checkbox-frame" :style="{ backgroundColor: operator.backgroundColor }">
                            <img class="checkmark-light" src="@/assets/checkmark_light.svg" />
                        </div>
                        <span class="checkbox-label">{{ operator.title }}</span>
                    </label>
                </template>
            </div>
            <div class="sidebar-section sidebar-lateralitet">
                <label class="filter-option">
                    <input
                        type="radio"
                        :checked="
                            currentFilterOption === 'LATERALITET' && allFilterResourceOptionsAreShown(lateraliteter)
                        "
                        @change="toggleFilterOption('LATERALITET')"
                    />
                    <div class="checkbox-frame">
                        <img class="checkmark-dark" src="@/assets/checkmark_dark.svg" />
                    </div>
                    <span class="section-title">Lateralitet</span>
                </label>
                <hr class="sidebar-divider" />
                <template v-if="currentFilterOption === 'LATERALITET'">
                    <label v-for="lat in lateraliteter" class="filter-resource-option">
                        <input type="checkbox" :checked="lat.show" @change="toggleShowResourceOption(lat)" />
                        <div class="checkbox-frame" :style="{ backgroundColor: lat.backgroundColor }">
                            <img class="checkmark-light" src="@/assets/checkmark_light.svg" />
                        </div>
                        <span class="checkbox-label">{{ lat.title }}</span>
                    </label>
                </template>
            </div>
        </div>
        <div class="calendar-container" :style="toggleSideBar === false ? 'margin-left: 0px !important' : ''">
            <div class="page-wrapper">
                <div class="header-section">
                    <div class="header-and-info">
                        <h2>Operationskalender</h2>
                        <h3 class="date-text">{{ getViewTitle }}</h3>
                        <span class="calendar-info">
                            <img class="info-img" src="@/assets/info_dark.svg" alt="operation" />
                            &nbsp;Dra och släpp operationer från Operationskoordinering i en annan flik till
                            Operationskalender.
                        </span>
                    </div>

                    <div class="header-section-left">
                        <div class="k3-calendar-toolbar">
                            <div
                                class="filtrering-tab"
                                :class="{
                                    'active-item-month': toggleSideBar === true,
                                }"
                            >
                                <img class="filter-img" src="@/assets/filter.svg" alt="operation" />
                                <button
                                    class="tab-button-left"
                                    :class="{
                                        'active-tab': toggleSideBar,
                                    }"
                                    @click="toggleSideBar = !toggleSideBar"
                                >
                                    Filtrering
                                </button>
                            </div>

                            <div v-if="canSparraTid" class="sparra-tid-tab">
                                <img class="sparra-tid-img" src="@/assets/block_time.svg" alt="operation" />
                                <button
                                    class="tab-button-left"
                                    :class="{ 'active-tab': showSparraTid }"
                                    @click="sparraTid"
                                >
                                    Spärra tid
                                </button>
                            </div>

                            <div></div>

                            <div class="tidslinje-tab">
                                <button
                                    @click="timelineView"
                                    class="view-button"
                                    :class="{
                                        'active-tab': isTimelineView,
                                    }"
                                >
                                    <span class="opsal-text"> Tidslinje </span>
                                </button>
                            </div>

                            <div class="opsal-tab" :class="{ 'active-item-month': isResourceView }">
                                <button
                                    @click="opSalView"
                                    class="view-button"
                                    :class="{
                                        'active-tab': isResourceView,
                                    }"
                                >
                                    <span class="opsal-text"> Opsal </span>
                                </button>
                            </div>

                            <div class="month-margins" :class="{ 'active-item-month': isMonth }">
                                <button
                                    @click="changeView('dayGridMonth')"
                                    class="view-button"
                                    :class="{ 'active-tab': isMonth }"
                                >
                                    Månad
                                </button>
                            </div>

                            <div class="week-margins" :class="{ 'active-item-month': isWeek }">
                                <button
                                    @click="changeView('timeGridWeek')"
                                    class="view-button"
                                    :class="{ 'active-tab': isWeek }"
                                >
                                    Vecka
                                </button>
                            </div>

                            <div
                                class="day-margins"
                                :class="{
                                    'active-item-month': isDay,
                                }"
                            >
                                <button
                                    @click="changeView('timeGridDay')"
                                    class="view-button"
                                    :class="{ 'active-tab': isDay }"
                                >
                                    Dag
                                </button>
                            </div>

                            <div class="today-margins" :class="{ 'active-item-month': isToday }">
                                <button @click="today" class="view-button" :class="{ 'active-tab': isToday }">
                                    Idag
                                </button>
                            </div>

                            <div class="arrow-buttons">
                                <div class="left-arrow-margins">
                                    <button class="arrow-button" @click="previous">
                                        <img src="@/assets/arrow_left_white.svg" alt="arrow-left" />
                                    </button>
                                </div>

                                <div class="right-arrow-margins">
                                    <button class="arrow-button" @click="next">
                                        <img src="@/assets/arrow_right_white.svg" alt="arrow-right" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="dagsinformation" v-if="isTimelineView || isResourceView || isDay">
                            <FormulateInput
                                type="checkbox"
                                label="Markera som klar"
                                v-model="planerad"
                                @change="savePlanerad"
                                :disabled="!canUpdateDagsinformation"
                            />
                            <hr />
                            <Button variant="hollow" size="small" @click="showKommentera = !showKommentera">
                                <img v-if="dagsinformation?.kommentar" src="@/assets/comment_filled.svg" />
                                <img v-else src="@/assets/comment_empty.svg" />
                                <span> Kommentera</span>
                            </Button>
                        </div>
                    </div>
                </div>
                <div v-if="isTimelineView && dayHasAllDayEvents" class="calendar-info timeline-all-day-info">
                    <img class="info-img" src="@/assets/warning_dark.svg" alt="operation" />
                    <span>
                        &nbsp;Obs: Det finns operationer på detta datum som inte visas i denna vy då de inte har någon
                        angiven tidpunkt.
                    </span>
                </div>
                <FullCalendar :options="calendarOptions" ref="fullCalendar">
                    <template v-slot:eventContent="arg">
                        <template v-if="arg.event.extendedProps.sparradTid">
                            <template v-if="arg.view.type === 'dayGridMonth'">
                                <div class="sparrad-tid-info sparrad-tid-info-nano">
                                    <span>{{ formatStartTime(arg.event) }}</span>
                                    <span>{{ arg.event.extendedProps.orsak?.namn }}</span>
                                </div>
                            </template>
                            <template
                                v-else-if="
                                    arg.event.extendedProps.duration < 75 || arg.view.type === 'resourceTimelineDay'
                                "
                            >
                                <div
                                    v-if="arg.view.type === 'resourceTimelineDay'"
                                    class="sparrad-tid-info sparrad-tid-info-timeline"
                                >
                                    <span>{{ arg.event.extendedProps.orsak?.namn }}</span>
                                    <span>&nbsp;{{ arg.event.extendedProps.sal?.namn }}</span>
                                </div>
                                <div
                                    v-else
                                    class="sparrad-tid-info"
                                    :class="{
                                        'sparrad-tid-info-tiny': arg.event.extendedProps.duration < 31,
                                        'sparrad-tid-info-small': arg.event.extendedProps.duration >= 31,
                                    }"
                                >
                                    <span>{{ arg.event.extendedProps.orsak?.namn }}</span>
                                    <span>{{ arg.event.extendedProps.sal?.namn }}</span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="sparrad-tid-time-full">
                                    <span>{{ formatSalstid(arg.event.start, arg.event.end) }}</span>
                                </div>
                                <div class="sparrad-tid-info sparrad-tid-info-full">
                                    <span>{{ arg.event.extendedProps.orsak?.namn }}</span>
                                    <span>{{ arg.event.extendedProps.sal?.namn }}</span>
                                </div>
                            </template>
                        </template>

                        <template v-else>
                            <VMenu
                                @click.native="bytpatient(arg.event.extendedProps.patientId)"
                                :triggers="[]"
                                :shown="arg.event.extendedProps.showPopup"
                                placement="auto"
                                :autoHide="true"
                            >
                                <!-- Tidslinjevy - visar personId och opkod -->
                                <div
                                    v-if="arg.view.type === 'resourceTimelineDay' && !arg.event.allDay"
                                    class="k3-custom-event-container timeline-event"
                                >
                                    <div class="k3-custom-event-info" :style="{ color: arg.event.textColor }">
                                        <span>{{ arg.event.extendedProps.opkod }}</span>
                                        <span>&nbsp;{{ arg.event.extendedProps.personId }}</span>
                                    </div>
                                    <div class="k3-custom-event-background">
                                        <div
                                            class="event-stripe"
                                            :style="{ background: arg.event.backgroundColor }"
                                        ></div>
                                        <div
                                            class="k3-custom-event-forberedelser"
                                            :style="{
                                                minWidth:
                                                    (arg.event.extendedProps.forberedelser /
                                                        arg.event.extendedProps.salstid) *
                                                        100 +
                                                    '%',
                                            }"
                                        ></div>
                                        <div
                                            class="k3-custom-event-optid"
                                            :style="{
                                                minWidth:
                                                    (arg.event.extendedProps.optid / arg.event.extendedProps.salstid) *
                                                        100 +
                                                    '%',
                                            }"
                                        ></div>
                                        <div
                                            class="k3-custom-event-avveckling"
                                            :style="{
                                                minWidth:
                                                    (arg.event.extendedProps.avveckling /
                                                        arg.event.extendedProps.salstid) *
                                                        100 +
                                                    '%',
                                            }"
                                        ></div>
                                    </div>
                                </div>
                                <!-- Månadsvyn - visar starttid och personnummer -->
                                <div
                                    v-else-if="arg.view.type === 'dayGridMonth'"
                                    class="k3-custom-event-container event-nano event-standard-pattern"
                                    :style="{ color: arg.event.textColor, background: arg.event.backgroundColor }"
                                >
                                    <div class="event-info-month">
                                        <span class="k3-custom-event-start">
                                            {{ formatStartTime(arg.event) }}
                                        </span>
                                        <span class="k3-custom-event-opkod">
                                            {{ arg.event.extendedProps.opkod }}
                                        </span>
                                    </div>
                                    <div class="k3-custom-event-background">
                                        <div
                                            class="event-stripe"
                                            :style="{ background: arg.event.backgroundColor }"
                                        ></div>
                                        <div class="k3-custom-event-optid" :style="{ minHeight: '100%' }"></div>
                                    </div>
                                </div>
                                <!-- Övriga vyer - visar info beroende på storlek -->
                                <div
                                    v-else
                                    :class="{
                                        'k3-custom-event-container': true,
                                        'event-standard-pattern': true,
                                        'event-tiny': arg.event.extendedProps.salstid < 31 || arg.event.allDay,
                                    }"
                                >
                                    <div class="k3-custom-event-info" :style="{ color: arg.event.textColor }">
                                        <div
                                            v-if="arg.event.extendedProps.salstid >= 75 && !arg.event.allDay"
                                            class="k3-custom-event-time"
                                        >
                                            {{ formatSalstid(arg.event.start, arg.event.end) }}
                                        </div>
                                        <div>
                                            <span class="k3-custom-event-opkod">
                                                {{ arg.event.extendedProps.opkod }}
                                            </span>
                                            <span class="k3-custom-event-personid">
                                                &nbsp;{{ arg.event.extendedProps.personId }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="k3-custom-event-background">
                                        <div
                                            v-if="arg.event.extendedProps.salstid < 31 || arg.event.allDay"
                                            class="k3-custom-event-optid"
                                            :style="{ minHeight: '100%' }"
                                        ></div>
                                        <template v-else>
                                            <div
                                                class="k3-custom-event-forberedelser"
                                                :style="{
                                                    minHeight:
                                                        (arg.event.extendedProps.forberedelser /
                                                            arg.event.extendedProps.salstid) *
                                                            100 +
                                                        '%',
                                                }"
                                            ></div>
                                            <div
                                                class="k3-custom-event-optid"
                                                :style="{
                                                    minHeight:
                                                        (arg.event.extendedProps.optid /
                                                            arg.event.extendedProps.salstid) *
                                                            100 +
                                                        '%',
                                                }"
                                            ></div>
                                            <div
                                                class="k3-custom-event-avveckling"
                                                :style="{
                                                    minHeight:
                                                        (arg.event.extendedProps.avveckling /
                                                            arg.event.extendedProps.salstid) *
                                                            100 +
                                                        '%',
                                                }"
                                            ></div>
                                        </template>
                                    </div>
                                </div>
                                <template #popper>
                                    <CalendarToolTip
                                        :tip-border="arg.event.backgroundColor"
                                        :dateBooked="arg.event.extendedProps.startDateInSwedish"
                                        :salstid="
                                            !arg.event.allDay ? formatSalstid(arg.event.start, arg.event.end) : null
                                        "
                                        :patient="`${arg.event.extendedProps.personId} ${' '}  ${
                                            arg.event.extendedProps.personNamn
                                        }`"
                                        :huvudoperationkod="arg.event.extendedProps.huvudoperation"
                                        :operatorer="arg.event.extendedProps.operatorer"
                                        :operationsal="arg.event.extendedProps.sal?.namn ?? ''"
                                    >
                                        <ButtonLink
                                            type="button"
                                            @click="visaOperationsinformation(arg.event.extendedProps.id)"
                                            :disabled="!canReadAnmalan"
                                        >
                                            Operationsinformation
                                        </ButtonLink>

                                        <template v-slot:inline-elements>
                                            <Button
                                                type="button"
                                                variant="contour"
                                                size="slim"
                                                @click="redigeraBokning(arg.event)"
                                                :disabled="!canBokaOperation"
                                            >
                                                <img src="@/assets/edit.svg" alt="edit icon" />
                                                Redigera bokning
                                            </Button>
                                            <Button
                                                type="button"
                                                variant="contour"
                                                size="slim"
                                                @click="avbokaOperation(arg.event)"
                                                :disabled="!canAvbokaOperation"
                                            >
                                                <img src="@/assets/calendar.svg" alt="calendar icon" /> Avboka operation
                                            </Button>
                                        </template>
                                    </CalendarToolTip>
                                </template>
                            </VMenu>
                        </template>
                    </template>
                </FullCalendar>
                <SparraTid
                    v-if="showSparraTid"
                    ref="sparraTid"
                    v-model="sparradTid"
                    @created="createSparradTidEvent"
                    @updated="updateSparradTidEvent"
                    @deleted="deleteSparradTidEvent"
                    @cancel="cancelSparraTid"
                />
                <Kommentera
                    v-if="showKommentera"
                    :value="dagsinformation?.kommentar"
                    @cancel="showKommentera = false"
                    @submit="submitKommentar"
                    :readonly="!canUpdateDagsinformation"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import FullCalendar from "@fullcalendar/vue";
    import dayGridPlugin from "@fullcalendar/daygrid";
    import timeGridPlugin from "@fullcalendar/timegrid";
    import interactionPlugin from "@fullcalendar/interaction";
    import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
    import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
    import svLocale from "@fullcalendar/core/locales/sv";
    import BaseTab from "@/tabs/mixins/BaseTab.js";
    import { mapGetters, mapMutations } from "vuex";
    import { fetchData, isNotEmptyArray, isNullOrUndefined } from "../utils";
    import { createEvent, createEventStyle, createSparradTidEvent, setEventInfo } from "../calendar-utils";
    import { BG_COLOR_NEUTRAL, eventColorScheme, TEXT_COLOR_NEUTRAL } from "../colors-utils";
    import moment from "moment";
    import { flashMessage, openDialog } from "@/utils";
    import { getConfig, getErrorMessage, klinikenApi } from "@/api";
    import "floating-vue/dist/style.css";
    import CalendarToolTip from "@/components/CalendarToolTip.vue";
    import Operationsinformation from "@/tabs/Operation/Operationsinformation.vue";
    import ButtonLink from "@/components/ButtonLink.vue";
    import Button from "@/components/Button.vue";
    import BokaOperation from "@/tabs/Operation/BokaOperation.vue";
    import AvbokaOperation from "@/tabs/Operation/AvbokaOperation.vue";
    import dialogEvents from "@/dialogEvents";
    import { formatMessage, NonFieldErrors } from "@/store/utils";
    import SparraTid from "./SparraTid.vue";
    import tabIcon from "@/assets/operationskalender.svg";
    import emitter from "tiny-emitter/instance";
    import Kommentera from "./Kommentera.vue";

    const FILTER_OPTION_SALAR = "SALAR";
    const FILTER_OPTION_OPERATORER = "OPERATORER";
    const FILTER_OPTION_LATERALITET = "LATERALITET";

    export default {
        extends: BaseTab,
        name: "Operationskalender",
        tabName: "Operationskalender",
        icon: tabIcon,
        components: {
            FullCalendar,
            CalendarToolTip,
            ButtonLink,
            Button,
            SparraTid,
            Kommentera,
        },
        data() {
            return {
                shown: false,
                toggleSideBar_: false,
                calendarApi: null,
                calendarOptions: {
                    plugins: [
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                        resourceTimeGridPlugin,
                        resourceTimelinePlugin,
                    ],
                    schedulerLicenseKey: import.meta.env.VITE_FULLCALENDAR_SCHEDULER_LICENSE_KEY || "",
                    initialView: "timeGridWeek",
                    locale: svLocale,
                    headerToolbar: false,
                    events: [],
                    droppable: true,
                    eventReceive: this.eventReceive,
                    editable: true,
                    eventDrop: this.eventDrop,
                    eventClick: this.eventClick,
                    weekends: false,
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    allDayClassNames: "all-day-cell",
                    allDayContent: "Operationer utan tid",
                    resourceOrder: "ordning,namn",
                    resources: [],
                    weekNumbers: true,
                    snapDuration: "00:05",
                    dateClick: this.dateClick,
                    resourceLabelClassNames: "resource-label-cell",
                    resourceLabelDidMount: this.resourceLabelDidMount,
                    resourceAreaHeaderContent: "Resurser",
                    eventResize: this.eventResize,
                    nowIndicator: true,
                },
                formatStartTime(event) {
                    if (!event.allDay) {
                        return moment(event.start).format("HH:mm");
                    } else {
                        return "";
                    }
                },
                sparradTid: null,
                showSparraTid: false,
                sparradTidEvent: null,
                eventColorScheme: [],
                opsalar: [],
                huvudoperatorer: [],
                lateraliteter: [],
                currentFilterOption: FILTER_OPTION_SALAR,
                dagsinformation: null,
                showKommentera: false,
                planerad: null,
                canReadOperationskalender: false,
                canReadAnmalan: false,
                canBokaOperation: false,
                canAvbokaOperation: false,
                canUpdateDagsinformation: false,
                canSparraTid: false,
            };
        },
        computed: {
            ...mapGetters("opCalendar", ["getEvents", "getUnPlannedOperations", "getOperationSalar"]),
            ...mapGetters("operation/operationsanmalan", ["opAnmalans"]),
            ...mapGetters("ability", ["can"]),

            hasCalenderApi() {
                return this.calendarApi && this.calendarApi.currentData;
            },

            getViewTitle() {
                return this.hasCalenderApi ? this.calendarApi.currentData.viewTitle : "";
            },

            getViewDate() {
                return this.hasCalenderApi ? this.calendarApi.currentData.currentDate : "";
            },

            getCurrentViewType() {
                return this.hasCalenderApi ? this.calendarApi.currentData.currentViewType : "";
            },
            isToday() {
                let marked = false;
                if (this.getViewDate === "") {
                    return;
                }
                const date = new Date(this.getViewDate);
                const value = this.compareIsToday_(date);
                if (
                    (this.getCurrentViewType === "timeGridDay" && value === true) ||
                    (this.getCurrentViewType === "resourceTimeGridDay" && value === true)
                ) {
                    marked = true;
                } else {
                    marked = false;
                }
                return marked;
            },
            isWeek() {
                if (this.getCurrentViewType === "") {
                    return;
                }

                return this.getCurrentViewType === "timeGridWeek" || this.getCurrentViewType === "resourceTimeGridWeek";
            },
            isMonth() {
                if (this.getCurrentViewType === "") {
                    return;
                }

                return this.getCurrentViewType === "dayGridMonth";
            },
            isDay() {
                if (this.getCurrentViewType === "") {
                    return;
                }

                return this.getCurrentViewType === "timeGridDay" || this.getCurrentViewType === "resourceTimeGridDay";
            },
            isTimelineView() {
                if (this.getCurrentViewType === "") {
                    return;
                }

                return this.getCurrentViewType === "resourceTimelineDay";
            },
            isResourceView() {
                return (
                    this.getCurrentViewType === "resourceTimeGridDay" ||
                    this.getCurrentViewType === "resourceTimeGridWeek"
                );
            },
            dayHasAllDayEvents() {
                const eventsOfTheDay = this.calendarApi
                    .getEvents()
                    .filter(
                        (event) =>
                            moment(event.start).format("YYYY-MM-DD") ===
                            moment(this.calendarApi.getDate()).format("YYYY-MM-DD")
                    );
                const found = eventsOfTheDay.find((event) => event.allDay);
                return Boolean(found);
            },
            toggleSideBar: {
                get: function () {
                    return this.toggleSideBar_;
                },
                set: function (value) {
                    this.toggleSideBar_ = value;
                    setTimeout(() => {
                        this.calendarApi.updateSize();
                    }, 2);
                },
            },
        },
        methods: {
            ...mapMutations("operation/operationsanmalan", ["setOpAnmala"]),

            toggleFilterOption(option) {
                this.currentFilterOption = option;

                // Visar alla events som dolts
                this.resetFiltering();

                // Ändrar färg på alla events enligt valt filteralternativ
                let events = this.calendarApi.getEvents();
                events.map((event) => {
                    if (!event.extendedProps.sparradTid) {
                        let colorId;
                        if (option === FILTER_OPTION_OPERATORER) {
                            colorId = this.findColorIdOfHuvudoperator(event.extendedProps.huvudoperator);
                        } else if (option === FILTER_OPTION_LATERALITET) {
                            colorId = this.findColorIdOfLateralitet(event.extendedProps.lateralitet);
                        } else {
                            colorId = event.extendedProps.sal?.id ?? 0;
                        }
                        const [backgroundColor, textColor] = createEventStyle(colorId);
                        event.setProp("backgroundColor", backgroundColor);
                        event.setProp("textColor", textColor);
                    }
                    return event;
                });
            },

            findColorIdOfHuvudoperator(pk) {
                const huvudoperator = this.huvudoperatorer.find((o) => o.pk === pk);
                return huvudoperator?.id ?? 0;
            },

            findColorIdOfLateralitet(code) {
                const lateralitet = this.lateraliteter.find((l) => l.code === code);
                return lateralitet?.id ?? 0;
            },

            // Funktion för att ge en lista av resurser rätt färger
            // (Kan flyttas till calendar-utils)
            configureResourceGroupColors(resourceGroup) {
                resourceGroup.map((resource) => {
                    const [backgroundColor, textColor] = createEventStyle(resource.id);
                    resource.backgroundColor = backgroundColor;
                    resource.textColor = textColor;
                });
                return resourceGroup;
            },

            allFilterResourceOptionsAreShown(resourceList) {
                return resourceList.every((resource) => resource.show);
            },

            toggleShowResourceOption(resource) {
                resource.show = !resource.show;

                if (resource.show) {
                    // Visa event genom att ta bort class hidden-event
                    let events = this.calendarApi.getEvents();
                    if (this.currentFilterOption === FILTER_OPTION_OPERATORER) {
                        events.map((event) => {
                            if (!event.extendedProps.sparradTid && event.extendedProps.huvudoperator === resource.pk) {
                                event.setProp(
                                    "classNames",
                                    event.classNames.filter((name) => name !== "hidden-event")
                                );
                            }
                            return event;
                        });
                    } else if (this.currentFilterOption === FILTER_OPTION_LATERALITET) {
                        events.map((event) => {
                            if (!event.extendedProps.sparradTid && event.extendedProps.lateralitet === resource.code) {
                                event.setProp(
                                    "classNames",
                                    event.classNames.filter((name) => name !== "hidden-event")
                                );
                            }
                            return event;
                        });
                    } else {
                        events.map((event) => {
                            if (
                                !event.extendedProps.sparradTid &&
                                (event.extendedProps.sal?.id === resource.id ||
                                    (resource.id == 0 && !event.extendedProps.sal))
                            ) {
                                event.setProp(
                                    "classNames",
                                    event.classNames.filter((name) => name !== "hidden-event")
                                );
                            }
                            return event;
                        });
                    }
                } else {
                    // Dölj event med class hidden-event (kan bli dubbletter, kanske inte gör något?)
                    let events = this.calendarApi.getEvents();
                    if (this.currentFilterOption === FILTER_OPTION_OPERATORER) {
                        events.map((event) => {
                            if (!event.extendedProps.sparradTid && event.extendedProps.huvudoperator === resource.pk) {
                                event.setProp("classNames", [...event.classNames, "hidden-event"]);
                            }
                            return event;
                        });
                    } else if (this.currentFilterOption === FILTER_OPTION_LATERALITET) {
                        events.map((event) => {
                            if (!event.extendedProps.sparradTid && event.extendedProps.lateralitet === resource.code) {
                                event.setProp("classNames", [...event.classNames, "hidden-event"]);
                            }
                            return event;
                        });
                    } else {
                        events.map((event) => {
                            if (
                                !event.extendedProps.sparradTid &&
                                (event.extendedProps.sal?.id === resource.id ||
                                    (resource.id == 0 && !event.extendedProps.sal))
                            ) {
                                event.setProp("classNames", [...event.classNames, "hidden-event"]);
                            }
                            return event;
                        });
                    }
                }
            },

            resetFiltering() {
                this.opsalar.map((sal) => (sal.show = true));
                this.huvudoperatorer.map((operator) => (operator.show = true));
                this.lateraliteter.map((lateralitet) => (lateralitet.show = true));

                let events = this.calendarApi.getEvents();
                events.map((event) => {
                    if (!event.extendedProps.sparradTid) {
                        event.setProp(
                            "classNames",
                            event.classNames.filter((name) => name !== "hidden-event")
                        );
                    }
                    return event;
                });
            },

            resourceLabelDidMount(info) {
                let colorMark = document.createElement("div");
                colorMark.className = "color-mark";
                colorMark.style.backgroundColor = info.resource._resource.extendedProps.backgroundColor;
                info.el.childNodes[0].insertBefore(colorMark, info.el.childNodes[0].childNodes[0]);
            },

            // Callback-metod när en operation släpps från en tid i kalendern till en annan
            async eventDrop(eventDropInfo) {
                if (eventDropInfo.event.extendedProps.sparradTid) {
                    eventDropInfo.revert();
                    if (this.canSparraTid)
                        openDialog(
                            "För att flytta en spärrad tid behöver du klicka på den och fylla i formuläret.",
                            "warning"
                        );
                    return;
                }

                if (!this.canBokaOperation) {
                    eventDropInfo.revert();
                    openDialog(
                        "Du har inte tillåtelse att boka om operationen. Kontakta din enhetsadministratör om du har frågor om din användarprofil.",
                        "warning"
                    );
                    return;
                }

                let bokadOperationTid;
                if (!eventDropInfo.event.allDay) {
                    bokadOperationTid = eventDropInfo.event.extendedProps.forberedelser
                        ? moment(eventDropInfo.event.start)
                              .add(eventDropInfo.event.extendedProps.forberedelser, "minutes")
                              .format("HH:mm")
                        : moment(eventDropInfo.event.start).format("HH:mm");
                } else {
                    bokadOperationTid = null;
                }
                const payload = {
                    id: eventDropInfo.event.extendedProps.id,
                    bokad_operation_datum: moment(eventDropInfo.event.start).format("YYYY-MM-DD"),
                    bokad_operation_tid: bokadOperationTid,
                };

                if (eventDropInfo.newResource) {
                    payload.bokad_sal = eventDropInfo.newResource.id !== "0" ? eventDropInfo.newResource.id : null;
                }

                const newAnmalan = await this._bookOperationFromCalender(eventDropInfo, payload, true);
                await this.bytpatient(eventDropInfo?.event?.extendedProps.patientId ?? null);

                if (!isNullOrUndefined(newAnmalan)) {
                    const colorId = this.getColorId(newAnmalan);
                    setEventInfo(eventDropInfo.event, newAnmalan, colorId, true);
                    this.resetFiltering();
                }
            },

            // Callback-metod när en operation släpps från Operationskoordinering
            async eventReceive(info) {
                if (!this.canBokaOperation) {
                    info.revert();
                    openDialog(
                        "Du har inte tillåtelse att boka operationen. Kontakta din enhetsadministratör om du har frågor om din användarprofil.",
                        "warning"
                    );
                    return;
                }

                let bokadOperationTid;
                if (!info.event.allDay) {
                    bokadOperationTid = moment(info.event.start).format("HH:mm");
                } else {
                    bokadOperationTid = null;
                }
                const payload = {
                    id: info.event.id,
                    bokad_operation_datum: moment(info.event.start).format("YYYY-MM-DD"),
                    bokad_operation_tid: bokadOperationTid,
                    bokad_huvudoperator: info.event.extendedProps.huvudoperator,
                };

                const resources = info.event.getResources();
                if (resources.length) {
                    payload.bokad_sal = resources[0].id !== "0" ? resources[0].id : null;
                }

                const newAnmalan = await this._bookOperationFromCalender(info, payload, false);

                if (!isNullOrUndefined(newAnmalan)) {
                    const colorId = this.getColorId(newAnmalan);
                    setEventInfo(info.event, newAnmalan, colorId, false);
                    emitter.emit("operationsanmalan-bokad", newAnmalan);
                    this.resetFiltering();
                }
            },

            async _bookOperationFromCalender(info, payload, isUpdating) {
                try {
                    const response = await klinikenApi.patch(
                        `operation/anmalan/${payload.id}/boka/`,
                        payload,
                        getConfig()
                    );
                    flashMessage(isUpdating ? "Bokningen uppdaterad" : "Operationen bokad");
                    const oldAnmalan = this.opAnmalans.find((item) => item.id === payload.id);
                    const newAnmalan = Object.assign({}, oldAnmalan, response.data);
                    this.setOpAnmala(newAnmalan);
                    return newAnmalan;
                } catch (error) {
                    const { non_field_errors } = error.response.data;

                    const { list: overbokningar } = NonFieldErrors(non_field_errors, "overbokningar");
                    if (overbokningar.length) {
                        const errorList = formatMessage(overbokningar, "overbokningar");
                        dialogEvents.$emit("open", {
                            type: "warning",
                            errorList,
                            description: "",
                            buttons: [
                                {
                                    title: "Avbryt",
                                    type: "outline-primary",
                                    action: () => {
                                        info.revert();
                                    },
                                },
                                {
                                    title: "Gå vidare med bokning ändå",
                                    type: "primary",
                                    action: async () => {
                                        payload.overboka = true;
                                        const newAnmalan = await this._bookOperationFromCalender(
                                            info,
                                            payload,
                                            isUpdating
                                        );
                                        if (!isNullOrUndefined(newAnmalan)) {
                                            const colorId = this.getColorId(newAnmalan);
                                            setEventInfo(info.event, newAnmalan, colorId, isUpdating);
                                            emitter.emit("operationsanmalan-bokad", newAnmalan);
                                            this.resetFiltering();
                                        } else {
                                            info.revert();
                                        }
                                    },
                                },
                            ],
                        });
                    } else {
                        openDialog(`Kunde inte boka operationen. ${getErrorMessage(error)}`, "error");
                        info.revert();
                    }
                }
            },

            eventClick(eventClickInfo) {
                eventClickInfo.jsEvent.preventDefault();

                const event = eventClickInfo.event;
                if (event.extendedProps.sparradTid) {
                    if (this.canSparraTid) this.redigeraSparradTid(event);
                } else {
                    event.setExtendedProp("showPopup", !event.extendedProps.showPopup);
                }
            },

            dateClick(dateClickInfo) {
                const events = this.calendarApi.getEvents();
                const eventsWithOpenPopups = events.filter((e) => e.extendedProps.showPopup);

                // Om det finns öppna detaljinfo-popups innebär ett klick i kalendern att de stängs.
                // Om inga popups är öppna innebär ett klick Öppna Spärra tid.
                if (eventsWithOpenPopups.length) {
                    eventsWithOpenPopups.map((e) => e.setExtendedProp("showPopup", false));
                } else if (this.canSparraTid) {
                    const starttidMoment = moment(dateClickInfo.date).startOf("hour");
                    const sparradTid = {
                        id: null,
                        datum: moment(dateClickInfo.date).format("YYYY-MM-DD"),
                        starttid: starttidMoment.format("HH:mm"),
                        sluttid: starttidMoment.add(1, "hours").format("HH:mm"),
                        sal: dateClickInfo.resource?.id !== "0" ? dateClickInfo.resource?.id : "",
                        orsak: "",
                    };
                    this.sparraTid(sparradTid);
                }
            },

            eventResize(eventResizeInfo) {
                eventResizeInfo.revert();
                if (eventResizeInfo.event.extendedProps.sparradTid) {
                    if (this.canSparraTid)
                        openDialog(
                            "För att ändra längden på en spärrad tid behöver du klicka på den och fylla i formuläret.",
                            "warning"
                        );
                } else {
                    if (this.canBokaOperation)
                        openDialog(
                            "För att ändra längden på en operation behöver du öppna och fylla i Redigera operationsanmälan.",
                            "warning"
                        );
                }
            },

            // Ett försök till att förkorta event-textens längd i tidslinjevyn med punkter, här baserat på eventets tidlängd.
            // Suboptimalt då förhållandet varierar mellan skärmstorlekar och teckenbredder, sök andra lösningar.
            truncateString(str, duration) {
                const chars = Math.floor(duration / 5 - 2);
                return str.length > chars ? str.slice(0, chars - 1) + "..." : str;
            },

            sparraTid(sparradTid = null) {
                this.sparradTid = sparradTid;
                this.showSparraTid = true;
            },

            redigeraSparradTid(event) {
                this.sparradTidEvent = event;
                const sparradTid = {
                    id: event.extendedProps.id,
                    datum: event.extendedProps.datum,
                    starttid: event.extendedProps.starttid,
                    sluttid: event.extendedProps.sluttid,
                    sal: event.extendedProps.sal.id,
                    orsak: event.extendedProps.orsak?.id ?? "",
                };
                this.sparraTid(sparradTid);
            },

            createSparradTidEvent(newSparradTid) {
                const sparradTidEvent = createSparradTidEvent(newSparradTid);
                this.calendarApi.addEvent(sparradTidEvent);
                this.showSparraTid = false;
                this.sparradTid = null;
                flashMessage("Tid spärrad");
            },

            updateSparradTidEvent(newSparradTid) {
                this.sparradTidEvent.setDates(newSparradTid.starttid_tidpunkt, newSparradTid.sluttid_tidpunkt, false);
                this.sparradTidEvent.setResources([newSparradTid.sal]);
                this.sparradTidEvent.setExtendedProp("datum", newSparradTid.datum);
                this.sparradTidEvent.setExtendedProp("starttid", newSparradTid.starttid);
                this.sparradTidEvent.setExtendedProp("sluttid", newSparradTid.sluttid);
                this.sparradTidEvent.setExtendedProp("sal", newSparradTid.sal_display);
                this.sparradTidEvent.setExtendedProp("orsak", newSparradTid.orsak_display ?? "");
                this.sparradTidEvent.setExtendedProp("duration", newSparradTid.duration);

                this.showSparraTid = false;
                this.sparradTid = null;
                this.sparradTidEvent = null;
                flashMessage("Spärrad tid uppdaterad");
            },

            deleteSparradTidEvent() {
                this.showSparraTid = false;
                this.sparradTid = null;
                this.sparradTidEvent.remove();
                this.sparradTidEvent = null;
                flashMessage("Spärrad tid borttagen");
            },

            cancelSparraTid() {
                this.showSparraTid = false;
                this.sparradTid = null;
                this.sparradTidEvent = null;
            },

            async changeView(view) {
                this.calendarApi.changeView(view);
                await this.loadEventsForCurrentView();
                await this.loadDagsinformation();
            },
            opSalView() {
                this.changeView("resourceTimeGridDay");
            },
            timelineView() {
                this.changeView("resourceTimelineDay");
            },
            async today() {
                this.calendarApi.today();
                await this.loadEventsForCurrentView();
                await this.loadDagsinformation();
            },
            async previous() {
                this.calendarApi.prev();
                await this.loadEventsForCurrentView();
                await this.loadDagsinformation();
            },
            async next() {
                this.calendarApi.next();
                await this.loadEventsForCurrentView();
                await this.loadDagsinformation();
            },

            compareIsToday_(date) {
                const today = new Date();

                return (
                    date.getDate() === today.getDate() &&
                    date.getMonth() === today.getMonth() &&
                    date.getFullYear() === today.getFullYear()
                );
            },

            async _fetchOperationsKalendar(rangeStart, rangeEnd) {
                this.filterValues = {
                    status__in: "Bokad, Genomförd, Signerad, Pågående",
                    bokad_operation_datum__gte: rangeStart,
                    bokad_operation_datum__lte: rangeEnd,
                };

                let params = {
                    ...this.filterValues,
                    ...{
                        ordering: this.sortField,
                    },
                };
                const response = await fetchData({
                    url: "/operation/oversikt/",
                    params,
                });
                return response;
            },

            resourceIsShown(anmalan, option = this.currentFilterOption) {
                if (option === FILTER_OPTION_OPERATORER) {
                    const operator = this.huvudoperatorer.find(
                        (operator) => operator.pk === anmalan.current_bokad_huvudoperator_display?.pk
                    );
                    if (operator && !operator.show) {
                        return false;
                    }
                } else if (option === FILTER_OPTION_LATERALITET) {
                    const lateralitet = this.lateraliteter.find(
                        (lateralitet) => lateralitet.code === anmalan.lateralitet
                    );
                    if (lateralitet && !lateralitet.show) {
                        return false;
                    }
                } else {
                    const sal = this.opsalar.find(
                        (sal) => sal.id === anmalan.bokad_sal || (sal.id == 0 && !anmalan.bokad_sal)
                    );
                    if (sal && !sal.show) {
                        return false;
                    }
                }
                return true;
            },

            getColorId(anmalan, option = this.currentFilterOption) {
                let colorId;
                if (option === FILTER_OPTION_OPERATORER) {
                    colorId = this.findColorIdOfHuvudoperator(anmalan.current_bokad_huvudoperator_display?.pk);
                } else if (option === FILTER_OPTION_LATERALITET) {
                    colorId = this.findColorIdOfLateralitet(anmalan.lateralitet);
                } else {
                    colorId = anmalan.bokad_sal ?? 0;
                }
                return colorId;
            },

            _createEvents(anmalningar = []) {
                let arr = [];
                if (isNotEmptyArray(anmalningar)) {
                    anmalningar.forEach((anmalan) => {
                        const colorId = this.getColorId(anmalan);
                        arr.push(createEvent(anmalan, colorId, this.resourceIsShown(anmalan)));
                    });
                }
                return arr;
            },
            _createSparradTidEvents(sparradeTider) {
                let arr = [];
                if (isNotEmptyArray(sparradeTider)) {
                    sparradeTider.forEach((sparradTid) => {
                        arr.push(createSparradTidEvent(sparradTid));
                    });
                }
                return arr;
            },

            formatSalstid(startParam, endParam) {
                let start;
                let end;
                if (!isNullOrUndefined(startParam)) {
                    start = moment(startParam).format("HH:mm");
                }

                if (!isNullOrUndefined(endParam)) {
                    end = moment(endParam).format("HH:mm");
                }
                return `${start ?? ""} ${end ? "- " + end : ""}`;
            },

            async bytpatient(patientId) {
                if (patientId) {
                    await this.$store.dispatch("patientData/loadFromBackend", {
                        id: patientId,
                        failSilently: false,
                    });
                }
            },

            async visaOperationsinformation(id) {
                await this.$store.dispatch("tabs/closeTab", Operationsinformation, { root: true });

                await this.$store.dispatch("tabs/openTab", {
                    component: Operationsinformation,
                    parameters: { operationsanmalan_id: id },
                });
            },

            async redigeraBokning(event) {
                const id = event.extendedProps.id;
                try {
                    const response = await klinikenApi.get(`operation/anmalan/${id}`, getConfig());
                    const anmalan = response.data;
                    const operatorer = await fetchData({
                        url: "/core/profiles/",
                        params: { operator: true },
                    });
                    const salar = await fetchData({
                        url: "/operation/salar/",
                    });
                    dialogEvents.$emit("openPopup", {
                        title: anmalan.status !== "Bokad" ? "Boka operation" : "Redigera bokning",
                        component: BokaOperation,
                        dismissable: false,
                        data: {
                            listItem: anmalan,
                            salar: salar,
                            operatorer: operatorer,
                        },
                    });
                } catch (error) {
                    openDialog(`Kunde inte boka om operationen. ${getErrorMessage(error)}`, "error");
                }
            },
            async avbokaOperation(event) {
                const id = event.extendedProps.id;
                try {
                    const response = await klinikenApi.get(`operation/anmalan/${id}`, getConfig());
                    const anmalan = response.data;
                    const strykningsorsaker = await fetchData({
                        url: "/operation/kodverk/strykningsorsaker/",
                    });
                    this.$emit("closePopup");
                    dialogEvents.$emit("openPopup", {
                        title: "Avboka eller Stryk",
                        component: AvbokaOperation,
                        dismissable: false,
                        data: {
                            listItem: anmalan,
                            strykningsorsaker,
                        },
                    });
                } catch (error) {
                    openDialog(`Kunde inte avboka operationen. ${getErrorMessage(error)}`, "error");
                }
            },
            async updateEvent(anmalan) {
                const event = this.calendarApi
                    .getEvents()
                    .find((e) => !e.extendedProps.sparradTid && e.extendedProps.id === anmalan.id);
                if (event) {
                    const colorId = this.getColorId(anmalan);
                    setEventInfo(event, anmalan, colorId, false);
                    this.resetFiltering();
                } else {
                    const colorId = this.getColorId(anmalan);
                    const newEvent = createEvent(anmalan, colorId);
                    this.calendarApi.addEvent(newEvent);
                    this.resetFiltering();
                }
            },
            async loadEventsForCurrentView() {
                const rangeStart = moment(this.calendarApi.currentData.dateProfile.activeRange.start).format(
                    "YYYY-MM-DD"
                );
                const rangeEnd = moment(this.calendarApi.currentData.dateProfile.activeRange.end).format("YYYY-MM-DD");

                const anmalningar = await this._fetchOperationsKalendar(rangeStart, rangeEnd);

                const response = await klinikenApi.get(
                    "/operation/sparradtid/",
                    getConfig({
                        params: { datum__gte: rangeStart, datum__lte: rangeEnd },
                    })
                );
                const sparradeTider = response.data.results;

                let events = this._createEvents(anmalningar);
                let sparradTidEvents = this._createSparradTidEvents(sparradeTider);
                events = events.concat(sparradTidEvents);
                this.$set(this.calendarOptions, "events", events);
            },

            async savePlanerad() {
                this.dagsinformation = { ...this.dagsinformation, planerad: this.planerad };

                if (this.dagsinformation.id) {
                    const response = await klinikenApi.patch(
                        `/operation/dagsinformation/${this.dagsinformation.datum}/`,
                        { planerad: this.planerad },
                        getConfig()
                    );
                    this.dagsinformation = response.data;
                } else {
                    const response = await klinikenApi.post(
                        "/operation/dagsinformation/",
                        this.dagsinformation,
                        getConfig()
                    );
                    this.dagsinformation = response.data;
                }
            },

            async submitKommentar(kommentar) {
                this.showKommentera = false;
                if (kommentar !== null) {
                    this.dagsinformation = { ...this.dagsinformation, kommentar };
                    if (this.dagsinformation.id) {
                        const response = await klinikenApi.patch(
                            `/operation/dagsinformation/${this.dagsinformation.datum}/`,
                            { kommentar: kommentar },
                            getConfig()
                        );
                        this.dagsinformation = response.data;
                    } else {
                        const response = await klinikenApi.post(
                            "/operation/dagsinformation/",
                            this.dagsinformation,
                            getConfig()
                        );
                        this.dagsinformation = response.data;
                    }
                }
            },

            async loadDagsinformation() {
                const currentDate = moment(this.calendarApi.currentData.dateProfile.currentDate).format("YYYY-MM-DD");

                try {
                    const response = await klinikenApi.get(`/operation/dagsinformation/${currentDate}/`, getConfig());
                    this.dagsinformation = response.data;
                } catch (e) {
                    this.dagsinformation = { datum: currentDate, planerad: false };
                }
                this.planerad = this.dagsinformation.planerad;
            },
        },

        async mounted() {
            this.canReadOperationskalender = this.can({ action: "read", subject: "operationskalender" });

            if (!this.canReadOperationskalender) {
                // TODO: Varningsmeddelande - vilket ska användas?
                await this.$store.dispatch("tabs/closeTab", this.tabId);
                return;
            }

            this.canReadAnmalan = this.can({ action: "read", subject: "anmalan" });
            this.canBokaOperation = this.can({ action: "boka", subject: "anmalan" });
            this.canAvbokaOperation = this.can({ action: "avboka", subject: "anmalan" });
            this.canUpdateDagsinformation = this.can({ action: "update", subject: "dagsinformation" });
            this.canSparraTid = this.can({ action: "update", subject: "sparradtid" });

            this.calendarApi = await this.$refs.fullCalendar.getApi();

            await this.loadEventsForCurrentView();

            await this.loadDagsinformation();

            this.eventColorScheme = eventColorScheme;

            let resources = [
                {
                    id: 0,
                    namn: "Ingen Opsal",
                    ordning: -1,
                    backgroundColor: BG_COLOR_NEUTRAL,
                    textColor: TEXT_COLOR_NEUTRAL,
                },
            ];
            let salar = await fetchData({
                url: "/operation/salar/",
            });
            salar = this.configureResourceGroupColors(salar);
            resources = resources.concat(salar);
            resources.map((resource) => {
                resource.title = resource.namn;
                resource.show = true;
                return resource;
            });
            this.$set(this.calendarOptions, "resources", resources);
            this.opsalar = [...resources];
            let operatorer = await fetchData({
                url: "/core/profiles/",
                params: { operator: true, is_active: true },
            });
            let operatorList = operatorer.map((operator, index) => ({
                id: index + 1,
                pk: operator.pk,
                title: operator.user_display?.name ?? "",
                show: true,
            }));
            operatorList = this.configureResourceGroupColors(operatorList);
            this.huvudoperatorer = operatorList;

            let lateraliteter = await fetchData({
                url: "/operation/kodverk/lateralitet/",
                filename: "",
                description: "",
            });
            let lateralitetList = lateraliteter.map((lat) => {
                lat.title = lat.displayName;
                lat.show = true;
                return lat;
            });
            lateralitetList = this.configureResourceGroupColors(lateralitetList);
            this.lateraliteter = lateralitetList;

            emitter.on("operationsanmalan-uppdaterad", this.updateEvent);
            emitter.on("operationsanmalan-bokad", this.updateEvent);
            emitter.on("operationsanmalan-avbokad", ({ id, status }) => {
                const event = this.calendarApi
                    .getEvents()
                    .find((e) => !e.extendedProps.sparradTid && e.extendedProps.id === id);
                if (event) {
                    if ((status && status === "Avbokad") || status === "Struken") {
                        event.remove();
                    }
                }
            });
            emitter.on(`adjust-tab-${this.$options.tabName}`, async () => {
                setTimeout(() => {
                    this.calendarApi.updateSize();
                }, 10);
            });
        },

        beforeDestroy() {
            emitter.off("operationsanmalan-updated");
            emitter.off("operationsanmalan-bokad");
            emitter.off("operationsanmalan-avbokad");
            emitter.off(`adjust-tab-${this.$options.tabName}`);
        },
    };
</script>

<style lang="scss">
    .operationskalender {
        $event-color-neutral: #7dbfd7;
        display: flex;
        gap: 46px;

        .page-wrapper {
            .header-section {
                display: flex;
                justify-content: space-between;
                padding-bottom: 22px;

                h2 {
                    &::first-letter {
                        text-transform: capitalize;
                    }
                }

                .header-section-left {
                    display: flex;
                    flex-direction: row-reverse;
                    flex-wrap: wrap;
                    gap: 20px;

                    .dagsinformation {
                        height: 56px;
                        width: 320px;
                        background-color: #fafbfd;
                        border: solid 1px #e4e8f0;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        padding: 16px 24px;
                        gap: 16px;

                        .formulate-input {
                            margin: 10px 0 0 0;

                            &[data-has-value="true"] {
                                .formulate-input-element-decorator {
                                    background-color: #2d9367;
                                }
                            }

                            .formulate-input-element-decorator {
                                height: 18px;
                                width: 18px;
                            }
                        }

                        span {
                            font-size: 14px;
                        }

                        label {
                            font-size: 14px;
                            color: #354052 !important;
                            margin-top: -3px;
                        }

                        hr {
                            border: none;
                            border-left: 1px solid #b9d8e3;
                            height: 40px;
                            width: 1px;
                        }
                    }

                    .k3-calendar-toolbar {
                        background-color: #ebf6f8;
                        min-width: 613px;
                        height: 56px;
                        border-radius: 4px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        padding: 12px;

                        .tab-button-left {
                            background: none;
                            border: none;
                            font-size: 14px;
                            color: #55676d;
                        }

                        .view-button {
                            background: none;
                            border: none;
                            font-size: 14px;
                            color: #3092b5;
                        }

                        .active-tab {
                            font-weight: bold;
                            text-decoration: underline;
                            text-decoration-thickness: 3px;
                            text-underline-offset: 12px;
                        }

                        .arrow-buttons {
                            display: flex;
                            justify-content: space-between;
                            width: 81px;
                        }

                        .arrow-button {
                            background: #38a7cf;
                            border: none;
                            border-radius: 4px;
                            width: 34px;
                            height: 34px;
                            color: #fafbfd;
                        }
                    }
                }
            }
        }

        .fc-scrollgrid {
            border: none !important;
        }

        tr.fc-scrollgrid-section-header {
            th {
                border-left: none;
                border-right: none;
                border-top: none;
            }
        }

        .fc-timegrid-slot-minor {
            border-top: none !important;
            border-top-style: none !important;
        }

        tr.fc-scrollgrid-section {
            height: 0;

            td.fc-timegrid-divider {
                padding: 0;
            }
        }

        .fc-event {
            border: none;
            border-radius: 4px;
            height: 100%;
        }

        .fc-event-main {
            height: 100%;
        }

        .v-popper {
            height: 100%;
            width: 100%;
        }

        .date-text {
            margin-bottom: 20px;
        }

        .calendar-info {
            font-size: 14px;
        }

        .timeline-all-day-info {
            margin-top: -2px;
            margin-bottom: 14px;
        }

        // Gäller enbart Dagens operationer
        .all-day-cell {
            font-size: 10.5px;
            line-height: 13px;

            div {
                background-color: #f6f8fb;
            }
        }

        .all-day-cell ~ .fc-day {
            height: 100%;

            .fc-daygrid-day-frame {
                background-color: #f6f8fb;
                height: 100%;

                .fc-daygrid-day-events {
                    height: 100%;

                    .fc-daygrid-event-harness {
                        height: 40px;

                        .fc-event {
                            height: 30px;
                            margin: 10px;

                            .k3-custom-event-info {
                                top: 4px;
                            }
                        }
                    }
                }
            }
        }

        // Textfärg i table headers
        *:is(.fc-col-header-cell-cushion, .fc-timegrid-axis-cushion, .fc-timeline-slot-cushion) {
            color: #55676d;

            &:hover {
                text-decoration: none;
            }
        }

        // Gäller enbart tidslinjevyn
        .fc-resourceTimelineDay-view {
            // Döljer veckonummer
            .fc-timeline-header-row:not(.fc-timeline-header-row-chrono) {
                th {
                    border-bottom: none;

                    a {
                        display: none;
                    }
                }
            }

            .fc-resource-timeline-divider {
                width: 0;
            }

            tr.fc-scrollgrid-section-header {
                .fc-resource-timeline-divider {
                    border: none;
                }
            }

            .all-day-event {
                display: none;
            }

            .resource-label-cell {
                div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .color-mark {
                        background-color: #93c9de;
                        width: 7px;
                        height: 28px;
                        border-radius: 4px;
                        margin-left: 9px;
                    }
                }
            }

            .fc-timeline-event-harness {
                box-sizing: border-box;
                height: 30px;
                top: 5px !important;

                .fc-event {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 0;

                    .k3-custom-event-container {
                        .k3-custom-event-background {
                            min-height: 30px;
                            display: flex;
                            flex-direction: row;
                            height: 100%;
                            width: 100%;
                            position: relative;
                            top: 0;
                            margin-left: 0;

                            .k3-custom-event-forberedelser {
                                width: 100%;
                                background-color: white;
                                opacity: 0.8;
                                margin-left: 0;
                            }

                            .k3-custom-event-optid {
                                width: 100%;
                                background-color: white;
                                opacity: 0.6;
                                margin-left: 0;
                            }

                            .k3-custom-event-avveckling {
                                width: 100%;
                                background-color: white;
                                opacity: 0.8;
                                margin-left: 0;
                            }
                        }
                    }
                }

                .all-day {
                    display: none !important;
                }
            }
        }

        // Gäller enbart månadsvyn
        .fc-dayGridMonth-view {
            .fc-event {
                margin: 0 6px;
            }

            .fc-daygrid-dot-event {
                padding: 0;
            }

            .fc-daygrid-day-events {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }
        }

        // Generellt för alla events
        .fc-event {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .k3-custom-event-container {
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            position: relative;

            .k3-custom-event-info {
                position: absolute;
                top: 5px;
                padding: 0 12px;
                color: #37809b;
                z-index: 1000;
            }

            &.event-tiny {
                .k3-custom-event-info {
                    top: 2px;
                }

                .k3-custom-event-optid {
                    position: relative;
                    top: 1px;
                    height: 105%;
                    border-radius: 0 4px 4px 0;
                    background-color: #d4eaf2;
                }
            }

            // Visas i månadsvy - starttid och personnummer
            &.event-nano {
                width: 100%;
                padding: 2px 8px 2px 12px;
                border-radius: 4px;
                color: white;
                position: relative;
                top: 0px;

                span {
                    z-index: 1000;
                }

                .event-info-month {
                    display: flex;
                    justify-content: space-between;
                }

                .k3-custom-event-background {
                    position: absolute;
                    top: 0;
                    margin: 0;
                    left: 0;
                    z-index: 999;
                    min-height: 26px !important;
                    width: 100%;

                    .k3-custom-event-optid {
                        width: 100%;
                        background-color: white;
                        opacity: 0.6;
                    }
                }
            }

            // Avnänds just nu enbart i månads- och tidslinjevyn, som barn till .k3-custom-event-background
            .event-stripe {
                position: absolute;
                z-index: 1000;
                height: 100%;
                min-width: 6px;
                background-color: $event-color-neutral;
                border-radius: 4px;
            }
        }

        // Gäller alla vyer utom tidslinjen
        .event-standard-pattern {
            .k3-custom-event-background {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 99.5%;
                position: relative;
                top: -1px;
                margin-left: 5px;

                .k3-custom-event-forberedelser {
                    width: 100%;
                    background-color: white;
                    opacity: 0.8;
                }

                .k3-custom-event-optid {
                    width: 100%;
                    background-color: white;
                    opacity: 0.6;
                }

                .k3-custom-event-avveckling {
                    width: 100%;
                    background-color: white;
                    opacity: 0.8;
                }
            }
        }

        // Spärrade tider
        .sparrad-tid-event {
            background: repeating-linear-gradient(-45deg, #ffffff, #ffffff 1px, #e9ecec 1px, #e9ecec 7px);

            span {
                color: #6c8188;
            }

            .sparrad-tid-time-full {
                margin: 9px 16px 0 16px;
                font-size: 16px;
                font-weight: 500;
            }

            .sparrad-tid-info {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
            }

            .sparrad-tid-info-nano {
                width: 100%;
                margin: 2px 12px 2px 12px;
            }

            .sparrad-tid-info-tiny {
                margin: 1px 16px 1px 16px;
            }

            .sparrad-tid-info-small {
                margin: 9px 16px 9px 16px;
            }

            .sparrad-tid-info-full {
                margin: 6px 16px 6px 16px;
            }

            .sparrad-tid-info-timeline {
                margin: 4px 10px 4px 10px;
            }
        }

        .fc-timegrid-now-indicator-line {
            border-color: #ca346f;
        }

        .fc-day-today {
            background: transparent !important;
        }

        .hidden-event {
            display: none;
        }

        .sidebar-div {
            z-index: 199;
            min-height: 100%;
            min-width: 250px;
            background-color: #f9fbfe;
            border-right: 1px solid #d3d8da;
        }

        .calendar-container {
            width: 100%;
        }

        .close-sidebar-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 14px;
            margin-right: 14px;
        }

        .sidebar-section {
            margin-top: 1.75rem;
            margin-left: 28px;
            margin-right: 17px;

            .section-title {
                margin-left: 5px;
                text-transform: uppercase;
                font-size: 11px;
                line-height: 16.5px;
                color: #6c8188;
            }
        }

        .sidebar-title {
            margin-left: 5px;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 16.5px;
            color: #55676d;
        }

        label.filter-option {
            position: relative;

            input {
                appearance: none;
            }

            input:not(:checked) + div.checkbox-frame {
                img {
                    display: none;
                }
            }

            div.checkbox-frame {
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
                border: #65767c 1px solid;
                border-radius: 4px;
            }

            span {
                position: absolute;
                top: 2px;
                left: 20px;
            }
        }

        label.filter-resource-option {
            position: relative;
            display: block;
            margin: 16px 0 12px 0;

            input {
                appearance: none;
            }

            input:not(:checked) + div.checkbox-frame {
                background-color: transparent !important;

                img {
                    display: none;
                }
            }

            input:hover + div.checkbox-frame {
                filter: brightness(90%);
            }

            input:active + div.checkbox-frame {
                filter: brightness(80%);
            }

            div.checkbox-frame {
                position: absolute;
                top: 0;

                display: flex;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
                border: #65767c 1px solid;
                border-radius: 4px;
            }

            img {
                z-index: 1000;
            }

            span {
                position: absolute;
                left: 26px;
            }
        }

        .close-sidebar-icon {
            height: 12px;
            width: 12px;
            cursor: pointer;
        }

        .sidebar-divider {
            height: 1px;
            border: none;
            background-color: #d3d8da;
        }
    }
</style>
