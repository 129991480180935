import { Operation } from "./Operation";
export class OplaneradOperation extends Operation {
    #pk;
    #personId;
    #code;

    constructor(pk, personId, code, forberedelsetid, avvecklingstid, optid, patient) {
        super(forberedelsetid, avvecklingstid, optid, patient);

        this.#pk = pk;
        this.#personId = personId;
        this.#code = code;
    }

    get pk() {
        return this.#pk;
    }

    get personId() {
        return this.#personId;
    }

    get code() {
        return this.#code;
    }

    set pk(value) {
        this.#pk = value;
    }

    set personId(value) {
        this.#personId = value;
    }

    set code(value) {
        this.#code = value;
    }

    toJSON() {
        const { pk, personId, code, forberedelsetid, avvecklingstid, optid, patient } = this;
        return {
            pk,
            personId,
            code,
            forberedelsetid,
            avvecklingstid,
            optid,
            patient,
        };
    }
}
