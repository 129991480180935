<script setup lang="ts">
    import { onMounted, onUnmounted, ref } from "vue";
    import useHandleEntryParameters from "@/composables/useHandleEntryParameters";
    import { useStore } from "@/store";
    import TabContainer from "@/tabs/TabContainer.vue";
    import KlinikenHeader from "@/components/KlinikenHeader.vue";
    import KlinikenMenu from "@/components/KlinikenMenu.vue";
    import ModalIdle from "@/components/ModalIdle.vue";
    import throttle from "lodash/throttle";

    const { handleEntryParameters } = useHandleEntryParameters();
    const store = useStore();

    const maxIdle = ref<number>(10 * 60 * 1000);
    const countdownTime = ref<number>(30000);
    const isIdle = ref<boolean>(false);
    const idleWorker = ref<null | Worker>(null);
    const minimized = ref<boolean>(false);

    function createIdleWorker() {
        idleWorker.value = new Worker("/workers/idleWorker.js");
        idleWorker.value?.postMessage({}); // start worker

        idleWorker.value?.addEventListener(
            "message",
            (
                event: MessageEvent<{
                    isIdle: boolean;
                    maxIdleFor?: number;
                    countdownTime?: number;
                    logout?: boolean;
                }>
            ) => {
                isIdle.value = event.data.isIdle;

                if (event.data.maxIdleFor) {
                    maxIdle.value = event.data.maxIdleFor;
                }

                if (event.data.countdownTime) {
                    countdownTime.value = event.data.countdownTime;
                }

                if (event.data.logout) {
                    logout();
                }
            }
        );
    }

    function toggleSize(minimizedValue: boolean) {
        minimized.value = minimizedValue;
    }

    const resetIdle = throttle(
        () => {
            idleWorker.value?.postMessage({ reset: true });
        },
        1000,
        { leading: true, trailing: false }
    );

    function logout() {
        store.dispatch("userData/logout");
    }

    handleEntryParameters();

    onMounted(() => {
        if (window.Worker) {
            createIdleWorker(); // create worker
        }

        window.addEventListener("click", resetIdle);
        window.addEventListener("mousemove", resetIdle);
        window.addEventListener("keypress", resetIdle);
        window.addEventListener("scroll", resetIdle);
        window.addEventListener("focus", resetIdle);
        window.addEventListener("touchstart", resetIdle);
        window.addEventListener("touchmove", resetIdle);
        window.addEventListener("touchend", resetIdle);
        window.addEventListener("touchcancel", resetIdle);
        window.addEventListener("touchleave", resetIdle);
        window.addEventListener("touchenter", resetIdle);
    });

    onUnmounted(() => {
        idleWorker.value?.terminate(); // stop worker

        window.removeEventListener("click", resetIdle);
        window.removeEventListener("mousemove", resetIdle);
        window.removeEventListener("keypress", resetIdle);
        window.removeEventListener("scroll", resetIdle);
        window.removeEventListener("focus", resetIdle);
        window.removeEventListener("touchstart", resetIdle);
        window.removeEventListener("touchmove", resetIdle);
        window.removeEventListener("touchend", resetIdle);
        window.removeEventListener("touchcancel", resetIdle);
        window.removeEventListener("touchleave", resetIdle);
        window.removeEventListener("touchenter", resetIdle);
    });
</script>

<template>
    <div id="kliniken-frame" :class="{ minimized: minimized }">
        <KlinikenMenu @toggleSize="toggleSize" />
        <div class="content">
            <KlinikenHeader />
            <TabContainer />
        </div>
        <ModalIdle v-if="isIdle" :maxIdleFor="maxIdle" :countdownTime="countdownTime" @close="resetIdle" />
    </div>
</template>

<style lang="scss" scoped>
    @import "@/style/variables";

    #kliniken-frame {
        $maximized-width: 290px;
        $minimized-width: 64px;

        #kliniken-menu {
            height: 100%;
            width: $maximized-width;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
        }

        .content {
            margin-left: $maximized-width;
            height: 100vh;
        }

        &.minimized {
            #kliniken-menu {
                width: $minimized-width;
            }

            .content {
                margin-left: $minimized-width;
            }
        }

        main {
            height: 100vh;
            flex: 1 1 auto;
        }
    }

    @media print {
        #kliniken-frame {
            #kliniken-header,
            #kliniken-menu {
                display: none;
            }

            .content {
                height: auto;
                margin-left: 0;
            }
        }
    }
</style>

<style lang="scss">
    @page {
        size: A4 portrait;
        margin: 16px;
    }
</style>
