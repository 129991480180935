<template>
    <div class="dialog-aktiv-patient">
        <div class="patientimg">
            <img src="@/assets/dialogs/patient.svg" alt="Patient" />
        </div>
        <div>
            <strong>Aktiv patient</strong>
            <ul class="patient-details">
                <li>{{ patient.personId }}</li>
                <li>{{ patient.namn }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "DialogAktivPatient",
    props: {
        patient: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.dialog-aktiv-patient {
    display: flex;
    align-items: flex-end;
    gap: 5px;

    .patientimg {
        background-color: $color-primary-lighten;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;

        img {
            width: 25px;
            height: 28px;
        }
    }

    strong {
        display: block;
        font-weight: 500;
        line-height: 30px;
        color: $color-text-black;
    }

    .patient-details {
        color: $color-text-header;
        font-size: 20px;
        line-height: 28px;
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
</style>
