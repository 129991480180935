<template>
    <div>
        <template v-if="!preview">
            <input
                :name="name + 'Display'"
                :required="required"
                :maxlength="maxlength"
                :minlength="minlength"
                class="text-input"
                v-model="displayValue"
                @keypress="inputTypeNumber($event)"
                @change="validate()"
                @blur="
                    validateAgainstBackend();
                    $emit('blur');
                "
            />
            <input type="hidden" :name="name" :value="value_" />
            <input type="hidden" :name="name + 'Type'" :value="personIdType" />
        </template>
        <span v-else class="input-preview">{{ displayValue }}</span>
    </div>
</template>

<script>
import PersonIdWidget from "@/components/widgets/inputs/PersonIdWidget.vue";
import { getConfig, klinikenApi } from "@/api";

export default {
    extends: PersonIdWidget,
    name: "FilhanteringPersonIdWidget",

    data() {
        return {
            value_: this.value,
            displayValue: this.value || "",
        };
    },

    watch: {
        // Denna behövs för att trigga validering när vi
        // kör "copyFromPrevious" längre upp i komponentträdet
        displayValue: function () {
            if (this.displayValue.length === 12 || this.displayValue.length === 13) {
                if (this.displayValue.length == 13) {
                    // If 13 characters are given remove the dash, this will
                    // turn 19121212-1212 into 191212121212, which is what our backend expects
                    this.displayValue = this.displayValue.replace("-", "");
                }
                this.validate();
                this.validateAgainstBackend();
            }
        },
    },

    methods: {
        async validateAgainstBackend() {
            // Kolla att det finns exakt en patient med angivet personId
            // eslint-disable-next-line

            const personId = this.value_;
            const url = `/core/patienter/?personId=${personId}`;
            const config = getConfig({}, "Filer");

            const result = await klinikenApi.get(url, config);
            const data = result.data;
            let eventData = {
                personIdIsValid: false,
                personIdIsUnique: false,
                personIdType: "",
                patientUUID: "",
                patientNamn: "",
                message: "",
            };
            switch (data.count) {
                case 0:
                    eventData.message = "Angivet Person-id finns inte.";
                    break;
                case 1:
                    eventData = {
                        personIdIsValid: true,
                        personIdIsUnique: true,
                        patientNamn: `${data.results[0].fornamn}  ${data.results[0].efternamn}`,
                        patientUUID: data.results[0].id,
                        personIdType: data.results[0].personIdType,
                        message: "",
                    };
                    break;
                default:
                    eventData.message = "Angivet Person-id är inte unikt.";
                    eventData.personIdIsValid = true;
            }

            //  Visa felmeddelande om personId inte är giltigt eller inte är unikt
            if (!(eventData.personIdIsValid && eventData.personIdIsUnique)) {
                this.showWarning(eventData.message);
            }
            this.$parent.$emit("personidchange", eventData);
        },
    },
};
</script>
