<template>
    <ModalContent :title="title" :showCloseButton="true" @close="cancel">
        <FormulateForm class="sparra-tid" v-model="formData" @submit="submit" autocomplete="off">
            <span class="calendar-info">
                <img class="info-img" src="@/assets/info_dark.svg" alt="operation" />
                &nbsp;Lägg in tidsperiod som inte är bokningsbar för operation
            </span>

            <div class="form">
                <div class="row">
                    <FormulateInput type="datepicker" label="Datum *" name="datum" />
                    <FormulateInput type="vueselect" label="Sal *" name="sal" :options="salar" />
                </div>
                <div class="row">
                    <FormulateInput type="timepicker" label="Starttid *" name="starttid" />
                    <FormulateInput type="timepicker" label="Sluttid *" name="sluttid" />
                </div>
                <div class="row">
                    <FormulateInput :options="orsaker" type="vueselect" isDropdown="true" label="Orsak" name="orsak" />
                </div>
            </div>

            <ButtonGroup class="buttongroup">
                <Button type="submit">Spara spärrad tid</Button>
                <Button v-if="value?.id" size="secondary" variant="alert" type="button" @click="remove">
                    Ta bort spärrad tid
                </Button>
                <Button size="secondary" variant="contour" type="button" @click="cancel">Avbryt</Button>
            </ButtonGroup>
        </FormulateForm>
    </ModalContent>
</template>

<script>
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import ModalContent from "@/components/generic/ModalContent.vue";
import { fetchData } from "../utils";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

export default {
    name: "SparraTid",
    components: {
        Button,
        ButtonGroup,
        ModalContent,
    },
    props: ["value"],
    data() {
        return {
            data: this.$attrs,
            salar: [],
            orsaker: [],
            formData: {},
            title: "",
        };
    },
    async created() {
        this.title = this.value?.id ? "Redigera spärrad tid" : "Spärra tid";

        const salar = await fetchData({ url: "/operation/salar/" });
        this.salar = [{ value: "", label: "" }].concat(
            salar.map((sal) => ({
                value: sal.id,
                label: sal.namn,
            }))
        );

        const orsaker = await fetchData({ url: "/operation/sparrningsorsaker/" });
        this.orsaker = [{ value: "", label: "" }].concat(
            orsaker.map((orsak) => ({
                value: orsak.id,
                label: orsak.namn,
            }))
        );

        if (this.value) {
            this.formData = {
                datum: this.value.datum,
                sal: `${this.value.sal ?? ""}`,
                starttid: this.value.starttid?.substring(0, 5),
                sluttid: this.value.sluttid?.substring(0, 5),
                orsak: `${this.value.orsak ?? ""}`,
            };
        } else {
            this.formData = {};
        }
    },
    methods: {
        cancel() {
            this.formData = {};
            this.$emit("cancel");
        },
        async submit() {
            const payload = {
                datum: this.formData.datum,
                sal: this.formData.sal,
                starttid: this.formData.starttid,
                sluttid: this.formData.sluttid,
                orsak: this.formData.orsak,
            };

            if (this.value && this.value.id) {
                try {
                    const response = await klinikenApi.put(
                        `/operation/sparradtid/${this.value.id}/`,
                        { ...payload },
                        getConfig()
                    );
                    this.$emit("updated", response.data);
                } catch (e) {
                    openDialog(`Kunde inte uppdatera den spärrade tiden. ${getErrorMessage(e)}`, "error");
                }
            } else {
                try {
                    const response = await klinikenApi.post("/operation/sparradtid/", { ...payload }, getConfig());
                    this.$emit("created", response.data);
                } catch (e) {
                    openDialog(`Kunde inte spärra tiden. ${getErrorMessage(e)}`, "error");
                }
            }
        },
        async remove() {
            try {
                await klinikenApi.delete(`/operation/sparradtid/${this.value.id}/`, getConfig());
                this.$emit("deleted");
            } catch (e) {
                openDialog(`Kunde inte ta bort den spärrade tiden. ${getErrorMessage(e)}`, "error");
            }
        },
    },
};
</script>

<style lang="scss">
.sparra-tid {
    @import "@/style/modal.scss";

    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-top: 10px;

    .form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .row {
            display: flex;
            justify-content: space-between;
        }

        .formulate-input {
            color: grey;
            width: 47.7%;

            label {
                color: #728296;
            }

            .mx-datepicker {
                width: 100%;
            }
        }
    }

    .buttongroup {
        margin-top: 15px;
        gap: 12px;

        .button-primary {
            font-size: 18px;
        }

        button {
            font-size: 16px;
            white-space: nowrap;
        }
    }
}
</style>
