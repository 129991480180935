<template>
    <div>
        <div class="samtyckenList">
            <div class="samtyckeMain">
                <h2 class="patientsamtycke">Patientsamtycken</h2>
                <button role="button" @click="openSamtyckesForm" class="hollow-button">
                    <img src="@/assets/plus_circle_blue.svg" />Lägg till patientsamtycke
                </button>
            </div>

            <div style="margin-top: 24px">
                <img src="@/assets/alert_dark.svg" /> Observera att om patienten tidigare motsatt sig utlämning av
                information för sammanhållen journalföring men sedan ångrar sig och vill att information delas med andra
                vårdgivare måste samtyckestypen "Utlämning sammanhållen journalföring" återkallas, därefter måste ett
                nytt samtycke för utlämning läggas upp där patienten står som informerad. Utan ett nytt samtycke kommer
                ingen data delas till andra vårdgivare.
            </div>

            <div class="visaMakulerade">
                <label
                    class="checkbox-container checkbox-container-samtycke"
                    style="margin-right: 30px; padding-top: 5px"
                    >Visa ej giltiga
                    <input class="mr-1 checkbox-samtycke" type="checkbox" @click="hideMakulerade = !hideMakulerade" />
                    <span class="checkmark checkmark-samtycke" />
                </label>
            </div>

            <custom-action-table
                v-if="patientsamtycken"
                class="no-top actionTableSamtycke"
                :selectableRows="false"
                :columns="samtyckeTableColumns"
                :items="
                    hideMakulerade
                        ? patientsamtycken.filter((samtycke) => samtycke.is_nullified === false)
                        : patientsamtycken
                "
                :actions="samtyckeListActions"
            ></custom-action-table>
            <div class="mt-2" v-if="!this.loadedPatientsamtyckenFinished">
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
                <hr />
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
                <hr />
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
                <hr />
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
                <hr />
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
                <hr />
                <skeleton-box width="100%" height="30px" radius="4px"></skeleton-box>
            </div>
            <div v-if="this.loadedPatientsamtyckenFinished && patientsamtycken.length === 0">
                <br /><br />
                <label class="saknar-samtycken"
                    ><img src="@/assets/info_dark.svg" /> Patienten saknar samtycken. Välj Lägg till patientsamtycke för
                    att lägga till data.</label
                >
            </div>
        </div>
    </div>
</template>

<script>
import { flashMessage } from "@/utils";
import BaseTab from "@/tabs/mixins/BaseTab";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import MakuleraSamtycke from "@/tabs/Samtycke/MakuleraSamtycke.vue";
import { mapActions, mapGetters } from "vuex";
import dialogEvents from "@/dialogEvents";
import SkeletonBox from "@/components/widgets/Skeleton.vue";
import { truncate } from "lodash";
import SamtyckesForm from "@/tabs/Samtycke/SamtyckesForm.vue";

const noNull = (str) => {
    return str || "";
};

const SAMTYCKE_TABLE_COLUMNS = [
    {
        name: "inhamtat_datum",
        title: "Datum",
        template: (item) => {
            return item.inhamtat_datum ? `<span style="font-size: 14px;">` + item.inhamtat_datum + `</span>` : "";
        },
    },
    {
        name: "typavsamtycke",
        title: "Typ av samtycke",
        template: (item) => {
            return noNull(item.typavsamtycke_display.namn);
        },
    },
    {
        name: "svar",
        title: "Samtycker",
        template: (item) => {
            switch (item.svar) {
                case "ej informerad":
                    return `<span class="ml-1">Ej informerad</span>`;
                case "informerad":
                    return `<img :src="fetchImg('samtycker.svg')" /> <span class="ml-1"> Informerad</span>`;
                case "samtycker":
                    return `<img :src="fetchImg('samtycker.svg')" /> <span class="ml-1"> Samtycker</span>`;
                case "samtycker ej":
                    return `<img :src="fetchImg('samtyckerEJ.svg')" /> <span class="ml-1"> Samtycker ej</span>`;
                case "motsätter sig":
                    return `<img :src="fetchImg('samtyckerEJ.svg')" /> <span class="ml-1"> Motsätter sig</span>`;
                default:
                    return "-";
            }
        },
    },
    {
        name: "inhamtat_av",
        title: "Inhämtat av",
        template: (item) => {
            if (item.inhamtat_av_profil) {
                return item.inhamtat_av_profil_display?.user.name;
            } else return truncate(item.inhamtat_av_annan || "", { length: 30 });
        },
    },
    {
        name: "inhamtningssatt",
        title: "Inhämtningssätt",
        template: (item) => {
            return noNull(item.inhamtningssatt_display?.displayName);
        },
    },
    {
        name: "samtyckestyp",
        title: "Samtyckestyp",
        template: (item) => {
            return noNull(item.typavsamtycke_display.samtyckestyp_display);
        },
    },
    {
        name: "is_nullified",
        title: "Status",
        template: (item) => {
            if (item.is_nullified === true) {
                return (
                    '<status-widget tooltip="Makulerat av ' +
                    item.nullified_by_display.user.name +
                    ": " +
                    item.nullifiedReason +
                    '" status="makulerad" />'
                );
            } else if (item.recalled_at !== null) {
                return (
                    '<status-widget tooltip="Återkallat av ' +
                    item.recalled_by_display.user.name +
                    ", " +
                    item.recalled_at.substring(0, 10) +
                    '" status="aterkallad" />'
                );
            } else if (item.is_current === false) {
                return (
                    '<status-widget tooltip="Giltigt till ' + item.giltigt_till + '" status="giltighetstidPasserad" />'
                );
            } else {
                return `<status-widget status="aktuell" />`;
            }
        },
    },
];

const SAMTYCKE_ROW_ACTIONS = [
    {
        name: "editSamtycke",
        title: "Redigera",
        img: "edit.svg",
        condition: (item) => {
            return !(item.is_nullified || item.is_recalled || !item.is_current);
        },
    },
    {
        name: "recallSamtycke",
        title: "Återkalla samtycke",
        img: "revoke.svg",
        condition: (item) => {
            return !(item.is_nullified || item.is_recalled || !item.is_current);
        },
    },
    {
        name: "makuleraSamtycke",
        title: "Makulera",
        img: "delete.svg",
        condition: (item) => {
            return !(item.is_nullified || !item.is_current);
        },
    },
];

export default {
    extends: BaseTab,
    patientBound: true,
    name: "Samtycke",
    tabName: "Samtycken",
    data() {
        return {
            notMakuleradeSamtycken: [],
            hideMakulerade: true,
            toggleSwitch: false,
            samtyckeTableColumns: SAMTYCKE_TABLE_COLUMNS,
            samtyckeListActions: SAMTYCKE_ROW_ACTIONS,
            loadedPatientsamtyckenFinished: false,
        };
    },
    components: {
        SkeletonBox,
        CustomActionTable,
    },
    computed: {
        ...mapGetters("patientData", ["patient"]),
        ...mapGetters("samtycke", ["patientsamtycken"]),
        sortedPatientSamtycken() {
            let sortedPatientSamtycken = [...this.patientsamtycken];

            return sortedPatientSamtycken.sort((a, b) => {
                let aStatus = 0;
                let bStatus = 0;

                if (a.recalled_at) aStatus = 2;
                else if (a.is_nullified) aStatus = 3;
                else if (!a.is_current) aStatus = 1;

                if (b.recalled_at) bStatus = 2;
                else if (b.is_nullified) bStatus = 3;
                else if (!b.is_current) bStatus = 1;

                if (bStatus === aStatus) {
                    return new Date(a.inhamtat_datum).getTime() - new Date(b.inhamtat_datum).getTime();
                } else return aStatus - bStatus;
            });
        },
    },
    methods: {
        ...mapActions("samtycke", ["loadPatientsamtycken", "aterkallaSamtycke"]),
        openSamtyckesForm() {
            let information = this.item;
            this.$store.dispatch("tabs/openTab", { component: SamtyckesForm, parameters: information });
        },
        async recallSamtycke(patientsamtycke) {
            const response = await this.aterkallaSamtycke({ patientsamtycke, user: this.patient.id });
            if (response.status === 200) {
                this.loadPatientsamtycken(this.patient.id);
                flashMessage("Samtycket har blivit återkallat");
            }
        },
    },
    async created() {
        try {
            await this.loadPatientsamtycken(this.patient.id);
            this.loadedPatientsamtyckenFinished = true;
        } catch (e) {
            //eslint-disable-next-line
            console.error(e);
        }

        /*this.$on('signSamtycke', async (data) => {
          await this.$store.dispatch('samtycke/signSamtycke', data)
        });*/

        this.$on("makuleraSamtycke", (data) => {
            dialogEvents.$emit("openPopup", {
                title: "Makulera Patientsamtycke",
                component: MakuleraSamtycke,
                attachedProps: {
                    id: data.id,
                },
            });
        });

        this.$on("recallSamtycke", (data) => {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Är du säker på att du vill återkalla samtycket?",
                buttons: [
                    {
                        title: "Ja, återkalla!",
                        type: "normal btn-alert btn-primary",
                        action: () => this.recallSamtycke(data),
                    },
                    {
                        title: "Avbryt",
                        type: "outline-secondary",
                    },
                ],
            });
        });

        this.$on("editSamtycke", async (data) => {
            await this.$store.dispatch("tabs/openTab", {
                component: SamtyckesForm,
                parameters: { ...data, editMode: true },
            });
        });
    },
    beforeDestroy() {
        this.$store.commit("samtycke/reset");
    },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables";
@import "@/style/deprecated_main";

.visaMakulerade {
    text-align: end;
    width: 100%;
    margin-right: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.patientsamtycke {
    display: inline-block;
    color: #277692;
    font-family: Roboto;
    letter-spacing: 0;
    line-height: 34px;
}

.samtyckeMain {
    background-color: #ffffff;
}

.saknar-samtycken {
    color: #354052;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

.saknar-samtycken > img {
    height: 16px;
    width: 16px;
    margin-bottom: 3px;
}

.hollow-button {
    height: 28px;
    color: #6c8188;
    font-family: Roboto Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    border: none;
    background-color: #ffffff;
    margin-left: 50px;
}

.hollow-button > img {
    margin-right: 8px;
}

.strike {
    color: #bebebe;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

.opaque {
    opacity: 0.7;
    color: #bebebe;
}

.checkbox-container-samtycke {
    align-items: end;
    color: #354052;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}

.samtyckenList {
    padding-bottom: 150px;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 2px 2px 7px 0 rgba(157, 166, 187, 0.5);
    border: 1px solid #999c9e !important;
    border-radius: 4px;
    flex: 1;
    margin: 0.125rem 30px 0 0 !important;
}

.dropdown-item {
    color: #6c8188 !important;
    padding: 17px 39px 18px 23px !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    line-height: 21px !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: #cee9f3 !important;
}

.dropdown-item > img {
    margin-right: 18px;
}
</style>
