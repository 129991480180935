<template>
    <div class="circular-progress-bar-wrapper">
        <div class="circular-progress-bar">
            <div class="outer-circle" :style="circularProgressBar()">
                <div class="inner-circle"></div>
            </div>
        </div>
        <div class="progress-value">{{ progressProps }}</div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        value: {
            type: Object,
        },
        progressProps: {
            type: Number,
            default: 10,
        },
        height: {
            type: Number,
            default: 0,
        },
        width: {
            type: Number,
            default: 0,
        },
        showPercentage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            progressValue: null,
            progressStartValue: 0,
            progressEndValue: 90,
            speed: 80,
            progress: 0,
        };
    },

    methods: {
        stopInterval() {
            clearInterval(this.progress);
        },
        circularProgressBar() {
            if (!Number.isNaN(this.progressProps)) {
                return {
                    background: `conic-gradient( #000 ${this.progressProps * 3.6}deg, #FFD1C2 0deg)`,
                    height: `${this.height}rem`,
                    width: `${this.width}rem`,
                };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.circular-progress-bar-wrapper {
    display: flex;
    justify-content: flex-end;

    .circular-progress-bar .outer-circle {
        background: conic-gradient(#000 0deg, #ffd1c2 0deg);
        min-width: 1.2rem;
        min-height: 1.2rem;
        border-radius: 50%;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        .inner-circle {
            min-width: 0.8rem;
            min-height: 0.8rem;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
        }
    }
    .circular-progress-bar {
        margin-right: 0.1rem;
    }
    &::after {
        content: "%";
    }
    .progress-value {
        min-width: 1.75rem;
        text-align: right;
    }
}
</style>
