// Taken from https://gist.github.com/mjackson/5311256

const EVENT_COLORS = [
    [71, 175, 130],
    [254, 160, 129],
    [134, 108, 228],
];

function rgbToHsl(r, g, b) {
    (r /= 255), (g /= 255), (b /= 255);

    var max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    var h,
        s,
        l = (max + min) / 2;

    if (max == min) {
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }

        h /= 6;
    }

    return [h, s, l];
}

function hue2rgb(p, q, t) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
}

function hslToRgb(h, s, l) {
    var r, g, b;

    if (s == 0) {
        r = g = b = l; // achromatic
    } else {
        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;

        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return [r * 255, g * 255, b * 255];
}

function getHexFromRgb(eventColor) {
    let returnString = "#";

    eventColor.forEach((color) => {
        let hex = Math.round(color).toString(16);
        if (Math.round(color) < 16) hex = "0" + hex;
        hex = hex.toUpperCase();
        returnString += hex;
    });
    return returnString;
}

function getTextFromBaseColor(eventColor) {
    let textColorHsl = rgbToHsl(eventColor[0], eventColor[1], eventColor[2]);

    textColorHsl[2] = textColorHsl[2] * 0.5;

    let textColorRgb = hslToRgb(textColorHsl[0], textColorHsl[1], textColorHsl[2]);
    return textColorRgb;
}

export { EVENT_COLORS, getHexFromRgb, getTextFromBaseColor };
