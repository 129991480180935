<template>
    <div class="journalloggar">
        <admin-lock-border />
        <div>
            <h2 class="headerText">Sök journallogg</h2>
        </div>

        <search-filter id="search-filter" v-model="filterValues" @input="handleSearch" :fields="filterFields">
            <template v-slot:header-filters>
                <FormulateInput class="search-field" name="q" placeholder="Sök journallogg" v-model="q" />
            </template>
            <template v-slot:fields>
                <FormulateInput type="text" name="created_by__user__first_name__icontains" label="Användare förnamn" />
                <FormulateInput type="text" name="created_by__user__last_name__icontains" label="Användare efternamn" />
                <FormulateInput type="text" name="created_by__yrkestitel" label="Yrkestitel" />
                <FormulateInput type="text" name="created_by__orgUnit__name__icontains" label="Vårdenhet" />
                <FormulateInput type="datepicker" name="created_at__date__gte" label="Datum fr.o.m." />
                <FormulateInput type="datepicker" name="created_at__date__lte" label="Datum t.o.m." />
                <FormulateInput type="text" name="patient__personId" label="Patient PersonID" />
            </template>
        </search-filter>

        <kliniken-table
            paginationStyle="paging"
            :columns="columns"
            :items="results"
            :count="count"
            :limit="limit"
            :currentPage="currentPage"
            :selectedItem="selectedItem"
            @sort="sort"
            @loadPage="loadPage"
            @changeLimit="changeLimit"
            @tableRowClicked="tableRowClicked"
        />
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import tabIcon from "@/assets/blank.png";
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import SearchFilter from "@/components/SearchFilter.vue";
import KlinikenTable from "@/components/Table/KlinikenTable.vue";

const TABLE_COLUMNS = [
    {
        title: "Användare",
        name: "created_by.user.name",
        sort: "created_by__user__name",
    },
    {
        title: "Yrkestitel",
        name: "created_by.yrkestitel",
    },
    {
        title: "Vårdenhet",
        name: "created_by.orgUnit.name",
        sort: "created_by__orgUnit__name",
    },
    {
        title: "Datum",
        name: "created_at",
        sort: "created_at",
        type: "datetime",
    },
    {
        title: "Patient",
        name: "patient.personId",
        sort: "patient__personId",
    },
    {
        title: "Tabb",
        name: "var",
        sort: "var",
    },
    {
        title: "Åtgärd",
        name: "handling_display",
        sort: "handling",
    },
    {
        title: "Dokument",
        name: "dokument_str",
    },
    {
        title: "Utlämnat till",
        name: "mottagare",
        sort: "mottagare",
    },
];

export default {
    extends: BaseTab,
    patientBound: false,
    name: "Journalloggar",
    tabName: "Journalloggar",
    icon: tabIcon,
    components: {
        AdminLockBorder,
        CustomActionTable,
        SearchFilter,
        KlinikenTable,
    },
    data() {
        return {
            q: "",
            filterValues: {},
            columns: TABLE_COLUMNS,
            items: [],
            sortField: "",
            selectedItem: null,
            currentPage: 1,
        };
    },
    computed: {
        ...mapGetters("journalloggar", ["results", "count", "limit", "offset", "params"]),

        filterFields() {
            return {
                q: { label: "Sök" },
                created_by__user__first_name__icontains: { label: "Användare förnamn" },
                created_by__user__last_name__icontains: { label: "Användare efternamn" },
                created_by__yrkestitel: { label: "Yrkeskategori" },
                created_by__orgUnit__name__icontains: { label: "Vårdenhet" },
                created_at__date__gte: { label: "Datum fr.o.m." },
                created_at__date__lte: { label: "Datum t.o.m. " },
                patient__personId: { label: "Patient PersonID" },
            };
        },
    },
    watch: {
        currentId() {
            if (this.currentId !== this.selectedItem?.patient) {
                this.selectedItem = null;
            }
        },
    },
    methods: {
        ...mapMutations("journalloggar", ["setLimit"]),
        ...mapActions("journalloggar", ["load", "setOffset", "setParams", "resetParams"]),

        async handleSearch(data) {
            this.filterValues = data;
            this.currentPage = 1;
            this.setOffset(0);
            await this.fetchResults();
        },

        async fetchResults() {
            let params = {
                ...this.filterValues,
                ...{
                    ordering: this.sortField,
                },
            };

            await this.clearBlankStrings(params);
            await this.setParams(params);
            await this.load();
        },

        async sort(field) {
            this.sortField = field;
            await this.fetchResults();
        },

        tableRowClicked(item) {
            if (item.patient?.id) {
                console.log("patient", item.patient);
                this.selectedItem = item;
                this.$store.dispatch("patientData/loadFromBackend", {
                    id: item.patient.id,
                    failSilently: false,
                });
            }
        },

        async loadPage(page) {
            this.currentPage = page;
            const offset = (page - 1) * this.limit;
            this.setOffset(offset);
            await this.fetchResults();
        },

        async changeLimit(limit) {
            this.currentPage = 1;
            this.setOffset(0);
            this.setLimit(limit);
            if (this.count > 0) await this.fetchResults();
        },

        clearBlankStrings(params) {
            for (let key in params) {
                if (params[key] === null || params[key] === undefined || params[key] === "") delete params[key];
                else if (Array.isArray(params[key])) {
                    params[key] = params[key].filter((el) => {
                        return el !== "";
                    });
                    if (params[key] === []) delete params[key];
                }
            }
            return params;
        },

        beforeDestroy() {
            this.$store.commit("journalloggar/reset");
        },
    },
};
</script>
<style lang="scss">
.journalloggar {
    #search-filter {
        margin: 20px 0;
    }
}
</style>
