<template>
    <div class="journalanteckningsmall">
        <div class="header">
            <h1 v-if="Boolean(this.parameters?.isEditing)">Redigera journalanteckningsmall</h1>
            <h1 v-else-if="Boolean(this.parameters?.isDuplicating)">Duplicera journalanteckningsmall</h1>
            <h1 v-else>Ny journalanteckningsmall</h1>
            <p>Lägg till data i de fält du vill ska ingå i mallen. Det går bra att lämna fält tomma.</p>
            <div>
                <FormulateInput
                    label="Välj journaltyp"
                    type="select"
                    :options="anteckningstyper"
                    @input="anteckningstyperChange"
                    v-model="valdAnteckningstyp"
                    :disabled="typeof parameters !== 'undefined'"
                    placeholder="Vänligen välj en journaltyp"
                />
            </div>
        </div>

        <FormulateForm v-model="values" :errors="formError" :schema="schema" @submit="handleSubmit" ref="form">
            <FormulateInput id="namn" name="namn" label="Namn mall" type="text" v-if="schema && schema.length > 0" />
        </FormulateForm>

        <ButtonGroup>
            <Button @click="submitForm">Spara</Button>
            <Button @click="cancel" variant="alert" size="secondary">Avbryt</Button>
        </ButtonGroup>
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import tabIcon from "@/assets/skapa_journal.svg";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";
import { getConfig, klinikenApi } from "@/api";
import { flashMessage, openDialog } from "@/utils";
import { cloneDeep, isArray, isEmpty, isEqual, isObject } from "lodash";

export default {
    extends: BaseTab,
    name: "Journalanteckningsmall",
    tabName: "Journalanteckningsmall",
    icon: tabIcon,
    components: { Button, ButtonGroup },
    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            values: {},
            anteckningstyper: [],
            anteckningstyp: {
                sokorsstruktur: [],
            },
            valdAnteckningstyp: "",
            formError: {},
        };
    },
    computed: {
        hasUnsavedData() {
            return !isEqual({}, this.clean(this.values));
        },
        schema: function () {
            return this.convertSchema(this.anteckningstyp.sokordsstruktur);
        },
    },
    methods: {
        clean(value) {
            if (isArray(value)) {
                return value.map((v) => this.clean(v)).filter((v) => (isObject(v) ? !isEmpty(v) : v !== ""));
            } else if (isObject(value)) {
                return Object.fromEntries(
                    Object.entries(value)
                        .map(([k, v]) => [k, this.clean(v)], this)
                        .filter(([_, v]) => (isObject(v) ? !isEmpty(v) : v !== ""))
                );
            }
            return value;
        },
        async handleSubmit(data) {
            const payload = {
                namn: data.namn,
                anteckningstyp:
                    this.anteckningstyp && this.anteckningstyp.pk
                        ? this.anteckningstyp.pk
                        : this.parameters.anteckningstyp,
                struktur: data,
            };
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });

            try {
                const response = await (this.parameters && Object.keys(this.parameters).length > 0 && this.parameters.id
                    ? klinikenApi.put(
                          "/anteckning/journalanteckningsmallar/" + this.parameters.id + "/",
                          payload,
                          getConfig()
                      )
                    : klinikenApi.post("/anteckning/journalanteckningsmallar/", payload, getConfig()));

                if (response.status === 201) {
                    const newObj = response.data;
                    this.$store.dispatch("journalanteckningsmall/addJournalanteckningsmall", newObj);
                    flashMessage("Journalanteckningsmall har skapats!");
                    await this.$store.dispatch("tabs/closeTab", this.tabId);
                }

                if (response.status === 200) {
                    const journalanteckningsmallar =
                        this.$store.getters["journalanteckningsmall/getJournalanteckningsmallar"];

                    const newArray = journalanteckningsmallar.map((item) =>
                        item.id == response.data.id ? { ...response.data } : item
                    );

                    this.$store.dispatch("journalanteckningsmall/setJournalanteckningsmallar", [...newArray]);

                    flashMessage("Journalanteckningsmall har uppdaterats!");
                    await this.$store.dispatch("tabs/closeTab", this.tabId);
                }
            } catch (error) {
                this.formError = error.response.data;
                openDialog(error.response.data["namn"][0] ?? "", "warning");
            } finally {
                await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
            }
        },
        submitForm() {
            this.$refs.form.formSubmitted();
        },
        async cancel() {
            this.closeTab(this.hasUnsavedData);
        },
        async anteckningstyperChange(value) {
            const response = await klinikenApi.get(`anteckning/typer/${value}/`, getConfig({}));
            this.anteckningstyp = cloneDeep(response.data);
        },
        convertSokordsstruktur(sokorsstruktur) {
            if (sokorsstruktur && sokorsstruktur.length > 0) {
                return sokorsstruktur
                    .filter((item) => item.aktiv && item.sokord.aktiv)
                    .sort((a, b) => b.sortering - a.sortering)
                    .flatMap(function (item) {
                        let schema = {
                            name: `${item.pk}`,
                            label: item.sokord.namn,
                            help: item.beskrivning,
                            type: item.typ,
                            children: this.convertSokordsstruktur(item.sokordsstruktur),
                            readonly: item.skrivskyddad,
                            disabled: false,
                            class: "",
                            id: "",
                        };

                        switch (schema.type) {
                            case "datum":
                                schema.type = "datepicker";
                                break;
                            case "heading":
                                schema.type = "group";
                                schema.class = "group-1";
                                schema.id = "heading" + " " + item.sokord.namn;
                                break;
                            case "dropdown":
                                schema.type = "select";
                                schema.options = item.alternativ.split(";");
                                break;
                            case "integer":
                                schema.type = "knumber";
                                break;
                            case "autocomplete":
                                schema.type = "autocomplete";
                                schema.url = item.alternativ;
                                break;
                            case "date":
                                schema.type = "date";
                                schema.disabled = true;
                                break;
                            case "hidden":
                                schema.label = "";
                                break;
                        }
                        if (schema.type !== "group" && schema.children) {
                            const children = schema.children;
                            schema.id = "heading-2" + " " + item.sokord.namn;
                            schema.children = [];
                            return [
                                schema,
                                {
                                    name: `group_${item.pk}`,
                                    type: "group",
                                    children,
                                    class: "group-2",
                                },
                            ];
                        }
                        return schema;
                    }, this);
            }
        },
        convertSchema(sokorsstruktur) {
            return this.convertSokordsstruktur(sokorsstruktur);
        },
    },
    async created() {
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });

        const response = await klinikenApi.get("/anteckning/typer/", getConfig());

        const arr = response.data.results.map((item) => {
            return { value: item.pk, label: item.namn, ...item };
        });

        this.anteckningstyper = [{ value: null, label: "" }, ...arr];

        if (this.parameters) {
            this.values = cloneDeep(this.parameters.struktur);
            this.valdAnteckningstyp = `${this.parameters.anteckningstyp}`;
        }

        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.journalanteckningsmall {
    ::v-deep .formulate-input-group-repeatable {
        flex-direction: column;
        width: 100%;
        margin: 20px 0px;
        height: 100%;
    }

    ::v-deep .formulate-form {
        label {
            font-weight: 400;
            color: #277692;
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 5px;
        }
    }

    ::v-deep label[id^="heading"] + div {
        position: relative;
        padding-left: 30px;
    }

    ::v-deep label[id^="heading"] + div::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0px; /* Adjust the top offset as needed */
        bottom: 0px; /* Adjust the bottom offset as needed */
        width: 3px;
        background: #c8e6ec;
    }

    ::v-deep label[id^="heading"] {
        margin: 20px 0px;
        height: 31px;
        font-weight: 400;
        color: #277692;
        font-size: 20px;
        line-height: 21px;
        border: none;
        outline: none;
        background-color: inherit;
        display: block;
    }

    ::v-deep label[id^="heading-2"] + div {
        position: relative;
        padding-left: 30px;
    }

    ::v-deep label[id^="heading-2"] + div::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0px;
        bottom: 0px;
        width: 3px;
        background: #c8e6ec;
    }

    margin-bottom: 60px;
    padding: 0 30px;

    .header {
        width: 724px;

        p {
            font-size: 16px;
            color: $color-text-black;
            line-height: 22px;
            margin-top: 24px;
            margin-bottom: 14px;
        }

        div {
            background-color: $color-blue-light;
            padding-top: 16px;
            padding-bottom: 25px;
            padding-left: 21px;
            padding-right: 21px;
        }
    }

    .formulate-form {
        display: flex;
        flex-direction: column;
        padding: 10px;
        max-width: 720px;
    }

    ::v-deep .formulate-input:has(#namn) {
        order: -1;
    }

    .button-group {
        margin-top: 24px;
    }
}
</style>
