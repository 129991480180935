import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

const getDefaultState = () => {
    return {
        resurser: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        resurser(state) {
            return state.resurser;
        },
        resurs(state) {
            return state.resurs;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setResurser: (state, data) => {
            state.resurser = data;
        },
    },
    actions: {
        async create(context, data) {
            try {
                return await klinikenApi.post(`operation/resurser/`, data.resurs, getConfig());
            } catch (e) {
                return { error: e };
            }
        },
        async update(context, data) {
            try {
                return await klinikenApi.put(`operation/resurser/${data.resurs.id}/`, data.resurs, getConfig());
            } catch (e) {
                return { error: e };
            }
        },
        async load({ commit }, failSilently = false) {
            try {
                const response = await klinikenApi.get(`operation/resurser/`, getConfig());
                const resurser = response.data.results;
                commit("setResurser", resurser);
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta uppgifter om resurser." + getErrorMessage(e), "warning");
                }
            }
        },
    },
};
