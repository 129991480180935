<script>
import BaseTab from "@/tabs/mixins/BaseTab.js";
import HollowButton from "@/components/widgets/HollowButton.vue";
import PlusCircleBlue from "@/assets/plus_circle_blue.svg";
import FormLag from "@/tabs/Skreg/Lag/FormLag.vue";
import { mapActions, mapGetters } from "vuex";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import tabIcon from "@/assets/narvarostatistik.svg";

const noNull = (str) => {
    return str || "";
};

const TABLE_COLUMNS = [
    {
        name: "namn",
        title: "Namn",
        template: (item) => {
            return noNull(item.namn);
        },
    },
    {
        name: "idrott",
        title: "Idrott",
        template: (item) => {
            return noNull(item.idrott_display.namn);
        },
    },
    {
        name: "division",
        title: "Division",
        template: (item) => {
            return noNull(item.division);
        },
    },
    {
        name: "plats",
        title: "Plats",
        template: (item) => {
            if (item.plats_display) return noNull(item.plats_display.namn);
            else return "";
        },
    },
];

const TABLE_ACTIONS = [
    {
        name: "edit",
        title: "Redigera",
        img: "edit.svg",
        condition: () => {
            return true;
        },
    },
];

export default {
    extends: BaseTab,
    name: "ListLag",
    tabName: "Lag",
    icon: tabIcon,

    components: {
        HollowButton,
        CustomActionTable,
    },

    data() {
        return {
            PlusCircleBlue,
            TableColumns: TABLE_COLUMNS,
            TableActions: TABLE_ACTIONS,
        };
    },

    computed: {
        ...mapGetters("skreg/lag", ["lag"]),
    },

    methods: {
        ...mapActions("skreg/lag", ["load"]),
        async createLag() {
            await this.$store.dispatch("tabs/openTab", { component: FormLag });
        },
        async updateLag(lag) {
            await this.$store.dispatch("tabs/closeTab", FormLag);
            await this.$store.dispatch("tabs/openTab", {
                component: FormLag,
                parameters: { lag: lag },
            });
        },
    },
    async created() {
        await this.load();
        this.$on("edit", async (lag) => {
            await this.updateLag(lag);
        });
    },
};
</script>

<template>
    <div>
        <h2>
            Lag
            <hollow-button @click="createLag" :icon="PlusCircleBlue" label="Skapa nytt lag" />
        </h2>
        <custom-action-table
            class="no-top"
            :selectableRows="false"
            :items="lag"
            :columns="TableColumns"
            :actions="TableActions"
        />
    </div>
</template>

<style lang="scss" scoped>
.hollow-button {
    margin-left: 50px;
}
</style>
