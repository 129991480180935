<script>
export default {
    name: "InputText",
    props: {
        error: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        klass: function () {
            return { error: this.error };
        },
    },
};
</script>

<template>
    <input type="text" :class="klass" v-bind="$attrs" v-on="$listeners" />
</template>

<style lang="scss" scoped>
@import "@/style/mixins/input";

input[type="text"] {
    @include input-mixin;
}
</style>
