<template>
    <div :class="context.classes.element" :data-type="context.type">
        <FormulateSlot name="prefix" :context="context">
            <component :is="context.slotComponents.prefix" v-if="context.slotComponents.prefix" :context="context" />
        </FormulateSlot>
        <date-picker
            v-model="context.model"
            v-bind="$attrs"
            v-on="$listeners"
            @blur="context.blurHandler"
            @close="context.blurHandler"
        >
            <template v-slot:icon-calendar><img src="@/assets/calendar.svg" /></template>
            <template v-slot:icon-clear><img src="@/assets/cross_circle.svg" /></template>
        </date-picker>
        <FormulateSlot name="suffix" :context="context">
            <component :is="context.slotComponents.suffix" v-if="context.slotComponents.suffix" :context="context" />
        </FormulateSlot>
    </div>
</template>

<script>
import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
import DatePicker from "@/components/widgets/inputs/DateWidget.vue";

export default {
    name: "FormulateDatePicker",
    mixins: [FormulateInputMixin],
    components: {
        DatePicker,
    },
};
</script>
