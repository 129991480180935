import { getConfig, klinikenApi } from "@/api";
import { cloneDeep } from "lodash";

const getDefaultState = () => {
    return {
        aktivitetstyper: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        aktivitetstyper(state) {
            return state.aktivitetstyper;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setAktivitetstyp: (state, aktivitetstyp) => {
            const index = state.aktivitetstyper.findIndex((i) => i.id === aktivitetstyp.id);

            if (index !== -1) {
                state.aktivitetstyper[index] = aktivitetstyp;
            } else {
                state.aktivitetstyper.push(aktivitetstyp);
            }

            state.aktivitetstyper = [...state.aktivitetstyper];
        },
        setAktivitetstyper: (state, payload) => (state.aktivitetstyper = payload),
    },
    actions: {
        async save({ dispatch, commit }, aktivitetstyp) {
            let response;
            if (aktivitetstyp.id === undefined) {
                response = await dispatch("create", aktivitetstyp);
            } else {
                response = await dispatch("update", aktivitetstyp);
            }
            commit("setAktivitetstyp", cloneDeep(response.data));
            return response;
        },
        async create({}, aktivitetstyp) {
            return await klinikenApi.post(`skreg/aktivitetstyper/`, aktivitetstyp, getConfig());
        },
        async update({}, aktivitetstyp) {
            return await klinikenApi.put(`skreg/aktivitetstyper/${aktivitetstyp.id}/`, aktivitetstyp, getConfig());
        },
        async load({ commit }) {
            const response = await klinikenApi.get(`skreg/aktivitetstyper/`, getConfig());
            commit("setAktivitetstyper", response.data);
        },
    },
};
