<template>
    <BankIDForm submit-button="Signera" @submit="submit" @cancel="cancel" :personnummer="personnummer" />
</template>

<script setup>
    import { useStore } from "@/store";
    import { computed, onMounted, toRefs } from "vue";
    import { getConfig, klinikenApi } from "@/api";
    import BankIDForm from "@/components/Auth/BankIDForm.vue";

    const store = useStore();

    const props = defineProps({
        url: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    });

    const { data, url } = toRefs(props);

    const personnummer = computed(() => {
        return store.state.userData.userData?.personId;
    });

    const emit = defineEmits(["poll", "error", "cancel"]);

    const submit = async () => {
        try {
            const response = await klinikenApi.post(`${url.value}sign/bankid/init/`, data.value, getConfig());
            emit("poll", {
                message: "Starta BankID-appen i din mobil och tryck på Scanna QR-kod. Skanna sedan QR-koden nedan.",
                url: `${url.value}sign/bankid/poll/`,
                data: response.data,
                type: "bankid",
            });
        } catch (error) {
            if (error.response.status === 400) {
                emit("error", error.response.data);
            } else {
                emit("error", "Det gick inte att logga in.");
            }
        }
    };

    const cancel = () => {
        emit("cancel");
    };

    // Call submit function when component is mounted
    onMounted(submit);
</script>
