<template>
    <div>
        <form @submit.prevent="send" id="receiver-details" autocomplete="off">
            <h2>Mottagare</h2>
            <div class="radiobutton-line">
                <label class="radiobutton-container">
                    Receptbrevlådan
                    <input type="radio" :value="true" v-model="receiverRBL" />
                    <span class="checkmark"></span>
                </label>
                <label class="radiobutton-container">
                    Enskilt apotek
                    <input type="radio" :value="false" v-model="receiverRBL" />
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="form-group k3-form">
                <input-wrapper
                    class="colspan whole"
                    v-if="formComponents.length && !receiverRBL"
                    :component="formComponents[0].component"
                    v-bind="formComponents[0].parameters"
                    v-on="formComponents[0].events"
                    :label="formComponents[0].label"
                />
                <input v-else-if="formComponents.length" type="hidden" name="receiver" :value="ehmGlnReceptbrevladan" />

                <input-wrapper
                    :disabled="patient.personIdType == '1.2.752.129.2.1.3.1'"
                    class="colspan whole"
                    v-if="formComponents.length"
                    :component="formComponents[1].component"
                    v-bind="formComponents[1].parameters"
                    v-on="formComponents[1].events"
                    :label="formComponents[1].label"
                />
                <input-wrapper
                    class="colspan whole"
                    v-if="formComponents.length"
                    :component="formComponents[2].component"
                    v-bind="formComponents[2].parameters"
                    v-on="formComponents[2].events"
                    :label="formComponents[2].label"
                />
                <input-wrapper
                    class="colspan whole"
                    v-if="formComponents.length && deliveryAddress && !receiverRBL"
                    :component="formComponents[3].component"
                    v-bind="formComponents[3].parameters"
                    v-on="formComponents[3].events"
                    :label="formComponents[3].label"
                />
            </div>
            <ButtonGroup>
                <Button type="submit"><img src="@/assets/send_white.svg" />Skicka</Button>
                <Button type="button" variant="contour" size="secondary" @click="close()">Avbryt</Button>
            </ButtonGroup>
        </form>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import dialogEvents from "@/dialogEvents";
import linkEvents from "@/linkEvents";
import widgets from "@/components/widgets";
import InputWrapper from "@/components/widgets/InputWrapper.vue";
import $ from "jquery";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

// const EA_LOC_CODE_RECEPTBREVLADAN = import.meta.env.VITE_EA_LOC_CODE_RECEPTBREVLADAN || "7350045511119";

const formComponents = [
    {
        label: "Sök apotek på namn, postnummer eller postort",
        component: widgets.AutoCompleteWidget,
        parameters: {
            name: "receiver",
            apisearch: "/apotek/apotek/",
            displayCode: false,
            required: true,
            map: (el) => {
                return {
                    id: el.EA_LOC_CODE,
                    code: el.VKOD,
                    displayName: el.BENAMNING_LANG,
                };
            },
        },
    },
    {
        label: "Förmånsberättigad patient",
        component: widgets.SelectWidget,
        parameters: {
            name: "careCoverageType",
            //required: true,
            options: [
                { text: "Nej", value: "U" },
                { text: "Ja", value: "R" },
            ],
        },
    },
    {
        label: "Kommentar till apotek",
        component: widgets.TextAreaWidget,
        parameters: {
            name: "prescribersComment",
            maxlength: 35,
            filter: /[^\u0020-\u007E\u00A1-\u00FF]+/g,
        },
    },
    {
        label: "Leveransinformation",
        component: widgets.TextAreaWidget,
        parameters: {
            name: "deliveryAddress",
            maxlength: 35,
            filter: /[^\u0020-\u007E\u00A1-\u00FF]+/g,
            required: true,
        },
    },
];

export default {
    name: "PrescriptionSendForm",
    props: ["standalone"], //Remove when PrescriptionSet.vue is deprecated
    components: {
        ...widgets,
        InputWrapper,
        Button,
        ButtonGroup,
    },
    data() {
        return {
            uid: new Date().getTime().toString(),
            deliveryAddress: false,
            receiverRBL: true,
            formComponents: [],
        };
    },
    computed: {
        ...mapGetters("patientData", ["personId", "careCoverage", "name", "address", "patient"]),
        ...mapGetters("systemData", ["ehmGlnReceptbrevladan"]),
        ...mapState("prescriptionSet", ["prescriptionSet"]),
    },
    methods: {
        getReceiver() {
            // Receiver can be an Apotek-object, String or null
            if (this.prescriptionSet.receiver) {
                return this.ehmGlnReceptbrevladan || this.prescriptionSet.receiver;
            } else return null;
        },
        getReceiverDetails() {
            let details = {};
            $("form#receiver-details")
                .serializeArray()
                .forEach((keyValuePair) => {
                    var key = keyValuePair.name;
                    var value = keyValuePair.value;
                    try {
                        value = JSON.parse(value);
                        // eslint-disable-next-line
                    } catch (e) {}
                    details[key] = value;
                });
            return details;
        },
        receiverChanged(value) {
            this.deliveryAddress = false;
            if (value) {
                value = JSON.parse(value);
                if (value.code.trim() === "DIAP") this.deliveryAddress = true;
            }
        },
        send() {
            dialogEvents.$emit("sendReceiverDetails", this.getReceiverDetails());
            this.close();
        },
        close() {
            dialogEvents.$emit("closePopup");
        },
    },
    created() {
        linkEvents.$on("receiver_changed", this.receiverChanged);
    },
    mounted() {
        // TODO: this needs to be moved in order for jest to apply variables before creation.
        this.receiverRBL = this.getReceiver() == this.ehmGlnReceptbrevladan;

        try {
            let VKOD = this.prescriptionSet.receiver.VKOD.trim();
            if (VKOD === "DIAP") this.deliveryAddress = true;
            // eslint-disable-next-line
        } catch (e) {}
        this.formComponents = formComponents;
        this.formComponents[0].parameters.value = this.getReceiver();
        if (this.careCoverage || this.patient.personIdType == "1.2.752.129.2.1.3.1") {
            this.formComponents[1].parameters.value = "R";
            this.formComponents[1].parameters.disabled = true;
        } else if (this.careCoverage) {
            this.formComponents[1].parameters.value = "R";
        } else {
            this.formComponents[1].parameters.value = "U";
        }
        this.formComponents[2].parameters.value = this.prescriptionSet.prescribersComment;
        this.formComponents[3].parameters.value = this.prescriptionSet.deliveryAddress || this.address;
    },
};
</script>
<style lang="sass" scoped>
#receiver-details
    position: relative
    padding: 0 80px 10px 80px

h2
    margin: 10px 0px 10px 0px !important

.radiobutton-line
    display: inline-block
    margin-top: 12px
    margin-bottom: 30px

    label:not(:first-of-type)
        margin-left: 32px
</style>
