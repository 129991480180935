<script>
export default {
    components: {},
    props: {
        summaryTableData: { type: Object },
    },
    data() {
        return {
            searchOptions: {},
            searchFilterValues: null,
            headers: ["", "Antal", "Timmar (h)"],
        };
    },

    async created() {},
};
</script>

<template>
    <div class="summaytabel">
        <table>
            <thead class="lightline-border-bottom">
                <tr>
                    <th class="header-vertical-padding-8 align-right" v-for="(header, index) in headers" :key="index">
                        <div>{{ header }}</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="body-header-row heading-style lightline-border-bottom">
                    <th colspan="1" class="padding-top">Antal genomförda aktiviteter</th>
                    <th colspan="1" class="padding-top align-right">
                        <p v-if="summaryTableData.antal_genomforda_aktiviteter">
                            {{ summaryTableData.antal_genomforda_aktiviteter }}
                        </p>
                        <p v-else>-</p>
                    </th>
                    <th colspan="1" class="padding-top align-right">
                        <p></p>
                    </th>
                </tr>
                <tr class="body-header-row heading-style lightline-border-bottom">
                    <th colspan="1" class="padding-top">Planerad Aktivitetstid</th>
                    <th colspan="1" class="padding-top align-right">
                        <p></p>
                    </th>
                    <th colspan="1" class="padding-top align-right">
                        <p v-if="summaryTableData.planerad_traningstid">
                            {{ summaryTableData.planerad_traningstid }}
                        </p>
                        <p v-else>-</p>
                    </th>
                </tr>

                <tr class="body-header-row heading-style lightline-border-bottom">
                    <th colspan="1" class="padding-top">Potentiell Exponering</th>
                    <th colspan="1" class="padding-top align-right">
                        <p></p>
                    </th>
                    <th colspan="1" class="padding-top align-right">
                        <p v-if="summaryTableData.potentiell_exponering">
                            {{ summaryTableData.potentiell_exponering }}
                        </p>
                        <p v-else>-</p>
                    </th>
                </tr>
                <tr class="body-header-row heading-style lightline-border-bottom">
                    <th colspan="1" class="padding-top">Potentiell Exponering Match</th>
                    <th colspan="1" class="padding-top align-right">
                        <p></p>
                    </th>
                    <th colspan="1" class="padding-top align-right">
                        <p v-if="summaryTableData.potentiell_exponering_match_timmar">
                            {{ summaryTableData.potentiell_exponering_match_timmar }}
                        </p>
                        <p v-else>-</p>
                    </th>
                </tr>
                <tr class="body-header-row heading-style lightline-border-bottom">
                    <th colspan="1" class="padding-top">Potentiell Exponering Träning</th>
                    <th colspan="1" class="padding-top align-right">
                        <p></p>
                    </th>
                    <th colspan="1" class="padding-top align-right">
                        <p v-if="summaryTableData.potentiell_exponering_traning_timmar">
                            {{ summaryTableData.potentiell_exponering_traning_timmar }}
                        </p>
                        <p v-else>-</p>
                    </th>
                </tr>
                <tr class="body-header-row heading-style lightline-border-bottom">
                    <th colspan="1" class="padding-top">Faktisk Exponering</th>
                    <th colspan="1" class="padding-top align-right">
                        <p></p>
                    </th>
                    <th colspan="1" class="padding-top align-right">
                        <p v-if="summaryTableData.faktisk_exponering">{{ summaryTableData.faktisk_exponering }}</p>
                        <p v-else>-</p>
                    </th>
                </tr>
                <tr class="body-header-row heading-style lightline-border-bottom">
                    <th colspan="1" class="padding-top">Faktisk Exponering Match</th>
                    <th colspan="1" class="padding-top align-right">
                        <p></p>
                    </th>
                    <th colspan="1" class="padding-top align-right">
                        <p v-if="summaryTableData.faktisk_exponering_match_timmar">
                            {{ summaryTableData.faktisk_exponering_match_timmar }}
                        </p>
                        <p v-else>-</p>
                    </th>
                </tr>
                <tr class="body-header-row heading-style lightline-border-bottom">
                    <th colspan="1" class="padding-top">Faktisk Exponering Träning</th>
                    <th colspan="1" class="padding-top align-right">
                        <p></p>
                    </th>
                    <th colspan="1" class="padding-top align-right">
                        <p v-if="summaryTableData.faktisk_exponering_traning_timmar">
                            {{ summaryTableData.faktisk_exponering_traning_timmar }}
                        </p>
                        <p v-else>-</p>
                    </th>
                </tr>
                <tr class="body-header-row heading-style lightline-border-bottom">
                    <th colspan="1" class="padding-top">Antal inträffade skador</th>
                    <th colspan="1" class="padding-top align-right">
                        <p v-if="summaryTableData.antal_intraffade_skador">
                            {{ summaryTableData.antal_intraffade_skador }}
                        </p>
                        <p v-else>-</p>
                    </th>
                    <th colspan="1" class="padding-top align-right">
                        <p></p>
                    </th>
                </tr>
                <tr class="body-header-row heading-style lightline-border-bottom">
                    <th colspan="1" class="padding-top">Antal förekomster av partiellt deltagande</th>
                    <th colspan="1" class="padding-top align-right">
                        <p v-if="summaryTableData.antal_forekomster_av_partiellt_deltagande">
                            {{ summaryTableData.antal_forekomster_av_partiellt_deltagande }}
                        </p>
                        <p v-else>-</p>
                    </th>
                    <th colspan="1" class="padding-top align-right"></th>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.summaytabel {
    table {
        border-collapse: collapse;
        width: 100%;
        thead {
            background-color: $color-bg-table-header;
            color: $color-table-header;
            font-family: Roboto, Medium;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .header-vertical-padding-8 {
        padding: 8px 0;
    }

    .padding-top {
        padding-top: 8px;
    }

    .align-right {
        text-align: right;
        padding-right: 3rem;
    }

    .lightline-border-bottom {
        border-bottom: 1px solid $color-table-row-line;
    }

    tbody {
        .heading-style {
            color: $color-table-header;
            font-family: Roboto, Medium;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            height: 1rem;
        }
        p {
            line-height: 1rem;
            display: inline-block;
            vertical-align: middle;
            color: $color-text-black;
            font-size: 14px;
        }
    }
}
</style>
