<template>
    <div>
        <template v-if="!preview">
            <input
                :name="name + 'Display'"
                :required="required"
                :maxlength="maxlength"
                :minlength="minlength"
                class="text-input"
                v-model="displayValue"
                @keypress="inputTypeNumber($event)"
                @change="validate()"
                @blur="$emit('blur')"
            />
            <input type="hidden" :name="name" :value="value_" />
            <input type="hidden" :name="name + 'Type'" :value="personIdType" />
        </template>
        <span v-else class="input-preview">
            {{ displayValue }}
        </span>
    </div>
</template>

<script>
export default {
    name: "PersonIdWidget",
    props: {
        name: String,
        value: String,
        required: Boolean,
        definition: Object,
        maxlength: undefined,
        minlength: undefined,
        personIdType: {
            type: String,
            //default: "1.2.752.129.2.1.3.1"
        },
        preview: Boolean,
    },
    data() {
        return {
            value_: this.value,
            displayValue: this.value || "",
        };
    },
    methods: {
        /*
            Emulate number field in IE10, not necessary in Chrome.
            IE10 removes illegal characters on blur, this will prevent their input like Chrome.
        */
        inputTypeNumber: function (event) {
            if (this.personIdType === "1.2.752.129.2.1.3.1") {
                // Personnummer
                var keyChar = event.charCode || event.keyCode;
                if (keyChar == 13 || keyChar == 45 || keyChar == 18)
                    // enter or '-' or space
                    return true;
                else if (keyChar < 48 || keyChar > 57) {
                    // not a number
                    event.preventDefault();
                } else {
                    return true;
                }
            }
            // TODO: add samordningsnummer // Reservnummer
            else return true;
        },
        validate() {
            //let personNummer = new RegExp('^(19|20)?[0-9]{2}((0[0-9])|(10|11|12))(([0-2][0-9])|(3[0-1]))[- ]?[0-9]{4}$');
            let personNummer = new RegExp("^(19|20)[0-9]{2}((0[0-9])|(10|11|12))(([0-2][0-9])|(3[0-1]))[- ]?[0-9]{4}$");
            /* ^(19|20)?[0-9]{2}((0[0-9])|(10|11|12))(([0-2][0-9])|(3[0-1])|(([7-8][0-9])|(6[1-9])|(9[0-1])))[- ]?[0-9]{4}$ */ // Possible versions, including samordningsnummer
            let currentDate = new Date();

            this.value_ = this.displayValue;

            if (this.displayValue.match(personNummer)) {
                // if input matches personnummer, translate to correct format
                //this.value_ = this.displayValue.replace(' ','').replace('-','');
                /*
                if (this.value_.length === 10) {// 10 numbers, add year
                    let currentYear = currentDate.getFullYear().toString().slice(2);
                    let bornYear = this.value_.slice(0,2);
                    if (bornYear <=  currentYear)
                        this.value_ = "20"+this.value_;
                    else
                        this.value_ = "19"+this.value_;
                }
                */

                //this.displayValue = this.value_.substr(0, 8) + '-' + this.value_.substr(8);
                let bornDate = new Date(
                    this.displayValue.slice(0, 4),
                    parseInt(this.displayValue.slice(4, 6)) - 1,
                    this.displayValue.slice(6, 8)
                );

                // Felaktig kontrollsiffra
                if (!this.luhnAlgorithm(this.value_.slice(2))) {
                    this.showWarning("Ej giltigt personnummer");
                } else if (bornDate.getTime() > currentDate.getTime()) {
                    /* || ((currentDate.getTime() - bornDate.getTime()) / year ) > 130) */ // Check for age > 120 not necessary at this point, since Regexp only allows for people born in the 1900s.
                    this.showWarning("Orimligt födelsedatum");
                } else {
                    this.showWarning(null);
                }
            } else this.showWarning("Känner ej igen format");
            /*
            if(this.personIdType === "1.2.752.129.2.1.3.1") { // Personnummer
                this.value_ = parseInt(this.value_.replace('-', ''));
                if((isNaN(this.value_)) && !this.required) {
                    this.value_ = undefined;
                    return true; // Allow empty value if value is not required.
                }
            }
            else {

            }
            */
            if (this.displayValue == "") this.showWarning(null);
            this.$emit("input", this.value_);
        },
        luhnAlgorithm(value) {
            if (/[^0-9-\s]+/.test(value)) return false;

            let nCheck = 0;
            let bEven = false;
            value = value.replace(/\D/g, "");

            for (var n = value.length - 1; n >= 0; n--) {
                var cDigit = value.charAt(n),
                    nDigit = parseInt(cDigit, 10);

                if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

                nCheck += nDigit;
                bEven = !bEven;
            }

            return nCheck % 10 == 0;
        },
        showWarning(message) {
            this.$emit("warning", message);
        },
    },
    watch: {
        value() {
            this.displayValue = this.value || "";
            this.validate();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/forms";
/* Bootstrap - End */

@import "@/style/deprecated_main";

form .error input,
form .error textarea,
form .error select,
form input.error,
form select.error,
form textarea.error {
    border-bottom: 4px solid #9e354b !important;
}
</style>
