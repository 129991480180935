<template>
    <ul class="kliniken-pagination">
        <li class="pagination-item">
            <button class="text first" @click="onClickFirstPage" :disabled="isInFirstPage">Första</button>
        </li>

        <li class="pagination-item">
            <button class="text" @click="onClickPreviousPage" :disabled="isInFirstPage">Föregående</button>
        </li>

        <!-- Synliga sidnummer -->
        <li class="pagination-item" v-for="page in visiblePages" :key="page.name">
            <button
                class="page-number"
                :class="{ active: isPageActive(page.name) }"
                @click="onClickPage(page.name)"
                :disabled="page.isDisabled"
            >
                {{ page.name }}
            </button>
        </li>
        <!---->

        <li class="pagination-item">
            <button class="text" @click="onClickNextPage" :disabled="isInLastPage || count < 1">Nästa</button>
        </li>

        <li class="pagination-item">
            <button class="text last" @click="onClickLastPage" :disabled="isInLastPage || count < 1">Sista</button>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 9,
        },
        count: {
            type: Number,
            required: true,
        },
        perPage: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
    },
    computed: {
        totalPages() {
            return Math.ceil(this.count / this.perPage);
        },
        visibleStartPage() {
            // När nuvarande sida är en av de första
            if (this.currentPage <= Math.ceil(this.maxVisibleButtons / 2)) {
                return 1;
            }

            // När nuvarande sida är en av de sista
            if (this.currentPage >= this.totalPages - Math.floor(this.maxVisibleButtons / 2)) {
                return this.totalPages - Math.min(this.totalPages, this.maxVisibleButtons) + 1;
            }

            // När nuvarande sida är någonstans emellan
            return this.currentPage - Math.floor(this.maxVisibleButtons / 2);
        },
        visibleEndPage() {
            return Math.min(this.visibleStartPage + this.maxVisibleButtons - 1, this.totalPages);
        },
        visiblePages() {
            const range = [];

            for (let i = this.visibleStartPage; i <= this.visibleEndPage; i++) {
                range.push({
                    name: i,
                    isDisabled: i === this.currentPage,
                });
            }

            return range;
        },
        isInFirstPage() {
            return this.currentPage === 1;
        },
        isInLastPage() {
            return this.currentPage === this.totalPages;
        },
    },
    methods: {
        onClickFirstPage() {
            this.$emit("pageChanged", 1);
        },
        onClickPreviousPage() {
            this.$emit("pageChanged", this.currentPage - 1);
        },
        onClickPage(page) {
            this.$emit("pageChanged", page);
        },
        onClickNextPage() {
            this.$emit("pageChanged", this.currentPage + 1);
        },
        onClickLastPage() {
            this.$emit("pageChanged", this.totalPages);
        },
        isPageActive(page) {
            return this.currentPage === page;
        },
    },
};
</script>
<style lang="scss">
@import "@/style/variables";

.kliniken-pagination {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;

    li.pagination-item {
        button {
            color: $color-navigation;
            background: #f7fdfe;
            border: 1px solid #b9d8e3;
            height: 42px;

            &.text {
                padding: 0 16px;
            }
            &.page-number {
                width: 54px;
            }
            &.active {
                background-color: $color-primary;
                border-color: $color-primary;
                color: #ffffff;
            }
            &.first {
                border-radius: 4px 0 0 4px;
            }
            &.last {
                border-radius: 0 4px 4px 0;
            }
        }
    }
}
</style>
