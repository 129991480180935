<template>
    <div class="expand-flex">
        <div class="expand-flex-main">
            <popout-suggest-widget
                :preview="preview"
                v-for="(value, index) in values"
                :key="name + '-' + index"
                :id="name + '-' + index"
                v-bind="$attrs"
                v-model="values[index]"
                :apisearch="apisearch"
                :extraParams="extraParams"
                :name="name"
                :required="required"
                :map="map"
                @blur="$emit('blur')"
            />
        </div>
        <div class="expand-flex-side" v-if="!preview">
            <div
                class="side-button-wrapper"
                :class="{
                    disabled: this.hasValues() == 0,
                }"
            >
                <button type="button" :disabled="this.hasValues() == 0" class="side-button" v-on:click="openNew()">
                    <div class="image-wrapper">+</div>
                    Lägg till {{ displayName ? displayName : name }}
                </button>
            </div>
            <div
                class="side-button-wrapper"
                v-for="(value, index) in values"
                :key="name + '-img-' + index"
                :style="index === 0 ? 'display: none' : ''"
            >
                <button type="button" @click="removeItem(index)" class="side-button">
                    <img src="@/assets/delete.svg" />Radera fält
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import PopoutSuggestWidget from "@/components/widgets/inputs/PopoutSuggestWidget.vue";
import ExpandableSuggest from "@/components/widgets/inputs/ExpandableSuggest.vue";

export default {
    name: "PopoutExpandableSuggestWidget",
    extends: ExpandableSuggest,
    components: {
        PopoutSuggestWidget,
    },
    watch: {
        values(newValue, oldValue) {
            if (newValue.length > oldValue.length) {
                this.count = newValue.length;
            }
            this.$emit("input", this.values);
        },
    },
};
</script>
