<template>
    <form @submit.prevent ref="preanestesibedomningForm" class="preanestesibedomning">
        <h2>{{ parameters ? "Redigera" : "Skapa" }} preanestesibedömning</h2>
        <br />
        <sign-frame
            v-if="signVisible"
            title="preanestesibedömning"
            :url="signUrl"
            :data="signData"
            @success="handleSignSuccess"
            @error="handleSignError"
            @cancel="handleSignCancel"
        />
        <div>
            <h3>
                <template v-if="!currentOperationsanmalan.id"> Koppla till operationsanmälan</template>
                <template v-else> Kopplad till operationsanmälan</template>
            </h3>

            <info-bar
                v-if="currentOperationsanmalan && Object.keys(currentOperationsanmalan).length > 0"
                :actions="infoBarActions"
                @taBortKoppling="removeCurrentOperationsanmalan"
            >
                <template v-slot:default>
                    Operationsanmälan skapad
                    {{ new Date(currentOperationsanmalan.created_at).toLocaleDateString("sv-SE") }} av
                    {{ currentOperationsanmalan?.created_by_display?.user_display?.name }},
                    {{ currentOperationsanmalan?.created_by_display?.yrkeskategori_display?.displayName }}
                    | {{ currentOperationsanmalan?.huvudoperationskod_display?.displayName }}
                </template>
                <template v-slot:right>
                    <operationsanmalan-status
                        :status="currentOperationsanmalan.status"
                        :text="currentOperationsanmalan.status"
                    />
                </template>
            </info-bar>

            <Button
                v-if="
                    currentOperationsanmalan &&
                    Object.keys(currentOperationsanmalan).length === 0 &&
                    !showOperationsanmalningar
                "
                size="secondary"
                role="button"
                button="button"
                @click.prevent="getOpanmalningar"
            >
                <img src="@/assets/download_white.svg" />Hämta operationsanmälningar
            </Button>

            <template
                v-if="
                    showOperationsanmalningar &&
                    operationsanmalningar.length &&
                    Object.keys(this.currentOperationsanmalan).length === 0
                "
            >
                <custom-action-table
                    class="custom-action-table-select"
                    :selectable-rows="true"
                    :columns="operationsanmalningarTableColumns"
                    :items="operationsanmalningar"
                    :actions="[]"
                    @rowSelected="selectOperationsanmalan"
                />
                <Button
                    :disabled="!selectedOperationsanmalan.id"
                    role="button"
                    type="button"
                    size="secondary"
                    @click="chooseOperationsanmalan"
                >
                    <img src="@/assets/link_white.svg" />Koppla till operationsanmälan
                </Button>
            </template>
        </div>

        <div class="tidigare-preanestesibedomning-container">
            <h3>Hämta data från tidigare preanestesibedömning</h3>

            <div class="tidigare-preanestesibedomning">
                <span
                    v-if="
                        !showPreanestesibedomningar &&
                        previousPreanestesibedomning &&
                        Object.keys(this.previousPreanestesibedomning).length !== 0
                    "
                >
                    <img src="@/assets/success_big.svg" /> Data fråm tidigare preanestesibedömning hämtad</span
                >
            </div>
            <Button
                class="tidigare-preanestesibedomning-button"
                v-if="showPreanestesibedomningar === false"
                type="button"
                variant="contour"
                size="slim"
                @click.prevent="getPreanestesibedomningar"
                :disabled="!can({ action: 'create', subject: 'preanestesibedomning' })"
            >
                <img src="@/assets/download-blue.svg" class="icon-blue" />Hämta tidigare preanestesibedömningar
            </Button>

            <div class="tidigare-preanestesibedomning" v-if="showPreanestesibedomningar">
                <custom-action-table
                    class="custom-action-table-select"
                    :selectable-rows="true"
                    :columns="preanestesibedomningarTableColumns"
                    :items="preanestesibedomningar"
                    @rowSelected="selectPreanestesibedomning"
                    :actions="preanestesibedomningRowActions"
                    :selectedItem="selectedItem"
                />
                <Button
                    :disabled="!selectedPreanestesibedomning.id"
                    type="button"
                    size="slim"
                    @click="choosePreanestesibedomning"
                >
                    <img src="@/assets/download_white.svg" />Hämta data från tidigare preanestesibedömningar
                </Button>
            </div>
        </div>

        <div class="form-group k3-form">
            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">1</div>
                    </div>
                    <div class="vr"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('operation')">
                        <h3>
                            Operation<img
                                :class="['k3-dropdown-icon', !hidden.operation ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form k3-card" v-if="!hidden.operation">
                            <div style="width: inherit">
                                <GridContainer>
                                    <GridRow :columns="12">
                                        <GridItem :span="4">
                                            <template>
                                                <strong>Operatör</strong><br />
                                                {{
                                                    currentOperationsanmalan?.huvudoperator_display?.user_display
                                                        ?.name ?? "-"
                                                }}
                                            </template>
                                        </GridItem>

                                        <GridItem :span="4">
                                            <template>
                                                <strong>Opassistent</strong><br />
                                                <div
                                                    v-if="
                                                        currentOperationsanmalan?.ass_operatorer_display &&
                                                        currentOperationsanmalan?.ass_operatorer_display.length
                                                    "
                                                >
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in currentOperationsanmalan?.ass_operatorer_display"
                                                        :key="index"
                                                    >
                                                        {{ item?.user_display?.name ?? "-" }}
                                                    </div>
                                                </div>
                                                <div v-else>-</div>
                                            </template>
                                        </GridItem>

                                        <GridItem :span="4">
                                            <template>
                                                <strong>Opdatum</strong><br />
                                                {{ currentOperationsanmalan?.bokad_operation_datum ?? "-" }}
                                            </template>
                                        </GridItem>
                                    </GridRow>

                                    <GridRow :columns="12">
                                        <GridItem :span="12">
                                            <template>
                                                <strong>Huvudoperationskod</strong><br />
                                                {{
                                                    currentOperationsanmalan?.huvudoperationskod_display?.code &&
                                                    currentOperationsanmalan?.huvudoperationskod_display?.displayName
                                                        ? currentOperationsanmalan?.huvudoperationskod_display?.code +
                                                          " " +
                                                          currentOperationsanmalan?.huvudoperationskod_display
                                                              ?.displayName
                                                        : "-"
                                                }}
                                            </template>
                                        </GridItem>
                                    </GridRow>

                                    <GridRow :columns="12">
                                        <GridItem :span="12">
                                            <template>
                                                <strong>Bioperationskoder</strong><br />
                                                <div
                                                    v-if="
                                                        currentOperationsanmalan?.bioperationskoder_display &&
                                                        currentOperationsanmalan?.bioperationskoder_display.length
                                                    "
                                                >
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in currentOperationsanmalan?.bioperationskoder_display"
                                                        :key="index"
                                                    >
                                                        {{
                                                            item.code && item.displayName
                                                                ? item.code + " " + item.displayName
                                                                : "-"
                                                        }}
                                                    </div>
                                                </div>
                                                <div v-else>-</div>
                                            </template>
                                        </GridItem>
                                    </GridRow>

                                    <GridRow :columns="12">
                                        <GridItem :span="12">
                                            <template>
                                                <strong>Huvuddiagnos</strong><br />
                                                {{
                                                    currentOperationsanmalan?.huvuddiagnos_display?.code &&
                                                    currentOperationsanmalan?.huvuddiagnos_display?.displayName
                                                        ? currentOperationsanmalan?.huvuddiagnos_display?.code +
                                                          " " +
                                                          currentOperationsanmalan?.huvuddiagnos_display?.displayName
                                                        : "-"
                                                }}
                                            </template>
                                        </GridItem>
                                    </GridRow>

                                    <GridRow :columns="12">
                                        <GridItem :span="12">
                                            <template>
                                                <strong>Bidiagnoskoder</strong><br />
                                                <div
                                                    v-if="
                                                        currentOperationsanmalan?.bidiagnoser_display &&
                                                        currentOperationsanmalan?.bidiagnoser_display.length
                                                    "
                                                >
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in currentOperationsanmalan?.bidiagnoser_display"
                                                        :key="index"
                                                    >
                                                        {{
                                                            item.code && item.displayName
                                                                ? item.code + " " + item.displayName
                                                                : "-"
                                                        }}
                                                    </div>
                                                </div>
                                                <div v-else>-</div>
                                            </template>
                                        </GridItem>
                                    </GridRow>

                                    <GridRow :columns="12">
                                        <GridItem :span="8"></GridItem>

                                        <GridItem :span="2">
                                            <template>
                                                <ButtonLink
                                                    type="button"
                                                    @click="openOperationsanmalan"
                                                    v-if="Object.keys(this.currentOperationsanmalan).length > 0"
                                                >
                                                    Visa operationsanmälan
                                                </ButtonLink>
                                            </template>
                                        </GridItem>
                                    </GridRow>
                                </GridContainer>
                            </div>
                        </div>
                    </collapse-transition>
                </div>
            </div>

            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">2</div>
                    </div>
                    <div class="vr"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('anestesi')">
                        <h3>
                            Anestesi<img
                                :class="['k3-dropdown-icon', !hidden.anestesi ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form" v-if="!hidden.anestesi">
                            <input-wrapper
                                :component="widgets.PopoutSuggestWidget"
                                label="Anestesiolog"
                                name="anestesiolog"
                                v-model="formData.anestesiolog"
                                apisearch="/core/profiles/"
                                :extraParams="{ roll__code: 'anestesilakare', is_active: true }"
                                :map="
                                    (el) => {
                                        return {
                                            id: el.pk,
                                            code: el.healthCareProviderHsaId,
                                            displayName: el.user.name,
                                        };
                                    }
                                "
                                required="true"
                                :error="formError.anestesiolog"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Anestesi"
                                name="anestesi"
                                v-model="formData.anestesi"
                                apisearch="/operation/kodverk/anestesier/"
                                :error="formError.anestesi"
                            />
                            <input-wrapper
                                :component="widgets.PopoutExpandableSuggestWidget"
                                label="Anestesikoder"
                                name="anestesikoder"
                                :displayCode="true"
                                v-model="formData.anestesikoder"
                                apisearch="/operation/kodverk/anestesikoder/"
                                display-name="anestesikoder"
                                colspan="8"
                                :error="formError.anestesikoder"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Tidigare anestesi"
                                name="tidigare_anestesi"
                                v-model="formData.tidigare_anestesi"
                                apisearch="/operation/kodverk/anestesier/"
                                :error="formError.tidigare_anestesi"
                            />
                            <input-wrapper
                                :component="widgets.ExpandableSuggest"
                                label="Tidigare anestesikoder"
                                name="tidigare_anestesikoder"
                                v-model="formData.tidigare_anestesikoder"
                                apisearch="/operation/kodverk/anestesikoder/"
                                display-name="tidigare anestesikoder"
                                colspan="6"
                                :displayCode="true"
                                :error="formError.tidigare_anestesikoder"
                            />
                        </div>
                    </collapse-transition>
                </div>
            </div>

            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">3</div>
                    </div>
                    <div class="vr"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('patientdata')">
                        <h3>
                            Patientdata<img
                                :class="['k3-dropdown-icon', !hidden.patientdata ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form" v-if="!hidden.patientdata">
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Rökare"
                                name="rokning"
                                @input="rokningChanged"
                                v-model="formData.rokning"
                                :options="jaNejOkant"
                                :error="formError.rokning"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                :disabled="formData.rokning !== 'JA'"
                                label="Rökavvänjning har erbjudits"
                                name="erbjuden_rokavvanjning"
                                v-model="formData.erbjuden_rokavvanjning"
                                :options="jaNejOkant"
                                :error="formError.erbjuden_rokavvanjning"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                :disabled="formData.rokning !== 'JA'"
                                label="Krav på slutat röka"
                                name="krav_sluta_roka"
                                v-model="formData.krav_sluta_roka"
                                :options="jaNejOkant"
                                :error="formError.krav_sluta_roka"
                            />
                            <div class="colspan c2">&nbsp;</div>
                            <input-wrapper
                                :component="widgets.NumberWidget"
                                label="Längd (cm)"
                                name="langd_patient"
                                :colspan="1"
                                v-model="formData.langd_patient"
                                :step="0.1"
                                :isIntegerOnly="true"
                                :error="formError.langd_patient"
                            />
                            <input-wrapper
                                :component="widgets.NumberWidget"
                                label="Vikt (kg)"
                                name="vikt_patient"
                                :colspan="1"
                                v-model="formData.vikt_patient"
                                :step="0.1"
                                :error="formError.vikt_patient"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="BMI"
                                name="bmi"
                                :value="formData.bmi"
                                :disabled="true"
                                :error="formError.bmi"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Status, AU"
                                :colspan="4"
                                :maxlength="1000"
                                name="status_AT"
                                v-model="formData.status_AT"
                                :error="formError.status_AT"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Cor"
                                :colspan="4"
                                :maxlength="1000"
                                name="cor"
                                v-model="formData.cor"
                                :error="formError.cor"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Pulm"
                                :colspan="4"
                                :maxlength="1000"
                                name="pulm"
                                v-model="formData.pulm"
                                :error="formError.pulm"
                            />
                            <input-wrapper
                                :component="widgets.NumberWidget"
                                label="Puls"
                                name="puls"
                                :colspan="1"
                                v-model="formData.puls"
                                :min="0"
                                :max="350"
                                :error="formError.puls"
                            />
                            <input-wrapper
                                :component="widgets.NumberWidget"
                                label="BT syst."
                                name="blodtryck_systoliskt"
                                :colspan="1"
                                v-model="formData.blodtryck_systoliskt"
                                :min="0"
                                :max="999"
                                :error="formError.blodtryck_systoliskt"
                            />
                            <input-wrapper
                                :component="widgets.NumberWidget"
                                label="BT diast."
                                name="blodtryck_diastoliskt"
                                :colspan="1"
                                v-model="formData.blodtryck_diastoliskt"
                                :min="0"
                                :max="999"
                                :error="formError.blodtryck_diastoliskt"
                            />
                            <div class="colspan c5">&nbsp;</div>
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Hjärt-/kärlsjukdom"
                                name="hjart_karlsjukdom"
                                v-model="formData.hjart_karlsjukdom"
                                @input="hjartKarlsjukdomChanged"
                                :options="jaNej"
                                :error="formError.hjart_karlsjukdom"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="Hjärt-kärlsjukdom, beskrivning"
                                :disabled="!formData.hjart_karlsjukdom"
                                :maxlength="1000"
                                name="hjart_karlsjukdom_text"
                                v-model="formData.hjart_karlsjukdom_text"
                                :error="formError.hjart_karlsjukdom_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Lungsjukdom"
                                name="lungsjukdom"
                                v-model="formData.lungsjukdom"
                                @input="lungsjukdomChanged"
                                :options="jaNej"
                                :error="formError.lungsjukdom"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="Lungsjukdom, beskrivning"
                                :disabled="!formData.lungsjukdom"
                                :maxlength="1000"
                                name="lungsjukdom_text"
                                v-model="formData.lungsjukdom_text"
                                :error="formError.lungsjukdom_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Organkomplikation"
                                name="organkomplikation"
                                v-model="formData.organkomplikation"
                                @input="organkomplikationChanged"
                                :options="jaNej"
                                :error="formError.organkomplikation"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="Organkomplikation, beskrivning"
                                :disabled="!formData.organkomplikation"
                                :maxlength="1000"
                                name="organkomplikation_text"
                                v-model="formData.organkomplikation_text"
                                :error="formError.organkomplikation_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Trombossjukdom/anlag"
                                name="trombossjukdom"
                                v-model="formData.trombossjukdom"
                                @input="trombossjukdomChanged"
                                :options="jaNej"
                                :error="formError.trombossjukdom"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="Trombossjukdom/anlag, beskriv"
                                :disabled="!formData.trombossjukdom"
                                :maxlength="1000"
                                name="trombossjukdom_text"
                                v-model="formData.trombossjukdom_text"
                                :error="formError.trombossjukdom_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Annan sjukdom"
                                name="annan_sjukdom"
                                v-model="formData.annan_sjukdom"
                                @input="annanSjukdomChanged"
                                :options="jaNej"
                                :error="formError.annan_sjukdom"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="Annan sjukdom, beskrivning"
                                :disabled="!formData.annan_sjukdom"
                                :maxlength="1000"
                                name="annan_sjukdom_text"
                                v-model="formData.annan_sjukdom_text"
                                :error="formError.annan_sjukdom_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Överkänslighet"
                                name="overkanslighet"
                                v-model="formData.overkanslighet"
                                @input="overkanslighetChanged"
                                :options="jaNej"
                                :error="formError.overkanslighet"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="Överkänslighet, beskrivning"
                                :disabled="!formData.overkanslighet"
                                :maxlength="1000"
                                name="overkanslighet_text"
                                v-model="formData.overkanslighet_text"
                                :error="formError.overkanslighet_text"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Diabetes"
                                name="diabetes"
                                v-model="formData.diabetes"
                                :options="jaNej"
                                :error="formError.diabetes"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Blödningsbenägenhet"
                                name="blodningsbenagenhet"
                                v-model="formData.blodningsbenagenhet"
                                :options="jaNej"
                                :error="formError.blodningsbenagenhet"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="Mp"
                                :maxlength="1000"
                                name="mp"
                                v-model="formData.mp"
                                :error="formError.mp"
                            />
                            <input-wrapper
                                :component="widgets.TextWidget"
                                label="Tm avstånd"
                                :maxlength="1000"
                                name="tm_avstand"
                                v-model="formData.tm_avstand"
                                :error="formError.tm_avstand"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Intubationssvårigheter"
                                name="intubationssvarigheter"
                                v-model="formData.intubationssvarigheter"
                                :options="jaNej"
                                :error="formError.intubationssvarigheter"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Refluxbesvär"
                                name="refluxbesvar"
                                v-model="formData.refluxbesvar"
                                :options="jaNej"
                                :error="formError.refluxbesvar"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Aktuell Medicin"
                                :colspan="4"
                                :maxlength="1000"
                                name="aktuell_medicin"
                                v-model="formData.aktuell_medicin"
                                :error="formError.aktuell_medicin"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Information till avdelning"
                                :colspan="4"
                                :maxlength="1000"
                                name="ordinationer_avdelning"
                                v-model="formData.ordinationer_avdelning"
                                :error="formError.ordinationer_avdelning"
                            />
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Information till operation"
                                :colspan="4"
                                :maxlength="1000"
                                name="ordinationer_operation"
                                v-model="formData.ordinationer_operation"
                                :error="formError.ordinationer_operation"
                            />
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="EKG godkänd"
                                name="ekg_godkand"
                                v-model="formData.ekg_godkand"
                                :options="jaNej"
                                :error="formError.ekg_godkand"
                            />
                        </div>
                    </collapse-transition>
                </div>
            </div>
            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">4</div>
                    </div>
                    <div class="vr vr-no-min-height"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('godkannande')">
                        <h3>
                            Bedömning<img
                                :class="['k3-dropdown-icon', !hidden.bedomning ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form" v-if="!hidden.bedomning">
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="ASA-klassifikation"
                                name="ASA"
                                v-model="formData.ASA"
                                :options="[
                                    { text: 'ASA1', value: 'ASA1' },
                                    { text: 'ASA2', value: 'ASA2' },
                                    { text: 'ASA3', value: 'ASA3' },
                                    { text: 'ASA4', value: 'ASA4' },
                                ]"
                                :error="formError.ASA"
                            />
                            <div class="colspan c6">&nbsp;</div>
                            <input-wrapper
                                :component="widgets.TextAreaWidget"
                                label="Kommentar"
                                :colspan="4"
                                :maxlength="1000"
                                name="kommentar"
                                v-model="formData.kommentar"
                                :error="formError.kommentar"
                            />
                        </div>
                    </collapse-transition>
                </div>
            </div>
            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">5</div>
                    </div>
                    <div class="vr vr-no-min-height"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('godkannande')">
                        <h3>
                            Godkännande inför operation<img
                                :class="['k3-dropdown-icon', !hidden.godkannande ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form" v-if="!hidden.godkannande">
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Godkännande"
                                name="godkannande"
                                v-model="formData.godkannande"
                                :options="godkannanden"
                                :colspan="4"
                                :error="formError.godkannande"
                                :required="isSigning"
                            />
                            <div class="colspan c6">&nbsp;</div>
                            <input-wrapper
                                :disabled="formData.godkannande !== 'Godkänd med krav'"
                                :component="widgets.TextAreaWidget"
                                label="Krav för godkännande"
                                :colspan="4"
                                :maxlength="1000"
                                name="krav_godkannande"
                                v-model="formData.krav_godkannande"
                                :error="
                                    formData.godkannande === 'Godkänd med krav' &&
                                    (formData.krav_godkannande === null ||
                                        formData.krav_godkannande === undefined ||
                                        formData.krav_godkannande.length === 0)
                                        ? 'Krav för godkännande krävs'
                                        : undefined
                                "
                                :required="formData.godkannande === 'Godkänd med krav'"
                            />
                            <div class="colspan c8">&nbsp;</div>

                            <input-wrapper
                                :component="widgets.DateWidget"
                                label="Datum för godkännande"
                                name="datum_godkannande"
                                v-model="formData.datum_godkannande"
                                :value="formattedDate"
                                :error="formError.datum_godkannande"
                            />
                            <input-wrapper
                                :component="widgets.DateWidget"
                                label="Giltigt till"
                                name="giltighetstid"
                                v-model="formData.giltighetstid"
                                :error="formError.giltighetstid"
                            />
                        </div>
                    </collapse-transition>
                </div>
            </div>
        </div>
        <div class="hr" />
        <ButtonGroup>
            <Button
                @click="save"
                :disabled="!can({ action: 'create', subject: 'preanestesibedomning' })"
                size="primary"
                role="button"
                type="button"
                >Spara</Button
            >
            <Button
                @click="sign"
                :disabled="!can({ action: 'create', subject: 'preanestesibedomning' })"
                size="primary"
                role="button"
                type="button"
                >Spara och signera</Button
            >
            <Button @click="cancel" size="secondary" variant="contour" type="button">Avbryt</Button>
        </ButtonGroup>
    </form>
</template>
<script>
    import widgets from "@/components/widgets/inputs";
    import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
    import BaseTab from "@/tabs/mixins/BaseTab";
    import Vue from "vue";
    import { mapGetters } from "vuex";
    import { CollapseTransition } from "@ivanv/vue-collapse-transition";
    import tabIcon from "@/assets/operationsanmalan.svg";
    import CustomActionTable from "@/components/generic/CustomActionTable.vue";
    import PreviewWidget from "@/components/widgets/PreviewWidget.vue";
    import Button from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import { GridContainer, GridItem, GridRow } from "@/components/Grid/index.js";
    import { getConfig, getErrorMessage, klinikenApi } from "@/api";
    import { flashMessage, openDialog } from "@/utils";
    import InfoBar from "@/components/InfoBar.vue";
    import ButtonLink from "@/components/ButtonLink.vue";
    import Operationsanmalan from "@/tabs/Operation/Operationsanmalan.vue";
    import { statusar, statusPreanestesibedomningar } from "./utils.js";
    import { cloneDeep, isEqual } from "lodash";
    import OperationsanmalanStatus from "./OperationsanmalanStatus.vue";
    import SignFrame from "@/components/Auth/SignFrame.vue";
    import PreanestesibedomningVisa from "@/tabs/Operation/PreanestesibedomningVisa.vue";

    function getStatusTemplate(status) {
        const s = statusar.find((i) => i.value === status);
        if (!s) return;
        return `<div style="display: flex; align-items: center;"><img style="margin-right: 7px; min-width: 20px; height: 20px"
                        :src="fetchImg('actionIcons/${s.icon}.svg')" alt="${s.label}"/> <span>${s.label}</span></div>`;
    }

    /**
     * Transforms server error response into a desired format.
     *
     * @param {Object} serverError - The error response from the server.
     * @returns {Object} Transformed error object.
     */
    function transformServerError(serverError) {
        const errorObj = {};

        for (const [key, valueArray] of Object.entries(serverError)) {
            if (Array.isArray(valueArray) && valueArray.length > 0) {
                errorObj[key] = valueArray[0];
            }
        }

        return errorObj;
    }

    const INFO_BAR_ACTIONS = [
        {
            title: "Ta bort koppling till operationsanmälan",
            name: "taBortKoppling",
            img: "koppla.svg",
        },
    ];

    const PREANESTESIBEDOMNING_ROW_ACTIONS = [
        {
            title: "Visa preanestesibedömning",
            disabled: (item) => false,
            name: "visaPreanestesibedomning",
            img: "skapapreanestesibedomning.svg",
        },
    ];

    const OPERATIONSANMALNINGAR_TABLE_COLUMNS = [
        {
            name: "radio",
            title: "",
            template: (item) => {
                return (
                    `<div class="custom-action-table-select-radio` +
                    (item.checked ? ` custom-action-table-select-radio-checked` : ``) +
                    `" />`
                );
            },
        },
        {
            name: "created_at",
            title: "Datum skapad",
            template: (item) => {
                return item.created_at ? new Date(item.created_at).toLocaleDateString("sv-SE") : "";
            },
        },
        {
            name: "beskrivning",
            title: "Beskrivning",
            template: (item) => {
                if (item.beskrivning) {
                    return item.beskrivning;
                } else if (item.huvudoperationskod_display?.displayName) {
                    return item.huvudoperationskod_display.displayName;
                } else {
                    return "";
                }
            },
        },
        {
            name: "preliminart_datum",
            title: "Prel. datum",
            template: (item) => {
                return item.preliminart_datum ? new Date(item.preliminart_datum).toLocaleDateString("sv-SE") : "";
            },
        },
        {
            name: "skapat_av",
            title: "Skapad av",
            template: (item) => {
                return item.created_by_display?.user_display?.name ?? "";
            },
        },
        {
            name: "status__sortering",
            title: "Status",
            template: (item) => {
                return getStatusTemplate(item.status);
            },
            hasSorting: true,
        },
    ];

    function getStatusPreanestesibedomningTemplate(status) {
        const s = statusPreanestesibedomningar.find(
            (i) => i.value === status && ["Godkänd med krav", "Godkänd", "Ej godkänd"].includes(i.value)
        );
        if (!s) return "-";
        return `<div style="display: flex; align-items: center;"><img style="margin-right: 7px; min-width: 20px; height: 20px"
            :src="fetchImg('actionIcons/statuspreanestesibedomning/${s.icon}.svg')" alt="${s.label}"/> <span>${s.label}</span></div>`;
    }

    const ja_nej = [
        { text: "Ja", value: true },
        { text: "Nej", value: false },
    ];

    const ja_nej_okant = [
        { text: "Ja", value: "JA" },
        { text: "Nej", value: "NEJ" },
        { text: "Okänt", value: "OKÄNT" },
    ];

    const godkannanden = [
        { text: "Godkänd med krav", value: "Godkänd med krav", disabled: false },
        { text: "Godkänd", value: "Godkänd", disabled: false },
        { text: "Ej godkänd", value: "Ej godkänd", disabled: false },
        { text: "Återkallad", value: "Återkallad", disabled: true },
        { text: "Utgången", value: "Utgången", disabled: true },
    ];

    const defaultData = {
        rokning: undefined,
        erbjuden_rokavvanjning: undefined,
        vikt_patient: null,
        langd_patient: null,
        krav_godkannande: "",
    };

    export default {
        extends: BaseTab,
        name: "Preanestesibedomning",
        tabName: "Preanestesibedömning",
        icon: tabIcon,
        patientBound: true,
        props: {
            uid: Number,
            parameters: Object,
            tabId: Number,
        },
        components: {
            OperationsanmalanStatus,
            InputWrapper,
            CustomActionTable,
            CollapseTransition,
            PreviewWidget,
            Button,
            ButtonGroup,
            GridContainer,
            GridRow,
            GridItem,
            InfoBar,
            ButtonLink,
            SignFrame,
            PreanestesibedomningVisa,
        },
        data() {
            return {
                widgets: widgets,
                infoBarActions: INFO_BAR_ACTIONS,
                preanestesibedomningRowActions: PREANESTESIBEDOMNING_ROW_ACTIONS,
                operationsanmalningarTableColumns: OPERATIONSANMALNINGAR_TABLE_COLUMNS,
                preanestesibedomningarTableColumns: [
                    {
                        name: "radio",
                        title: "",
                        template: (item) => {
                            return (
                                `<div class="custom-action-table-select-radio` +
                                (item.checked ? ` custom-action-table-select-radio-checked` : ``) +
                                `" />`
                            );
                        },
                    },
                    {
                        name: "created_at",
                        title: "Datum skapad",
                        template: (item) => {
                            return item.created_at ? new Date(item.created_at).toLocaleDateString("sv-SE") : "";
                        },
                    },
                    {
                        name: "created_by_display",
                        title: "Skapad av",
                        template: (item) => {
                            return item.created_by_display?.user_display?.name ?? "";
                        },
                    },
                    {
                        name: "godkannande",
                        title: "Preanestesibedömning",
                        template: (item) => {
                            return getStatusPreanestesibedomningTemplate(item.godkannande);
                        },
                    },
                    {
                        name: "giltighetstid",
                        title: "Giltighetstid",
                        template: (item) => {
                            return item.giltighetstid ? new Date(item.giltighetstid).toLocaleDateString("sv-SE") : "";
                        },
                    },
                    {
                        name: "operation",
                        title: "Status",
                        template: (item) => {
                            if (item.operationsanmalan) {
                                if (item.operationsanmalan.status === "Genomförd") {
                                    return "Genomförd";
                                }
                                return "Planerad";
                            }
                            return "Ej kopplad";
                        },
                    },
                ],
                jaNej: ja_nej,
                jaNejOkant: ja_nej_okant,
                godkannanden: godkannanden,
                statusar: statusar,

                formData: cloneDeep(defaultData),
                initialFormData: cloneDeep(defaultData),
                formError: {},
                formDataObject: {},
                hidden: {},
                isSigning: false,

                signVisible: false,
                signUrl: "",
                signData: "",

                currentOperationsanmalan: {},

                showOperationsanmalningar: false,
                operationsanmalningar: [],
                selectedOperationsanmalan: {},

                showPreanestesibedomningar: false,
                preanestesibedomningar: [],
                selectedPreanestesibedomning: {},
                previousPreanestesibedomning: {},
                selectedItem: {},
            };
        },
        computed: {
            ...mapGetters("operation/operationsanmalan", ["opAnmalans"]),
            ...mapGetters("patientData", ["patient"]),
            ...mapGetters("ability", ["can"]),
            bmi() {
                if (this.formData.langd_patient && this.formData.vikt_patient) {
                    return Number.parseFloat(
                        this.formData.vikt_patient / Math.pow(this.formData.langd_patient / 100, 2)
                    )
                        .toFixed(1)
                        .toString()
                        .replace(".", ",");
                }
                return "";
            },
            rokning() {
                return this.formData.rokning === "JA" || this.formData.rokning === "OKÄNT";
            },

            formattedDate() {
                const d = new Date();
                const year = d.getFullYear();
                const month = (d.getMonth() + 1).toString().padStart(2, "0");
                const date = d.getDate().toString().padStart(2, "0");

                return `${year}-${month}-${date}`;
            },

            hasUnsavedData() {
                return !isEqual(this.initialFormData, this.formData);
            },
        },
        methods: {
            removeEmpty(cleanFormData) {
                for (let key in cleanFormData) {
                    // Should allow null values and empty strings
                    if (cleanFormData[key] === undefined) delete cleanFormData[key];
                    else if (Array.isArray(cleanFormData[key])) {
                        cleanFormData[key] = cleanFormData[key].filter((el) => {
                            return el !== "";
                        });
                        if (cleanFormData[key].length === 0) delete cleanFormData[key];
                    }
                }
                return cleanFormData;
            },
            toggleVisible(subFormName) {
                let hidden = this.hidden[subFormName] || false;
                Vue.set(this.hidden, subFormName, !hidden);
            },
            validateForm(form) {
                if (form.checkValidity() === true) return true;

                // Check the validity of each form element
                for (let i = 0; i < form.elements.length; i++) {
                    const element = form.elements[i];

                    if (element.checkValidity() === false) {
                        // Found the first invalid field
                        element.focus();
                        break;
                    }
                }
                return false;
            },
            rokningChanged(rokning) {
                if (rokning !== "JA") {
                    this.formData.erbjuden_rokavvanjning = "";
                    this.formData.krav_sluta_roka = "";
                }
            },
            hjartKarlsjukdomChanged(value) {
                if (value === false) {
                    this.formData.hjart_karlsjukdom_text = "";
                }
            },
            lungsjukdomChanged(value) {
                if (value === false) {
                    this.formData.lungsjukdom_text = "";
                }
            },
            organkomplikationChanged(value) {
                if (value === false) {
                    this.formData.organkomplikation_text = "";
                }
            },
            trombossjukdomChanged(value) {
                if (value === false) {
                    this.formData.trombossjukdom_text = "";
                }
            },
            annanSjukdomChanged(value) {
                if (value === false) {
                    this.formData.annan_sjukdom_text = "";
                }
            },
            overkanslighetChanged(value) {
                if (value === false) {
                    this.formData.overkanslighet_text = "";
                }
            },

            async save() {
                if (!this.can({ action: "create", subject: "preanestesibedomning" })) return;
                this.isSigning = false;
                if (this.formData?.godkannande) {
                    openDialog(`För att slutföra bedömningen måste den signeras. Välj Spara och signera.`, "warning");
                    return;
                }
                // väntar med att anropa spara funktionen tills isSigned har hunnit propagera
                this.$nextTick(async function () {
                    if (!this.validateForm(this.$refs.preanestesibedomningForm)) {
                        return;
                    }

                    let cleanFormData = this.removeEmpty(cloneDeep(this.formData));
                    cleanFormData.patient = cleanFormData.patient ? cleanFormData.patient : this.patient.id;
                    cleanFormData.operationsanmalan = this.currentOperationsanmalan.id;

                    await this.$store.commit("tabs/displaySpinner", { id: this.tabId, flag: true });

                    let response = await this.$store.dispatch(`operation/preanestesibedomning/save`, cleanFormData);

                    await this.$store.commit("tabs/displaySpinner", { id: this.tabId, flag: false });

                    if (response.status === 200 || response.status === 201) {
                        await this.$store.dispatch("tabs/closeTab", this.tabId);
                    } else {
                        this.formError = transformServerError(response.data);
                    }
                });
            },
            async sign() {
                if (!this.can({ action: "create", subject: "preanestesibedomning" })) return;
                this.isSigning = true;
                // väntar med att anropa spara funktionen tills isSigned har hunnit propagera
                this.$nextTick(async function () {
                    if (!this.validateForm(this.$refs.preanestesibedomningForm)) {
                        return;
                    }

                    let cleanFormData = this.removeEmpty(cloneDeep(this.formData));
                    cleanFormData.patient = cleanFormData.patient ? cleanFormData.patient : this.patient.id;
                    cleanFormData.operationsanmalan = this.currentOperationsanmalan.id;

                    const url = cleanFormData.id
                        ? `/operation/preanestesibedomningar/${cleanFormData.id}/`
                        : "/operation/preanestesibedomningar/";

                    this.signData = cleanFormData;
                    this.signUrl = url;
                    this.signVisible = true;
                });
            },
            async handleSignSuccess(data) {
                flashMessage("Preanestesibedömningen signerad");
                await this.$store.commit("operation/preanestesibedomning/setPreanestesibedomning", data);
                await this.$store.dispatch("tabs/closeTab", this.tabId);
            },
            handleSignError(errors) {
                if (typeof errors === "object") {
                    this.formError = transformServerError(errors);
                } else {
                    openDialog(errors, "error");
                }
                this.signVisible = false;
            },
            handleSignCancel() {
                this.signVisible = false;
            },
            cancel() {
                this.closeTab(this.hasUnsavedData);
            },

            removeCurrentOperationsanmalan() {
                this.currentOperationsanmalan = {};
            },
            async getOpanmalningar() {
                if (!this.can({ action: "read", subject: "preanestesibedomning" })) return;
                try {
                    const response = await klinikenApi.get(
                        "operation/koordinering/",
                        getConfig({
                            params: {
                                patient: this.patient.id,
                                excluded_statuses: "Att hantera,Struken,Genomförd",
                            },
                        })
                    );

                    this.operationsanmalningar = response.data.results;
                    this.showOperationsanmalningar = true;
                } catch (error) {
                    flashMessage(getErrorMessage(error));
                }
            },
            selectOperationsanmalan(operationsanmalan) {
                this.operationsanmalningar.forEach((item, index) => {
                    Vue.set(this.operationsanmalningar[index], "checked", operationsanmalan === item);
                });

                this.selectedOperationsanmalan = operationsanmalan;
            },
            async chooseOperationsanmalan() {
                this.showOperationsanmalningar = false;
                this.currentOperationsanmalan = cloneDeep(this.selectedOperationsanmalan);
                if (!this.formData.langd_patient)
                    Vue.set(this.formData, "langd_patient", this.currentOperationsanmalan.langd);
                if (!this.formData.vikt_patient)
                    Vue.set(this.formData, "vikt_patient", this.currentOperationsanmalan.vikt);
                await flashMessage("Preanestesibedömning kopplad till operationsanmälan");
            },

            async openOperationsanmalan() {
                await this.$store.dispatch("operation/operationsanmalan/openOpAnmalan", {
                    component: Operationsanmalan,
                    id: this.currentOperationsanmalan.id,
                    patient: this.currentOperationsanmalan.patient,
                    mode: "opcoordinator",
                });
            },

            async getPreanestesibedomningar() {
                try {
                    const response = await klinikenApi.get(
                        "operation/preanestesibedomningar/",
                        getConfig({
                            params: {
                                patient: this.patient.id,
                            },
                        })
                    );

                    this.preanestesibedomningar = response.data.results;
                    this.showPreanestesibedomningar = true;
                } catch (error) {
                    flashMessage(getErrorMessage(error));
                }
            },
            selectPreanestesibedomning(preanestesibedomning) {
                this.preanestesibedomningar.forEach((item, index) => {
                    Vue.set(this.preanestesibedomningar[index], "checked", preanestesibedomning === item);
                });

                this.selectedItem = preanestesibedomning;
                this.selectedPreanestesibedomning = preanestesibedomning;
            },
            async choosePreanestesibedomning() {
                this.showPreanestesibedomningar = false;
                this.previousPreanestesibedomning = cloneDeep(this.selectedPreanestesibedomning);
                this.formData.rokning = this.previousPreanestesibedomning.rokning;
                this.formData.erbjuden_rokavvanjning = this.previousPreanestesibedomning.erbjuden_rokavvanjning;
                this.formData.krav_sluta_roka = this.previousPreanestesibedomning.krav_sluta_roka;
                this.formData.langd_patient = this.previousPreanestesibedomning.langd_patient;
                this.formData.vikt_patient = this.previousPreanestesibedomning.vikt_patient;
                this.formData.status_AT = this.previousPreanestesibedomning.status_AT;
                this.formData.cor = this.previousPreanestesibedomning.cor;
                this.formData.pulm = this.previousPreanestesibedomning.pulm;
                this.formData.puls = this.previousPreanestesibedomning.puls;
                this.formData.blodtryck_systoliskt = this.previousPreanestesibedomning.blodtryck_systoliskt;
                this.formData.blodtryck_diastoliskt = this.previousPreanestesibedomning.blodtryck_diastoliskt;
                this.formData.hjart_karlsjukdom = this.previousPreanestesibedomning.hjart_karlsjukdom;
                this.formData.lungsjukdom = this.previousPreanestesibedomning.lungsjukdom;
                this.formData.lungsjukdom_text = this.previousPreanestesibedomning.lungsjukdom_text;
                this.formData.organkomplikation = this.previousPreanestesibedomning.organkomplikation;
                this.formData.organkomplikation_text = this.previousPreanestesibedomning.organkomplikation_text;
                this.formData.trombossjukdom = this.previousPreanestesibedomning.trombossjukdom;
                this.formData.trombossjukdom_text = this.previousPreanestesibedomning.trombossjukdom_text;
                this.formData.annan_sjukdom = this.previousPreanestesibedomning.annan_sjukdom;
                this.formData.annan_sjukdom_text = this.previousPreanestesibedomning.annan_sjukdom_text;
                this.formData.overkanslighet = this.previousPreanestesibedomning.overkanslighet;
                this.formData.overkanslighet_text = this.previousPreanestesibedomning.overkanslighet_text;
                this.formData.diabetes = this.previousPreanestesibedomning.diabetes;
                this.formData.blodningsbenagenhet = this.previousPreanestesibedomning.blodningsbenagenhet;
                this.formData.mp = this.previousPreanestesibedomning.mp;
                this.formData.tm_avstand = this.previousPreanestesibedomning.tm_avstand;
                this.formData.intubationssvarigheter = this.previousPreanestesibedomning.intubationssvarigheter;
                this.formData.refluxbesvar = this.previousPreanestesibedomning.refluxbesvar;
                this.formData.aktuell_medicin = this.previousPreanestesibedomning.aktuell_medicin;
                this.formData.ordinationer_avdelning = this.previousPreanestesibedomning.ordinationer_avdelning;
                this.formData.ordinationer_operation = this.previousPreanestesibedomning.ordinationer_operation;
                this.formData.ekg_godkand = this.previousPreanestesibedomning.ekg_godkand;
            },

            _manuallyComputedBMI(langd, vikt) {
                let value = "";
                if (langd && vikt) {
                    value = Number.parseFloat(vikt / Math.pow(langd / 100, 2))
                        .toFixed(1)
                        .toString()
                        .replace(".", ",");
                }
                return value;
            },
            visaPreanestesibedomning(preanestesibedomning) {
                this.$store.dispatch("tabs/openTab", {
                    component: PreanestesibedomningVisa,
                    parameters: {
                        preanestesibedomningId: preanestesibedomning.id,
                    },
                });
            },
        },
        watch: {
            "formData.godkannande": function (newVal) {
                if (["Godkänd med krav", "Godkänd"].includes(newVal)) {
                    this.formData.datum_godkannande = this.formattedDate;
                } else {
                    this.formData.datum_godkannande = null;
                }
            },

            "currentOperationsanmalan.rokavvanjning": function (newVal) {
                if (newVal) {
                    this.formData.erbjuden_rokavvanjning = newVal;
                }
            },

            "currentOperationsanmalan.rokare": function (newVal) {
                if (newVal) {
                    this.formData.rokning = newVal;
                }
            },

            "formData.vikt_patient": function (newValue) {
                this.formData.vikt_patient = newValue;
                this.formData.bmi = this._manuallyComputedBMI(this.formData.langd_patient, newValue);
            },

            "formData.langd_patient": function (newValue) {
                this.formData.langd_patient = newValue;
                this.formData.bmi = this._manuallyComputedBMI(newValue, this.formData.vikt_patient);
            },

            currentOperationsanmalan(newVal) {
                this.formData.langd_patient = newVal.langd ?? null;
                this.formData.vikt_patient = newVal.vikt ?? null;

                if (newVal.langd && newVal.vikt) {
                    this.formData.bmi = this._manuallyComputedBMI(newVal.langd, newVal.vikt);
                }
            },
        },
        async created() {
            if (!this.can({ action: "read", subject: "preanestesibedomning" })) return;
            if (this.parameters?.operationsanmalan) {
                this.currentOperationsanmalan = cloneDeep(this.parameters.operationsanmalan);
            }
            if (this.parameters?.preanestesibedomning) {
                try {
                    const response = await klinikenApi.get(
                        `operation/preanestesibedomningar/${this.parameters.preanestesibedomning.id}/`,
                        getConfig({}, "Preanestesibedömning")
                    );

                    this.formData = cloneDeep(response.data);
                    this.initialFormData = cloneDeep(this.formData);
                } catch (error) {
                    flashMessage(getErrorMessage(error));
                }
            }

            this.$on("visaPreanestesibedomning", (item) => {
                this.visaPreanestesibedomning(item);
            });
        },
    };
</script>

<style lang="scss">
    .preanestesibedomning {
        @import "@/style/deprecated_main";
        @import "@/style/variables";

        position: relative;
        max-width: 1100px;

        .left-pad {
            margin-left: 10px;
        }

        button,
        .left-pad {
            > img {
                margin-right: 8px;
            }
        }

        .form-group-border {
            border-top: 1px solid #6fb8d2;
            border-bottom: 1px solid #6fb8d2;
            padding-top: 10px;
        }

        .form-group-first {
            border-left: 1px solid #6fb8d2;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .form-group-last {
            border-right: 1px solid #6fb8d2;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .k3-big-font {
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 22px;
        }

        .k3-fake-table {
            margin-left: 17px;
            width: 1100px;
            background-color: #f6f8fb;
            padding: 20px;
        }

        .k3-card {
            width: 1100px;
            background-color: #f6f8fb;
            padding: 22px;
            margin: 22px 10px 32px 10px;
        }

        .btn {
            &:disabled {
                background-color: #38a7cf;
                color: #ffffff;
                opacity: 0.4;

                img {
                    filter: none;
                }
            }
        }

        img {
            width: 16px;
            height: 16px;
        }

        .circle {
            height: 26px;
            width: 26px;
            background-color: #277692;
            border-radius: 13px;

            .number {
                height: 26px;
                width: 100%;
                color: #ffffff;
                font-family: Roboto;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 26px;
                text-align: center;
            }
        }

        .vr {
            border-left: 1px solid #277692;
            height: calc(100% - 60px);
            min-height: 10px;
            margin-left: 13px;
            margin-top: 10px;
            margin-bottom: 10px;

            &.vr-no-min-height {
                min-height: 0px;
            }
        }

        .k3-form {
            flex-flow: column nowrap;
        }

        .k3-sub-form-flex-container {
            display: flex;
            flex-direction: row;
            margin-left: -20px;
        }

        .k3-sub-form {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .k3-dropdown-icon {
            margin-left: 15px;
        }

        .k3-img-rotate {
            transform: rotate(180deg);
        }

        .hr {
            box-sizing: border-box;
            height: 1px;
            width: 100%;
            border-top: 1px solid #979797;
        }

        .btn-group {
            margin-top: 25px !important;
            margin-bottom: 55px !important;

            > .btn {
                flex: 0 0 auto !important;
            }

            > .btn-primary {
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 22px;
            }
        }
        .tidigare-preanestesibedomning-container {
            margin: 28px 0;
            .tidigare-preanestesibedomning {
                background-color: #f6f8fb;
                .custom-action-table-select {
                    th {
                        color: #277692;
                        font-family: Roboto;
                        font-size: 14px;
                        font-weight: 600 !important;
                        letter-spacing: 0;
                        line-height: 16px;
                    }

                    td:first-of-type {
                        padding: 0px 12px 0px 12px;
                    }

                    .custom-action-table-select-radio {
                        display: inline-block;
                        height: 20px !important;
                        width: 20px !important;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        border-radius: 50%;
                        border: 1px solid #95a1b1;
                        background: radial-gradient(circle, #ffffff 0%, #ffffff 100%);
                    }

                    .custom-action-table-select-radio-checked {
                        background: radial-gradient(circle, #ffffff 0%, #ffffff 30%, #ca3470 30%, #ca3470 100%);
                    }
                }
                .button-standard {
                    margin: 17px;
                }
            }
        }
        strong {
            color: $color-dark;
        }

        ::v-deep(.button-link) {
            font-size: 16px;
            min-width: fit-content;
            width: fit-content;
            white-space: nowrap;
        }

        .icon-blue {
            color: $color-button-standard;
        }
    }
</style>
