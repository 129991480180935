import { getConfig, klinikenApi } from "@/api";
import { cloneDeep } from "lodash";

const getDefaultState = () => {
    return {
        underlag: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        underlag(state) {
            return state.underlag;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setUnderlag: (state, item) => {
            const index = state.underlag.findIndex((i) => i.id === item.id);

            if (index !== -1) {
                state.underlag[index] = item;
            } else {
                state.underlag.push(item);
            }

            state.underlag = [...state.underlag];
        },
        setLista: (state, payload) => (state.underlag = payload),
    },
    actions: {
        async save({ dispatch, commit }, underlag) {
            let response;
            if (underlag.id === undefined) {
                response = await dispatch("create", underlag);
            } else {
                response = await dispatch("update", underlag);
            }
            commit("setUnderlag", cloneDeep(response.data));
            return response;
        },
        async create({}, underlag) {
            return await klinikenApi.post(`skreg/underlag/`, underlag, getConfig());
        },
        async update({}, underlag) {
            return await klinikenApi.put(`skreg/underlag/${underlag.id}/`, underlag, getConfig());
        },
        async load({ commit }) {
            const response = await klinikenApi.get(`skreg/underlag/`, getConfig());
            commit("setLista", response.data);
        },
    },
};
