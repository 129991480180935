import $ from "jquery";

/*
 * Child classes must implement close()
 */

export default {
    data() {
        return {
            previousFocus: undefined,
            focusControlActive: false,
            focusElements: "a,button,input,textarea",
            focusWithin: null,
        };
    },
    methods: {
        focusOn(element) {
            this.focusWithin = element || this.$el;
            this.focusControlActive = true;
            this.previousFocus = $(":focus");
            this.$nextTick(() => {
                this.focusFirst();
            });
        },
        focusOff() {
            this.focusControlActive = false;
            if (this.previousFocus && this.previousFocus.focus) this.previousFocus.focus();
        },
        dismiss() {
            this.close();
        },
        focusFirst() {
            $(this.focusWithin).find(this.focusElements).first().focus()
        },
    },
    created() {
        $(document).on("focusout", (event) => {
            if (this.focusControlActive && !this.focusWithin.contains(event.relatedTarget)) this.focusFirst();
        });
        $(document).keyup((event) => {
            if (this.focusControlActive && event.key === "Escape") {
                this.dismiss(event);
            }
        });
    },
};
