<template>
    <div class="pagaende-operation">
        <div class="header-section">
            <div class="header">
                <h2>Pågående operation</h2>
                <h3>{{ getViewTitle }}</h3>
            </div>
            <div class="header-section-left">
                <div class="toolbar">
                    <div class="show-person-id">
                        <div class="labeled-checkbox">
                            <FormulateInput type="checkbox" id="person-id-checkbox" v-model="showPersonID" />
                            <label for="person-id-checkbox" class="checkbox-label">Visa PersonID</label>
                        </div>
                        <hr class="toolbar-divider" />
                    </div>
                    <button @click="today" class="today-button" :class="{ 'active-tab': isToday }">Idag</button>
                    <div class="arrow-buttons">
                        <button class="arrow-button" @click="previous">
                            <img src="@/assets/arrow_left_white.svg" alt="Föregående" />
                        </button>
                        <button class="arrow-button" @click="next">
                            <img src="@/assets/arrow_right_white.svg" alt="Nästa" />
                        </button>
                    </div>
                </div>
                <div class="legend">
                    <div class="legend-entry">
                        <div class="color-box in-time"></div>
                        <span>Operation i tid</span>
                    </div>
                    <div class="legend-entry">
                        <div class="color-box time-exceeded"></div>
                        <span>Operation gått över tid</span>
                    </div>
                    <div class="legend-entry">
                        <div class="color-box x-min-left"></div>
                        <span>{{ operationXMinuterKvar }} minuter kvar</span>
                    </div>
                </div>
            </div>
        </div>
        <FullCalendar :options="calendarOptions" ref="fullCalendar">
            <template v-slot:eventContent="arg">
                <div class="event-info">
                    <span>{{ arg.event.extendedProps.opkod }}</span>
                    <span v-if="showPersonID">&nbsp;{{ arg.event.extendedProps.personId }}</span>
                </div>
                <div class="op-body-wrapper">
                    <div
                        class="opdel forberedelser"
                        :style="{
                            backgroundColor: opdelITid(arg.event, 1),
                            minWidth:
                                (arg.event.extendedProps.forberedelser / arg.event.extendedProps.salstid) * 100 + '%',
                        }"
                    ></div>
                    <div
                        class="opdel optid"
                        :style="{
                            backgroundColor: opdelITid(arg.event, 2),
                            minWidth: (arg.event.extendedProps.optid / arg.event.extendedProps.salstid) * 100 + '%',
                        }"
                    ></div>
                    <div
                        class="opdel avveckling"
                        :style="{
                            backgroundColor: opdelITid(arg.event, 3),
                            minWidth:
                                (arg.event.extendedProps.avveckling / arg.event.extendedProps.salstid) * 100 + '%',
                        }"
                    ></div>
                    <div
                        v-if="arg.event.extendedProps.xMinuterKvar"
                        class="x-minuter-kvar"
                        :style="{
                            left: (minutesUntilXMinuterKvar(arg.event) / arg.event.extendedProps.salstid) * 100 + '%',
                            minWidth: (operationXMinuterKvar / arg.event.extendedProps.salstid) * 100 + '%',
                        }"
                    ></div>
                </div>
            </template>
        </FullCalendar>
    </div>
</template>
<script>
    import BaseTab from "@/tabs/mixins/BaseTab";
    import tabIcon from "@/assets/bed_icon.svg";
    import FullCalendar from "@fullcalendar/vue";
    import interactionPlugin from "@fullcalendar/interaction";
    import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
    import svLocale from "@fullcalendar/core/locales/sv";
    import { getConfig, klinikenApi } from "@/api";
    import moment from "moment";
    import { mapGetters } from "vuex";
    import emitter from "tiny-emitter/instance";
    import InputCheckbox from "@/components/InputCheckbox.vue";

    const LIGHT_GREY = "#DEE2E3";
    const LIGHT_RED = "#FFA4A9";
    const LIGHT_GREEN = "#9BE2C3";

    export default {
        extends: BaseTab,
        name: "PagaendeOperation",
        tabName: "Pågående operation",
        icon: tabIcon,
        components: {
            FullCalendar,
            InputCheckbox,
        },

        data() {
            return {
                calendarApi: null,
                reloadInterval: null,
                calendarOptions: {
                    plugins: [interactionPlugin, resourceTimelinePlugin],
                    schedulerLicenseKey: import.meta.env.VITE_FULLCALENDAR_SCHEDULER_LICENSE_KEY || "",
                    initialView: "resourceTimeline",
                    headerToolbar: false,
                    resourceOrder: "ordning,namn",
                    resources: [],
                    locale: svLocale,
                    events: [],
                    weekends: false,
                    slotMinTime: "07:00:00",
                    slotMaxTime: "19:00:00",
                    resourceLabelClassNames: "resource-label-cell",
                    resourceAreaHeaderContent: "Resurser",
                    nowIndicator: true,
                    eventMinWidth: 1,
                    eventColor: "transparent",
                },
                showPersonID: false,
            };
        },

        computed: {
            ...mapGetters("systemData", ["operationXMinuterKvar"]),
            ...mapGetters("ability", ["can"]),

            hasCalenderApi() {
                return this.calendarApi && this.calendarApi.currentData;
            },
            getViewTitle() {
                return this.hasCalenderApi ? this.calendarApi.currentData.viewTitle : "";
            },
            isToday() {
                const today = new Date();
                return this.hasCalenderApi
                    ? this.calendarApi.currentData.currentDate?.getDate() === today.getDate()
                    : false;
            },
        },

        methods: {
            async today() {
                this.calendarApi.today();
                await this.loadOperationerForCurrentView();
            },
            async previous() {
                this.calendarApi.prev();
                await this.loadOperationerForCurrentView();
            },
            async next() {
                this.calendarApi.next();
                await this.loadOperationerForCurrentView();
            },

            opdelITid(event, opdel) {
                switch (opdel) {
                    case 1:
                        return event.extendedProps.forberedelserITid
                            ? LIGHT_GREEN
                            : event.extendedProps.forberedelserITid === false
                            ? LIGHT_RED
                            : LIGHT_GREY;
                    case 2:
                        return event.extendedProps.optidITid
                            ? LIGHT_GREEN
                            : event.extendedProps.optidITid === false
                            ? LIGHT_RED
                            : LIGHT_GREY;
                    case 3:
                        return event.extendedProps.avvecklingITid
                            ? LIGHT_GREEN
                            : event.extendedProps.avvecklingITid === false
                            ? LIGHT_RED
                            : LIGHT_GREY;
                    default:
                        return LIGHT_GREY;
                }
            },

            minutesUntilXMinuterKvar(event) {
                return (moment(event.extendedProps.xMinuterKvar) - moment(event.start)) / 60000;
            },

            async loadOperationerForCurrentView() {
                const currentDate = moment(this.calendarApi.currentData.dateProfile.currentDate).format("YYYY-MM-DD");
                const params = {
                    bokad_operation_datum: currentDate,
                };

                const response = await klinikenApi.get("/operation/pagaendeoperation/", getConfig({ params: params }));
                let operationer = response.data;

                const events = this.createEvents(operationer);
                this.$set(this.calendarOptions, "events", events);
            },

            createEvents(operationer) {
                let events = [];
                operationer.forEach((op) => {
                    const event = {
                        start: op.pagaende_op_forberedelser_start,
                        end: op.pagaende_op_avveckling_slut,
                        resourceId: op.bokad_sal ?? "0",
                        extendedProps: {
                            anmalanId: op.id,
                            opjournalId: op.operation_id,
                            opkod: op.huvudoperationskod_display?.code ?? "",
                            personId: op.patient_display?.personId ?? "",
                            forberedelser: op.pagaende_op_forberedelser_minuter,
                            forberedelserITid: op.pagaende_op_forberedelser_i_tid,
                            optid: op.pagaende_op_optid_minuter,
                            xMinuterKvar: op.pagaende_op_x_minuter_kvar,
                            optidITid: op.pagaende_op_optid_i_tid,
                            avveckling: op.pagaende_op_avveckling_minuter,
                            avvecklingITid: op.pagaende_op_avveckling_i_tid,
                            salstid: op.pagaende_op_salstid_minuter,
                        },
                    };
                    events.push(event);
                });
                return events;
            },
        },

        async mounted() {
            if (!this.can({ action: "read", subject: "pagaendeoperation" })) {
                await this.$store.dispatch("tabs/closeTab", this.tabId);
                return;
            }
            // Initierar kalenderfunktionerna
            this.calendarApi = await this.$refs.fullCalendar.getApi();

            // Initierar resurser/salar
            const salarResponse = await klinikenApi.get("/operation/salar/", getConfig());
            let resources = salarResponse.data?.results ?? [];
            resources = resources.map((resource) => {
                resource.title = resource.namn;
                return resource;
            });
            this.$set(this.calendarOptions, "resources", resources);

            // Hämtar operationer och skapar events
            await this.loadOperationerForCurrentView();

            emitter.on("pagaende-op-tider-updated", async () => await this.loadOperationerForCurrentView());
            emitter.on(`adjust-tab-${this.$options.tabName}`, async () => {
                setTimeout(() => {
                    this.calendarApi.updateSize();
                }, 10);
            });

            this.reloadInterval = setInterval(async () => {
                await this.loadOperationerForCurrentView();
            }, 60000);
        },

        beforeDestroy() {
            emitter.off("pagaende-op-tider-updated");
            emitter.off(`adjust-tab-${this.$options.tabName}`);
            clearInterval(this.reloadInterval);
        },
    };
</script>
<style lang="scss">
    .pagaende-operation {
        $event-text-color: #55676d;
        $stripes-color: #354052;
        $toolbar-bg-color: #ebf6f8;
        $toolbar-divider-color: #b9d8e3;
        $toolbar-btn-color: #38a7cf;
        $toolbar-text-color: #3092b5;
        $toolbar-arrow-color: #fafbfd;

        .header-section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;

            .header {
                h1 {
                    margin-bottom: 14px;
                }
            }

            .header-section-left {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row-reverse;
                gap: 20px;

                .legend {
                    height: 68px;
                    width: 351px;
                    background-color: #fafbfd;
                    border: solid 1px #e4e8f0;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    padding: 9px;
                    row-gap: 10px;

                    .legend-entry {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .color-box {
                            height: 18px;
                            width: 18px;
                            border-radius: 4px;

                            &.in-time {
                                background-color: #9be2c3;
                            }

                            &.time-exceeded {
                                background-color: #ffa4a9;
                            }

                            &.x-min-left {
                                background-image: repeating-linear-gradient(
                                    145deg,
                                    #dee2e3,
                                    #dee2e3 4.5px,
                                    $event-text-color 4.5px,
                                    $event-text-color 6px
                                );
                            }
                        }

                        span {
                            line-height: 16px;
                            font-size: 14px;
                        }
                    }
                }

                .toolbar {
                    background-color: $toolbar-bg-color;
                    min-width: 166px;
                    height: 68px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 32px;
                    gap: 32px;

                    div.show-person-id {
                        display: flex;
                        align-items: center;
                        gap: 32px;

                        .labeled-checkbox {
                            display: flex;
                            align-items: center;
                            gap: 7px;

                            label {
                                font-size: 14px;
                            }

                            .formulate-input {
                                margin-top: 17px;
                            }

                            .checkbox-label {
                                margin: 0;
                            }
                        }

                        hr.toolbar-divider {
                            border: none;
                            border-left: 1px solid $toolbar-divider-color;
                            height: 40px;
                            width: 1px;
                        }
                    }

                    .today-button {
                        background: none;
                        border: none;
                        font-size: 14px;
                        color: $toolbar-text-color;
                    }

                    .active-tab {
                        font-weight: bold;
                        text-decoration: underline;
                        text-decoration-thickness: 3px;
                        text-underline-offset: 12px;
                    }

                    .arrow-buttons {
                        display: flex;
                        gap: 18px;

                        .arrow-button {
                            background: $toolbar-btn-color;
                            border: none;
                            border-radius: 4px;
                            width: 34px;
                            height: 34px;
                            color: $toolbar-arrow-color;
                        }
                    }
                }
            }
        }

        .fc-timeline-events {
            min-height: 46px;

            .fc-timeline-event-harness {
                margin-top: 5px;
                height: 52px;

                a.fc-event {
                    padding: 0;
                    height: 100%;

                    .fc-event-main {
                        .event-info {
                            color: $event-text-color;
                            font-size: 14px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .op-body-wrapper {
                            height: 30px;
                            display: flex;
                            border-radius: 4px;
                            overflow: hidden;

                            // (Tips till senare: för att inte få vita kanter på operationer utan
                            // förberedelser/avveckling - kolla om opdelarna har syskon)
                            .forberedelser {
                                border-right: 1px solid white;
                            }

                            .optid {
                                border-left: 1px solid white;
                                border-right: 1px solid white;
                            }

                            .avveckling {
                                border-left: 1px solid white;
                            }

                            .x-minuter-kvar {
                                position: absolute;
                                min-height: 30px;
                                background-image: repeating-linear-gradient(
                                    145deg,
                                    transparent,
                                    transparent 4.5px,
                                    $event-text-color 4.5px,
                                    $event-text-color 6px
                                );
                            }
                        }
                    }
                }
            }
        }
    }
</style>
