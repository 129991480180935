<template>
    <div class="expand-flex">
        <div class="expand-flex-main">
            <auto-complete-widget
                v-bind="item"
                v-for="(item, index) in items"
                :key="item.name + '-' + index"
                @search.native="removeItem(index)"
                @autocomplete_changed="updateItem($event)"
            ></auto-complete-widget>
        </div>
        <div class="expand-flex-side">
            <button type="button" class="circle" v-on:click="openNew()">
                <img src="@/assets/frasregister.svg" /></button
            ><span class="circle-label">Lägg till {{ displayname ? displayname : name }} </span>
        </div>
    </div>
</template>

<script>
import AutoCompleteWidget from "@/components/widgets/AutoCompleteWidget.vue";
import linkEvents from "@/linkEvents";

export default {
    name: "ExpandableSuggest",
    components: {
        AutoCompleteWidget,
    },
    props: {
        apisearch: String,
        name: String,
        displayname: String,
        required: Boolean,
        maxlength: String,
        value: undefined,
        map: Function,
        extraParams: Object,
    },
    data: function () {
        return {
            uid: 1,
            items: [
                {
                    apisearch: this.apisearch,
                    name: this.name,
                    required: this.required,
                    maxlength: this.maxlength,
                    value: this.value,
                    creationTime: new Date().getTime(),
                    map: this.map,
                    extraParams: this.extraParams,
                    uid: 1,
                },
            ],
        };
    },
    methods: {
        openNew(value = null) {
            this.items.push({
                apisearch: this.apisearch,
                name: this.name,
                required: this.required,
                maxlength: this.maxlength,
                value: value,
                creationTime: new Date().getTime(),
                map: this.map,
                extraParams: this.extraParams,
                uid: ++this.uid,
            });
        },
        removeItem(index) {
            document.activeElement.blur();
            if (this.items.length > 1) {
                this.items.splice(index, 1);
            }
        },
        updateItem(value) {
            let index = this.items.findIndex((item) => {
                return item.uid === value.uid;
            });
            let newValue = JSON.parse(value.value);
            if (index !== -1)
                this.items.splice(index, 1, {
                    apisearch: this.apisearch,
                    name: this.name,
                    required: this.required,
                    maxlength: this.maxlength,
                    value: newValue,
                    creationTime: new Date().getTime(),
                    map: this.map,
                    extraParams: this.extraParams,
                    uid: value.uid,
                });
        },
        update(update) {
            if (update.value !== undefined) {
                this.items = [];
                let value = update.value;

                // Populate item values, first case array
                if (Array.isArray(value))
                    value.forEach((el) => {
                        this.openNew(el);
                    }, this);
                //second case string
                else this.openNew(value);
            }
        },
    },
    created() {
        linkEvents.$on("update_" + this.name, this.update);

        // Populate item values, first case array
        if (Array.isArray(this.value)) {
            for (var i = 1; i < this.value.length; i++) {
                /*
                let displayName = null;
                if (this.displayName)
                    if (this.displayName[i])
                        displayName = this.displayName[i];
                */
                this.openNew(this.value[i]);
            }
            this.items[0].value = this.value[0];
            /*
            if (this.items[0].displayName)
                this.items[0].displayName = this.displayName[0];
            */
        }
        //second case string
        else {
            this.items[0].value = this.value;
            /*
            this.items[0].code = this.code;
            this.items[0].displayName = this.displayName;
            */
        }
    },
    watch: {
        items() {
            linkEvents.$emit(this.name + "_changed", this.items);
        },
    },
};
</script>

<style lang="sass" scoped>
.expand-flex
    display: flex

.expand-flex-main
    flex-grow: 1

    div:not(:last-of-type)
        margin-bottom: 10px

.expand-flex-side
    flex-grow: 0
    padding-left: 20px

.circle
    margin-top: 10px
    height: 28px
    width: 28px
    border-radius: 14px
    background-color: #38A7CF
    border: none

.circle-label
    margin-left: 38px
    margin-top: -24px
    font-weight: bold !important
    color: black !important
</style>
