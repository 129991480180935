<template>
    <div class="op-salar">
        <admin-lock-border />
        <modal-basic ref="dialog" />
        <h2 v-if="props.parameters.redigera">Redigera opsal</h2>
        <h2 v-else>Skapa ny opsal</h2>
        <div class="header">
            <FormulateForm v-model="formData" @submit="createSal">
                <div class="input-fields">
                    <FormulateInput type="text" name="namn" label="Namn *" validation="required"> </FormulateInput>
                    <FormulateInput
                        type="knumber"
                        name="ordning"
                        label="Ordningsnummer *"
                        validation="required"
                        validation-name="Ordningsnummer"
                    >
                    </FormulateInput>
                    <!-- <div class="radio-buttons">
                        <FormulateInput
                            type="radio"
                            name="active"
                            :options="{ ja: 'Activt värde', nej: 'Inactivt värde' }"
                        >
                        </FormulateInput>
                    </div> -->
                </div>
                <ButtonGroup>
                    <CustomButton type="submit">
                        <span>{{ props.parameters.redigera ? "Redigera opsal" : "Skapa ny opsal" }}</span>
                    </CustomButton>
                    <CustomButton @click.prevent="cancelForm" size="slim" variant="contour">
                        <span>Avbryt</span>
                    </CustomButton>
                </ButtonGroup>
            </FormulateForm>
        </div>
    </div>
</template>

<script>
    import tabIcon from "@/assets/receptforskrivning.svg";
    export default {
        name: "Opsal",
        tabName: "Opsal",
        icon: tabIcon,
    };
</script>

<script setup>
    import { ref, onMounted, useAttrs, toRefs, watch, computed } from "vue";
    import emitter from "tiny-emitter/instance";

    import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";

    import { getConfig, klinikenApi } from "@/api";
    import AdminLockBorder from "@/components/AdminLockBorder.vue";
    import { flashMessage, openDialog } from "@/utils";
    import CustomButton from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import store from "@/store";

    const attrs = useAttrs();

    const props = defineProps({
        parameters: Object,
    });

    const { parameters } = toRefs(props);

    const formData = ref({
        namn: "",
        ordning: "",
        active: "ja",
        ...parameters.value,
    });
    const defaultFormData = ref({ ...formData.value });
    const dialog = ref();

    const cancelForm = async () => {
        if (!formIsChanged.value) {
            store.dispatch("tabs/closeTab", attrs["tab-id"]);
        } else {
            const confirm = await dialog.value.show({
                variant: BasicModalVariant.WARNING,
                title: "Ändringar har gjorts",
                message: "Vill du spara ändringarna?",
                cancelButton: "Avbryt",
                okButton: "Ja",
                cancelButtonVariant: "contour",
            });
            if (confirm) {
                store.dispatch("tabs/closeTab", attrs["tab-id"]);
            } else {
                return;
            }
        }
    };

    watch(formData, async (newValue, oldValue) => {
        if (oldValue.active === undefined) return;
        if (newValue.active !== oldValue.active && props.parameters.redigera) {
            const confirm = await dialog.value.show({
                variant: BasicModalVariant.WARNING,
                title: "Vill du verkligen ändra.",
                cancelButton: "Avbryt",
                okButton: "Ja",
            });
            if (!confirm) {
                formData.value.active = defaultFormData.value.active;
            }
        }
    });

    const formIsChanged = computed(() => {
        return JSON.stringify(formData.value) !== JSON.stringify(defaultFormData.value);
    });

    const createSal = async () => {
        try {
            if (parameters.value.redigera) {
                const response = await klinikenApi.put(
                    `/operation/salar/${parameters.value.salId}/`,
                    formData.value,
                    getConfig()
                );
                if (response.status === 200) {
                    flashMessage("Redigerat");
                }
                await store.dispatch("tabs/closeTab", attrs["tab-id"]);
            } else {
                const response = await klinikenApi.post("/operation/salar/", formData.value, getConfig());
                if (response.status === 201) {
                    flashMessage("Sal skapad");
                }
                await store.dispatch("tabs/closeTab", attrs["tab-id"]);
            }
        } catch (error) {
            openDialog(
                parameters.value.redigera
                    ? "Något gick fel, kunde inte uppdatera sal. "
                    : "Något gick fel, kunde inte skapa sal",
                "warning"
            );
        }
        emitter.emit("opsalar-uppdaterad", {});
    };
</script>
<style lang="scss">
    .op-salar {
        .header {
            h1 {
                margin: 0;
            }
            display: flex;
            gap: 20px;
            align-items: center;
            .input-fields {
                display: flex;
                gap: 20px;
                .radio-buttons {
                    display: flex;
                    gap: 20px;
                    height: 110px;
                    align-items: center;
                    [data-type="radio"] .formulate-input-group {
                        flex-direction: row;
                    }
                }
            }
        }
    }
</style>
