<template>
    <div class="dropdown">
        <button
            @click="open"
            v-on-clickaway="away"
            :disabled="isDropdownDisabled"
            :class="{ disabled: isDropdownDisabled }"
        >
            <img src="@/assets/message.svg" />
        </button>
        <ul v-if="isOpen">
            <li
                v-for="(action, index) in dropdownActions"
                :key="index"
                v-show="action.show(item)"
                @click.prevent="emitAction(action.name, item)"
            >
                <img v-if="action.icon" :src="action.icon" />
                <span> {{ action.title }} </span>
            </li>
        </ul>
    </div>
</template>

<script>
    import { mixin as clickaway } from "vue-clickaway";

    export default {
        name: "KlinikenDropDownMenu",
        mixins: [clickaway],

        props: {
            dropdownActions: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            item: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                isOpen: false,
            };
        },
        computed: {
            isDropdownDisabled() {
                return this.dropdownActions.every((action) => !action.show(this.item));
            },
        },
        methods: {
            open() {
                this.isOpen = true;
            },
            away() {
                this.isOpen = false;
            },
            emitAction(action, item) {
                this.$emit("action", { action, item });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/style/_variables.scss";

    .dropdown {
        position: relative;
        display: flex;
        justify-content: flex-end;
        top: 0px;
        .disabled {
            opacity: $disabled-opacity;
        }
        button {
            background: transparent;
            border: 1px solid $color-menu-button-border;
            border-radius: $border-radius;
            //padding: 4px 7px;
            cursor: pointer;
            &:hover {
                background-color: #cee9f3;
                cursor: pointer;
            }
        }

        ul {
            list-style: none;

            position: absolute;
            right: 0;
            top: 35px;
            z-index: 1;

            margin: 0;
            padding: 14px 0;
            background-color: #ffffff;
            border: 1px solid $color-menu-border;
            border-radius: $border-radius;
            min-width: $column-width;

            li {
                cursor: default;
                white-space: nowrap;
                display: flex;
                padding: 17px 25px;

                &:hover {
                    background-color: $color-menu-hover;
                }

                img {
                    height: 22px;
                    width: 22px;
                    margin-right: 11px;
                }
            }
        }
    }
</style>
