<template>
    <div class="range">
        <template v-if="!preview">
            <input
                type="number"
                :min="min"
                :max="max"
                :name="name"
                :step="step"
                v-model.number="value_"
                v-bind="$attrs"
                @input="validateRange()"
            />
            <span class="increase-decrease">
                <button type="button" @click="increase" tabindex="-1"></button>
                <button type="button" @click="decrease" tabindex="-1"></button>
            </span>
        </template>
        <span v-else class="input-preview">
            {{ value_ }}
        </span>
    </div>
</template>
<script>
    export default {
        name: "NumberWidget",
        props: {
            name: String,
            min: Number,
            max: Number,
            step: {
                type: Number,
                default: 1,
            },
            value: undefined, // Can be String or Number
            preview: Boolean,
            isIntegerOnly: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                value_: isNaN(this.parse(this.value)) ? undefined : this.parse(this.value),
            };
        },
        computed: {
            stepDecimals() {
                return this.step % 1 ? this.step.toString().split(".")[1].length : 0;
            },
        },
        methods: {
            increase() {
                if (this.value_ == "" || isNaN(this.value_)) {
                    this.value_ = 0;
                }
                this.value_ += this.step;
                this.validateRange();
            },
            decrease() {
                if (this.value_ == "" || isNaN(this.value_)) this.value_ = 0;
                this.value_ -= this.step;
                this.validateRange();
            },

            validateRange() {
                this.value_ = this.parse(this.value_);

                if (isNaN(this.value_)) {
                    this.value_ = "";
                    this.$emit("input", this.value_);
                    return true;
                }

                if (this.value_ < this.min) this.value_ = this.min;
                if (this.value_ > this.max) this.value_ = this.max;

                // TODO: add check to only allow multiples of step_
                var intermediateValue = this.value_;
                if (this.stepDecimals > 0) intermediateValue = this.parse(intermediateValue.toFixed(this.stepDecimals));
                this.value_ = intermediateValue;

                this.$emit("input", this.value_);
            },

            parse(value) {
                return this.isIntegerOnly ? parseInt(value) : parseFloat(value);
            },
        },
        watch: {
            min() {
                this.validateRange();
            },
            max() {
                this.validateRange();
            },
            value() {
                this.value_ = this.value;
                this.validateRange();
            },
        },
    };
</script>

<style lang="sass" scoped>
    @import "@/style/variables"

    /* Bootstrap - Start */
    @import "bootstrap/scss/functions"
    @import "bootstrap/scss/variables"
    @import "bootstrap/scss/mixins"
    @import "bootstrap/scss/root"
    @import "bootstrap/scss/reboot"

    @import "bootstrap/scss/type"
    @import "bootstrap/scss/forms"
    @import "bootstrap/scss/buttons"
    @import "bootstrap/scss/button-group"
    @import "bootstrap/scss/input-group"
    @import "bootstrap/scss/custom-forms"
    /* Bootstrap - End */

    @import "@/style/deprecated_main"
    .range
        display: flex

        input
            border-top-right-radius: 0px !important
            border-bottom-right-radius: 0px !important
            border-right: none

        .increase-decrease
            display: flex
            flex-direction: column
            background-image: url('@/assets/plus_minus.svg')
            border: 1px solid #95A1B1
            border-left: none
            border-bottom-right-radius: 4px !important
            border-top-right-radius: 4px !important
            background-color: #FFFFFF

        button
            opacity: 0
            //background-color: white
            //border: 1px solid #95A1B1
            //border-left: none
            height: 22px
            width: 22px
            flex: 0 0 auto
            border: none
            //&:first-of-type
            //    border-top-right-radius: 4px !important
            //&:last-of-type
            //    border-bottom-right-radius: 4px !important
            //    border-top: 0px

    //Supposed to only target IE10, but unfortunately targets IE11 as well.
    @media screen and (min-width:0/0)
        .range
            margin-right: 22px

    // Hide spinners in Firefox
    input[type=number]
        -moz-appearance: textfield

    // Hide spinners in other browsers
    input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0
</style>
