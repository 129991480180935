const TEXT_COLOR_NEUTRAL = "#277692";
const BG_COLOR_NEUTRAL = "#93C9DE";

const TEXT_COLOR_1 = "#D7562C";
const BG_COLOR_1 = "#FFC6B4";

const TEXT_COLOR_2 = "#866CE4";
const BG_COLOR_2 = "#B7A7EF";

const TEXT_COLOR_3 = "#299465";
const BG_COLOR_3 = "#90CFB4";

const TEXT_COLOR_4 = "#A8781E";
const BG_COLOR_4 = "#E4BF7A";

const TEXT_COLOR_5 = "#E472B7";
const BG_COLOR_5 = "#EFAAD4";

const TEXT_COLOR_6 = "#2EA5AA";
const BG_COLOR_6 = "#81C9CC";

const TEXT_COLOR_7 = "#E26E6E";
const BG_COLOR_7 = "#EFAEAE";

const TEXT_COLOR_8 = "#60A74C";
const BG_COLOR_8 = "#B1D6A6";

const TEXT_COLOR_9 = "#7F841E";
const BG_COLOR_9 = "#D3D59E";

const TEXT_COLOR_LAST = "#A746DB";
const BG_COLOR_LAST = "#D3A2ED";

interface IColorEvent {
    textColor: string;
    backgroundColor: string;
    colorNumber?: number;
}

const NEUTRALCOLOR: IColorEvent = {
    textColor: TEXT_COLOR_NEUTRAL,
    backgroundColor: BG_COLOR_NEUTRAL,
    colorNumber: 0,
};

const FIRSTCOLOR: IColorEvent = {
    textColor: TEXT_COLOR_1,
    backgroundColor: BG_COLOR_1,
    colorNumber: 1,
};

const LASTCOLOR: IColorEvent = {
    textColor: TEXT_COLOR_LAST,
    backgroundColor: BG_COLOR_LAST,
    colorNumber: 10,
};

const eventColorScheme: IColorEvent[] = [
    FIRSTCOLOR,
    {
        textColor: TEXT_COLOR_2,
        backgroundColor: BG_COLOR_2,
        colorNumber: 2,
    },
    {
        textColor: TEXT_COLOR_3,
        backgroundColor: BG_COLOR_3,
        colorNumber: 3,
    },
    {
        textColor: TEXT_COLOR_4,
        backgroundColor: BG_COLOR_4,
        colorNumber: 4,
    },
    {
        textColor: TEXT_COLOR_5,
        backgroundColor: BG_COLOR_5,
        colorNumber: 5,
    },
    {
        textColor: TEXT_COLOR_6,
        backgroundColor: BG_COLOR_6,
        colorNumber: 6,
    },
    {
        textColor: TEXT_COLOR_7,
        backgroundColor: BG_COLOR_7,
        colorNumber: 7,
    },
    {
        textColor: TEXT_COLOR_8,
        backgroundColor: BG_COLOR_8,
        colorNumber: 8,
    },
    {
        textColor: TEXT_COLOR_9,
        backgroundColor: BG_COLOR_9,
        colorNumber: 9,
    },
    LASTCOLOR,
];

const getEventColor = (id: number): Omit<IColorEvent, "colorNumber"> => {
    /***
     * This function returns the color for the event based on the id, if
     * the id is 0 or null it returns the NEUTRALCOLOR or if the id is
     * greater than the length of the eventColorScheme it returns the
     * LASTCOLOR.
     * @param id: number
     * @returns IColorEvent
     * @throws Error
     */
    if (id === null || id == 0) return NEUTRALCOLOR;

    if (isNaN(id)) throw new Error("Missing id");

    if (id > eventColorScheme.length) return LASTCOLOR;

    return eventColorScheme[id - 1];
};

export { FIRSTCOLOR, LASTCOLOR, eventColorScheme, NEUTRALCOLOR, getEventColor, BG_COLOR_NEUTRAL, TEXT_COLOR_NEUTRAL };
