<template>
    <div class="patient-search">
        <div class="header">
            <h1>Sök patient</h1>
            <button type="button" @click="openPatientRegistration">
                <img src="@/assets/plus_circle_blue.svg" />
                <span>Skapa patient</span>
            </button>
        </div>
        <search-filter v-model="filterValues" @input="handleSearch" :fields="filterFields">
            <template v-slot:header-filters>
                <FormulateInput class="search-field" name="q" placeholder="Sök patient" v-model="q" />
            </template>
            <template v-slot:fields>
                <FormulateInput type="text" name="personId__icontains" label="PersonID" />
                <FormulateInput type="text" name="fornamn__icontains" label="Förnamn" />
                <FormulateInput type="text" name="efternamn__icontains" label="Efternamn" />
                <FormulateInput type="datepicker" name="dateOfBirth" label="Födelsedatum" />
                <FormulateInput type="text" name="adress" label="Adress" />
                <FormulateInput type="text" name="postnummer__icontains" label="Postnummer" />
                <FormulateInput type="text" name="postort__icontains" label="Postort" />
                <FormulateInput type="tel" name="telefon__icontains" label="Telefon" />
            </template>
        </search-filter>
        <div class="subheader">
            <span>
                Visar <strong>{{ items.length }}</strong> av <strong>{{ count }}</strong> träffar
            </span>
        </div>
        <kliniken-table
            :columns="columns"
            :items="items"
            :hasDropdown="true"
            :dropdownActions="dropdownActions"
            @editPatient="editPatient"
            :count="count"
            :limit="limit"
            :selectedItem="selectedItem"
            @sort="sort"
            @loadMore="loadMore"
            @changeLimit="changeLimit"
            @tableRowClicked="tableRowClicked"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseTab from "@/tabs/mixins/BaseTab.js";
import tabEvents, { getQueue } from "@/tabEvents";
import KlinikenTable from "@/components/Table/KlinikenTable.vue";
import SearchFilter from "@/components/SearchFilter.vue";
import ButtonLink from "@/components/ButtonLink.vue";
import tabIcon from "@/assets/operationskoordinator.svg";
import { getConfig, klinikenApi } from "@/api";
import { openDialog } from "@/utils";
import PatientRegistration from "@/tabs/Patient/PatientRegistration.vue";
import EditIcon from "@/assets/actionIcons/edit.svg";

export default {
    extends: BaseTab,
    name: "PatientSearch",
    tabName: "Sök/välj patient",
    icon: tabIcon,
    components: { KlinikenTable, SearchFilter, ButtonLink },

    props: {
        tabId: Number,
        parameters: Object,
    },

    data() {
        return {
            q: "",
            filterValues: {},
            sortField: "",
            fetchBatch: 1,
            limit: 20,
            columns: [
                {
                    title: "PersonID",
                    name: "personId",
                    sort: "patient__personId",
                },
                {
                    title: "Förnamn",
                    name: "fornamn",
                    sort: "patient__fornamn",
                },
                {
                    title: "Efternamn",
                    name: "efternamn",
                    sort: "patient__efternamn",
                },
                {
                    title: "Födelsedatum",
                    name: "dateOfBirth",
                    sort: "patient__dateOfBirth",
                },
                {
                    title: "Adress",
                    name: "adressRad",
                    sort: "patient__adressRad",
                    type: "adress",
                },
                {
                    title: "Postnummer",
                    name: "postnummer",
                    sort: "patient__postnummer",
                },
                {
                    title: "Postort",
                    name: "postort",
                    sort: "patient__postort",
                },
                {
                    title: "Telefon",
                    name: "telefon",
                    sort: "patient__telefon",
                },
            ],
            dropdownActions: [
                {
                    name: "editPatient",
                    title: "Redigera patientinformation",
                    icon: EditIcon,
                    show: () => {
                        return true;
                    },
                },
            ],
            loading: false,
            selectedItem: null,
            items: [],
            count: 0,
        };
    },

    computed: {
        ...mapGetters("patientData", ["currentId"]),
        filterFields() {
            return {
                q: { label: "Sök " },
                personId__icontains: { label: "PersonID" },
                fornamn__icontains: { label: "Förnamn " },
                efternamn__icontains: { label: "Efternamn " },
                dateOfBirth: { label: "Födelsedatum" },
                adress: { label: "Adress" },
                postnummer__icontains: { label: "Postnummer" },
                postort__icontains: { label: "Postort" },
                telefon__icontains: { label: "Telefon" },
            };
        },
    },

    watch: {
        currentId() {
            if (this.currentId !== this.selectedItem?.id) {
                this.selectedItem = null;
            }
        },

        "parameters.q": {
            immediate: true,
            handler(newValue) {
                this.q = newValue || "";
                this._searchPatientQuery();
            },
        },
    },

    methods: {
        async fetchAnmalningar() {
            let params = {
                ...this.filterValues,
                ...{
                    ordering: this.sortField,
                },
            };

            if (this.limit > 0) {
                params["limit"] = this.limit * this.fetchBatch;
            }

            this.loading = true;

            try {
                const response = await klinikenApi.get("/core/patienter/", getConfig({ params }));
                this.items = response.data.results;
                this.count = response.data.count;
            } catch (error) {
                openDialog(error, "error");
            }
            this.loading = false;
        },

        async handleSearch(filterData) {
            this.filterValues = filterData;
            await this.fetchAnmalningar();
        },

        async sort(field) {
            this.sortField = field;
            await this.fetchAnmalningar();
        },
        async loadMore() {
            this.fetchBatch++;
            await this.fetchAnmalningar();
        },
        async changeLimit(limit) {
            this.fetchBatch = 1;
            this.limit = limit;
            await this.fetchAnmalningar();
        },

        async tableRowClicked(item) {
            if (item) {
                this.selectedItem = item;
                await this.$store.dispatch("patientData/loadFromBackend", {
                    id: item.id,
                    failSilently: false,
                });
                await this.$store.dispatch("alertInformation/loadAlertInformationSymbol", { patientId: item.id });
                await this.$store.dispatch("alertInformation/loadAlertInformationList", { patientId: item.id });

                const queuedTab = getQueue(); // Note: getQueue sets queue in tabEvents to null. Important to store queue locally.
                if (queuedTab) {
                    this.$store.dispatch("tabs/openTab", { component: queuedTab });
                }
            }
        },

        openPatientRegistration() {
            this.$store.dispatch("tabs/openTab", {
                component: PatientRegistration,
            });
        },

        async editPatient(item) {
            await this.$store.commit("patientData/setEditPatient", item.id);
            this.$store.dispatch("tabs/openTab", {
                component: PatientRegistration,
            });
        },

        async _searchPatientQuery() {
            this.q = this.parameters?.q ?? "";

            if (this.q) {
                this.filterValues.q = this.q;
                await this.fetchAnmalningar();
            }
        },
        async _refreshPatientSearchHandler() {
            await this.fetchAnmalningar();
        },
    },

    created() {
        tabEvents.$on("refreshPatientSearch", this._refreshPatientSearchHandler);
    },

    async mounted() {
        await this._searchPatientQuery();
    },

    beforeDestroy() {
        tabEvents.$off("refreshPatientSearch");
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_variables.scss";

.patient-search {
    .header {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 26px;

        h1 {
            margin: 0px !important;
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 34px;
            color: #277692;
        }

        .button-hollow:hover {
            background-color: transparent;
        }

        button {
            background: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 8px;
            }

            span {
                color: #6c8188;
                font-family: Roboto Medium, serif;
                font-size: 16px;
                line-height: 0px;
                white-space: nowrap;
            }
        }
    }

    .subheader {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        span {
            font-size: 22px;
            line-height: 30px;
            font-weight: normal;
        }

        strong {
            font-size: 22px;
            line-height: 30px;
            font-weight: normal;
            font-family: Roboto Medium, sans-serif;
        }
    }

    .search-field {
        width: calc(256px * 1.5);
    }
}
</style>
