<template>
    <div
        v-if="hasTextBelow || haveHistoryBelow || sektion.text !== '' || historicalsektioner.length"
        class="rekursiv-sektion"
    >
        <div v-if="showSokord" :class="[{ border: depth > 1, indent: depth > 1 }]">
            <div
                v-if="typ === 'checkbox'"
                class="checkbox"
                :class="{ checked: sektion.text !== 'false', unchecked: sektion.text === 'false' }"
            >
                <div class="check"></div>
                <div class="label">{{ sektion.sokord.namn }}</div>
            </div>
            <div v-else>
                <div class="sokord" :class="{ root: depth === 1 }">
                    {{ sektion.sokord.namn }}
                </div>
                <pre v-if="sektion.text" class="text">{{ sektion.text }}</pre>
            </div>

            <div
                v-for="(historicalsektion, index) in historicalsektioner"
                :key="index"
                class="historia"
                :class="{ child: depth > 1 }"
            >
                <div class="version">
                    <div class="header">
                        <div>
                            Tidigare version
                            {{ moment(historicalsektion.created_at).format("YYYY-MM-DD [kl.] HH:mm") }}
                        </div>
                        <div>
                            av {{ historicalsektion.created_by ? historicalsektion.created_by.user.name : "[Saknas]" }}
                        </div>
                    </div>
                    <div
                        v-if="typ === 'checkbox'"
                        class="checkbox"
                        :class="{
                            checked: historicalsektion.text !== 'false',
                            unchecked: historicalsektion.text === 'false',
                        }"
                    >
                        <div class="check"></div>
                        <div class="label">{{ sektion.sokord.namn }}</div>
                    </div>
                    <div v-else>
                        <div class="sokord" :class="{ root: depth === 1 }">
                            {{ sektion.sokord.namn }}
                        </div>
                        <pre v-if="historicalsektion.text" class="text">{{ historicalsektion.text }}</pre>
                        <pre v-else class="text empty">[ ingen notering ]</pre>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="sektion.sektioner.length" :class="{ indent: depth > 1 }">
            <recursive-section
                v-for="subsektion in sektion.sektioner"
                :key="subsektion.pk"
                :sektion="subsektion"
                :depth="depth + 1"
                :historisk="historisk"
            />
        </div>
    </div>
</template>

<script>
import moment from "moment/moment";

export default {
    name: "RecursiveSection",
    props: {
        sektion: Object,
        depth: Number,
        historisk: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        moment() {
            return moment;
        },
        typ() {
            return this.sektion.sokordsstruktur ? this.sektion.sokordsstruktur.typ : null;
        },
        hasTextBelow() {
            return this.findTextBelow(this.sektion.sektioner);
        },
        haveHistoryBelow() {
            return this.historisk && this.findHistoryBelow(this.sektion.historicalsektioner, this.sektion.text);
        },
        showSokord() {
            switch (this.typ) {
                case "hidden":
                    return false;
                case "checkbox":
                    return this.historisk || this.sektion.text !== "false";
                default:
                    return true;
            }
        },
        historicalsektioner() {
            let historicalsektioner = [];
            if (this.historisk && this.sektion.historicalsektioner) {
                let lastChange = this.sektion.text;
                for (let sektion of this.sektion.historicalsektioner) {
                    if (sektion.text !== lastChange) {
                        historicalsektioner.push(sektion);
                    }
                    lastChange = sektion.text;
                }
            }
            return historicalsektioner;
        },
    },
    methods: {
        findTextBelow(sektioner) {
            const x = sektioner.find((item) => item.text !== "");
            if (x === undefined) {
                for (let sektion of sektioner) {
                    if (sektion.sektioner) {
                        let y = this.findTextBelow(sektion.sektioner);
                        if (y !== undefined) return y;
                    }
                }
            } else return x;
        },
        findHistoryBelow(sektioner, text) {
            if (this.historisk && sektioner) {
                for (let sektion of sektioner) {
                    if (this.findHistoryBelow(sektion.sektioner)) {
                        return true;
                    }

                    if (sektion.historicalsektioner) {
                        let lastChange = text;
                        for (let hs of sektion.historicalsektioner) {
                            if (hs.text !== lastChange) {
                                return true;
                            } else {
                                lastChange = hs.text;
                            }
                        }
                    }
                }
            }

            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.rekursiv-sektion {
    &:first-child > .border:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    &:last-child > .border:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .indent {
        padding-left: 23px;
    }

    .border {
        position: relative;
        top: -1px;
        border-left: 3px solid #c8e6ec;
        padding-top: 1px;
    }

    .checkbox-sokord {
        display: flex;
        align-items: center;
    }

    .sokord {
        height: 30px;
        margin-top: 10px;
        padding-bottom: 6px;

        color: $color-active-dark;

        font-size: 16px;
        font-weight: 400;
        line-height: 30px;

        &.root {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 4px;
        }
    }

    .checkbox {
        display: flex;
        justify-content: flex-start;

        .check {
            border: 1px solid $list-border-color;
            height: 16px;
            width: 16px;
            border-radius: 4px;
            margin: 2px 10px 2px 0;
        }

        .label {
            box-sizing: border-box;
            color: $color-text-black;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            margin-right: 26px;
            text-align: left;
            text-decoration-color: rgb(53, 64, 82);
        }

        &.checked {
            .check:after {
                content: "";
                position: relative;
                display: block;
                top: 1px;
                left: 4px;
                width: 6px;
                height: 11px;
                border: solid #6c8188;
                border-top-width: medium;
                border-right-width: medium;
                border-bottom-width: medium;
                border-left-width: medium;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }
        }

        &.unchecked {
            .check {
                border: none;
                background-color: $list-border-color;
            }

            .label {
                text-decoration: line-through;
            }
        }
    }

    .text {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: $color-text-black;
        white-space: pre-wrap;
        margin: 0;
        border: none;
        padding-bottom: 7px;
    }

    .historia {
        padding-bottom: 20px;

        .version {
            background-color: #f0f3f8;
            margin-top: 20px;
            border: 1px solid #e4e8f0;
            border-radius: 4px;
            padding: 20px;
            color: #354052;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 20px;

            .header {
                flex: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                margin-bottom: 17px;
                color: $color-greyline;
                font-size: 14px;
                line-height: 16px;

                div:first-child {
                    padding-right: 17px;
                }

                div + div {
                    border-left: 1px solid $color-greyline;
                    padding-left: 17px;
                    padding-right: 17px;
                }
            }
        }

        .text.empty {
            color: #7b868a;
        }
    }
}
</style>
