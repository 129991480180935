<template>
    <div class="permission-denied-alert">
        <div :class="['custom-alert', getbackground]">
            <img src="@/assets/lock-dark.svg" alt="Icon" width="16" height="16" />
            <span class="content">{{ message }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from "vue";
    const props = defineProps({
        roll: {
            type: String,
            default: "",
        },
        type: String,
    });
    const getbackground = computed(() => {
        switch (props.type) {
            case "warning":
                return "warning";
            case "info":
                return "info";
            default:
                return "default";
        }
    });
    const rollString = computed(() => props.roll && `[${props.roll}]`);
    const messageOne = "Din användarprofil har inte rättighet att redigera vissa sektioner på sidan";
    const messageTwo = "Du har inte rättighet att redigera eftersom din användarprofil har rollen";
    const message = computed(() => `${props.roll ? messageTwo : messageOne} ${rollString.value}`);
</script>

<style scoped lang="scss">
    @import "@/style/variables.scss";
    .permission-denied-alert {
        display: flex;
        align-items: center;
        margin: 16px 0 16px 0;
        .custom-alert {
            height: 44px;
            display: flex;
            gap: 8px;
            border-radius: 6px;
            padding: 8px 16px 8px 16px;
            color: $color-text-black;
            align-items: center;
        }
        .default {
            background-color: #a6d0d7;
        }
        .warning {
            background-color: #ffe8e0;
        }
        .info {
            background-color: #69c4f5;
        }
    }
</style>
