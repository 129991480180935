<template>
    <div>
        <input
            v-if="!preview"
            :value="value"
            v-bind="$attrs"
            :type="type"
            :name="name"
            :required="required"
            :maxlength="maxlength"
            :minlength="minlength"
            class="text-input"
            @blur="$emit('blur')"
            @input="$emit('input', $event.target.value)"
        />
        <span v-else class="input-preview">
            {{ value }}
        </span>
    </div>
</template>

<script>
export default {
    name: "TextWidget",
    props: {
        name: String,
        value: String,
        required: Boolean,
        type: String,
        maxlength: Number,
        minlength: Number,
        preview: Boolean,
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

/* Bootstrap - Start */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
/* Bootstrap - End */

@import "@/style/deprecated_main";

form .error input,
form .error textarea,
form .error select,
form input.error,
form select.error,
form textarea.error {
    border-bottom: 4px solid #9e354b !important;
}
</style>
