<script>
import tabIcon from "@/assets/narvarostatistik.svg";
import BaseTab from "@/tabs/mixins/BaseTab.js";
import HollowButton from "@/components/widgets/HollowButton.vue";
import PlusCircleBlue from "@/assets/plus_circle_blue.svg";
import { mapActions, mapGetters } from "vuex";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import FormUnderlag from "@/tabs/Skreg/Underlag/FormUnderlag.vue";

const noNull = (str) => {
    return str || "";
};

const TABLE_COLUMNS = [
    {
        name: "namn",
        title: "Namn",
        template: (item) => {
            return noNull(item.namn);
        },
    },
];

const TABLE_ACTIONS = [
    {
        name: "edit",
        title: "Redigera",
        img: "edit.svg",
        condition: () => {
            return true;
        },
    },
];

export default {
    extends: BaseTab,
    name: "ListUnderlag",
    tabName: "Underlag",
    icon: tabIcon,

    components: {
        HollowButton,
        CustomActionTable,
    },

    data() {
        return {
            PlusCircleBlue,
            TableColumns: TABLE_COLUMNS,
            TableActions: TABLE_ACTIONS,
        };
    },

    computed: {
        ...mapGetters("skreg/underlag", ["underlag"]),
    },

    methods: {
        ...mapActions("skreg/underlag", ["load"]),
        async createUnderlag() {
            await this.$store.dispatch("tabs/openTab", { component: FormUnderlag });
        },
        async updateUnderlag(underlag) {
            await this.$store.dispatch("tabs/closeTab", FormUnderlag);
            await this.$store.dispatch("tabs/openTab", {
                component: FormUnderlag,
                parameters: { underlag: underlag },
            });
        },
    },
    async created() {
        await this.load();
        this.$on("edit", async (underlag) => {
            await this.updateUnderlag(underlag);
        });
    },
};
</script>

<template>
    <div>
        <h2>
            Underlag
            <hollow-button @click="createUnderlag" :icon="PlusCircleBlue" label="Skapa nytt underlag" />
        </h2>
        <custom-action-table
            class="no-top"
            :selectableRows="false"
            :items="underlag"
            :columns="TableColumns"
            :actions="TableActions"
        />
    </div>
</template>

<style lang="scss" scoped>
.hollow-button {
    margin-left: 50px;
}
</style>
