import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";
import URLSearchParams from "@ungap/url-search-params";

const getDefaultParameters = () => {
    return {};
};

const getDefaultState = () => {
    return {
        icdkoder: [],
        count: 0,
        limit: 100,
        offset: 0,
        parameters: getDefaultParameters(),
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        icdkoder(state) {
            return state.icdkoder;
        },
        count(state) {
            return Math.ceil(state.count);
        },
        limit(state) {
            return state.limit;
        },
        offset(state) {
            return state.offset;
        },
        parameters(state) {
            return state.parameters;
        },
        icdkod(state) {
            return state.icdkod;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setICDKoder: (state, icdkoder) => {
            state.icdkoder = icdkoder;
        },
        setCount: (state, count) => {
            state.count = count;
        },
        setLimit: (state, limit) => {
            state.limit = limit;
        },
        setOffset: (state, offset) => {
            state.offset = offset;
        },
        setParameters: (state, parameters) => {
            state.parameters = parameters;
        },
        resetParameters(state) {
            state.parameters = getDefaultState();
        },
    },
    actions: {
        async load({ commit, state }, failSilently = false) {
            try {
                const qs = new URLSearchParams(state.parameters);
                qs.set("limit", state.limit);
                qs.set("offset", state.offset);

                const response = await klinikenApi.get(`core/kodverk/icdkoder/?${qs}`, getConfig());
                commit("setICDKoder", response.data.results);
                commit("setCount", response.data.count);
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta ICDKod." + getErrorMessage(e), "warning");
                }
            }
        },
        setOffset: ({ commit }, offset) => {
            commit("setOffset", offset);
        },
        setParameters: ({ commit }, parameters) => {
            commit("setParameters", parameters);
        },
        resetParameters({ commit }) {
            commit("resetParameters");
        },
        async save(context, data) {
            try {
                const response = await klinikenApi.post(`core/kodverk/icdkoder/`, data.icdkod, getConfig());
                return response;
            } catch (e) {
                openDialog("Kunde inte spara ICDKod: " + getErrorMessage(e), "warning");
            }
        },
        async update(context, data) {
            try {
                const response = await klinikenApi.put(
                    `core/kodverk/icdkoder/${data.icdkod.id}/`,
                    data.icdkod,
                    getConfig()
                );
                return response;
            } catch (e) {
                openDialog("Kunde inte uppdatera ICDKod: " + getErrorMessage(e), "warning");
            }
        },
    },
};
