<script>
import SuggestTextArea from "@/components/widgets/SuggestTextArea.vue";
import { flashMessage } from "@/utils";
import { getDosageText } from "@/tabs/PrescriptionSet/utils.js";

export default {
    extends: SuggestTextArea,
    name: "DosageSuggestTextArea",
    data: function () {
        return {
            variableDosage: new RegExp("^\\d+(\\+\\d+){1,3}$"),
            regularDosage: new RegExp("^\\d+x\\d+$"),
            allowScroll: true,
        };
    },
    methods: {
        /**
         * Invoked on @keydown.space or @blur.
         */
        parseContent: function () {
            if (this.filter) {
                let lengthBefore = this.value_.length;
                this.value_ = this.value_.replace(this.filter, "");
                if (lengthBefore !== this.value_.length) flashMessage("Otillåtna tecken har tagits bort från texten.");
            }

            // if text is empty or undefined, return.
            if (this.lastWord.length === 0) return;

            // check if the word matches a RegExp (i.e. is shorthand dosage), replace last word with verbose dosage
            var dosageText = getDosageText(this.lastWord);
            if (dosageText !== null) {
                this.value_ = this.value_.substring(0, this.indexOfLastWord) + dosageText;
                this.updateValue();
                return;
            }
            // Check if the word matches any key in dictionary. Not sure if we want this behaviour.
            /*
            else if (this.results.length >= 1) {
                this.value_ = this.value_.substring(0, this.indexOfLastWord) + this.results[0].value;
                this.updateValue();
                return;
            }
            */
        },
        settle(event) {
            // Enter is by default disabled to prevent linebreaks being passed by selecting a list item
            if (!isNaN(this.highlightedPosition)) this.select();
            this.updateValue();
            event.stopPropagation();
        },

        /**
         * Recognizes shorthand dosages, and returns their verbose form. If a shorthand is not recognized,
         * returns null.
         */
        dosageText(lastWord) {
            var dosage = null;
            var dosageText = null;

            if (this.regularDosage.test(lastWord)) {
                dosage = lastWord.split("x");
                dosageText = dosage[0] + " st " + dosage[1] + " gånger dagligen";
            } else if (this.variableDosage.test(lastWord)) {
                dosage = lastWord.split("+");
                dosageText = "";

                // TODO: göra dynamiskt? "Officiellt" får man bara ha max 4n i n+n+n+n eftersom det ska motsvara facken en dosett.
                // TODO: ersätta 'st' med annan enhet om det är annan beredningsform?
                if (dosage.length === 2) dosageText = dosage[0] + " st på morgonen, " + dosage[1] + " st på kvällen";
                else if (dosage.length === 3)
                    dosageText =
                        dosage[0] + " st på morgonen, " + dosage[1] + " st till lunch, " + dosage[2] + " st på kvällen";
                // Regexp matchar bara 2-4 siffror
                else
                    dosageText =
                        dosage[0] +
                        " st på morgonen, " +
                        dosage[1] +
                        " st till lunch, " +
                        dosage[2] +
                        " st på kvällen, " +
                        dosage[3] +
                        " st till natten";
            }
            return dosageText;
        },
    },
};
</script>
