<template>
    <div id="dictionary">
        <div class="internal-scroll">
            <table>
                <tbody>
                    <tr
                        v-for="(key, index) in Object.keys(dictionary)"
                        v-bind:key="'dict' + index"
                        @click="setSelectedText(dictionary[key])"
                    >
                        <td style="padding: 5px">
                            <strong>{{ key }}</strong>
                        </td>
                        <td>{{ dictionary[key] }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="btn-group">
                <button type="button" @click="close()" class="btn btn-secondary">Stäng</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import dialogEvents from "@/dialogEvents";

export default {
    computed: {
        ...mapState("dictionary", ["dictionary", "selected"]),
    },
    methods: {
        close() {
            dialogEvents.$emit("closePopup");
        },
        setSelectedText(selected) {
            this.$store.dispatch("dictionary/updateSelected", selected + " ");
        },
    },
};
</script>
<style lang="sass" scoped>
#dictionary
    position: relative
    min-width: 240px
    max-width: 480px
    max-height: 100%
    overflow: hidden

.internal-scroll
    overflow-y: scroll
    max-height: 60vh
    padding-right: 20px

    &::-webkit-scrollbar
        width: 15px

    &::-webkit-scrollbar-track
        background: #e2e7ee

    &::-webkit-scrollbar-thumb
        border-radius: 7.5px
        border: 2px solid #e2e7ee
        background-color: #B8C0CA

    &::-webkit-scrollbar-button:vertical:decrement
        background-image: url('@/assets/scroll_arrow_up.svg')
        background-repeat: no-repeat
        background-position-y: center
        background-position-x: center
        height: 15px
        background-color: #e2e7ee
        border-top-left-radius: 2px
        border-top-right-radius: 2px

    &::-webkit-scrollbar-button:vertical:increment
        background-image: url('@/assets/scroll_arrow_down.svg')
        height: 15px
        background-color: #e2e7ee
        background-repeat: no-repeat
        background-position-y: center
        background-position-x: center
        border-bottom-left-radius: 2px
        border-bottom-right-radius: 2px
</style>
