<template>
    <div class="skadestatistik">
        <h2 class="statistikH2">Skadestatistik</h2>
        <div class="searchBar padding">
            <h4 class="headerText">Filtrera efter skadedatum</h4>
            <div class="row dateinputs">
                <div class="input-div" style="display: flex; flex-direction: column">
                    <div style="display: flex; flex-direction: row">
                        <input-wrapper
                            class="mr-2"
                            :colspan="4"
                            :disabled="false"
                            :component="widgets.DateWidget"
                            name="SkadekortDatumRangeMin"
                            v-model="searchDateMin"
                            :required="false"
                            label="Fr.o.m"
                        />
                        <input-wrapper
                            class="ml-2 mr-4"
                            :colspan="4"
                            :disabled="false"
                            :component="widgets.DateWidget"
                            name="SkadekortDatumRangeMax"
                            v-model="searchDateMax"
                            :required="false"
                            label="T.o.m"
                        />
                    </div>
                    <FormulateForm v-model="formData" class="liga-dropdown">
                        <FormulateInput name="liga" :options="getLigor" type="select" label="Välj liga" />
                    </FormulateForm>
                </div>

                <ButtonGroup>
                    <Button type="button" size="slim" variant="standard" @click.prevent="initialize"> Sök</Button>
                    <Button type="button" size="slim" variant="alert" @click.prevent="clearFormData"> Rensa</Button>
                </ButtonGroup>
            </div>
        </div>

        <div v-if="getLokalisationDataObject.chartData">
            <!-- height: 5px per raw per label/etikett/alternativ-->
            <statistik-component
                chartAxis="y"
                :width="200"
                :height="10 * lengthLabelsLokalisation"
                :chartData="getLokalisationDataObject"
            />
            <statistik-component
                chartAxis="y"
                :width="200"
                :height="10 * lengthLabelsSkademekanism"
                :chartData="getSkademekanismDataObject"
            />
            <statistik-component
                chartAxis="y"
                :width="200"
                :height="15 * lengthLabelsSkadetyp"
                :chartData="getSkadetypDataObject"
            />
        </div>
    </div>
</template>

<script>
import widgets from "@/components/widgets/inputs";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import StatistikComponent from "./StatistikComponent.vue";
import { mapActions, mapGetters } from "vuex";

import BaseTab from "@/tabs/mixins/BaseTab";
import tabIcon from "@/assets/narvarostatistik.svg";
import { getConfig, klinikenApi } from "@/api";
import { cloneDeep } from "lodash";
import StatistikPopup from "./StatistikenPopup.vue";
import dialogEvents from "@/dialogEvents";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

export default {
    extends: {
        BaseTab,
    },
    name: "StatistikMain",
    tabName: "Skadestatistik",
    icon: tabIcon,
    components: {
        InputWrapper: InputWrapper,
        StatistikComponent,
        StatistikPopup,
        Button,
        ButtonGroup,
    },
    data() {
        return {
            widgets: widgets,
            searchDateMin: null,
            searchDateMax: null,
            // downloadURL: "skreg/statistik/ishockey/export-excel/",
            reportData: null,
            reportDataLokalisation: null,
            reportDataSkademekanism: null,
            reportDataSkadetyp: null,
            formData: {
                liga: "Alla ligor",
            },
            options: {
                indexAxis: "y",
                onClick: this.graphClickEvent,
                plugins: {
                    title: {
                        display: true,
                        text: "Skador lokalisation",
                    },
                },
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 1,
                                beginAtZero: true,
                                callback: function (value) {
                                    if (value % 1 === 0) {
                                        return value;
                                    }
                                },
                            },
                        },
                    ],
                },
                legend: {
                    display: true,
                },
                responsive: true,
                maintainAspectRatio: true,
            },
        };
    },
    computed: {
        ...mapGetters("systemData", ["kundNamn"]),
        //charts datatyp objekt
        ...mapGetters("skreg/statistik", [
            "getSkademekanismDataObject",
            "getLokalisationDataObject",
            "getSkadetypDataObject",
            "getIdrotter",
            "getLigor",
            "getIdrott",
            "getLiga",
        ]),

        showDownloadSelectedBtn() {
            return this.searchDateMin || this.searchDateMax || this.formData.liga !== "Alla ligor" ? true : false;
        },
        lengthLabelsSkadetyp() {
            return this.$store.state.skreg.statistik.skadetypDataObject.chartData.labels.length;
        },
        lengthLabelsSkademekanism() {
            return this.$store.state.skreg.statistik.skademekanismDataObject.chartData.labels.length;
        },
        lengthLabelsLokalisation() {
            return this.$store.state.skreg.statistik.lokalisationDataObject.chartData.labels.length;
        },
    },
    methods: {
        ...mapActions("skreg/statistik", [
            "fetchSkadekort",
            "fetchIdrotter",
            "fetchLigor",
            "setIdrott",
            "clearStatistik",
        ]),

        async clearFormData() {
            this.searchDateMin = null;
            this.searchDateMax = null;
            this.formData.liga = "Alla ligor";
            await this.initialize();
        },

        async initialize() {
            let _chartopt = cloneDeep(this.dataSetsOptions());
            await this.fetchSkadekort({
                from: this.searchDateMin,
                to: this.searchDateMax,
                idrott: this.getIdrott,
                liga: this.formData.liga == "" ? "Alla ligor" : this.formData.liga,
                chartOptions: _chartopt,
            });
            setTimeout(() => {
                this.$emit("updateTable");
            }, 1000);
        },

        async downloadDataAsExcel(download_params) {
            const response = await klinikenApi.get(
                `skreg/statistik/idrotter/${this.getIdrott}/export-excel/`,
                getConfig({
                    responseType: "blob",
                    params: {
                        startDate: download_params.from,
                        endDate: download_params.to,
                        liga: download_params.liga,
                    },
                })
            );
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            const date = new Date();
            link.download =
                "statistik_ishockey_" +
                String(date.getFullYear()).padStart(2, "0") +
                String(1 + date.getMonth()).padStart(2, "0") +
                String(date.getDate()).padStart(2, "0") +
                String(date.getHours()).padStart(2, "0") +
                String(date.getMinutes()).padStart(2, "0") +
                ".xlsx";
            link.click();
            URL.revokeObjectURL(link.href);
        },

        downloadNonSelectedDatesDataAsExcel() {
            this.downloadDataAsExcel({
                from: "",
                to: "",
                liga: "",
            });
        },
        downloadSelectedDataAsExcel() {
            this.downloadDataAsExcel({
                from: this.searchDateMin,
                to: this.searchDateMax,
                liga: this.formData.liga == "Alla ligor" ? "" : this.formData.liga,
            });
        },

        dataSetsOptions() {
            const datasets = [
                {
                    label: `${this.kundNamn} Match`,
                    backgroundColor: "rgba(255, 99, 132, 0.6)",
                    borderWidth: 1,
                    borderColor: "rgba(255,99,132,1)",
                    pointBorderColor: "#2554FF",
                    stack: "Stack 0",
                },
                {
                    label: `${this.kundNamn} Träning`,
                    backgroundColor: "rgba(255, 69, 102, 0.2)",
                    //backgroundColor: "--background-color",
                    borderWidth: 1,
                    borderColor: "rgba(255,69,102,1)",
                    pointBorderColor: "#2554FF",
                    stack: "Stack 0",
                },
                {
                    label: `${this.formData.liga} Match`,
                    backgroundColor: "rgba(132, 99, 255, 0.6)",
                    borderWidth: 1,
                    borderColor: "rgba(132,99,255,1)",
                    pointBorderColor: "#2554FF",
                    stack: "Stack 1",
                },
                {
                    label: `${this.formData.liga} Träning`,
                    backgroundColor: "rgba(102, 69, 255, 0.2)",
                    borderWidth: 1,
                    borderColor: "rgba(102,69,255,1)",
                    pointBorderColor: "#2554FF",
                    stack: "Stack 1",
                },
            ];
            return datasets;
        },

        openPopup() {
            let _chartopt = cloneDeep(this.dataSetsOptions());
            dialogEvents.$emit("openPopup", {
                title: "Välja idrott",
                component: StatistikPopup,
                dismissable: false,
                data: {
                    chartOptions: _chartopt,
                    liga: this.getLiga,
                },
            });
        },
    },

    async created() {
        await this.fetchIdrotter();
        let _idrott = this.getIdrotter[0];
        this.setIdrott(_idrott);
        await this.fetchLigor(this.getIdrott);
        if (this.getIdrotter.length > 1) {
            this.openPopup();
        } else {
            await this.initialize();
        }
    },
    beforeDestroy() {
        this.clearStatistik();
    },
};
</script>

<style scoped lang="scss">
@import "@/style/_variables.scss";
@import "@/style/_deprecated_main.scss";

.skadestatistik {
    .table {
        .buttonDiv {
            display: flex;
        }

        .visited {
            text-decoration: line-through;
        }
    }

    .headerText {
        color: $color-thead;
        font-family: Roboto;
        letter-spacing: 0;
        line-height: 34px;
        font-weight: bold;
        font-size: large;
    }

    .searchBar {
        background-color: $color-blue-light;

        .dateinputs {
            display: flex;
            justify-content: space-between;

            .input-div {
                display: flex;
                justify-items: start;

                .mr-2 {
                    margin-right: 2rem;
                }

                .mr-4 {
                }

                margin-right: 5rem;
            }

            .buttons-group {
                .mr-2 {
                    margin-right: 1rem;
                }

                margin-top: auto;
            }

            .liga-dropdown {
                &[data-type="select"] {
                    label {
                    }
                }

                color: $color-input-border;
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
                display: block;
                margin-bottom: 2px;
                position: relative;
                white-space: normal;
            }
        }
    }

    .padding {
        padding: 1rem;
    }

    .downloadButton {
        display: flex;
        justify-content: flex-end;

        button {
            background-color: $color-primary;
            color: #ffffff;
            border: none;
            border-radius: 5px;
            align-self: right;
            padding: 15px;
            margin-left: 10px;
            margin-top: 10px;
        }

        .choosedDatesDownloadButton button {
            background-color: $color-thead;
            color: #ffffff;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter,
    .fade-leave-to

        /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    .slot {
        section {
            margin-bottom: 30px;
        }
    }
}
</style>
