<template>
    <div
        class="FilhanteringForhandsvisningContainer"
        v-bind:class="{ filhanteringModal: modalClass, filhanteringInline: inlineClass }"
        v-if="src"
    >
        <component v-bind:src="src" v-bind:inlineOrModal="inlineOrModal" v-bind:is="komponentForFiltyp"></component>
    </div>
</template>

<script>
import { getConfig, klinikenApi } from "@/api";
import FilhanteringForhandsvisningBild from "./FilhanteringForhandsvisningBild.vue";
import FilhanteringForhandsvisningPdf from "./FilhanteringForhandsvisningPdf.vue";
import FilhanteringForhandsvisningOhanteradFiltyp from "./FilhanteringForhandsvisningOhanteradFiltyp.vue";

export default {
    name: "FilhanteringForhandsvisningContainer",
    props: ["file", "url", "metaData", "inlineOrModal"],

    data() {
        return {
            src: undefined,
        };
    },

    computed: {
        modalClass() {
            return this.inlineOrModal === "modal";
        },
        inlineClass() {
            return this.inlineOrModal !== "modal";
        },

        displayFile() {
            return this.src !== undefined;
        },

        komponentForFiltyp() {
            let result = undefined;
            switch (this.metaData.filformat) {
                case "PNG":
                case "JPG":
                case "TIFF":
                case "GIF":
                case "BMP":
                    result = FilhanteringForhandsvisningBild;
                    break;

                case "WAV":
                case "MP3":
                    result = FilhanteringForhandsvisningOhanteradFiltyp;
                    break;

                case "MP4":
                    result = FilhanteringForhandsvisningOhanteradFiltyp;
                    break;

                case "PDF":
                    result = FilhanteringForhandsvisningPdf;
                    break;

                default:
                    result = FilhanteringForhandsvisningOhanteradFiltyp;
                    break;
            }
            return result;
        },
    },
    async mounted() {
        // We passed a file which means we are uploading
        // something and have the file in browser memory
        if (this.file !== undefined) {
            this.src = URL.createObjectURL(this.file);
        } else {
            // We passed a url and have to get that
            // through the api so that our request
            // is authenticated. We get the result into memory
            // and set it on the image.src the same way
            // we'd done if it had been passed as a file

            const response = await klinikenApi.get(this.url, getConfig({ responseType: "blob" }, "Filer"));
            this.src = URL.createObjectURL(response.data);
        }
    },
};
</script>

<style lang="scss" scoped>
div.FilhanteringForhandsvisningContainer {
    &.filhanteringModal {
        div {
            height: 100%;
        }
    }

    &.filhanteringInline {
        height: 20rem;

        > div {
            height: 100%;
        }
    }
}
</style>
