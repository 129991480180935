<template>
    <div>
        <modal-basic ref="dialog" />
        <h2 class="left-pad">Preanestesibedömning</h2>
        <br />

        <h3 class="left-pad">Kopplad till operationsanmälan</h3>

        <info-bar
            class="left-pad"
            v-if="
                preanestesibedomning.operationsanmalan_display &&
                Object.keys(preanestesibedomning.operationsanmalan_display).length > 0
            "
        >
            <template v-slot:default>
                Operationsanmälan skapad
                {{ new Date(preanestesibedomning.operationsanmalan_display?.created_at).toLocaleDateString("sv-SE") }}
                av {{ preanestesibedomning.operationsanmalan_display?.created_by_display?.user_display?.name }},
                {{
                    preanestesibedomning.operationsanmalan_display?.created_by_display?.yrkeskategori_display
                        ?.displayName
                }}
                | {{ preanestesibedomning.operationsanmalan_display?.huvudoperationskod_display?.displayName }}
            </template>
            <template v-slot:right>
                <operationsanmalan-status
                    :status="preanestesibedomning.operationsanmalan_display.status"
                    :text="preanestesibedomning.operationsanmalan_display?.status"
                />
            </template>
        </info-bar>

        <br />

        <div class="form-group k3-form">
            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">1</div>
                    </div>
                    <div class="vr"></div>
                </div>
                <div>
                    <div class="left-pad" @click="toggleVisible('operation')">
                        <h3>
                            Operation<img
                                :class="['k3-dropdown-icon', !hidden.operation ? 'k3-img-rotate' : '']"
                                src="@/assets/dropdown-arrow-blue.svg"
                            />
                        </h3>
                    </div>
                    <collapse-transition>
                        <div class="k3-sub-form k3-card" v-if="!hidden.operation">
                            <div style="width: inherit">
                                <GridContainer>
                                    <GridRow :columns="12">
                                        <GridItem :span="4">
                                            <template>
                                                <strong>Operatör</strong><br />
                                                {{
                                                    preanestesibedomning.operationsanmalan_display
                                                        ?.huvudoperator_display?.user_display?.name ?? "-"
                                                }}
                                            </template>
                                        </GridItem>

                                        <GridItem :span="4">
                                            <template>
                                                <strong>Opassistent</strong><br />
                                                <div
                                                    v-if="
                                                        preanestesibedomning.operationsanmalan_display
                                                            ?.ass_operatorer_display &&
                                                        preanestesibedomning.operationsanmalan_display
                                                            ?.ass_operatorer_display.length
                                                    "
                                                >
                                                    <div
                                                        v-for="(item, index) in preanestesibedomning
                                                            .operationsanmalan_display?.ass_operatorer_display"
                                                        :key="index"
                                                    >
                                                        {{ item?.user_display?.name ?? "-" }}
                                                    </div>
                                                </div>
                                                <div v-else>-</div>
                                            </template>
                                        </GridItem>

                                        <GridItem :span="4">
                                            <template>
                                                <strong>Opdatum</strong><br />
                                                {{
                                                    preanestesibedomning.operationsanmalan_display
                                                        ?.bokad_operation_datum ?? "-"
                                                }}
                                            </template>
                                        </GridItem>
                                    </GridRow>

                                    <GridRow :columns="12">
                                        <GridItem :span="12">
                                            <template>
                                                <strong>Huvudoperationskod</strong><br />
                                                {{
                                                    preanestesibedomning.operationsanmalan_display
                                                        ?.huvudoperationskod_display?.code &&
                                                    preanestesibedomning.operationsanmalan_display
                                                        ?.huvudoperationskod_display?.displayName
                                                        ? preanestesibedomning.operationsanmalan_display
                                                              ?.huvudoperationskod_display?.code +
                                                          " " +
                                                          preanestesibedomning.operationsanmalan_display
                                                              ?.huvudoperationskod_display?.displayName
                                                        : "-"
                                                }}
                                            </template>
                                        </GridItem>
                                    </GridRow>

                                    <GridRow :columns="12">
                                        <GridItem :span="12">
                                            <template>
                                                <strong>Bioperationskoder</strong><br />
                                                <div
                                                    v-if="
                                                        preanestesibedomning.operationsanmalan_display
                                                            ?.bioperationskoder_display &&
                                                        preanestesibedomning.operationsanmalan_display
                                                            ?.bioperationskoder_display.length
                                                    "
                                                >
                                                    <div
                                                        v-for="(item, index) in preanestesibedomning
                                                            .operationsanmalan_display?.bioperationskoder_display"
                                                        :key="index"
                                                    >
                                                        {{
                                                            item.code && item.displayName
                                                                ? item.code + " " + item.displayName
                                                                : "-"
                                                        }}
                                                    </div>
                                                </div>
                                                <div v-else>-</div>
                                            </template>
                                        </GridItem>
                                    </GridRow>

                                    <GridRow :columns="12">
                                        <GridItem :span="12">
                                            <template>
                                                <strong>Huvuddiagnos</strong><br />
                                                {{
                                                    preanestesibedomning.operationsanmalan_display?.huvuddiagnos_display
                                                        ?.code &&
                                                    preanestesibedomning.operationsanmalan_display?.huvuddiagnos_display
                                                        ?.displayName
                                                        ? preanestesibedomning.operationsanmalan_display
                                                              ?.huvuddiagnos_display?.code +
                                                          " " +
                                                          preanestesibedomning.operationsanmalan_display
                                                              ?.huvuddiagnos_display?.displayName
                                                        : "-"
                                                }}
                                            </template>
                                        </GridItem>
                                    </GridRow>

                                    <GridRow :columns="12">
                                        <GridItem :span="12">
                                            <template>
                                                <strong>Bidiagnoskoder</strong><br />
                                                <div
                                                    v-if="
                                                        preanestesibedomning.operationsanmalan_display
                                                            ?.bidiagnoser_display &&
                                                        preanestesibedomning.operationsanmalan_display
                                                            ?.bidiagnoser_display.length
                                                    "
                                                >
                                                    <div
                                                        v-for="(item, index) in preanestesibedomning
                                                            .operationsanmalan_display?.bidiagnoser_display"
                                                        :key="index"
                                                    >
                                                        {{
                                                            item.code && item.displayName
                                                                ? item.code + " " + item.displayName
                                                                : "-"
                                                        }}
                                                    </div>
                                                </div>
                                                <div v-else>-</div>
                                            </template>
                                        </GridItem>
                                    </GridRow>

                                    <GridRow :columns="12">
                                        <GridItem :span="8"></GridItem>

                                        <GridItem :span="2">
                                            <template>
                                                <ButtonLink
                                                    type="button"
                                                    @click="openOperationsanmalan"
                                                    v-if="this.preanestesibedomning.operationsanmalan_display"
                                                >
                                                    Visa operationsanmälan
                                                </ButtonLink>
                                            </template>
                                        </GridItem>
                                    </GridRow>
                                </GridContainer>
                            </div>
                        </div>
                    </collapse-transition>
                </div>
            </div>
        </div>

        <div class="k3-sub-form-flex-container">
            <div>
                <div class="circle">
                    <div class="number">2</div>
                </div>
                <div class="vr"></div>
            </div>
            <div>
                <div class="left-pad" @click="toggleVisible('anestesi')">
                    <h3>
                        Anestesi<img
                            :class="['k3-dropdown-icon', !hidden.anestesi ? 'k3-img-rotate' : '']"
                            src="@/assets/dropdown-arrow-blue.svg"
                        />
                    </h3>
                </div>
                <collapse-transition>
                    <div class="k3-sub-form k3-card" v-if="!hidden.bedomning">
                        <div style="width: inherit">
                            <GridContainer>
                                <GridRow :columns="12">
                                    <GridItem :span="4">
                                        <template>
                                            <strong>Anestesiolog</strong><br />
                                            {{ preanestesibedomning?.anestesiolog_display?.user_display?.name ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="4">
                                        <template>
                                            <strong>Anestesi</strong><br />
                                            {{ preanestesibedomning.anestesi_display?.displayName ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="4" />
                                </GridRow>

                                <GridRow :columns="12"></GridRow>

                                <GridRow :columns="12">
                                    <GridItem :span="12">
                                        <template>
                                            <strong>Anestesikoder</strong><br />
                                            <div
                                                v-if="
                                                    preanestesibedomning?.anestesikoder_display &&
                                                    preanestesibedomning?.anestesikoder_display.length
                                                "
                                            >
                                                <div
                                                    v-for="(item, index) in preanestesibedomning?.anestesikoder_display"
                                                    :key="index"
                                                >
                                                    {{
                                                        item.code && item.displayName
                                                            ? item.code + " " + item.displayName
                                                            : "-"
                                                    }}
                                                </div>
                                            </div>
                                            <div v-else>-</div>
                                        </template>
                                    </GridItem>
                                </GridRow>

                                <GridRow :columns="12">
                                    <GridItem :span="4">
                                        <template>
                                            <strong>Tidigare anestesi</strong><br />
                                            {{ preanestesibedomning.tidigare_anestesi_display?.displayName ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="8">
                                        <template>
                                            <strong>Tidigare anestesikoder</strong><br />
                                            <div
                                                v-if="
                                                    preanestesibedomning?.tidigare_anestesikoder_display &&
                                                    preanestesibedomning?.tidigare_anestesikoder_display.length
                                                "
                                            >
                                                <div
                                                    v-for="(
                                                        item, index
                                                    ) in preanestesibedomning?.tidigare_anestesikoder_display"
                                                    :key="index"
                                                >
                                                    {{
                                                        item.code && item.displayName
                                                            ? item.code + " " + item.displayName + "\n"
                                                            : "-"
                                                    }}
                                                </div>
                                            </div>
                                            <div v-else>-</div>
                                        </template>
                                    </GridItem>
                                </GridRow>
                            </GridContainer>
                        </div>
                    </div>
                </collapse-transition>
            </div>
        </div>

        <div class="k3-sub-form-flex-container">
            <div>
                <div class="circle">
                    <div class="number">3</div>
                </div>
                <div class="vr"></div>
            </div>
            <div>
                <div class="left-pad" @click="toggleVisible('patientdata')">
                    <h3>
                        Patientdata<img
                            :class="['k3-dropdown-icon', !hidden.patientdata ? 'k3-img-rotate' : '']"
                            src="@/assets/dropdown-arrow-blue.svg"
                        />
                    </h3>
                </div>
                <collapse-transition>
                    <div class="k3-sub-form k3-card" v-if="!hidden.patientdata">
                        <div style="width: inherit">
                            <GridContainer>
                                <GridRow :columns="12">
                                    <GridItem :span="3">
                                        <template>
                                            <strong>Rökning</strong><br />
                                            {{ preanestesibedomning.rokning ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Rökavärjning</strong><br />
                                            {{ preanestesibedomning.erbjuden_rokavvanjning ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Krav på slutat röka</strong><br />
                                            {{ preanestesibedomning.krav_sluta_roka ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3" />
                                </GridRow>

                                <br />

                                <GridRow :columns="12">
                                    <GridItem :span="2">
                                        <template>
                                            <strong>Längd (cm)</strong><br />
                                            {{ preanestesibedomning.langd_patient ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="2">
                                        <template>
                                            <strong>Vikt</strong><br />
                                            {{ preanestesibedomning.vikt_patient ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="2">
                                        <template>
                                            <strong>BMI</strong><br />
                                            {{
                                                manuallyComputedBMI(
                                                    this.preanestesibedomning.langd_patient,
                                                    this.preanestesibedomning.vikt_patient
                                                ) ?? "-"
                                            }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="6">
                                        <template>
                                            <strong>Status, AU</strong><br />
                                            {{ preanestesibedomning.status_AT ?? "-" }}
                                        </template>
                                    </GridItem>
                                </GridRow>

                                <br />

                                <GridRow :columns="12">
                                    <GridItem :span="6">
                                        <template>
                                            <strong>Cor</strong><br />
                                            {{ preanestesibedomning.cor ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="6">
                                        <template>
                                            <strong>Pulm</strong><br />
                                            {{ preanestesibedomning.pulm ?? "-" }}
                                        </template>
                                    </GridItem>
                                </GridRow>
                                <br />

                                <GridRow :columns="12">
                                    <GridItem :span="4">
                                        <template>
                                            <strong>Puls</strong><br />
                                            {{ preanestesibedomning.puls ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="4">
                                        <template>
                                            <strong>BT syst.</strong><br />
                                            {{ preanestesibedomning.blodtryck_systoliskt ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="4">
                                        <template>
                                            <strong>BT diast.</strong><br />
                                            {{ preanestesibedomning.blodtryck_diastoliskt ?? "-" }}
                                        </template>
                                    </GridItem>
                                </GridRow>

                                <br />

                                <GridRow :columns="12">
                                    <GridItem :span="3">
                                        <template>
                                            <strong>Hjärt-/kärlsjukdom</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.hjart_karlsjukdom) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Hjärt-kärlsjukdom, beskrivning</strong><br />
                                            {{ preanestesibedomning.hjart_karlsjukdom_text ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Lungsjukdom</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.lungsjukdom) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Lungsjukdom, beskrivning</strong><br />
                                            {{ preanestesibedomning.lungsjukdom_text ?? "-" }}
                                        </template>
                                    </GridItem>
                                </GridRow>

                                <br />

                                <GridRow :columns="12">
                                    <GridItem :span="3">
                                        <template>
                                            <strong>Organkomplikation</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.organkomplikation) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Organkomplikation, beskrivning</strong><br />
                                            {{ preanestesibedomning.organkomplikation_text ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Trombossjukdom/anlag</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.trombossjukdom) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Trombossjukdom/anlag, beskriv</strong><br />
                                            {{ preanestesibedomning.trombossjukdom_text ?? "-" }}
                                        </template>
                                    </GridItem>
                                </GridRow>

                                <br />

                                <GridRow :columns="12">
                                    <GridItem :span="3">
                                        <template>
                                            <strong>Annan sjukdom</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.annan_sjukdom) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Annan sjukdom, beskrivning</strong><br />
                                            {{ preanestesibedomning.annan_sjukdom_text ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Överkänslighet</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.overkanslighet) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Överkänslighet, beskrivning</strong><br />
                                            {{ preanestesibedomning.overkanslighet_text ?? "-" }}
                                        </template>
                                    </GridItem>
                                </GridRow>

                                <br />

                                <GridRow :columns="12">
                                    <GridItem :span="3">
                                        <template>
                                            <strong>Diabetes</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.diabetes) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Blödningsbenägenhet</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.blodningsbenagenhet) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Mp</strong><br />
                                            {{ preanestesibedomning.mp ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Tm avstånd</strong><br />
                                            {{ preanestesibedomning.tm_avstand ?? "-" }}
                                        </template>
                                    </GridItem>
                                </GridRow>

                                <br />

                                <GridRow :columns="12">
                                    <GridItem :span="3">
                                        <template>
                                            <strong>Intubationssvårigheter</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.intubationssvarigheter) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="3">
                                        <template>
                                            <strong>Refluxbesvär</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.refluxbesvar) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="6">
                                        <template>
                                            <strong>Aktuell Medicin</strong><br />
                                            {{ preanestesibedomning.aktuell_medicin ?? "-" }}
                                        </template>
                                    </GridItem>
                                </GridRow>

                                <br />

                                <GridRow :columns="12">
                                    <GridItem :span="6">
                                        <template>
                                            <strong>Information till avdelning</strong><br />
                                            {{ preanestesibedomning.ordinationer_avdelning ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="6">
                                        <template>
                                            <strong>Information till operation</strong><br />
                                            {{ preanestesibedomning.ordinationer_operation ?? "-" }}
                                        </template>
                                    </GridItem>
                                </GridRow>
                                <br />

                                <GridRow :columns="12">
                                    <GridItem :span="3">
                                        <template>
                                            <strong>EKG godkänd</strong><br />
                                            {{ getDropdownDisplayValue(preanestesibedomning.ekg_godkand) }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="9" />
                                </GridRow>
                            </GridContainer>
                        </div>
                    </div>
                </collapse-transition>
            </div>
        </div>

        <div class="k3-sub-form-flex-container">
            <div>
                <div class="circle">
                    <div class="number">4</div>
                </div>
                <div class="vr vr-no-min-height"></div>
            </div>
            <div>
                <div class="left-pad" @click="toggleVisible('bedomning')">
                    <h3>
                        Bedömning<img
                            :class="['k3-dropdown-icon', !hidden.bedomning ? 'k3-img-rotate' : '']"
                            src="@/assets/dropdown-arrow-blue.svg"
                        />
                    </h3>
                </div>
                <collapse-transition>
                    <div class="k3-sub-form k3-card" v-if="!hidden.bedomning">
                        <div style="width: inherit">
                            <GridContainer>
                                <GridRow :columns="12">
                                    <GridItem :span="4">
                                        <template>
                                            <strong>ASA-klassifikation</strong><br />
                                            {{ preanestesibedomning.ASA ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="8" />
                                </GridRow>

                                <GridRow :columns="12">
                                    <GridItem :span="8">
                                        <template>
                                            <strong>Kommentar</strong><br />
                                            {{ preanestesibedomning.kommentar ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="4" />
                                </GridRow>
                            </GridContainer>
                        </div>
                    </div>
                </collapse-transition>
            </div>
        </div>

        <div class="k3-sub-form-flex-container">
            <div>
                <div class="circle">
                    <div class="number">5</div>
                </div>
                <div class="vr vr-no-min-height"></div>
            </div>
            <div>
                <div class="left-pad" @click="toggleVisible('godkannande')">
                    <h3>
                        Godkännande inför operation<img
                            :class="['k3-dropdown-icon', !hidden.godkannande ? 'k3-img-rotate' : '']"
                            src="@/assets/dropdown-arrow-blue.svg"
                        />
                    </h3>
                </div>
                <collapse-transition>
                    <div class="k3-sub-form k3-card" v-if="!hidden.godkannande">
                        <div style="width: inherit">
                            <GridContainer>
                                <GridRow :columns="12">
                                    <GridItem :span="8">
                                        <template>
                                            <strong>Godkänd för operation</strong><br />
                                            {{ preanestesibedomning.godkannande ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="4" />
                                </GridRow>

                                <GridRow :columns="12">
                                    <GridItem :span="8">
                                        <template>
                                            <strong>Krav för godkännade</strong><br />
                                            {{ preanestesibedomning.krav_godkannande ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="4" />
                                </GridRow>

                                <GridRow :columns="12">
                                    <GridItem :span="4">
                                        <template>
                                            <strong>Datum för godkännande</strong><br />
                                            {{ preanestesibedomning.datum_godkannande ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="4">
                                        <template>
                                            <strong>Giltighetstid</strong><br />
                                            {{ preanestesibedomning.giltighetstid ?? "-" }}
                                        </template>
                                    </GridItem>

                                    <GridItem :span="4" />
                                </GridRow>
                            </GridContainer>
                        </div>
                    </div>
                </collapse-transition>
            </div>
        </div>

        <div class="hr" />
        <ButtonGroup>
            <Button size="primary" role="button" type="button" @click="close">Stäng</Button>

            <Button
                size="secondary"
                role="button"
                type="button"
                @click="editPreanestesibedomning"
                :disabled="!can({ action: 'create', subject: 'preanestesibedomning' })"
                >Redigera preanestesibedömning
            </Button>
            <Button
                size="secondary"
                variant="alert"
                type="button"
                @click="aterkallaPreanestesibedomning"
                :disabled="!can({ action: 'create', subject: 'preanestesibedomning' })"
                >Återkalla preanestesibedömning
            </Button>
        </ButtonGroup>
    </div>
</template>
<script>
    import Vue from "vue";
    import { CollapseTransition } from "@ivanv/vue-collapse-transition";
    import BaseTab from "@/tabs/mixins/BaseTab";
    import tabIcon from "@/assets/operationsanmalan.svg";
    import Button from "@/components/Button.vue";
    import ButtonLink from "@/components/ButtonLink.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
    import { GridContainer, GridItem, GridRow } from "@/components/Grid/index.js";
    import { getConfig, getErrorMessage, klinikenApi } from "@/api";
    import { flashMessage, openDialog } from "@/utils";
    import InfoBar from "@/components/InfoBar.vue";
    import { statusar } from "./utils.js";
    import Operationsanmalan from "@/tabs/Operation/Operationsanmalan.vue";
    import Preanestesibedomning from "@/tabs/Operation/Preanestesibedomning.vue";
    import OperationsanmalanStatus from "@/tabs/Operation/OperationsanmalanStatus.vue";
    import { mapGetters } from "vuex";

    export default {
        extends: BaseTab,
        name: "PreanestesibedomningVisa",
        tabName: "Preanestesibedömning",
        icon: tabIcon,
        patientBound: true,
        props: {
            uid: Number,
            parameters: Object,
            tabId: Number,
        },
        components: {
            OperationsanmalanStatus,
            CollapseTransition,
            Button,
            ButtonLink,
            ButtonGroup,
            ModalBasic,
            GridContainer,
            GridRow,
            GridItem,
            InfoBar,
        },
        data() {
            return {
                hidden: {},
                operationsanmalan: {},
                preanestesibedomning: {},
            };
        },
        computed: {
            ...mapGetters("ability", ["can"]),
            statusar() {
                return statusar;
            },
        },
        methods: {
            toggleVisible(subFormName) {
                let hidden = this.hidden[subFormName] || false;
                Vue.set(this.hidden, subFormName, !hidden);
            },

            async openOperationsanmalan() {
                await this.$store.dispatch("operation/operationsanmalan/openOpAnmalan", {
                    component: Operationsanmalan,
                    id: this.operationsanmalan.id,
                    patient: this.operationsanmalan.patient,
                    mode: "opcoordinator",
                });
            },

            close() {
                this.$store.dispatch("tabs/closeTab", this.tabId);
            },

            getDropdownDisplayValue(value) {
                switch (value) {
                    case null:
                        return "-";
                    case true:
                        return "Ja";
                    case false:
                        return "Nej";
                    default:
                        return "-";
                }
            },

            manuallyComputedBMI(langd, vikt) {
                let value = "";
                if (langd && vikt) {
                    value = Number.parseFloat(vikt / Math.pow(langd / 100, 2))
                        .toFixed(1)
                        .toString()
                        .replace(".", ",");
                }
                return value;
            },

            editPreanestesibedomning() {
                this.$store.dispatch("tabs/openTab", {
                    component: Preanestesibedomning,
                    parameters: {
                        operationsanmalan: this.operationsanmalan,
                        preanestesibedomning: this.preanestesibedomning,
                    },
                });
            },

            async aterkallaPreanestesibedomning() {
                const ability = this.can({ action: "update", subject: "preanestesibedomning" });
                if (!ability) return;
                const confirm = await this.$refs.dialog.show({
                    variant: BasicModalVariant.WARNING,
                    title: "Om du återkallar preanestesibedömningen stryks operationen. Vill du verkligen återkalla?",
                    okButton: "Ja, återkalla ",
                    cancelButton: "Nej, avbryt ",
                });

                if (confirm) {
                    let response = await this.$store.dispatch("operation/preanestesibedomning/save", {
                        id: this.preanestesibedomning.id,
                        patient: this.preanestesibedomning.patient,
                        godkannande: "Återkallad",
                    });
                    if (response.status === 200) {
                        flashMessage("Preanestesibedömning återkallad", 2000);
                        this.close();
                    } else {
                        openDialog("Misslyckades med att återkalla preanestesibedömning.", "error");
                    }
                }
            },

            async _fetchPreanestesibedomning(id) {
                try {
                    const response = await klinikenApi(
                        `/operation/preanestesibedomningar/${id}/`,
                        getConfig({
                            params: {},
                        })
                    );

                    this.preanestesibedomning = response.data;
                } catch (error) {
                    flashMessage(getErrorMessage(error));
                }
            },
        },
        async created() {
            await this._fetchPreanestesibedomning(this.parameters.preanestesibedomningId);
        },
    };
</script>

<style lang="sass" scoped>
    @import "@/style/deprecated_main"
    @import "@/style/variables"

    #preane-registration-details
        position: relative
        max-width: 1100px

    .left-pad
        margin-left: 10px

    button, .left-pad
        > img
            margin-right: 8px

    .form-group-border
        border-top: 1px solid #6FB8D2
        border-bottom: 1px solid #6FB8D2
        padding-top: 10px

    .form-group-first
        border-left: 1px solid #6FB8D2
        border-top-left-radius: 4px
        border-bottom-left-radius: 4px

    .form-group-last
        border-right: 1px solid #6FB8D2
        border-top-right-radius: 4px
        border-bottom-right-radius: 4px

    .k3-big-font
        font-size: 20px
        font-weight: 500
        letter-spacing: 0
        line-height: 22px

    .k3-fake-table
        margin-left: 17px
        width: 1100px
        background-color: #F6F8FB
        padding: 20px

    .k3-card
        width: 1100px
        background-color: #F6F8FB
        padding: 22px
        margin: 22px 10px 32px 10px

    .btn
        &:disabled
            background-color: #38a7cf
            color: #FFFFFF
            opacity: 0.4

            img
                filter: none

    img
        width: 16px
        height: 16px

    .circle
        height: 26px
        width: 26px
        background-color: #277692
        border-radius: 13px

        .number
            height: 26px
            width: 100%
            color: #FFFFFF
            font-family: Roboto
            font-size: 18px
            letter-spacing: 0
            line-height: 26px
            text-align: center

    .vr
        border-left: 1px solid #277692
        height: calc(100% - 60px)
        min-height: 10px
        margin-left: 13px
        margin-top: 10px
        margin-bottom: 10px

        &.vr-no-min-height
            min-height: 0px

    .k3-form
        flex-flow: column nowrap

    .k3-sub-form-flex-container
        display: flex
        flex-direction: row
        margin-left: -20px

    .k3-sub-form
        display: flex
        flex-direction: row
        flex-wrap: wrap

    .k3-dropdown-icon
        margin-left: 15px

    .k3-img-rotate
        transform: rotate(180deg)

    .hr
        box-sizing: border-box
        height: 1px
        width: 100%
        border-top: 1px solid #979797

    .btn-group
        margin-top: 25px !important
        margin-bottom: 55px !important

        > .btn
            flex: 0 0 auto !important

        > .btn-primary
            font-size: 20px
            font-weight: 500
            letter-spacing: 0
            line-height: 22px
</style>
<style lang="sass">
    .k3-op-select
        width: 1100px
        margin-left: 17px

        table
            background-color: #F6F8FB

        th
            color: #277692
            font-family: Roboto
            font-size: 14px
            font-weight: 600 !important
            letter-spacing: 0
            line-height: 16px

        td:first-of-type
            padding: 0px 12px 0px 12px

        .k3-mock-radio
            margin-top: 12px
            display: inline-block
            height: 20px !important
            width: 20px !important
            -webkit-appearance: none
            -moz-appearance: none
            border-radius: 50%
            border: 1px solid #95A1B1
            background: radial-gradient(circle, #FFFFFF 0%, #FFFFFF 100%)

        .k3-mock-radio-checked
            background: radial-gradient(circle, #FFFFFF 0%, #FFFFFF 30%, #ca3470 30%, #ca3470 100%)
</style>

<style lang="scss" scoped>
    @import "@/style/variables";

    strong {
        color: $color-dark;
    }

    ::v-deep(.button-link) {
        font-size: 16px;
        min-width: fit-content;
        width: fit-content;
        white-space: nowrap;
    }
</style>
