<template>
    <FormulateForm v-model="formData" class="Statistik-popup">
        <FormulateInput name="idrott" :options="getIdrotter" type="select" label="välja idrott" />
        <FormulateInput type="button" v-on:click="setChoosenIdrott" name="button" label="Visa data" />
    </FormulateForm>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dialogEvents from "@/dialogEvents";
export default {
    data() {
        return {
            data: this.$attrs.response,
            formData: {
                idrotter: {},
                idrott: null,
            },
        };
    },
    computed: {
        ...mapGetters("skreg/statistik", ["getIdrotter", "getIdrott", "getLiga"]),
    },
    methods: {
        ...mapActions("skreg/statistik", ["setIdrott", "fetchSkadekort", "fetchLigor"]),
        async setChoosenIdrott() {
            this.setIdrott(this.formData.idrott);
            await this.fetchLigor(this.getIdrott);
            await this.fetchSkadekort({
                idrott: this.getIdrott,
                chartOptions: this.data.chartOptions,
                liga: this.getLiga,
            });
            dialogEvents.$emit("closePopup");
        },
    },
    mounted() {
        this.setIdrott(this.formData.idrott);
    },
};
</script>
<style scoped lang="scss">
@import "@/style/_variables.scss";
.Statistik-popup {
    width: 400px;
    .formulate-input:nth-child(1) {
        margin-bottom: 20px;
    }
    .formulate-input:nth-child(2)[data-type-button] {
        color: red;
        font-size: large;
    }
}
</style>
