<template>
    <div id="kliniken-menu" :class="{ minimized: minimized }">
        <div class="logo">
            <img v-if="minimized" src="@/assets/kliniken-logo-small.png" alt="Kliniken patientsystem" />
            <img v-else src="@/assets/kliniken-logo.svg" alt="Kliniken patientsystem" />
        </div>
        <div class="size-toggle">
            <button role="button" @click="toggleSize">
                <img
                    src="@/assets/menu.svg"
                    width="35px"
                    height="35px"
                    class="menu-button"
                    v-tooltip="{
                        content: 'Minimera vänstermeny',
                        theme: 'kliniken-tooltip',
                        disabled: minimized,
                    }"
                />
            </button>
        </div>
        <div class="menu">
            <ul class="groups" v-for="rubrik in menu" :key="rubrik.namn">
                <li>
                    <span class="title" v-if="!minimized">{{ rubrik.namn }}</span>
                    <ul class="items">
                        <li
                            v-for="lank in rubrik.lankar"
                            :class="{
                                open: lank.open,
                                visible: lank.visible,
                                disabled: isRubrikDisabled(lank.komponent),
                            }"
                            :key="lank.namn"
                        >
                            <a href="#" @click.prevent="openLank(lank.komponent)">
                                <img
                                    height="22px"
                                    width="22px"
                                    :src="getMenuIcon(lank.komponent)"
                                    alt=""
                                    :disabled="{ disabledLinks: isRubrikDisabled(lank.komponent) }"
                                    v-tooltip="{
                                        content: lank.namn,
                                        theme: 'kliniken-tooltip',
                                        disabled: !minimized,
                                    }"
                                />
                                <span v-if="!minimized">{{ lank.namn }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from "vuex";
    import tabComponents from "@/tabs";
    import { getConfig, klinikenApi } from "@/api";

    export default {
        name: "KlinikenMenu",

        data() {
            return {
                minimized: false,
            };
        },

        computed: {
            ...mapGetters("tabs", ["menu"]),
            ...mapGetters("ability", ["can"]),
        },

        methods: {
            ...mapMutations("tabs", ["setMenu"]),
            ...mapActions("tabs", ["openTab"]),

            isRubrikDisabled(rubrikNamn) {
                const actionSubjectMap = {
                    Operationsanmalan: { action: "create", subject: "anmalan" },
                    Operationskoordinering: { action: "read", subject: "operationskoordinering" },
                    Preanestesibedomning: { action: "create", subject: "preanestesibedomning" },
                    Operationskalender: { action: "read", subject: "operationskalender" },
                    Operationsoversikt: { action: "read", subject: "operationsoversikt" },
                    Sparratid: { action: "read", subject: "sparradtid" },
                    PagaendeOperation: { action: "read", subject: "pagaendeoperation" },
                };

                const actionSubject = actionSubjectMap[rubrikNamn];
                if (actionSubject) {
                    return !this.can(actionSubject);
                } else {
                    return false;
                }
            },
            toggleSize() {
                this.minimized = !this.minimized;
                this.$emit("toggleSize", this.minimized);
            },
            getComponent(name) {
                if (name !== undefined) {
                    return tabComponents[name];
                }
            },
            getMenuIcon(name) {
                const component = this.getComponent(name);
                if (component !== undefined) {
                    return component.icon;
                }
            },
            openLank(name) {
                const component = this.getComponent(name);
                if (component !== undefined) {
                    this.openTab({ component: component });
                }
            },
        },

        async created() {
            const response = await klinikenApi.get("/core/meny/", getConfig(), 1);
            this.setMenu(response.data);
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/style/variables";

    #kliniken-menu {
        background-color: $main-grey-bg;

        .logo {
            background-color: $main-logo-bg;
            text-align: center;
            height: $header-height;

            img {
                width: 120px;
                height: 43px;
                margin-top: 24px;
                margin-right: 24px;
            }
        }

        .size-toggle {
            min-height: 64px;
            border-bottom: 1px solid #dbe3e3;
            display: flex;
            justify-content: flex-end;

            button {
                border: none;
                outline: none;
                background-color: inherit;
            }
        }

        .menu {
            height: calc(100vh - 64px - $header-height);
            overflow-x: hidden;
            overflow-y: auto;

            .groups {
                list-style: none;
                padding: 0;
                margin: 0;

                .title {
                    padding-left: 25px;
                    font-family: Roboto, sans-serif;
                    color: #6c8188;
                    font-size: 19px;
                    line-height: 54px;
                }

                .items {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li:first-of-type {
                        border-top: 1px solid #dbe3e3;
                    }

                    li:last-of-type {
                        border-bottom: 1px solid #dbe3e3;
                    }
                }
                li {
                    font-family: Roboto, sans-serif;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 46px;
                    white-space: nowrap;
                    a {
                        padding-left: 30px;
                        display: block;
                        color: #6c8188;
                        text-decoration: none;

                        img {
                            width: 18px;
                            height: 22px;
                        }

                        span {
                            padding-left: 30px;
                        }
                    }

                    &.open {
                        background: linear-gradient(to right, #ca346f 0px, #ca346f 5px);
                        background-size: 5px 100%;
                        background-repeat: no-repeat;
                        background-position: left;

                        a {
                            color: #ca346f;
                        }
                    }
                }
                .disabled {
                    pointer-events: none;
                    opacity: $disabled-opacity;
                }
            }
        }

        &.minimized {
            .logo {
                img {
                    height: 43px;
                    width: 41px;
                    margin-right: 17px;
                    margin-left: 17px;
                }
            }

            .size-toggle {
                justify-content: center;

                button {
                    transform: rotate(180deg);
                }
            }

            .menu {
                padding-right: 0;
                justify-content: center;

                .groups {
                    .items {
                        li {
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }
</style>
