<template>
    <div class="operationsjournal">
        <modal-basic ref="dialog" />
        <h1>Operationsjournal</h1>
        <PermissionDeniedAlert v-if="isAnyReadonly" type="warning" />
        <collapsible-section number="1" heading="Operationsuppgifter" class="operationsuppgifter">
            <FormulateForm
                :readonly="readonlyOperationsUppgifter"
                @submit="submitOperationsuppgifter"
                v-model="operationsuppgifterFormData"
                :errors="formError"
            >
                <div class="typoperationer">
                    <FormulateInput
                        :readonly="readonlyOperationsUppgifter"
                        :clearable="!readonlyOperationsUppgifter"
                        name="typoperation"
                        :options="typoperationer"
                        type="vueselect"
                        isDropdown="true"
                        placeholder="Välj typoperation"
                        label="Typoperation"
                        v-on:input="typoperationChanged()"
                    />
                </div>

                <FormulateInput
                    :readonly="readonlyOperationsUppgifter"
                    id="huvuddiagnos"
                    name="huvuddiagnos"
                    label="Huvuddiagnoskod *"
                    type="vueselect"
                    :options="huvuddiagnoser"
                    @search="searchHuvuddiagnos"
                    v-on:input="huvuddiagnosChanged()"
                    class="autocomplete"
                />

                <FormulateInput
                    :readonly="readonlyOperationsUppgifter"
                    type="group"
                    name="bidiagnoser"
                    :repeatable="true"
                    add-label="Lägg till bidiagnoser"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            :readonly="readonlyOperationsUppgifter"
                            name="bidiagnos"
                            label="Bidiagnoskod"
                            type="vueselect"
                            isDropdown="true"
                            :options="bidiagnoser[index]"
                            @search="(search, loading) => searchBidiagnoser(index, search, loading)"
                            v-on:input="bidiagnosChanged(index)"
                            class="autocomplete"
                        />
                    </template>

                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeBidiagnoser(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <div class="readonly">
                                <button
                                    type="button"
                                    :disabled="readonlyOperationsUppgifter"
                                    data-minor
                                    :data-ghost="readonlyOperationsUppgifter"
                                    @click="addMoreBidiagnoser(addMore)"
                                >
                                    <span class="formulate-input-element--button--label"
                                        >Lägg till fler bidiagnoser</span
                                    >
                                </button>
                            </div>
                        </div>
                    </template>
                </FormulateInput>

                <FormulateInput
                    :readonly="readonlyOperationsUppgifter"
                    id="huvudoperationskod"
                    name="huvudoperationskod"
                    label="Huvudoperationskod *"
                    type="vueselect"
                    isDropdown="true"
                    :options="huvudoperationskoder"
                    @search="searchHuvudoperationskod"
                    v-on:input="huvudoperationskodChanged()"
                    class="autocomplete"
                />

                <FormulateInput
                    :readonly="readonlyOperationsUppgifter"
                    type="group"
                    name="bioperationskoder"
                    :repeatable="true"
                    add-label="Lägg till bioperationskod"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            :readonly="readonlyOperationsUppgifter"
                            name="bioperationskod"
                            label="Bioperationskod"
                            type="vueselect"
                            :options="bioperationskoder[index]"
                            @search="(search, loading) => searchBioperationskoder(index, search, loading)"
                            v-on:input="bioperationskodChanged(index)"
                            class="autocomplete"
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeBioperationskoder(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button
                                :readonly="readonlyOperationsUppgifter"
                                type="button"
                                something="something"
                                data-minor=""
                                data-ghost=""
                                @click="addMoreBioperationskoder(addMore)"
                            >
                                <span class="formulate-input-element--button--label"
                                    >Lägg till fler bioperationskoder</span
                                >
                            </button>
                        </div>
                    </template>
                </FormulateInput>

                <div class="row">
                    <FormulateInput
                        :readonly="readonlyOperationsUppgifter"
                        type="date"
                        name="operationsdatum"
                        label="Operationsdatum"
                        @input="removeError('operationsdatum')"
                    />

                    <FormulateInput
                        :readonly="readonlyOperationsUppgifter"
                        name="sal"
                        label="Opsal"
                        type="vueselect"
                        :options="salar"
                        placeholder="Välj Opsal"
                    />

                    <FormulateInput
                        :disabled="readonlyOperationsUppgifter"
                        type="checkbox"
                        name="reop"
                        label="Reoperation"
                    />

                    <FormulateInput
                        :disabled="readonlyOperationsUppgifter"
                        type="checkbox"
                        name="garantiop"
                        label="Garantioperation"
                    />
                </div>

                <div class="row">
                    <FormulateInput
                        :readonly="readonlyOperationsUppgifter"
                        name="lateralitet"
                        label="Lateralitet"
                        type="vueselect"
                        :options="lateraliteter"
                    />
                    <FormulateInput
                        :readonly="readonlyOperationsUppgifter"
                        class="anmarkning"
                        name="anmarkning"
                        type="textarea"
                        label="Anmärkning"
                    />
                </div>
            </FormulateForm>
            <div class="grey-div">
                <Signeringansvarig
                    :readonly="readonlyOperationsUppgifter"
                    type="submit"
                    @emitSigneringsansvarigaAction="submitOperationsuppgifter"
                    :isSignable="true"
                    :currentProfile="currentProfile"
                    :isSigned="operationsuppgifterFormData.is_signed"
                    :loading="isSaved"
                    :operationsjournalid="operationjournal_id"
                    :orgunit="operationsanmalan.huvudoperator_display?.orgUnit"
                    :accountableHealthcareProfessional="operationsuppgifterFormData.accountableHealthcareProfessional"
                    :editing="editing"
                ></Signeringansvarig>

                <sign-frame
                    v-if="signVisibleOperationsuppgifter"
                    title="Operationsuppgifter"
                    :url="signUrlOperationsuppgifter"
                    :data="signDataOperationsuppgifter"
                    @success="handleSignOperationsuppgifterSuccess"
                    @error="handleSignOperationsuppgifterError"
                    @cancel="handleSignOperationsuppgifterCancel"
                />
            </div>
        </collapsible-section>

        <collapsible-section number="2" heading="Tider" class="tider">
            <FormulateForm @submit="submitTider" v-model="tiderFormData" :errors="formError">
                <h4>Förberedelser</h4>
                <div class="wrapper-3-1">
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="uppdukning_start"
                        label="Uppdukning start"
                        @blur="submitTid('uppdukning_start')"
                        @clicknow="submitTid('uppdukning_start')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="uppdukning_slut"
                        label="Uppdukning slut"
                        @blur="submitTid('uppdukning_slut')"
                        @clicknow="submitTid('uppdukning_slut')"
                    />
                </div>
                <div class="wrapper-3-1">
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="antibiotikaprofylax_start"
                        label="Antibiotikaprofylax start"
                        @blur="submitTid('antibiotikaprofylax_start')"
                        @clicknow="submitTid('antibiotikaprofylax_start')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="ankomsttid_preopenhet"
                        label="Ankomsttid preopenhet"
                        @blur="submitTid('ankomsttid_preopenhet')"
                        @clicknow="submitTid('ankomsttid_preopenhet')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="preoperativ_anestesi_start"
                        label="Preoperativ anestesi start"
                        @blur="submitTid('preoperativ_anestesi_start')"
                        @clicknow="submitTid('preoperativ_anestesi_start')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="patienttid_start"
                        label="Patienttid start"
                        @blur="submitTid('patienttid_start')"
                        @clicknow="submitTid('patienttid_start')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="patient_in_opsal"
                        label="Patient in på opsal"
                        @blur="submitTid('patient_in_opsal')"
                        @clicknow="submitTid('patient_in_opsal')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="anestesitid_start"
                        label="Anestesitid start"
                        @blur="submitTid('anestesitid_start')"
                        @clicknow="submitTid('anestesitid_start')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="anestesitid_klar"
                        label="Anestesi klar"
                        @blur="submitTid('anestesitid_klar')"
                        @clicknow="submitTid('anestesitid_klar')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="operationsforberedelse_klar"
                        label="Operationsförberedelse klar"
                        @blur="submitTid('operationsforberedelse_klar')"
                        @clicknow="submitTid('operationsforberedelse_klar')"
                    />
                </div>

                <h4>Optid</h4>
                <div class="wrapper-3-1">
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="opstart"
                        label="Operation start"
                        @blur="submitTid('opstart')"
                        @clicknow="submitTid('opstart')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="x_minuter_kvar"
                        :label="`${operationXMinuterKvar} minuter kvar`"
                        @blur="submitTid('x_minuter_kvar')"
                        @clicknow="submitTid('x_minuter_kvar')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="opslut"
                        label="Operation slut"
                        @blur="submitTid('opslut')"
                        @clicknow="submitTid('opslut')"
                    />
                </div>

                <h4>Avveckling</h4>
                <div class="wrapper-3-1">
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="anestesi_slut"
                        label="Anestesi slut"
                        @blur="submitTid('anestesi_slut')"
                        @clicknow="submitTid('anestesi_slut')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="patient_ut_opsal"
                        label="Patient ut från opsal"
                        @blur="submitTid('patient_ut_opsal')"
                        @clicknow="submitTid('patient_ut_opsal')"
                    />
                    <FormulateInput
                        :readonly="readonlyTider"
                        type="timepicker"
                        useNow
                        :useDropdown="false"
                        name="patienttid_slut"
                        label="Patienttid slut"
                        @blur="submitTid('patienttid_slut')"
                        @clicknow="submitTid('patienttid_slut')"
                    />
                </div>
                <FormulateInput
                    :readonly="readonlyTider"
                    type="textarea"
                    name="fritext"
                    label="Kommentar tider"
                    class="fritext"
                    @blur="submitTid('fritext')"
                ></FormulateInput>
            </FormulateForm>
        </collapsible-section>

        <ModalContent
            title="Checklista 1 - Förberedelser"
            :showCloseButton="true"
            v-if="showWHOchecklistaForberedelse"
            @close="showWHOchecklistaForberedelse = false"
            class="who-modal"
        >
            <div class="header">
                <DialogAktivPatient :patient="operationsanmalan.patient_display" />

                <div class="datum">
                    <strong>Datum</strong>
                    {{ getCurrentDate }}
                </div>

                <div class="tid">
                    <strong>Tid</strong>
                    {{ getCurrentHourMinute }}
                </div>
            </div>

            <h3>Före inledning av anestesi</h3>

            <hr />

            <FormulateForm @submit="submitWHOchecklistaForberedelse" v-model="whochecklistaForberedelseFormData">
                <FormulateInput :disabled="readonlyWhoForberwdelse" name="patient_bekraftat" type="checkbox">
                    <template #label="{ id }">
                        <div>
                            <label :for="id">Patienten har bekräftad följande:</label>
                            <ul>
                                <li>
                                    <label>identitet</label>
                                </li>
                                <li>
                                    <label>plats för incision</label>
                                </li>
                                <li>
                                    <label>informerad om och samtycker till operation</label>
                                </li>
                            </ul>
                        </div>
                    </template>
                </FormulateInput>

                <hr />

                <FormulateInput
                    :disabled="readonlyWhoForberwdelse"
                    name="operationsomrade_markerat"
                    type="checkbox"
                    label="Operationsområde markerat/ej tillämpligt"
                />

                <hr />

                <FormulateInput
                    :disabled="readonlyWhoForberwdelse"
                    name="sakerhetskontroll_for_anestesi"
                    type="checkbox"
                    label="Säkerhetskontroll för anestesi genomförd"
                />

                <hr />

                <FormulateInput
                    :disabled="readonlyWhoForberwdelse"
                    name="fungerande_pulsoximeter"
                    type="checkbox"
                    label="Fungerande pulsoximeter kopplad"
                />

                <hr />

                <p>Har patienten något av följande:</p>

                <FormulateInput
                    :disabled="readonlyWhoForberwdelse"
                    name="kand_allergi"
                    label="Känd allergi?"
                    :options="{ false: 'Nej', true: 'Ja' }"
                    type="radio"
                />

                <FormulateInput
                    :disabled="readonlyWhoForberwdelse"
                    name="risk_for_aspiration"
                    label="Risk för aspiration/svår intubation?"
                    :options="{
                        false: 'Nej',
                        true: 'Ja och utrustning/assistans är tillgänglig',
                    }"
                    type="radio"
                />

                <FormulateInput
                    :disabled="readonlyWhoForberwdelse"
                    name="risk_for_blodforlust"
                    label="Risk för >500 ml blodförlust (7 ml/kg för barn)?"
                    :options="{
                        false: 'Nej',
                        true: 'Ja, och tillfredsställande intravenösa infarter och blod/vätskor är planerade',
                    }"
                    type="radio"
                />

                <FormulateInput
                    :disabled="readonlyWhoForberwdelse"
                    name="risk_for_hypotermi"
                    label="Risk för hypotermi?"
                    :options="{
                        false: 'Nej',
                        true: 'Ja, och åtgärder är planerade/vidtagna',
                    }"
                    type="radio"
                />

                <hr />

                <i>
                    Published by the World Health Organization in 2008 under the title WHO surgical safety checklist 1st
                    edition 2008 (TR/08/216). Producerad av Landstingens Ömsesidiga Försäkringsbolag mars 2009.
                </i>
                <hr />

                <ButtonGroup>
                    <Button :disabled="readonlyWhoForberwdelse" type="submit" size="primary">Spara</Button>
                    <Button
                        :disabled="readonlyWhoForberwdelse"
                        type="button"
                        size="secondary"
                        variant="contour"
                        @click.prevent="showWHOchecklistaForberedelse = false"
                    >
                        Stäng
                    </Button>
                </ButtonGroup>
            </FormulateForm>
        </ModalContent>

        <ModalContent
            title="Checklista 2 - Timeout"
            :showCloseButton="true"
            v-if="showWHOchecklistaTimeOut"
            @close="showWHOchecklistaTimeOut = false"
            class="who-modal"
        >
            <div class="header">
                <DialogAktivPatient :patient="operationsanmalan.patient_display" />

                <div class="datum">
                    <strong>Datum</strong>
                    {{ getCurrentDate }}
                </div>

                <div class="tid">
                    <strong>Tid</strong>
                    {{ getCurrentHourMinute }}
                </div>
            </div>

            <h3>Före incision</h3>

            <hr />

            <FormulateForm v-model="whochecklistaTimeoutFormData" @submit="submitWHOchecklistaTimeOut">
                <FormulateInput
                    :disabled="readonlyWhoTimeout"
                    name="medlemmar_presenterat_sig"
                    type="checkbox"
                    label="Bekräfta att alla medlemmar i laget presenterat sig med namn och roll"
                />

                <hr />

                <FormulateInput :disabled="readonlyWhoTimeout" type="checkbox" name="bekraftar_muntligt">
                    <template #label="{ id }">
                        <div>
                            <label :for="id">
                                Anestesiolog/anestesisjuksköterska och operationssjuksköterska, operatör bekräftar
                                muntligt:
                            </label>
                            <ul>
                                <li>
                                    <label>patient ID</label>
                                </li>
                                <li>
                                    <label>plats för incision</label>
                                </li>
                                <li>
                                    <label>planerad operation</label>
                                </li>
                            </ul>
                        </div>
                    </template>
                </FormulateInput>

                <hr />

                <div class="checkbox-group">
                    <p>Väntade kritiska moment under operationen</p>

                    <FormulateInput
                        :disabled="readonlyWhoTimeout"
                        type="checkbox"
                        name="bedomning_patientspecifika_problem"
                    >
                        <template #label="{ id }">
                            <label :for="id">
                                <strong>Anestesipersonalens bedömning: </strong> finns några patientspecifika eller
                                anestesiologiska problem?
                            </label>
                        </template>
                    </FormulateInput>

                    <FormulateInput
                        :disabled="readonlyWhoTimeout"
                        type="checkbox"
                        name="bedomning_sterilitet_bekraftat"
                    >
                        <template #label="{ id }">
                            <label :for="id">
                                <strong>Operationssjuksköterskans bedömning: </strong> har sterilitet bekräftats?
                            </label>
                        </template>
                    </FormulateInput>

                    <FormulateInput
                        :disabled="readonlyWhoTimeout"
                        name="bedomning_problem_utrustning"
                        type="checkbox"
                        label="Finns några problem med utrustning eller annat?"
                    />

                    <FormulateInput
                        :disabled="readonlyWhoTimeout"
                        type="checkbox"
                        name="bedomning_korrekt_upplagd"
                        label="Är patienten korrekt upplagd?"
                    />

                    <FormulateInput :disabled="readonlyWhoTimeout" type="checkbox" name="bedomning_kritiska_moment">
                        <template #label="{ id }">
                            <label :for="id">
                                <strong>Operatörens bedömning: </strong> vilka kritiska eller oväntade moment finns,
                                operationens längd, förväntad blodförlust?
                            </label>
                        </template>
                    </FormulateInput>
                </div>

                <hr />

                <FormulateInput
                    :disabled="readonlyWhoTimeout"
                    name="antibiotikaprofylax"
                    label="Har antibiotikaprofylax givits inom de senaste 60 minuterna?"
                    :options="{
                        true: 'Ja',
                        false: 'Ej tillämplig',
                    }"
                    type="radio"
                />

                <hr />

                <FormulateInput
                    :disabled="readonlyWhoTimeout"
                    name="trombosprofylax"
                    label="Har trombosprofylax givits enligt ordination?"
                    :options="{
                        true: 'Ja',
                        false: 'Ej tillämplig',
                    }"
                    type="radio"
                />

                <hr />

                <FormulateInput
                    :disabled="readonlyWhoTimeout"
                    name="bildinformation"
                    label="Visas nödvändig bildinformation?"
                    :options="{
                        true: 'Ja',
                        false: 'Ej tillämplig',
                    }"
                    type="radio"
                />
                <hr />

                <i>
                    Published by the World Health Organization in 2008 under the title WHO surgical safety checklist 1st
                    edition 2008 (TR/08/216). Producerad av Landstingens Ömsesidiga Försäkringsbolag mars 2009.
                </i>

                <ButtonGroup>
                    <Button :disabled="readonlyWhoTimeout" type="submit" size="primary">Spara</Button>
                    <Button
                        :disabled="readonlyWhoTimeout"
                        type="button"
                        size="secondary"
                        variant="contour"
                        @click.prevent="showWHOchecklistaTimeOut = false"
                    >
                        Stäng
                    </Button>
                </ButtonGroup>
            </FormulateForm>
        </ModalContent>

        <ModalContent
            title="Checklista 3 - Avslutning"
            :showCloseButton="true"
            v-if="showWHOchecklistaAvslutning"
            @close="showWHOchecklistaAvslutning = false"
            class="who-modal"
        >
            <div class="header">
                <DialogAktivPatient :patient="operationsanmalan.patient_display" />

                <div class="datum">
                    <strong>Datum</strong>
                    {{ getCurrentDate }}
                </div>

                <div class="tid">
                    <strong>Tid</strong>
                    {{ getCurrentHourMinute }}
                </div>
            </div>

            <h3>Innan patienten lämnar operationssalen</h3>

            <hr />

            <FormulateForm v-model="whochecklistaAvslutningFormData" @submit="submitWHOchecklistaAvslutning">
                <div class="checkbox-group">
                    <p>Checklisteansvarig får muntlig bekräftelse av laget:</p>

                    <FormulateInput
                        :disabled="readonlyWhoAvslutning"
                        name="vilket_ingrepp"
                        type="checkbox"
                        label="Vilket ingrepp har utförts"
                    />

                    <FormulateInput
                        :disabled="readonlyWhoAvslutning"
                        name="antal_instrument_torkar_nalar"
                        type="checkbox"
                        label="Att antal instrument, torkar och nålar stämmer (eller ej tillämpligt)"
                    />

                    <FormulateInput
                        :disabled="readonlyWhoAvslutning"
                        name="preparat_markta"
                        type="checkbox"
                        label="Hur preparat är märkta (inklusive patientens namn och personnummer)"
                    />

                    <FormulateInput
                        :disabled="readonlyWhoAvslutning"
                        name="problem_utrustning_uppmarksammas"
                        type="checkbox"
                        label="Finns problem med utrustningen som behöver uppmärksammas?"
                    />
                </div>

                <hr />

                <FormulateInput
                    :disabled="readonlyWhoAvslutning"
                    name="huvudpunkter_postoperativt_omhandertagande"
                    type="checkbox"
                >
                    <template #label="{ id }">
                        <div class="checkbox-texts-wrapper">
                            <label :for="id">
                                Operatör, anestesiläkare/sjuksköterska och operationssjuksköterska går igenom
                                huvudpunkterna för det initiala postoperativa omhändertagandet
                            </label>
                        </div>
                    </template>
                </FormulateInput>

                <hr />

                <FormulateInput
                    :disabled="readonlyWhoAvslutning"
                    name="vad_kan_vi_lara"
                    type="checkbox"
                    label="Vad kan vi lära? Vad kan vi göra bättre nästa gång?"
                />
                <hr />

                <i
                    >Published by the World Health Organization in 2008 under the title WHO surgical safety checklist
                    1st edition 2008 (TR/08/216). Producerad av Landstingens Ömsesidiga Försäkringsbolag mars 2009.
                </i>
                <ButtonGroup>
                    <Button :disabled="readonlyWhoAvslutning" type="submit" size="primary">Spara</Button>
                    <Button
                        :disabled="readonlyWhoAvslutning"
                        type="button"
                        size="secondary"
                        variant="contour"
                        @click.prevent="showWHOchecklistaAvslutning = false"
                    >
                        Stäng
                    </Button>
                </ButtonGroup>
            </FormulateForm>
        </ModalContent>

        <collapsible-section number="3" heading="WHO-checklista">
            <div class="who-checklista">
                <p>Checklista för säkerhet vid operationer</p>

                <ButtonLink type="button" @click.prevent="openWhoForberedelse">
                    Checklista 1 - Förberedelse
                    <small>
                        <span>Före inledning av anestesi</span>
                        <span class="updated" v-if="!isEmpty(whochecklistaForberedelseFormData.updated_by)">
                            Sparad {{ moment(whochecklistaForberedelseFormData.updated_at).format("YYYY-MM-DD") }}
                        </span>
                    </small>
                </ButtonLink>

                <ButtonLink type="button" @click.prevent="openWhoTimeout">
                    Checklista 2 - Timeout
                    <small>
                        <span>Före incision</span>
                        <span class="updated" v-if="!isEmpty(whochecklistaTimeoutFormData.updated_by)">
                            Sparad {{ moment(whochecklistaTimeoutFormData.updated_at).format("YYYY-MM-DD") }}
                        </span>
                    </small>
                </ButtonLink>

                <ButtonLink type="button" @click.prevent="openWhoAvslutning">
                    Checklista 3 - Avslutning
                    <small>
                        <span>Innan patienten lämnar operationssalen</span>
                        <span class="updated" v-if="!isEmpty(whochecklistaAvslutningFormData.updated_by)">
                            Sparad {{ moment(whochecklistaAvslutningFormData.updated_at).format("YYYY-MM-DD") }}
                        </span>
                    </small>
                </ButtonLink>
            </div>
        </collapsible-section>

        <collapsible-section number="4" heading="Personal" class="personal">
            <FormulateForm
                :readonly="readonlyPersonal"
                @submit="submitPersonal"
                v-model="personalFormData"
                :errors="formError"
            >
                <div class="wrapper-3-1">
                    <FormulateInput
                        :readonly="readonlyPersonal"
                        label="Huvudoperatör *"
                        name="huvudoperator"
                        type="vueselect"
                        :options="operatorer"
                        @search="(search, loading) => searchOperatorer(search, loading)"
                        @input="removeError('huvudoperator')"
                    />
                    <FormulateInput
                        :readonly="readonlyPersonal"
                        label="Tid in"
                        type="timepicker"
                        useNow
                        name="huvudoperator_intid"
                    />

                    <FormulateInput
                        :readonly="readonlyPersonal"
                        label="Tid ut"
                        type="timepicker"
                        useNow
                        name="huvudoperator_uttid"
                    />
                </div>

                <div class="wrapper-3-1">
                    <FormulateInput
                        :readonly="readonlyPersonal"
                        name="anestesilakare"
                        label="Anestesiläkare *"
                        type="vueselect"
                        :options="anestesilakare"
                        @search="searchAnestesilakare"
                        @input="removeError('anestesilakare')"
                    />

                    <FormulateInput
                        :readonly="readonlyPersonal"
                        label="Tid in"
                        type="timepicker"
                        useNow
                        name="anestesilakare_intid"
                    />

                    <FormulateInput
                        :readonly="readonlyPersonal"
                        label="Tid ut"
                        type="timepicker"
                        useNow
                        name="anestesilakare_uttid"
                    />
                </div>

                <FormulateInput
                    :readonly="readonlyPersonal"
                    type="group"
                    name="anestesisjukskoterskor"
                    :repeatable="true"
                    add-label="Lägg till fler anestesisjuksköterskor"
                    remove-label="Radera fält"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            :readonly="readonlyPersonal"
                            name="profile"
                            label="Anestesisjuksköterska"
                            type="vueselect"
                            :options="anestesisjukskoterskor"
                            @search="(search, loading) => searchAnestesisjukskoterska(index, search, loading)"
                        />

                        <FormulateInput
                            :readonly="readonlyPersonal"
                            label="Tid in"
                            type="timepicker"
                            useNow
                            name="intid"
                        />

                        <FormulateInput
                            :readonly="readonlyPersonal"
                            label="Tid ut"
                            type="timepicker"
                            useNow
                            name="uttid"
                        />
                    </template>
                </FormulateInput>
                <FormulateInput
                    :readonly="readonlyPersonal"
                    type="group"
                    name="ass_operatorer"
                    :repeatable="true"
                    add-label="Lägg till fler opassistenter"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            :readonly="readonlyPersonal"
                            label="Operationsassistent"
                            type="vueselect"
                            name="profile"
                            :options="ass_operatorer"
                            @input="personalChanged(index, 'ass_operatorer')"
                        />
                        <FormulateInput
                            :readonly="readonlyPersonal"
                            label="Tid in"
                            type="timepicker"
                            useNow
                            name="intid"
                        />
                        <FormulateInput
                            :readonly="readonlyPersonal"
                            label="Tid ut"
                            type="timepicker"
                            useNow
                            name="uttid"
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeOpassistenter(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button type="button" data-minor="" data-ghost="" @click="addMoreAssOperatorer(addMore)">
                                <span class="formulate-input-element--button--label">Lägg till fler opassistenter</span>
                            </button>
                        </div>
                    </template>
                </FormulateInput>
                <FormulateInput
                    :readonly="readonlyPersonal"
                    type="group"
                    name="operationssjukskoterskor"
                    :repeatable="true"
                    add-label="Lägg till fler opsjuksköterskor"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            :readonly="readonlyPersonal"
                            type="vueselect"
                            :label="index === 0 ? 'Operationssjuksköterska *' : 'Operationssjuksköterska'"
                            :options="operationssjukskoterskor"
                            name="profile"
                            @input="removeError('operationssjukskoterskor')"
                        />
                        <FormulateInput
                            :readonly="readonlyPersonal"
                            label="Tid in"
                            type="timepicker"
                            useNow
                            name="intid"
                        />
                        <FormulateInput
                            :readonly="readonlyPersonal"
                            label="Tid ut"
                            type="timepicker"
                            useNow
                            name="uttid"
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeOperationssjukskoterska(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button
                                type="button"
                                data-minor=""
                                data-ghost=""
                                @click="addMoreOperationssjukskoterska(addMore)"
                            >
                                <span class="formulate-input-element--button--label">
                                    Lägg till fler opsjuksköterskor
                                </span>
                            </button>
                        </div>
                    </template>
                </FormulateInput>
                <FormulateInput
                    :readonly="readonlyPersonal"
                    type="group"
                    name="operationsunderskoterskor"
                    :repeatable="true"
                    add-label="Lägg till fler undersköterskor"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            :readonly="readonlyPersonal"
                            name="profile"
                            label="Undersköterska"
                            type="vueselect"
                            @input="personalChanged(index, 'operationsunderskoterskor')"
                            :options="operationsunderskoterskor"
                        />
                        <FormulateInput
                            :readonly="readonlyPersonal"
                            label="Tid in"
                            type="timepicker"
                            useNow
                            name="intid"
                        />
                        <FormulateInput
                            :readonly="readonlyPersonal"
                            label="Tid ut"
                            type="timepicker"
                            useNow
                            name="uttid"
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeOperationsunderskoterska(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button
                                type="button"
                                data-minor=""
                                data-ghost=""
                                @click="addMoreOperationsunderskoterska(addMore)"
                            >
                                <span class="formulate-input-element--button--label"
                                    >Lägg till fler undersköterskor</span
                                >
                            </button>
                        </div>
                    </template>
                </FormulateInput>
                <FormulateInput
                    :readonly="readonlyPersonal"
                    type="textarea"
                    name="fritext"
                    label="Kommentar personal"
                    class="fritext"
                ></FormulateInput>
            </FormulateForm>
            <div class="grey-div">
                <Signeringansvarig
                    @emitSigneringsansvarigaAction="submitPersonal"
                    :readonly="readonlyPersonal"
                    :isSignable="true"
                    :currentProfile="currentProfile"
                    :isSigned="personalFormData.is_signed"
                    :loading="isSaved"
                    :operationsjournalid="operationjournal_id"
                    :orgunit="operationsanmalan.huvudoperator_display?.orgUnit"
                    :accountableHealthcareProfessional="personalFormData.accountableHealthcareProfessional"
                    :editing="editing"
                ></Signeringansvarig>

                <sign-frame
                    v-if="signVisiblePersonal"
                    title="Personal"
                    :url="signUrlPersonal"
                    :data="signDataPersonal"
                    @success="handleSignPersonalSuccess"
                    @error="handleSignPersonalError"
                    @cancel="handleSignPersonalCancel"
                />
            </div>
        </collapsible-section>

        <collapsible-section number="5" heading="Anestesi" class="anestesi">
            <FormulateForm
                :readonly="readonlyAnestesi"
                @submit="submitAnestesi"
                v-model="anestesiFormData"
                :errors="formError"
            >
                <div class="huvudanestesikod">
                    <FormulateInput
                        :readonly="readonlyAnestesi"
                        id="huvudanestesikod"
                        name="huvudanestesikod"
                        label="Huvudanestesikod *"
                        type="vueselect"
                        :options="huvudanestesikoder"
                        @search="searchHuvudanestesikod"
                        v-on:input="huvudanestesikodChanged()"
                        class="autocomplete"
                    />
                </div>
                <FormulateInput
                    type="group"
                    name="bianestesikoder"
                    :repeatable="true"
                    add-label="Lägg till bianestesikod"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            :readonly="readonlyAnestesi"
                            name="kod"
                            label="Bianestesikod"
                            type="vueselect"
                            :options="bianestesikoder[index]"
                            @search="(search, loading) => searchBianestesikoder(index, search, loading)"
                            class="autocomplete"
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeBianestesikoder(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button type="button" data-minor="" data-ghost="" @click="addMoreBianestesikoder(addMore)">
                                <span class="formulate-input-element--button--label"
                                    >Lägg till fler bianestesikoder</span
                                >
                            </button>
                        </div>
                    </template>
                </FormulateInput>

                <ModalContent
                    title="Komplikationsgrad förklaring"
                    show-close-button="show-close-button"
                    v-if="forklaring"
                    @close="forklaring = false"
                    class="komplikationsgrad-forklaring"
                >
                    <dl class="forklaringar">
                        <dt>Grad 0</dt>
                        <dd>Anges enbart vid Normalt förlopp</dd>

                        <dt>Grad 1</dt>
                        <dd>Saknar betydelse för post op omhändertagande</dd>

                        <dt>Grad 2</dt>
                        <dd>Påverkar omhändertagandet post op på UVA men saknar betydelse för fortsatt vård</dd>

                        <dt>Grad 3</dt>
                        <dd>
                            Påverkar omhändertagandet post op på vårdavdelning med förlängt omhändertagande och/eller
                            fortsatt speciell observation
                        </dd>

                        <dt>Grad 4</dt>
                        <dd>Påverkar omhändertagandet så att intensivvård krävs postoperativt</dd>

                        <dt>Grad 5</dt>
                        <dd>Medför sannolikt bestående skada eller död</dd>
                    </dl>

                    <i> Senast uppdaterad 2022-10-26 (alt. SPOR version XYX) </i>

                    <ButtonGroup>
                        <Button type="button" size="primary" @click.prevent="forklaring = false">Stäng</Button>
                    </ButtonGroup>
                </ModalContent>
                <FormulateInput
                    type="group"
                    name="anestesikomplikationer"
                    :repeatable="true"
                    add-label="Lägg till fler komplikationer"
                    remove-position="after"
                    class="anestesikomplikationer"
                >
                    <template #default="{ index }">
                        <FormulateInput
                            :readonly="readonlyAnestesi"
                            id="anestesikomplikationer-kategori"
                            name="kategori"
                            label="Anestesikomplikation kategori *"
                            type="vueselect"
                            :options="anestesikomplikationerKategorier"
                            @option:selected="anestesikomplikationerKategorierChanged(index)"
                            @input="anestesikomplikationChanged(index, $event)"
                        />
                        <FormulateInput
                            :readonly="readonlyAnestesi"
                            id="anestesikomplikationer-kod"
                            label="Anestesikomplikation *"
                            name="kod"
                            type="vueselect"
                            :options="anestesikomplikationer[index]"
                            @input="anestesikomplikationerErrorChanged('kod', index)"
                            validation="required"
                            :validation-messages="{
                                required: 'Det här fältet är obligatoriskt.',
                            }"
                        >
                        </FormulateInput>

                        <FormulateInput
                            :readonly="readonlyAnestesi"
                            id="anestesikomplikationer-svarighetsgrad"
                            name="svarighetsgrad"
                            label="Svårighetsgrad *"
                            type="vueselect"
                            :options="anestesikomplikationSvarighetsgrader"
                            @input="anestesikomplikationerErrorChanged('svarighetsgrad', index)"
                            validation="required"
                            :validation-messages="{
                                required: 'Det här fältet är obligatoriskt.',
                            }"
                        >
                            <ButtonLink type="button" class="forklaring" @click.prevent="forklaring = true">
                                Förklaring
                            </ButtonLink>
                        </FormulateInput>

                        <FormulateInput
                            :readonly="readonlyAnestesi"
                            id="anestesikomplikationer-tidpunkt"
                            name="tidpunkt"
                            label="Tidpunkt för komplikation"
                            type="timepicker"
                            useNow
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeAnestesikomplikationer(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>
                </FormulateInput>
            </FormulateForm>
            <div class="grey-div anestesi-signering">
                <Signeringansvarig
                    :readonly="readonlyAnestesi"
                    class=""
                    type="submit"
                    @emitSigneringsansvarigaAction="submitAnestesi"
                    :isSignable="true"
                    :currentProfile="currentProfile"
                    :isSigned="anestesiFormData.is_signed"
                    :loading="isSaved"
                    :operationsjournalid="operationjournal_id"
                    :orgunit="operationsanmalan.huvudoperator_display?.orgUnit"
                    :accountableHealthcareProfessional="anestesiFormData.accountableHealthcareProfessional"
                    :editing="editing"
                ></Signeringansvarig>

                <sign-frame
                    v-if="signVisibleAnestesi"
                    title="Anestesi"
                    :url="signUrlAnestesi"
                    :data="signDataAnestesi"
                    @success="handleSignAnestesiSuccess"
                    @error="handleSignAnestesiError"
                    @cancel="handleSignAnestesiCancel"
                />
            </div>
        </collapsible-section>

        <collapsible-section number="6" heading="Material" class="material">
            <FormulateForm
                :readonly="readonlyMaterial"
                @submit="submitMaterial"
                v-model="materialFormData"
                :errors="formError"
            >
                <FormulateInput
                    type="group"
                    label=""
                    name="material"
                    :repeatable="true"
                    add-label="Lägg till fler material"
                    remove-position="after"
                >
                    <template #default="{ index }">
                        <!-- <FormulateInput
                            :help="
                                setWarningMessage({
                                    groupIndex: index,
                                    formGroup: materialFormData.material,
                                    formGroupFieldName: 'artikelnummer',
                                    customWarningMessage:
                                        'Artikeln används redan. Justera antal i tidigare fält eller ange ny batch här.',
                                })
                            "
                            class="first"
                            label="Artikelnummer"
                            name="artikelnummer"
                            type="vueselect"
                            :options="material[index]"
                            @search="(search, loading) => searchMaterial(index, search, loading)"
                            @option:selected="(selectedOption) => materialSelected(selectedOption, index)"
                            ref="artikelnummer"
                        /> -->

                        <FormulateInput
                            :readonly="readonlyMaterial"
                            label="Artikelnummer"
                            name="artikelnummer"
                            type="text"
                            ref="artikelnummer"
                            @keydown.enter.prevent="($event) => materialOnEnter($event.target?.value ?? '', index)"
                            :error="errorMessages[index]"
                            @blur="($event) => artikelNummerOnLeave($event.target?.value ?? '', index)"
                        />

                        <FormulateInput
                            :readonly="readonlyMaterial"
                            class="second"
                            label="Materialnamn"
                            name="materialnamn"
                            type="text"
                        />
                        <FormulateInput
                            :readonly="readonlyMaterial"
                            class="third"
                            label="Batchnummer"
                            name="batchnummer"
                            type="text"
                        />
                        <FormulateInput
                            :readonly="readonlyMaterial"
                            class="fourth"
                            label="Antal"
                            name="antal"
                            type="knumber"
                            ref="antal"
                            v-on:keydown.enter.prevent="(e) => antalOnEnter(e)"
                        />
                    </template>
                    <template #remove="{ index, removeItem }">
                        <a
                            role="button"
                            class="formulate-input-group-repeatable-remove"
                            @click="removeMaterial(index, removeItem)"
                        >
                            Radera fält
                        </a>
                    </template>

                    <template #addMore="{ addMore }">
                        <div class="formulate-input-group-add-more">
                            <button type="button" data-minor="" data-ghost="" @click="addMoreMaterial(addMore)">
                                <span class="formulate-input-element--button--label">Lägg till fler material</span>
                            </button>
                        </div>
                    </template>
                </FormulateInput>
                <FormulateInput
                    :readonly="readonlyMaterial"
                    type="textarea"
                    name="fritext"
                    label="Kommentar material"
                    class="fritext"
                ></FormulateInput>
            </FormulateForm>
            <div class="grey-div">
                <Signeringansvarig
                    :readonly="readonlyMaterial"
                    type="submit"
                    @emitSigneringsansvarigaAction="submitMaterial"
                    :isSignable="true"
                    :currentProfile="currentProfile"
                    :isSigned="materialFormData.is_signed"
                    :loading="isSaved"
                    :operationsjournalid="operationjournal_id"
                    :orgunit="operationsanmalan.huvudoperator_display?.orgUnit"
                    :accountableHealthcareProfessional="materialFormData.accountableHealthcareProfessional"
                    :editing="editing"
                ></Signeringansvarig>

                <sign-frame
                    v-if="signVisibleMaterial"
                    title="Material"
                    :url="signUrlMaterial"
                    :data="signDataMaterial"
                    @success="handleSignMaterialSuccess"
                    @cancel="handleSignMaterialCancel"
                />
            </div>
        </collapsible-section>

        <collapsible-section number="7" heading="Patientdata" class="patientdata">
            <FormulateForm @submit="submitPatientdata" v-model="patientdataFormData" :errors="formError">
                <FormulateInput
                    type="group"
                    name="patientdata"
                    class="patientdata-group"
                    #default="{ index: groupIndex }"
                >
                    <FormulateInput type="group" name="kontroll" class="kontroll patientdata-groups">
                        <template #label="{ id }">
                            <h4 :for="id">Kontroll</h4>
                        </template>
                        <FormulateInput
                            :readonly="readonlyPatientData"
                            name="id_kontroll"
                            label="ID-kontroll"
                            type="vueselect"
                            :options="id_kontroll"
                        />

                        <FormulateInput
                            :readonly="readonlyPatientData"
                            name="opmarkering"
                            label="Opmarkering"
                            type="text"
                        />
                        <FormulateInput
                            :readonly="readonlyPatientData"
                            name="orenop"
                            label="Oren op"
                            type="vueselect"
                            :options="[
                                { value: 'ja', label: 'Ja' },
                                { value: 'nej', label: 'Nej' },
                            ]"
                        />

                        <FormulateInput
                            :readonly="readonlyPatientData"
                            name="blodsmitta"
                            label="Blodsmitta"
                            type="vueselect"
                            :options="[
                                { value: 'ja', label: 'Ja' },
                                { value: 'nej', label: 'Nej' },
                            ]"
                        />
                    </FormulateInput>
                    <FormulateInput type="group" name="hud" class="hud patientdata-groups">
                        <template #label="{ id }">
                            <h4 :for="id">Hud</h4>
                        </template>

                        <template #default="{ index: hudIndex }">
                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="hudstatus"
                                label="Hudstatus"
                                type="vueselect"
                                :options="[
                                    { value: 'ua', label: 'Ua' },
                                    { value: 'anmarkning', label: 'Anmärkning' },
                                ]"
                                @input="hudstatusChanged(hudIndex)"
                            />
                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="anmarkninghud"
                                label="Anmärkning hud"
                                type="text"
                                :disabled="showPatientAnmarkning"
                            />
                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="haravkortning"
                                label="Håravkortning"
                                type="vueselect"
                                :options="[
                                    { value: 'ja', label: 'Ja' },
                                    { value: 'nej', label: 'Nej' },
                                ]"
                            />

                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="optvatt"
                                label="Optvätt"
                                type="vueselect"
                                :options="[
                                    { value: 'descutan', label: 'Descutan' },
                                    { value: 'klorhexidin', label: 'Klorhexidin' },
                                    { value: 'annat', label: 'Annat' },
                                ]"
                            />

                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="diatermi"
                                label="Diatermi"
                                type="text"
                            />

                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="diatermi_placering"
                                label="Diatermi placering"
                                type="vueselect"
                                :options="[
                                    { value: 'ben', label: 'Ben' },
                                    { value: 'rygg', label: 'Rygg' },
                                    { value: 'mage', label: 'Mage' },
                                    { value: 'annat', label: 'Annat' },
                                ]"
                            />

                            <FormulateInput
                                :readonly="readonlyPatientData"
                                type="text"
                                name="diatermi_installningar"
                                label="Diatermi inställningar"
                            />

                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="blodtomt_tryck_tid"
                                label="Blodtomt tryck tid (minuter)"
                                type="knumber"
                            />
                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="hudsutur"
                                label="Hudsutur"
                                type="vueselect"
                                :options="[
                                    { value: 'resorberbar', label: 'Resorberbar' },
                                    { value: 'ejresorberbar', label: 'Ej resorberbar' },
                                    { value: 'stapler', label: 'Stapler' },
                                    { value: 'annan', label: 'Annan' },
                                ]"
                            />

                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="forband"
                                label="Förband"
                                type="vueselect"
                                :options="[
                                    { value: 'tegaderm', label: 'Tegaderm' },
                                    { value: 'tejp', label: 'Tejp' },
                                    { value: 'annat', label: 'Annat' },
                                ]"
                            />
                        </template>
                    </FormulateInput>
                    <FormulateInput type="group" name="atgard" class="atgard patientdata-groups">
                        <template #label="{ id }">
                            <h4 :for="id">Åtgärd</h4>
                        </template>

                        <template #default="{ index: atgardIndex }">
                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="skoljvatska"
                                label="Sköljvätska"
                                type="vueselect"
                                :options="[
                                    { value: 'natriumklorid9mg/ml', label: 'Natriumklorid 9mg/ml' },
                                    { value: 'annat', label: 'Annat' },
                                ]"
                            />

                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="antalpad"
                                label="Antal PAD"
                                type="knumber"
                            />
                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="odling_tagen"
                                label="Odling tagen"
                                type="vueselect"
                                :options="[
                                    { value: 'ja', label: 'Ja' },
                                    { value: 'nej', label: 'Nej' },
                                ]"
                            />

                            <div class="flexbreak"></div>
                            <FormulateInput
                                :readonly="readonlyPatientData"
                                name="dranage"
                                label="Dränage"
                                type="vueselect"
                                :options="[
                                    { value: 'ja', label: 'Ja' },
                                    { value: 'nej', label: 'Nej' },
                                ]"
                                @input="atgardChanged(atgardIndex)"
                            />
                            <FormulateInput
                                :readonly="readonlyPatientData"
                                :disabled="showPatientAtgard"
                                name="klippt"
                                label="Klippt?"
                                type="vueselect"
                                :options="[
                                    { value: 'ja', label: 'Ja' },
                                    { value: 'nej', label: 'Nej' },
                                ]"
                            />
                        </template>
                    </FormulateInput>
                </FormulateInput>
            </FormulateForm>
            <div class="grey-div">
                <Signeringansvarig
                    :readonly="readonlyPatientData"
                    type="submit"
                    @emitSigneringsansvarigaAction="submitPatientdata"
                    :isSignable="true"
                    :currentProfile="currentProfile"
                    :isSigned="patientdataFormData.is_signed"
                    :loading="isSaved"
                    :operationsjournalid="operationjournal_id"
                    :orgunit="operationsanmalan.huvudoperator_display?.orgUnit"
                    :accountableHealthcareProfessional="patientdataFormData.accountableHealthcareProfessional"
                    :editing="editing"
                ></Signeringansvarig>
                <sign-frame
                    v-if="signVisiblePatientdata"
                    title="patientdata"
                    :url="signUrlPatientdata"
                    :data="signDataPatientdata"
                    @success="handleSignPatientdataSuccess"
                    @cancel="handleSignPatientdataCancel"
                />
            </div>
        </collapsible-section>
    </div>
</template>

<script>
    import DialogAktivPatient from "@/tabs/Operation/DialogAktivPatient.vue";
    import moment from "moment";
    import BaseTab from "@/tabs/mixins/BaseTab.js";
    import CollapsibleSection from "@/components/generic/CollapsibleSection.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import ButtonLink from "@/components/ButtonLink.vue";
    import { mapActions, mapGetters } from "vuex";
    import emitter from "tiny-emitter/instance";

    import Signeringansvarig from "@/tabs/Operation/Signeringsansvarig.vue";
    import tabIcon from "@/assets/bed_icon.svg";
    import Button from "@/components/Button.vue";
    import { cloneDeep, isEmpty } from "lodash";
    import { flashMessage, flashWarning, openDialog } from "@/utils";
    import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
    import { getConfig, getErrorMessage, klinikenApi } from "@/api";
    import ModalContent from "@/components/generic/ModalContent.vue";
    import SignFrame from "@/components/Auth/SignFrame.vue";
    import PermissionDeniedAlert from "@/components/PermissionDeniedAlert.vue";

    import {
        anestesikomplikationerKategorierOptions,
        anestesikomplikationerOptions,
        arrayPropertyExistsNotEmpty,
        avtalOptions,
        changeTimeToDate,
        cvOptions,
        cvOptionsShort,
        extractHourMinuteFromDateTime,
        fetchData,
        getPropertyValue,
        getTimeFromDatetime,
        hasDuplicatedObjectsInArray,
        isEmptyObject,
        isNotEmptyArray,
        isNotEmptyObject,
        isNull,
        materialOptions,
        parseError,
        profileOptions,
        propertyExists,
        propertyExistsNotNull,
        removeEmptyObjectsFromArray,
        salarOptions,
        SetField,
        typoperationerOptions,
    } from "./utils";

    export default {
        extends: BaseTab,
        name: "Operationsjournal",
        tabName: "Operationsjournal",
        icon: tabIcon,
        components: {
            ModalContent,
            ModalBasic,
            CollapsibleSection,
            Button,
            ButtonGroup,
            ButtonLink,
            DialogAktivPatient,
            Signeringansvarig,
            SignFrame,
            PermissionDeniedAlert,
        },
        props: {
            parameters: {
                type: Object,
                required: false,
            },

            uid: Number,
            tabId: Number,
            editing: Boolean,
        },

        data() {
            return {
                isEditing: false,
                formError: {},
                validation: {},
                operationsuppgifterFormData: {
                    typoperation: null,
                    huvuddiagnos: null,
                    bidiagnoser: [{}],
                    bioperationskoder: [{}],
                    operationsdatum: null,
                    sal: "",
                    reop: false,
                    garantiop: false,
                    lateralitet: "",
                    avtal: null,
                    anmarkning: null,
                    is_signed: false,
                    accountableHealthcareProfessional: "",
                },
                patientdataFormData: {
                    patientdata: [],
                    is_signed: false,
                    accountableHealthcareProfessional: "",
                },
                personalFormData: {
                    huvudoperator: {},
                    ass_operatorer: [{}],
                    operationssjukskoterskor: [{}],
                    operationsunderskoterskor: [{}],
                    is_signed: false,
                    accountableHealthcareProfessional: "",
                    anestesisjukskoterska_intid: null,
                    anestesisjukskoterska_uttid: null,
                    anestesisjukskoterskor: [{}],
                    fritext: "",
                },
                whochecklistaForberedelseFormData: {
                    fungerande_pulsoximeter: null,
                    kand_allergi: null,
                    operationsomrade_markerat: null,
                    patient_bekraftat: null,
                    risk_for_aspiration: null,
                    risk_for_blodforlust: null,
                    risk_for_hypotermi: null,
                    sakerhetskontroll_for_anestesi: null,
                    updated_at: null,
                    updated_by: null,
                },
                whochecklistaTimeoutFormData: {
                    antibiotikaprofylax: null,
                    bedomning_korrekt_upplagd: null,
                    bedomning_kritiska_moment: null,
                    bedomning_patientspecifika_problem: null,
                    bedomning_problem_utrustning: null,
                    bedomning_sterilitet_bekraftat: null,
                    bekraftar_muntligt: null,
                    bildinformation: null,
                    medlemmar_presenterat_sig: null,
                    trombosprofylax: null,
                    updated_at: null,
                    updated_by: null,
                },
                whochecklistaAvslutningFormData: {
                    antal_instrument_torkar_nalar: null,
                    huvudpunkter_postoperativt_omhandertagande: null,
                    preparat_markta: null,
                    problem_utrustning_uppmarksammas: null,
                    vad_kan_vi_lara: null,
                    vilket_ingrepp: null,
                    updated_at: null,
                    updated_by: null,
                },
                materialFormData: {
                    material: [{}],
                    is_signed: false,
                    accountableHealthcareProfessional: "",
                    fritext: "",
                },
                anestesiFormData: {
                    anestesikomplikationer: [{}],
                    huvudanestesikod: null,
                    bianestesikoder: [{}],
                    is_signed: false,
                    accountableHealthcareProfessional: "",
                },
                tiderFormData: {
                    uppdukning_start: null,
                    uppdukning_slut: null,
                    antibiotikaprofylax_start: null,
                    ankomsttid_preopenhet: null,
                    preoperativ_anestesi_start: null,
                    patienttid_start: null,
                    patient_in_opsal: null,
                    anestesitid_start: null,
                    anestesitid_klar: null,
                    operationsforberedelse_klar: null,
                    opstart: null,
                    x_minuter_kvar: null,
                    opslut: null,
                    anestesi_slut: null,
                    patient_ut_opsal: null,
                    patienttid_slut: null,
                    fritext: "",
                },
                operationsuppgifterSavedFormData: {},
                patientdataSavedFormData: {
                    patientdata: [],
                },
                personalSavedFormData: {},
                materialSavedFormData: {},
                anestesiSavedFormData: {},
                tiderSavedFormData: {},

                typoperationer: [],
                huvuddiagnoser: [],
                bidiagnoser: [],
                huvudoperationskoder: [],
                bioperationskoder: [],
                avtal: [],
                salar: [],
                lateraliteter: [
                    { value: "", label: "" },
                    { value: "HÖGER_SIDA", label: "Höger sida" },
                    { value: "VÄNSTER_SIDA", label: "Vänster sida" },
                    { value: "BILATERALT", label: "Bilateralt" },
                    { value: "EJ_AKTUELLT", label: "Ej aktuellt" },
                ],
                id_kontroll: [],
                operatorer: [],
                ass_operatorer: [],
                operationssjukskoterskor: [],
                operationsunderskoterskor: [],
                material: [],
                anestesilakare: [],
                anestesisjukskoterskor: [],
                huvudanestesikoder: [],
                bianestesikoder: [],
                rawAnestesikomplikationer: [],
                anestesikomplikationerKategorier: [],
                anestesikomplikationer: [],
                anestesikomplikationSvarighetsgrader: [],

                showWHOchecklistaForberedelse: false,
                showWHOchecklistaTimeOut: false,
                showWHOchecklistaAvslutning: false,

                forklaring: false,

                operationsanmalan: {},
                loading: false,
                isSaved: false,
                operationjournal_id: "",
                errorMessages: [],
                showPatientAnmarkning: true,
                showPatientAtgard: true,

                signVisibleOperationsuppgifter: false,
                signUrlOperationsuppgifter: "",
                signDataOperationsuppgifter: "",

                signVisiblePersonal: false,
                signUrlPersonal: "",
                signDataPersonal: "",

                signVisibleAnestesi: false,
                signUrlAnestesi: "",
                signDataAnestesi: "",

                signVisibleMaterial: false,
                signUrlMaterial: "",
                signDataMaterial: "",

                signVisiblePatientdata: false,
                signUrlPatientdata: "",
                signDataPatientdata: "",
                readonlyOperationsUppgifter: false,
                readonlyTider: false,
                readonlyWho: false,
                readonlyWhoForberwdelse: false,
                readonlyWhoTimeout: false,
                readonlyWhoAvslutning: false,
                readonlyPersonal: false,
                readonlyAnestesi: false,
                readonlyMaterial: false,
                readonlyPatientData: false,
            };
        },

        computed: {
            ...mapGetters("systemData", ["operationXMinuterKvar"]),
            ...mapGetters("userData", ["currentProfile"]),
            ...mapGetters("ability", ["can"]),
            isAnyReadonly() {
                return (
                    this.readonlyOperationsUppgifter ||
                    this.readonlyWho ||
                    this.readonlyWhoForberwdelse ||
                    this.readonlyWhoTimeout ||
                    this.readonlyWhoAvslutning ||
                    this.readonlyPersonal ||
                    this.readonlyAnestesi ||
                    this.readonlyMaterial ||
                    this.readonlyPatientData
                );
            },
            isEmpty() {
                return isEmpty;
            },
            moment() {
                return moment;
            },
            hasUnsavedData() {
                // FIXME: Av någon anledning matchar inte patientdataFormData och patientdataSavedFormData vid start,
                // så den varnar utan att det finns ändringar. Funkar inte heller att jämföra this.patientdataFormData.patientdata
                // med this.patientdataSavedFormData.patientdata för av någon anledning ändras även patientdataSavedFormData

                // vid ändringar i formuläret. Utkommenterat tills vi hittat en lösning.
                return (
                    this.formDataIsEqual(this.operationsuppgifterFormData, this.operationsuppgifterSavedFormData) ||
                    this.checkifPatientData(
                        this.patientdataSavedFormData.patientdata,
                        this.patientdataFormData.patientdata
                    ) ||
                    this.formDataIsEqual(this.personalFormData, this.personalSavedFormData) ||
                    this.formDataIsEqual(this.materialFormData, this.materialSavedFormData) ||
                    this.formDataIsEqual(this.anestesiFormData, this.anestesiSavedFormData) ||
                    this.formDataIsEqual(this.tiderFormData, this.tiderSavedFormData)
                );
            },
            getCurrentHourMinute() {
                return moment().format("HH:mm");
            },
            getCurrentDate() {
                return moment().format("YYYY-MM-DD");
            },
        },

        methods: {
            ...mapActions("tabs", ["closeTab"]),
            ...mapActions("operation/operationoversikt", ["updateAnmalningarList"]),
            ...mapActions("operation/operationsjournal", ["sign"]),
            checkifPatientData(formData, form_saved_data) {
                if ((formData, form_saved_data)) {
                    return JSON.stringify(formData) !== JSON.stringify(form_saved_data);
                }
            },
            removeEmpty(obj) {
                return Object.fromEntries(
                    Object.entries(obj)
                        // eslint-disable-next-line no-unused-vars
                        .filter(([_, v]) => v != null && v !== "")
                        .map(([k, v]) => [k, v === Object(v) ? this.removeEmpty(v) : v], this)
                );
            },
            formDataIsEqual(formData, savedFormData) {
                if ((formData, savedFormData)) {
                    return (
                        JSON.stringify(this.removeEmpty(formData)) !== JSON.stringify(this.removeEmpty(savedFormData))
                    );
                }
            },

            sectionEmitedErrors(errors) {
                this.formError = errors;
            },

            removeError(name) {
                delete this.formError[name];
                this.formError = parseError(this.formError);
            },

            typoperationChanged() {
                if (this.loading) {
                    return;
                }

                let id = this.operationsuppgifterFormData.typoperation;
                if (isNull(id)) {
                    this.removeError("typoperation");
                    return;
                }

                const typoperation = this.typoperationer.find((item) => `${item.id}` === `${id}`);

                if (typoperation === undefined) {
                    return;
                }

                this.removeError("typoperation");

                let operationsuppgifterFormData = {};

                this.huvudoperationskoder = [
                    {
                        value: typoperation.huvudoperationskod_display.id,
                        label: `${typoperation.huvudoperationskod_display.code} ${typoperation.huvudoperationskod_display.displayName}`,
                    },
                ];
                operationsuppgifterFormData.huvudoperationskod = `${typoperation.huvudoperationskod}`;

                operationsuppgifterFormData.bioperationskoder = [{}];
                typoperation.opkoder.forEach((value, index) => {
                    this.$set(this.bioperationskoder, index, [
                        {
                            value: typoperation.opkoder_display[index].id,
                            label: `${typoperation.opkoder_display[index].code} ${typoperation.opkoder_display[index].displayName}`,
                        },
                    ]);
                    operationsuppgifterFormData.bioperationskoder[index] = { bioperationskod: `${value}` };
                });

                this.huvuddiagnoser = [
                    {
                        value: typoperation.huvuddiagnos_display.id,
                        label: `${typoperation.huvuddiagnos_display.code} ${typoperation.huvuddiagnos_display.displayName}`,
                    },
                ];
                operationsuppgifterFormData.huvuddiagnos = `${typoperation.huvuddiagnos}`;

                operationsuppgifterFormData.bidiagnoser = [{}];
                typoperation.bidiagnoser.forEach((value, index) => {
                    this.$set(this.bidiagnoser, index, [
                        {
                            value: typoperation.bidiagnoser_display[index].id,
                            label: `${typoperation.bidiagnoser_display[index].code} ${typoperation.bidiagnoser_display[index].displayName}`,
                        },
                    ]);
                    operationsuppgifterFormData.bidiagnoser[index] = { bidiagnos: `${value}` };
                });

                this.operationsuppgifterFormData = {
                    ...this.operationsuppgifterFormData,
                    ...operationsuppgifterFormData,
                };
            },

            async clearTypoperation() {
                if (
                    await this.$refs.dialog.show({
                        variant: BasicModalVariant.WARNING,
                        title: "De valda operationsuppgifterna stämmer inte med den valda typoperationen. Typoperationen kommer tas bort. Vill du fortsätta?",
                        okButton: "OK",
                        cancelButton: "Avbryt",
                    })
                ) {
                    this.operationsuppgifterFormData.typoperation = null;
                } else {
                    this.typoperationChanged();
                }
            },

            huvuddiagnosChanged() {
                if (isNull(this.operationsuppgifterFormData.huvuddiagnos)) {
                    fetchData({
                        url: "/core/kodverk/icdkoder/",
                    }).then((response) => {
                        this.huvuddiagnoser = cvOptions(response);
                    });
                } else {
                    this.removeError("huvuddiagnos");
                }

                if (!this.loading) {
                    const typoperation = this.typoperationer.find(
                        (item) => `${item.id}` === `${this.operationsuppgifterFormData.typoperation}`
                    );
                    if (
                        typoperation &&
                        `${this.operationsuppgifterFormData.huvuddiagnos}` !== `${typoperation.huvuddiagnos}`
                    ) {
                        this.clearTypoperation();
                    }
                }
            },

            bidiagnosChanged(index) {
                if (isNull(this.operationsuppgifterFormData.bidiagnoser[index].bidiagnos)) {
                    fetchData({
                        url: "/core/kodverk/icdkoder/",
                    }).then((response) => {
                        this.$set(this.bidiagnoser, index, cvOptions(response));
                    });
                }

                if (!this.loading) {
                    const typoperation = this.typoperationer.find(
                        (item) => `${item.id}` === `${this.operationsuppgifterFormData.typoperation}`
                    );
                    if (
                        typoperation &&
                        `${this.operationsuppgifterFormData.bidiagnoser[index].bidiagnos}` !==
                            `${typoperation.bidiagnoser[index]}`
                    ) {
                        this.clearTypoperation();
                    }
                }
            },

            huvudoperationskodChanged() {
                if (isNull(this.operationsuppgifterFormData.huvudoperationskod)) {
                    fetchData({
                        url: "/operation/kodverk/operationskoder/",
                    }).then((response) => {
                        this.huvudoperationskoder = cvOptions(response);
                    });
                } else {
                    this.removeError("huvudoperationskod");
                }

                if (!this.loading) {
                    const typoperation = this.typoperationer.find(
                        (item) => `${item.id}` === `${this.operationsuppgifterFormData.typoperation}`
                    );
                    if (
                        typoperation &&
                        `${this.operationsuppgifterFormData.huvudoperationskod}` !==
                            `${typoperation.huvudoperationskod}`
                    ) {
                        this.clearTypoperation();
                    }
                }
            },

            bioperationskodChanged(index) {
                if (isNull(this.operationsuppgifterFormData.bioperationskoder[index].bioperationskod)) {
                    fetchData({
                        url: "/operation/kodverk/operationskoder/",
                    }).then((response) => {
                        this.$set(this.bioperationskoder, index, cvOptions(response));
                    });
                }

                if (!this.loading) {
                    const typoperation = this.typoperationer.find(
                        (item) => `${item.id}` === `${this.operationsuppgifterFormData.typoperation}`
                    );
                    if (
                        typoperation &&
                        `${this.operationsuppgifterFormData.bioperationskoder[index].bioperationskod}` !==
                            `${typoperation.opkoder[index]}`
                    ) {
                        this.clearTypoperation();
                    }
                }
            },

            async searchHuvudoperationskod(search, loading) {
                if (search.trim().length >= 3) {
                    loading(true);
                    this.huvudoperationskoder = cvOptions(
                        await fetchData({
                            url: "/operation/kodverk/operationskoder/all/",
                            params: { q: search },
                        })
                    );
                    loading(false);
                }
            },

            async searchBioperationskoder(index, search, loading) {
                if (search.trim().length >= 3) {
                    loading(true);
                    const options = cvOptions(
                        await fetchData({
                            url: "/operation/kodverk/operationskoder/all/",
                            params: { q: search },
                        })
                    );
                    this.$set(this.bioperationskoder, index, options);
                    loading(false);
                }
            },

            removeBioperationskoder(index, removeItem) {
                removeItem(index);
                let bioperationskoder = this.bioperationskoder;
                bioperationskoder.splice(index, 1);
                for (let i = 0; i < this.bioperationskoder.length; i++) {
                    this.$set(this.bioperationskoder, i, bioperationskoder[i]);
                }
            },

            async searchHuvuddiagnos(search, loading) {
                if (search.trim().length >= 3) {
                    loading(true);
                    this.huvuddiagnoser = cvOptions(
                        await fetchData({
                            url: "/core/kodverk/icdkoder/all/",
                            params: { q: search },
                        })
                    );
                    loading(false);
                }
            },

            async searchBidiagnoser(index, search, loading) {
                if (search.trim().length >= 3) {
                    loading(true);
                    const options = cvOptions(
                        await fetchData({
                            url: "/core/kodverk/icdkoder/all/",
                            params: { q: search },
                        })
                    );
                    this.$set(this.bidiagnoser, index, options);
                    loading(false);
                }
            },

            removeBidiagnoser(index, removeItem) {
                removeItem(index);
                let bidiagnoser = this.bidiagnoser;
                bidiagnoser.splice(index, 1);
                for (let i = 0; i < this.bidiagnoser.length; i++) {
                    this.$set(this.bidiagnoser, i, bidiagnoser[i]);
                }
            },

            async addMoreBidiagnoser(addMore) {
                addMore();
                let bidiagnoser = cvOptions(
                    await fetchData({
                        url: "/core/kodverk/icdkoder/",
                    })
                );

                this.$set(this.bidiagnoser, this.bidiagnoser.length, bidiagnoser);
            },

            async addMoreBioperationskoder(addMore) {
                addMore();

                let bioperationskoder = cvOptions(
                    await fetchData({
                        url: "/operation/kodverk/operationskoder/",
                    })
                );

                this.$set(this.bioperationskoder, this.bioperationskoder.length, bioperationskoder);
            },

            hudstatusChanged(index) {
                const hudstatus = this.patientdataFormData.patientdata[index]?.hud[index]?.hudstatus;
                if (hudstatus === null || hudstatus === "ua") {
                    this.patientdataFormData.patientdata[index].hud[index].anmarkninghud = "";
                    this.showPatientAnmarkning = true;
                } else {
                    this.showPatientAnmarkning = false;
                }
            },

            atgardChanged(index) {
                const atgardStatus = this.patientdataFormData.patientdata[index]?.atgard[index]?.dranage;
                if (atgardStatus === null || atgardStatus === "nej") {
                    this.patientdataFormData.patientdata[index].atgard[index].klippt = "";
                    this.showPatientAtgard = true;
                } else {
                    this.showPatientAtgard = false;
                }
            },

            async searchProfile(url, search, loading) {
                loading(true);
                const options = profileOptions(
                    await fetchData({
                        url: url,
                        params: { q: search },
                    })
                );
                loading(false);
                return options;
            },

            personalChanged(index, fieldName) {
                this.removeError(`${fieldName}.${index}.profile`);
                if (this.personalFormData[fieldName][index].profile === null) {
                    this.personalFormData[fieldName][index] = {};
                }
            },

            async addMoreAssOperatorer(addMore) {
                addMore();

                const ass_operatorer = profileOptions(
                    await fetchData({
                        url: "core/profiles/?roll__code=kirurg",
                    })
                );

                this.$set(this.ass_operatorer, this.ass_operatorer.length, ass_operatorer);
            },

            async addMoreOperationssjukskoterska(addMore) {
                addMore();

                const operationssjukskoterskor = profileOptions(
                    await fetchData({
                        url: "core/profiles/?roll__code=operationssjukskoterska",
                    })
                );

                this.$set(
                    this.operationssjukskoterskor,
                    this.operationssjukskoterskor.length,
                    operationssjukskoterskor
                );
            },

            async addMoreOperationsunderskoterska(addMore) {
                addMore();

                const operationsunderskoterskor = profileOptions(
                    await fetchData({
                        url: "core/profiles/?roll__code=operationsunderskoterska",
                    })
                );

                this.$set(
                    this.operationsunderskoterskor,
                    this.operationsunderskoterskor.length,
                    operationsunderskoterskor
                );
            },

            async searchOperatorer(search, loading) {
                if (search.trim().length >= 2) {
                    this.operatorer = await this.searchProfile("core/profiles/?roll__code=kirurg", search, loading);
                }
            },

            removeOpassistenter(index, removeItem) {
                removeItem(index);
            },

            removeOperationssjukskoterska(index, removeItem) {
                removeItem(index);
            },

            removeOperationsunderskoterska(index, removeItem) {
                removeItem(index);
            },

            async searchMaterial(index, search, loading) {
                if (search.trim().length >= 2) {
                    loading(true);
                    const options = materialOptions(
                        await fetchData({
                            url: "/operation/material/all/",
                            params: { q: search },
                        })
                    );
                    this.$set(this.material, index, options);
                    loading(false);
                }
            },

            materialSelected(selectedOption, index) {
                if (propertyExistsNotNull(selectedOption) && isNotEmptyObject(selectedOption)) {
                    const { id, namn, artikelnummer } = selectedOption;

                    this.$set(this.materialFormData.material, index, {
                        material: id,
                        materialnamn: namn,
                        artikelnummer,
                    });

                    this.$nextTick(() => {
                        this.$refs.antal.$el.querySelector("input").focus();
                    });
                } else {
                    this.$set(this.materialFormData.material, index, {});
                }
            },

            async materialOnEnter(search = "", index = NaN) {
                this.$set(this.errorMessages, index, "");

                const options = materialOptions(
                    await fetchData({
                        url: "/operation/material/all/",
                        params: { q: search },
                    })
                );

                if (options.length > 1) {
                    this.$set(this.errorMessages, index, "För många artikelnummer.");
                } else if (options.length === 0) {
                    this.$set(this.errorMessages, index, `Hittar inget material på artikelnummret: ${search}`);
                    this.$refs.artikelnummer.$el.querySelector("input").focus();
                } else {
                    const Option = options[0];
                    const { id, namn, artikelnummer } = Option;
                    this.$set(this.materialFormData.material, index, {
                        material: id,
                        materialnamn: namn,
                        artikelnummer,
                    });
                    this.$set(this.material, index, options);
                    this.$nextTick(() => {
                        this.$refs.antal.$el.querySelector("input").focus();
                    });
                }
            },

            artikelNummerOnLeave(input = "", index = NaN) {
                if (input.length === 0) {
                    this.$set(this.materialFormData.material, index, {});
                }
            },

            setWarningMessage({
                groupIndex = NaN,
                formGroup = [],
                formGroupFieldName = "",
                customWarningMessage = "",
            }) {
                let warningMessage;

                if (groupIndex >= 1) {
                    const { hasDuplicates, indexofDuplicated } = hasDuplicatedObjectsInArray({
                        list: formGroup,
                        propertyFieldName: formGroupFieldName,
                    });

                    if (hasDuplicates && groupIndex === indexofDuplicated) {
                        warningMessage = customWarningMessage;
                    }
                }

                return warningMessage;
            },

            antalOnEnter(event) {
                if (event.target.value) {
                    this.materialFormData.material.push({});
                    this.$nextTick(() => {
                        this.$refs.artikelnummer.$el.querySelector("input").focus();
                    });
                }
            },

            removeMaterial(index, removeItem) {
                removeItem(index);
                let material = this.material;
                material.splice(index, 1);
                for (let i = 0; i < this.material.length; i++) {
                    this.$set(this.material, i, material[i]);
                }
            },

            async addMoreMaterial(addMore) {
                addMore();
            },

            async searchAnestesilakare(search, loading) {
                if (search.trim().length >= 2) {
                    this.anestesilakare = await this.searchProfile(
                        "core/profiles/?roll__code=anestesilakare",
                        search,
                        loading
                    );
                } else if (search.trim().length < 1) {
                    this.anestesilakare = await this.searchProfile(
                        "core/profiles/?roll__code=anestesilakare",
                        "",
                        loading
                    );
                }
            },

            huvudanestesikodChanged() {
                if (isNull(this.anestesiFormData.huvudanestesikod)) {
                    fetchData({
                        url: "/operation/kodverk/anestesikoder/all/",
                        filename: "Operationsjournal.vue",
                        description: "Hämta huvudanestesikoder",
                    }).then((response) => {
                        this.huvudanestesikoder = cvOptions(response);
                    });
                } else {
                    this.removeError("huvudanestesikod");
                }
            },

            anestesikomplikationerKategorierChanged(index) {
                const options = anestesikomplikationerOptions(
                    this.rawAnestesikomplikationer.filter(
                        (obj) => obj.kapitel === this.anestesiFormData.anestesikomplikationer[index].kategori
                    )
                );
                this.$set(this.anestesikomplikationer, index, options);
                if (this.anestesiFormData.anestesikomplikationer[index].kategori === "A0") {
                    const obj = this.rawAnestesikomplikationer.find((obj) => obj.code === "A0");
                    this.$set(this.anestesiFormData.anestesikomplikationer, index, {
                        kategori: this.anestesiFormData.anestesikomplikationer[index].kategori,
                        kod: `${obj.id}`,
                        svarighetsgrad: "1",
                    });
                    this.removeError("anestesikomplikationer");
                    this.removeError(`anestesikomplikationer.${index}.kod`);
                    this.removeError(`anestesikomplikationer.${index}.svarighetsgrad`);
                } else {
                    this.$set(this.anestesiFormData.anestesikomplikationer, index, {
                        kategori: this.anestesiFormData.anestesikomplikationer[index].kategori,
                        kod: "",
                        svarighetsgrad: "",
                    });
                }
            },
            anestesikomplikationChanged(index, v) {
                if (v === null) {
                    this.$set(this.anestesiFormData.anestesikomplikationer, index, {
                        kod: "",
                        svarighetsgrad: "",
                    });
                }
                this.removeError("anestesikomplikationer");
            },

            anestesikomplikationerErrorChanged(name, index) {
                this.removeError(`anestesikomplikationer.${index}.${name}`);
            },
            operationssjukskoterskaErrorChanged(name, index) {
                this.removeError(`operationssjukskoterskor.${index}.${name}`);
            },

            removeAnestesikomplikationer(index, removeItem) {
                removeItem(index);
                let anestesikomplikationer = this.anestesikomplikationer;
                anestesikomplikationer.splice(index, 1);
                for (let i = 0; i < this.anestesikomplikationer.length; i++) {
                    this.$set(this.anestesikomplikationer, i, anestesikomplikationer[i]);
                }
            },

            async searchHuvudanestesikod(search, loading) {
                if (search.trim().length >= 3) {
                    loading(true);
                    this.huvudanestesikoder = cvOptions(
                        await fetchData({
                            url: "/operation/kodverk/anestesikoder/all/",
                            params: { q: search },
                        })
                    );
                    loading(false);
                }
            },

            async searchAnestesisjukskoterska(index, search, loading) {
                if (search.trim().length >= 3) {
                    loading(true);
                    this.anestesisjukskoterskor = profileOptions(
                        await fetchData({
                            url: "core/profiles/?roll__code=anestesisjukskoterska",
                            params: { q: search },
                        })
                    );
                    loading(false);
                } else if (search.trim().length < 1) {
                    this.anestesisjukskoterskor = profileOptions(
                        await fetchData({
                            url: "core/profiles/?roll__code=anestesisjukskoterska",
                            params: { q: search },
                        })
                    );
                }
            },

            async searchBianestesikoder(index, search, loading) {
                if (search.trim().length >= 3) {
                    loading(true);
                    const anestesikoder = cvOptions(
                        await fetchData({
                            url: "/operation/kodverk/anestesikoder/all/",
                            params: { q: search },
                        })
                    );
                    this.$set(this.bianestesikoder, index, anestesikoder);
                    loading(false);
                }
            },

            removeBianestesikoder(index, removeItem) {
                removeItem(index);
                let bianestesikoder = this.bianestesikoder;
                bianestesikoder.splice(index, 1);
                for (let i = 0; i < this.bianestesikoder.length; i++) {
                    this.$set(this.bianestesikoder, i, bianestesikoder[i]);
                }
            },

            async addMoreBianestesikoder(addMore) {
                addMore();
                let bianestesikoder = cvOptions(
                    await fetchData({
                        url: "/operation/kodverk/anestesikoder/all/",
                    })
                );

                this.$set(this.bianestesikoder, this.bianestesikoder.length, bianestesikoder);
            },

            removeNullPropertyValuesFromWhoList(payload) {
                for (let i in payload) {
                    if (payload[i] === null) {
                        delete payload[i];
                    }
                }
                return payload;
            },

            // Ska hela översiktslistan refreshas och ska det göras här?
            async updateEntry(id) {
                try {
                    const response = await fetchData({
                        url: `/operation/oversikt/${id}/`,
                    });
                    await this.updateAnmalningarList(response);
                } catch (error) {
                    openDialog(error, "error");
                }
            },

            async submitOperationsuppgifter({ button, signeringsansvarig }) {
                let payload = cloneDeep(this.operationsuppgifterFormData);

                if (payload.typoperation === "null") {
                    delete payload.typoperation;
                }

                if (payload.bidiagnoser) {
                    payload.bidiagnoser = this.extractValues(payload.bidiagnoser);
                    payload.bidiagnoser = removeEmptyObjectsFromArray(payload.bidiagnoser);
                }
                if (payload.bioperationskoder) {
                    payload.bioperationskoder = this.extractValues(payload.bioperationskoder);
                    payload.bioperationskoder = removeEmptyObjectsFromArray(payload.bioperationskoder);
                }
                if (button === "save") {
                    await this.submit(
                        payload,
                        "Operationsuppgifter sparade",
                        "operationsuppgifter/",
                        signeringsansvarig
                    );
                    this.operationsuppgifterSavedFormData = this.operationsuppgifterFormData;
                } else if (button === "sign") {
                    await this.signSectionOperationsuppgifter({
                        payload: payload,
                        signeringsansvarig: signeringsansvarig,
                    });
                }
            },

            async signSectionOperationsuppgifter({ payload, signeringsansvarig }) {
                payload.id = this.operationsanmalan.id;
                payload.accountableHealthcareProfessional = signeringsansvarig;
                if (!this.operationjournal_id) {
                    try {
                        const response = await klinikenApi.post(
                            "operation/operationsjournaler/",
                            { anmalan: this.operationsanmalan.id },
                            getConfig({}, "Operationsjournal")
                        );
                        this.operationjournal_id = response.data.id;
                    } catch (error) {
                        return;
                    }
                }

                const url = `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/operationsuppgifter/`;

                this.signDataOperationsuppgifter = payload;
                this.signUrlOperationsuppgifter = url;
                this.signVisibleOperationsuppgifter = true;
            },

            async handleSignOperationsuppgifterSuccess(data) {
                this.operationsuppgifterFormData.is_signed = data.is_signed;
                this.operationsuppgifterSavedFormData = cloneDeep(this.operationsuppgifterFormData);

                this.signVisibleOperationsuppgifter = false;
            },

            handleSignOperationsuppgifterError(errors) {
                if (typeof errors === "object") {
                    this.formError = parseError(errors);
                } else {
                    openDialog("Misslyckades med att signera operationsuppgifter.", "error");
                }
                this.signVisibleOperationsuppgifter = false;
            },

            handleSignOperationsuppgifterCancel() {
                this.signVisibleOperationsuppgifter = false;
            },

            // Används för att formatera bidiagnoskoder, bioperationskoder och bianestesikoder i payload till backend
            extractValues(list) {
                const valueList = list.map((item) => getPropertyValue(item));
                return valueList;
            },

            async submitPatientdata({ button, signeringsansvarig }) {
                this.isSaved = true;
                let payload = cloneDeep(this.patientdataFormData);
                payload.accountableHealthcareProfessional = signeringsansvarig;
                if (button === "save") {
                    await this.submit(payload, "Patientdata sparad", "patientdata/", signeringsansvarig);
                    this.patientdataSavedFormData = cloneDeep(this.patientdataFormData);
                } else if (button === "sign") {
                    this.signSectionPatientdata({
                        signeringsansvarig: signeringsansvarig,
                        payload: payload,
                    });
                }
                this.isSaved = false;
            },

            async signSectionPatientdata({ payload, signeringsansvarig }) {
                payload.id = this.operationsanmalan.id;
                payload.accountableHealthcareProfessional = signeringsansvarig;
                if (!this.operationjournal_id) {
                    try {
                        const response = await klinikenApi.post(
                            "operation/operationsjournaler/",
                            { anmalan: this.operationsanmalan.id },
                            getConfig({}, "Operationsjournal")
                        );
                        this.operationjournal_id = response.data.id;
                    } catch (error) {
                        return;
                    }
                }

                const url = `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/patientdata/`;

                this.signDataPatientdata = payload;
                this.signUrlPatientdata = url;
                this.signVisiblePatientdata = true;
            },

            handleSignPatientdataSuccess(data) {
                this.patientdataFormData.is_signed = data.is_signed;
                this.patientdataSavedFormData = cloneDeep(this.patientdataFormData);

                this.signVisiblePatientdata = false;
            },

            handleSignPatientdataCancel() {
                this.signVisiblePatientdata = false;
            },

            formatPersonalGroup(payload, name) {
                // Tar bort rader utan vald profil
                payload[name] = payload[name].filter((obj) => Boolean(obj.profile));
                // Extraherar uuid från profilen
                payload[name].forEach((obj) => (obj.profile = obj.profile.value ?? obj.profile));
                // Lägger till datum på in- och uttider
                payload[name].forEach((item, index) => {
                    payload[name][index].intid = this.setDateOnTid(item.intid);
                    payload[name][index].uttid = this.setDateOnTid(item.uttid);
                });
            },

            removeNullElements(inputArray, fieldName) {
                return inputArray.filter((item) => item[fieldName] !== null).map((item) => item[fieldName]);
            },

            async submitPersonal({ button, signeringsansvarig }) {
                let payload = cloneDeep(this.personalFormData);
                this.isSaved = true;

                payload.huvudoperator_intid = this.setDateOnTid(payload.huvudoperator_intid);
                payload.huvudoperator_uttid = this.setDateOnTid(payload.huvudoperator_uttid);

                payload.anestesilakare_intid = this.setDateOnTid(payload.anestesilakare_intid);
                payload.anestesilakare_uttid = this.setDateOnTid(payload.anestesilakare_uttid);

                if (payload.ass_operatorer) this.formatPersonalGroup(payload, "ass_operatorer");
                if (payload.operationssjukskoterskor) this.formatPersonalGroup(payload, "operationssjukskoterskor");
                if (payload.operationsunderskoterskor) this.formatPersonalGroup(payload, "operationsunderskoterskor");
                if (payload.anestesisjukskoterskor) this.formatPersonalGroup(payload, "anestesisjukskoterskor");

                if (button === "save") {
                    await this.submit(payload, "Personal sparad", "personal/", signeringsansvarig);
                    this.personalSavedFormData = cloneDeep(this.personalFormData);
                } else if (button === "sign") {
                    await this.signSectionPersonal({
                        signeringsansvarig: signeringsansvarig,
                        payload: payload,
                    });
                }
                this.isSaved = false;
            },

            async signSectionPersonal({ payload, signeringsansvarig }) {
                payload.id = this.operationsanmalan.id;
                payload.accountableHealthcareProfessional = signeringsansvarig;
                if (!this.operationjournal_id) {
                    try {
                        const response = await klinikenApi.post(
                            "operation/operationsjournaler/",
                            { anmalan: this.operationsanmalan.id },
                            getConfig({}, "Operationsjournal")
                        );
                        this.operationjournal_id = response.data.id;
                    } catch (error) {
                        return;
                    }
                }

                const url = `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/personal/`;

                this.signDataPersonal = payload;
                this.signUrlPersonal = url;
                this.signVisiblePersonal = true;
            },

            async handleSignPersonalSuccess(data) {
                this.personalFormData.is_signed = data.is_signed;
                this.personalSavedFormData = cloneDeep(this.personalFormData);

                this.signVisiblePersonal = false;
            },

            handleSignPersonalError(errors) {
                if (typeof errors === "object") {
                    this.formError = parseError(errors);
                } else {
                    openDialog("Misslyckades med att signera personal.", "error");
                }
                this.signVisiblePersonal = false;
            },

            handleSignPersonalCancel() {
                this.signVisiblePersonal = false;
            },

            async submitWHOchecklistaForberedelse(data) {
                let payload = cloneDeep(data);
                payload = this.removeNullPropertyValuesFromWhoList(payload);
                this.showWHOchecklistaForberedelse = false;
                let response = await this.submit(
                    payload,
                    "WHO-checklista 1 - Förberedelse sparad",
                    "whochecklista/forberedelse/"
                );
                if (response !== undefined) {
                    this.whochecklistaForberedelseFormData.updated_by = response.updated_by;
                    this.whochecklistaForberedelseFormData.updated_at = moment(response.updated_at).format(
                        "YYYY-MM-DD"
                    );
                }
            },

            async submitWHOchecklistaTimeOut(data) {
                let payload = cloneDeep(data);
                payload = this.removeNullPropertyValuesFromWhoList(payload);
                this.showWHOchecklistaTimeOut = false;
                let response = await this.submit(
                    payload,
                    "WHO-checklista 2 - Timeout sparad",
                    "whochecklista/timeout/"
                );
                if (response !== undefined) {
                    this.whochecklistaTimeoutFormData.updated_by = response.updated_by;
                    this.whochecklistaTimeoutFormData.updated_at = moment(response.updated_at).format("YYYY-MM-DD");
                }
            },

            async submitWHOchecklistaAvslutning(data) {
                let payload = cloneDeep(data);
                payload = this.removeNullPropertyValuesFromWhoList(payload);
                this.showWHOchecklistaAvslutning = false;
                let response = await this.submit(
                    payload,
                    "WHO-checklista 3 - Avslutning sparad",
                    "whochecklista/avslutning/"
                );
                if (response !== undefined) {
                    this.whochecklistaAvslutningFormData = response;
                    this.whochecklistaAvslutningFormData.updated_at = moment(response.updated_at).format("YYYY-MM-DD");
                }
            },

            async submitMaterial({ button, signeringsansvarig }) {
                this.isSaved = true;
                let payload = cloneDeep(this.materialFormData);
                this.materialFormData.material.map((item) => {
                    const itemKeys = Object.keys(item);
                    const quantity = "antal";

                    if (itemKeys.length === 1 && itemKeys.includes(quantity) && item[quantity] === 0) {
                        delete item[quantity];
                    }
                });

                if (payload.material) payload.material = removeEmptyObjectsFromArray(payload.material);

                if (button === "save") {
                    await this.submit(payload, "Material sparade", "material/", signeringsansvarig);
                    this.materialSavedFormData = cloneDeep(this.materialFormData);
                } else if (button === "sign") {
                    await this.signSectionMaterial({
                        signeringsansvarig: signeringsansvarig,
                        payload: payload,
                    });
                }
                this.isSaved = false;
            },

            async signSectionMaterial({ payload, signeringsansvarig }) {
                payload.id = this.operationsanmalan.id;
                payload.accountableHealthcareProfessional = signeringsansvarig;
                if (!this.operationjournal_id) {
                    try {
                        const response = await klinikenApi.post(
                            "operation/operationsjournaler/",
                            { anmalan: this.operationsanmalan.id },
                            getConfig({}, "Operationsjournal")
                        );
                        this.operationjournal_id = response.data.id;
                    } catch (error) {
                        return;
                    }
                }

                const url = `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/material/`;

                this.signDataMaterial = payload;
                this.signUrlMaterial = url;
                this.signVisibleMaterial = true;
            },

            async handleSignMaterialSuccess(data) {
                this.materialFormData.is_signed = data.is_signed;
                this.materialSavedFormData = cloneDeep(this.materialFormData);

                this.signVisibleMaterial = false;
            },

            handleSignMaterialCancel() {
                this.signVisibleMaterial = false;
            },

            async submitAnestesi({ button, signeringsansvarig }) {
                this.isSaved = true;
                let payload = cloneDeep(this.anestesiFormData);

                if (payload.bianestesikoder) {
                    payload.bianestesikoder = this.extractValues(payload.bianestesikoder);
                    payload.bianestesikoder = removeEmptyObjectsFromArray(payload.bianestesikoder);
                }

                if (payload.anestesikomplikationer) {
                    payload.anestesikomplikationer = removeEmptyObjectsFromArray(payload.anestesikomplikationer);
                    payload.anestesikomplikationer.forEach((komplikation, index) => {
                        payload.anestesikomplikationer[index].tidpunkt = this.setDateOnTid(komplikation.tidpunkt);
                    });
                }

                if (button === "save") {
                    await this.submit(payload, "Anestesi sparad", "anestesi/", signeringsansvarig);
                    this.anestesiSavedFormData = cloneDeep(this.anestesiFormData);
                } else if (button === "sign") {
                    await this.signSectionAnestesi({
                        signeringsansvarig: signeringsansvarig,
                        payload: payload,
                    });
                }
                this.isSaved = false;
            },

            async signSectionAnestesi({ payload, signeringsansvarig }) {
                payload.id = this.operationsanmalan.id;
                payload.accountableHealthcareProfessional = signeringsansvarig;
                if (!this.operationjournal_id) {
                    try {
                        const response = await klinikenApi.post(
                            "operation/operationsjournaler/",
                            { anmalan: this.operationsanmalan.id },
                            getConfig({}, "Operationsjournal")
                        );
                        this.operationjournal_id = response.data.id;
                    } catch (error) {
                        return;
                    }
                }

                const url = `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/anestesi/`;

                this.signDataAnestesi = payload;
                this.signUrlAnestesi = url;
                this.signVisibleAnestesi = true;
            },

            async handleSignAnestesiSuccess(data) {
                this.anestesiFormData.is_signed = data.is_signed;
                this.anestesiSavedFormData = cloneDeep(this.anestesiFormData);

                this.signVisibleAnestesi = false;
            },

            handleSignAnestesiError(errors) {
                if (typeof errors === "object") {
                    this.formError = parseError(errors);
                } else {
                    openDialog("Misslyckades med att signera anestesi.", "error");
                }
                this.signVisibleAnestesi = false;
            },

            handleSignAnestesiCancel() {
                this.signVisibleAnestesi = false;
            },

            setDateOnTid(tid) {
                return tid ? changeTimeToDate(this.operationsuppgifterFormData.operationsdatum, tid) : null;
            },

            async submitTid(key) {
                let value = this.tiderFormData[key];
                value = this.setDateOnTid(value);
                const payload = { [key]: value };

                payload.fritext = this.tiderFormData.fritext;

                const error = await this.submit(payload, "Tid sparad", "tider/");
                if (error) {
                    openDialog(getErrorMessage(error), "error");
                    this.tiderFormData[key] = this.tiderSavedFormData[key];
                }

                if (
                    this.tiderFormData.uppdukning_start !== this.tiderSavedFormData.uppdukning_start ||
                    this.tiderFormData.patienttid_start !== this.tiderSavedFormData.patienttid_start ||
                    this.tiderFormData.x_minuter_kvar !== this.tiderSavedFormData.x_minuter_kvar ||
                    this.tiderFormData.opslut !== this.tiderSavedFormData.opslut ||
                    this.tiderFormData.patienttid_slut !== this.tiderSavedFormData.patienttid_slut
                ) {
                    emitter.emit("pagaende-op-tider-updated");
                }

                this.tiderSavedFormData = cloneDeep(this.tiderFormData);
            },

            async submitTider() {
                let payload = cloneDeep(this.tiderFormData);

                payload.uppdukning_start = this.setDateOnTid(payload.uppdukning_start);
                payload.uppdukning_slut = this.setDateOnTid(payload.uppdukning_slut);
                payload.antibiotikaprofylax_start = this.setDateOnTid(payload.antibiotikaprofylax_start);
                payload.ankomsttid_preopenhet = this.setDateOnTid(payload.ankomsttid_preopenhet);
                payload.preoperativ_anestesi_start = this.setDateOnTid(payload.preoperativ_anestesi_start);
                payload.patienttid_start = this.setDateOnTid(payload.patienttid_start);
                payload.patient_in_opsal = this.setDateOnTid(payload.patient_in_opsal);
                payload.anestesitid_start = this.setDateOnTid(payload.anestesitid_start);
                payload.anestesitid_klar = this.setDateOnTid(payload.anestesitid_klar);
                payload.operationsforberedelse_klar = this.setDateOnTid(payload.operationsforberedelse_klar);
                payload.opstart = this.setDateOnTid(payload.opstart);
                payload.x_minuter_kvar = this.setDateOnTid(payload.x_minuter_kvar);
                payload.opslut = this.setDateOnTid(payload.opslut);
                payload.anestesi_slut = this.setDateOnTid(payload.anestesi_slut);
                payload.patient_ut_opsal = this.setDateOnTid(payload.patient_ut_opsal);
                payload.patienttid_slut = this.setDateOnTid(payload.patienttid_slut);

                await this.submit(payload, "Tider sparade", "tider/");

                if (
                    this.tiderFormData.uppdukning_start !== this.tiderSavedFormData.uppdukning_start ||
                    this.tiderFormData.patienttid_start !== this.tiderSavedFormData.patienttid_start ||
                    this.tiderFormData.x_minuter_kvar !== this.tiderSavedFormData.x_minuter_kvar ||
                    this.tiderFormData.opslut !== this.tiderSavedFormData.opslut ||
                    this.tiderFormData.patienttid_slut !== this.tiderSavedFormData.patienttid_slut
                ) {
                    emitter.emit("pagaende-op-tider-updated");
                }

                this.tiderSavedFormData = cloneDeep(this.tiderFormData);
            },

            async submit(payload, saveMessage, url, signeringsansvarig) {
                if (!isEmptyObject(this.validation) && arrayPropertyExistsNotEmpty(this.validation.errors)) {
                    return;
                }
                payload.accountableHealthcareProfessional = signeringsansvarig;

                let response;

                // Första gången en sektion för en operationsjournal sparas måste operationsjournalen först skapas
                if (!this.operationjournal_id) {
                    try {
                        response = await klinikenApi.post(
                            "operation/operationsjournaler/",
                            { anmalan: this.operationsanmalan.id },
                            getConfig({}, "Operationsjournal")
                        );
                        this.operationjournal_id = response.data.id;
                    } catch (error) {
                        return;
                    }
                }

                try {
                    response = await klinikenApi.put(
                        `operation/operationsjournaler/${this.operationjournal_id}/sektioner/${url}`,
                        payload,
                        getConfig({}, "Operationsjournal")
                    );

                    if (response.status === 200) {
                        flashMessage(saveMessage);
                        await this.updateEntry(this.operationsanmalan.id);
                    } else {
                        flashWarning("Något gick fel");
                    }
                } catch (error) {
                    if (url === "tider/") return error;
                    this.formError = parseError(error.response.data);
                }
            },

            async cancel() {
                if (this.hasUnsavedData) {
                    if (
                        await this.$refs.dialog.show({
                            variant: BasicModalVariant.WARNING,
                            title: "Denna flik har osparad data. Vill du stänga ändå?",
                            okButton: "Ja",
                            cancelButton: "Nej",
                        })
                    ) {
                        await this.$store.dispatch("tabs/closeTab", this.tabId);
                    }
                } else {
                    await this.$store.dispatch("tabs/closeTab", this.tabId);
                }
            },

            async openWhoForberedelse() {
                this.showWHOchecklistaForberedelse = true;
                if (this.operationjournal_id) {
                    let response = await fetchData({
                        url: `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/whochecklista/forberedelse/`,
                    });
                    this.whochecklistaForberedelseFormData = response;
                    const { kand_allergi, risk_for_aspiration, risk_for_blodforlust, risk_for_hypotermi } = response;
                    if (typeof kand_allergi === "boolean") {
                        this.whochecklistaForberedelseFormData.kand_allergi = kand_allergi.toString();
                    }
                    if (typeof risk_for_aspiration === "boolean") {
                        this.whochecklistaForberedelseFormData.risk_for_aspiration = risk_for_aspiration.toString();
                    }
                    if (typeof risk_for_blodforlust === "boolean") {
                        this.whochecklistaForberedelseFormData.risk_for_blodforlust = risk_for_blodforlust.toString();
                    }
                    if (typeof risk_for_hypotermi === "boolean") {
                        this.whochecklistaForberedelseFormData.risk_for_hypotermi = risk_for_hypotermi.toString();
                    }
                }
            },
            async openWhoTimeout() {
                this.showWHOchecklistaTimeOut = true;
                if (this.operationjournal_id) {
                    let response = await fetchData({
                        url: `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/whochecklista/timeout/`,
                    });
                    const { antibiotikaprofylax, bildinformation, trombosprofylax } = response;
                    this.whochecklistaTimeoutFormData = response;
                    if (typeof antibiotikaprofylax === "boolean") {
                        this.whochecklistaTimeoutFormData.antibiotikaprofylax = antibiotikaprofylax.toString();
                    }

                    if (typeof trombosprofylax === "boolean") {
                        this.whochecklistaTimeoutFormData.trombosprofylax = trombosprofylax.toString();
                    }

                    if (typeof bildinformation === "boolean") {
                        this.whochecklistaTimeoutFormData.bildinformation = bildinformation.toString();
                    }
                }
            },
            async openWhoAvslutning() {
                this.showWHOchecklistaAvslutning = true;
                if (this.operationjournal_id) {
                    this.whochecklistaAvslutningFormData = await fetchData({
                        url: `/operation/operationsjournaler/${this.operationjournal_id}/sektioner/whochecklista/avslutning/`,
                    });
                }
            },

            setOperationsuppgifter(data) {
                this.operationsuppgifterFormData.is_signed = data.is_signed;
                this.operationsuppgifterFormData.accountableHealthcareProfessional =
                    data.accountableHealthcareProfessional && data.accountableHealthcareProfessional;
                this.operationsuppgifterFormData.typoperation = isNull(data.typoperation) ? "" : `${data.typoperation}`;

                if (propertyExists(data.huvuddiagnos_display) && isNotEmptyObject(data.huvuddiagnos_display)) {
                    this.huvuddiagnoser = SetField({
                        value: data.huvuddiagnos_display.id,
                        label: `${data.huvuddiagnos_display.code} ${data.huvuddiagnos_display.displayName}`,
                        formData: this.operationsuppgifterFormData,
                        objectPropertyKey: "huvuddiagnos",
                    });
                }
                if (propertyExists(data.bidiagnoser_display) && isNotEmptyObject(data.bidiagnoser_display)) {
                    data.bidiagnoser_display.forEach(function (obj, index) {
                        this.$set(this.bidiagnoser, index, [
                            {
                                value: obj.id,
                                label: `${obj.code} ${obj.displayName}`,
                            },
                        ]);
                        this.$set(this.operationsuppgifterFormData.bidiagnoser, index, { bidiagnos: `${obj.id}` });
                    }, this);
                }

                if (
                    propertyExists(data.huvudoperationskod_display) &&
                    isNotEmptyObject(data.huvudoperationskod_display)
                ) {
                    this.huvudoperationskoder = SetField({
                        value: data.huvudoperationskod_display.id,
                        label: `${data.huvudoperationskod_display.code} ${data.huvudoperationskod_display.displayName}`,
                        formData: this.operationsuppgifterFormData,
                        objectPropertyKey: "huvudoperationskod",
                    });
                }
                if (
                    propertyExists(data.bioperationskoder_display) &&
                    isNotEmptyObject(data.bioperationskoder_display)
                ) {
                    data.bioperationskoder_display.forEach(function (obj, index) {
                        this.$set(this.bioperationskoder, index, [
                            {
                                value: obj.id,
                                label: `${obj.code} ${obj.displayName}`,
                            },
                        ]);
                        this.$set(this.operationsuppgifterFormData.bioperationskoder, index, {
                            bioperationskod: `${obj.id}`,
                        });
                    }, this);
                }

                if (!this.isEditing) {
                    if (propertyExistsNotNull(data.bokad_operation_datum)) {
                        this.operationsuppgifterFormData.operationsdatum = data.bokad_operation_datum;
                    }
                    if (!isNull(data.current_bokad_sal)) {
                        this.operationsuppgifterFormData.sal = `${data.current_bokad_sal}`;
                    }
                } else {
                    if (propertyExistsNotNull(data.operationsdatum)) {
                        this.operationsuppgifterFormData.operationsdatum = data.operationsdatum;
                    }
                    if (!isNull(data.sal)) {
                        this.operationsuppgifterFormData.sal = `${data.sal}`;
                    }
                }
                if (!isNull(data.reop)) {
                    this.operationsuppgifterFormData.reop = data.reop;
                }
                if (!isNull(data.garantiop)) {
                    this.operationsuppgifterFormData.garantiop = data.garantiop;
                }
                if (!isNull(data.lateralitet)) {
                    this.operationsuppgifterFormData.lateralitet = data.lateralitet;
                }
                if (!isNull(data.avtal)) {
                    this.operationsuppgifterFormData.avtal = data.avtal;
                }
                if (!isNull(data.anmarkning)) {
                    this.operationsuppgifterFormData.anmarkning = data.anmarkning;
                }
            },

            async setPersonal(data) {
                this.personalFormData.is_signed = data.is_signed;
                this.personalFormData.accountableHealthcareProfessional =
                    data.accountableHealthcareProfessional && data.accountableHealthcareProfessional;
                if (propertyExists(data.huvudoperator)) {
                    this.personalFormData.huvudoperator = data.current_bokad_huvudoperator;
                    this.personalFormData.huvudoperator_intid = getTimeFromDatetime(data.huvudoperator_intid);
                    this.personalFormData.huvudoperator_uttid = getTimeFromDatetime(data.huvudoperator_uttid);
                }

                this.personalFormData.anestesilakare = data.anestesilakare;

                this.personalFormData.anestesilakare_intid = getTimeFromDatetime(data.anestesilakare_intid);
                this.personalFormData.anestesilakare_uttid = getTimeFromDatetime(data.anestesilakare_uttid);

                if (!this.isEditing) {
                    this.operatorer = await this.addInactiveProfile(this.operatorer, data.current_bokad_huvudoperator);
                    if (arrayPropertyExistsNotEmpty(data.ass_operatorer)) {
                        data.ass_operatorer.forEach((ass_operator, groupIndex) => {
                            this.$set(this.ass_operatorer, groupIndex, [
                                {
                                    value: `${ass_operator}`,
                                    label: data.ass_operatorer_display[groupIndex].user_display.name,
                                },
                            ]);
                            this.$set(this.personalFormData.ass_operatorer, groupIndex, {
                                profile: `${ass_operator}`,
                            });
                        });
                    }
                    this.ass_operatorer = await this.addInactiveProfiles(
                        this.ass_operatorer,
                        this.personalFormData.ass_operatorer
                    );
                } else {
                    this.operatorer = await this.addInactiveProfile(this.operatorer, data.current_bokad_huvudoperator);
                    this.anestesilakare = await this.addInactiveProfile(
                        this.anestesilakare,
                        this.personalFormData.anestesilakare
                    );
                    this.setPersonalField(data, "ass_operatorer");
                }

                if (arrayPropertyExistsNotEmpty(data.anestesisjukskoterskor)) {
                    this.setPersonalField(data, "anestesisjukskoterskor");
                }

                if (arrayPropertyExistsNotEmpty(data.operationssjukskoterskor)) {
                    this.setPersonalField(data, "operationssjukskoterskor");
                }

                if (arrayPropertyExistsNotEmpty(data.operationsunderskoterskor)) {
                    this.setPersonalField(data, "operationsunderskoterskor");
                }
                this.personalFormData.fritext = data.fritext;
            },

            async setPatient(data) {
                this.patientdataFormData.is_signed = data.is_signed;
                this.patientdataFormData.accountableHealthcareProfessional =
                    data.accountableHealthcareProfessional && data.accountableHealthcareProfessional;
                this.patientdataFormData.patientdata = data.patientdata;
                this.id_kontroll = await this.addInactiveProfile(
                    this.id_kontroll,
                    this.patientdataFormData.patientdata[0].kontroll[0].id_kontroll
                );
            },

            setWHOCheckLista(data) {
                this.whochecklistaForberedelseFormData.updated_at = moment(
                    data.sektion_whochecklistaforberedelse.updated_at
                ).format("YYYY-MM-DD");
                this.whochecklistaForberedelseFormData.updated_by = data.sektion_whochecklistaforberedelse.updated_by;

                this.whochecklistaTimeoutFormData.updated_at = moment(
                    data.sektion_whochecklistatimeout.updated_at
                ).format("YYYY-MM-DD");
                this.whochecklistaTimeoutFormData.updated_by = data.sektion_whochecklistatimeout.updated_by;

                this.whochecklistaAvslutningFormData.updated_at = moment(
                    data.sektion_whochecklistaavslutning.updated_at
                ).format("YYYY-MM-DD");
                this.whochecklistaAvslutningFormData.updated_by = data.sektion_whochecklistaavslutning.updated_by;
            },

            setMaterial(data) {
                this.materialFormData.is_signed = data.is_signed;
                this.materialFormData.accountableHealthcareProfessional =
                    data.accountableHealthcareProfessional && data.accountableHealthcareProfessional;
                if (arrayPropertyExistsNotEmpty(data.material)) {
                    this.materialFormData.material = data.material.map((item) => {
                        const { antal, batchnummer } = item;
                        const { artikelnummer, id, namn } = item.material_display;
                        return { material: id, artikelnummer, materialnamn: namn, antal, batchnummer };
                    });
                }
                this.materialFormData.fritext = data.fritext;
            },

            setAnestesi(data) {
                this.anestesiFormData.is_signed = data.is_signed;
                this.anestesiFormData.accountableHealthcareProfessional =
                    data.accountableHealthcareProfessional && data.accountableHealthcareProfessional;
                if (propertyExistsNotNull(data.anestesilakare)) {
                    this.anestesiFormData.anestesilakare = `${data.anestesilakare}`;
                }

                if (propertyExistsNotNull(data.anestesisjukskoterska)) {
                    this.anestesiFormData.anestesisjukskoterska = `${data.anestesisjukskoterska}`;
                }

                if (propertyExists(data.huvudanestesikod_display) && isNotEmptyObject(data.huvudanestesikod_display)) {
                    this.huvudanestesikoder = SetField({
                        value: data.huvudanestesikod_display.id,
                        label: `${data.huvudanestesikod_display.code} ${data.huvudanestesikod_display.displayName}`,
                        formData: this.anestesiFormData,
                        objectPropertyKey: "huvudanestesikod",
                    });
                }

                if (arrayPropertyExistsNotEmpty(data.bianestesikoder)) {
                    data.bianestesikoder.forEach((bianestesikod, groupIndex) => {
                        this.$set(this.bianestesikoder, groupIndex, [
                            {
                                value: `${bianestesikod}`,
                                label: `${data.bianestesikoder_display[groupIndex].code} ${data.bianestesikoder_display[groupIndex].displayName}`,
                            },
                        ]);
                        this.$set(this.anestesiFormData.bianestesikoder, groupIndex, {
                            kod: `${bianestesikod}`,
                        });
                    });
                }

                if (arrayPropertyExistsNotEmpty(data.anestesikomplikationer)) {
                    data.anestesikomplikationer.forEach((anestesikomplikation, groupIndex) => {
                        this.anestesikomplikationer[groupIndex] = [
                            {
                                value: `${anestesikomplikation.kod}`,
                                label: anestesikomplikation.kod_display.displayName,
                            },
                        ];

                        this.$set(this.anestesiFormData.anestesikomplikationer, groupIndex, {
                            kategori: `${anestesikomplikation.kod_display.kapitel}`,
                            kod: `${anestesikomplikation.kod}`,
                            svarighetsgrad: `${anestesikomplikation.svarighetsgrad}`,
                            tidpunkt: extractHourMinuteFromDateTime(anestesikomplikation.tidpunkt),
                        });
                    });
                }

                if (propertyExistsNotNull(data.anestesikomplikationer)) {
                    data.anestesikomplikationer && isNotEmptyArray(data.anestesikomplikationer)
                        ? (this.anestesiFormData.anestesikomplikationer = data.anestesikomplikationer.map(
                              (komplikation) => {
                                  return {
                                      kategori:
                                          komplikation.kod_display.kapitel && `${komplikation.kod_display.kapitel}`,
                                      svarighetsgrad: `${komplikation.svarighetsgrad}`,
                                      kod: `${komplikation.kod}`,
                                      tidpunkt: extractHourMinuteFromDateTime(komplikation.tidpunkt),
                                  };
                              }
                          ))
                        : (data.anestesikomplikationer = [{}]);
                }
            },

            setTider(data) {
                data.uppdukning_start &&
                    (this.tiderFormData.uppdukning_start = `${extractHourMinuteFromDateTime(data.uppdukning_start)}`);
                data.uppdukning_slut &&
                    (this.tiderFormData.uppdukning_slut = `${extractHourMinuteFromDateTime(data.uppdukning_slut)}`);
                data.antibiotikaprofylax_start &&
                    (this.tiderFormData.antibiotikaprofylax_start = `${extractHourMinuteFromDateTime(
                        data.antibiotikaprofylax_start
                    )}`);
                data.ankomsttid_preopenhet &&
                    (this.tiderFormData.ankomsttid_preopenhet = `${extractHourMinuteFromDateTime(
                        data.ankomsttid_preopenhet
                    )}`);
                data.preoperativ_anestesi_start &&
                    (this.tiderFormData.preoperativ_anestesi_start = `${extractHourMinuteFromDateTime(
                        data.preoperativ_anestesi_start
                    )}`);
                data.patienttid_start &&
                    (this.tiderFormData.patienttid_start = `${extractHourMinuteFromDateTime(data.patienttid_start)}`);
                data.patient_in_opsal &&
                    (this.tiderFormData.patient_in_opsal = `${extractHourMinuteFromDateTime(data.patient_in_opsal)}`);
                data.anestesitid_start &&
                    (this.tiderFormData.anestesitid_start = `${extractHourMinuteFromDateTime(data.anestesitid_start)}`);
                data.anestesitid_klar &&
                    (this.tiderFormData.anestesitid_klar = `${extractHourMinuteFromDateTime(data.anestesitid_klar)}`);
                data.operationsforberedelse_klar &&
                    (this.tiderFormData.operationsforberedelse_klar = `${extractHourMinuteFromDateTime(
                        data.operationsforberedelse_klar
                    )}`);
                data.opstart && (this.tiderFormData.opstart = `${extractHourMinuteFromDateTime(data.opstart)}`);
                data.x_minuter_kvar &&
                    (this.tiderFormData.x_minuter_kvar = `${extractHourMinuteFromDateTime(data.x_minuter_kvar)}`);
                data.opslut && (this.tiderFormData.opslut = `${extractHourMinuteFromDateTime(data.opslut)}`);
                data.anestesi_slut &&
                    (this.tiderFormData.anestesi_slut = `${extractHourMinuteFromDateTime(data.anestesi_slut)}`);
                data.patient_ut_opsal &&
                    (this.tiderFormData.patient_ut_opsal = `${extractHourMinuteFromDateTime(data.patient_ut_opsal)}`);
                data.patienttid_slut &&
                    (this.tiderFormData.patienttid_slut = `${extractHourMinuteFromDateTime(data.patienttid_slut)}`);
                this.tiderFormData.fritext = data.fritext;
            },

            setPersonalField(personalSection = {}, field) {
                personalSection[field].forEach((item, groupIndex) => {
                    if (isEmptyObject(item)) throw new Error(`item object is empty! ${item}`);

                    this.$set(personalSection[field], groupIndex, [
                        {
                            value: `${item.profile}`,
                            label: `${item.profile_display.user_display.name}`,
                        },
                    ]);
                    this.$set(this.personalFormData[field], groupIndex, {
                        profile: {
                            value: `${item.profile}`,
                            label: `${item.profile_display.user_display.name}`,
                        },
                        intid: getTimeFromDatetime(item.intid),
                        uttid: getTimeFromDatetime(item.uttid),
                    });
                });
            },

            async getOperationAnmalan(id) {
                return await fetchData({ url: `/operation/anmalan/${id}/` });
            },

            async getOperation(id) {
                return await fetchData({ url: `/operation/operationsjournaler/${id}/`, tabbnamn: "Operationsjournal" });
            },

            setOperation(data) {
                if (this.isEditing) {
                    this.setOperationsuppgifter(data.sektion_operationsuppgifter);
                    this.setPatient(data.sektion_patientdata);
                    this.setPersonal(data.sektion_personal);
                    this.setWHOCheckLista(data);
                    this.setMaterial(data.sektion_material);
                    this.setAnestesi(data.sektion_anestesi);
                    this.setTider(data.sektion_tider);
                } else {
                    this.setOperationsuppgifter(data);
                    this.setPersonal(data);
                }

                this.operationsuppgifterSavedFormData = cloneDeep(this.operationsuppgifterFormData);
                this.patientdataSavedFormData = cloneDeep(this.patientdataFormData);
                this.personalSavedFormData = cloneDeep(this.personalFormData);
                this.materialSavedFormData = cloneDeep(this.materialFormData);
                this.anestesiSavedFormData = cloneDeep(this.anestesiFormData);
                this.tiderSavedFormData = cloneDeep(this.tiderFormData);
            },
            async addInactiveProfiles(profiles, formProfiles) {
                let newArray = cloneDeep(profiles);

                for (let dropdownProfile of formProfiles) {
                    if (!newArray.some((profile) => profile.value === dropdownProfile.profile)) {
                        const response = await klinikenApi.get(
                            `/core/profiles/${dropdownProfile.profile}/`,
                            getConfig({})
                        );
                        newArray.push({
                            value: response.data.pk,
                            label: response.data.user_display.name,
                        });
                    }
                }

                return newArray;
            },
            async addInactiveProfile(profiles, id) {
                let new_array = cloneDeep(profiles);
                if (id !== undefined && id !== null) {
                    if (!new_array.some((element) => element.value === id)) {
                        const response = await klinikenApi.get(`/core/profiles/${id}/`, getConfig({}));
                        new_array.push({
                            value: response.data.pk,
                            label: response.data.user_display.name,
                        });
                    }
                }
                return new_array;
            },
        },

        async created() {
            this.readonlyOperationsUppgifter = !this.can({
                action: "update",
                subject: "sektionoperationsuppgifter",
            });
            this.readonlyTider = !this.can({
                action: "update",
                subject: "sektiontider",
            });
            this.readonlyWhoForberwdelse = !this.can({
                action: "update",
                subject: "sektionwhochecklistaforberedelse",
            });
            this.readonlyWhoTimeout = !this.can({
                action: "update",
                subject: "sektionwhochecklistatimeout",
            });
            this.readonlyWhoAvslutning = !this.can({
                action: "update",
                subject: "sektionwhochecklistaavslutning",
            });
            this.readonlyPersonal = !this.can({
                action: "update",
                subject: "sektionpersonal",
            });
            this.readonlyAnestesi = !this.can({
                action: "update",
                subject: "sektionanestesi",
            });
            this.readonlyMaterial = !this.can({
                action: "update",
                subject: "sektionmaterial",
            });
            this.readonlyPatientData = !this.can({
                action: "update",
                subject: "sektionpatientdata",
            });

            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            this.loading = true;

            this.typoperationer = typoperationerOptions(
                await fetchData({
                    url: "/operation/typoperationer/",
                })
            );

            let diagnoser = cvOptions(
                await fetchData({
                    url: "/core/kodverk/icdkoder/",
                })
            );

            this.huvuddiagnoser = diagnoser;
            this.$set(this.bidiagnoser, 0, diagnoser);

            let operationskoder = cvOptions(
                await fetchData({
                    url: "/operation/kodverk/operationskoder/",
                })
            );

            this.huvudoperationskoder = operationskoder;
            this.$set(this.bioperationskoder, 0, operationskoder);

            this.salar = salarOptions(
                await fetchData({
                    url: "/operation/salar/",
                })
            );

            this.avtal = avtalOptions(
                await fetchData({
                    url: "/core/avtal/",
                })
            );

            let operator = profileOptions(
                await fetchData({
                    url: "core/profiles/",
                    params: {
                        roll__code: "kirurg",
                        is_active: true,
                    },
                })
            );

            this.operatorer = operator;
            this.ass_operatorer = operator;

            let operationssjukskoterskor = profileOptions(
                await fetchData({
                    url: "core/profiles/",
                    params: {
                        roll__code: "operationssjukskoterska",
                        is_active: true,
                    },
                })
            );

            let operationsunderskoterska = profileOptions(
                await fetchData({
                    url: "core/profiles/",
                    params: {
                        roll__code: "operationsunderskoterska",
                        is_active: true,
                    },
                })
            );

            this.id_kontroll = operationssjukskoterskor;
            this.operationssjukskoterskor = operationssjukskoterskor;

            this.operationsunderskoterskor = operationsunderskoterska;

            this.anestesilakare = profileOptions(
                await fetchData({
                    url: "core/profiles/",
                    params: {
                        roll__code: "anestesilakare",
                        is_active: true,
                    },
                })
            );

            this.anestesisjukskoterskor = profileOptions(
                await fetchData({
                    url: "core/profiles/",
                    params: {
                        roll__code: "anestesisjukskoterska",
                        is_active: true,
                    },
                })
            );

            let anestesikoder = cvOptions(
                await fetchData({
                    url: "/operation/kodverk/anestesikoder/",
                })
            );

            this.huvudanestesikoder = anestesikoder;
            this.$set(this.bianestesikoder, 0, anestesikoder);

            this.rawAnestesikomplikationer = await fetchData({
                url: "/operation/kodverk/anestesikomplikationer/",
            });
            this.anestesikomplikationerKategorier = anestesikomplikationerKategorierOptions(
                this.rawAnestesikomplikationer
            );

            this.anestesikomplikationSvarighetsgrader = cvOptionsShort(
                await fetchData({
                    url: "/operation/kodverk/anestesikomplikationsvarighetsgrader/",
                })
            );

            this.operationsanmalan = await this.getOperationAnmalan(this.parameters.operationsanmalan.id);
            this.operationjournal_id = this.operationsanmalan.operation_id;
            this.isEditing = propertyExistsNotNull(this.operationjournal_id);

            if (this.isEditing) {
                const operation = await this.getOperation(this.operationsanmalan.operation_id);
                await this.setOperation(operation);
            } else {
                await this.setOperation(this.operationsanmalan);
            }

            this.loading = false;
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/style/variables.scss";

    .operationsjournal {
        width: $form-width;

        .operationsuppgifter {
            ::v-deep .formulate-input-group-repeatable {
                column-gap: 18px;
            }

            .typoperationer {
                padding: 20px;
                margin-bottom: 20px;
                background-color: $color-blue-light;
            }

            ::v-deep .formulate-input-wrapper {
                #huvuddiagnos,
                #huvudoperationskod {
                    width: 75.3%;
                }
            }

            .row {
                display: flex;
                gap: $column-gap;
                width: 75%;

                [data-type="checkbox"] {
                    align-self: end;
                }

                .anmarkning {
                    width: 100%;

                    ::v-deep textarea {
                        height: 46px;
                    }
                }
            }
        }

        .patientdata {
            h4 {
                margin: 0;
            }

            ::v-deep
                .patientdata-group
                > .formulate-input-wrapper
                > .formulate-input-group
                > .formulate-input-group-repeatable {
                display: flex;
                flex-direction: column;
            }

            .patientdata-groups {
                ::v-deep .formulate-input-group-repeatable {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }

                .formulate-input {
                    width: $column-width;
                }
            }
        }

        .personal {
            .wrapper-3-1 {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 26px;
            }

            .fritext {
                ::v-deep textarea {
                    max-width: 33.56rem;
                    max-height: 5.75rem;
                }
            }
        }

        .who-checklista {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;

            .button-link {
                height: 70px;
                width: 480px;
                padding: 15px inherit 15px 20px;

                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: stretch !important;
                text-align: left;

                small {
                    display: flex;
                    justify-content: space-between;

                    .updated {
                        font-style: italic;
                    }
                }
            }
        }

        .who-modal {
            .header {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                gap: 85px;
                margin-bottom: 30px;

                .dialog-aktiv-patient {
                    margin-right: auto;
                }

                .datum,
                .tid {
                    strong {
                        display: block;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 21px;
                        color: $color-input-help;
                    }
                }
            }

            hr {
                border: 0;
                border-bottom: 1px solid $divider-color;
                margin-bottom: 20px;
            }

            .formulate-input[data-type="checkbox"] {
                ul {
                    padding-left: 18px;
                    margin: 0;
                }
            }

            i {
                font-size: 14px;
                font-style: italic;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0;
                text-align: left;
            }

            .checkbox-group {
                p {
                    margin-bottom: 10px;
                }

                & > .formulate-input {
                    margin-bottom: 15px;
                }
            }
        }

        .material {
            .fritext {
                ::v-deep textarea {
                    max-width: 33.56rem;
                    max-height: 5.75rem;
                }
            }
        }

        .anestesi {
            ::v-deep .formulate-input-wrapper {
                .formulate-input-group-repeatable {
                    column-gap: 10px;
                }

                #anestesikomplikationer-kategori {
                    width: 230px;
                }

                #anestesikomplikationer-kod,
                #anestesikomplikationer-svarighetsgrad,
                [data-type="timepicker"] {
                    width: 230px;
                }
            }

            .anestesi-signering {
            }

            .anestesikomplikationer {
                ::v-deep .formulate-input-errors {
                    position: absolute;
                    top: 80px;
                }

                ::v-deep .formulate-input-group-add-more {
                    top: auto;
                    right: auto;
                    bottom: -10px;
                }

                ::v-deep .formulate-input-element--button {
                    margin-top: 32px;
                }
            }

            .wrapper-3-1 {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 26px;
            }

            .huvudanestesikod {
                width: calc(100% - 275px);
            }

            .forklaring {
                background-color: transparent;
                border: none;
                cursor: pointer;
                background-image: url("@/assets/link_internal.svg");
                padding-right: 44px;
                background-size: 18px 18px;
                height: auto;
                padding-left: 8px;
            }

            .komplikationsgrad-forklaring {
                .forklaringar {
                    width: 100%;
                    overflow: hidden;
                    padding: 0;
                    margin: 0;

                    dt {
                        float: left;
                        width: 15%;
                        padding: 0;
                        margin: 0 0 20px 0;
                    }

                    dd {
                        float: left;
                        width: 85%;
                        padding: 0;
                        margin: 0 0 20px 0;
                    }
                }

                .button-group {
                    margin-top: 42px;
                }
            }
        }

        .tider {
            h4 {
                margin-top: 20px;
                margin-bottom: 0;
            }

            .wrapper-3-1 {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 26px;
            }

            .fritext {
                ::v-deep textarea {
                    max-width: 33.56rem;
                    max-height: 5.75rem;
                }
            }
        }
    }
</style>
