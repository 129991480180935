<template>
    <section>
        <button
            @click.prevent="isOpen = !isOpen"
            class="flex items-center space-x-3"
            :aria-expanded="isOpen"
            :aria-controls="`collapse${_uid}`"
            style=""
        >
            <span v-if="!isOpen" class="font-semibold text-xl">Visa {{ chartData.statistiknamn }} tabell </span>
            <span v-if="isOpen" class="font-semibold text-xl">Dölj {{ chartData.statistiknamn }} tabell </span>
            <img
                src="@/assets/dropdown-arrow-white.svg"
                :class="{
                    rotate180: isOpen,
                    rotate0: !isOpen,
                }"
            />
        </button>

        <Transition name="fade">
            <div v-show="isOpen" :id="`collapse${_uid}`" class="table-container">
                <div class="buttonDiv" style="width: 50%; margin: auto; margin-bottom: 10px">
                    <div
                        style="
                            border: 1px solid rgba(255, 99, 132, 1);
                            width: 40px;
                            height: 15px;
                            background: rgba(255, 99, 132, 0.6);
                            margin-right: 5px;
                        "
                    ></div>
                    <p
                        @click.prevent="showInternalSkadekort = !showInternalSkadekort"
                        :class="{ visited: !showInternalSkadekort }"
                        type="button"
                        style="line-height: 15px"
                    >
                        {{ kundNamn }}
                    </p>
                    <div
                        style="
                            border: 1px solid rgba(132, 99, 255, 1);
                            width: 40px;
                            height: 15px;
                            background: rgba(132, 99, 255, 0.6);
                            margin-right: 5px;
                            margin-left: 5px;
                        "
                    ></div>
                    <p
                        @click.prevent="showExternalSkadekort = !showExternalSkadekort"
                        :class="{ visited: !showExternalSkadekort }"
                        type="button"
                        style="line-height: 15px"
                    >
                        {{ liga }}
                    </p>
                </div>
                <div class="table">
                    <table>
                        <thead>
                            <tr class="tr">
                                <th></th>
                                <!--                                <th v-for="header in headers_without_space" colspan="2">{{ header.title }}</th>-->
                                <th :colspan="colspanComputed">Match</th>
                                <th :colspan="colspanComputed">Träning</th>
                                <th :colspan="colspanComputed">Totalt skador</th>
                                <th :colspan="colspanComputed">Skadedagar</th>
                                <th :colspan="colspanComputed">Dagar/Skada</th>
                            </tr>
                            <tr>
                                <th></th>
                                <template v-for="n in headers_without_space">
                                    <th v-if="showInternalSkadekort" class="th-small">{{ kundNamn }}</th>
                                    <th v-if="showExternalSkadekort" class="th-small">{{ liga }}</th>
                                </template>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(item, i) in computedChartData._labels" class="tr" :key="i">
                                <td>{{ item }}</td>

                                <td v-if="showInternalSkadekort" class="tbody-data">
                                    {{ tableDataInternal["int_match"][i] }}
                                </td>
                                <td v-if="showExternalSkadekort" class="tbody-data">
                                    {{ tableDataExternal["ext_match"][i] }}
                                </td>

                                <td v-if="showInternalSkadekort" class="tbody-data">
                                    {{ tableDataInternal["int_traning"][i] }}
                                </td>
                                <td v-if="showExternalSkadekort" class="tbody-data">
                                    {{ tableDataExternal["ext_traning"][i] }}
                                </td>

                                <td v-if="showInternalSkadekort" class="tbody-data">
                                    {{ tableDataInternal["int_total"][i] }}
                                </td>
                                <td v-if="showExternalSkadekort" class="tbody-data">
                                    {{ tableDataExternal["ext_total"][i] }}
                                </td>

                                <td v-if="showInternalSkadekort" class="tbody-data">
                                    {{ tableDataInternal["int_totalSkadedagar"][i] }}
                                </td>
                                <td v-if="showExternalSkadekort" class="tbody-data">
                                    {{ tableDataExternal["ext_totalSkadedagar"][i] }}
                                </td>

                                <td v-if="showInternalSkadekort" class="tbody-data">
                                    {{ tableDataInternal["int_dagarPSkada"][i] }}
                                </td>
                                <td v-if="showExternalSkadekort" class="tbody-data">
                                    {{ tableDataExternal["ext_dagarPSkada"][i] }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Transition>
    </section>
</template>
<script setup>
import { ref, computed, toRefs } from "vue";
import statistikLokalStore from "./statistikStore.js";

const { state, getters } = statistikLokalStore();
let showInternalSkadekort = ref(true);
let showExternalSkadekort = ref(true);
const isOpen = ref(false);

const props = defineProps({
    headers: {
        type: Array,
        required: true,
    },
    tableDataInternal: {
        type: Object,
    },
    tableDataExternal: {
        type: Object,
    },
    tableDataAll: {
        type: Object,
    },
    emptyObject: {
        type: Object,
    },
    kundNamn: {
        type: String,
    },
    chartData: {
        type: Object,
    },
});

const { tableDataInternal, tableDataExternal, tableDataAll, allaSkadekort, emptyObject } = toRefs(props);

const computedChartData = computed(() => {
    let _chartData = {};
    if (showInternalSkadekort.value) {
        _chartData = tableDataInternal;
    } else {
        _chartData = tableDataExternal;
    }
    return _chartData.value;
});

const headers_without_space = computed(() => props.headers.slice(1));

let colspanComputed = computed(() => {
    let _colspan = 1;
    if (showInternalSkadekort.value && showExternalSkadekort.value) {
        _colspan = 2;
    }
    return _colspan;
});

const liga = computed(() => state.skreg.statistik.liga);
</script>

<style lang="scss" scoped>
@import "../../../style/variables";

section {
    button {
        width: 100%;
        height: 40px;
        position: relative;
        justify-content: space-between;
        justify-items: baseline;
        background-color: #38a7cf;
        border: none;
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
        margin: 50px 0;
        border-radius: 5px;
        &:hover {
            background-color: #026687;
            box-shadow: 0px 2px 5px 2px rgba(113, 110, 110, 0.2);
            //color: rgb(110, 105, 105);
            font-weight: bold;
        }

        img {
            position: absolute;
            right: 10px;
            margin: auto;
        }
        .rotate180 {
            transform: rotate(180deg);
        }
        .rotate0 {
            transform: rotate(0deg);
        }
        font-weight: bold;
        color: snow;
    }

    .table-container {
        width: 100%;
        padding-top: 15px;
        .buttonDiv {
            display: flex;
            justify-content: center;

            p.visited {
                text-decoration: line-through;
            }

            p {
                margin: 0;
            }
        }
        table {
            width: 100%;
            th {
                color: $color-thead;
                font-family: Roboto;
                letter-spacing: 0;
                line-height: 34px;
                font-weight: bold;
                font-size: large;
                text-align: center;
            }
            .th-small {
                color: $color-thead;
                font-family: Roboto;
                letter-spacing: 0;
                line-height: 15px;
                font-weight: bold;
                font-size: small;
                text-align: center;
            }
            .tbody-data {
                text-align: center;
            }
        }
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
}
</style>
