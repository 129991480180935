<template>
    <!--
        InputWrapper är en wrapper-component för alla typer av inputs. Den ska normalt bara användas i ett <form k3-form>, och syftar framförallt till att skapa CSS för:

        1) flex (via colspan)
        2) rubriker (och i synnerhet obligatoriska rubriker)
        3) felmeddelanden och varningsmeddelanden
        4) erbjuda highlight när hela komponenten är i fokus
        5) default-validering

        Inputwrapper stöder både v-model och eventhubbar för direkt och indirekt two-way data binding (det senare behövs bl.a. i journalanteckningar).
    -->
    <div
        :class="'input-wrapper colspan c' + normalizedColspan"
        @focusin="setBackground"
        @focusout="clearBackground"
        :style="{ 'background-color': backgroundColor }"
    >
        <!-- Rubrik för input, ska inte visas för checkboxar eller vissa hopfällbara fält i journalanteckningar -->
        <template v-if="!collapsable && !checkboxLabel">
            <span
                v-if="requiredLabel"
                :class="{ error: error_, warning: warning_ }"
                :style="disabled ? 'opacity: 0.4' : ''"
                >{{ requiredLabel }}</span
            >
            <span v-else>&nbsp;</span>
        </template>

        <!-- Hopfällbara fält med undantag för checkboxar i journalanteckningar -->
        <button
            v-else-if="!checkboxLabel"
            type="button"
            @click="
                expanded = !expanded;
                collapsable();
            "
            class="label-button"
        >
            <img :class="{ rotate: !expanded }" src="@/assets/dropdown-arrow-blue.svg" />
            {{ requiredLabel }}
        </button>

        <!-- Checkboxar visar inte rubrik alls. -->
        <span v-else-if="showEmptyLabel">&nbsp;</span>
        <span class="definition" v-if="definition">{{ definition }}</span>

        <!--
            Den dynamiska komponent som ska renderas. Notera att v-bind="$attrs" skickar vidare allt som inte definierats som props i wrappern till komponenten, t.ex. disabled.
            Det är snyggast att lägga till props för :map och annat som inte ska visas i klartext i den generade html:en.
        -->
        <component
            :is="component"
            :name="name"
            :value="value_"
            :object-value="objectValue"
            @input="valueChanged($event)"
            v-bind="$attrs"
            :required="normalizedRequired"
            :min="normalizedMin"
            :max="normalizedMax"
            :autocomplete="autocomplete"
            :class="{ error: error_, warning: warning_ }"
            :label="checkboxLabel"
            @blur="inputHasHadFocus = true"
            @warning="setWarningMessage($event)"
            @error="setErrorMessage($event)"
            @update="objectValueChanged($event)"
            :preview="preview"
            :optionalArgs="optionalArgs"
            :style="disabled ? 'opacity: 0.4' : ''"
            :disabled="disabled"
        />

        <!-- Enkelt felmeddelande -->
        <span class="error" v-if="error_">{{ error_ }}</span>

        <!-- Show empty row below for checkboxes in forms-->
        <span v-if="showEmptyLabel && checkboxLabel">&nbsp;</span>

        <!-- Enkelt varningsmeddelande -->
        <template v-if="warning_ && !expandedWarning">
            <img src="@/assets/warning_dark_contour.svg" style="float: left; margin-right: 3px; margin-top: 1px" />
            <span :class="{ warning: true, suggest: suggest }">{{ warning_ }}</span>
        </template>

        <div v-if="information_" style="margin-top: 8px">
            <img src="@/assets/info_dark.svg" style="opacity: 0.4; float: left; margin-right: 3px; margin-top: 1px" />
            <span :class="{ information: true, suggest: suggest }">{{ information_ }}</span>
        </div>

        <!-- Utökat varningsmeddelande (används ffa i Recept för förmånsbegränsningar). TODO: gör detta generiskt. -->
        <div
            class="expanded-warning suggest"
            v-if="warning_ && expandedWarning"
            @click="collapseWarning = !collapseWarning"
        >
            <div>
                <span class="expanded-warning-icon">!</span>
            </div>
            <div>
                Observera att varan är förmånsbegränsad. Ange i doseringsanvisningen om förmånen uppfylls eller ej.
                <span v-if="!collapseWarning">{{ warning_ }}</span>
            </div>
            <div><img :class="{ rotate: !collapseWarning }" src="@/assets/dropdown-arrow.svg" /></div>
        </div>
    </div>
</template>
<script>
/**
 * InputWrapper is a wrapper around generic widgets for use in forms. It contains CSS for displaying widgets in a flexbox and
 * logic to change appearance to show error messages if e.g. a component is required and empty.
 */
import linkEvents from "@/linkEvents";

export default {
    name: "InputWrapper",
    props: {
        showEmptyLabel: Boolean,
        colspan: {
            type: undefined,
            default: 2, // definierar bredden i form av antal kolumner (x av 8), antingen fast bredd eller relativ, se _main.sass.
        },
        name: String,
        value: undefined, // Note: value is decoupled from value_ to work with eventHub intercepting the two-way data binding.
        definition: String,
        label: String,
        component: Object,
        error: String,
        warning: String,
        information: String,
        highlightColor: String,
        collapsable: {
            type: Function,
            default: undefined,
        },
        checkboxLabel: String,
        sokordParameters: Object, // Used in journalanteckningar to avoid destructuring parameters breaking reactivity.
        required: undefined, // String or Boolean, use normalizedRequired instead to always get Boolean
        autocomplete: {
            // Default should prevent Chrome autofilling field
            type: String,
            default: "off",
        },
        eventHub: Object, // The eventHub object
        preview: Boolean,
        min: undefined, // string or int
        max: undefined, // string or int
        optionalArgs: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        disabled: Boolean,
    },
    data() {
        return {
            expanded: true,
            defaultErrorMessage: "Ange text",
            customErrorMessage: null,
            customWarningMessage: null,
            inputHasHadFocus: false,
            expandedWarning: false,
            collapseWarning: true,
            suggest: false,
            value_: this.value,
            backgroundColor: "",
            objectValue: null,
        };
    },
    computed: {
        error_() {
            if (this.customErrorMessage) return this.customErrorMessage;
            else if (this.error) return this.error;
            else if (this.normalizedRequired && this.emptyValue && this.inputHasHadFocus)
                // value can be "false"
                return this.defaultErrorMessage;
            return null;
        },
        warning_() {
            if (this.customWarningMessage) return this.customWarningMessage;
            else if (this.warning) return this.warning;
            return null;
        },
        information_() {
            if (this.information) return this.information;
            else return null;
        },
        requiredLabel() {
            if (this.label === null || this.label === undefined) return null;
            return this.label + (this.required ? " *" : "");
        },
        normalizedRequired() {
            return this.required === "true" || this.required === true;
        },
        normalizedColspan() {
            return new String(this.colspan);
        },
        normalizedMin() {
            return Number.parseInt(this.min);
        },
        normalizedMax() {
            return Number.parseInt(this.max);
        },
        emptyValue() {
            if (this.value_ === null || this.value_ === undefined || this.value_ === "") return true;
            else if (Array.isArray(this.value_)) {
                let anyEmpty = false;
                if (this.value_.length === 0) return true;
                for (let i = 0; i < this.value_.length; i++) {
                    if (this.value_[i] === "") {
                        anyEmpty = true;
                    }
                }
                return anyEmpty;
            }
            return false;
        },
    },
    methods: {
        // invoked on @warning/@error to show a custom warning or error message

        // The value should be boolean
        setInputHasHadFocus(value) {
            this.inputHasHadFocus = value;
        },
        setErrorMessage(value) {
            this.customErrorMessage = value;
        },
        setWarningMessage(value) {
            this.customWarningMessage = value;
        },

        // set or clear custom highlight background color
        setBackground() {
            this.backgroundColor = this.highlightColor;
        },
        clearBackground() {
            this.backgroundColor = "";
        },

        // part of default validation, not pretty but leave as is for now
        setFocus(e) {
            e.preventDefault();
            document.getElementsByName(this.name)[0].focus();
        },
        setFocusDisplay(e) {
            e.preventDefault();
            document.getElementsByName(this.name + "Display")[0].focus();
        },

        // part of eventHub mechanic
        valueChanged(value) {
            this.value_ = value;
            this.$emit("input", this.value_);
            if (this.eventHub) this.eventHub.$emit(this.name + "_changed", this.value_);
        },
        objectValueChanged(value) {
            this.objectValue = value;
            this.$emit("update", value);
        },
        updateValue(value) {
            this.value_ = value;
        },
    },
    mounted() {
        // set default values for error messages for specific component types
        switch (this.component.name) {
            case "RangeWidget":
                this.defaultErrorMessage = "Ange värde";
                break;
            case "SelectWidget":
                this.defaultErrorMessage = "Välj alternativ";
                break;
            case "DosageSuggestTextArea":
                this.expandedWarning = true;
                this.suggest = true;
                break;
            case "SuggestTextArea":
                this.suggest = true;
                break;
        }

        linkEvents.$on(this.name + "_changed", this.valueChanged);
        linkEvents.$on("update_" + this.name, this.updateValue);
        linkEvents.$on(this.name + "_error", this.setErrorMessage);
        linkEvents.$on(this.name + "_warning", this.setWarningMessage);
        linkEvents.$on(this.name + "_focus", this.setInputHasHadFocus);

        if (this.eventHub) this.eventHub.$on("update_" + this.name, this.updateValue);

        if (this.preview) return;
        /**
         * Default validation. This should normally never be used since all forms should implement custom validation and have submit turned off.
         * Included for legacy and quick-and-dirty forms with rudimentary validation.
         */
        // These components utilizes hidden inputs with the actual name and name+'Display' for the visible input
        if (
            this.component.name == "PersonIdWidget" ||
            this.component.name == "SelectWidget" ||
            this.component.name == "SuggestWidget" ||
            this.component.name == "InfiniteWidget"
        )
            document.getElementsByName(this.name + "Display")[0].addEventListener("invalid", this.setFocusDisplay);
        else {
            let input = document.getElementsByName(this.name)[0];
            if (input) {
                input.addEventListener("invalid", this.setFocus);
            }
        }
    },

    beforeDestroy() {
        linkEvents.$off(this.name + "_changed", this.valueChanged);
        linkEvents.$off("update_" + this.name, this.updateValue);
        linkEvents.$off(this.name + "_error", this.setErrorMessage);
        linkEvents.$off(this.name + "_warning", this.setWarningMessage);
        linkEvents.$off(this.name + "_focus", this.setInputHasHadFocus);
    },
    watch: {
        value() {
            this.value_ = this.value;
        },
    },
};
</script>
<style lang="sass" scoped>
@import "@/style/variables"

/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

@import "bootstrap/scss/forms"
/* Bootstrap - End */

@import "@/style/deprecated_main"

.input-wrapper
    padding-bottom: 12px
    margin-bottom: 20px
    position: relative

    .label-button
        height: 21px
        font-weight: 400
        color: #277692
        font-size: 20px
        line-height: 21px
        border: none
        outline: none
        background-color: inherit
        display: block
        padding-top: 0px
        margin-top: 20px

    .definition
        height: 21px
        color: #7F8FA4
        font-size: 14px
        letter-spacing: 0
        line-height: 21px
        display: block
        margin-bottom: 5px

    .expanded-warning
        box-sizing: border-box
        border: 1px solid #EFC2D4
        border-radius: 3px
        background-color: #F7E1E9
        color: #354052
        display: flex
        padding: 10px 0px 10px 0px

        &.suggest
            width: calc(100% - 150px)

        span
            font-size: 14px
            color: #354052
            white-space: normal
            display: block

        .expanded-warning-icon
            padding-left: 5.5px
            padding-top: 2px
            color: #FFF
            font-size: 12px
            line-height: 12px
            font-weight: bold
            height: 14px
            width: 14px
            border-radius: 7px
            border: none
            background-color: #354052
            margin-top: 5px
            margin-left: 5px
            margin-right: 10px

        img
            margin-top: 25px
            margin-left: 5px
            margin-right: 10px

        &.rotate
            transform: rotate(180deg)

    > button
        img
            height: 12px
            width: 12px
            margin-right: 5px
            margin-top: -5px

            &.rotate
                transform: rotate(180deg)

    span
        color: #728296
        font-size: 16px
        font-weight: 500
        line-height: 21px
        display: block
        margin-bottom: 2px
        position: relative
        white-space: normal

        &.error
            color: #9E354B

        &.error:not(:first-of-type)
            font-size: 14px
            line-height: 21px
            font-weight: 400
            //margin-top: -32px
            margin-left: 16px

            &::before
                content: '!'
                padding-left: 5.5px
                padding-top: 2px
                color: #FFF
                font-size: 12px
                line-height: 12px
                font-weight: bold
                height: 14px
                width: 14px
                border-radius: 7px
                border: none
                background-color: #9E354B
                position: absolute
                left: -16px
                top: 2px

        &.warning:not(:first-of-type)
            color: #354052
            font-size: 14px
            line-height: 21px
            font-weight: 400

            &.suggest
                white-space: normal
                width: calc(100% - 150px)
        //overflow: hidden
        //text-overflow: ellipsis

        &.information
            color: #728296
            font-size: 14px
            letter-spacing: 0
            line-height: 19px

.k3-form

    .error
        input, textarea, select
            border-bottom: 4px solid #9E354B !important

    input, select, textarea
        &.error
            border-bottom: 4px solid #9E354B !important

    textarea
        min-height: 81px
</style>
