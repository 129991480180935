import { getConfig, klinikenApi } from "@/api";
import {
    EVENT_COLORS,
    FullCalenderEvent,
    getHexFromRgb,
    getTextFromBaseColor,
    OperationSanitizer,
    OplaneradOperation,
    Services,
} from "@/tabs/Operation/Kalender";

const getDefaultState = () => {
    return {
        events: [],
        operationSalar: [],
        planeringar: [],
        unPlannedOperations: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setOperationSalar(state, newValue) {
            state.operationSalar = newValue;
        },
        setEvents(state, newValue) {
            state.events = newValue;
        },
        setPlaneringar(state, newValue) {
            state.planeringar = newValue;
        },

        addNewPlanering(state, planering) {
            planering.backgroundColor = getHexFromRgb(EVENT_COLORS[state.events.length % 3]);
            planering.textColor = getHexFromRgb(EVENT_COLORS[state.events.length % 3]);
            state.planeringar.push(planering);
        },

        deleteEvent(state, id) {
            const eventIndex = state.todos.findIndex((v) => v.id === id);
            this.setEvents(state.events.splice(eventIndex, 1));
        },

        addNewEvent(state, event) {
            state.events.push(event);
        },

        setUnPlannedOperations(state, newValue) {
            state.unPlannedOperations = newValue;
        },

        deleteUnPlannedOperation(state, id) {
            const unPlannedOperationIndex = state.unPlannedOperations.findIndex((v) => v.pk === id);

            state.unPlannedOperations.splice(unPlannedOperationIndex, 1);
        },

        resetUnPlannedOperations(state) {
            state.unPlannedOperations = [];
        },

        resetEvents(state) {
            state.events = [];
        },
    },
    getters: {
        getOperationSalar(state) {
            return state.operationSalar;
        },
        getEvents(state) {
            return state.events;
        },
        getPlaneringar(state) {
            return state.planeringar;
        },
        findEvent(state, id) {
            return state.events.find((v) => v.id === id);
        },
        newTempId(state) {
            return Math.max(0, ...state.events.map((item) => item.id || 0)) + 1;
        },

        getUnPlannedOperations(state) {
            let filteredUnPlannedOperations = [];
            let hasDuplicates = false;

            for (const unPlannedOperation of state.unPlannedOperations) {
                for (const event of state.events) {
                    if (unPlannedOperation.pk === event.id) {
                        hasDuplicates = true;
                    }
                }

                if (hasDuplicates === false) {
                    filteredUnPlannedOperations.push(unPlannedOperation);
                }
            }

            return filteredUnPlannedOperations;
        },
    },
    actions: {
        async addEvent(context, args) {
            const patient = args.operation_patient;
            const operationstid_start = args.operation_start;
            const operationstid_slut = args.operation_slut;
            const operation_anmalan = args.operation_anmalan;
            const operation_sal = args.operation_sal;
            const unplanned_operation = args.unplanned_operation;

            const services = new Services();
            const response = await services.postEnPlaneradOperation(
                patient,
                operationstid_start,
                operationstid_slut,
                operation_anmalan,
                operation_sal ? parseInt(operation_sal.id) : null
            );

            const unique_id = context.getters.getEvents.length + 2;

            const event = {
                id: response.data.id,
                backgroundColor: getHexFromRgb(EVENT_COLORS[unique_id % 3]),
                textColor: getHexFromRgb(getTextFromBaseColor(EVENT_COLORS[unique_id % 3])),
                start: new Date(operationstid_start),
                end: new Date(operationstid_slut),
                title: unplanned_operation.personId + " " + unplanned_operation.code,
                startEditable: true,
                durationEditable: true,
                extendedProps: {
                    preparationTime: unplanned_operation.forberedelsetid,
                    decommissionTime: unplanned_operation.avvecklingstid,
                    patient_id: response.data.patient,
                },
                resourceId: operation_sal ? parseInt(operation_sal.id) : null,
                resourceEditable: true,
            };

            context.commit("addNewPlanering", event);
        },

        async fetchOperationSalar({ commit }) {
            await klinikenApi
                .get("/operation/salar/", getConfig())
                .then((response) => {
                    let list = [];
                    response.data.results.map((item) => {
                        let newOperation = new OperationSanitizer(item);
                        list.push(newOperation);
                    });
                    commit("setOperationSalar", list);
                })
                .catch((e) => {
                    // eslint-disable-next-line no-console
                    console.error(e);
                });
        },

        async fetchAllaOperationAnmalan({ commit }) {
            const services = new Services();
            let data = [];
            let oplaneradOperation;
            let oplaneradOperationer = [];
            let patient_id;

            data = await services.hämtaAllaOplaneradeOperationer();

            data.map((opl) => {
                oplaneradOperation = new OplaneradOperation(
                    opl.id,
                    opl.patient.personId,
                    opl.huvudoperationskod.code,
                    opl.forberedelser,
                    opl.avveckling,
                    opl.optid,
                    opl.patient.id
                );
                patient_id = opl.patient.id;
                oplaneradOperationer.push(oplaneradOperation.toJSON());
            });

            const allPlaneringar = await services.hämtaAllaPlaneradOperationer(patient_id);

            const events = allPlaneringar.map((element, index) => {
                const anmalan = element.anmalan || null;

                const huvudoperationskod = anmalan && anmalan.huvudoperationskod;
                const code = huvudoperationskod ? huvudoperationskod.code : "";

                const patient = element && element.patient;
                const personId = patient ? patient.personId : "";

                const forberedelser = anmalan.forberedelser || NaN;
                const avveckling = anmalan.forberedelser || NaN;

                const operationstid_start = element.operationstid_start || "";
                const operationstid_slut = element.operationstid_slut || "";
                const patient_id = element.patient.id;

                return new FullCalenderEvent({
                    id: element.id,
                    backgroundColor: getHexFromRgb(EVENT_COLORS[index % 3]),
                    textColor: getHexFromRgb(getTextFromBaseColor(EVENT_COLORS[index % 3])),
                    start: new Date(operationstid_start),
                    end: new Date(operationstid_slut),
                    title: personId + " " + code,
                    startEditable: true,
                    durationEditable: true,
                    extendedProps: {
                        preparationTime: forberedelser,
                        decommissionTime: avveckling,
                        patient_id,
                    },
                    resourceId: element.sal ? element.sal.toString() : null,
                    resourceEditable: true,
                });
            });

            commit("setEvents", events);
            commit("setPlaneringar", events);

            commit("setUnPlannedOperations", oplaneradOperationer);
        },

        async updatePlaneradOperation(_context, { id, operationstid_start, operationstid_slut, sal, patient }) {
            const services = new Services();
            return await services.uppdateraExisterandePlaneradOperation(
                id,
                operationstid_start,
                operationstid_slut,
                sal,
                patient
            );
        },
    },
};
