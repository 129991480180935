<template>
    <div class="signeringsansvarig">
        <div class="left-div">
            <FormulateInput
                v-if="isSignable"
                :readonly="readonly"
                :options="signeringsansvariga"
                v-model="signeringsansvarig"
                type="vueselect"
                label="Signeringsansvarig"
            ></FormulateInput>
            <ButtonGroup class="buttonGroup">
                <Button
                    v-if="isSignable"
                    :disabled="!isSigneringsansvarig || isSigned || readonly"
                    class="spara"
                    variant="standard"
                    size="secondary"
                    @click.prevent="emitButtonAction('sign')"
                    type="submit"
                >
                    <img src="@/assets/sign_green.svg" alt="" />
                    <span>Spara och signera</span>
                </Button>
                <Button
                    :disabled="isSigned || readonly"
                    variant="standard"
                    size="small"
                    class="spara"
                    @click.prevent="emitButtonAction('save')"
                    type="submit"
                >
                    <img v-if="loading" class="rotate" src="@/assets/loader.svg" alt="" />
                    <span v-else>Spara</span>
                </Button>
            </ButtonGroup>
        </div>
        <div class="right-div" v-if="isSigned">
            <img src="@/assets/sign_green.svg" alt="" />
            <p>Signerad</p>
        </div>
    </div>
</template>

<script setup>
    import { computed, onMounted, ref, toRefs, watch } from "vue";
    import { getConfig, klinikenApi } from "@/api";
    import Button from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";

    const props = defineProps([
        "isSignable",
        "currentProfile",
        "isSigned",
        "loading",
        "operationsjournalid",
        "orgunit",
        "legalAuthenticator",
        "accountableHealthcareProfessional",
        "editing",
        "readonly",
    ]);

    const { isSignable, currentProfile, isSigned, loading, orgunit, accountableHealthcareProfessional } = toRefs(props);

    const _loading = ref(false);
    const signeringsansvariga = ref([]);

    const emit = defineEmits(["emitButtonAction"]);
    let signeringsansvarig = ref(null);

    const isSigneringsansvarig = computed(() => {
        return signeringsansvarig.value !== null && signeringsansvarig.value === currentProfile.value.pk;
    });

    function emitButtonAction(button) {
        _loading.value = true;
        emit("emitSigneringsansvarigaAction", { button, signeringsansvarig: signeringsansvarig.value });
    }

    watch(accountableHealthcareProfessional, async (newVal, oldVal) => {
        if (signeringsansvarig.value === "" || signeringsansvarig.value === null) {
            signeringsansvarig.value = newVal;
        }
        await getSigneringsansvariga();
    });

    onMounted(async () => {
        signeringsansvarig.value = accountableHealthcareProfessional.value;
        await getSigneringsansvariga();
    });

    async function getSigneringsansvariga() {
        const response = await klinikenApi.get(
            "/core/profiles/",
            getConfig({
                params: { signeringsansvarig: true, orgunit: orgunit },
            })
        );
        signeringsansvariga.value = response.data.results
            .filter((personal) => {
                return personal.is_active === true || signeringsansvarig.value === personal.pk;
            })
            .map((personal) => {
                return {
                    value: personal.pk,
                    label: personal.user_display.name,
                };
            });
    }
</script>

<style lang="scss" scoped>
    @import "@/style/variables";

    .signeringsansvarig {
        margin-top: 2.375rem;
        background-color: $color-grey-bg;
        display: flex;
        width: 100%;
        justify-content: space-between;
        position: relative;
        padding: 0.8rem;

        .left-div {
            flex: 1;
            display: flex;

            ::v-deep(.formulate-input [role="combobox"]) {
                height: 38px;

                .vs__selected {
                    top: 7px;
                }
            }

            .buttonGroup {
                margin: auto 0.5rem 0.5rem 1.4rem;

                button {
                    align-self: flex-end;
                    position: relative;

                    img {
                        width: 20px;
                        position: sticky;
                        color: red;
                        z-index: 1;
                        filter: invert(42%) sepia(93%) saturate(2%) hue-rotate(118deg) brightness(159%) contrast(309%);
                    }
                }

                .button-standard {
                    height: 38px;
                }

                .button-small {
                    height: 30px;
                }
            }
        }

        .right-div {
            width: auto;
            position: absolute;
            bottom: 0.4rem;
            right: 1.2rem;
            font-size: 0.9rem;
            display: flex;

            p {
                color: $color-input-text;
            }

            img {
                margin-right: 5px;
                margin-bottom: 0.8rem;
            }
        }

        .rotate {
            animation: rotation 1s infinite linear;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(359deg);
            }
        }
    }
</style>
