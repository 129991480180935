<template>
    <div class="op-salar">
        <admin-lock-border />
        <div class="header">
            <h1>Opsal</h1>
            <CustomButton type="button" variant="hollow" @click="openCreateSal">
                <img src="@/assets/plus_circle_blue.svg" />
                <span>Skapa ny opsal</span>
            </CustomButton>
        </div>

        <kliniken-table
            v-if="salarList.results"
            :items="salarList.results"
            paginationStyle="paging"
            :columns="columns"
            :count="count"
            :limit="limit"
            :currentPage="currentPage"
            @loadPage="loadPage"
            @changeLimit="changeLimit"
            @loadMore="loadMore"
            :showBottom="true"
            :showLimitDropdown="true"
            :has-dropdown="true"
            :dropdown-actions="dropdownActions"
            @editSal="editSalFromTable"
        />
    </div>
</template>

<script>
    import tabIcon from "@/assets/blank.png";

    export default {
        name: "Opsalar",
        tabName: "Opsalar",
        icon: tabIcon,
    };
</script>

<script setup>
    import { onMounted, ref, onBeforeUnmount } from "vue";
    import emitter from "tiny-emitter/instance";
    import { openDialog } from "@/utils";
    import AdminLockBorder from "@/components/AdminLockBorder.vue";
    import { getConfig, klinikenApi } from "@/api";
    import KlinikenTable from "@/components/Table/KlinikenTable.vue";
    import CustomButton from "@/components/Button.vue";
    import store from "@/store";
    import Opsal from "@/tabs/Admin/Opsal.vue";

    const salarList = ref([]);
    const count = ref(0);
    const columns = ref([
        {
            title: "Namn",
            name: "namn",
        },
        {
            title: "Ordningsnummer",
            name: "ordning",
        },
    ]);
    const dropdownActions = ref([
        {
            name: "editSal",
            title: "Redigera opsal",
            show: () => true,
        },
    ]);

    const limit = ref(20);
    const offset = ref(0);
    const fetchBatch = ref(1);
    const params = ref({});
    const currentPage = ref(1);

    const loadPage = async (page) => {
        currentPage.value = page;
        offset.value = (page - 1) * limit.value;
        await fetchSalarList();
    };

    const loadMore = async () => {
        fetchBatch.value++;
        await this.fetchSalarList();
    };

    const changeLimit = async (dropdownLimit) => {
        fetchBatch.value = 1;
        limit.value = dropdownLimit;
        await fetchSalarList();
    };

    const fetchSalarList = async () => {
        params.value = { ...params.value, limit: limit.value, offset: offset.value };
        if (limit.value > 0) {
            params.value["limit"] = limit.value * fetchBatch.value;
        }
        try {
            const response = await klinikenApi.get("/operation/salar/", getConfig({ params: { ...params.value } }));
            salarList.value = response.data;
            count.value = response.data.count;
        } catch (error) {
            openDialog("Kunde inte hämta Operationsalar.", "warning");
        }
    };
    const openCreateSal = () => {
        store.dispatch("tabs/openTab", {
            component: Opsal,
            parameters: { redigera: false },
        });
    };
    const editSalFromTable = (sal) => {
        store.dispatch("tabs/openTab", {
            component: Opsal,
            parameters: {
                redigera: true,
                salId: sal.id,
                ordning: sal.ordning,
                namn: sal.namn,
            },
        });
    };

    onMounted(() => {
        fetchSalarList();
        emitter.on("opsalar-uppdaterad", fetchSalarList);
    });

    onBeforeUnmount(() => {
        emitter.off("opsalar-uppdaterad", fetchSalarList);
    });
</script>
<style lang="scss">
    .op-salar {
        .header {
            h1 {
                margin: 0;
            }

            display: flex;
            gap: 20px;
            align-items: center;
        }
    }
</style>
