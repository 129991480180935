<template>
    <div class="info-bar">
        <div class="left">
            <slot></slot>
        </div>

        <div class="right">
            <slot name="right"> </slot>
            <actions-dropdown v-if="actions" :actions="actions" @dropdownAction="dropdownAction" />
        </div>
    </div>
</template>
<script>
    import ActionsDropdown from "@/components/generic/ActionsDropdown.vue";

    export default {
        name: "InfoBar",

        components: {
            ActionsDropdown,
        },

        props: {
            actions: {
                type: Array,
                required: false,
                default: function () {
                    return [];
                },
            },
        },

        methods: {
            dropdownAction(event, args) {
                this.$emit(event, args);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/style/variables";

    .info-bar {
        border-radius: 4px;
        padding: 10px;
        background-color: $color-tablerow;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1300px;
        .left {
            font-size: 14px;
            font-weight: 500;
            color: $tabs-text-color;
        }
        .right {
            display: flex;
            align-items: center;
            gap: 17px;
            font-size: 14px;
            font-weight: 500;
            color: $tabs-text-color;
        }
        ::v-deep(.dropdown-icon) {
            margin-right: 0px;
        }
    }
</style>
