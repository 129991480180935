<template>
    <svg
        width="68px"
        height="64px"
        viewBox="0 0 68 64"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g id="Uppmärksamhetssignal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Uppmärksamhetssignaler" transform="translate(-897.000000, -696.000000)">
                <g transform="translate(896.000000, 693.000000)">
                    <g transform="translate(1.940504, 4.000000)" fill="#FFFFFF">
                        <polygon
                            id="vit-fyllning-copy"
                            points="22.7228602 14 22.7228602 0 43.6408819 0 43.6408819 14.2390747 56.8643484 6.89211718 66.0594962 23.9113714 54.3184326 31 66.0594962 38 56.8643484 54.6586311 43.7677351 47.4316625 43.7677351 62 22.7228602 62 22.7228602 47.4316625 9.63379437 54.6586311 0.920351788 38 12.6979678 31 1.53967781e-11 23.9113714 9.63379437 6.89211718"
                        ></polygon>
                    </g>
                    <g id="Group" transform="translate(1.940504, 4.000000)" stroke="#FFFFFF" stroke-linejoin="round">
                        <g id="UI/signal-vit-kontur">
                            <polygon
                                id="vit-fyllning-copy"
                                points="22.7228602 14 22.7228602 0 43.6408819 0 43.6408819 14.2390747 56.8643484 6.89211718 66.0594962 23.9113714 54.3184326 31 66.0594962 38 56.8643484 54.6586311 43.7677351 47.4316625 43.7677351 62 22.7228602 62 22.7228602 47.4316625 9.63379437 54.6586311 0.920351788 38 12.6979678 31 1.53967781e-11 23.9113714 9.63379437 6.89211718"
                            ></polygon>
                        </g>
                    </g>
                    <g
                        id="Group"
                        transform="translate(1.940504, 4.000000)"
                        fill="#FFFFFF"
                        stroke="#979797"
                        stroke-linejoin="round"
                    >
                        <g id="UI/signal-grå-kontur">
                            <polygon
                                id="vit-fyllning-copy"
                                points="22.7228602 14 22.7228602 0 43.6408819 0 43.6408819 14.2390747 56.8643484 6.89211718 66.0594962 23.9113714 54.3184326 31 66.0594962 38 56.8643484 54.6586311 43.7677351 47.4316625 43.7677351 62 22.7228602 62 22.7228602 47.4316625 9.63379437 54.6586311 0.920351788 38 12.6979678 31 1.53967781e-11 23.9113714 9.63379437 6.89211718"
                            ></polygon>
                        </g>
                    </g>
                    <g
                        v-if="alertInformationSymbol.unstructured"
                        class="unstructured"
                        id="UI/signal-1"
                        transform="translate(3.836442, 14.364883)"
                        fill="#F01616"
                    >
                        <polygon
                            id="Rectangle-Copy-5"
                            transform="translate(13.000000, 10.745014) rotate(-60.000000) translate(-13.000000, -10.745014) "
                            points="6 2 20 2 20 15.0522842 12.8825774 19.4900272 6 15.0522842"
                        ></polygon>
                    </g>
                    <g
                        v-if="alertInformationSymbol.communicableDisease"
                        class="communicableDisease"
                        id="UI/signal-3"
                        transform="translate(3.836442, 33.689671)"
                        fill="#F7A71C"
                    >
                        <polygon
                            id="Rectangle-Copy-7"
                            transform="translate(13.000000, 10.745014) scale(1, -1) rotate(-60.000000) translate(-13.000000, -10.745014) "
                            points="6 2 20 2 20 15.0522842 12.8825774 19.4900272 6 15.0522842"
                        ></polygon>
                    </g>
                    <g
                        v-if="alertInformationSymbol.treatment || alertInformationSymbol.seriousDisease"
                        class="treatmentAndDiseases"
                        id="UI/signal-2"
                        transform="translate(40.673402, 14.364883)"
                        fill="#ED1C24"
                    >
                        <polygon
                            id="Rectangle-Copy-6"
                            transform="translate(13.000000, 10.745014) scale(-1, 1) rotate(-60.000000) translate(-13.000000, -10.745014) "
                            points="6 2 20 2 20 15.0522842 12.8825774 19.4900272 6 15.0522842"
                        ></polygon>
                    </g>
                    <g
                        v-if="alertInformationSymbol.restrictionOfCare"
                        class="restrictionOfCare"
                        id="UI/signal-4"
                        transform="translate(40.673402, 33.689671)"
                        fill="#517EEA"
                    >
                        <polygon
                            id="Rectangle-Copy-8"
                            transform="translate(13.000000, 10.745014) scale(-1, -1) rotate(-60.000000) translate(-13.000000, -10.745014) "
                            points="6 2 20 2 20 15.0522842 12.8825774 19.4900272 6 15.0522842"
                        ></polygon>
                    </g>
                    <g
                        v-if="alertInformationSymbol.hyperSensitivity"
                        class="hyperSensitivity"
                        id="UI/signal-5"
                        transform="translate(28.000000, 48.000000)"
                        fill="#F01616"
                    >
                        <circle id="Oval" cx="7" cy="7" r="7"></circle>
                    </g>
                    <g
                        v-if="
                            alertInformationSymbol.degreeOfSeverity === 2 ||
                            alertInformationSymbol.degreeOfSeverity === 3
                        "
                        class="sensDegree2"
                        id="UI/signal-6"
                        transform="translate(28.000000, 30.000000)"
                        fill="#F01616"
                    >
                        <rect id="Rectangle" x="0" y="12" width="14" height="4"></rect>
                        <rect id="Rectangle-Copy-9" x="0" y="6" width="14" height="4"></rect>
                        <rect id="Rectangle-Copy-10" x="0" y="0" width="14" height="4"></rect>
                    </g>
                    <g
                        v-if="alertInformationSymbol.degreeOfSeverity === 3"
                        class="sensDegree3"
                        id="UI/signal-7"
                        transform="translate(28.000000, 8.000000)"
                        fill="#F01616"
                    >
                        <rect id="Rectangle-Copy-11" x="0" y="0" width="14" height="20"></rect>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "AlertSymbol",
    props: ["alertInformationSymbol"],
};
</script>

<style scoped></style>
