<template>
    <div class="intygMain">
        <form @submit.prevent id="intyg-registration-details">
            <h2 v-if="!formData.pk">Skapa intyg</h2>
            <h2 v-else>Redigera intyg</h2>

            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">1</div>
                    </div>
                    <div class="vr"></div>
                </div>
                <div>
                    <h3 class="left-pad">Välj typ av intyg</h3>
                    <div class="k3-sub-form k3-card inputs">
                        <div style="width: 250px" class="inputOne">
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Ansvarig vårdpersonal"
                                name="accountableHealthcareProfessional"
                                v-model="formData.accountableHealthcareProfessional"
                                :required="true"
                                :options="accountableHealthCareProfessionals"
                                :colspan="4"
                                :map="
                                    (item) => {
                                        return { value: item.value, text: item.text };
                                    }
                                "
                            />
                        </div>
                        <div class="inputTwo">
                            <input-wrapper
                                :component="widgets.SelectWidget"
                                label="Typ av intyg"
                                name="typ"
                                :colspan="12"
                                v-model="formData.typ"
                                :required="true"
                                apisearch="/intyg/kodverk/typavintyg/"
                                @input="searchForMallar()"
                                :map="
                                    (el) => {
                                        return { value: el.id, text: el.code + ' - ' + el.displayName };
                                    }
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="k3-sub-form-flex-container">
                <div>
                    <div class="circle">
                        <div class="number">2</div>
                    </div>
                    <div class="vr"></div>
                </div>
                <div>
                    <h3 class="left-pad">Skapa intyg</h3>
                    <div v-if="formData.accountableHealthcareProfessional && formData.typ">
                        <div class="btn-group form-group mt-4 ml-3">
                            <Button class="btn-skapa-nytt" role="button" @click="submit('tomtIntyg')">
                                <img src="@/assets/document_white.svg" />{{
                                    intygSupportsMallar() ? "Skapa nytt, tomt intyg" : "Skapa intyg"
                                }}
                            </Button>
                        </div>

                        <section class="product-selection menu" v-if="intygSupportsMallar()">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a
                                        data-toggle="tab"
                                        class="nav-link"
                                        :class="activeTab === 1 ? 'active' : ''"
                                        href="#search-results"
                                        v-on:click="
                                            activeTab = 1;
                                            activeView = 1;
                                        "
                                    >
                                        <span>Alla mallar</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        data-toggle="tab"
                                        class="nav-link"
                                        :class="activeTab === 2 ? 'active' : ''"
                                        href="#previous-prescriptions"
                                        v-on:click="
                                            activeTab = 2;
                                            activeView = 2;
                                        "
                                    >
                                        <span>Gemensamma mallar</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        data-toggle="tab"
                                        class="nav-link"
                                        :class="activeTab === 3 ? 'active' : ''"
                                        href="#favourites"
                                        v-on:click="
                                            activeTab = 3;
                                            activeView = 3;
                                        "
                                    >
                                        <span>Mina mallar</span>
                                    </a>
                                </li>
                            </ul>
                        </section>

                        <div class="tables mt-3" v-if="intygsmallar.length > 0 && intygSupportsMallar()">
                            <custom-action-table
                                class="k3-intyg-lista"
                                @chooseMall="chooseSkapaFranMall"
                                v-if="activeView === 1"
                                :selectableRows="true"
                                :columns="intygsmallarTableColumns"
                                :items="allIntygsmallar"
                                :actions="intygsmallarListActions"
                            ></custom-action-table>
                            <custom-action-table
                                class="k3-intyg-lista"
                                @chooseMall="chooseSkapaFranMall"
                                v-if="activeView === 2"
                                :selectableRows="true"
                                :columns="intygsmallarTableColumns"
                                :items="publicIntygsmallar"
                                :actions="intygsmallarListActions"
                            ></custom-action-table>
                            <custom-action-table
                                class="k3-intyg-lista"
                                @chooseMall="chooseSkapaFranMall"
                                v-if="activeView === 3"
                                :selectableRows="true"
                                :columns="intygsmallarTableColumns"
                                :items="privateIntygsmallar"
                                :actions="intygsmallarListActions"
                            ></custom-action-table>
                        </div>
                        <div
                            class="row mt-4 ml-3 mr-1 mb-2"
                            v-if="!intygsmallar.length > 0 && intygSupportsMallar()"
                            style="align-content: baseline"
                        >
                            <span
                                ><img class="mr-2" src="@/assets/info_dark.svg" />Det finns inga mallar. Välj Skapa ny
                                intygsmall för att skapa en mall.</span
                            >
                        </div>
                        <button
                            role="button"
                            @click="openIntygsmallFormTab()"
                            v-if="intygSupportsMallar()"
                            class="hollow-button mb-3"
                        >
                            <img src="@/assets/plus_circle_blue.svg" />Skapa ny intygsmall
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import IntygsmallForm from "@/tabs/Intyg/IntygsmallForm.vue";
import Vue from "vue";
import dialogEvents from "@/dialogEvents";
import tabEvents from "@/tabEvents";
import tabIcon from "@/assets/operationsanmalan.svg";
import widgets from "@/components/widgets/inputs";
import { cloneDeep } from "lodash";
import { getAccessToken, getConfig, getErrorMessage, klinikenApi } from "@/api";
import { mapActions, mapGetters } from "vuex";
import { flashMessage, openDialog } from "@/utils";
import Button from "@/components/Button.vue";

const noNull = (str) => {
    return str || "";
};

const INTYGSMALLAR_TABLE_COLUMNS = [
    {
        name: "dummycolumn",
        title: "",
        template: () => {
            return noNull(`<span></span>`);
        },
    },
    {
        name: "namn",
        title: "Namn",
        template: (item) => {
            return noNull(item.namn);
        },
    },
    {
        name: "created_by",
        title: "Skapad av",
        template: (item) => {
            return noNull(item.created_by.user.name);
        },
    },
    {
        name: "created_at",
        title: "Skapad",
        template: (item) => {
            return item.created_at.substr(0, 10);
        },
    },
    {
        name: "updated_at",
        title: "Senast uppdaterad",
        template: (item) => {
            return item.updated_at.substr(0, 10);
        },
    },
    {
        name: "attach_intyg",
        title: "",
        template: (item) => {
            return `
                <div class="parentAttachIntygKnapp" style="float: right;">
                    <button class="attachIntygKnapp button button-primary button-slim" @click="$parent.$parent.$parent.$parent.$emit('chooseMall', ${item.id})">
                        <img style="height: 16px; width: 16px; margin-right: 7px; vertical-align: baseline;" :src="fetchImg('mall_white.svg')" />
                        Skapa från mall
                    </button>
                </div>
            `;
        },
    },
];

export default {
    extends: BaseTab,
    name: "Intyg",
    icon: tabIcon,
    tabName: "Skapa intyg",
    patientBound: true,
    props: {
        uid: Number,
        parameters: Object,
        tabId: Number,
    },
    components: {
        InputWrapper,
        CustomActionTable,
        Button,
    },
    data() {
        return {
            intygsmallarTableColumns: INTYGSMALLAR_TABLE_COLUMNS,
            valdMall: null,
            formData: {},
            widgets: widgets,
            accountableHealthCareProfessionals: [],
            mall: {},
            showLista: true,
            hidden: {},
            isOpen: false,
            activeTab: 1,
            activeView: 1,
        };
    },
    computed: {
        ...mapGetters("patientData", ["currentId"]),
        ...mapGetters("userData", ["currentProfile"]),
        ...mapGetters("intyg", ["intygsmallar"]),
        ...mapGetters("userData", ["user"]),
        allIntygsmallar() {
            return this.intygsmallar.filter(
                (el) => el.is_public || (!el.is_public && el.created_by.user.pk === this.currentProfile.user.pk)
            );
        },
        publicIntygsmallar() {
            return this.intygsmallar.filter((el) => el.is_public);
        },
        privateIntygsmallar() {
            return this.intygsmallar.filter(
                (el) => !el.is_public && el.created_by.user.pk === this.currentProfile.user.pk
            );
        },
        intygsmallarListActions() {
            return [
                {
                    name: "editMall",
                    title: "Redigera",
                    condition: (item) => {
                        return this.user.pk === item.created_by.user.pk;
                    },
                },
                {
                    name: "deleteMall",
                    title: "Ta bort",
                    condition: (item) => {
                        return this.user.pk === item.created_by.user.pk;
                    },
                },
            ];
        },
    },
    methods: {
        intygSupportsMallar() {
            return this.formData.typ === 1;
        },
        chooseSkapaFranMall(mallId) {
            this.valdMall = null;
            this.valdMall = mallId;
            this.submit();
        },
        toggleVisible(subFormName) {
            let hidden = this.hidden[subFormName] || false;
            Vue.set(this.hidden, subFormName, !hidden);
        },
        validateForm(formName) {
            var form = document.getElementById(formName);
            if (!form) return false;
            if (form.checkValidity() === true) return true;
            else {
                var list = form.querySelectorAll(":invalid");
                for (var item of list) {
                    item.focus();
                }
                form.querySelector(":invalid").focus();
            }
            return false;
        },
        async submit(str) {
            if (str === "tomtIntyg") {
                this.valdMall = null;
            }
            this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            if (!this.validateForm("intyg-registration-details")) return;

            var details = cloneDeep(this.formData);
            details.patient = this.currentId;
            details.mall = this.valdMall;

            let successfulPost = false;
            const config = getConfig({}, "Intyg");
            await (details.pk
                ? klinikenApi.put("/intyg/intyg/" + details.pk + "/", details, config)
                : klinikenApi.post("/intyg/intyg/", details, config)
            )
                .then((response) => {
                    if (response.data.create_info) {
                        dialogEvents.$emit("open", {
                            type: "information",
                            description: response.data.create_info,
                            buttons: [
                                {
                                    title: "Fortsätt",
                                    type: "primary",
                                    action: () => {
                                        this.openWebcert(response.data.id);
                                    },
                                },
                            ],
                        });
                    } else this.openWebcert(response.data.id);
                    successfulPost = true;
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
            tabEvents.$emit("refreshIntyg", {});
            this.$store.dispatch("tabs/displaySpinner", {
                id: this.tabId,
                display: false,
            });
            if (successfulPost) this.close();
        },
        async close() {
            await this.$store.dispatch("tabs/closeTab", this.tabId);
        },
        async getIntyg(id) {
            const config = getConfig({}, "Intyg");
            let intyg = null;

            await klinikenApi
                .get("intyg/" + id + "/", config)
                .then((response) => {
                    intyg = response.data;
                })
                .catch((e) => {
                    openDialog("Kunde inte hämta intyg från servern." + getErrorMessage(e), "warning");
                });
            return intyg;
        },
        openWebcert(id) {
            window.open(
                window.location.protocol +
                    "//" +
                    window.location.hostname +
                    ":" +
                    window.location.port +
                    "/inhopp/intyg/webcert/" +
                    id +
                    "?jwt=" +
                    getAccessToken() +
                    "&orgunit=" +
                    this.currentProfile.orgUnit.id
            );
        },
        async getAccountableHealthCareProfessionals() {
            const config = getConfig({}, "Intyg");
            let ahcps = [];
            if (!this.currentProfile) return ahcps;
            await klinikenApi
                .get(
                    "core/profiles/?yrkeskategori__code=LK&yrkeskategori__system=1.2.752.116.3.1.3&is_active=true&orgUnit_id=" +
                        this.currentProfile.orgUnit.id,
                    config
                )
                .then((response) => {
                    ahcps = response.data.results;
                })
                .catch((e) => {
                    openDialog("Kunde inte hämta profiler från servern." + getErrorMessage(e), "warning");
                });
            return ahcps;
        },
        removeValdMall() {
            this.valdMall = null;
        },
        changeShow() {
            this.showLista = !this.showLista;
        },
        ...mapActions("intyg", ["loadIntygsmallar", "deleteIntygsmall"]),
        openIntygsmallFormTab() {
            this.$store.dispatch("tabs/openTab", {
                component: IntygsmallForm,
                parameters: this.formData,
            });
        },
        editIntygsmall(data) {
            this.$store.dispatch("tabs/openTab", {
                component: IntygsmallForm,
                parameters: data,
            });
        },
        deleteMallEvent(data) {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Är du säker på att du vill ta bort intygsmallen?",
                buttons: [
                    {
                        title: "Ja",
                        type: "normal btn-alert btn-primary",
                        action: () => this.deleteMall(data),
                    },
                    {
                        title: "Avbryt",
                        type: "outline-secondary",
                    },
                ],
            });
        },
        async deleteMall(data) {
            const response = await this.deleteIntygsmall(data);
            if (response.status == 204) {
                flashMessage("Intygsmallen har raderats.");
            }
            this.searchForMallar();
        },
        async init() {
            let accountableHealthCareProfessionals = await this.getAccountableHealthCareProfessionals();
            this.accountableHealthCareProfessionals = accountableHealthCareProfessionals.map((el) => {
                return { value: el.pk, text: el.user.name };
            });

            let currentProfile = this.accountableHealthCareProfessionals.find((el) => {
                return el.value === this.currentProfile.pk;
            });

            if (currentProfile) {
                Vue.set(this.formData, "accountableHealthcareProfessional", currentProfile.value);
            }

            this.$on("editMall", (item) => {
                this.editIntygsmall(item);
            });

            this.$on("deleteMall", (item) => {
                this.deleteMallEvent(item);
            });
        },
        async searchForMallar() {
            await this.loadIntygsmallar(this.formData.typ);
        },
    },
    created() {
        this.init();
    },
};
</script>
<style lang="sass" scoped>
@import "@/style/variables"
@import "/src/style/deprecated_main"

.product-selection
    .action-table
        > button
            background-color: $color-tablerow-button-selected

            &.collapsed:not(:focus)
                background-color: $color-tablerow

    td
        vertical-align: middle !important

    button:disabled
        background-color: #B4D6DD
        color: #FFFFFF
        cursor: default

        img
            filter: none

#intyg-registration-details
    position: relative
    max-width: 720px

.k3-simple-form
    max-width: 720px

    label
        margin-top: 5px
        margin-bottom: 0px

.removeButton
    align-self: center
    min-width: 35px
    margin-bottom: 10px

.k3-sub-form-flex-container
    display: flex
    flex-direction: row
    margin-left: -20px

.vr
    border-left: 1px solid #8EBACA
    height: calc(100% - 60px)
    min-height: 10px
    margin-left: 13px
    margin-top: 10px
    margin-bottom: 10px

.circle
    height: 26px
    width: 26px
    background-color: #277692
    border-radius: 13px

    .number
        height: 26px
        width: 100%
        color: #FFFFFF
        font-family: Roboto
        font-size: 18px
        letter-spacing: 0
        line-height: 26px
        text-align: center

.left-pad
    margin-left: 10px

button, .left-pad
    > img
        margin-right: 8px

.k3-dropdown-icon
    margin-left: 15px

.k3-img-rotate
    transform: rotate(180deg)

.product-selection
    margin-top: 42px
    margin-left: 12px

    ul.nav-tabs
        height: 42px
        display: flex
        list-style-type: none
        gap: 34px
        padding-left: 28px

        background: linear-gradient(to top, #E3E6EE 0%, #E3E6EE 100%)
        background-repeat: no-repeat
        background-size: 100% 7px
        background-position: bottom

        li.nav-item
            flex: 0 1 auto
            min-width: 0

        a.nav-link
            text-decoration: none
            box-sizing: border-box
            border: none
            padding-top: 0
            padding-bottom: 0
            color: #354052
            font-family: "Roboto Medium"
            font-weight: 500
            padding-bottom: 20px

            span
                line-height: 24px

            &.active
                color: #CA346F
                background: linear-gradient(to top, #CA346F 0%, #CA346F 100%)
                background-repeat: no-repeat
                background-size: calc(100% - 32px) 7px
                background-position: center bottom

            &:hover:not(.active)
                background: linear-gradient(to top, #EAADC5 0%, #EAADC5 100%)
                background-repeat: no-repeat
                background-size: calc(100% - 32px) 7px
                background-position: center bottom

.k3-intyg-lista
    margin-bottom: 50px
    background-color: #f6f8fb

.tables
    min-width: 1000px
    margin-left: 12px

.inputs
    width: 500px

.hollow-button
    height: 28px
    color: #6C8188
    font-family: Roboto Medium
    font-size: 16px
    font-weight: 500
    line-height: 28px
    border: none
    background-color: #FFFFFF
    margin-left: 50px

    img
        margin-right: 8px

.inputOne
    padding: 10px 20px 20px 0
    margin: 15px 0 0 10px
    width: 230px

.inputTwo
    padding: 20px
    margin: -15px 10px 40px 10px
    background-color: $color-blue-light
    width: 720px
</style>
<style lang="scss" scoped>
@import "@/style/mixins/button";

.intygMain {
    margin-bottom: 46px;

    h2 {
        margin-bottom: 24px;
    }

    .btn-skapa-nytt {
        margin: 24px 0 -12px 12px;
    }

    ::v-deep tbody td {
        vertical-align: middle;
    }

    ::v-deep tbody tr:hover {
        background-color: #cee9f3;
    }

    ::v-deep .parentAttachIntygKnapp .attachIntygKnapp {
        @include button;
        @include button-standard;
        @include button-slim;
        padding: 0 17px;
        width: fit-content !important;
        white-space: nowrap;
        visibility: hidden;
    }

    ::v-deep .table-row:hover .attachIntygKnapp {
        visibility: visible;
    }
}
</style>
