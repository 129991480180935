import { openDialog } from "@/utils";
import { getConfig, klinikenApi } from "@/api";
import dialogEvents from "@/dialogEvents";
import Sign from "@/components/Sign.vue";

const waitForPoll = async ({ state }) => {
    let failsafeCounter = 300; // Hard timeout of 5 minutes/300 seconds
    while (state.polling && failsafeCounter--) {
        await pollTimeout();
    }
    return new Promise((resolve) => resolve());
};

const pollTimeout = () => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
};

const getDefaultState = () => {
    return {
        polling: false,
        preanestesibedomning: null,
        preanestesibedomningar: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setPolling(state, polling) {
            state.polling = polling;
        },
        setPreanestesibedomning(state, payload) {
            state.preanestesibedomning = payload;
        },
        setPreanestesibedomningar(state, payload) {
            state.preanestesibedomningar = payload;
        },
    },
    actions: {
        async loadPreanestesibedomningar({ commit }, patient_id) {
            const config = getConfig({}, "Operationsanmälan");
            let preanestesibedomningar = [];
            await klinikenApi
                .get(`operation/preanestesibedomningar/?patient__id=${patient_id}`, config)
                .then((response) => {
                    preanestesibedomningar = response.data.results;
                    preanestesibedomningar.sort((a, b) => {
                        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                    });
                })
                .catch(() => {
                    openDialog("Kunde inte hämta preanestesibedomningar från servern. ", "error");
                });
            commit("setPreanestesibedomningar", preanestesibedomningar);
        },

        async save({ commit }, data) {
            const config = getConfig({}, "Preanestesibedömning");

            try {
                let response = data.id
                    ? await klinikenApi.patch(`/operation/preanestesibedomningar/${data.id}/`, data, config)
                    : await klinikenApi.post("/operation/preanestesibedomningar/", data, config);

                commit("setPreanestesibedomning", response.data);

                return response;
            } catch (error) {
                openDialog("Misslyckades med att spara preanestesibedömning.", "error");
                return error.response;
            }
        },

        async sign({ state, commit }, data) {
            const config = getConfig({}, "Preanestesibedömning");

            let url = "/operation/preanestesibedomningar/";
            if (data.id) {
                url = `${url}${data.id}/`;
            }

            commit("setPolling", true);

            const promise = new Promise((resolve, reject) => {
                dialogEvents.$emit("openPopup", {
                    title: "Signera preanestesibedömning",
                    component: Sign,
                    dismissable: false,
                    data: {
                        config: config,
                        params: data,
                        url: url,
                        closeOnError: true,
                        callback: (data) => {
                            commit("setPolling", false);
                            if (data.is_signed) {
                                commit("setPreanestesibedomning", data);
                            }
                            resolve();
                        },
                        errorCallback: () => {
                            commit("setPolling", false);
                            reject();
                        },
                    },
                });
            });

            await waitForPoll({ state });

            return promise;
        },
    },
};
