<template>
    <FormulateForm v-model="searchBarValues" class="statistik-search-bar">
        <FormulateInput
            v-if="showLagDropdown"
            name="dropdownOption"
            type="vueselect"
            label="Lag"
            :options="dropdownOptions"
            placeholder="Välj lag"
        />
        <FormulateInput name="fromDate" type="datepicker" label="Från datum" placeholder="Välj datum" />
        <FormulateInput name="toDate" type="datepicker" label="Till datum" placeholder="Välj datum" />
        <div class="buttongroup">
            <Button :disabled="!searchBarValues?.dropdownOption" variant="standard" @click="emitValues" size="slim"
                >Sök
            </Button>
        </div>
    </FormulateForm>
</template>

<script>
import Button from "@/components/Button.vue";
import SectionDivider from "@/components/generic/SectionDivider.vue";

export default {
    components: { SectionDivider, Button },
    props: {
        value: {
            type: Object,
        },
        lag: {
            type: Array,
            default: () => [],
        },
        showLagDropdown: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        searchBarValues: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
        dropdownOptions() {
            return this.lag !== [] ? this.transformOptionsNamnId(this.lag) : [];
        },
    },
    methods: {
        emitValues() {
            this.$emit("emitValues", this.searchBarValues);
        },
        transformOptionsNamnId(list) {
            const transformer = (obj) => {
                return {
                    value: `${obj.id}`,
                    label: `${obj.namn}`,
                };
            };

            return list.map((i) => {
                return transformer(i);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.statistik-search-bar {
    display: flex;
    gap: 20px;
    align-items: baseline;
    background-color: $color-blue-light;
    padding: 20px;
    margin-bottom: 20px;

    .buttongroup {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .input-row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .input-row label {
        margin-right: 10px;
    }
}
</style>
