<template>
    <div class="suggest-wrap">
        <!--
            Important! keyup.esc.stop and keydown.enter.prevent necessary to prevent these keys from propagating to form
        -->
        <input
            :required="required_"
            v-model="text_"
            :name="name + 'Display'"
            v-on:input="updateValue"
            :placeholder="placeholder"
            autocomplete="off"
            class="text-input"
            type="select"
            @click="isOpen = !isOpen"
            @keyup.esc.stop="isOpen = false"
            @keydown="prevent($event)"
            @keydown.down.prevent="moveDown"
            @keydown.up.prevent="moveUp"
            @keydown.enter.prevent="settle"
            @blur="
                isOpen = false;
                hasFocus(false);
            "
            @focus="hasFocus(true)"
            :disabled="disabled_"
        />
        <!--         @wheel.prevent.stop="mouseWheel($event)" -->
        <input type="hidden" :name="name" :value="value_" />

        <ul class="suggest-list" v-show="isOpen" @wheel.prevent.stop="mouseWheel($event)">
            <li
                v-for="(option, index) in options_"
                :key="index"
                @mouseenter="highlightedPosition = index"
                @mousedown="select"
                :name="name + '-suggest'"
                :class="['suggest-item', { highlighted: index === highlightedPosition }]"
            >
                {{ option.text || "&nbsp;" }}
            </li>
        </ul>
    </div>
</template>

<script>
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";
import linkEvents from "@/linkEvents";

export default {
    name: "SelectWidget",
    props: {
        placeholder: String,
        options: Array, //{ value, text }
        tabindex: Number,
        name: String,
        required: Boolean,
        maxlength: String,
        apisearch: String,
        extraParams: Object, // An object specifying additional parameters to be passed to backend, such as filters
        value: undefined, // Should be Object, but can be Number until backend catches up
        displayCode: Boolean, // if code should be shown or not
        /**
         * In case the data from backend is NOT a JSON object with the format
         * { id, code, displayName }, a map Function must be supplied by the parent
         */
        map: Function,
        uid: Number, // Will only be passed if parent is an ExpandableSuggest
        noResults: Boolean, // Whether backend response array wrapped in results or not
        disabled: Boolean,
    },
    data() {
        return {
            API_SEARCH: this.apisearch,
            searchTimeout: false,
            loading: false,
            isOpen: false,
            highlightedPosition: NaN,
            text_: null,
            value_: this.value,
            options_: this.options,
            focus: false,
            map_:
                this.map ||
                function (item) {
                    return {
                        value: item.id,
                        text: item.displayName,
                    };
                },
            disabled_: this.disabled,
            firstLetter: "",
            indexOfElementFinded_: NaN,
            required_: this.required,
        };
    },
    methods: {
        /**
         * @returns {boolean}
         */
        highlightedPositionNotEmpty() {
            return !isNaN(this.highlightedPosition);
        },

        /**
         * @returns {number}
         */
        indexOfElementFinded() {
            return this.indexOfElementFinded_;
        },

        /**
         * @param {number} value
         */
        setIndexOfElementFinded(value) {
            this.indexOfElementFinded_ = value;
        },

        /**
         * Option definition
         * @typedef {Object} Option
         * @property {string} text
         * @property {number} value
         */

        /**
         * @returns {Option}
         */
        highlightedOption() {
            return this.options_[this.highlightedPosition];
        },
        /**
         * @param {number} indexNum
         * @param {Event} event
         */
        toggleHighlightedMatchingOption(indexNum, event) {
            if (indexNum !== -1) {
                // togglehighlightingmatchingoption()

                /**
                 *  Should return an array with two objects if two words starts with same letters
                 *  allOptionsThatStartWithFirstLetter.length === 2 in that case
                 */
                const allOptionsThatStartWithFirstLetter = this.options_.filter((el) =>
                    el.text.startsWith(this.firstLetter)
                );

                /**
                 *  This condition is only true if you press the same keyboard key twice
                 *
                 */
                if (allOptionsThatStartWithFirstLetter.length > 1 && this.highlightedPositionNotEmpty()) {
                    if (this.highlightedOption().text.startsWith(this.firstLetter)) {
                        /**
                         *  returns 0 should also return the first object in allOptionsThatStartWithFirstLetter
                         *
                         */
                        this.setIndexOfElementFinded(
                            allOptionsThatStartWithFirstLetter.indexOf(this.options_[this.highlightedPosition])
                        );

                        if (this.indexOfElementFinded() == allOptionsThatStartWithFirstLetter.length - 1) {
                            this.setIndexOfElementFinded(0);
                        } else {
                            this.setIndexOfElementFinded(this.indexOfElementFinded() + 1);
                        }

                        this.highlightedPosition = this.options_.indexOf(
                            allOptionsThatStartWithFirstLetter[this.indexOfElementFinded()]
                        );
                        event.preventDefault();
                        return this.highlightedPosition;
                    }
                }
                this.highlightedPosition = indexNum;
            }
        },
        prevent(event) {
            /**
             * Should return 65 when pressing A keyboard key
             */
            const keyChar = event.charCode || event.keyCode;

            /**
             * Parses so that it returns the correct letter based on the keyChar'\n'
             * keyChar === 65 return letter A
             */
            this.firstLetter = String.fromCharCode(keyChar);

            /**
             *  returns 0 === options_[0]
             *  findIndex returns the index position of the chosen elements
             */
            const indexOfMatchedOption = this.options.findIndex((option) => option.text.startsWith(this.firstLetter));

            this.toggleHighlightedMatchingOption(indexOfMatchedOption, event);

            if (keyChar !== 9) {
                // Allow tab
                event.preventDefault();
            } else {
                return true;
            }
        },
        validate() {
            this.isOpen = false;
        },
        getValueFromText(text) {
            if (!this.options_) return null;
            let option = this.options_.find((el) => {
                return el.text === text;
            });
            if (option) return option.value;
            else return "";
        },
        getTextFromValue(value) {
            if (!this.options_) return "";
            let option = this.options_.find((el) => {
                return el.value === value;
            });
            if (option) return option.text;
            else return "";
        },
        async fetchData() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            let options = [];
            await klinikenApi
                .get(this.API_SEARCH, getConfig({ params: this.extraParams }))
                .then((response) => {
                    options = this.noResults ? response.data.map(this.map_) : response.data.results.map(this.map_);
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                    return [];
                })
                .then(() => {
                    this.loading = false;
                    this.searchTimeout = false;
                });
            return options;
        },
        moveDown() {
            if (!this.isOpen) this.isOpen = true;
            if (isNaN(this.highlightedPosition)) this.highlightedPosition = 0;
            else this.highlightedPosition = (this.highlightedPosition + 1) % this.options_.length;
        },
        moveUp() {
            if (!this.isOpen) this.isOpen = true;
            this.highlightedPosition =
                this.highlightedPosition - 1 < 0 ? this.options_.length - 1 : this.highlightedPosition - 1;
        },
        select() {
            let selectedOption = this.options_[this.highlightedPosition];
            this.value_ = selectedOption.value;
            this.text_ = selectedOption.text;
            this.valueChanged(selectedOption);
            this.isOpen = false;
        },
        settle(event) {
            if (this.isOpen && !isNaN(this.highlightedPosition)) {
                this.select();
            }
            event.stopPropagation();
        },
        update({ value, options, required, disabled }) {
            if (disabled !== null && disabled !== undefined) {
                this.disabled_ = disabled;
            }
            if (value || value === "") {
                let text = this.getTextFromValue(value);
                if (text) {
                    this.value_ = value;
                    this.text_ = text;
                } else {
                    this.value_ = this.getValueFromText(value);
                    this.text_ = value;
                }
            }
            /*
            else {
                this.value_ = null;
                this.text_ = "";
            }
            */
            if (required) this.required_ = true;
            if (options) this.options_ = options;
        },
        updateValue() {
            this.isOpen = true;
            this.highlightedPosition = NaN;
        },
        valueChanged(selectedOption) {
            if (this.uid) {
                this.$emit("select_changed", {
                    uid: this.uid,
                    value: selectedOption,
                });
            } else linkEvents.$emit(this.name + "_changed", selectedOption.value);
        },
        mouseWheel(event) {
            if (event.deltaY > 0) this.moveDown();
            else this.moveUp();
        },
        hasFocus(focus) {
            window.getSelection().removeAllRanges();
            if (focus)
                // first time input gets focus
                this.focus = true;
            if (!focus && this.focus)
                // every time input loses focus
                linkEvents.$emit(this.name + "_focus");
        },
    },
    async created() {
        if (this.API_SEARCH) {
            this.options_ = await this.fetchData();
        }
        linkEvents.$on("update_" + this.name, this.update);
        if (typeof this.value_ === "object" && this.value_ !== null) {
            this.value_ = this.map_(this.value_).value;
            this.text_ = this.getTextFromValue(this.value_);
        } else if (this.value_ !== null || this.value !== undefined) {
            this.text_ = this.getTextFromValue(this.value_);
        }
    },
    watch: {
        /**
         * value (a prop) can change as a result of ExpandableSelect changing indeces. When that happens, re-initialize values.
         */
        value() {
            this.update({ value: this.value });
        },
        disabled() {
            this.disabled_ = this.disabled;
        },
    },
};
</script>

<style lang="sass" scoped>
input[type="select"]
    background-color: #fff
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    background-image: url('@/assets/select_arrow.svg')
    background-repeat: no-repeat
    background-position-y: center
    background-position-x: calc(100% - 18px)
    cursor: default
    caret-color: transparent

    &:disabled
        opacity: 0.3

.suggest-wrap
    position: relative

.suggest-list
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
    position: absolute
    left: 0
    top: 48px
    width: 100%
    z-index: 100
    background: #ffffff
    padding: 0px !important

.suggest-item
    list-style: none
    border: 1px solid #EEE
    margin: 0
    padding: 0
    border-width: 0 1px 1px 1px
    padding: .4rem

    &:first-of-type
        border-top: 1px solid #EEE

.highlighted
    background: #eee
    cursor: pointer
</style>
