<script>
    export default {
        name: "Button",
        props: {
            variant: {
                type: String,
                default: "standard",
                validator: function (value) {
                    return ["standard", "alert", "contour", "hollow", "link"].indexOf(value) !== -1;
                },
            },
            size: {
                type: String,
                default: "primary",
                validator: function (value) {
                    return ["primary", "secondary", "slim", "small"].indexOf(value) !== -1;
                },
            },
        },
        computed: {
            klass: function () {
                return [`button-${this.variant}`, `button-${this.size}`];
            },
        },
    };
</script>

<template>
    <button class="button" :class="klass" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </button>
</template>

<style lang="scss" scoped>
    @import "@/style/button";
</style>
