import AutoCompleteWidget from "./AutoCompleteWidget.vue";
import CheckboxGroup from "./CheckboxGroup.vue";
import CheckboxWidget from "./CheckboxWidget.vue";
import DateWidget from "./DateWidget.vue";
import DateTimeWidget from "./DateTimeWidget.vue";
import DosageSuggestTextArea from "./DosageSuggestTextArea.vue";
import ExpandableSelect from "./ExpandableSelect.vue";
import ExpandableSuggest from "./ExpandableSuggest.vue";
import HiddenWidget from "./HiddenWidget.vue";
import HollowButton from "./HollowButton.vue";
//import PersonIdWidget from './PersonIdWidget.vue';
import RadioButtonWidget from "@/components/widgets/RadioButtonWidget.vue";
import RangeWidget from "@/components/widgets/RangeWidget.vue";
import ScalableTextArea from "@/components/widgets/ScalableTextArea.vue";
import SelectWidget from "@/components/widgets/SelectWidget.vue";
import StaticTextWidget from "@/components/widgets/StaticTextWidget.vue";
import SuggestTextArea from "@/components/widgets/SuggestTextArea.vue";
import TextAreaWidget from "@/components/widgets/TextAreaWidget.vue";
import TextWidget from "@/components/widgets/TextWidget.vue";
import InputWrapper from "@/components/widgets/InputWrapper.vue";
import RemoveButton from "@/components/widgets/RemoveButton.vue";
import LoginButton from "@/components/Auth/LoginButton.vue";

export default {
    DateWidget,
    DateTimeWidget,
    SelectWidget,
    RangeWidget,
    TextAreaWidget,
    TextWidget,
    AutoCompleteWidget,
    DosageSuggestTextArea,
    SuggestTextArea,
    CheckboxWidget,
    CheckboxGroup,
    ExpandableSuggest,
    ExpandableSelect,
    StaticTextWidget,
    RadioButtonWidget,
    ScalableTextArea,
    HiddenWidget,
    InputWrapper,
    RemoveButton,
    HollowButton,
    LoginButton,
    // PersonIdWidget,
};
