<template>
    <Labportalen :tab-id="this.tabId" endpoint="/labportalen/webapplication/user/utkorg/" />
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import tabIcon from "@/assets/labPortal/utkorg.svg";
import Labportalen from "@/tabs/LabPortalen/Labportalen.vue";

export default {
    components: { Labportalen },
    extends: BaseTab,
    patientBound: false,
    name: "LabPortalenUtkorg",
    icon: tabIcon,
    tabName: "Labportalen utkorg",
    props: ["parameters", "tabId"],
};
</script>
