<template>
    <input :value="value" type="hidden" :name="name" />
</template>

<script>
export default {
    name: "HiddenWidget",
    props: {
        name: String,
        value: String,
    },
};
</script>
