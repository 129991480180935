<template>
    <button class="hollow-button" type="button" @click="onClick">
        <img v-if="this.icon !== ''" :src="icon" :alt="label" />
        <slot>{{ label }}</slot>
    </button>
</template>

<script>
export default {
    name: "hollow-button",

    props: {
        label: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            required: false,
        },
    },

    computed: {},

    methods: {
        onClick() {
            this.$emit("click");
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/style/variables";

.hollow-button {
    height: 28px;
    color: #6c8188;
    font-family: Roboto Medium, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    border: none;
    background-color: #ffffff;
    white-space: nowrap;

    &:disabled {
        opacity: $disabled-opacity;
    }

    img {
        margin-right: 8px;
        vertical-align: middle;
    }
}
</style>
