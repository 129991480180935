/* A subclass of our basic SuggestWidget that display the dropdown a soon as the component receives focus. */

<template>
    <div class="suggest-wrap">
        <template v-if="!preview">
            <input
                v-model="q"
                :required="required"
                :name="name + 'Display'"
                @input="updateValue($event)"
                @focus="onFocus()"
                v-bind="$attrs"
                autocomplete="off"
                class="text-input"
                @keyup.esc.stop="validate()"
                @keydown.down="moveDown"
                @keydown.up="moveUp"
                @keydown.enter.prevent="settle"
                @blur="
                    validate();
                    $emit('blur');
                "
            />
            <input type="hidden" :name="name" :value="id" />

            <ul ref="suggest-list" class="suggest-list" v-show="isOpen" @wheel.prevent.stop="mouseWheel($event)">
                <li
                    v-for="(item, index) in this.results"
                    :key="index"
                    @mouseenter="highlightedPosition = index"
                    @mousedown="select"
                    :name="name + '-suggest'"
                    :class="['suggest-item', { highlighted: index === highlightedPosition }]"
                >
                    <slot name="item"
                        ><strong v-if="displayCode">{{ item.code }}</strong> {{ item.displayName }}</slot
                    >
                </li>
            </ul>
        </template>
        <span v-else class="input-preview">
            {{ q }}
        </span>
    </div>
</template>

<script>
import SuggestWidget from "@/components/widgets/inputs/SuggestWidget.vue";

export default {
    name: "PopoutSuggestWidget",
    extends: SuggestWidget,
    methods: {
        onFocus() {
            this.fetchResults();
            this.updateValue();
        },

        validate() {
            this.isOpen = false;

            if (this.results.length === 0 || this.q === "") {
                this.setValues();
            } else {
                this.setValues(this.results[0]);
            }

            this.emitValue();
        },

        // Fix me later
        updateValue(event) {
            this.isOpen = true;
            this.highlightedPosition = NaN;

            if (event?.target.value === "") {
                this.q = "";
                this.results = [];
                this.search();
            }
        },
    },
};
</script>
