<template>
    <div class="filterWidget">
        <form v-if="topSearchBar" class="k3-form topSearchBar">
            <label v-if="label">{{ label }}</label>
            <text-widget
                :colspan="6"
                name="searchBarData"
                v-model="searchBarData['search']"
                label="Valfri textsökning"
                @input="emitNormalizedValue($event)"
                class="searchBar"
                type="search"
                @change="$emit('search')"
            />
            <button @click.prevent="$emit('search')" class="btn btn-primary searchButton">Sök</button>
        </form>
        <div class="pseudo-form">
            <label @click="toggleDropdown = !toggleDropdown">
                <img
                    alt="dropdown arrow"
                    :class="{ rotate: toggleDropdown }"
                    class="dropdownArrow"
                    src="@/assets/dropdown-arrow-blue.svg"
                />Sökalternativ ({{ Object.keys(filterData).length }})
            </label>
        </div>
        <transition name="slide">
            <div v-if="toggleDropdown">
                <form class="k3-form no-padding">
                    <input-wrapper
                        v-for="input in queryInputs"
                        :key="input.name"
                        :component="input.component"
                        :name="input.name"
                        :colspan="input.colspan"
                        v-model="filterData[input.name]"
                        :label="input.label"
                        v-bind="input.parameters"
                        @update="setObjectValue($event, input.name)"
                        @input="emitNormalizedValue($event, input.name)"
                    />
                </form>
            </div>
        </transition>
        <div class="pseudo-form" v-show="Object.keys(filterData).length > 0">
            <div class="filter-wrapper">
                <button
                    v-for="(value, key, index) in filterData"
                    class="filter"
                    @click="deleteFilter(key)"
                    type="button"
                    role="button"
                    :key="index"
                >
                    {{ getLabelName(key) }}: {{ getDisplayValue(key) }} <img src="@/assets/cross_error_white.svg" />
                </button>
            </div>
            <button class="btn btn-sm btn-alert" type="button" role="button" @click="deleteAllFilters">
                Rensa filter
            </button>
            <button
                class="btn btn-primary"
                style="margin: 10px 0 0 0"
                v-if="!topSearchBar"
                @click.prevent="$emit('search')"
                type="button"
                role="button"
            >
                Sök
            </button>
        </div>
    </div>
</template>
<script>
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";

import TextWidget from "@/components/widgets/inputs/TextWidget.vue";
import Vue from "vue";

export default {
    name: "FilterWidget",
    props: {
        value: Object,
        topSearchBar: Boolean,
        queryInputs: Array,
        label: String,
    },
    components: {
        TextWidget,
        InputWrapper,
    },
    data() {
        return {
            toggleDropdown: false,
            searchBarData: this.getNormalizedValue("searchBarData"),
            filterData: this.getNormalizedValue("filterData"),
            objectData: {},
        };
    },
    methods: {
        getNormalizedValue(name) {
            let value = this.value && this.value[name] ? this.value[name] : {};
            return value;
        },
        deleteFilter(key) {
            Vue.delete(this.filterData, key);
            this.$emit("input", {
                searchBarData: this.searchBarData,
                filterData: this.filterData,
            });
        },
        deleteAllFilters() {
            Object.keys(this.filterData).forEach((key) => {
                this.deleteFilter(key);
            });
            this.objectData = {};
        },
        getLabelName(key) {
            return this.queryInputs.find((el) => {
                return el.name === key;
            }).label;
        },
        getDisplayValue(key) {
            let result = "";
            if (this.objectData[key]) {
                result = this.objectData[key].displayName || this.objectData[key].text;
            } else {
                if (this.filterData[key]["displayName"]) {
                    result = this.filterData[key].displayName;
                } else {
                    result = this.filterData[key];
                }
            }
            return result;
        },
        emitNormalizedValue(event, key) {
            if (key && event === "") Vue.delete(this.filterData, key);
            this.$emit("input", {
                searchBarData: this.searchBarData,
                filterData: this.filterData,
            });
        },
        setObjectValue(event, key) {
            this.objectData[key] = event;
        },
    },
    /**
     * Note: watching nested properties like this requires Vue.set/Object.assign in parent, e.g. Vue.set(<object passed in v-model>, 'searchBarData', {search: "121212-1212"})
     * Not sure if this function is ever needed, but added for proper v-model support
     */
    watch: {
        value: {
            handler() {
                this.searchBarData = this.getNormalizedValue("searchBarData");
                this.filterData = this.getNormalizedValue("filterData");
            },
            deep: true,
        },
    },
    mounted() {
        this.topSearchBar ? (this.toggleDropdown = false) : (this.toggleDropdown = true);
    },
};
</script>

<style scoped lang="scss">
@import "@/style/variables";
@import "@/style/_variables.scss";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import "bootstrap/scss/buttons";
@import "bootstrap/scss/variables";

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "@/style/deprecated_main";

/* Bootstrap - End */
label {
    flex: 1;
    min-width: 100%;
}

.pseudo-form {
    background-color: #ebf6f8;
    padding: 20px;
}

.pseudo-form > button {
    min-width: 132px;
    display: inline-block;
    margin-left: 18px;
    vertical-align: top;
    margin-top: 8px;
}

.no-padding {
    padding: 0px 10px 0px 10px !important;
}

.filter-wrapper {
    min-height: 46px;
    width: calc(100% - 150px);
    border-radius: 4px;
    background-color: #d6e7ed;
    padding: 8px 8px 0px 8px;
    color: #55676d;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 22px;
    display: inline-block;
}

.filter {
    height: 30px;
    border-radius: 4px;
    background-color: #277692;
    font-style: normal;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    padding-left: 14px;
    padding-right: 14px;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 8px;
    border: none;
}

.filter img {
    height: 8px;
    width: 8px;
    margin-bottom: 4px;
}

.filterWidget {
    margin: 0px auto 0px auto;
    max-width: 100%;
}

.activeFilters {
    padding: 0 40px 40px 40px;
    flex-direction: row;
}

.topSearchBar {
    padding: 20px;
    height: 110px;
}

.searchBar {
    flex: 1;
}

.dropdownArrow {
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-bottom: 4px;
    margin-right: 11px;
}

form {
    background-color: #ebf6f8;
    padding: 20px;
}

.searchButton {
    flex: 0;
    height: 46px !important;
    margin-left: 20px;
    min-width: 148px;
}

label {
    height: 24px;
    color: #277692;
    font-family: Roboto Medium;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
}

img {
    height: 12px;
    width: 12px;
    margin-left: 12px;
}

.rotate {
    transform: rotate(180deg);
}

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
    max-height: 300px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
