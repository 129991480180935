import tabEvents from "@/tabEvents";
import Vue from "vue";
import { getTimeout } from "@/api";
import emitter from "tiny-emitter/instance";

/**
 * componentStore är ett globalt objekt som innehåller objektsreferenser till alla existerande tabb-komponenter (öppna tabbar). componentStore kan _inte_ ligga i state eftersom
 * komponenterna muterar sig själva, och state får bara muteras av setters/mutations.
 *
 * tabs är en array i state som innehåller referenser till öppna tabbar, och sköter mappningen mellan state och componentStore. Den innehåller även meta-data om komponenten,
 * t.ex. när den öppnades, om det är en sido-tabb, etc.
 * Notera att index i tabs-arrayen skiljer sig från själva id:t i tabs-objektet och i componentStore. tabs[index] används typiskt bara i mutations, medan alla actions bör använda
 * tab.id.
 *
 * currentTabId är id:t på den huvud-tabb som är öppen (i motsats till sido-tabbar, som per definition alltid är öppna).
 *
 * klinikenMenu är en array med de tabbar som hämtats från BE och som ska synas i vänstermenyn.
 *
 * Pga risken att göra fel i den här relativt komplexa modulen har jag försökt vara noga med att inte anropa state i actions utan bara getters. Notera att tabs.gettern har mer
 * information är som finns i state, t.ex. patientBound eftersom den informationen sparas i komponenten själv.
 * Alla actions som öppnar eller stänger tabbar körs synkront vare sig de anropas med await eller inte, inbakade i ett promise med setTimeout.
 */

let componentStore = {};

const getDefaultState = () => {
    return {
        menu: [],
        tabs: [],
        currentTabId: NaN,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        newTabId(state) {
            return state.tabs.length ? Math.max(...state.tabs.map((el) => el.id)) + 1 : 1;
        },
        tabs(state) {
            return state.tabs.map((tab) => {
                return {
                    id: tab.id,
                    component: componentStore[tab.id],
                    createdTime: tab.createdTime,
                    openedTime: tab.openedTime,
                    parameters: tab.parameters,
                    displaySpinner: tab.displaySpinner,
                    hideScroll: tab.hideScroll,
                    isSideTab: tab.isSideTab,
                    visible: tab.id === state.currentTabId, // Allow "overwriting" name and patientbound
                    patientBound: tab.patientBound || componentStore[tab.id].patientBound,
                    tabName: tab.tabName || componentStore[tab.id].tabName,
                    hasUnsavedData: tab.hasUnsavedData,
                };
            });
        },
        menu(state) {
            return state.menu.map((rubrik) => {
                const lankar = rubrik.lankar.map((lank) => {
                    const tabId = state.tabs.find((tab) => {
                        return componentStore[tab.id].name === lank.komponent;
                    });
                    const open = tabId !== undefined;
                    const visible = state.currentTabId === tabId;
                    return { ...lank, tabId: tabId, open: open, visible: visible };
                });
                return { ...rubrik, lankar: lankar };
            });
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
            Object.assign(componentStore, {});
        },
        setMenu(state, menu) {
            state.menu = menu;
        },
        showTab(state, { id, parameters }) {
            // Only show tab if it's not a side tab and thus already visible
            let index = state.tabs.findIndex((item) => item.id === id);
            if (!state.tabs[index].isSideTab) {
                state.currentTabId = id;
                Vue.set(state.tabs[index], "openedTime", new Date().getTime());
                Vue.set(state.tabs[index], "parameters", parameters);
            }
        },
        openTab(state, { component, id, parameters }) {
            let tab = {
                id,
                createdTime: new Date().getTime(),
                parameters,
                hasUnsavedData: false,
            };
            state.tabs.push(tab);
            componentStore[id] = component;
        },
        closeTab(state, id) {
            const index = state.tabs.findIndex((el) => {
                return id === el.id;
            });
            if (index !== -1) {
                delete componentStore[id];
                state.tabs.splice(index, 1);
            }
        },
        displaySpinner(state, { id, flag }) {
            let index = state.tabs.findIndex((el) => {
                return id === el.id;
            });
            if (index !== -1) Vue.set(state.tabs[index], "displaySpinner", flag);
        },
        setSideTab(state, { id, flag }) {
            let index = state.tabs.findIndex((el) => {
                return id === el.id;
            });
            Vue.set(state.tabs[index], "isSideTab", flag);
        },
        setHideScroll(state, { id, flag }) {
            let index = state.tabs.findIndex((el) => {
                return id === el.id;
            });
            Vue.set(state.tabs[index], "hideScroll", flag);
        },
        setPatientBound(state, { id, flag }) {
            let index = state.tabs.findIndex((el) => {
                return id === el.id;
            });
            Vue.set(state.tabs[index], "patientBound", flag);
        },
        setTabName(state, { id, name }) {
            let index = state.tabs.findIndex((el) => {
                return id === el.id;
            });
            Vue.set(state.tabs[index], "tabName", name);
        },
        setOrderOfTabs(state, newOrder) {
            Vue.set(state, "tabs", newOrder);
        },
        hasUnsavedData(state, { id, value }) {
            let index = state.tabs.findIndex((el) => {
                return id === el.id;
            });
            Vue.set(state.tabs[index], "hasUnsavedData", value);
        },
    },
    actions: {
        openTab({ rootState, commit, getters }, { component, parameters }) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    // First, check if tab already open/exists...
                    let tab = getters.tabs.find((tab) => tab.component === component);
                    let id;
                    // ...if it's not, create new.
                    if (typeof tab === "undefined") {
                        // If it's patientBound and if patient is not selected abort opening and instead trigger patient select.
                        if (component.patientBound && (!rootState.patientData || !rootState.patientData.currentId)) {
                            tabEvents.$emit("patientSelectRequired", component);
                        }
                        // Else create/open a new tab.
                        else {
                            id = getters.newTabId;
                            commit("openTab", { component: component, id: id, parameters: parameters });
                        }
                    } else id = tab.id;
                    // Show the tab called upon, by new or existing id
                    if (id) commit("showTab", { id: id, parameters: parameters });
                    resolve();
                }, 0);
            });
        },
        displaySpinner({ getters, commit }, { id, display, ignoreTimeout }) {
            let tempId = id;
            if (isNaN(id)) {
                let tab = getters.tabs.find((tab) => tab.component === id);
                if (tab) tempId = tab.id;
            }
            if (!id) return;
            commit("displaySpinner", { id: tempId, flag: display });

            if (display === true && !ignoreTimeout) {
                // failsafe, turn off spinner after timeout
                setTimeout(() => {
                    commit("displaySpinner", { id: tempId, flag: false });
                }, getTimeout());
            }
        },
        closeTabWithUnsavedDataCheck({ getters, commit, dispatch }, id) {
            return new Promise((resolve) => {
                let tempId = id;
                setTimeout(() => {
                    if (isNaN(id)) {
                        // Om id inte är ett nummer utan en komponent letar vi upp tabben och plockar ut id:t
                        let tab = getters.tabs.find((tab) => tab.component === id);
                        if (tab) tempId = tab.id;
                    }

                    let tab = getters.tabs.find((el) => {
                        return el.id === tempId;
                    });
                    let isSideTab;
                    if (tab) isSideTab = tab.isSideTab || false;

                    if (tab && tab.hasUnsavedData) {
                        emitter.emit("open-dialog", {
                            type: "warning",
                            description:
                                "Du har en flik som innehåller osparad data. Vill du gå vidare utan att spara?",
                            buttons: [
                                {
                                    title: "Avbryt",
                                    type: "secondary",
                                    action: () => {
                                        dispatch("tabs/showTab", { id: tab.id }, { root: true });
                                    },
                                },
                                {
                                    title: "Ja, gå vidare utan att spara",
                                    type: "primary",
                                    action: () => {
                                        commit("closeTab", tempId);
                                        if (isSideTab === false) dispatch("adjustSideTabs");
                                        resolve();
                                    },
                                },
                            ],
                        });
                    } else {
                        commit("closeTab", tempId);
                        if (isSideTab === false) dispatch("adjustSideTabs");
                        resolve();
                    }
                }, 0);
            });
        },
        async closeTab({ getters, commit, dispatch }, id) {
            let tempId = id;
            if (isNaN(id)) {
                // Om id inte är ett nummer utan en komponent letar vi upp tabben och plockar ut id:t
                let tab = getters.tabs.find((tab) => tab.component === id);
                if (tab) tempId = tab.id;
            }

            let tab = getters.tabs.find((el) => {
                return el.id === tempId;
            });
            let isSideTab;
            if (tab) isSideTab = tab.isSideTab || false;

            commit("closeTab", tempId);

            if (isSideTab === false) await dispatch("adjustSideTabs");
        },
        async adjustSideTabs({ getters, commit, dispatch }) {
            let numberOfSideTabs = getters.tabs.filter((el) => el.isSideTab).length;

            // If mainTabs is empty, reduce number of sideTabs so that mainTabs is at least 1.
            if (getters.tabs.length - numberOfSideTabs === 0 && getters.tabs.length > 0) {
                dispatch("setSideTabs", numberOfSideTabs - 1);
            }

            // the next tab to be opened is the one with the highest creation or last opened time.
            let orderedMainTabs = getters.tabs
                .filter((el) => !el.isSideTab)
                .sort((a, b) => {
                    return (a.openedTime || a.createdTime) - (b.openedTime || b.createdTime);
                });
            if (orderedMainTabs.length > 0) commit("showTab", { id: orderedMainTabs[orderedMainTabs.length - 1].id });
        },
        showTab({ commit }, { id, parameters }) {
            commit("showTab", { id, parameters });
        },
        /*
         * Close all tabs where patientBound === true. Typically used when changing patients.
         */
        closeAllPatientBound({ commit, getters }) {
            let patientBoundTabs = [];
            getters.tabs.forEach((el) => {
                if (el.patientBound) {
                    patientBoundTabs.push(el);
                }
            });
            patientBoundTabs.forEach((el) => {
                commit("closeTab", el.id);
            });
        },
        setSideTabs({ getters, commit }, count) {
            let i = NaN;
            let orderedMainTabs = getters.tabs
                .filter((el) => !el.isSideTab)
                .sort((a, b) => {
                    return (a.openedTime || a.createdTime) - (b.openedTime || b.createdTime);
                });
            let numberOfSideTabs = getters.tabs.filter((el) => el.isSideTab).length;
            switch (true) {
                // number is equal to or more than currently open tabs, exit
                case count >= getters.tabs.length:
                    return true;
                // number is more than currently active sideViews: flag last ordered main tabs as sideTabs
                case count > numberOfSideTabs:
                    for (i = numberOfSideTabs; i < count; i++) {
                        commit("setSideTab", { id: orderedMainTabs[orderedMainTabs.length - 1].id, flag: true });
                        orderedMainTabs.splice(orderedMainTabs.length - 1, 1);
                    }
                    break;
                // number is less than currently active sideViews
                case count < numberOfSideTabs:
                    for (i = numberOfSideTabs; i > count; i--)
                        commit("setSideTab", {
                            id: getters.tabs.filter((el) => el.isSideTab)[numberOfSideTabs - 1].id,
                            flag: false,
                        });
                    break;
            }

            // After tabs have been adjusted, show currently active tab.
            orderedMainTabs = getters.tabs
                .filter((el) => !el.isSideTab)
                .sort((a, b) => {
                    return (a.openedTime || a.createdTime) - (b.openedTime || b.createdTime);
                });
            if (orderedMainTabs.findIndex((el) => el.id === getters.currentTabId) === -1) {
                commit("showTab", { id: orderedMainTabs[orderedMainTabs.length - 1].id });
            }
        },
    },
};
