<!-- Baserad på https://www.npmjs.com/package/@cone2875/vue-formulate-select -->
<template>
    <v-select
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
        v-model="context.model"
        v-bind="attributes"
        @close="context.blurHandler"
        @search="onSearch"
        label="label"
        :filterable="false"
        :options="computedOptions"
        :reduce="(option) => option.value"
        @option:selected="onSelected"
        :components="{ OpenIndicator }"
        ref="vsel"
    >
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">Det finns inga alternativ</template>
    </v-select>
</template>

<script>
import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
import vSelect from "vue-select";
import { debounce } from "lodash";
import { getConfig, klinikenApi } from "@/api";

export default {
    name: "FormulateAutocomplete",
    mixins: [FormulateInputMixin],
    components: {
        vSelect,
    },
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            options: [],
            OpenIndicator: {
                render: (createElement) => createElement("span", ""),
            },
        };
    },
    computed: {
        computedOptions() {
            if (
                this.options.length === 0 &&
                this.attributes.initial_option !== undefined &&
                this.attributes.initial_option !== {}
            ) {
                return [
                    {
                        value: this.attributes.initial_option.id,
                        label: `${this.attributes.initial_option.code} ${this.attributes.initial_option.displayName}`,
                        ...this.attributes.initial_option,
                    },
                ];
            }
            return this.options;
        },
    },
    watch: {
        async "context.model"(newVal) {
            if (newVal === "") {
                this.options = [];

                await this.init(this.context.model);
            }
        },
    },
    methods: {
        onSelected(option) {
            this.$emit("selected", option);
        },
        async init(id) {
            if (id) {
                try {
                    const res = await klinikenApi.get(`${this.url}${id}/`, getConfig());
                    const obj = res.data;

                    this.options = [
                        {
                            value: obj.id,
                            label: `${obj.code} ${obj.displayName}`,
                            ...obj,
                        },
                    ];
                } catch (error) {
                    throw new Error(error);
                }
            } else {
                try {
                    const res = await klinikenApi.get(`${this.url}`, getConfig());

                    this.options = res.data.results.map((obj) => {
                        return {
                            value: obj.id,
                            label: `${obj.code} ${obj.displayName}`,
                            ...obj,
                        };
                    });
                } catch (error) {
                    throw new Error(error);
                }
            }
        },
        async onSearch(search, loading) {
            let timeoutId = null;
            clearTimeout(timeoutId);

            if (search.length >= 3) {
                if (this.url.endsWith("/")) {
                    loading(true);
                    this.search(`${this.url}all/?q=${encodeURIComponent(search)}`, loading, this);
                } else {
                    loading(true);
                    this.search(`${this.url}/all/?q=${encodeURIComponent(search)}`, loading, this);
                }
            }

            if (search.length === 0) {
                timeoutId = setTimeout(async () => {
                    await this.init();
                }, 300); // Adjust the delay (in milliseconds) as per your requirements
            }
        },
        search: debounce((url, loading, vm) => {
            return klinikenApi.get(url, getConfig()).then((res) => {
                vm.options = res.data.map((obj) => {
                    return {
                        value: obj.id,
                        label: `${obj.code} ${obj.displayName}`,
                        ...obj,
                    };
                });
                loading(false);
            });
        }, 350),
    },

    async created() {
        await this.init(this.context.model);
    },
};
</script>
