import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

function translateErrorMessage(translationMap = new Map(), msg) {
    for (const entry of translationMap) {
        msg = msg.replace(entry[0], entry[1]);
    }
    return msg;
}

const getDefaultState = () => {
    return {
        intygsmallar: [],
        intygsmall: {},
        intygstyp: {},
        intyg: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        intygsmallar(state) {
            return state.intygsmallar;
        },
        intygsmall(state) {
            return state.intygsmall;
        },
        intygstyp(state) {
            return state.intygstyp;
        },
        intyg(state) {
            return state.intyg;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setIntygsmallar: (state, data) => {
            state.intygsmallar = data;
        },
        setIntygsmall: (state, data) => {
            state.intygsmall = data;
        },
        setIntygtyp: (state, data) => {
            state.intygstyp = data;
        },
        setIntyg: (state, data) => {
            state.intyg = data;
        },
    },
    actions: {
        async getIntygsTyp({ commit }, id) {
            let response;
            try {
                response = await klinikenApi.get(`intyg/kodverk/typavintyg/${id}`, getConfig());
                commit("setIntygtyp", response.data);
            } catch (error) {
                openDialog("Kunde inte hämta intygstyp" + getErrorMessage(error), "warning");
            }
            return response.data;
        },
        async loadIntygsmallar({ commit }, typ, failSilently = false) {
            const intygsmallarFetch = await klinikenApi.get(`intyg/mallar/?typ=${typ}`, getConfig());
            let intygsmallar = intygsmallarFetch.data.results;

            try {
                commit("setIntygsmallar", intygsmallar);
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta intygsmallar" + getErrorMessage(e), "warning");
                }
            }
        },
        async loadIntygsmall({ commit }, data, failSilently = false) {
            const typ = data.typ_display.code.toLowerCase();
            const intygsmallFetch = await klinikenApi.get(`intyg/mallar/${typ}/${data.id}/`, getConfig());
            let intygsmall = intygsmallFetch.data;

            try {
                commit("setIntygsmall", intygsmall);
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta intygsmall." + getErrorMessage(e), "warning");
                }
            }
        },
        async saveIntygsmall({}, { intygsmall }) {
            intygsmall.typ = intygsmall.typAvIntyg.id;
            return await klinikenApi.post(`intyg/mallar/${typ}/`, intygsmall, getConfig());
        },
        async updateIntygsmall({}, { intygsmall }) {
            intygsmall.typ = intygsmall.typAvIntyg.id;
            return await klinikenApi.put(`/intyg/mallar/${typ}/${intygsmall.id}/`, intygsmall, getConfig());
        },
        async deleteIntygsmall({}, intygsmall) {
            const typ = intygsmall.typ_display.code.toLowerCase();
            const deleteIntygsmallRequest = await klinikenApi.delete(
                `intyg/mallar/${typ}/${intygsmall.id}/`,
                getConfig()
            );
            return deleteIntygsmallRequest;
        },
        async fetchIntyg({ rootState, commit }, failSilently = false) {
            await klinikenApi
                .get("/intyg/intyg/", getConfig({ params: { patient: rootState.patientData.currentId } }))
                .then((response) => {
                    let results = response.data.results;
                    results.sort((a, b) => {
                        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                    });
                    commit("setIntyg", results);
                })
                .catch((e) => {
                    if (!failSilently) openDialog(getErrorMessage(e), "error");
                });
        },
    },
};
