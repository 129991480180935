<template>
    <div>
        <div>
            <h2>Lägg till uppmärksamhetssignal</h2>

            <sign-frame
                v-if="signVisible"
                title="uppmärksamhetssignal"
                :url="signUrl"
                :data="signData"
                @success="handleSignSuccess"
                @error="handleSignError"
                @cancel="handleSignCancel"
            />

            <form @submit.prevent id="alertTyp" class="alertPicker" :class="{ 'disabled-form': lockAlertType }">
                <span>Typ av uppmärksamhetssignal</span>
                <select-widget
                    name="alertType"
                    :placeholder="placeholder"
                    :disabled="lockAlertType"
                    @input="lockAlertType = !lockAlertType"
                    v-model="alertType"
                    :options="alertInformationOptions"
                ></select-widget>
                <ButtonGroup v-if="lockAlertType" class="change-alertinformation-type">
                    <Button type="button" role="button" variant="contour" size="slim" @click="unlockAlertType()">
                        Ändra typ av uppmärksamhetssignal
                    </Button>
                </ButtonGroup>
            </form>
            <component
                class="chosenComponent"
                :is="alertType"
                @save="saveForm"
                @sign="saveAndSignForm"
                @cancelAlertForm="cancelForm"
                @hasUnsavedData="hasUnsavedDataValue = $event"
            />
        </div>
    </div>
</template>

<script>
    import communicableForm from "@/tabs/AlertInformation/Forms/communicableForm.vue";
    import otherHyperSensitivityForm from "@/tabs/AlertInformation/Forms/otherHyperSensitivityForm.vue";
    import pharmaceuticalHyperSensitivityForm from "@/tabs/AlertInformation/Forms/pharmaceuticalHyperSensitivityForm.vue";
    import restrictionOfCareForm from "@/tabs/AlertInformation/Forms/restrictionOfCareForm.vue";
    import seriousDiseaseForm from "@/tabs/AlertInformation/Forms/seriousDiseaseForm.vue";
    import treatmentForm from "@/tabs/AlertInformation/Forms/treatmentForm.vue";
    import unstructuredForm from "@/tabs/AlertInformation/Forms/unstructuredForm.vue";

    import BaseTab from "@/tabs/mixins/BaseTab";
    import { mapActions, mapGetters } from "vuex";
    import widgets from "@/components/widgets/inputs";
    import RecursiveForm from "@/tabs/Journalanteckningar/RecursiveForm.vue";
    import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
    import linkEvents from "@/linkEvents";
    import dialogEvents from "@/dialogEvents";
    import SkeletonBox from "@/components/widgets/Skeleton.vue";
    import { getErrorMessage } from "@/api";
    import { openDialog, flashMessage } from "@/utils";
    import Button from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import SignFrame from "@/components/Auth/SignFrame.vue";
    import {
        STATUS_SIGN_FAILED,
        STATUS_SIGNED,
        STATUS_SIGNING,
        checkType,
        copy,
    } from "@/store/modules/alertInformation";

    export default {
        name: "AlertInformationForm",
        tabName: "Lägg till Uppmärksamhetssignal",
        namespaced: true,
        extends: BaseTab,
        patientBound: true,
        components: {
            ButtonGroup,
            Button,
            SkeletonBox,
            ...widgets,
            RecursiveForm,
            InputWrapper,
            CommunicableDiseaseForm: communicableForm,
            otherHyperSensitivityForm: otherHyperSensitivityForm,
            pharmaceuticalHyperSensitivityForm: pharmaceuticalHyperSensitivityForm,
            RestrictionOfCareForm: restrictionOfCareForm,
            SeriousDiseaseForm: seriousDiseaseForm,
            TreatmentForm: treatmentForm,
            UnstructuredForm: unstructuredForm,
            SignFrame,
        },
        props: {
            tabId: Number,
            parameters: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                hasUnsavedDataValue: false,
                placeholder: "Välj uppmärksamhetssignal",
                selected: null,
                formComponents: [
                    {
                        label: "Signeringsansvarig",
                        component: widgets.SelectWidget,
                        parameters: {},
                        colspan: 4,
                    },
                    {
                        label: "Kontaktdatum",
                        component: widgets.DateWidget,
                        parameters: {
                            name: "created_at",
                            disabled: true,
                        },
                        colspan: 4,
                    },
                ],
                alertType: null, // Vald journaltyp med trädstruktur
                alertInformationForms: [
                    { text: "Smitta", value: "CommunicableDiseaseForm" },
                    {
                        text: "Läkemedelsöverkänslighet",
                        value: "pharmaceuticalHyperSensitivityForm",
                    },
                    { text: "Överkänslighet", value: "otherHyperSensitivityForm" },
                    {
                        text: "Avsteg från vårdrutin",
                        value: "RestrictionOfCareForm",
                    },
                    { text: "Allvarlig sjukdom", value: "SeriousDiseaseForm" },
                    { text: "Behandling", value: "TreatmentForm" },
                    { text: "Övrigt", value: "UnstructuredForm" },
                ],
                sektioner: null,
                initialized: false,
                inhoppData: null,
                lockAlertType: false,
                legalAuthenticator: null,
                showSpinner: false,

                signVisible: false,
                signUrl: "",
                signData: "",
            };
        },
        computed: {
            ...mapGetters("patientData", ["patient"]),
            hasUnsavedData() {
                return this.hasUnsavedDataValue;
            },
            alertInformationOptions() {
                return this.alertInformationForms.map((form) => {
                    return { text: form.text, value: form.value };
                });
            },
            isItLocked() {
                return this.lockAlertType;
            },
        },
        methods: {
            ...mapActions("alertInformation", ["saveAlertInformation"]),
            async saveForm(data) {
                data.patient = this.patient.id;
                try {
                    const response = await this.saveAlertInformation(data);
                    if (response.status === 201) {
                        await this.$store.dispatch("alertInformation/loadAlertInformationSymbol", {
                            patientId: this.patient.id,
                        });
                        await this.$store.dispatch("alertInformation/loadAlertInformationList", {
                            patientId: this.patient.id,
                        });
                        await this.$store.dispatch("tabs/closeTab", this.tabId);
                    }
                } catch (e) {
                    openDialog(getErrorMessage(e), "error");
                }
            },
            select(value) {
                this.selected = value;
            },
            updateForm(data) {
                this.lockAlertType = true;
                this.alertType = data;
            },
            unlockAlertType() {
                if (this.hasUnsavedData) {
                    dialogEvents.$emit("open", {
                        type: "warning",
                        description:
                            "Om du byter typ av uppmärksamhetssignal kommer all data i formuläret att försvinna. Vill du fortsätta?",
                        buttons: [
                            {
                                title: "Nej",
                                type: "secondary",
                            },
                            {
                                title: "Ja",
                                type: "primary",
                                action: () => {
                                    this.lockAlertType = false;
                                    this.alertType = null;
                                },
                            },
                        ],
                    });
                } else {
                    this.lockAlertType = false;
                    this.alertType = null;
                }
            },
            cancelForm() {
                this.closeTab(this.hasUnsavedData);
            },
            async saveAndSignForm(data) {
                data.patient = this.patient.id;
                data.comingFromSign = true;
                try {
                    const response = await this.saveAlertInformation(data);
                    if (response.status === 201) {
                        response.data.typeOfAlertInformation = data.type;

                        const alert = response.data;

                        this.$store.commit("alertInformation/setStatus", STATUS_SIGNING);

                        let signingAlert = copy(alert);
                        let type = checkType(signingAlert.typeOfAlertInformation);

                        let url = `/uppmarksamhetssignal/${type}/`;
                        if (signingAlert.pk !== null && signingAlert.pk !== undefined) {
                            url = `${url}${signingAlert.pk}/`;
                        }
                        this.signData = signingAlert;
                        this.signUrl = url;
                        this.signVisible = true;
                    }
                } catch (error) {
                    openDialog(getErrorMessage(error), "error");
                }
            },
            async handleSignSuccess() {
                flashMessage("Uppmärksamhetssignal signerad");
                this.$store.commit("alertInformation/setStatus", STATUS_SIGNED);
                this.signVisible = false;

                await this.$store.dispatch("alertInformation/loadAlertInformationSymbol", {
                    patientId: this.patient.id,
                });
                await this.$store.dispatch("alertInformation/loadAlertInformationList", {
                    patientId: this.patient.id,
                });
                await this.$store.dispatch("tabs/closeTab", this.tabId);
            },
            handleSignError(errors) {
                this.$store.commit("alertInformation/setStatus", STATUS_SIGN_FAILED);
                openDialog("Misslyckades med att signera Uppmärksamhetssignal." + getErrorMessage(errors), "error");
                this.signVisible = false;
            },
            handleSignCancel() {
                this.signVisible = false;
            },
        },
        created() {
            linkEvents.$on("alertType_changed", this.updateForm);
        },
    };
</script>

<style lang="scss" scoped>
    .change-alertinformation-type {
        justify-content: flex-end;

        button,
        button:hover {
            background-color: white;
        }
    }
</style>

<style scoped>
    .right-opaque > button {
        margin-top: 20px;
    }

    .chosenComponent {
        margin-top: 30px;
        width: 710px;
    }

    .alertPicker {
        margin-top: 40px;
        width: 734px;
        height: auto;
        background-color: #ebf6f8;
        padding: 25px 15px;
    }

    img {
        margin-bottom: 3px;
    }
</style>
