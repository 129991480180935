<template>
    <ModalOverlay>
        <div class="sign-frame">
            <div class="header">
                <h2>Signera {{ title }}</h2>
            </div>

            <div class="body">
                <div v-if="nextStep === ''">
                    <BasicSign
                        v-if="basicEnabled"
                        :url="url"
                        :data="data"
                        @success="handleSign"
                        @error="handleError"
                        @cancel="handleCancel"
                    />
                    <BankIDSign
                        v-if="bankIDEnabled"
                        :url="url"
                        :data="data"
                        @poll="handlePolling"
                        @error="handleError"
                        @cancel="handleCancel"
                    />
                    <NetIDSign
                        v-if="netIDEnabled"
                        :url="url"
                        :data="data"
                        @poll="handlePolling"
                        @error="handleError"
                        @cancel="handleCancel"
                    />
                </div>

                <PollingFrame
                    v-if="nextStep === 'polling'"
                    :message="pollMesssage"
                    :url="pollUrl"
                    :data="pollData"
                    :type="pollType"
                    @success="handleSign"
                    @error="handlePollingError"
                    @cancel="handleCancel"
                />
            </div>
        </div>
    </ModalOverlay>
</template>

<script setup>
    import { computed, ref, toRefs } from "vue";
    import { getAuthenticationMethod } from "@/api";
    import ModalOverlay from "@/components/generic/ModalOverlay.vue";
    import BasicSign from "@/components/Auth/BasicSign.vue";
    import BankIDSign from "@/components/Auth/BankIDSign.vue";
    import NetIDSign from "@/components/Auth/NetIDSign.vue";
    import PollingFrame from "@/components/Auth/PollingFrame.vue";

    const nextStep = ref("");
    const pollMesssage = ref("");
    const pollUrl = ref("");
    const pollData = ref({});
    const pollType = ref("");

    const props = defineProps({
        title: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    });

    const { url, data } = toRefs(props);

    const basicEnabled = computed(() => {
        return getAuthenticationMethod() === "Basic";
    });

    const bankIDEnabled = computed(() => {
        return getAuthenticationMethod() === "BankID";
    });

    const netIDEnabled = computed(() => {
        return getAuthenticationMethod() === "NetID";
    });

    const emit = defineEmits(["success", "error", "cancel"]);

    async function handleSign(data) {
        emit("success", data);
    }

    function handlePolling({ message, url, data, type }) {
        pollMesssage.value = message;
        pollUrl.value = url;
        pollData.value = data;
        pollType.value = type;
        nextStep.value = "polling";
    }

    function handlePollingError(message) {
        emit("error", message);
    }

    function handleError(errors) {
        emit("error", errors);
    }

    function handleCancel() {
        emit("cancel");
    }
</script>

<style lang="scss">
    @import "@/style/variables";

    .sign-frame {
        background-color: white;
        width: 950px;
        min-height: 390px;
        max-height: 750px;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 30px;

        .header {
            background-color: $color-active-dark;
            padding: 21px 28px 21px 28px;

            text-align: center;

            h2 {
                color: white;
                font-size: 38px;
                line-height: 42px;
                text-align: center;
            }
        }

        .body {
            padding: 0 120px 65px;
        }
    }
</style>
