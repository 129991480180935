<template>
    <div class="FilhanteringForhandsvisningOhanteradFiltyp">
        <p>Filen kan inte förhandsvisas. Det beror oftast på filformat som inte stöds.</p>
    </div>
</template>

<script>
export default {
    name: "FilhanteringForhandsvisningOhanteradFiltyp",
    props: ["src"],
};
</script>

<style lang="scss" scoped>
div.FilhanteringForhandsvisningOhanteradFiltyp {
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
