<template>
    <div class="filinlasning">
        <h1>Filinläsning</h1>
        <FilhanteringUploadLocalFiles />
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import uploadIcon from "@/assets/upload.svg";
import FilhanteringUploadLocalFiles from "./FilhanteringUploadLocalFiles.vue";

export default {
    extends: BaseTab,
    name: "FilhanteringFilval",
    tabName: "Filinläsning",
    icon: uploadIcon,
    patientBound: true,
    components: { FilhanteringUploadLocalFiles },
};
</script>

<style lang="scss">
.filinlasning {
    h1 {
        margin: 0 0 26px 0;
    }
}
</style>
