<template>
    <div class="kallelsemall-form">
        <div class="header">
            <h1>{{ title }}</h1>

            <div class="grid-container">
                <div class="grid-item-span-6">
                    <div>
                        <strong>Typ av mall</strong><br />
                        <span>Operation</span>
                    </div>
                    <br />
                    <div>
                        <FormulateForm
                            ref="form"
                            name="kallelseForm"
                            @submit="submitForm"
                            v-model="formValues"
                            :errors="formErrors"
                        >
                            <FormulateInput
                                type="text"
                                name="namn"
                                label="Namn på mall *"
                                help="Max 40 tecken"
                                :value="formValues.namn ?? ''"
                                validation="required"
                                :validation-messages="{
                                    required: 'Namn på mall är obligatoriskt',
                                }"
                                autocomplete="off"
                            />

                            <AdvancedEditor
                                :key="formValues.body"
                                :content="formValues.body"
                                @update:content="updateContent"
                            />

                            <ButtonGroup>
                                <Button type="submit">Spara</Button>
                                <Button type="button" variant="contour" size="secondary" @click="cancel">Avbryt</Button>
                            </ButtonGroup>
                        </FormulateForm>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import cloneDeep from "lodash/cloneDeep";
    import isEqual from "lodash/isEqual";
    import BaseTab from "@/tabs/mixins/BaseTab.js";
    import Button from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import { getConfig, getErrorMessage, klinikenApi } from "@/api.js";
    import { flashMessage, openDialog } from "@/utils/index.js";
    import emitter from "tiny-emitter/instance";
    import AdvancedEditor from "@/components/Editor/AdvancedEditor.vue";

    const DEFAULTVALUES = {
        namn: "",
        body: "",
        json: {},
    };

    export default {
        extends: BaseTab,
        name: "KallelsemallForm",
        components: {
            Button,
            ButtonGroup,
            AdvancedEditor,
        },
        data() {
            return {
                title: "Skapa kallelsemall",
                defaultFormValues: cloneDeep(DEFAULTVALUES),
                formValues: cloneDeep(DEFAULTVALUES),
                formErrors: {},
                json: null,
            };
        },
        computed: {
            hasUnsavedData() {
                return !isEqual(this.defaultFormValues, this.formValues);
            },
        },
        methods: {
            updateContent({ json }) {
                this.json = json;
                this.formValues.json = this.json;
            },

            async submitForm(formData = {}) {
                delete formData.json;

                formData.typ = "operation";
                formData.body = this.json;

                if (this.parameters?.isDuplicating && formData.body === null) {
                    formData.body = this.parameters?.body;
                }

                await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
                try {
                    let response = {};

                    if (this.parameters?.id) {
                        const id = this.parameters?.id;
                        response = await klinikenApi.put(`/kallelser/mallar/${id}/`, formData, getConfig());
                    } else {
                        response = await klinikenApi.post("/kallelser/mallar/", formData, getConfig());
                    }

                    if (response.status === 201 || response.status === 200) {
                        flashMessage(response.status === 201 ? "Kallelsemall skapad" : "Kallelsemall redigerad");

                        emitter.emit("kallelsemall");

                        await this.$store.dispatch("tabs/closeTab", this.tabId);

                        return;
                    }

                    throw new Error("Fel vid skapande/redigering av kallelsemall");
                } catch (error) {
                    this.formErrors = error.response?.data;

                    openDialog(getErrorMessage(error), "error");
                } finally {
                    await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
                }
            },

            cancel() {
                this.closeTab(this.hasUnsavedData);
            },

            _setTabName() {
                if (this.parameters?.isEditing) {
                    this.title = "Redigera kallelsemall";
                    this.$store.commit("tabs/setTabName", { id: this.tabId, name: "Redigera kallelsemall" });
                } else if (this.parameters?.isDuplicating) {
                    this.title = "Duplicera kallelsemall";
                    this.$store.commit("tabs/setTabName", { id: this.tabId, name: "Duplicera kallelsemall" });
                } else {
                    this.title = "Skapa kallelsemall";
                    this.$store.commit("tabs/setTabName", { id: this.tabId, name: "Skapa kallelsemall" });
                }
            },
        },

        async created() {
            this._setTabName();

            if (!this.parameters?.isDuplicating && !this.parameters?.isEditing) {
                this.defaultFormValues = cloneDeep(DEFAULTVALUES);
                this.formValues = cloneDeep(DEFAULTVALUES);

                return;
            }

            if (this.parameters && Object.keys(this.parameters).length > 0) {
                this.formValues.body = this.parameters.body; // Needed otherwise body will be empty
                this.formValues.namn = this.parameters.namn;

                if (typeof this.formValues.body === "object" && Object.keys(this.formValues.body).length > 0) {
                    this.formValues.json = this.formValues.body;
                }

                this.defaultFormValues = cloneDeep(this.formValues);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .kallelsemall-form {
        position: relative;

        ::v-deep(.formulate-input) {
            .formulate-input-help {
                &:before {
                    content: url("@/assets/info_gray.svg");
                    margin-right: 5px;
                    position: relative;
                    bottom: -2px;
                }
            }
        }

        .grid-container {
            display: flex;
            flex-wrap: wrap;

            .grid-item {
                flex-basis: calc(100% / 12);
                padding: 10px;
            }

            .grid-item-span-6 {
                flex-basis: calc(100% / 12 * 6); /* Set the width to span 6 columns */
            }
        }
    }
</style>
