<template>
    <div class="textContainer">
        <div v-if="details">
            <div class="newbox pt-4">
                <p class="left">ICD-kod</p>
                <p class="right">{{ details.code_display.displayName }}</p>
            </div>
            <div class="newbox">
                <p class="left">Kommentar</p>
                <p class="right">{{ details.comment }}</p>
            </div>
            <div class="newbox">
                <p class="left">Smittväg</p>
                <p class="right">
                    {{ details.routeOfTransmission_display.displayName }}
                </p>
            </div>
            <div class="newbox pb-4">
                <p class="left">Giltighetstid</p>
                <p class="right">
                    {{ details.validityTimePeriodStart }} -
                    {{ details.validityTimePeriodEnd ? details.validityTimePeriodEnd : "~" }}
                </p>
            </div>
        </div>
        <div v-if="!details" class="skeletonBox" :class="{ nullifiedRed: item.is_nullified }">
            <skeleton height="14px" :min-width="15" :max-width="40" radius="3px" margin="0 0 20px 0"></skeleton>
            <skeleton height="14px" :min-width="15" :max-width="40" radius="3px" margin="0 0 20px 0"></skeleton>
            <skeleton height="14px" :min-width="15" :max-width="40" radius="3px" margin="0 0 20px 0"></skeleton>
            <skeleton height="14px" :min-width="15" :max-width="40" radius="3px" margin="0 0 20px 0"></skeleton>
            <skeleton height="14px" :min-width="15" :max-width="40" radius="3px" margin="0 0 20px 0"></skeleton>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { openDialog } from "@/utils";
import { getErrorMessage } from "@/api";

import Skeleton from "@/components/widgets/Skeleton.vue";

export default {
    name: "communicableDiseaseTextbox",
    props: ["item"],
    components: {
        Skeleton,
    },
    data() {
        return {
            type: "cd",
            details: null,
        };
    },
    methods: {
        ...mapActions("alertInformation", ["loadDetailsInformation"]),
        async loadDetails() {
            try {
                const response = await this.loadDetailsInformation({
                    pk: this.item.pk,
                    type: this.type,
                });
                if (response) {
                    this.details = response;
                }
            } catch (e) {
                openDialog(getErrorMessage(e), "error");
            }
        },
    },
    mounted() {
        this.loadDetails();
    },
};
</script>

<style scoped>
.textContainer {
    background-color: #f3f5f9;
    margin: auto -15px auto -15px;
    flex: 1 1 auto;
}

.disabledAlertInformation {
    opacity: 0.7;
}

.newbox {
    margin-left: 51px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.left {
    display: flex;
    flex-direction: column;
    width: 15%;
    color: #277692;
    font-family: Roboto Medium;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}

.right {
    display: flex;
    flex-direction: column;
    width: 75%;
    color: #354052;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}

.skeletonBox {
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    background-color: #f3f5f9;
    width: 1015px;
}
</style>
