import { AbilityBuilder, Ability } from "@casl/ability";
import userData from "./userData";

const getDefaultState = () => {
    return {
        permissions: [],
        abilities: {},
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setRules(state, permissions) {
            state.permissions = permissions;
        },
        setAbilities(state, abilities) {
            state.abilities = abilities;
        },
    },
    getters: {
        getRules(state) {
            return state.rules;
        },
        can: (state) => (data) => {
            const currentProfile = userData.state.currentProfile;
            data.rollDisplay = currentProfile.roll_display.displayName;
            if (!data.action || !data.subject) return;
            const can = state.abilities.can(data.action, data.subject);
            return can;
        },
    },
    actions: {
        loadRules({ commit, dispatch }, permissions) {
            commit("setRules", permissions);
            dispatch("defineAbilitiesFor", permissions);
        },
        async defineAbilitiesFor({ commit }, permissions) {
            const { can, cannot, initialRules, build } = new AbilityBuilder(Ability);
            permissions &&
                permissions.forEach((rule) => {
                    can(rule.action, rule.subject);
                });
            const abilities = await build();
            commit("setAbilities", abilities);
            return abilities;
        },
    },
};
