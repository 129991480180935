<template>
    <!--
    CustomTableCell kompilerar och renderar en template i runtime. <td>-tagg läggs automatiskt på.
    
    Hela template:n körs i den här komponentens scope, så det är viktigt att definiera alla komponenter som kan komma att användas här
    (jag har lagt in SkeletonBox och StatusWidget).
    
          template: `
              <status-widget status="makulerad" />
          `
    
    V-tooltip är globalt scope, så finns tillgänglig i den här komponenten, men notera att v-tooltip
    är ett directive, och rena text-strängar måste i directives omges av citationstecken, t.ex.:

          template: `
              <span v-tooltip="'tooltip'">Hovra över så visas texten tooltip</span>
          `

    Backticks kan även användas för att escape:a ytterligare en nivå. Behövs bl.a. när v-tooltips text genereras dynamiskt:

    template: (item) => {
            return '<div v-tooltip="`'+item.info_till_opplaneringen+'`">' + ((item.atgardad) ? "Ja" : "Nej") + '</div>';
        }

  -->
    <runtime-template-compiler :template="'<td>' + template + '</td>'" :key="key" />
</template>
<script>
import { RuntimeTemplateCompiler } from "vue-runtime-template-compiler";
import SkeletonBox from "@/components/widgets/Skeleton.vue";
import StatusWidget from "@/components/widgets/StatusWidget.vue";

export default {
    components: {
        RuntimeTemplateCompiler,
        //eslint-disable-next-line
        SkeletonBox,
        //eslint-disable-next-line
        StatusWidget,
    },
    methods: {
        fetchImg(img) {
            const imgUrl = new URL(`../../assets/${img}`, import.meta.url).href;
            return imgUrl;
            // const imgUrl = new URL(`../../assets/${img}`, import.meta.url).href;
            // return imgUrl;
        },
    },
    props: {
        template: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            key: 0,
        };
    },
    watch: {
        template() {
            this.key++;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep img {
    vertical-align: middle;
}
</style>
