<template>
    <div :class="context.classes.element" :data-type="context.type">
        <FormulateSlot name="prefix" :context="context">
            <component :is="context.slotComponents.prefix" v-if="context.slotComponents.prefix" :context="context" />
        </FormulateSlot>
        <div v-if="isPopupOpen && hasError === false" class="fullscreen-overlay"></div>
        <div class="help-text-wrapper">
            <textarea
                :name="context.name"
                v-model="context.model"
                :placeholder="context.placeholder"
                :disabled="context.disabled"
                :readonly="context.readonly"
                :class="context.classes.input"
                ref="textareaInput"
                :style="{ zIndex: isPopupOpen ? '1000' : 'auto' }"
            />
            <button class="circle-button" type="button" @click="openHelpText">
                <div class="circle">
                    <img src="@/assets/frasregister.svg" />
                </div>
                Frasregister
            </button>

            <div
                class="popup-helpinfo-wrapper"
                v-if="isPopupOpen && hasError === false"
                :style="{ zIndex: isPopupOpen ? '1000' : 'auto' }"
            >
                <div class="popup-helpinfo">
                    <div class="popup-heading">
                        <h2>Frasregister</h2>
                        <button class="close-button" @click="closePopup">
                            <img src="@/assets/close_modal.svg" alt="Stäng" />
                        </button>
                    </div>
                    <div class="popup-content">
                        <div v-for="(option, index) in options" :key="index">
                            <strong @click="insertWord(option.value)">{{ option.value }}</strong>
                            <span>{{ option.text }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FormulateSlot name="suffix" :context="context">
            <component :is="context.slotComponents.suffix" v-if="context.slotComponents.suffix" :context="context" />
        </FormulateSlot>
    </div>
</template>

<script>
    import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
    import { openDialog } from "@/utils";
    import Button from "@/components/Button.vue";
    import { mixin as clickaway } from "vue-clickaway";

    export default {
        name: "FormulateFrasregister",
        mixins: [FormulateInputMixin, clickaway],
        components: {
            Button,
        },
        data() {
            return {
                isPopupOpen: false,
                hasError: false,
            };
        },
        computed: {
            options() {
                if (this._validateOptions(this.context.options)) {
                    return this.context.options;
                }
            },
        },

        methods: {
            openHelpText() {
                if (this.hasError) {
                    openDialog("Du har ett felmeddelande, kontrollera konsolen", "warning");
                }
                this.isPopupOpen = true;
            },

            /**
             * Inserts a word into the model at the current cursor position, with a space if needed.
             * @param {string} [word=""] - The word to be inserted.
             */
            insertWord(word = "") {
                const cursorPosition = this.$refs.textareaInput.selectionStart;
                const inputArray = this.context.model.split("");

                if (word !== "" && cursorPosition > 0 && inputArray[cursorPosition - 1] !== " ") {
                    inputArray.splice(cursorPosition, 0, " " + word);
                } else {
                    inputArray.splice(cursorPosition, 0, word);
                }

                this.context.model = inputArray.join("");
                this.isPopupOpen = false;
            },

            closePopup() {
                this.isPopupOpen = false;
            },

            _validateOptions(options) {
                if (!Array.isArray(options)) {
                    this.hasError = true;
                    throw new Error("options must be an array");
                }

                if (options.length === 0) {
                    this.hasError = true;
                    throw new Error("options must have at least one");
                }

                for (const option of options) {
                    if (!option.hasOwnProperty("value") || !option.hasOwnProperty("text")) {
                        this.hasError = true;
                        throw new Error("Each option object must have both 'value' and 'text' properties.");
                    }
                }
                if (!this.hasError) {
                    return true;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .fullscreen-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
    .help-text-wrapper {
        display: inline-flex;
        width: 100%;

        button {
            white-space: nowrap;
        }

        .circle-button {
            background-color: transparent;
            border: none;
        }
        .circle {
            border-radius: 14px;
            background-color: #38a7cf;
            padding-top: 2px;
            padding-left: 1px;
            height: 28px;
            width: 28px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .popup-helpinfo {
        width: 90vw; /* 90% of the viewport width */
        max-width: 600px; /* A reasonable maximum width for large screens */
        position: absolute;
        top: 0px;

        .popup-heading {
            background-color: #277692;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                color: white;
                line-height: 42px;
                color: #fff;
                font-size: 38px;
                margin: 0 auto;
                padding: 15px;
            }

            button {
                background: transparent;
                border: none;
                margin-right: 15px;
            }
        }

        .popup-content {
            background-color: #f0f0f0;
            padding: 10px;
            overflow-y: scroll;
            max-height: 60vh;
            padding: 2rem;

            div {
                margin-bottom: 16px;

                strong {
                    display: inline-block;
                    width: 110px;
                    font-family: Roboto Medium, sans-serif;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    color: black;
                    margin-right: 15px;
                    cursor: pointer;
                }
            }
        }
    }
</style>
