<template>
    <div class="blockad">
        <admin-lock-border />
        <modal-basic ref="dialog" />
        <h2 v-if="props.parameters.redigera">Redigera blockad</h2>
        <h2 v-else>Skapa ny blockad</h2>
        <div class="form">
            <FormulateForm v-model="formData" @submit="createBlockad">
                <div class="grid-container">
                    <FormulateInput
                        type="text"
                        name="displayName"
                        label="Namn *"
                        validation="required"
                        validation-name="Namn"
                    >
                    </FormulateInput>
                    <FormulateInput
                        type="knumber"
                        name="ordning"
                        label="Ordningsnummer *"
                        validation="required"
                        validation-name="Ordningsnummer"
                    >
                    </FormulateInput>
                    <FormulateInput type="text" name="system" label="System"> </FormulateInput>
                    <FormulateInput type="text" name="systemName" label="SystemNamn"> </FormulateInput>
                    <FormulateInput type="text" name="originalText" label="OrginalText"> </FormulateInput>
                </div>
                <ButtonGroup>
                    <CustomButton type="submit">
                        <span>{{ props.parameters.redigera ? "Redigera blockad" : "Skapa ny blockad" }}</span>
                    </CustomButton>
                    <CustomButton @click.prevent="cancelForm" size="slim" variant="contour">
                        <span>Avbryt</span>
                    </CustomButton>
                </ButtonGroup>
            </FormulateForm>
        </div>
    </div>
</template>

<script>
    import tabIcon from "@/assets/receptforskrivning.svg";
    export default {
        name: "Blockad",
        tabName: "Blockad",
        icon: tabIcon,
    };
</script>

<script setup>
    import { ref, useAttrs, toRefs, computed } from "vue";
    import emitter from "tiny-emitter/instance";

    import ModalBasic, { BasicModalVariant } from "@/components/generic/ModalBasic.vue";
    import { getConfig, klinikenApi } from "@/api";
    import AdminLockBorder from "@/components/AdminLockBorder.vue";
    import { flashMessage, openDialog } from "@/utils";
    import CustomButton from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import store from "@/store";

    const attrs = useAttrs();

    const props = defineProps({
        parameters: Object,
    });

    const { parameters } = toRefs(props);

    const formData = ref({
        namn: "",
        ordning: "",
        active: "ja",
        ...parameters.value,
    });
    const defaultFormData = ref({ ...formData.value });
    const dialog = ref();

    const cancelForm = async () => {
        if (!formIsChanged.value) {
            store.dispatch("tabs/closeTab", attrs["tab-id"]);
        } else {
            const confirm = await dialog.value.show({
                variant: BasicModalVariant.WARNING,
                title: "Ändringar har gjorts",
                message: "Vill du spara ändringarna?",
                cancelButton: "Avbryt",
                okButton: "Ja",
                cancelButtonVariant: "contour",
            });
            if (confirm) {
                store.dispatch("tabs/closeTab", attrs["tab-id"]);
            } else {
                return;
            }
        }
    };

    const formIsChanged = computed(() => {
        return JSON.stringify(formData.value) !== JSON.stringify(defaultFormData.value);
    });

    const createBlockad = async () => {
        try {
            if (parameters.value.redigera) {
                const response = await klinikenApi.put(
                    `/operation/kodverk/blockader/${parameters.value.id}/`,
                    formData.value,
                    getConfig()
                );
                if (response.status === 200) {
                    flashMessage("Redigerat");
                }
                await store.dispatch("tabs/closeTab", attrs["tab-id"]);
            } else {
                const response = await klinikenApi.post("/operation/kodverk/blockader/", formData.value, getConfig());
                if (response.status === 201) {
                    flashMessage("Blockad skapad");
                }
                await store.dispatch("tabs/closeTab", attrs["tab-id"]);
            }
        } catch (error) {
            openDialog(
                parameters.value.redigera
                    ? "Något gick fel, kunde inte uppdatera blockad. "
                    : "Något gick fel, kunde inte skapa blockad",
                "warning"
            );
        }
        emitter.emit("Blockad-table-uppdaterad", {});
    };
</script>
<style lang="scss">
    .blockad {
        .form {
            h1 {
                margin: 0;
            }
            .grid-container {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                gap: 10px;
            }
        }
    }
</style>
