import typoperationer from "./typoperationer.js";
import operationoversikt from "./operationoversikt.js";
import operationsanmalan from "./operationsanmalan.js";
import operationskalender from "./operationskalender.js";
import operationsjournal from "./operationsjournal.js";
import preanestesibedomning from "./preanestesibedomning.js";

export default {
    namespaced: true,
    modules: {
        typoperationer,
        operationoversikt,
        operationsanmalan,
        operationskalender,
        operationsjournal,
        preanestesibedomning,
    },
    mutations: {
        reset() {},
    },
};
