<template>
    <div class="operationsanmalan-status">
        <img :src="imageSrc" :alt="text" />
        {{ text }}
    </div>
</template>

<script>
    import { statusar } from "@/tabs/Operation/utils";

    export default {
        name: "StatusWidget",
        props: ["status", "text"],
        computed: {
            imageSrc() {
                const s = statusar.find((i) => i.value === this.status);
                if (s !== undefined) return new URL(`/src/assets/actionIcons/${s.icon}.svg`, import.meta.url).href;
                return "";
            },
        },
    };
</script>

<style lang="scss">
    .operationsanmalan-status {
    }
</style>
