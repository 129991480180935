<script>
import moment from "moment";
import _ from "lodash";
import { mixin as clickaway } from "vue-clickaway";

export default {
    name: "InputNow",
    mixins: [clickaway],
    props: {
        value: "",
        error: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            activeListItem1: 0,
            activeListItem2: 0,
            timeValue: "",
        };
    },
    computed: {
        formattedValue: {
            get: function () {
                return this.value;
            },
            set: function (newValue) {
                this.timeValue = newValue;
                this.$emit("input", this.timeValue);
            },
        },
        klass: function () {
            return { error: this.error };
        },
        rotateArrow() {
            return this.isOpen ? "rotated180" : "rotateBackTo0";
        },
    },
    methods: {
        now() {
            this.formattedValue = moment().format("HH:mm");
        },
        padStart(string, length, chars) {
            return _.padStart(string, length, chars);
        },
        range(v) {
            return _.range(v);
        },
        getTime() {
            const a = this.formattedValue.split(":");
            return {
                h: a[0] !== undefined ? a[0] : 0,
                m: a[1] !== undefined ? a[1] : 0,
            };
        },
        selectH(v, index) {
            let { h, m } = this.getTime();
            v = _.padStart(`${v}`, 2, "0");
            h = _.padStart(`${h}`, 2, "0");
            m = _.padStart(`${m}`, 2, "0");
            this.formattedValue = `${v}:${m}`;
            if (this.activeListItem1 === index) {
                this.activeListItem1 = 0;
            } else {
                this.activeListItem1 = index;
            }
        },
        selectM(v, index) {
            let { h, m } = this.getTime();
            v = _.padStart(`${v}`, 2, "0");
            h = _.padStart(`${h}`, 2, "0");
            m = _.padStart(`${m}`, 2, "0");
            this.formattedValue = `${h}:${v}`;
            if (this.activeListItem2 === index) {
                this.activeListItem2 = 0;
            } else {
                this.activeListItem2 = index;
            }
        },
        away() {
            this.isOpen = false;
        },
    },
};
</script>

<template>
    <div
        class="input-now"
        v-on-clickaway="away"
        @click="$emit('time-input:clicked', { inputValue: value, fieldName: $attrs.name })"
    >
        <div class="input-wrapper">
            <button type="button" class="time_button" @click="now()">
                <img src="@/assets/time_white.svg" alt="arrow image" />
            </button>
            <input type="time" :class="klass" v-bind="$attrs" v-model="formattedValue" @focus="isOpen = true" />
            <img
                class="arrow-image"
                @click="isOpen = !isOpen"
                :class="rotateArrow"
                src="@/assets/arrow_down.svg"
                alt="arrow image"
            />
        </div>
        <div class="dropdown" v-if="isOpen">
            <div class="dropdown-inner">
                <div class="header">
                    <div class="header-center-wrapper">
                        <span class="header-center">tim</span>
                    </div>
                    <div class="header-center-wrapper">
                        <span class="header-center">min</span>
                    </div>
                </div>
                <div class="lists">
                    <ol>
                        <li
                            v-for="(v, index) in range(24)"
                            :class="activeListItem1 === index ? 'active' : ''"
                            @click="selectH(v, index)"
                            :key="index"
                        >
                            <span>{{ padStart(`${v}`, 2, "0") }}</span>
                        </li>
                    </ol>
                    <ol>
                        <li
                            v-for="(v, index) in range(60)"
                            :key="index"
                            @click="selectM(v, index)"
                            :class="activeListItem2 === index ? 'active' : ''"
                        >
                            <span>{{ padStart(`${v}`, 2, "0") }}</span>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/style/mixins/input";

.input-now {
    position: relative;

    .input-wrapper {
        @include input-mixin;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin-bottom: 0;
        .time_button {
            background-color: $color-button-timepicker;
            border: none;
            border-radius: 5px;
            margin-left: 10px;
            img {
                height: 80%;
                width: 80%;
            }
        }
        .arrow-image {
            margin-right: 10px;
        }
        .rotated180 {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        .rotateBackTo0 {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        input[type="time"] {
            flex-grow: 1;
            border: 0;

            &:focus-visible {
                outline: 0;
            }

            &::-webkit-calendar-picker-indicator {
                background: none;
                display: none;
            }
        }
    }

    .dropdown {
        position: absolute;
        margin-top: 10px;
        background-color: white;
        border: 1px solid $color-input-border;
        border-radius: $border-radius;
        text-align: center;
        height: 242px;
        width: 100%;
        z-index: 999;
        .dropdown-inner {
            .header {
                display: flex;
                justify-content: space-around;
                width: 100%;
                border-bottom: 1px solid $color-input-border;

                .header-center-wrapper {
                    height: 40px;
                    position: relative;
                    span {
                        color: $color-input-timepicker;
                    }
                }
                .header-center {
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }
            }
            .lists {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 0;
                ol:first-child {
                    width: 100%;
                    padding: 0;
                    height: 200px;
                    overflow-y: scroll;
                }
                ol:last-child {
                    width: 100%;
                    padding: 0;
                    height: 200px;
                    overflow-y: scroll;
                    border-left: 1px solid grey;
                }
                .active {
                    background-color: $color-red-listItem;
                    span {
                        color: white;
                    }
                }
                ol {
                    margin-top: 0;
                    margin-bottom: 0;
                    li {
                        list-style-type: none;
                        cursor: default;
                        height: 46px;
                        position: relative;
                        &:hover {
                            background-color: $color-lightred-listItem;
                        }
                        color: #000;
                        text-decoration: none;
                        span {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            font-size: 20px;
                            color: $color-input-text;
                            margin-left: -10px;
                        }
                    }
                }
            }
        }
    }
}
</style>
