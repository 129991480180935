import { getConfig, klinikenApi } from "@/api";

const DEFAULT_LIMIT = 20;

const getDefaultState = () => {
    return {
        aktiviteter: [],
        count: 0,
        limit: DEFAULT_LIMIT,
        filterParams: {},
    };
};

export default {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        aktiviteter: (state) => state.aktiviteter,
        count: (state) => state.count,
        limit: (state) => state.limit,
        filterParams: (state) => state.filterParams,
    },

    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        resetAktiviteter: (state) => (state.aktiviteter = []),
        setAktiviteter: (state, payload) => (state.aktiviteter = payload),
        appendAktiviteter: (state, payload) => (state.aktiviteter = state.aktiviteter.concat(payload)),
        setCount: (state, payload) => (state.count = payload),
        removeAktivitet: (state, payload) => (state.aktiviteter = state.aktiviteter.filter((a) => a.id !== payload.id)),
        resetLimit: (state) => (state.limit = DEFAULT_LIMIT),
        setLimit: (state, payload) => (state.limit = payload),
        resetFilterParams: (state) => (state.filterParams = {}),
        setFilterParams: (state, payload) => (state.filterParams = payload),
    },

    actions: {
        async save({ dispatch }, aktivitet) {
            let response;
            if (aktivitet.id === undefined) {
                response = await dispatch("create", aktivitet);
            } else {
                response = await dispatch("update", aktivitet);
            }
            await dispatch("load");
            return response;
        },
        async create({}, aktivitet) {
            return await klinikenApi.post(`skreg/aktiviteter/`, aktivitet, getConfig());
        },
        async update({}, aktivitet) {
            return await klinikenApi.put(`skreg/aktiviteter/${aktivitet.id}/`, aktivitet, getConfig());
        },
        async delete({ dispatch }, aktivitet) {
            try {
                const response = await klinikenApi.delete(`/skreg/aktiviteter/${aktivitet.id}/`, getConfig());
            } catch (e) {
                console.log(e);
            }
            await dispatch("load");
        },
        async _load({ commit }, { url, params = {} }) {
            const response = await klinikenApi.get(url, getConfig({ params: params }));
            commit("setAktiviteter", response.data.results);
            commit("setCount", response.data.count);
        },
        async load({ commit, state, dispatch }, _params) {
            var params = { ...state.filterParams, ..._params };
            params.limit = state.limit;
            commit("resetAktiviteter");
            await dispatch("_load", { url: `skreg/aktiviteter/`, params });
        },
        async loadMore({ commit, state, dispatch }, numberOfBatchesToShow) {
            commit("setLimit", DEFAULT_LIMIT * numberOfBatchesToShow);
            var params = { ...state.filterParams };
            params.limit = state.limit;
            await dispatch("_load", { url: `skreg/aktiviteter/`, params });
        },
    },
};
