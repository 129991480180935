<template>
    <div>
        <h2 class="headline">Allvarlig Sjukdom</h2>

        <form @submit.prevent id="alertInformation-details" autocomplete="off">
            <div class="form-group k3-form fullscreen">
                <input-wrapper
                    name="ascertainedDate"
                    v-model="form.ascertainedDate"
                    :colspan="8"
                    :component="widgets.DateWidget"
                    :required="true"
                    label="Fastställandedatum"
                />
                <input-wrapper
                    name="verifiedTime"
                    v-model="form.verifiedTime"
                    :colspan="8"
                    :component="widgets.DateWidget"
                    :required="true"
                    label="Veriferat datum"
                />
                <div class="colspan c8">
                    <h4>Giltighetstid</h4>
                </div>
                <input-wrapper
                    name="validityTimePeriodStart"
                    v-model="form.validityTimePeriodStart"
                    :colspan="3"
                    :required="true"
                    :component="widgets.DateWidget"
                    label="Från"
                />
                <input-wrapper
                    name="validityTimePeriodEnd"
                    :required="false"
                    v-model="form.validityTimePeriodEnd"
                    :colspan="3"
                    :component="widgets.DateWidget"
                    label="Till"
                />
                <input-wrapper
                    name="comment"
                    v-model="form.comment"
                    :colspan="8"
                    :required="true"
                    :maxlength="140"
                    :component="widgets.TextAreaWidget"
                    label="Kommentar"
                />
                <input-wrapper
                    name="disease"
                    v-model="form.disease"
                    :colspan="8"
                    :required="true"
                    apisearch="/core/kodverk/icdkoder/"
                    :component="widgets.SuggestWidget"
                    label="ICD-kod"
                    :displayCode="true"
                />
            </div>
            <ButtonGroup>
                <Button type="button" @click="sign" size="primary">
                    <img src="@/assets/sign_white.svg" />Signera
                </Button>
                <Button @click="save" type="button" size="primary">
                    <img src="@/assets/send_white.svg" />Spara uppmärksamhetssignal
                </Button>
                <Button type="button" @click="cancel" variant="contour" size="secondary">Avbryt</Button>
            </ButtonGroup>
        </form>
    </div>
</template>

<script>
import widgets from "@/components/widgets/inputs";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import Button from "@/components/Button.vue";
import { cloneDeep, isEqual } from "lodash";

const defaultForm = {
    ascertainedDate: null,
    verifiedTime: null,
    validityTimePeriodStart: null,
    validityTimePeriodEnd: null,
    comment: "",
    disease: "",
    type: "sd",
};

export default {
    name: "seriousDiseaseForm",
    props: ["chosenComponent", "alertInformationForm"],
    components: {
        Button,
        ButtonGroup,
        InputWrapper,
    },
    data() {
        return {
            defaultForm: cloneDeep(defaultForm),
            form: cloneDeep(defaultForm),
            widgets: widgets,
        };
    },
    computed: {
        hasUnsavedData() {
            return !isEqual(this.defaultForm, this.form);
        },
    },
    watch: {
        hasUnsavedData(value) {
            this.$emit("hasUnsavedData", value);
        },
    },
    methods: {
        cancel() {
            this.$emit("cancelAlertForm");
        },
        save() {
            if (!this.validateForm("alertInformation-details")) {
                return;
            }

            if (this.form.validityTimePeriodEnd === "") {
                this.form.validityTimePeriodEnd = null;
            }

            this.$emit("save", this.form);
        },
        sign() {
            if (!this.validateForm("alertInformation-details")) {
                return;
            }
            if (this.form.validityTimePeriodEnd === "") {
                this.form.validityTimePeriodEnd = null;
            }
            this.$emit("sign", this.form);
        },
        validateForm(formName) {
            const form = document.getElementById(formName);
            if (form.checkValidity() === true) return true;
            else {
                const list = form.querySelectorAll(":invalid");
                for (let item of list) {
                    item.focus();
                }
                form.querySelector(":invalid").focus();
            }
            return false;
        },
    },
};
</script>

<style lang="sass" scoped>
#receiver-details
    position: relative
    min-height: 600px
    min-width: 600px
    display: inline-block

h2
    margin: 10px 0px 10px 0px !important

.radiobutton-line
    display: inline-block
    margin-top: 12px
    margin-bottom: 30px

    label:not(:first-of-type)
        margin-left: 32px

.btn-group
    float: none !important

.headline
    margin-bottom: 20px !important
</style>
