<template>
    <BankIDForm :errors="errors" @submit="submit" @cancel="cancel" />
</template>

<script setup>
    import { onMounted, ref } from "vue";
    import { getConfig, klinikenApi } from "@/api";
    import BankIDForm from "@/components/Auth/BankIDForm.vue";

    const errors = ref({});

    const emit = defineEmits(["poll", "error", "cancel"]);

    const submit = async (formData) => {
        try {
            const response = await klinikenApi.post("auth/bankid/init", {}, getConfig());

            emit("poll", {
                message: "Starta BankID-appen i din mobil och tryck på Scanna QR-kod. Skanna sedan QR-koden nedan.",
                url: "auth/bankid/poll",
                data: response.data,
                type: "bankid",
            });
        } catch (error) {
            emit("error", "Det gick inte att logga in.");
        }
    };

    const cancel = () => {
        emit("cancel");
    };

    // Call submit function when component is mounted
    onMounted(submit);
</script>
