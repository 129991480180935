<template>
    <div :class="!expandableChild ? 'input-wrapper colspan c' + normalizedColspan : ''">
        <!-- Expandables should not render new flex dvs-->
        <span v-if="!expandableChild">{{ label }}</span>
        <template v-if="value_ && value_.length">
            <preview-widget
                v-bind="$props"
                :expandableChild="true"
                :value="itemValue"
                v-for="(itemValue, index) in value_"
                :key="'child' + index"
            />
        </template>
        <template v-else>
            {{ displayValue }}
        </template>
    </div>
</template>
<script>
import { fetchMixin } from "./inputs/fetchMixin.js";

export default {
    name: "PreviewWidget",
    mixins: [fetchMixin],
    props: {
        colspan: {
            type: undefined,
            default: 2, // definierar bredden i form av antal kolumner (x av 8), antingen fast bredd eller relativ, se _main.sass.
        },
        name: String,
        label: String,
        apisearch: String,
        extraParams: Object, // An object specifying additional parameters to be passed to backend, such as filters
        value: undefined, // Should be Object, but can be Number until backend catches up. value is decoupled from value_ to allow intercepting the two-way data binding.
        displayCode: Boolean, // if code should be shown or not
        component: Object, // The type of component
        /**
         * In case the data from backend is NOT a JSON object with the format
         * { id, code, displayName }, a map Function must be supplied by the parent
         */
        map: {
            type: Function,
            default: (item) => {
                return {
                    id: item.id,
                    code: item.code,
                    displayName: item.displayName,
                };
            },
        },
        expandableChild: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value_: this.value,
            /**
             * Used in fetchMixin
             */
            API_SEARCH: this.apisearch,
            itemCache: {},
        };
    },
    computed: {
        type() {
            if (this.expandableChild) {
                return this.component.name === "ExpandableSuggest" ? "SuggestWidget" : "SelectWidget";
            }
            return this.component.name;
        },
        normalizedColspan() {
            return new String(this.colspan).toString();
        },
        emptyValue() {
            if (this.value_ === null || this.value_ === undefined || this.value_ === "") return true;
            else if (Array.isArray(this.value_)) {
                let anyEmpty = false;
                if (this.value_.length === 0) return true;
                for (let i = 0; i < this.value_.length; i++) {
                    if (this.value_[i] === "") {
                        anyEmpty = true;
                    }
                }
                return anyEmpty;
            }
            return false;
        },
        displayValue() {
            if (this.emptyValue) return "-";
            switch (this.type) {
                case "SuggestWidget":
                    if (typeof this.value_ === "object") {
                        if (this.displayCode) return this.value_.code + " " + this.value_.displayName;
                        else return this.value_.displayName;
                    }
                    break;
            }
            return this.value_;
        },
        checkbox() {
            return this.type === "CheckboxWidget";
        },
    },
    methods: {
        setValues(value) {
            this.value_ = value;
        },
        /**
         * Called from fetchMixin, but serves no purpose in this widget.
         */
        emitValue() {},
    },
    mounted() {
        switch (this.type) {
            case "SuggestWidget":
                this.initializeSuggest(this.value);
                break;
            case "SelectWidget":
                this.normalizeValue(this.value);
                break;
        }
    },
    watch: {
        value() {
            this.value_ = this.value;
        },
    },
};
</script>
<style lang="sass" scoped>

span
    font-weight: 600
    color: #354052
</style>
