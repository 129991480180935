<template>
    <div>
        <template v-if="!preview">
            <input
                :name="name + 'Display'"
                :required="required"
                :maxlength="maxlength"
                :minlength="minlength"
                class="text-input"
                v-model="displayValue"
                @keypress="inputTypeNumber($event)"
                @change="validate()"
                @blur="$emit('blur')"
            />
            <input type="hidden" :name="name" :value="value_" />
            <input type="hidden" :name="name + 'Type'" :value="personIdType" />
        </template>
        <span v-else class="input-preview">
            {{ displayValue }}
        </span>
    </div>
</template>
<script>
import PersonIdWidget from "./PersonIdWidget.vue";
export default {
    name: "SocialSecurityNumber",
    extends: PersonIdWidget,
    methods: {
        validate() {
            this.value_ = this.value_ && this.value_.length > 0 ? this.value_.replace("-", "") : this.value_;
            this.displayValue =
                this.displayValue && this.displayValue.length > 0
                    ? this.displayValue.replace("-", "")
                    : this.displayValue;

            //let personNummer = new RegExp('^(19|20)?[0-9]{2}((0[0-9])|(10|11|12))(([0-2][0-9])|(3[0-1]))[- ]?[0-9]{4}$');
            let personNummer = new RegExp("^(19|20)[0-9]{2}((0[0-9])|(10|11|12))(([0-2][0-9])|(3[0-1]))[- ]?[0-9]{4}$"); // Possible versions, including samordningsnummer
            /* ^(19|20)?[0-9]{2}((0[0-9])|(10|11|12))(([0-2][0-9])|(3[0-1])|(([7-8][0-9])|(6[1-9])|(9[0-1])))[- ]?[0-9]{4}$ */ let currentDate =
                new Date();

            this.value_ = this.displayValue;

            if (this.displayValue.match(personNummer)) {
                // if input matches personnummer, translate to correct format
                //this.value_ = this.displayValue.replace(' ','').replace('-','');
                /*
                if (this.value_.length === 10) {// 10 numbers, add year
                    let currentYear = currentDate.getFullYear().toString().slice(2);
                    let bornYear = this.value_.slice(0,2);
                    if (bornYear <=  currentYear)
                        this.value_ = "20"+this.value_;
                    else
                        this.value_ = "19"+this.value_;
                }
                */

                //this.displayValue = this.value_.substr(0, 8) + '-' + this.value_.substr(8);
                let bornDate = new Date(
                    this.displayValue.slice(0, 4),
                    parseInt(this.displayValue.slice(4, 6)) - 1,
                    this.displayValue.slice(6, 8)
                );

                // Felaktig kontrollsiffra
                if (!this.luhnAlgorithm(this.value_.slice(2))) {
                    this.showWarning("Ej giltigt personnummer");
                } else if (bornDate.getTime() > currentDate.getTime()) {
                    /* || ((currentDate.getTime() - bornDate.getTime()) / year ) > 130) */ // Check for age > 120 not necessary at this point, since Regexp only allows for people born in the 1900s.
                    this.showWarning("Orimligt födelsedatum");
                } else {
                    this.showWarning(null);
                }
            } else this.showWarning("Känner ej igen format");
            /*
            if(this.personIdType === "1.2.752.129.2.1.3.1") { // Personnummer
                this.value_ = parseInt(this.value_.replace('-', ''));
                if((isNaN(this.value_)) && !this.required) {
                    this.value_ = undefined;
                    return true; // Allow empty value if value is not required.
                }
            }
            else {

            }
            */
            if (this.displayValue == "") this.showWarning(null);
            this.$emit("input", this.value_);
        },
    },
};
</script>
