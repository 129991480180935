<template>
    <div class="FilhanteringForhandsvisningBild">
        <img v-bind:src="src" />
    </div>
</template>

<script>
export default {
    name: "FilhanteringForhandsvisningBild",
    props: ["src"],
};
</script>

<style lang="scss" scoped>
div.FilhanteringForhandsvisningBild {
    // height: 20rem;
    overflow-y: scroll;
    img {
        width: 100%;
    }
}
</style>
