<template>
    <textarea
        :name="name"
        @change="parseContent()"
        @focus="hasFocus(true)"
        @blur="hasFocus(false)"
        :tabindex="tabindex"
        :required="required"
        :maxlength="maxlength"
        class="text-area"
        v-model="value_"
        :disabled="disabled"
    ></textarea>
</template>

<script>
import { flashMessage } from "@/utils";
import linkEvents from "@/linkEvents";

export default {
    name: "TextAreaWidget",
    props: {
        name: String,
        required: Boolean,
        maxlength: Number,
        value: undefined, // Should be string, but number inputs are automatically converted to numbers
        tabindex: Number,
        filter: RegExp,
        disabled: Boolean,
    },
    data() {
        return {
            value_: this.value || "",
            focus: false,
        };
    },
    methods: {
        parseContent: function () {
            if (this.filter) {
                let lengthBefore = this.value_.length;
                this.value_ = this.value_.replace(this.filter, "");
                if (lengthBefore !== this.value_.length) flashMessage("Otillåtna tecken har tagits bort från texten.");
            }
            linkEvents.$emit(this.name + "_changed", this.value_);
        },
        hasFocus(focus) {
            if (focus)
                // first time input gets focus
                this.focus = true;
            if (!focus && this.focus)
                // every time input loses focus
                linkEvents.$emit(this.name + "_focus");
        },
    },
};
</script>

<style lang="sass" scoped>
@import "@/style/variables"

/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

@import "bootstrap/scss/forms"
/* Bootstrap - End */

@import "@/style/deprecated_main"

.text-area
    resize: none
</style>
