/**
 * @param {string} matchField - Matching fieldName should correspond to an object key
 * @param {Array}  field_errors - An array that contains all error objects can be found in **non_field_errors**
 *
 * ```
 * NonFieldErrors(error.response.data.non_field_errors, "overbokningar")
 * ```
 */
const NonFieldErrors = (field_errors = [], matchField = "") => {
    let list: any = [];
    if (typeof field_errors !== "string" && field_errors.length > 0) {
        field_errors.map((field_error) => {
            if (typeof field_error === "object" && matchField in field_error) return list.push(field_error);
        });
    }
    return { list };
};

/**
 * @param {Array}  errors - List that contains the error, you get by using the function above
 * @param {Array}  key - Optional key provided from outside that you want to map against
 *
 * ```
 * // setup for getting the errors argument.
 * const { lists } = NonFieldErrors(non_field_errors, "overbokningar");
 *
 * // we pass lists as errors argument and the key is "overbokningar"
 *  formateMessage(lists, "overbokningar");
 * ```
 */
const formatMessage = (errors = [], key = "") => {
    let errorList: any[] = [];

    errors.map((error: Object) => {
        if (error.hasOwnProperty(key)) {
            errorList.push(Object.values(error)[0]);
        } else {
            errorList.push(error);
        }
    });

    return errorList;
};

export { NonFieldErrors, formatMessage };
