import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";
import URLSearchParams from "@ungap/url-search-params";

const getDefaultParameters = () => {
    return {};
};

const getDefaultState = () => {
    return {
        sokords: [],
        count: 0,
        limit: 100,
        offset: 0,
        parameters: getDefaultParameters(),
        allSokords: [],
        sokordsstrukturer: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        sokords(state) {
            return state.sokords;
        },
        count(state) {
            return Math.ceil(state.count);
        },
        limit(state) {
            return state.limit;
        },
        offset(state) {
            return state.offset;
        },
        parameters(state) {
            return state.parameters;
        },
        sokord(state) {
            return state.sokord;
        },
        allSokords(state) {
            return state.allSokords;
        },
        sokordsstrukturer(state) {
            return state.sokordsstrukturer;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setSokords: (state, sokords) => {
            state.sokords = sokords;
        },
        setAllSokords: (state, sokords) => {
            state.allSokords = sokords;
        },
        setSokordsstrukturer(state, sokordsstrukturer) {
            state.sokordsstrukturer = sokordsstrukturer;
        },
        setCount: (state, count) => {
            state.count = count;
        },
        setLimit: (state, limit) => {
            state.limit = limit;
        },
        setOffset: (state, offset) => {
            state.offset = offset;
        },
        setParameters: (state, parameters) => {
            state.parameters = parameters;
        },
        resetParameters(state) {
            state.parameters = getDefaultState();
        },
    },
    actions: {
        async load({ commit, state }, failSilently = false) {
            try {
                const qs = new URLSearchParams(state.parameters);
                qs.set("limit", state.limit);
                qs.set("offset", state.offset);
                const response = await klinikenApi.get(`anteckning/sokord/?${qs}`, getConfig());
                commit("setSokords", response.data.results);
                commit("setCount", response.data.count);
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta journallogg." + getErrorMessage(e), "warning");
                }
            }
        },
        async loadAll({ commit }, failSilently = false) {
            try {
                const response = await klinikenApi.get("anteckning/sokord/", getConfig({ params: { limit: 1000 } }));
                commit("setAllSokords", response.data.results);
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta journallogg." + getErrorMessage(e), "warning");
                }
            }
        },
        async fetchSokordsstrukturer({ commit, state }, failSilently = false) {
            const { parameters, limit, offset } = state;
            try {
                const qs = new URLSearchParams(parameters);
                qs.set("limit", limit);
                qs.set("offset", offset);
                const response = await klinikenApi.get(
                    `anteckning/sokordsstrukturer/?${qs}`,
                    getConfig({ params: { limit: import.meta.NODE_ENV === "production" ? 1000 : null } })
                );
                commit("setSokordsstrukturer", response.data.results);
                commit("setCount", response.data.count);
            } catch (error) {
                getErrorMessage(error);
                if (!failSilently) {
                    openDialog("Kunde inte hämta sökordsstukturer." + getErrorMessage(error), "warning");
                }
            }
        },
        setOffset: ({ commit }, offset) => {
            commit("setOffset", offset);
        },
        setParameters: ({ commit }, parameters) => {
            commit("setParameters", parameters);
        },
        resetParameters({ commit }) {
            commit("resetParameters");
        },
        async save(context, data) {
            try {
                const response = await klinikenApi.post(`anteckning/sokord/`, data.sokord, getConfig());
                return response;
            } catch (e) {
                openDialog("Kunde inte spara sökord: " + getErrorMessage(e), "warning");
            }
        },
        async update(context, data) {
            try {
                const response = await klinikenApi.put(
                    `anteckning/sokord/${data.sokord.pk}/`,
                    data.sokord,
                    getConfig()
                );
                return response;
            } catch (e) {
                openDialog("Kunde inte uppdatera sökord: " + getErrorMessage(e), "warning");
            }
        },
    },
};
