<template>
    <div class="pad">
        <admin-lock-border />
        <h2>ICDKoder</h2>

        <custom-action-table
            class="no-top"
            :selectableRows="false"
            :columns="tableColumns"
            :items="icdkoder"
            :actions="tableActions"
        >
        </custom-action-table>

        <pagination
            class="pagination mt-5"
            v-model="page"
            :per-page="100"
            :records="count"
            :options="paginationOptions"
            @paginate="paginate"
        />
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import ICDKod from "@/tabs/Admin/ICDKod.vue";
import Pagination from "vue-pagination-2";
import { mapActions, mapGetters } from "vuex";
import tabIcon from "@/assets/blank.png";
import AdminLockBorder from "@/components/AdminLockBorder.vue";

const noNull = (str) => {
    return str || "";
};

const TABLE_COLUMNS = [
    {
        name: "code",
        title: "Kod",
        template: (item) => {
            return noNull(item.code);
        },
    },
    {
        name: "displayname",
        title: "DisplayName",
        template: (item) => {
            return noNull(item.displayName);
        },
    },
];

const TABLE_ACTIONS = [
    {
        name: "edit",
        title: "Redigera",
        img: "edit.svg",
        condition: () => {
            return true;
        },
    },
];

export default {
    extends: BaseTab,
    name: "ListICDKod",
    tabName: "ICDKod",
    icon: tabIcon,
    props: ["tabId"],
    data() {
        return {
            tableColumns: TABLE_COLUMNS,
            tableActions: TABLE_ACTIONS,
            searchParameters: {},
            page: 1,
            paginationOptions: {
                format: true,
                chunk: 10,
                chunksNavigation: "fixed",
                edgeNavigation: true,
                theme: "bootstrap4",
                texts: {
                    count: "Visar {from} till {to} av {count} ICDKod|{count} ICDKoder|En ICDkod",
                    first: "Första",
                    last: "Sista",
                    nextChunk: ">>",
                    prevChunk: "<<",
                },
            },
        };
    },
    components: {
        AdminLockBorder,
        CustomActionTable,
        Pagination,
    },
    computed: {
        ...mapGetters("icdkod", ["icdkoder", "count", "limit", "offset", "parameters"]),
    },
    methods: {
        ...mapActions("icdkod", ["load", "setOffset", "setParameters", "resetParameters"]),
        createICDKod() {
            this.$store.dispatch("tabs/openTab", { component: ICDKod });
        },
        async paginate(page) {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            this.setOffset(page * 100 - 100);
            await this.load();
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },
    },
    async created() {
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
        this.setOffset(0);
        this.setParameters(this.searchParameters);
        await this.load();
        await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });

        this.$on("edit", async (data) => {
            await this.$store.dispatch("tabs/openTab", {
                component: ICDKod,
                parameters: data,
            });
        });
        this.$root.$on("saveOrEdit", () => {
            this.page = 1;
        });
    },
    beforeDestroy() {
        this.$store.commit("icdkod/reset");
    },
};
</script>
