<template>
    <div class="FilhanteringPerson colspan c8">
        <input-wrapper :component="DisplayTextWidget" label="Person-id" v-model="personId" />

        <input-wrapper :component="DisplayTextWidget" label="Typ av person-id" v-model="personIdTypeName" />

        <input-wrapper :component="DisplayTextWidget" label="Patientnamn" v-model="name" />

        <!-- For now this component is dumbed down to just pick up whatever person is currently selected -->
        <!-- Uncomment here and in script tag to change back to a more dynamic component -->
        <!-- <input-wrapper
             v-if="!inlast"
             class="colspan c2"
             :component="FilhanteringPersonIdWidget"
             label="Person-id"
             name="personId"
             v-model="personId"
             :required="true"
             information="Ange person-, samordnings- eller reservnummer."
             @personidchange="handlePersonIdChange"
             />
             <input-wrapper v-else class="colspan c4" :component="DisplayTextWidget" label="Person-id" v-model="personId" />

             <template v-if="personIdIsValid">
             <input-wrapper
             :component="DisplayTextWidget"
             label="Typ av person-id"
             v-model="personIdTypeName"
             class="colspan c2"
             />
             <input-wrapper
             :component="DisplayTextWidget"
             label="Patientnamn"
             v-model="patientNamn"
             class="colspan c2"
             />
             </template>
        -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { getConfig, klinikenApi } from "@/api";

import DisplayTextWidget from "@/components/widgets/inputs/DisplayTextWidget.vue";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";

const metaDataGetter = (key) => {
    return {
        get() {
            const index = this.index;
            return this.$store.state.filhantering.lokalUppladdning.filer[index].metaData[key];
        },
    };
};

export default {
    name: "FilhanteringPerson",

    components: {
        InputWrapper,
    },

    props: { index: Number },
    data() {
        return {
            personIdType2Name: {
                "1.2.752.129.2.1.3.1": "Personnummer",
                "1.2.752.129.2.1.3.3": "Samordningsnummer",
                "1.2.752.97.3.1.3": "SLL reservnummer",
                kliniken2: "Kliniken 2",
                skreg: "Skaderegistret",
            },

            formErrors: [],
            DisplayTextWidget,
            // FilhanteringPersonIdWidget,
            personIdIsValid: true,
        };
    },

    computed: {
        // ...mapGetters("userData", ["currentProfile"]),
        ...mapGetters("patientData", ["patient", "personId", "name"]),
        personIdTypeName() {
            //  Lista ut vårt personIdTypeName

            // const personIdType = this.$store.state.filhantering.lokalUppladdning.filer[this.index].metaData["personIdType"];
            const personIdType = this.patient.personIdType;
            let result = this.personIdType2Name[personIdType];

            // Om vi inte får någon träff returnerar vi koden som den är
            if (result === undefined) {
                result = personIdType;
            }
            return result;
        },

        // personId: {
        //     get() {
        //         const index = this.index;
        //         const key = "personId";
        //         return this.$store.state.filhantering.lokalUppladdning.filer[index].metaData[key];
        //     },
        //     set(value) {
        //         const index = this.index;
        //         const key = "personId";
        //         this.$store.commit("filhantering/lokalUppladdingAngeMetaData", { index, key, value });
        //     },
        // },

        patientNamn: metaDataGetter("patientNamn"),
        inlast: metaDataGetter("inlast"),
        // personIdIsValid: metaDataGetter("personIdIsValid"),
    },

    created() {
        const config = getConfig();
        // Talk to backend to figure out if personIdType2Name needs updating
        klinikenApi.get("core/kodverk/personidtypes/", config).then((response) => {
            // Peta in svaren in ett object
            const personIdType2NameFromBE = Object.fromEntries(
                response.data.map((item) => [item.code, item.displayName])
            );

            // Uppdatera det vi har lokalt med vad vi fått från servern
            Object.assign(this.personIdType2Name, personIdType2NameFromBE);
        });

        // Tell vuex about the data we pick up from patientData
        const index = this.index;
        const key = "patientUUID";
        const value = this.patient.id;
        this.$store.commit("filhantering/lokalUppladdingAngeMetaData", { index, key, value });
    },

    methods: {
        // handlePersonIdChange(data) {
        //     const index = this.index;
        //     const keys = ["patientNamn", "patientUUID", "personIdType", "personIdIsValid", "personIdIsUnique"];
        //
        //     keys.forEach((key) => {
        //         const value = data[key];
        //         this.$store.commit("filhantering/lokalUppladdingAngeMetaData", { index, key, value });
        //     });
        // },
    },
};
</script>
<style lang="scss" scoped>
div.FilhanteringPerson {
    display: flex;
    flex-direction: row;
}
</style>
