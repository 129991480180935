import tabIcon from "@/assets/blank.png";
import emitter from "tiny-emitter/instance";

export default {
    tabName: "Base Tab",
    patientBound: false,
    icon: tabIcon,
    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },
    methods: {
        /*
         * Lifecycle hook for when tab is made visible
         */
        show() {},
        /*
         * Lifecycle hook for when tab is hidden
         */
        hide() {},
        closeTab(hasUnsavedData) {
            if (hasUnsavedData) {
                emitter.emit("open-dialog", {
                    type: "warning",
                    description: "Du har osparad data. Vill du stänga utan att spara?",
                    buttons: [
                        {
                            title: "Fortsätt redigera",
                            type: "secondary",
                        },
                        {
                            title: "Ja, stäng utan att spara",
                            type: "primary",
                            action: () => {
                                this.$store.dispatch("tabs/closeTab", this.tabId);
                            },
                        },
                    ],
                });
            } else {
                this.$store.dispatch("tabs/closeTab", this.tabId);
            }
        },
    },
    computed: {
        hasUnsavedData() {
            return false;
        },
    },
    watch: {
        hasUnsavedData: {
            handler: function (value) {
                if (this.tabId !== undefined) {
                    this.$store.commit("tabs/hasUnsavedData", { id: this.tabId, value: value ?? false });
                }
            },
            immediate: true,
        },
    },

    // Skip error ;)
    render(f) {
        return f();
    },
};
