<template>
    <div class="sms-send">
        <div class="header">
            <h1>Skapa sms</h1>
            <button type="button" @click="openSmsMallarTab">
                <img src="@/assets/icons/gear_icon.svg" alt="Hantera sms-mallar" />
                Hantera sms-mallar
            </button>
        </div>
        <div class="container-select-sms">
            <FormulateInput
                label="Sms-mall *"
                type="vueselect"
                :options="smsMallar"
                @option:selected="onSmsMallOptionSelected"
                @input="onSmsMallInput"
            />
            <Button
                variant="standard"
                size="secondary"
                :disabled="isSelectedSmsMallEmpty"
                @click="isPreviewDispayed = true"
            >
                Skapa förhandvisning
            </Button>
        </div>

        <div v-if="hasAnyReceivers && hasAnyReceiversWithoutPhoneNumber !== true" class="sms-receiver-container">
            <span>
                Sms kommer att skickas till
                <strong>{{ getReceiversCount }}</strong> valda patienter
            </span>
        </div>
        <div v-else-if="hasAnyReceiversWithoutPhoneNumber === true" class="sms-receiver-container">
            <span>
                Sms kommer att skickas till
                <strong>{{ getReceiversWithPhoneNumberCount }}</strong> av
                <strong>{{ getReceiversCount.replace(/[()]/g, "") }}</strong>
                valda patienter
            </span>
            <div v-for="{ patient_display } in getReceiversWithoutPhoneNumber" :key="patient_display.id">
                <img src="@/assets/warning_dark.svg" alt="Varning" />
                <span>
                    Sms till
                    <strong>{{ patient_display?.personId ?? "" }} {{ patient_display?.namn ?? "" }}</strong> kommer inte
                    skickas
                    {{
                        patient_display?.onskar_sms
                            ? "eftersom mobilnummer saknas"
                            : "eftersom patienten inte vill ha sms"
                    }}
                </span>
            </div>
        </div>
        <div v-if="!isSelectedSmsMallEmpty && isPreviewDispayed" class="preview-sms">
            <h3>Förhandsvisning sms</h3>
            <hr />
            <div>
                <p>{{ selectedSmsMall.body }}</p>
            </div>
            <hr />
            <template>
                <ButtonGroup>
                    <Button type="button" size="primary" @click="sendSms" :disabled="!hasAnyReceiversWithPhoneNumber">
                        Skicka sms&nbsp;
                        <span v-if="hasAnyReceivers && !hasAnyReceiversWithoutPhoneNumber">
                            {{ getReceiversCount }}
                        </span>
                        <span v-else-if="hasAnyReceiversWithoutPhoneNumber">
                            ({{ getReceiversWithPhoneNumberCount }})
                        </span>
                    </Button>
                    <Button type="button" size="secondary" variant="contour" @click="cancel"> Avbryt</Button>
                </ButtonGroup>
            </template>
        </div>
    </div>
</template>

<script>
    import BaseTab from "@/tabs/mixins/BaseTab.js";
    import { getConfig, klinikenApi } from "@/api";
    import { flashMessage, flashWarning } from "@/utils";
    import Button from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import SMSMallar from "@/tabs/Operation/SMS/SMSMallar.vue";
    import tabEvents from "@/tabEvents";

    export default {
        extends: BaseTab,
        name: "SMSSend",
        tabName: "Skapa sms",
        components: { Button, ButtonGroup },
        props: {
            tabId: Number,
            parameters: Object,
        },
        data() {
            return {
                smsMallar: [],
                selectedSmsMall: {},
                isPreviewDispayed: false,
                successfulSms: [],
                failedSms: {}, // dictionary med { felmeddelande: antal }
            };
        },
        computed: {
            /**
             * Checks if the selectedSmsMall object is empty.
             * @returns {boolean} Returns true if the selectedSmsMall object is empty, otherwise false.
             */
            isSelectedSmsMallEmpty() {
                return Object.keys(this.selectedSmsMall).length === 0;
            },

            /**
             * Computed property to calculate the count of Sms receivers
             * @returns {string} The count of Sms receivers within parentheses or an empty string
             */
            getReceiversCount() {
                if (this.parameters?.receivers?.length) {
                    return `(${this.parameters.receivers.length})`;
                } else {
                    return "";
                }
            },

            /**
             * Returns the count of Sms receivers that have a phone number.
             *
             * @returns {number} The count of Sms receivers with a phone number.
             */
            getReceiversWithPhoneNumberCount() {
                return this.getReceiversWithPhoneNumber.length;
            },

            /**
             * Checks if there are any Sms receivers available?
             * @returns {boolean} Returns true if there are any Sms receivers, false otherwise.
             */
            hasAnyReceivers() {
                return this.parameters?.receivers?.length > 0;
            },

            hasAnyReceiversWithPhoneNumber() {
                return this.getReceiversWithPhoneNumberCount > 0;
            },

            /**
             * Checks if there are any Sms receivers without a phone number.
             *
             * @returns {boolean} True if there are Sms receivers without a phone number, false otherwise.
             */
            hasAnyReceiversWithoutPhoneNumber() {
                return this.getReceiversWithoutPhoneNumber.length > 0;
            },

            /**
             * Returns an array of Sms receivers that have a phone number.
             *
             * @returns {Array} An array of Sms receivers with phone numbers.
             */
            getReceiversWithPhoneNumber() {
                return (
                    this.parameters?.receivers?.filter(({ patient_display }) => {
                        return (
                            patient_display?.telefon !== null &&
                            patient_display?.telefon !== undefined &&
                            patient_display?.telefon !== "" &&
                            patient_display?.onskar_sms !== false
                        );
                    }) || []
                );
            },

            /**
             * Returns an array of Sms receivers without a phone number.
             *
             * @returns {Array} An array of Sms receivers without phone numbers.
             */
            getReceiversWithoutPhoneNumber() {
                return (
                    this.parameters?.receivers?.filter(({ patient_display }) => {
                        return (
                            patient_display?.telefon === null ||
                            patient_display?.telefon === undefined ||
                            patient_display?.telefon === "" ||
                            patient_display?.onskar_sms === false
                        );
                    }) || []
                );
            },
        },
        methods: {
            onSmsMallOptionSelected(selectedOption = {}) {
                this._setSelectedSmsMall(selectedOption);
            },

            onSmsMallInput(input = "") {
                if (input === null) {
                    this._resetValues();
                }
            },

            openSmsMallarTab() {
                this.$store.dispatch("tabs/openTab", {
                    component: SMSMallar,
                });
            },

            async sendSms() {
                await Promise.all(
                    this.getReceiversWithPhoneNumber.map(async ({ id }) => {
                        if (id) {
                            try {
                                const CONFIGERROR = "Sms kunde ej skickas, felaktig konfiguration.";
                                const { id: mall, body } = this.selectedSmsMall;
                                const baseUrl = this.parameters?.baseUrl ?? "";
                                const path = this.parameters?.path ?? "";

                                if (!baseUrl || !path) {
                                    if (CONFIGERROR in this.failedSms) {
                                        this.failedSms[CONFIGERROR] = this.failedSms[CONFIGERROR] + 1;
                                    } else {
                                        this.failedSms[CONFIGERROR] = 1;
                                    }
                                } else {
                                    const response = await klinikenApi.post(
                                        baseUrl + id + path,
                                        { mall, body },
                                        getConfig()
                                    );
                                    if (response.status === 201) {
                                        this.successfulSms.push(response);
                                    } else {
                                        if (response.data.detail in this.failedSms) {
                                            this.failedSms[response.data.detail] =
                                                this.failedSms[response.data.detail] + 1;
                                        } else {
                                            this.failedSms[response.data.detail] = 1;
                                        }
                                    }
                                }
                            } catch (error) {
                                if (error.response.data.detail in this.failedSms) {
                                    this.failedSms[error.response.data.detail] =
                                        this.failedSms[error.response.data.detail] + 1;
                                } else {
                                    this.failedSms[error.response.data.detail] = 1;
                                }
                            }
                        }
                    })
                );

                if (this.successfulSms.length > 0) {
                    flashMessage(`Sms skickade (${this.successfulSms.length})`, 2000);
                }

                for (let meddelande in this.failedSms) {
                    if (this.failedSms[meddelande] > 0) {
                        flashWarning(`${meddelande} (${this.failedSms[meddelande]})`, 2000);
                    }
                }

                tabEvents.$emit("refreshSmsKallelser", {});
                await this.$store.dispatch("tabs/closeTab", this.tabId);
            },

            async cancel() {
                await this.$store.dispatch("tabs/closeTab", this.tabId);
            },

            _resetValues() {
                this.selectedSmsMall = {};
                this.isPreviewDispayed = false;
            },

            _setSelectedSmsMall(selectedSmsMall = {}) {
                this.selectedSmsMall = selectedSmsMall;
            },

            async _fetchSmsMallar() {
                try {
                    const response = await klinikenApi.get(
                        "/sms/mallar/",
                        getConfig({
                            params: {
                                typ: this.parameters?.typ ?? "",
                            },
                        })
                    );

                    this.smsMallar = response.data.results.map((item) => {
                        return { value: item.id, label: item.namn, ...item };
                    });
                } catch (error) {
                    throw new Error(error);
                }
            },
        },

        async created() {
            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });

            await this._fetchSmsMallar();

            tabEvents.$on("refreshSmsMallar", this._fetchSmsMallar);

            await this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/style/variables";

    .sms-send {
        width: calc(100% / 12 * 7);

        .header {
            display: flex;
            align-items: center;
            gap: 40px;
            margin-bottom: 26px;

            h1 {
                margin: 0 !important;
            }

            .button-hollow:hover {
                background-color: transparent;
            }

            button {
                background: transparent;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    margin-right: 8px;
                }
            }
        }

        .container-select-sms {
            background-color: $color-blue-light;
            padding-top: 16px;
            padding-bottom: 25px;
            padding-left: 21px;
            padding-right: 21px;
            display: flex;
            column-gap: 40px;
            justify-content: center;
            align-items: center;

            button {
                flex-grow: 0;
            }
        }

        .container-select-sms > * {
            flex-grow: 1;
        }

        .sms-receiver-container {
            margin-top: 10px;
            margin-bottom: 20px;

            div {
                margin-top: 10px;
                margin-bottom: 10px;

                display: flex;
                align-items: center;

                img {
                    margin-right: 8px;
                }
            }
        }

        .preview-sms {
            margin-top: 16px;

            div {
                margin: 16px 0px;

                p {
                    white-space: pre-line;
                }
            }

            .button-group {
                margin-top: 34px;
            }
        }
    }
</style>
