<template>
    <div class="result-frame">
        <h3>{{ message }}</h3>
        <img v-if="success" src="@/assets/success_big.svg" alt="Success" />
        <img v-else src="@/assets/error_big.svg" alt="Error" />
        <ButtonGroup>
            <Button v-if="!success" type="button" size="secondary" @click="retry">Försök igen</Button>
            <Button v-if="!success" type="button" size="secondary" variant="contour" @click="cancel">Avbryt</Button>
        </ButtonGroup>
    </div>
</template>

<script setup>
    import { toRefs } from "vue";
    import Button from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";

    const props = defineProps({
        success: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            required: true,
        },
    });

    const { success, message } = toRefs(props);

    const emit = defineEmits(["cancel", "retry"]);

    const cancel = () => {
        emit("cancel");
    };

    const retry = () => {
        emit("retry");
    };
</script>

<style lang="scss">
    .result-frame {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        h3 {
            color: #354052;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 28px;
            text-align: center;
        }
    }
</style>
