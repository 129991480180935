<template>
    <div :class="`columns-${columns}`">
        <slot></slot>
    </div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";

interface GridRowProps {
    columns?: number;
}

const props = withDefaults(defineProps<GridRowProps>(), {
    columns: 12,
});

const { columns } = props;

// Ensure the columns prop is within the range 1-12
if (columns < 1 || columns > 12) {
    console.warn('The "columns" prop should be between 1 and 12.');
}
</script>

<style lang="scss" scoped>
@import "@/style/variables";

/* 1 to 12 column configurations */

/* Base grid row styles */
.columns-1,
.columns-2,
.columns-3,
.columns-4,
.columns-5,
.columns-6,
.columns-7,
.columns-8,
.columns-9,
.columns-10,
.columns-11,
.columns-12 {
    display: grid;
    column-gap: $column-gap;
}

.columns-1 {
    grid-template-columns: 1fr;
}
.columns-2 {
    grid-template-columns: repeat(2, 1fr);
}
.columns-3 {
    grid-template-columns: repeat(3, 1fr);
}
.columns-4 {
    grid-template-columns: repeat(4, 1fr);
}
.columns-5 {
    grid-template-columns: repeat(5, 1fr);
}
.columns-6 {
    grid-template-columns: repeat(6, 1fr);
}
.columns-7 {
    grid-template-columns: repeat(7, 1fr);
}
.columns-8 {
    grid-template-columns: repeat(8, 1fr);
}
.columns-9 {
    grid-template-columns: repeat(9, 1fr);
}
.columns-10 {
    grid-template-columns: repeat(10, 1fr);
}
.columns-11 {
    grid-template-columns: repeat(11, 1fr);
}
.columns-12 {
    grid-template-columns: repeat(12, 1fr);
}
</style>
