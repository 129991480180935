<script>
    export default {
        name: "FrasList",
    };
</script>

<script setup>
    defineProps({
        items: {
            type: Array,
        },
        command: {
            type: Function,
        },
    });
</script>

<template>
    <ul class="fras-list">
        <li v-for="(item, index) in items" :key="index" @click="command(item)">
            <strong>{{ item.value }}</strong>
            <span>{{ item.text }}</span>
        </li>
    </ul>
</template>

<style scoped lang="scss">
    @import "@/style/variables";

    .fras-list {
        list-style: none;
        background-color: $color-background-light;
        overflow-y: scroll;
        max-height: 60vh;
        border: 1px solid $divider-color;
        box-shadow: $box-shadow-pop-up;
        display: grid;
        width: fit-content;
        grid-template-columns: 1fr max-content;
        grid-column-gap: 2rem;
        padding: 1rem 0;
        border-radius: $border-radius-pop-up;

        li {
            display: grid;
            grid-template-columns: subgrid;
            grid-column: 1 / -1;
            cursor: pointer;
            padding: .5rem 1.5rem;

            &:hover {
                background-color: $color-hover;
            }

            strong {
                grid-column: 1;
            }

            span {
                grid-column: 2;
            }
        }
    }
</style>