<template>
    <div class="suggest-wrap" :id="name + '-suggest-wrap'">
        <div class="suggest-flex-main" :id="name + '-flex-main'">
            <textarea
                :tabindex="tabindex"
                :name="name"
                :required="required"
                :maxlength="maxlength"
                class="text-area"
                v-model="value_"
                @blur="
                    parseContent();
                    closeSuggest();
                    hasFocus(false);
                "
                @focus="hasFocus(true)"
                @keydown.space="parseContent"
                @keyup.esc.stop=""
                @keydown.down="moveDown"
                @keydown.up="moveUp"
                @keydown.enter.prevent="settle"
            >
            </textarea>
            <!--                 @keydown.tab="focusNext($event)" -->
            <ul :id="name + '-suggest'" class="suggest-list">
                <li
                    v-for="(item, index) in results"
                    :key="index"
                    @mouseenter="highlightedPosition = index"
                    @mousedown="select"
                    :name="name"
                    :class="['suggest-item', { highlighted: index === highlightedPosition }]"
                >
                    <slot name="item"
                        ><strong>{{ item.key }}</strong> {{ item.value }}</slot
                    >
                </li>
            </ul>
        </div>
        <div class="suggest-flex-side" :id="name + '-flex-side'">
            <button class="circle-button" tabindex="-1" type="button" v-on:click="openDictionary()">
                <div class="circle">
                    <img src="@/assets/frasregister.svg" />
                </div>
                Frasregister
            </button>
        </div>
    </div>
</template>

<script>
import TextAreaWidget from "@/components/widgets/TextAreaWidget.vue";
import { mapState } from "vuex";
import $ from "jquery";
import dialogEvents from "@/dialogEvents";
import { flashMessage } from "@/utils";
import linkEvents from "@/linkEvents";
import Dictionary from "@/components/Dictionary.vue";

import getCaretCoordinates from "textarea-caret";
// var getCaretCoordinates = import("textarea-caret");

export default {
    extends: TextAreaWidget,
    name: "SuggestTextArea",
    props: {
        dictionaryName: String,
    },
    data: function () {
        return {
            highlightedPosition: NaN,
            results: [],
            popupOpen: false,
        };
    },
    computed: {
        /**
         * Change to dictionaryName?
         */
        ...mapState("dictionary", ["dictionary", "selected"]),

        /**
         * Helper values
         */
        // Returns true if browser is IE
        isIE: function () {
            var ua = navigator.userAgent;
            /* MSIE used to detect old browsers and Trident used to newer ones*/
            return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
        },

        // Returns Textarea element (rather than a JQuery object)
        textArea() {
            return $('[name="' + this.name + '"]')[0];
        },
        // Returns the dropdown ("suggest") element (rather than a JQuery object)
        textAreaSuggest() {
            return $("#" + this.name + "-suggest")[0];
        },
        // Returns last word in Textarea separated by whitespace
        lastWord() {
            if (this.value_ === undefined || this.value_.length === 0) return "";
            // separate words by one or more whitespace characters
            else
                return this.value_
                    .split(/\s{1,}/)
                    .splice(-1)[0]
                    .trim();
        },
        // Returns index of last word
        indexOfLastWord() {
            return this.value_.lastIndexOf(this.lastWord);
        },
    },
    methods: {
        openDictionary() {
            var textAreaPosition = $("#" + this.name + "-suggest-wrap")[0]
                ? $("#" + this.name + "-suggest-wrap")[0].getBoundingClientRect()
                : { right: 0, width: 0, left: 0 };

            var dictionaryPosition = null;
            if (textAreaPosition.right + 540 < window.innerWidth)
                dictionaryPosition = {
                    left: textAreaPosition.width + textAreaPosition.left + 10 + "px",
                    top: "50%",
                    transform: "translateY(-50%)",
                };
            else
                dictionaryPosition = {
                    right: window.innerWidth - textAreaPosition.right + textAreaPosition.width + 10 + "px",
                    top: "50%",
                    transform: "translateY(-50%)",
                };

            dialogEvents.$emit("openPopup", {
                title: "Frasregister",
                component: Dictionary,
                position: dictionaryPosition,
                allowScroll: this.allowScroll,
            });
            this.$nextTick(() => {
                if (this.$el.scrollIntoView) this.$el.scrollIntoView(true);
            });
            if ($("#" + this.name + "-suggest-wrap")[0] && $("#" + this.name + "-suggest-wrap")[0].style)
                $("#" + this.name + "-suggest-wrap")[0].style.zIndex = "1031";
            if (("#" + this.name + "-flex-side")[0] && ("#" + this.name + "-flex-side")[0].style)
                $("#" + this.name + "-flex-side")[0].style.visibility = "hidden";
            if (this.textArea) this.textArea.focus();
        },
        closeDictionary() {
            if ($("#" + this.name + "-suggest-wrap")[0]) $("#" + this.name + "-suggest-wrap")[0].style.zIndex = "";
            if ($("#" + this.name + "-flex-side")[0]) $("#" + this.name + "-flex-side")[0].style.visibility = "visible";

            this.textArea.focus();
        },
        /**
         * Invoked on @keydown.space or @blur.
         */
        closeSuggest() {
            this.results = [];
        },
        parseContent() {
            if (this.filter) {
                let lengthBefore = this.value_.length;
                this.value_ = this.value_.replace(this.filter, "");
                if (lengthBefore !== this.value_.length) flashMessage("Otillåtna tecken har tagits bort från texten.");
            }

            // if text is empty or undefined, return.
            if (this.lastWord.length === 0) return;

            // Check if the word matches any key in dictionary. Not sure if we want this behaviour.
            /*
            else if (this.results.length >= 1) {
                this.value_ = this.value_.substring(0, this.indexOfLastWord) + this.results[0].value;
                this.updateValue();
                return;
            }
            */
        },
        /**
         * Checks if a word begins with uppercase letter
         */
        isFirstCharUpperCase(word) {
            if (word.length === 0) return false;
            if (word.charAt(0) === word.toUpperCase().charAt(0)) return true;
            return false;
        },
        /**
         * Returns the parameter with first letter uppercase
         */
        firstCharToUpperCase(word) {
            if (word.length === 0) return null;
            return word.substring(0, 1).toUpperCase() + word.substring(1);
        },
        moveDown(event) {
            if (this.results.length !== 0)
                // If suggest-list is populated, disable arrow navigation
                event.preventDefault();
            if (isNaN(this.highlightedPosition)) this.highlightedPosition = 0;
            else this.highlightedPosition = (this.highlightedPosition + 1) % this.results.length;
        },
        moveUp(event) {
            if (this.results.length !== 0)
                // If suggest-list is populated, disable arrow navigation
                event.preventDefault();
            this.highlightedPosition =
                this.highlightedPosition - 1 < 0 ? this.results.length - 1 : this.highlightedPosition - 1;
        },
        select() {
            this.value_ =
                this.value_.substring(0, this.indexOfLastWord) + this.results[this.highlightedPosition].value + " ";
            this.results = [this.results[this.highlightedPosition]];
        },
        settle(event) {
            // Enter is by default disabled to prevent linebreaks being passed by selecting a list item
            // If enter is pressed and results is empty, should allow enter anyway
            if (this.results.length === 0) this.value_ += "\n";
            if (!isNaN(this.highlightedPosition)) this.select();
            this.updateValue();
            event.stopPropagation();
        },
        updateValue() {
            this.highlightedPosition = NaN;

            if (this.isIE) this.textArea.innerText = this.value_;
        },
        update() {
            linkEvents.$emit(this.name + "_changed", this.value_);
        },
    },
    mounted() {
        /**
         * Subscribe to changes to "selected" in store/dictionary. Could be implemented as watch() as well.
         * When "selected" changes (i.e. word is selected in dictionary), update value_ and set focus on textarea.
         */
        this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case "dictionary/setSelected":
                    if (this.value_ == undefined) this.value_ = "";
                    var textArea = this.textArea;
                    var caretPosition = textArea.selectionStart;
                    this.value_ =
                        this.value_.substring(0, caretPosition) + this.selected + this.value_.substring(caretPosition);
                    caretPosition += this.selected.length;

                    // Behaviour in IE is very unpredictable
                    setTimeout(function () {
                        // Needs to be wrapped in setTimeout for some reason
                        textArea.focus();
                        textArea.setSelectionRange(caretPosition, caretPosition);
                    }, 0);
                    break;
            }
        });
    },
    created() {
        dialogEvents.$on("closePopup", this.closeDictionary);
    },
    beforeDestroy() {
        dialogEvents.$off("closePopup", this.closeDictionary);
    },
    watch: {
        value_() {
            // Check for abbreviation in dictionary
            linkEvents.$emit(this.name + "_changed", this.value_);

            this.results = [];
            if (this.lastWord.length === 0) return;
            for (const key in this.dictionary) {
                if (key.indexOf(this.lastWord.toLowerCase()) === 0) {
                    this.results.push({
                        key: this.isFirstCharUpperCase(this.lastWord) ? this.firstCharToUpperCase(key) : key,
                        value: this.isFirstCharUpperCase(this.lastWord)
                            ? this.firstCharToUpperCase(this.dictionary[key])
                            : this.dictionary[key],
                    });
                }
            }
            if (this.results.length >= 1)
                // Automatically set first item as highlighted
                this.highlightedPosition = 0;
            else this.highlightedPosition = NaN;
            var coords = getCaretCoordinates(this.textArea, this.textArea.selectionStart);
            if (this.textAreaSuggest && this.textAreaSuggest.style)
                this.textAreaSuggest.style.left = coords.left + "px";

            // Math.min prevents suggest-list from appearing too far below textarea
            // According to spec of getCaretCoordinates should do this anyway, but doesn't work?
            if (this.textAreaSuggest && this.textAreaSuggest.style)
                this.textAreaSuggest.style.top = Math.min(coords.top + 24, this.textArea.offsetHeight + 2) + "px";
        },
    },
};
</script>

<style lang="sass" scoped>
.suggest-wrap
    position: relative
    display: flex
    z-index: 100

    .suggest-flex-main
        flex-grow: 1

    // TODO: change to generic dropdown
    .suggest-list
        -webkit-box-sizing: border-box
        -moz-box-sizing: border-box
        box-sizing: border-box
        position: absolute
        max-width: 100%
        z-index: 100
        background: #ffffff
        padding: 0px !important

        .suggest-item
            list-style: none
            border: 1px solid #EEE
            margin: 0
            padding: 0
            border-width: 0 1px 1px 1px
            padding: .4rem

            &:first-of-type
                border-top: 1px solid #EEE

    .suggest-flex-side
        flex-grow: 0
        padding-left: 10px

        .circle-button
            width: 140px
            outline: none
            background-color: inherit
            border: none

        .circle
            margin-top: 10px
            margin-right: 10px
            height: 28px
            width: 28px
            border-radius: 14px
            background-color: #38A7CF
            border: none
            display: inline-block
            padding-top: 2px
            padding-left: 1px

        .circle-label
            margin-left: 38px
            margin-top: -24px
            color: black !important

.highlighted
    background: #eee
    cursor: pointer
</style>
