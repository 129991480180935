<template>
    <div class="statistikComponent">
        <section>
            <div v-if="chartData" class="barBox">
                <h4 class="h4or">
                    {{ chartData.statistiknamn }} | {{ kundNamn }} | Skadedatum perioden: {{ chartData.dates.from }} -
                    {{ chartData.dates.to }}
                </h4>
                <Bar
                    :chartData="chartData.chartData"
                    :chartOptions="chartOptions()"
                    :plugins="chartPlugins()"
                    :width="width"
                    :height="height"
                />
            </div>
            <div v-else>
                <h1>Laddar...</h1>
            </div>
            <div class="chartTable">
                <kliniken-table-chart
                    :headers="headers"
                    :kundNamn="kundNamn"
                    :tableDataInternal="sortedInternalDataObject"
                    :tableDataExternal="sortedExternalDataObject"
                    :tableDataAll="sortedAllDataObject"
                    :emptyObject="emptyObject"
                    :chartData="chartData"
                    liga="Alla ligor"
                />
            </div>
        </section>
    </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import Chart from "chart.js/auto"; // this import is needed https://github.com/sgratzl/chartjs-chart-wordcloud/issues/4
import KlinikenTableChart from "@/tabs/Skreg/Statistik/KlinikenTableChart.vue";
import { mapGetters } from "vuex";

export default {
    name: "StatistikComponent",
    components: {
        Bar,
        KlinikenTableChart,
        // eslint-disable-next-line vue/no-unused-components
        Chart, // to prevent PyCharm from removing the import when optimizing
    },
    props: {
        chartData: {
            type: Object,
            required: false,
            /* default: {
                statistiknamn: "",
                dates: {
                    from: "",
                    to: "",
                },
            }, */
        },
        width: {
            type: Number,
            default: "200",
        },
        height: {
            type: Number,
        },
        chartAxis: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            liga: "",
            headers: [
                { title: "" },
                { title: "Match" },
                { title: "Träning" },
                { title: "Totalt skador" },
                { title: "Skadedagar" },
                { title: "Dagar/Skada" },
            ],
            sortedAllDataObject: {},
            sortedInternalDataObject: {},
            sortedExternalDataObject: {},
            emptyObject: {},
        };
    },
    methods: {
        chartOptions() {
            const options = {
                indexAxis: this.chartAxis,
                onClick: this.graphClickEvent,
                plugins: {
                    title: {
                        display: true,
                        text: `${this.chartData.statistiknamn} skador`,
                    },
                },
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            stepSize: 1,
                        },
                    },
                    y: {
                        stacked: true,
                    },
                },
                legend: {
                    display: true,
                },
                responsive: true,
                maintainAspectRatio: true,
            };
            return options;
        },
        chartPlugins() {
            const plugings = [
                {
                    beforeInit: function (chart) {
                        chart.data.labels.forEach(function (e, i, a) {
                            if (e.length >= 30) {
                                var regex = /\s*(?:(\S{30})|([\s\S]{1,30})(?!\S))/g;
                                var resultString = e.replace(regex, function ($0, $1, $2) {
                                    return $1 ? $1 + "-\n" : $2 + "\n";
                                });
                                let _resultArray = resultString.split(/\n/);
                                _resultArray.pop();
                                a[i] = _resultArray;
                            }
                        });
                    },
                },
            ];
            return plugings;
        },
        updateTable() {
            const [inte_match_data, int_traning_data, ext_match_data, ext_traning_data] = this.chartData.chartDataArr;
            let _labels = this.chartData.chartData.labels;
            let int_match;
            let int_traning;

            let ext_match;
            let ext_traning;

            let int_matchSkadedagar;
            let int_traningSkadedagar;

            let ext_matchSkadedagar;
            let ext_traningSkadedagar;

            int_match = inte_match_data.antal;
            int_matchSkadedagar = inte_match_data.skadedagar;
            int_traning = int_traning_data.antal;
            int_traningSkadedagar = int_traning_data.skadedagar;

            ext_match = ext_match_data.antal;
            ext_matchSkadedagar = ext_match_data.skadedagar;
            ext_traning = ext_traning_data.antal;
            ext_traningSkadedagar = ext_traning_data.skadedagar;

            //all
            let _match_total = int_match.map((a, i) => a + ext_match[i]);
            let _traning_total = int_traning.map((a, i) => a + ext_traning[i]);
            let total = _match_total.map((a, i) => a + _traning_total[i]);

            let skadedagar_one = int_traningSkadedagar.map((a, i) => a + ext_traningSkadedagar[i]);
            let skadedagar_two = int_matchSkadedagar.map((a, i) => a + ext_matchSkadedagar[i]);
            let skadedagar = skadedagar_one.map((a, i) => a + skadedagar_two[i]);

            let dagPSkada = skadedagar.map((a, i) =>
                (a / total[i]).toFixed() == "NaN" ? "-" : (a / total[i]).toFixed()
            );
            this.sortedAllDataObject = { _labels, _match_total, _traning_total, total, skadedagar, dagPSkada };

            //internal
            let int_total = int_traning.map((a, i) => a + int_match[i]);
            let int_totalSkadedagar = int_traningSkadedagar.map((a, i) => a + int_matchSkadedagar[i]);
            let int_dagarPSkada = int_totalSkadedagar.map((a, i) =>
                (a / int_total[i]).toFixed() == "NaN" ? "-" : (a / int_total[i]).toFixed()
            );
            this.sortedInternalDataObject = {
                _labels,
                int_match,
                int_traning,
                int_total,
                int_totalSkadedagar,
                int_dagarPSkada,
            };

            //external
            let ext_total = ext_traning.map((a, i) => a + ext_match[i]);
            let ext_totalSkadedagar = ext_traningSkadedagar.map((a, i) => a + ext_matchSkadedagar[i]);
            let ext_dagarPSkada = ext_totalSkadedagar.map((a, i) =>
                (a / ext_total[i]).toFixed() == "NaN" ? "-" : (a / ext_total[i]).toFixed()
            );
            this.sortedExternalDataObject = {
                _labels,
                ext_match,
                ext_traning,
                ext_total,
                ext_totalSkadedagar,
                ext_dagarPSkada,
            };

            this.emptyObject = {
                _labels,
                m: [],
                t: [],
                t: [],
                sd: [],
                spd: [],
            };
        },
    },
    computed: {
        ...mapGetters("systemData", ["kundNamn"]),
        ...mapGetters("skreg/statistik", ["getInternalSkadekort"]),
        ...mapGetters("skreg/statistik", ["getExternalSkadekort"]),
        ...mapGetters("skreg/statistik", ["getAllaSkadekort"]),
        ...mapGetters("skreg/statistik", [
            "getLokalisationDataObject",
            "getSkademekanismDataObject",
            "getSkadetypDataObject",
        ]),
        statistikNamn() {
            return this.chartData.statistiknamn;
        },
    },
    mounted() {
        this.$parent.$on("updateTable", this.updateTable);
    },
    created() {
        this.updateTable();
    },
};
</script>
<style scoped>
.statistikComponent {
    margin-top: 170px;
}

.loading {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 200px;
}

.redbackground {
    background-color: red;
}
</style>
