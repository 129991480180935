<template>
    <div :class="{ 'note-indent': depth !== 0, 'node-spacer': true }">
        <input-wrapper
            v-model="sektion.text"
            @update="
                sektion.textObjectModel = $event;
                updateOmSa($event);
            "
            :class="{
                'input-label-button': sektion.sektioner.length && root,
                'input-label-span': !sektion.sektioner.length && root,
                'label-span': !root,
                'node-border': !root,
            }"
            :required="sektion.sokordsstruktur.obligatorisk"
            :definition="sektion.sokordsstruktur.beskrivning"
            :collapsable="root && sektion.sektioner.length ? toggleCollapse : null"
            v-if="!hasHiddenInput"
            v-bind="parameters"
            :component="getComponent(sektion.sokordsstruktur.typ)"
            :name="'input-' + depth + '-' + sektion.sokord.pk"
            :label="sektion.sokord.namn"
        />

        <hidden-widget v-else type="hidden" :value="sektion.text" />
        <div v-if="sektion.sokordsstruktur.typ === 'heading' && !sektion.disabled" :class="{ 'node-border': !root }">
            <button v-if="root" class="label-button" type="button" @click="toggleCollapse">
                <img :class="{ rotate: !expanded }" src="@/assets/dropdown-arrow-blue.svg" />
                {{ sektion.sokord.namn }}{{ sektion.sokordsstruktur.obligatorisk ? " *" : "" }}
            </button>
            <div class="label-span" v-else>
                <span> {{ sektion.sokord.namn }}{{ sektion.sokordsstruktur.obligatorisk ? " *" : "" }} </span>
            </div>
            <span v-if="sektion.sokordsstruktur.beskrivning">{{ sektion.sokordsstruktur.beskrivning }}</span>
        </div>
        <collapse-transition>
            <div v-if="expanded">
                <div :id="'sokord-' + depth + '-' + sektion.sokord.pk" v-if="sektion.sektioner.length">
                    <!--
                        NOTE: including sektion.disabled in key is essential here. I have not found a way to make recursive components reflect deep changes 
                in a recursive data structures. This forces re-rendering when the prop changes. It's hacky, but works. Whenever similar props are added
                to the component, they should be present in the key as well.
            -->
                    <recursive-form
                        v-if="expanded"
                        v-for="(subSektion, index) in sektion.sektioner"
                        v-show="!sektion.disabled"
                        :depth="depth + 1"
                        :rootEl="rootEl"
                        :key="
                            'sokord-' + depth + '-' + subSektion.sokord.namn + '-' + index + '-' + subSektion.updateKey
                        "
                        :sektion="subSektion"
                    ></recursive-form>
                </div>
            </div>
        </collapse-transition>
    </div>
</template>
<script>
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import widgets from "@/components/widgets/inputs";
import HiddenWidget from "@/components/widgets/inputs/HiddenWidget.vue";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";

const INPUT_TYPES = {
    text: { component: widgets.ScalableTextArea },
    heading: { component: null },
    hidden: { component: null },
    checkbox: { component: widgets.CheckboxWidget },
    integer: { component: widgets.NumberWidget },
    decimal: {
        component: widgets.NumberWidget,
        parameters: {
            step: 0.1,
        },
    },
    date: { component: widgets.DateWidget },
    radiobuttons: { component: widgets.RadioButtonWidget },
    dropdown: {
        component: widgets.SelectWidget,
    },
    autocomplete: {
        component: widgets.InfiniteWidget,
        parameters: {
            displayCode: true,
        },
    },
    yes_no: {
        component: widgets.SelectWidget,
        parameters: {
            options: [
                { text: "Ja", value: true },
                { text: "Nej", value: false },
            ],
        },
    },
};

const defined = function (value) {
    if (value === undefined) return false;
    if (value === null) return false;
    if (value === "") return false;
    return true;
};

export default {
    name: "RecursiveForm",
    components: {
        InputWrapper,
        HiddenWidget,
        CollapseTransition,
    },
    props: {
        sektion: Object, // Object
        depth: Number,
        rootEl: Object, // Reference to Journalanteckning-component, which we want to emit changes to.
    },
    data() {
        return {
            inputTypes: INPUT_TYPES,
            root: this.depth === 0,
            expanded: true,
        };
    },
    computed: {
        hasHiddenInput() {
            return (
                /**
                 * Sokordsstruktur is the only entry that decides if the Sokord in that Anteckningstyp is visible/editable.
                 * Sokord table is a way to link all Sokordsstruktur with the same Sokord.
                 * When Sokord.aktiv is set to False by the user, BE sets all Sokordsstruktur linked to aktiv=False.
                 * To reactivate a Sokord in an Anteckningstyp, the user has to do it by hand.
                 *
                 * @return {boolean} - Returns true if the sektion input/text should be hidden, false otherwise.
                 */
                this.sektion.sokordsstruktur.typ === "heading" ||
                this.sektion.sokordsstruktur.typ === "hidden" ||
                this.sektion.disabled === true ||
                (this.sektion.sokordsstruktur.aktiv === false && this.sektion.text === "") // show text even if Sokordsstruktur becomes inaktiv
            );
        },
        parameters() {
            let sokordStruktur = this.sektion.sokordsstruktur;
            let typ = sokordStruktur.typ;
            let alternativ = sokordStruktur.alternativ;
            let inputTyp = this.inputTypes[typ];
            let parameters = inputTyp.parameters || {};

            if (alternativ) {
                if (typ === "dropdown" || typ === "radiobuttons") {
                    let alternativOptions = alternativ.split(";");
                    let options = [];
                    alternativOptions.forEach((el) => {
                        options.push({ text: el, value: el });
                    });
                    parameters.options = options;
                } else if (typ === "autocomplete") {
                    parameters.apisearch = alternativ;
                }
            }
            if (typ === "checkbox") parameters.checkboxLabel = sokordStruktur.sokord.namn;
            if (sokordStruktur.skrivskyddad) parameters.disabled = true;
            return parameters;
        },
        omSa() {
            return this.sektion.sokordsstruktur.om_sa !== "";
        },
    },
    methods: {
        toggleCollapse() {
            this.expanded = !this.expanded;
            //$("#sokord-" + this.depth + "-" + this.sektion.sokord.pk).collapse("toggle");
        },
        getComponent(typ) {
            let inputTyp = this.inputTypes[typ];
            return inputTyp.component;
        },
        updateOmSa(event) {
            if (!this.omSa) return;
            let omSa = JSON.parse(this.sektion.sokordsstruktur.om_sa);
            this.rootEl.$emit("updateOmSa", {
                selectedValue: event.value,
                omSa,
            });
        },
    },
};
</script>
<style lang="sass" scoped>
::v-deep .note-indent
  .input-wrapper
    span
      font-size: 16px

.k3-journal

  .input-label-button
    > button:first-of-type
      height: 21px
      font-weight: 400
      color: #277692
      font-size: 20px
      line-height: 21px
      border: none
      outline: none
      background-color: inherit
      display: block
      margin-bottom: 5px

  .input-label-span
    > span:first-of-type
      height: 21px
      font-weight: 400
      color: #277692
      font-size: 20px
      line-height: 21px
      margin-bottom: 5px

      &.error
        color: #9E354B

  .label-span
    > span:first-of-type
      height: 21px
      color: #277692
      font-size: 16px
      font-weight: 500
      line-height: 21px
      margin-bottom: 5px

      &.error
        color: #9E354B
</style>

<style lang="sass" scoped>
.note-indent
  margin-left: 30px

.node-spacer

  .node-border
    padding-top: 20px
    margin-left: -30px
    padding-left: 30px
    border-left: 3px solid #C8E6EC

  &:first-of-type

    > .node-border
      padding-top: 0px
      margin-top: 20px

  &:last-of-type
    margin-bottom: 20px

.label-button
  height: 21px
  font-weight: 400
  color: #277692
  font-size: 20px
  line-height: 21px
  border: none
  outline: none
  background-color: inherit
  display: block

img
  height: 12px
  width: 12px
  margin-right: 5px
  margin-top: -5px

  &.rotate
    transform: rotate(180deg)

.input-wrapper
  margin-bottom: 0px !important
</style>
