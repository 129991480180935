<template>
    <div :class="context.classes.element" :readonly="context.attributes.readonly" :data-type="context.type">
        <FormulateSlot name="prefix" :context="context">
            <component :is="context.slotComponents.prefix" v-if="context.slotComponents.prefix" :context="context" />
        </FormulateSlot>
        <v-select
            v-model="context.model"
            :options="context.options"
            v-bind="attributes"
            v-on="$listeners"
            :reduce="(x) => x.value"
            label="label"
            @close="context.blurHandler"
            :components="{ OpenIndicator }"
        >
            <template #no-options="{ search, searching, loading }">
                <template v-if="search !== undefined && search.length === 0"> Börja skriv för att söka</template>
                <template v-else> Det finns inga alternativ</template>
            </template>
        </v-select>
        <slot></slot>
        <FormulateSlot name="suffix" :context="context">
            <component :is="context.slotComponents.suffix" v-if="context.slotComponents.suffix" :context="context" />
        </FormulateSlot>
    </div>
</template>

<script>
    import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
    import vSelect from "vue-select";

    export default {
        name: "FormulateVueSelect",
        mixins: [FormulateInputMixin],
        components: {
            vSelect,
        },
        data() {
            return {
                OpenIndicator: {
                    render: (createElement) => createElement("span", ""),
                },
            };
        },
    };
</script>
