<template>
    <div class="product-order pad">
        <div class="k3-journalmall-header">
            <div class="k3-journalmall-header-main">
                {{ favorit.id ? "REDIGERA FAVORIT - RECEPT" : "SKAPA FAVORIT - RECEPT" }}
            </div>
            <div class="k3-journalmall-header-border-bottom"></div>
        </div>
        <br />
        Namn mall*
        <text-widget
            v-if="favoritSetNamn !== null"
            v-model="favoritSetNamn"
            :required="true"
            style="max-width: 200px"
            :maxlength="60"
        />
        <br />
        <radio-button-widget
            :disabled="!rightToCreateGemensamFavorit"
            v-model="personlig"
            :options="[
                { text: 'Personlig Favorit', value: true },
                { text: 'Gemensam Favorit', value: false },
            ]"
        />
        <div class="product-selection selected order-form" v-if="selected">
            <div class="fixed-width-align-center">
                <h3 v-if="selected.tempId || selected.pk">Ändra favorit</h3>
                <h3 v-else>Lägg till läkemedel i favorit</h3>

                <div class="product-display">
                    <table class="table selected">
                        <tbody>
                            <td>
                                {{ selected.drugArticle.distributedTradeName }}
                                <span v-if="selected.drugArticle.isRecommended" class="label" title="Rekommenderat"
                                    >REK</span
                                >
                                <span
                                    v-if="!silApproved.includes(selected.drugArticle.productTypeCode)"
                                    class="label"
                                    title="Rekommenderat"
                                    >IGL</span
                                >
                                <br /><span class="manufacturer">{{
                                    selected.drugArticle.localRepresentative || selected.drugArticle.marketingAuthHolder
                                }}</span>
                            </td>
                            <td>{{ selected.drugArticle.strengthText }}</td>
                            <td>{{ selected.drugArticle.atcCode }}</td>
                            <td>{{ selected.drugArticle.drugFormTextSv }}</td>
                            <td>{{ selected.drugArticle.packSizeText }}</td>
                        </tbody>
                    </table>
                    <table class="lm-info" style="margin-bottom: 20px">
                        <tbody>
                            <tr>
                                <td>Läkemedel</td>
                                <td>{{ selected.drugArticle.distributedTradeName }}</td>
                            </tr>
                            <tr>
                                <td>Styrka</td>
                                <td>{{ selected.drugArticle.strengthText }}</td>
                            </tr>
                            <tr>
                                <td>Förpackningsinnehåll</td>
                                <td>{{ selected.drugArticle.packageText }}</td>
                            </tr>
                            <tr>
                                <td>Form</td>
                                <td>{{ selected.drugArticle.drugFormTextSv }}</td>
                            </tr>
                            <tr>
                                <td>ATC</td>
                                <td>{{ selected.drugArticle.atcCode }}</td>
                            </tr>
                            <tr v-if="selected.drugArticle.productTypeCode !== 'LIC'">
                                <td>Pris</td>
                                <td>{{ parseInt(selected.drugArticle.aup) }} kr</td>
                            </tr>
                            <tr v-if="selected.drugArticle.productTypeCode !== 'LIC'">
                                <td>FASS-länk</td>
                                <td>
                                    <!-- Licensläkemedel saknar FASS-sida -->
                                    <a
                                        :href="
                                            'https://www.fass.se/LIF/product?userType=0&nplId=' +
                                            selected.drugArticle.nplId
                                        "
                                        target="_blank"
                                        >{{ selected.drugArticle.distributedTradeName }}
                                        {{ selected.drugArticle.strengthText }} (öppnas i nytt fönster)</a
                                    >
                                </td>
                            </tr>
                            <tr v-if="janusBaseURL">
                                <td>Janus-info:</td>
                                <!-- TODO: set height and width according to content of IFRAME -->
                                <td>
                                    <div class="janus-wrapper">
                                        <div class="janus-border" />
                                        <iframe
                                            name="janus"
                                            scrolling="no"
                                            seamless
                                            class="janus-info"
                                            :src="janusURLSelected"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- FIXME :key maybe not so clean -->
                    <prescription-edit-form
                        ref="orderForm"
                        :formcomponents="formComponents"
                        :requiredfields="requiredFields"
                        :data="selected"
                        :key="selected.tempId || selected.drugArticle.id"
                        @submit-order="orderSelected"
                        @cancel-order="deSelect"
                        :favorit="favorit"
                    />
                </div>
            </div>
        </div>
        <h3>Favoritrader</h3>
        <action-table
            :columns="orderTableColumns"
            :required-fields="requiredFields"
            :items="favoritrader"
            :actions="favouriteActions"
        ></action-table>
        <!-- :sort-method="(key,reversed) => $store.commit('prescriptionSet/sortItems', { key, reversed })" -->
        <div class="janus-table" v-if="janusBaseURL && favoritrader.length">
            Janusmed
            <div class="janus-wrapper">
                <div class="janus-border" />
                <iframe name="janus" scrolling="no" seamless class="janus-info" :src="janusURLAll" />
            </div>
        </div>
        <div class="order-buttons">
            <ButtonGroup style="padding-top: 22px">
                <Button size="primary" @click="addNew = !addNew"> Lägg till nytt läkemedel</Button>
                <Button size="primary" :disabled="!personlig && !rightToCreateGemensamFavorit" @click="save">
                    Spara som favorit
                </Button>
                <Button variant="contour" size="secondary" class="btn btn-outline-secondary" @click="cancel"
                    >Avbryt och stäng
                </Button>
            </ButtonGroup>
        </div>
        <template v-if="addNew && !selected">
            <form class="form-active" @submit.prevent="search">
                <fieldset class="form-group">
                    <label for="presc_q">Sök läkemedel eller läkemedel och styrka</label>
                    <div class="search-wrapper">
                        <input
                            class="search-field"
                            name="q"
                            id="presc_q"
                            type="search"
                            v-model="q"
                            autocomplete="off"
                        />
                        <!-- Well-known IE bug that text inputs in form groups are not set to disabled with the other fields. -->
                        <Button class="search-button" type="submit">Sök</Button>
                    </div>
                    <!-- NOTE: using the relevant widgets breaks v-model and has to use events instead.-->
                    <label class="checkbox-container" style="margin-right: 30px"
                        >Visa endast rekommenderade läkemedel
                        <input type="checkbox" name="rec" id="presc_rec" v-model="rek" />
                        <span class="checkmark" />
                        <span class="label label-inline">REK</span>
                    </label>
                    <label class="checkbox-container" style="margin-right: 30px"
                        >Visa icke godkända läkemedel
                        <input type="checkbox" name="igl" id="presc_igl" v-model="igl" />
                        <span class="checkmark" />
                        <span class="label label-inline">IGL</span>
                    </label>
                </fieldset>
            </form>
            <h3>Sökträffar läkemedel</h3>
            <div class="product-selection">
                <div class="active">
                    <action-table
                        v-for="group in groupedSearchResults"
                        :labels="groupLabel(group)"
                        :key="group[0].drugId"
                        :columns="drugTableColumns"
                        :items="group"
                        :disabled-row-handler="disabledRowHandler"
                        :id="'group-' + group[0].drugId"
                        :collapse-label="group[0].distributedTradeName + ' (' + group.length + ')'"
                        selectable-rows
                        class="soktraff-action-table group"
                    />
                    <!-- :sort-method="(key, reversed) => groupSort(group, key, reversed)" // disabled in prescriptionSet -->
                    <!-- :selected-item="selected" // removing - this table can never be shown while selected is not null-->
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import BaseTab from "@/tabs/mixins/BaseTab";
import tabIcon from "@/assets/receptforskrivning.svg";
import ActionTable from "@/components/generic/ActionTable.vue";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";
import widgets from "@/components/widgets";
import PrescriptionEditForm from "./PrescriptionEditForm.vue";
import TextWidget from "@/components/widgets/inputs/TextWidget.vue";
import RadioButtonWidget from "@/components/widgets/inputs/RadioButtonWidget.vue";
import tabEvents from "@/tabEvents";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

const ORDER_TABLE_COLUMNS = [
    {
        name: "drugArticle.distributedTradeName",
        title: "Namn",
        renderer: (item) => {
            return item.drugArticle.distributedTradeName;
        },
    },
    {
        name: "drugArticle.strengthNumeric",
        title: "Styrka",
        renderer: (item) => {
            return item.drugArticle.strengthText;
        },
    },
    {
        name: "drugArticle.drugFormTextSv",
        title: "Form",
        renderer: (item) => {
            return item.drugArticle.drugFormTextSv;
        },
    },
    {
        name: "numberOfPackages",
        title: "Antal beställning",
        renderer: (item) => {
            if (item.medicinalProduct === "00000000000000") return "";
            return (
                (item.numberOfPackages === null || item.numberOfPackages === undefined
                    ? "ej angivet antal"
                    : item.numberOfPackages) +
                " förp. à " +
                item.drugArticle.packSizeText
            );
        },
    },
    { name: "unstructuredDosageAdmin", title: "Ordination" },
];

const NullableString = (string) => string || null;
const NullableNumber = (string) => (string ? Number(string) : null);
const ORDER_DETAIL_TYPES = {
    dispensingRepeatNumber: Number,
    numberOfPackages: Number,
    timeValue: NullableNumber,
    latestRequestedTimeForDispensing: NullableString,
};

const SEARCH_WAIT = 200;

const SIL_APPROVED = [
    "MEP", // läkemedel
    "VBL", // växtbaserade läkemedel
    "ALL", // allergen
    "NLM", // naturläkemedel
    "VUM", // vissa utvärtes läkemedel
    "RAP", // radiofarmaka
];

const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
};

const DRUG_TABLE_COLUMNS = [
    {
        name: "distributedTradeName",
        title: "Namn",
        renderer: (item) => {
            let returnString = item.distributedTradeName;
            if (item.isRecommended) returnString += '<span class="label">REK</span>';
            if (!SIL_APPROVED.includes(item.productTypeCode)) returnString += '<span class="label">IGL</span>';
            returnString +=
                '<br/><span class="manufacturer">' +
                (item.localRepresentative || item.marketingAuthHolder || "") +
                "</span>";
            return returnString;
        },
    },
    {
        name: "atcCode",
        title: "ATC",
    },
    {
        name: "strengthNumeric",
        title: "Styrka",
        renderer: (item) => {
            return item.strengthText;
        },
    },
    {
        name: "drugFormTextSv",
        title: "Form",
    },
    {
        name: "packageText",
        title: "Förpackningsinformation",
    },
];

const FAVORIT_ACTIONS = [
    {
        name: "favorit::redigera",
        title: "Redigera",
    },
    {
        name: "favorit::radera",
        title: "Radera",
    },
];

const formComponents = [
    {
        label: "Doseringsanvisning",
        component: widgets.DosageSuggestTextArea,
        parameters: {
            name: "unstructuredDosageAdmin",
            maxlength: 254,
            filter: /[^\u0020-\u007E\u00A1-\u00FF]+/g,
        },
        colspan: 6,
    },
    {
        label: "Ordinationsorsak (ICD)",
        component: widgets.AutoCompleteWidget,
        parameters: {
            name: "indicationForPrescription",
            apisearch: "/core/kodverk/icdkoder/",
            displayCode: true,
        },
    },
    {
        label: "Antal förpackningar",
        component: widgets.RangeWidget,
        parameters: {
            name: "numberOfPackages",
            min: 1,
            max: 9999,
        },
    },
    {
        label: "Antal uttag",
        component: widgets.RangeWidget,
        parameters: {
            name: "dispensingRepeatNumber",
            min: 1,
            max: 99,
        },
    },
    {
        label: "Startförpackning",
        component: widgets.SelectWidget,
        parameters: {
            name: "pro",
            options: [
                { text: "Ja", value: true },
                { text: "Nej", value: false },
            ],
        },
    },
    {
        label: "Språk",
        component: widgets.AutoCompleteWidget,
        parameters: {
            name: "languageOfLabel",
            apisearch: "/core/kodverk/iso_639_1/",
            map: (el) => {
                return {
                    id: el.code,
                    code: el.code,
                    displayName: el.displayName,
                };
            },
        },
    },
    {
        label: "Får bytas",
        component: widgets.SelectWidget,
        parameters: {
            name: "eby",
            // Frågan som BE hanterar är "Får ej bytas mot generika", så Ja på frågan "Får bytas" blir false i BE och Nej blir true
            options: [
                { text: "Ja", value: false },
                { text: "Nej", value: true },
            ],
        },
    },
    /*     {
      label: "Sista dag för första uttag",
      component: widgets.DateWidget,
      parameters: {
          name: "latestRequestedTimeForDispensing",
          notBefore: "today",
          notAfter: "year",
      },
  }, */
    {
        label: "Tid mellan uttag",
        component: widgets.RangeWidget,
        parameters: {
            name: "timeValue",
            min: 1,
            max: 365,
        },
    },
    {
        label: "Tidsenhet",
        component: widgets.SelectWidget,
        parameters: {
            name: "timeUnit",
            options: [
                { text: "", value: "" },
                { text: "dag/dagar", value: "Dg" },
                { text: "vecka/veckor", value: "V" },
                { text: "månad(er)", value: "Mn" },
            ],
        },
    },
];

export default {
    extends: BaseTab,
    name: "Favoritsamling",
    icon: tabIcon,
    tabName: "Skapa/redigera favorit",
    patientBound: false,
    props: {
        tabId: Number,
    },
    data() {
        return {
            favouriteActions: FAVORIT_ACTIONS,
            requiredFields: [],
            orderTableColumns: ORDER_TABLE_COLUMNS,
            addNew: false,
            igl: false,
            rek: false,
            q: "",
            drugTableColumns: DRUG_TABLE_COLUMNS,
            searching: false,
            results: [],
            selected: null,
            favoritSetNamn: null,
            silApproved: SIL_APPROVED,
            formComponents: formComponents,
            personlig: true,
        };
    },
    components: {
        ActionTable,
        PrescriptionEditForm,
        TextWidget,
        RadioButtonWidget,
        Button,
        ButtonGroup,
    },
    computed: {
        ...mapGetters("prescriptionSet", ["favourites", "favorit", "favoritrader"]),
        ...mapGetters("systemData", ["systemName", "systemVersion", "janusBaseURL"]),
        ...mapGetters("userData", ["arbKod", "currentProfile"]),
        ...mapGetters("patientData", ["binaryGender", "dateOfBirth"]),
        groupedSearchResults() {
            let groupedResults = [];
            let drugIds = this.results.map((item) => item.drugId).filter(distinct);
            drugIds.forEach((drugId) => {
                groupedResults.push(this.results.filter((item) => item.drugId === drugId));
            });
            return groupedResults;
        },
        rightToCreateGemensamFavorit() {
            if (!this.currentProfile) return false;
            return this.currentProfile.user.is_superuser || this.currentProfile.user.is_verksamhetsadmin;
        },
        janusURLSelected() {
            return this.janusURL(this.favoritrader.concat(this.selected));
        },
        janusURLAll() {
            return this.janusURL(this.favoritrader);
        },
    },
    methods: {
        async cancel() {
            await this.$store.dispatch("tabs/closeTab", this.tabId);
        },
        disabledRowHandler(item) {
            return !item.is_cancelled;
        },
        groupLabel(group) {
            let labels = [];
            if (group.filter((el) => el.isRecommended).length > 0) labels.push("REK");
            if (group.filter((el) => !SIL_APPROVED.includes(el.productTypeCode)).length > 0) labels.push("IGL");
            return labels;
        },
        search() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            if (this.q.length >= 3) {
                this.searchTimeout = setTimeout(this.fetchResults, SEARCH_WAIT);
            }
        },
        getSearchParams() {
            let params = {
                q: this.q,
                limit: 1000,
            };
            if (this.rek) params.isRecommended = true;
            if (!this.igl) params.productTypeGroup = "APPROVED";
            return params;
        },
        async fetchResults() {
            if (this.searching) return;
            this.searching = true;
            await klinikenApi
                .get("/sil/search/", getConfig({ params: this.getSearchParams() }))
                .then((response) => {
                    let results = response.data.results;
                    results.forEach((item) => {
                        // TEMPORARY
                        // Remove when API sends in correct case.
                        // item.drugId = item.drugId || item.drugid;
                        // Alternative drugId
                        item.drugId = btoa(item.distributedTradeName.toLowerCase()).replace(/[/=+]/g, "");
                        item.id = item.nplPackId;
                    });
                    this.results = results;
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
            this.searching = false;
        },
        addFavoritItem(item) {
            this.selected = { drugArticle: item };
        },
        deSelect() {
            this.selected = null;
        },
        janusURL: function (items) {
            if (!this.janusBaseURL) return null;
            let url = this.janusBaseURL + "K=";
            url += this.binaryGender.charAt(0);
            url += "&F=" + this.dateOfBirth.substring(0, 4);
            url += "&FORSKOD=&ARBKOD=" + this.arbKod + "&SNAME=" + this.systemName + "&SVER=" + this.systemVersion;
            for (let i = 0; i < items.length; i++) {
                if (items[i].drugArticle.nplId !== "00000000000000") url += "&L" + i + "=" + items[i].drugArticle.nplId;
            }
            return url;
        },
        orderSelected() {
            const order = {
                item: this.selected,
                details: this.getOrderDetails(),
            };
            if (this.selected.pk || this.selected.tempId)
                this.$store.dispatch("prescriptionSet/updateFavoritItem", order);
            else this.$store.dispatch("prescriptionSet/addFavoritItem", order);
            this.addNew = false;
            this.selected = null;
            this.q = "";
            this.results = [];
        },
        getOrderDetails() {
            let details = {};
            let orderForm = document.getElementById("recipe-details");
            let formData = new FormData(orderForm);

            for (var pair of formData.entries()) {
                var key = pair[0];
                var value = pair[1];
                try {
                    value = JSON.parse(value);
                    //eslint-disable-next-line
                } catch (e) {}
                if (key in ORDER_DETAIL_TYPES) details[key] = ORDER_DETAIL_TYPES[key](value);
                else {
                    // Check if details already exists
                    if (details[key]) {
                        // If does, check if it is an array, if not convert to array
                        if (!Array.isArray(details[key])) details[key] = [details[key]];
                        details[key].push(value);
                    } else details[key] = value;
                }
            }
            return details;
        },
        async save() {
            this.$store.commit("prescriptionSet/setFavoritNamn", this.favoritSetNamn);
            this.$store.commit(
                "prescriptionSet/setFavoritAnvandare",
                this.personlig ? this.currentProfile.user.pk : null
            );
            await this.$store.dispatch("prescriptionSet/saveFavoritSet");
            await this.$store.dispatch("prescriptionSet/fetchBothFavoriter", this.currentProfile.user);
            tabEvents.$emit("refreshFavoriter");
        },
        deleteFavoritItem(item) {
            this.$store.commit("prescriptionSet/removeFavoritItem", item);
        },
        editFavoritItem(item) {
            this.selected = item;
        },
    },
    async created() {
        //await this.$store.dispatch("prescriptionSet/fetchFavourites");
        this.favoritSetNamn = this.favorit && this.favorit.namn ? this.favorit.namn : "";
        this.personlig = (this.favorit && this.favorit.anvandare) || (this.favorit && !this.favorit.id) ? true : false;
        this.$on("actionTable::select", this.addFavoritItem);
        this.$on("favorit::redigera", this.editFavoritItem);
        this.$on("favorit::radera", this.deleteFavoritItem);
    },
    beforeDestroy() {
        this.$store.commit("prescriptionSet/resetFavorit");
        this.$off("actionTable::select", this.addFavoritItem);
        this.$off("favorit::redigera", this.editFavoritItem);
        this.$off("favorit::radera", this.deleteFavoritItem);
    },
    watch: {
        q() {
            if (this.searchTimeout) setTimeout(this.search, 200);
            else this.search();
        },
        rek() {
            this.search();
        },
        igl() {
            this.search();
        },
    },
};
</script>
<style lang="sass" scoped>
@import @/style/variables

h3
    padding: 20px 0px 0px 12px

.pad
    .k3-journalmall-header
        width: 718px

    .k3-journalmall-header-main
        width: 100%
        height: 52px
        background: linear-gradient(134.72deg, #EDF8F8 0%, rgba(194, 230, 228, 0) 100%)
        padding-left: 20px
        padding-top: 15px
        color: #277692
        font-family: Roboto
        font-size: 17px
        letter-spacing: 0
        line-height: 28px

.k3-journalmall-header-border-bottom
    width: 100%
    height: 8px
    background: linear-gradient(134.72deg, #C2E6E6 0%, rgba(194, 230, 228, 0) 100%)

form
    padding: 20px

    &.form-active
        margin-top: 30px
        background-color: $color-blue-light

.order-buttons
    background-color: $color-blue-light
    padding: 0px 0px 10px 22px

.form-group
    .label
        float: none
        margin-left: 20px
        border-radius: 2px
        padding-left: 2px
        padding-right: 2px
        font-weight: bold
        font-size: 12px
        margin-top: 2px
        color: $color-label-grey
        border: 1px solid $color-border-grey
        max-height: 17px
        line-height: 17px

        &.label-inline
            float: right

// Styles for search bar and button
.search-wrapper
    display: flex
    margin-bottom: 30px

    .search-field
        height: 46px
        width: 693px
        border-radius: 4px
        font-family: Roboto
        font-size: 16px
        line-height: 21px
        border: 1px solid #95a1b1
        margin-right: 15px
        flex: 0 1 100%
        padding-left: 10px
        padding-right: 10px
        -webkit-box-sizing: border-box
        -moz-box-sizing: border-box
        box-sizing: border-box

    .search-button
        height: 46px
        flex: 0 0 211px

.janus-wrapper
    position: relative
    height: 32px
    width: 473px

    .janus-info
        position: absolute
        display: block
        height: 100%
        width: 100%
        border: none
        overflow-y: hidden
        overflow-x: hidden

    .janus-border
        pointer-events: none
        /* Allow click-through */
        position: absolute
        z-index: 1
        height: 100%
        width: 100%
        border: 4px solid $color-blue-light
/* Paint over the grey background */

// Styles for Janus-med iframe
.janus-table
    color: $color-thead
    font-family: Roboto Medium
    font-size: 14px
    line-height: 16px
    width: 100%
    padding: 10px 0px 10px 12px

.product-order .janus-table
    background-color: $color-blue-light
    border-bottom: 1px solid $color-border-light-grey

.product-display
    max-width: 1100px
    padding-top: 1px
    background-color: $color-blue-light

.lm-info
    margin: 10px 20px 0 20px

    td
        padding: 5px

        &:first-child
            font-family: Roboto Medium
            text-align: right

.product-selection
    margin-bottom: 30px

.selected .table
    margin: 20px
    padding: 10px
    border-collapse: separate
    border-radius: 1px
    -moz-border-radius: 1px
    -webkit-border-radius: 1px
    border: 1px solid white
    background-color: white
    color: $color-thead
    width: 1060px

    td
        border: none !important
        line-height: 24px

    td:first-of-type
        font-size: 24px
        font-weight: bold
        font-family: Roboto Medium

    a
        color: white

    a:hover,
    a:focus
        background-color: $color-tablerow-selected

    span
        display: inline

::v-deep .soktraff-action-table
    margin-bottom: 10px

    .btn-block
        background-color: #e4e8f0

    table
        background-color: #f3f5f9
</style>
