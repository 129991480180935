<template slot="popover">
    <div class="tooltip-content">
        <div class="history-popover-wrapper" v-for="versionHistory in history" :key="versionHistory.text">
            <div class="history-popover">
                <div class="history-flex-wrapper">
                    <div class="version">Version {{ versionHistory.data.version }}</div>
                    <div class="border" />
                    <div>{{ updatedAt(versionHistory.data) }}</div>
                    <div class="border" />
                    <div>av {{ updatedBy(versionHistory.data) }}</div>
                </div>
                <div v-if="getValue(versionHistory.value)" class="text">
                    {{ getValue(versionHistory.value) }}
                </div>
                <div v-else class="text empty-text">[ ingen notering ]</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        history: Array, // Each item is an object with the following format: {value: Text, data: Object }
        // where data is the PrescriptionItemDetails containing relevant metadata such as updated_by.
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        updatedAt(data) {
            let date = new Date(data.edited_at || data.updated_at || data.created_at);
            return date.toLocaleString("sv-SE").slice(0, -9) + " kl. " + date.toLocaleString("sv-SE").slice(11, -3);
        },
        updatedBy(data) {
            return (data.edited_by || data.updated_by || data.created_by).user.name;
        },
        getValue(value) {
            if (typeof value === "object" && value !== null) {
                return value.code + " " + value.displayName;
            } else return value;
        },
    },
};
</script>
<style lang="sass" scoped>
.history-popover
    box-sizing: border-box
    min-width: 425px
    border: 1px solid #E4E8F0
    border-radius: 4px
    background-color: #F0F3F8
    margin-bottom: 6px
    color: #7B868A
    font-size: 14px
    letter-spacing: 0
    line-height: 16px
    padding: 18px
    text-align: left
    box-shadow: 2px 2px 13px 0 rgba(53,64,82,0.25)
    -webkit-filter: drop-shadow(0px 2px 2px rgba(130,130,130,1))
    filter        : drop-shadow(0px 2px 2px rgba(130,130,130,1))
    -ms-filter    : "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')"
    filter        : "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')"

    .history-flex-wrapper
        display: flex
        width: 100%

        div
            flex: 0
            white-space: nowrap

    .version
        color: #277692

    .text
        color: #354052
        font-size: 16px
        letter-spacing: 0
        line-height: 20px
        margin-top: 20px
        margin-bottom: 16px

    .empty-text
        color: #7B868A

    .border
        box-sizing: border-box
        height: 17.37px
        width: 0.5px
        border: 1px solid #979797
        margin-left: 13px
        margin-right: 13px

.history-popover
    position: relative

    &:after // Arrow component
        top: 100%
        left: 50%
        border: solid transparent
        content: " "
        height: 0
        width: 0
        position: absolute
        pointer-events: none
        border-color: rgba(255, 255, 255, 0)
        border-top-color: #F0F3F8
        border-width: 6px
        margin-left: -18px
</style>
