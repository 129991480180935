<template>
    <div id="tab-container">
        <div class="layout-selector">
            <button
                class="toggle"
                @click.prevent="toggleLayoutSelector = !toggleLayoutSelector"
                v-on-clickaway="hideLayoutSelector"
            >
                <img src="@/assets/layout_select.png" /><span>Välj vy</span>
            </button>
            <div class="menu" v-if="toggleLayoutSelector">
                <button
                    @click="setSideTabs(0, COL)"
                    :disabled="sideTabs.length === 0"
                    :class="{ selected: sideTabs.length === 0 }"
                >
                    <img src="@/assets/fullscreen.png" /><span>Fullskärm</span>
                </button>
                <button
                    @click="setSideTabs(1, COL)"
                    :disabled="tabs.length < 2 || (sideTabs.length === 1 && tabContainersDirection === COL)"
                    :class="{ selected: sideTabs.length === 1 && tabContainersDirection === COL }"
                >
                    <img src="@/assets/2_columns.png" /><span>2 lodrätt</span>
                </button>
                <button
                    @click="setSideTabs(1, ROW)"
                    :disabled="tabs.length < 2 || (sideTabs.length === 1 && tabContainersDirection === ROW)"
                    :class="{ selected: sideTabs.length === 3 && tabContainersDirection === ROW }"
                >
                    <img src="@/assets/2_rows.png" /><span>2 vågrätt</span>
                </button>
            </div>
        </div>

        <div class="tab-containers" :class="tabContainersClass">
            <div class="tabs">
                <div
                    v-for="tab in tabs.filter((i) => !i.isSideTab)"
                    :key="tab.name"
                    class="tab"
                    :class="{ active: tab.visible || tab.isSideTab }"
                >
                    <a @click="showTab(tab.id)">
                        <span class="name">{{ tab.tabName }}</span>
                        <a class="close" @click.stop="closeTab(tab.id)"> &times; </a>
                    </a>
                </div>
            </div>

            <div class="tabs side">
                <div
                    v-for="tab in tabs.filter((i) => i.isSideTab)"
                    :key="tab.name"
                    class="tab"
                    :class="{ active: tab.visible || tab.isSideTab }"
                >
                    <a @click="showTab(tab.id)">
                        <span class="name">{{ tab.tabName }}</span>
                        <a class="close" @click.stop="closeTab(tab.id)"> &times; </a>
                    </a>
                </div>
            </div>

            <section
                v-for="tab in tabs"
                :key="tab.id"
                :class="{ side: tab.isSideTab }"
                v-show="tab.visible || tab.isSideTab"
            >
                <div :class="{ 'spinner-overlay': tab.displaySpinner }">
                    <component
                        :is="tab.component"
                        :parameters="tab.parameters"
                        :tab-id="tab.id"
                        :ref="tab.id"
                        :uid="tab.createdTime"
                        :key="'component-' + tab.createdTime"
                    />
                    <div class="spinner-wrapper" v-if="tab.displaySpinner">
                        <img class="spinner" src="@/assets/loader.svg" />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import { mapGetters, mapMutations } from "vuex";
import { openDialog } from "@/utils";
import emitter from "tiny-emitter/instance";

export default {
    mixins: [clickaway],

    data() {
        return {
            COL: "col",
            ROW: "row",
            toggleLayoutSelector: false,
            tabContainersDirection: "col",
        };
    },

    computed: {
        ...mapGetters("tabs", ["tabs"]),
        tabContainersClass() {
            return `tab-containers-${this.sideTabs.length + 1} tab-containers-${this.tabContainersDirection}`;
        },
        mainTabs: {
            get() {
                return this.tabs.filter((el) => {
                    return !el.isSideTab;
                });
            },
            set(value) {
                this.setOrderOfTabs(value);
            },
        },
        sideTabs() {
            return this.tabs.filter((el) => {
                return el.isSideTab;
            });
        },
    },
    watch: {
        sideTabs(newValue) {
            if (newValue.length < 1) this.tabContainersDirection = this.COL;
        },
    },
    methods: {
        ...mapMutations("tabs", ["setOrderOfTabs"]),
        emitEventInTabs() {
            this.tabs.forEach((tab) => {
                emitter.emit(`adjust-tab-${tab.tabName}`);
            });
        },
        hideLayoutSelector() {
            this.toggleLayoutSelector = false;
        },
        showTab(id) {
            this.$store.dispatch("tabs/showTab", { id: id });
            const component = this.$refs[id][0];
            emitter.emit(`adjust-tab-${component.$options.tabName}`);
        },
        setSideTabs(count, tabContainersDirection) {
            this.tabContainersDirection = tabContainersDirection;
            this.$store.dispatch("tabs/setSideTabs", count);
            this.emitEventInTabs();
        },
        closeTab(id) {
            const component = this.$refs[id][0];
            if (component.hasUnsavedData) {
                openDialog({
                    type: "warning",
                    description: "Du har inte sparat. Vill du stänga utan att spara?",
                    buttons: [
                        {
                            title: "Avbryt",
                            type: "secondary",
                        },
                        {
                            title: "Ja, stäng utan att spara",
                            type: "primary",
                            action: () => this.$store.dispatch("tabs/closeTab", id),
                        },
                    ],
                });
            } else {
                this.$store.dispatch("tabs/closeTab", id);
            }

            this.$store.commit("journalloggar/reset");
            this.emitEventInTabs();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

$tabs-height: 72px;

#tab-container {
    height: calc(100vh - $header-height);
    position: relative;
    box-shadow: 2px 2px 9px #d3d8da85 inset;
    background-color: #f0f3f8;

    .layout-selector {
        position: absolute;
        z-index: 60;
        top: 25px;
        right: 17px;

        button {
            border: none;
            outline: none;
            background-color: inherit;
            
            &:disabled {
                opacity: 40%;
            }
            
            img {
                margin-left: 48px;
                margin-right: 13px;
            }
        }
        
        .menu {
            box-sizing: border-box;
            position: absolute;
            right: 10px;
            top: 60px;
            z-index: 61;
            padding: 24px;
            width: 313px;
            background-color: #ffffff;
            border: none;
            border-radius: 4px;
            box-shadow: 0 2px 9px 0 rgba(174, 174, 174, 0.5);
            
            button {
                height: 55px;
                width: 268px;
                text-align: left;
                &.selected {
                    background: linear-gradient(to right, #ca346f 0px, #ca346f 5px, #f0f3f8 5px, #f0f3f8 100%);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: left;
                }
            }
        }
    }

    .tab-containers {
        display: grid;
        height: 100%;

        &.tab-containers-1 {
            grid-template-rows: minmax(0, $tabs-height) minmax(0, 1fr);
            grid-template-columns: minmax(0, 1fr);

            .tabs {
                grid-row: 1 / span 1;
                grid-column: 1 / span 1;
            }

            .section {
                grid-row: 2 / span 1;
                grid-column: 1 / span 1;
            }
        }

        &.tab-containers-2.tab-containers-col {
            grid-template-rows: minmax(0, $tabs-height) minmax(0, 1fr);
            grid-template-columns: minmax(0, 1fr) 10px minmax(0, 1fr);

            .tabs {
                grid-row: 1 / span 1;
                grid-column: 1 / span 1;

                &.side {
                    grid-row: 1 / span 1;
                    grid-column: 3 / span 1;
                }
            }

            section {
                grid-row: 2 / span 1;
                grid-column: 1 / span 1;

                &.side {
                    grid-row: 2 / span 1;
                    grid-column: 3 / span 1;
                }
            }
        }

        &.tab-containers-2.tab-containers-row {
            grid-template-rows: minmax(0, $tabs-height) minmax(0, 1fr) minmax(0, $tabs-height) minmax(0, 1fr);
            grid-template-columns: minmax(0, 1fr);

            .tabs {
                grid-row: 1 / span 1;
                grid-column: 1 / span 1;

                &.side {
                    grid-row: 3 / span 1;
                    grid-column: 1 / span 1;
                }
            }

            section {
                grid-row: 2 / span 1;
                grid-column: 1 / span 1;

                &.side {
                    grid-row: 4 / span 1;
                    grid-column: 1 / span 1;
                }
            }
        }
    }

    .tabs {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;

        box-sizing: border-box;
        height: $tabs-height;
        padding-top: 10px;
        padding-left: 28px;
        padding-right: 28px;

        .tab {
            box-sizing: border-box;
            cursor: pointer;
            color: $tabs-text-color;
            white-space: nowrap;

            & > a {
                font-size: 14px;
                position: relative;
                padding: 22px 17px 0 17px;
                height: 100%;

                display: flex;
                gap: 25px;
                color: inherit;

                background: linear-gradient(to right, #b8c0ca 0px, #b8c0ca 1px);
                background-position-x: 0;
                background-position-y: 0;
                background-repeat: repeat;
                background-size: auto;
                background-size: 1px 32px;
                background-repeat: no-repeat;
                background-position: left;

                & > * {
                    display: block;
                    height: 100%;
                }

                .close {
                    position: relative;
                    font-size: 30px;
                    font-weight: 100;
                    color: inherit;
                }
            }

            &:hover {
                background-color: #f7f9fb;

                a {
                    text-decoration: none;
                }
            }

            &.active {
                background-color: #ffffff;

                .name {
                    border-bottom: 4px solid $color-choosen;
                }

                a {
                    background: none;
                }
            }

            &.active + .tab {
                a {
                    background: none;
                }
            }

            &:last-of-type:not(.active):after {
                content: "";
                position: relative;
                float: right;
                margin-top: -45px;
                height: 32px;
                width: 1px;
                background-color: #b8c0ca;
            }
        }
    }

    section {
        overflow: auto;
        padding: 46px 46px 0 46px;
        border-top: 2px solid #dbe3e3;
        background: white;
    }

    .spinner-overlay {
        z-index: 1;
        pointer-events: none;
        height: calc(100vh - 159px);
        overflow: hidden;
        position: relative;
        opacity: 0.5;

        .spinner-wrapper {
            left: 50%;
            top: 50%;
            position: absolute;
            transform: translate(-50%, -50%);

            .spinner {
                animation: spin 4s linear infinite;
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
}

@media print {
    #tab-container {
        height: auto;
        position: static;
        box-shadow: none;
        background-color: transparent;

        .layout-selector,
        .tabs {
            display: none;
        }

        .tab-containers {
            display: block;
        }

        section {
            padding: 0;
            border-top: none;
            overflow: visible;
        }
    }
}
</style>
