<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :class="{ 'selected-row': selectedItem === item, 'table-row': true }"
        >
            <td v-if="showCheckboxes">
                <InputCheckbox
                    v-if="!hideCheckbox(item, hideCheckboxProperty, hideCheckboxValue)"
                    :checked="checkedIds.includes(item.id)"
                    @change="checkId(item.id, $event.target.checked)"
                />
            </td>
            <td v-for="(column, colIndex) in columns" :key="colIndex" @click="tableRowClicked(item)">
                <div :class="{ commonStyle: hasBackground(column) }" :style="{ background: column.background }">
                    <img v-if="column.type === 'icon'" :src="getIcon(item, column)" />
                    <ul v-if="column.type === 'list'">
                        <li v-for="(i, index) in getContent(item, column.name)" :key="index">
                            <span>{{ getContent(i, column.listName) }}</span>
                        </li>
                    </ul>
                    <ul v-else-if="column.type === 'time'">
                        <span>{{ getTime(getContent(item, column.name)) }}</span>
                    </ul>
                    <template v-else-if="column.type === 'boolean'">{{
                        getContent(item, column.name) ? "Ja" : "Nej"
                    }}</template>
                    <template v-else-if="column.type == 'date'">
                        <span>{{ getDate(getContent(item, column.name)) }}</span>
                    </template>
                    <ul v-else-if="column.type === 'datetime'">
                        <span>{{ getDateTime(getContent(item, column.name)) }}</span>
                    </ul>
                    <template v-else-if="column.type === 'adress'">
                        <span>{{ getAdress(item) }}</span>
                    </template>
                    <template v-else-if="column.type === 'mall'">
                        <span class="showmulti-rows">{{ splitStringto2Rows(item, column.name) }}</span>
                    </template>
                    <template v-else>
                        <span>{{ getContent(item, column.name) }}</span>
                    </template>
                </div>
            </td>
            <td v-if="hasDropdown" @click="tableRowClicked(item)">
                <kliniken-drop-down-menu :dropdownActions="dropdownActions" :item="item" @action="dropdownaction" />
            </td>
        </tr>
    </tbody>
</template>

<script>
    import KlinikenDropDownMenu from "./KlinikenDropDownMenu.vue";
    import { get } from "lodash";
    import InputCheckbox from "@/components/InputCheckbox.vue";
    import moment from "moment";

    export default {
        name: "KlinikenTableBody",
        components: { InputCheckbox, KlinikenDropDownMenu },
        props: {
            columns: {
                type: Array,
                required: true,
            },
            items: {
                type: Array,
                required: true,
            },
            hasDropdown: {
                type: Boolean,
                default: false,
            },
            dropdownActions: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            selectedItem: {
                type: Object,
                required: false,
            },
            showCheckboxes: {
                type: Boolean,
                required: false,
                default: false,
            },
            hideCheckboxProperty: {
                type: String,
                required: false,
            },
            hideCheckboxValue: {
                type: String,
                required: false,
            },
            checkedIds: {
                type: Array,
                default: () => {
                    return [];
                },
            },
        },

        methods: {
            hasBackground(column) {
                return column.background ? true : false;
            },
            getContent(item, key) {
                return get(item, key, "");
            },
            getTime(value) {
                return typeof value === "string" ? value.substring(0, 5) : "";
            },
            getDate(value) {
                return typeof value === "string" ? value.substring(0, 10) : "";
            },
            getDateTime(value) {
                return typeof value === "string" ? moment(value).format("YYYY-MM-DD HH:mm") : "";
            },
            splitStringto2Rows(str, name) {
                str = get(str, name);
                if (str?.length > 20) {
                    const lastSpaceIndex = str.lastIndexOf(" ", 20);
                    if (lastSpaceIndex !== -1) {
                        const firstPart = str.slice(0, lastSpaceIndex);
                        const secondPart = str.slice(lastSpaceIndex + 1);
                        const result = `${firstPart} \n ${secondPart}`;
                        return result;
                    }
                }

                return str;
            },
            getAdress(option = {}) {
                const adressRad1 = option?.adressRad1 ?? "";
                const adressRad2 = option?.adressRad2 ?? "";

                let adress = "";

                if (adressRad1.length > 0 && adressRad2.length > 0) {
                    adress = adressRad1;
                } else if (adressRad1.length === 0 && adressRad2.length !== 0) {
                    adress = adressRad2;
                } else if (adressRad1.length > 0 && adressRad2.length === 0) {
                    adress = option.adressRad1;
                }

                return adress;
            },
            getIcon(item, column) {
                const value = get(item, column.name, "");
                const icon = get(column.icons, value, "");

                return icon;
            },
            dropdownaction({ action, item }) {
                this.$emit("dropdownaction", { action, item });
            },
            tableRowClicked(item) {
                this.$emit("tableRowClicked", item);
            },
            checkId(id, value) {
                this.$emit("idChecked", { id, value });
            },
            hideCheckbox(item, property, value) {
                return item[property] === value;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/style/_variables.scss";

    tr {
        text-align: left;
        position: relative;
        border-bottom: 0.5px solid $list-border-color;

        td {
            white-space: nowrap;
            line-height: normal;
            vertical-align: top;
            padding-top: 6px;
            padding-right: 10px;
            padding-bottom: 3px;
            padding-left: 8px;

            div {
                padding: 5px;
                top: 10px;
                white-space: nowrap;
                margin: 0;

                img {
                    margin-right: 8px;
                    margin-bottom: -2px;
                }
                .showmulti-rows {
                    white-space: pre-wrap;
                }
            }

            ul {
                margin-block-start: 0;
                margin-block-end: 0;
                margin-inline-start: 0;
                padding-inline-start: 0;

                li {
                    list-style: none;
                }
            }
        }

        span {
            font-size: 14px;
            line-height: 16px;
            color: $color-text-black;
            font-family: Roboto, sans-serif;
        }
    }

    .selected-row {
        border-left: 4px solid #277692;
        background-color: #ebf6f8;

        td {
            div {
                background: transparent !important;
            }
        }
    }

    .table-row {
        cursor: pointer;
    }
</style>
