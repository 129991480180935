export class OperationSanitizer {
    id = "";
    title = "";
    constructor({ id = NaN, namn = "" }) {
        this.id = id;
        this.title = namn;

        this._toJSON();
    }

    // private

    _toJSON() {
        const { id, title } = this;
        return {
            id,
            title,
        };
    }
}
