<template>
    <div class="makulera-altertinformation">
        <div class="top-text">
            <p>Observera att makulering enbart bör användas för felaktigt dokumenterade uppmärksamhetssignaler.</p>
            <p>Efter att du makulerat ett uppmärksamhetssignalen kommer den ej längre att synas i listan.</p>
        </div>
        <div class="text-area-div">
            <input-wrapper
                :component="widgets.TextAreaWidget"
                name="nullifiedReason"
                v-model="nullifiedReason"
                label="Ange orsak till makuleringen"
            />
        </div>
        <ButtonGroup align="center">
            <Button variant="alert" size="secondary" @click="setAsMakulerad">Makulera</Button>
            <Button variant="contour" size="secondary" @click="cancel">Avbryt</Button>
        </ButtonGroup>
    </div>
</template>

<script>
import dialogEvents from "@/dialogEvents";
import { flashMessage, openDialog } from "@/utils";
import { getErrorMessage } from "@/api";
import { mapActions, mapGetters } from "vuex";
import widgets from "@/components/widgets/inputs";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

export default {
    name: "MakuleraAlertInformation",
    props: ["attachedProps"],
    components: {
        ButtonGroup,
        InputWrapper,
        Button,
    },
    data() {
        return {
            nullifiedReason: "",
            widgets,
        };
    },
    computed: {
        ...mapGetters("patientData", ["patient"]),
        ...mapGetters("userData", ["currentProfile"]),
    },
    methods: {
        ...mapActions("alertInformation", ["makuleraAlertInformation"]),
        cancel() {
            dialogEvents.$emit("closePopup");
        },
        async setAsMakulerad() {
            try {
                const data = {
                    patient: this.patient ? this.patient.id : null,
                    pk: this.attachedProps.pk,
                    type: this.attachedProps.type,
                    reason: this.nullifiedReason,
                };
                const response = await this.makuleraAlertInformation(data);
                if (response.status === 200) {
                    await this.reloadStoreAfterUpdates();
                    flashMessage("Makulering av uppmärksamhetssignal utförd");
                    dialogEvents.$emit("closePopup");
                }
            } catch (e) {
                openDialog(getErrorMessage(e), "error");
            }
        },
        async reloadStoreAfterUpdates() {
            await this.$store.dispatch("alertInformation/loadAlertInformationList", { patientId: this.patient.id });
            await this.$store.dispatch("alertInformation/loadAlertInformationSymbol", { patientId: this.patient.id });
        },
    },
};
</script>

<style>
.makulera-altertinformation {
    padding: 23px 104px;

    .top-text {
        width: 576px;
    }

    .text-area-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 31px;
    }
}
</style>
