<template>
    <div class="FilhanteringForhandsvisningModal">
        <div class="modalClose" @click="modalDolj"></div>
        <div class="outerModalContainer">
            <div class="modalContainer">
                <FilhanteringForhandsvisningContainer
                    v-bind:file="file"
                    v-bind:url="url"
                    v-bind:metaData="metaData"
                    inlineOrModal="modal"
                >
                </FilhanteringForhandsvisningContainer>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import FilhanteringForhandsvisningContainer from "./FilhanteringForhandsvisningContainer.vue";

export default {
    name: "FilhanteringForhandsvisningModal",

    props: ["file", "url", "metaData"],

    methods: {
        ...mapMutations("filhantering", ["modalVisa", "modalDolj"]),
    },

    components: {
        FilhanteringForhandsvisningContainer,
    },
};
</script>

<style lang="scss" scoped>
div.FilhanteringForhandsvisningModal {
    background-color: white;
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    z-index: 60;

    div.outerModalContainer {
        display: absolute;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;

        overflow-y: scroll;

        div.modalContainer {
            width: 80vw;
            height: 200vh; // TODO Calculate this height in js
            margin-top: 5rem;
        }
    }

    div.modalClose {
        background-image: url("@/assets/close.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
}
</style>
