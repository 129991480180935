<!--
     It is important that the prop src is really available when this renders
     therefore you should v-if the rendering of this components in it's parent
-->
<template>
    <div class="FilhanteringForhandsvisningPdf" v-if="src">
        <vue-pdf-embed :source="src" />
    </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
    name: "FilhanteringForhandsvisningPdf",
    props: ["src"],
    components: {
        VuePdfEmbed,
    },
};
</script>

<style lang="scss" scoped>
div.FilhanteringForhandsvisningPdf {
    // height: 20rem;
    overflow-y: scroll;
}
</style>
