<template>
    <div class="netid-form">
        <h3>Kontrollera att ditt SITHS-kort sitter i kortläsaren</h3>
        <ButtonGroup align="center">
            <Button type="button" @click="submit" size="secondary">{{ submitButton }}</Button>
            <Button type="button" @click="cancel" size="secondary" variant="contour">Avbryt</Button>
        </ButtonGroup>
    </div>
</template>

<script setup>
    import { toRefs } from "vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import Button from "@/components/Button.vue";

    const props = defineProps({
        submitButton: {
            type: String,
            default: "Logga in",
        },
    });

    const { submitButton } = toRefs(props);

    const emit = defineEmits(["error", "cancel"]);

    const submit = async () => {
        emit("submit");
    };

    const cancel = () => {
        emit("cancel");
    };
</script>

<style lang="scss">
    .netid-form {
        h3 {
            color: #354052;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 28px;
            text-align: center;
        }
    }
</style>
