<template>
    <section>
        <div class="product-selection">
            <div class="active">
                <action-table
                    v-for="group in groupedResults"
                    :labels="groupLabel(group)"
                    :key="group[0].drugId"
                    :columns="drugTableColumns"
                    :items="group"
                    :disabled-row-handler="disabledRowHandler"
                    :id="'group-' + group[0].drugId"
                    :collapse-label="group[0].distributedTradeName + ' (' + group.length + ')'"
                    selectable-rows
                    class="group"
                />
                <!-- :sort-method="(key, reversed) => groupSort(group, key, reversed)" // disabled in prescriptionSet -->
                <!-- :selected-item="selected" // removing - this table can never be shown while selected is not null-->
            </div>
        </div>
    </section>
</template>
<script>
import ActionTable from "@/components/generic/ActionTable.vue";

const SIL_APPROVED = [
    "MEP", // läkemedel
    "VBL", // växtbaserade läkemedel
    "ALL", // allergen
    "NLM", // naturläkemedel
    "VUM", // vissa utvärtes läkemedel
    "RAP", // radiofarmaka
];

export default {
    components: { ActionTable },
    props: {
        groupedResults: Array,
        disabledRowHandler: Function, // a function passed by parent to determine which, if any, rows in a table that should not be selectable
        drugTableColumns: Array,
        actionTableSelectHandler: Function,
    },
    methods: {
        groupLabel(group) {
            let labels = [];
            if (group.filter((el) => el.isRecommended).length > 0) labels.push("REK");
            if (group.filter((el) => !SIL_APPROVED.includes(el.productTypeCode)).length > 0) labels.push("IGL");
            return labels;
        },
    },
    mounted() {
        this.$on("actionTable::select", (item) => {
            this.actionTableSelectHandler(item);
        });
    },
};
</script>
