/**
 * Small mixin primarily used by SuggestWidget, SelectWidget and PreviewWidget, as these components often do
 * an initial lookup of an kodverks-id or user-id to get a displayName.
 */

import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

const isInteger = /^[0-9]+$/;
const uuid = /^[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
const config = getConfig();

export const fetchMixin = {
    methods: {
        initializeSuggest(value) {
            if (!value) return;
            /**
             * Initialize values.
             * Case 0: value is a number in String format -> convert to Int
             */
            if (isInteger.test(value)) value = parseInt(value);

            /**
             * Case: 1 value is a number in Int format
             */
            if (Number.isInteger(value)) {
                // Value is already stored in cache
                if (this.itemCache[value]) {
                    this.setValues(this.itemCache[value]);
                    this.emitValue(true);
                    return;
                }
                klinikenApi
                    .get(this.API_SEARCH + value + "/", config)
                    .then((response) => {
                        value = response.data;
                        value = this.map(value);
                        this.itemCache[value.id] = value;
                        this.setValues(this.itemCache[value.id]);
                        this.emitValue(true);
                    })
                    .catch((e) => {
                        alert(e);
                    });
            } else if (typeof value === "string" && value !== "") {
                /**
                 * Case 2: value is String
                 */
                if (this.displayCode)
                    // value is a string with both code and text, used in Note.vue
                    value = value.split(" ")[0];

                // Value (as code) is already stored in cache
                for (const [cacheKey, cacheValue] of Object.entries(this.itemCache)) {
                    if (cacheValue.code === value || cacheValue.id == value || cacheValue.value === value) {
                        this.setValues(this.itemCache[cacheKey]);
                        // Send back normalized id
                        this.emitValue();
                        return;
                    }
                }

                (uuid.test(value)
                    ? klinikenApi.get(this.API_SEARCH + value + "/", getConfig())
                    : klinikenApi.get(this.API_SEARCH, getConfig({ params: { q: value } }))
                )
                    .then((response) => {
                        if (response.data.results) value = response.data.results[0];
                        else value = response.data;
                        if (!value) {
                            this.setValues();
                            this.emitValue();
                            return;
                        }

                        value = this.map(value);
                        this.itemCache[value.id] = value;
                        this.setValues(value);
                        // Send back normalized id
                        this.emitValue();
                    })
                    .catch((e) => {
                        openDialog(getErrorMessage(e), "error");
                    });
            } else if (typeof value === "object" && value !== null) {
                /**
                 * Case 3: value in a Object
                 */
                // value is in format matching database (such as when intially read)
                value = this.map(value);
                this.itemCache[value.id] = value;
                this.setValues(value);
                // Send back normalized id
                this.emitValue();
            }
        },
        normalizeValue(value) {
            if (typeof value === "object" && value !== null) {
                this.value_ = this.map(value).value;
                // update normalized value
                this.$emit("input", this.value_);
                this.$emit("update", this.map(value));
            } else this.value_ = value;
        },
    },
};
