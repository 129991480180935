<template>
    <div class="polling-frame">
        <h3>{{ msg }}</h3>
        <img v-if="typ === 'netid'" class="spin" src="@/assets/loader.svg" data-testid="spin-image" />
        <div v-else class="qrcode">
            <QRCode
                v-show="code === 'outstandingTransaction'"
                class="qrcode"
                size="200"
                :QRCodeBase64="BankIDQRCodeBase64"
            ></QRCode>
            <img
                v-show="code !== 'outstandingTransaction'"
                class="bankidImg"
                src="@/assets/mobilt_bankid_app.svg"
                alt="bank_id"
            />
        </div>

        <Button type="button" size="slim" variant="contour" @click="cancel">Avbryt</Button>
    </div>
</template>

<script setup>
    import { ref, toRefs } from "vue";
    import { getConfig, klinikenApi } from "@/api";
    import Button from "@/components/Button.vue";
    import QRCode from "@/components/widgets/QRCode.vue";

    const props = defineProps({
        message: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    });

    const { message, url, data, type } = toRefs(props);

    const msg = ref(message.value);
    const code = ref(data.value.code);
    const typ = ref(type.value);

    const emit = defineEmits(["cancel", "success", "error"]);

    const BankIDQRCodeBase64 = ref(data.value["QRCodeBase64"]);
    const autoStartToken = ref(data.value["autoStartToken"]);

    const cancel = () => {
        clearInterval(polling);
        emit("cancel");
    };

    let attempts = 0;
    const maxWait = 60 * 1000; // 1 minute
    const interval = 2 * 1000; // 2 seconds
    const maxAttempts = maxWait / interval;

    const polling = setInterval(async () => {
        try {
            const response = await klinikenApi.post(url.value, { ...data.value, QRCodeBase64: undefined }, getConfig());

            if (response.status === 200) {
                clearInterval(polling);
                if (response.data.code === "userCancel") {
                    emit("error", response.data.message);
                } else {
                    emit("success", response.data);
                }
            } else if (response.status === 202) {
                code.value = response.data.code;
                if (code.value === "startFailed") {
                    clearInterval(polling);
                    emit("error", "Det tog för lång tid att logga in.");
                }
                if (code.value === "userCancel") {
                    clearInterval(polling);
                    emit("error", response.data.message);
                }
                BankIDQRCodeBase64.value = response.data["QRCodeBase64"];
                msg.value = response.data.message;
            } else {
                clearInterval(polling);
                emit("error", response.data.message ?? "Något gick fel");
            }
        } catch (error) {
            clearInterval(polling);
            emit("error", error.message ?? "Något gick fel");
        }

        attempts++;
        if (attempts >= maxAttempts) {
            clearInterval(polling);
            emit("error", "Det tog för lång tid att logga in.");
        }
    }, interval);
</script>

<style lang="scss">
    @import "@/style/variables";

    .polling-frame {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        h3 {
            text-align: center;
            color: $color-text-black;
        }

        .spin {
            animation: spin 4s infinite linear;
        }

        .bankidImg {
            width: 200px;
            height: 200px;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
</style>
