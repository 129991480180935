export class Operation {
    #forberedelsetid;
    #avvecklingstid;
    #optid;
    #patient;

    constructor(forberedelsetid, avvecklingstid, optid, patient) {
        this.#forberedelsetid = forberedelsetid;
        this.#avvecklingstid = avvecklingstid;
        this.#optid = optid;
        this.#patient = patient;
    }

    get patient() {
        return this.#patient;
    }

    get forberedelsetid() {
        return this.#forberedelsetid;
    }

    get avvecklingstid() {
        return this.#avvecklingstid;
    }

    get optid() {
        return this.#optid;
    }

    set patient(value) {
        this.#patient = value;
    }

    set forberedelsetid(value) {
        this.#forberedelsetid = value;
    }

    set avvecklingstid(value) {
        this.#avvecklingstid = value;
    }

    set optid(value) {
        this.#optid = value;
    }
}
