<template>
    <div id="login-frame">
        <div class="header">
            <template v-if="nextStep === 'basic'">
                <sign-header title="Logga in" :kundNamn="kundNamn" />
            </template>

            <template v-else-if="nextStep === 'bankid' || (nextStep === 'polling' && pollType === 'bankid')">
                <sign-header
                    :image="require('@/assets/bankid.svg')"
                    title="Logga in med Mobilt BankID"
                    :kundNamn="kundNamn"
                />
            </template>

            <template v-else-if="nextStep === 'netid' || (nextStep === 'polling' && pollType === 'netid')">
                <sign-header
                    :image="require('@/assets/siths.svg')"
                    title="Logga in med SITHS-kort"
                    :kundNamn="kundNamn"
                />
            </template>

            <template v-else>
                <h2>{{ title }}</h2>
                <h3>{{ kundNamn }}</h3>
            </template>

            <h3 v-if="currentUser.name">Du är inloggad som {{ currentUser.name }}</h3>
        </div>

        <LoginButtonGroup v-if="nextStep === ''">
            <LoginButton :img="require('@/assets/password.svg')" @click="loginWithBasic" v-if="basicEnabled">
                Logga in med användarnamn och lösenord
            </LoginButton>
            <LoginButton :img="require('@/assets/bankid.svg')" @click="loginWithBankID" v-if="bankIDEnabled">
                Logga in med mobilt BankID
            </LoginButton>
            <LoginButton :img="require('@/assets/siths.svg')" @click="loginWithNetID" v-if="netIDEnabled">
                Logga in med SITHS-kort
            </LoginButton>
        </LoginButtonGroup>

        <BasicLogin v-if="nextStep === 'basic'" @success="handleLogin" @error="handleError" @cancel="resetLogin" />

        <BankIDLogin v-if="nextStep === 'bankid'" @poll="handlePolling" @error="handleError" @cancel="resetLogin" />

        <NetIDLogin v-if="nextStep === 'netid'" @poll="handlePolling" @cancel="resetLogin" />

        <PollingFrame
            v-if="nextStep === 'polling'"
            :message="pollMessage"
            :url="pollUrl"
            :data="pollData"
            :type="pollType"
            @success="handleLogin"
            @error="handleError"
            @cancel="resetLogin"
        />

        <SelectProfile v-if="nextStep === 'selectProfile'" @select="setCurrentProfile" />

        <ResultFrame
            v-if="nextStep === 'result'"
            :success="result"
            :message="resultMessage"
            @cancel="resetLogin"
            @retry="retryLogin"
        />
    </div>
</template>

<script setup>
    import { computed, ref } from "vue";
    import { useStore } from "@/store";
    import {
        getAuthenticationMethod,
        getConfig,
        klinikenApi,
        refreshJWT,
        setAuthenticationMethod,
        setJWT,
        setRefreshToken,
    } from "@/api";
    import LoginButtonGroup from "@/components/Auth/LoginButtonGroup.vue";
    import LoginButton from "@/components/Auth/LoginButton.vue";
    import BasicLogin from "@/components/Auth/BasicLogin.vue";
    import BankIDLogin from "@/components/Auth/BankIDLogin.vue";
    import NetIDLogin from "@/components/Auth/NetIDLogin.vue";
    import PollingFrame from "@/components/Auth/PollingFrame.vue";
    import SelectProfile from "@/components/Auth/SelectProfile.vue";
    import ResultFrame from "@/components/Auth/ResultFrame.vue";
    import SignHeader from "@/components/Auth/SignHeader.vue";
    import { flashMessage, openDialog } from "@/utils";
    import isEmpty from "lodash/isEmpty";

    const store = useStore();

    const previousStep = ref("");
    const nextStep = ref("");
    const authenticationMethod = ref("");
    const pollMessage = ref("");
    const pollUrl = ref("");
    const pollData = ref({});
    const pollType = ref("");
    const result = ref(false);
    const resultMessage = ref("");

    const title = computed(() => {
        if (nextStep.value === "selectProfile") {
            return "Välj din inloggningsprofil";
        } else {
            return "Logga in";
        }
    });

    const kundNamn = computed(() => {
        return store.state.systemData.kundNamn;
    });

    const currentUser = computed(() => {
        return store.state.userData.userData;
    });

    const basicEnabled = computed(() => {
        return store.state.systemData.authenticationMethods.includes("Basic");
    });

    const bankIDEnabled = computed(() => {
        return store.state.systemData.authenticationMethods.includes("BankID");
    });

    const netIDEnabled = computed(() => {
        return store.state.systemData.authenticationMethods.includes("NetID");
    });

    function resetLogin() {
        nextStep.value = "";
        result.value = false;
        resultMessage.value = "";
        authenticationMethod.value = "";
    }

    function retryLogin() {
        nextStep.value = previousStep.value;
    }

    function loginWithBasic() {
        authenticationMethod.value = "Basic";
        beginLogin("basic");
    }

    function loginWithBankID() {
        authenticationMethod.value = "BankID";
        beginLogin("bankid");
    }

    function loginWithNetID() {
        authenticationMethod.value = "NetID";
        beginLogin("netid");
    }

    function beginLogin(type) {
        nextStep.value = type;
    }

    function updatePreviousStepForPolling() {
        if (previousStep.value === "polling") {
            if (authenticationMethod.value === "BankID") {
                previousStep.value = "bankid";
            } else if (authenticationMethod.value === "NetID") {
                previousStep.value = "netid";
            }
        }
    }

    function handleError(message) {
        result.value = false;
        resultMessage.value = message;
        previousStep.value = nextStep.value;
        updatePreviousStepForPolling();
        nextStep.value = "result";
    }

    function handlePolling({ message, url, data, type }) {
        pollMessage.value = message;
        pollUrl.value = url;
        pollData.value = data;
        pollType.value = type;
        nextStep.value = "polling";
    }

    async function handleLogin(data) {
        setAuthenticationMethod(authenticationMethod.value);
        setRefreshToken(data.refresh);
        setJWT(data.access);

        await store.dispatch("systemData/loadFromBackend");

        /* Pga timing issues med att hämta systemData från backend så kan systemData.fallbackSignMethod vara tomt
när inhoppet är inloggat. Därför sätts authenticationMethod efter att systemData är hämtat efter
inloggningen om det är tomt.
*/
        if (getAuthenticationMethod() === null) {
            setAuthenticationMethod(store.state.systemData.fallbackSignMethod);
        }

        try {
            const response = await klinikenApi.get("core/users/current/", getConfig());

            store.commit("userData/setUserData", response.data);
            store.commit("userData/setProfiles", response.data.profiles);
        } catch (error) {
            handleError("Det gick inte att logga in.");
            nextStep.value = "";
            return;
        }

        const activeProfiles = store.getters["userData/activeProfiles"];
        if (activeProfiles.length === 1) {
            setCurrentProfile(activeProfiles[0]);
        } else {
            nextStep.value = "selectProfile";
        }
    }

    function setCurrentProfile(profile) {
        result.value = true;
        resultMessage.value = "Du är nu inloggad.";
        nextStep.value = "result";

        // måste sättas innan timeout, pga beroende i store i getter can
        store.dispatch("ability/loadRules", profile.permissions);

        setTimeout(() => {
            store.commit("userData/setCurrentProfileId", profile.pk);
            resetLogin();
        }, 1500);
    }

    // Check if the user was redirected from an external login
    async function checkExternalLogin() {
        const params = new URLSearchParams(window.location.search);
        const jwt = params.get("jwt");

        if (jwt !== null) {
            flashMessage("Loggar in...");

            params.delete("jwt");
            const urlWithoutJwt = `${window.location.pathname}?${params.toString()}`;
            window.history.replaceState({}, document.title, urlWithoutJwt);

            try {
                let response = await klinikenApi.post("/auth/external/verify/", { token: jwt });
                if (response.status === 200) {
                    if (!isEmpty(response.data)) {
                        authenticationMethod.value = store.state.systemData.fallbackSignMethod;
                        handleLogin(response.data);
                    }
                } else {
                    openDialog("Autentiseringsfel. Du kunde inte loggas in med de angivna uppgifterna.", "error");
                }
            } catch (error) {
                if (error?.response.status === 401) {
                    openDialog("Autentiseringsfel. Du kunde inte loggas in med de angivna uppgifterna.", "error");
                } else {
                    openDialog("Det gick inte att logga in.", "error");
                }
            }
        }
    }

    async function refreshSession() {
        if (process.env.NODE_ENV === "development") {
            let refresh = sessionStorage.getItem("Refresh");
            let method = sessionStorage.getItem("AuthenticationMethod");
            let profileId = sessionStorage.getItem("ProfileId");
            if (refresh && method && profileId) {
                setRefreshToken(refresh);
                await refreshJWT();
                setAuthenticationMethod(method);
                const response = await klinikenApi.get("core/users/current/", getConfig());
                await store.commit("userData/setUserData", response.data);
                await store.commit("userData/setProfiles", response.data.profiles);
                await store.commit("userData/setCurrentProfileId", profileId);

                const profile = await store.getters["userData/currentProfile"];
                await store.dispatch("ability/loadRules", profile.permissions);

                resetLogin();
            }
        }
    }

    refreshSession();

    checkExternalLogin();
</script>

<style lang="scss">
    @import "@/style/variables";

    #login-frame {
        background-color: white;
        width: 950px;
        max-width: 100%;
        min-height: 390px;
        max-height: 750px;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 30px;

        padding: 45px 120px 65px;

        .header {
            text-align: center;
            color: $color-active-dark;

            h2 {
                font-size: 38px;
                line-height: 42px;
                text-align: center;
            }

            h3 {
                font-size: 16px;
                letter-spacing: 0;
                line-height: 21px;
            }
        }

        .center {
            display: flex;
            justify-content: center;
            column-gap: 24px;
            margin-top: 32px;
        }
    }

    @media screen and (max-width: 850px) {
        #login-frame {
            padding-left: 60px;
            padding-right: 60px;
        }
    }

    @media screen and (max-width: 680px) {
        #login-frame {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
</style>
