import Vue from "vue";
import App from "@/App.vue";
import store from "@/store";
import VCalendar from "v-calendar";
import VueFormulate from "@braid/vue-formulate";
import { sv } from "@braid/vue-formulate-i18n";
import FormulateVSelectPlugin from "@cone2875/vue-formulate-select";
import Autocomplete from "@trevoreyre/autocomplete-vue";

import {
    FormulateAutocompletePlugin,
    FormulateDatePickerPlugin,
    FormulateNowPlugin,
    FormulateNumberPlugin,
    FormulateTimePickerPlugin,
    FormulateToggleSwitchPlugin,
    FormulateVueSelectPlugin,
    FormulateFrasregisterPlugin,
} from "./vueformulate.js";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import { KlinikenToolTip } from "@/utils/tooltip";

new Vue({
    store,
    render: (h) => h(App),
}).$mount("#app");
Vue.use(VCalendar, {
    componentPrefix: "vc", // ...other defaults
});
Vue.use(FloatingVue, {
    themes: {
        "kliniken-tooltip": KlinikenToolTip["kliniken-tooltip"],
    },
});

Vue.use(VueFormulate, {
    plugins: [
        sv,
        FormulateVSelectPlugin,
        FormulateAutocompletePlugin,
        FormulateNumberPlugin,
        FormulateVueSelectPlugin,
        FormulateDatePickerPlugin,
        FormulateNowPlugin,
        FormulateToggleSwitchPlugin,
        FormulateTimePickerPlugin,
        FormulateFrasregisterPlugin,
    ],
    locale: "sv",
});
Vue.use(Autocomplete);
