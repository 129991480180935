<template>
    <div>
        <h2>Visa intyg</h2>
        <button role="button" @click="openIntygTab()" class="hollow-button">
            <img src="@/assets/plus_circle_blue.svg" />Skapa intyg
        </button>
        <div class="visaMakulerade">
            <label class="checkbox-container checkbox-container-samtycke" style="margin-right: 30px; padding-top: 5px"
                >Visa makulerade och raderade
                <input class="mr-1 checkbox-samtycke" type="checkbox" @click="displayAll = !displayAll" />
                <span class="checkmark checkmark-samtycke" />
            </label>
        </div>
        <custom-action-table
            class="no-top k3-intyg-lista"
            :selectableRows="false"
            :columns="displayAll ? intygTableColumnsWithStatus : intygTableColumns"
            :items="!displayAll ? intyg.filter((item) => !item.is_nullified && !item.is_deleted) : intyg"
            :actions="intygListActions"
        ></custom-action-table>
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import CustomActionTable from "@/components/generic/CustomActionTable.vue";
import widgets from "@/components/widgets/inputs";
import { mapGetters } from "vuex";
import tabEvents from "@/tabEvents";
import tabIcon from "@/assets/operationsanmalan.svg";
import Intyg from "@/tabs/Intyg/Intyg.vue";
import { getAccessToken } from "@/api";

const noNull = (str) => {
    return str || "";
};

const INTYG_TABLE_COLUMNS = [
    {
        name: "type",
        title: "Intygstyp",
        template: (item) => {
            return `<span style="font-size: 14px;">` + item.typ.displayName + " (" + item.typ.code + ")" + `</span>`;
        },
    },
    {
        name: "legalAuthenticator",
        title: "Ansvarig vårdpersonal",
        template: (item) => {
            if (item.accountableHealthcareProfessional) {
                return noNull(item.accountableHealthcareProfessional.user.name);
            } else {
                return "";
            }
        },
    },
    {
        name: "updated_at",
        title: "Senast uppdaterad",
        template: (item) => {
            let lastUpdatedFormatted = item.latest_handelse
                ? new Date(item.latest_handelse.tidpunkt).toLocaleString("sv-SE").slice(0, -3)
                : "";
            return noNull(lastUpdatedFormatted);
        },
    },
    {
        name: "handelsestatus",
        title: "Händelsestatus",
        template: (item) => {
            let displayName = "";
            if (item && item.latest_handelse && item.latest_handelse.handelsekod) {
                displayName = item.latest_handelse.handelsekod.displayName || "";
            }
            return displayName;
        },
    },
    {
        name: "created_at",
        title: "Skapad",
        template: (item) => {
            return new Date(item.created_at).toLocaleString("sv-SE").slice(0, -3);
        },
    },
];

const STATUSES = {
    name: "status",
    title: "Status",
    template: (item) => {
        let codeString = "";
        if (item) {
            if (item.is_nullified) {
                codeString = '<status-widget status="makulerad" />';
            }
            if (item.is_deleted) {
                codeString = '<status-widget status="raderad" />';
            }
        }
        return codeString;
    },
};

const INTYG_ROW_ACTIONS = [
    {
        name: "openIntyg",
        title: "Visa intyg på Webcert",
        condition: (item) => {
            return !item.is_deleted;
        },
    },
];

export default {
    name: "ListaIntyg",
    icon: tabIcon,
    extends: BaseTab,
    patientBound: true,
    components: {
        CustomActionTable,
        ...widgets,
    },
    tabName: "Visa intyg",
    data() {
        return {
            q: "",
            loading: false,
            selected: null,
            searchTimeout: false,
            intygTableColumns: INTYG_TABLE_COLUMNS,
            intygTableColumnsWithStatus: [...INTYG_TABLE_COLUMNS, STATUSES],
            intygListActions: INTYG_ROW_ACTIONS,
            formComponents: null,
            collapsedFilter: false,
            displayAll: false,
        };
    },
    computed: {
        ...mapGetters("userData", ["currentProfile"]),
        ...mapGetters("intyg", ["intyg"]),
    },
    created() {
        tabEvents.$on("refreshIntyg", this.$store.dispatch("intyg/fetchIntyg"));
        this.$on("openIntyg", this.openIntyg);
        // this.$store.dispatch("intyg/fetchIntyg");
    },
    methods: {
        openIntyg(item) {
            window.open(
                window.location.protocol +
                    "//" +
                    window.location.hostname +
                    ":" +
                    window.location.port +
                    "/inhopp/intyg/webcert/" +
                    item.id +
                    "?jwt=" +
                    getAccessToken() +
                    "&orgunit=" +
                    this.currentProfile.orgUnit.id
            );
        },
        openIntygTab() {
            this.$store.dispatch("tabs/openTab", { component: Intyg });
        },
    },
};
</script>
<style scoped>
.checkbox-container-samtycke {
    align-items: end;
    color: #354052;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}

.visaMakulerade {
    text-align: end;
    width: 100%;
    margin-right: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>

<style lang="sass" scoped>
@import "../../style/variables"

/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"
/* Bootstrap - End */

@import "/src/style/deprecated_main"

.action-table.no-top
  th
    border-top: 0px

h2
  //margin-top: 30px
  margin-bottom: 30px
  display: inline-block

.hollow-button
  height: 28px
  color: #6C8188
  font-family: Roboto Medium
  font-size: 16px
  font-weight: 500
  line-height: 28px
  border: none
  background-color: #FFFFFF
  margin-left: 50px

  img
    margin-right: 8px
</style>
<style lang="sass" scoped>
.k3-intyg-lista
  table
    td:first-child
      border-right: 0px !important

    td:nth-of-type(3)
      white-space: nowrap

    td:nth-of-type(5)
      white-space: nowrap

      span
        font-weight: normal !important

    thead
      th
        border-top: 0px
</style>
