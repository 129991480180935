<template>
    <div class="modal-overlay">
        <div class="modal">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalOverlay",
};
</script>

<style lang="scss">
.modal-overlay {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 1030;

    .modal {
        background: #fff;
        box-shadow: 8px 8px 18px rgba(85, 103, 109, 0.441916);
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
