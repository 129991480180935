<template>
    <date-picker
        @change="valueChanged"
        :show-week-number="true"
        :show-second="false"
        :disabled-date="disabledDate"
        :type="type"
        @input-error="showWarning"
        :formatter="customFormat"
        v-model="value_"
        :lang="sv"
        :disabled="disabled"
        :required="required"
        :open="popupOpen"
        @open="popupOpen = true"
        @close="
            $emit('blur');
            popupOpen = false;
        "
        @focus="focusInterceptor"
        :input-attr="{ name: name }"
    >
        <img slot="icon-clear" src="@/assets/cross_circle.svg" />
        <img slot="icon-calendar" src="@/assets/calendar.svg" />
    </date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/scss/index.scss";

const numericDate = new RegExp("^([0-9]{2}){1,2}[- ]?((0[0-9])|(10|11|12))[- ]?(([0-2][0-9])|(3[0-1]))$"); // Matches date in YYMMDD or YYYYMMDD with spaces and dashes.
const naturalDate = new RegExp("^(([0-2]?[0-9])|(3[0-1]))[ ][a-zA-Z]{3,}[ ]([0-9]{2}){1,2}$"); // Matches the format { day: 'numeric', year: 'numeric', month: 'long' } in display
const numericDateTime = new RegExp(
    "^([0-9]{2}){1,2}[-]?((0[0-9])|(10|11|12))[-]?(([0-2][0-9])|(3[0-1]))[ ](([0-9]{2})[:]?){2}$"
); // Matches date in (YY)YYMMDD HHMM(SS) with spaces, dashes and colon.

export default {
    name: "DateWidget",
    props: {
        name: String,
        value: String,
        required: Boolean,
        notBefore: String,
        notAfter: String,
        disabled: Boolean,
        type: {
            // Valid types are "date" or "datetime"
            type: String,
            default: "date",
        },
    },
    data: function () {
        return {
            popupOpen: false,
            value_: this.value ? this.customParse(this.value) : "",
            sv: {
                days: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
                months: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
                pickers: ["nästa 7 dagar", "nästa 30 dagar", "föregående 7 dagar", "föregående 30 dagar"],
                placeholder: {
                    date: "", // 'Välj datum'
                    dateRange: "", // 'Välj datumintervall'
                },
                formatLocale: {
                    firstDayOfWeek: 1,
                },
            },
            customFormat: {
                stringify: (date) => {
                    if (this.type === "datetime") return date ? date.toLocaleString("sv-SE").slice(0, -3) : "";
                    else return date ? date.toLocaleString("sv-SE").split(" ")[0] : "";
                },

                /**
                 * Custom parse, takes any numericalDate or naturalDate (see regexp above) and transforms them into a date if possible.
                 */
                parse: (value) => {
                    return this.customParse(value);
                },
            },
        };
    },
    components: {
        DatePicker,
    },
    methods: {
        /**
         * focusInterceptor är lite extra magisk.
         *
         * Problemet: live-validering kräver att ett fält har haft fokus minst en gång för att trigga felmeddelande. Vid formulärsvalidering
         * i samband med submit får därför alla fält fokus en gång. Problemet med Datewidget är att datumpopupen inte stängs vid @blur utan fortsätter
         * vara öppen.
         *
         * focusInterceptor kollar om det är användarinput som gett fältet fokus (via event.sourceCapabilities som isf inte är ""). Är det
         * ett programmatiskt focus-event stängs popupen, och ett blur-event triggas som om fältet haft fokus.
         *
         * För att detta ska fungera krävs "manuell" öppning och stängning av datumpopupen via :open, @open och @close.
         */
        focusInterceptor(event) {
            if (!event.sourceCapabilities) {
                this.popupOpen = false;
                this.$emit("blur");
            }
        },
        disabledDate(date) {
            let now = new Date();
            let before = false;
            let after = false;
            if (this.notBefore === "today") {
                before = date.toLocaleString("sv-SE").slice(0, 10) < now.toLocaleString("sv-SE").slice(0, 10);
            } else if (this.notBefore) {
                before = date.toLocaleString("sv-SE") < new Date(this.notBefore).toLocaleString("sv-SE");
            }
            if (this.notAfter === "year") {
                now.setFullYear(now.getFullYear() + 1);
                after = date.toLocaleString("sv-SE").slice(0, 10) > now.toLocaleString("sv-SE").slice(0, 10);
            } else if (this.notAfter === "today") {
                after = date.toLocaleString("sv-SE").slice(0, 10) > now.toLocaleString("sv-SE").slice(0, 10);
            } else if (this.notAfter) {
                after = date.toLocaleString("sv-SE") > new Date(this.notAfter).toLocaleString("sv-SE");
            }
            return before || after;
        },
        valueChanged(payload) {
            this.$emit("input", this.customFormat.stringify(payload));
        },
        customParse(value) {
            let dateArray = [];

            if (this.type === "datetime") {
                // If format matches the above regexp, it's a date. DatePicker uses autocorrection, Date.parse() does not, at least not in Chrome.
                if (value.match(numericDateTime)) {
                    let rawDate = value.replace(/\D/g, "");
                    dateArray[0] = parseInt(rawDate.slice(0, -8));
                    if (dateArray[0] < 100) dateArray[0] += 2000; //Convert 19 to 2019
                    dateArray[1] = parseInt(rawDate.slice(-8, -6)) - 1;
                    dateArray[2] = parseInt(rawDate.slice(-6, -4));
                    dateArray[3] = parseInt(rawDate.slice(-4, -2));
                    dateArray[4] = parseInt(rawDate.slice(-2));
                } else return NaN;
                return new Date(dateArray[0], dateArray[1], dateArray[2], dateArray[3], dateArray[4]);
            }

            // If format matches the above regexp, it's a date. DatePicker uses autocorrection, Date.parse() does not, at least not in Chrome.
            if (value.match(numericDate)) {
                let rawDate = value.replace(/\D/g, "");
                dateArray[0] = parseInt(rawDate.slice(0, -4));
                if (dateArray[0] < 100) dateArray[0] += 2000; //Convert 19 to 2019
                dateArray[1] = parseInt(rawDate.slice(-4, -2)) - 1;
                dateArray[2] = parseInt(rawDate.slice(-2));
            }
            // If it matches the format for natural date, replace any month whose first 3 letters does not match in Swedish and English with the English name, and parse normally
            else if (value.match(naturalDate)) {
                let reversedDateArray = value.split(" ");
                dateArray[0] = parseInt(reversedDateArray[2]);
                dateArray[1] = reversedDateArray[1];
                dateArray[2] = parseInt(reversedDateArray[0]);
                let month = dateArray[1].slice(0, 3).toLowerCase();
                switch (month) {
                    case "jan":
                        dateArray[1] = 0;
                        break;
                    case "feb":
                        dateArray[1] = 1;
                        break;
                    case "mar":
                        dateArray[1] = 2;
                        break;
                    case "apr":
                        dateArray[1] = 3;
                        break;
                    case "may":
                        dateArray[1] = 4;
                        break;
                    case "maj":
                        dateArray[1] = 4;
                        break;
                    case "jun":
                        dateArray[1] = 5;
                        break;
                    case "jul":
                        dateArray[1] = 6;
                        break;
                    case "aug":
                        dateArray[1] = 7;
                        break;
                    case "sep":
                        dateArray[1] = 8;
                        break;
                    case "okt":
                        dateArray[1] = 9;
                        break;
                    case "oct":
                        dateArray[1] = 9;
                        break;
                    case "nov":
                        dateArray[1] = 10;
                        break;
                    case "dec":
                        dateArray[1] = 11;
                        break;
                }
            } else return NaN;
            return new Date(dateArray[0], dateArray[1], dateArray[2]);
        },
        showWarning() {
            this.$emit("error", "Felaktigt datum");
            let name = this.name;
            // Can't capture @input of Datepicker, so can't make the error message change reactively upon new input. Using setTimeout instead.
            setTimeout(
                () => {
                    this.$emit("error", "");
                },
                5000,
                name
            );
        },
    },
    watch: {
        value() {
            this.value_ = this.value ? this.customParse(this.value) : "";
            if (isNaN(this.value_)) {
                this.showWarning();
                this.value_ = "";
                this.$emit("input", this.value_);
            }
        },
    },
    mounted() {
        // Set required on input TODO: double-check if this is really necessary. DateWidget 2 surely supports required?
        if (this.required) {
            let el = document.getElementsByName(this.name)[0];
            if (el) el.required = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.mx-datepicker-main {
    font-family: "Roboto Medium";
}

.mx-calendar-header {
    height: 48px;
    line-height: 48px;
}

.mx-calendar {
    width: 311px;
}

.mx-calendar-content {
    height: 100%;
}

.mx-calendar-content .disabled {
    color: #6c8188;
    background-color: #e4e8f0;
    padding-left: 0px;
    padding-right: 0px;
}

.mx-date-row {
    height: 38px;

    td.cell {
        color: #000000;
        font-size: 16px;
        padding-left: 4px;
        padding-right: 4px;

        &.not-current-month {
            padding-left: 0px;
            padding-right: 0px;
        }

        &.today div {
            border: 1px solid #38a7cf;
        }
    }
}

.mx-btn.mx-btn-text.mx-btn-current-year,
.mx-btn.mx-btn-text.mx-btn-current-month {
    color: #000000;
    font-size: 16px;
}

.mx-calendar-content .cell:hover {
    color: #000000;
    background-color: #f7e1e9;
}

.mx-calendar-content .cell.active {
    color: #ffffff;
    background-color: #ca3470;
}

.mx-input {
    height: 46px !important;
}
</style>
