<script setup>
    import RichTextEditor from "@/components/Editor/RichTextEditor.vue";
    import { ref } from "vue";

    const editorComp = ref(null);

    defineProps({
        content: {
            type: [Object, String],
        },
        options: {
            type: Array,
            required: false,
        },
    });

    const emit = defineEmits(["update:content"]);

    function onUpdate(json) {
        emit("update:content", {
            json,
        });
    }

    function insertFrasChar() {
        if (!editorComp.value?.editor?.state?.selection) {
            return;
        }

        editorComp.value?.editor.chain().focus().insertContent("$").run();
    }
</script>

<template>
    <div class="advanced-editor">
        <div class="editor-bar">
            <div class="editor-buttons" v-if="editorComp?.editor">
                <button
                    type="button"
                    @click.prevent="editorComp?.editor?.chain().focus().toggleBold().run()"
                    :class="{ 'is-active': editorComp?.editor?.isActive('bold') }"
                >
                    <img src="@/assets/editor/bold.svg" />
                </button>

                <button
                    type="button"
                    @click.prevent="editorComp?.editor?.chain().focus().toggleItalic().run()"
                    :class="{ 'is-active': editorComp?.editor?.isActive('italic') }"
                >
                    <img src="@/assets/editor/italic.svg" />
                </button>

                <button
                    type="button"
                    @click.prevent="editorComp?.editor?.chain().focus().toggleStrike().run()"
                    :class="{ 'is-active': editorComp?.editor?.isActive('strike') }"
                >
                    <img src="@/assets/editor/strike.svg" />
                </button>
                <button
                    type="button"
                    @click.prevent="editorComp?.editor?.chain().focus().toggleHeading({ level: 1 }).run()"
                    :class="{ 'is-active': editorComp?.editor?.isActive('heading', { level: 1 }) }"
                >
                    <img src="@/assets/editor/heading_one.svg" />
                </button>
                <button
                    type="button"
                    @click.prevent="editorComp?.editor?.chain().focus().toggleHeading({ level: 2 }).run()"
                    :class="{ 'is-active': editorComp?.editor?.isActive('heading', { level: 2 }) }"
                >
                    <img src="@/assets/editor/heading_two.svg" />
                </button>
                <button
                    type="button"
                    @click.prevent="editorComp?.editor?.chain().focus().toggleBulletList().run()"
                    :class="{ 'is-active': editorComp?.editor?.isActive('bulletList') }"
                >
                    <img src="@/assets/editor/bullet_list.svg" />
                </button>
                <button
                    type="button"
                    @click.prevent="editorComp?.editor?.chain().focus().toggleOrderedList().run()"
                    :class="{ 'is-active': editorComp?.editor?.isActive('orderedList') }"
                >
                    <img src="@/assets/editor/ordered_list.svg" />
                </button>

                <button type="button" @click.prevent="editorComp?.editor?.chain().focus().setHorizontalRule().run()">
                    <img src="@/assets/editor/horizontal_rule.svg" />
                </button>
            </div>

            <div class="history-buttons">
                <button @click.prevent="insertFrasChar">
                    <img src="@/assets/editor/frasregister.svg" />
                </button>

                <button
                    type="button"
                    @click.prevent="editorComp?.editor?.chain().focus().undo().run()"
                    :disabled="!editorComp?.editor?.can().undo()"
                >
                    <img src="@/assets/editor/undo.svg" />
                </button>
                <button
                    type="button"
                    @click.prevent="editorComp?.editor?.chain().focus().redo().run()"
                    :disabled="!editorComp?.editor?.can().redo()"
                >
                    <img src="@/assets/editor/redo.svg" />
                </button>
            </div>
        </div>
        <RichTextEditor ref="editorComp" :content="content" @input="onUpdate" />
    </div>
</template>

<style scoped lang="scss">
    @import "@/style/variables";

    $flex-gap: 1.5rem;

    .advanced-editor {
        display: grid;
        grid-template-rows: max-content auto;
        width: $content-width;
        min-height: 30rem;
        background: $color-background-light;
        border: 1px solid $divider-color;
        border-radius: $border-radius;

        .editor-bar {
            background-color: $color-background-tool-menu;
            height: 3.5rem;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            padding: 1rem 1.5rem;
            display: flex;
            justify-content: space-between;
            gap: $flex-gap;

            .editor-buttons, .history-buttons {
                display: flex;
                gap: $flex-gap;

                button {
                    background: transparent;
                    border: none;
                    display: grid;
                    justify-content: center;
                    align-content: center;

                    &:hover {
                        background-color: $color-hover;
                        border-radius: $border-radius;
                    }

                    &.is-active {
                        background-color: $color-hover;
                        border-radius: $border-radius;
                    }

                    &:disabled {
                        opacity: $disabled-opacity;
                    }
                }
            }
        }
    }
</style>
