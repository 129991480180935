<template>
    <div class="expand-flex">
        <div class="expand-flex-main">
            <select-widget
                :preview="preview"
                v-for="(value, index) in values"
                :key="name + '-' + index"
                v-bind="$attrs"
                :apisearch="apisearch"
                :extraParams="extraParams"
                :name="name"
                :required="required"
                :map="map"
                @search.native="removeItem(index)"
                v-model="values[index]"
                @blur="$emit('blur')"
            />
        </div>
        <div class="expand-flex-side" v-if="!preview">
            <div
                class="side-button-wrapper"
                :class="{
                    disabled: this.hasValues() == this.count ? false : true,
                }"
            >
                <button
                    type="button"
                    :disabled="this.hasValues() == this.count ? false : true"
                    class="side-button"
                    v-on:click="openNew()"
                >
                    <div class="image-wrapper">+</div>
                    Lägg till {{ displayName ? displayName : name }}
                </button>
            </div>
            <div
                class="side-button-wrapper"
                v-for="(value, index) in values"
                :key="name + '-img-' + index"
                :style="index === 0 ? 'display: none' : ''"
            >
                <button type="button" @click="removeItem(index)" class="side-button">
                    <img src="@/assets/delete.svg" />Radera fält
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import SelectWidget from "@/components/widgets/inputs/SelectWidget.vue";

export default {
    name: "ExpandableSelect",
    components: {
        SelectWidget,
    },
    props: {
        apisearch: String,
        name: String,
        required: Boolean,
        value: Array,
        map: Function,
        extraParams: Object,
        displayName: String,
        preview: Boolean,
    },
    data: function () {
        return {
            values: this.value && this.value.length ? this.value : [""],
            count: 1,
        };
    },
    methods: {
        hasValues() {
            const newArr = this.values.filter((item) => item != "");
            return newArr.length;
        },
        openNew(value = "") {
            this.values.push(value);
            this.count += 1;
        },
        removeItem(index) {
            document.activeElement.blur();
            if (this.values.length > 1) {
                this.values.splice(index, 1);
            }
            this.count -= 1;
        },
    },
    watch: {
        value() {
            this.values = this.value.length ? this.value : [""];
        },
        values() {
            this.$emit("input", this.values);
        },
    },
};
</script>

<style lang="sass" scoped>
.expand-flex
    display: flex

.expand-flex-main
    flex-grow: 1

    div:not(:last-of-type)
        margin-bottom: 10px

.expand-flex-side
    flex-grow: 0

.side-button-wrapper
    height: 46px
    line-height: 46px
    display: block
    padding-top: 2px
    padding-bottom: 12px
    padding-left: 10px

    &:not(:last-of-type)
        margin-bottom: 10px

    &:first-of-type
        padding-left: 20px

    .side-button
        border: none
        white-space: nowrap
        font-family: Roboto Medium
        color: black
        border-radius: 4px
        background-color: inherit
        height: 38px
        line-height: 32px

        &:hover:not([disabled])
            background-color: #CEE9F3

        .image-wrapper
            display: inline-block
            width: 28px
            height: 28px
            line-height: 28px
            border-radius: 14px
            background-color: #38A7CF
            color: white
            margin-right: 8px

        img
            margin-top: -6px
            margin-right: 8px

.disabled
    opacity: 0.3
    color: grey
</style>
