<script>
    import BaseTab from "@/tabs/mixins/BaseTab.js";
    import HollowButton from "@/components/widgets/HollowButton.vue";
    import PlusCircleBlue from "@/assets/plus_circle_blue.svg";
    import { mapActions, mapGetters } from "vuex";
    import CustomActionTable from "@/components/generic/CustomActionTable.vue";
    import TypoperationForm from "@/tabs/Operation/Admin/TypoperationForm.vue";
    import tabIcon from "@/assets/blank.png";
    import AdminLockBorder from "@/components/AdminLockBorder.vue";

    const noNull = (str) => {
        return str || "";
    };

    const TABLE_COLUMNS = [
        {
            name: "namn",
            title: "Namn",
            template: (item) => {
                return noNull(item.namn);
            },
        },
        {
            name: "huvuddiagnos",
            title: "Huvuddiagnos",
            template: (item) => {
                return `${noNull(item.huvuddiagnos_display.code)} ${noNull(item.huvuddiagnos_display.displayName)}`;
            },
        },
        {
            name: "huvudoperationskod",
            title: "Operationskod",
            template: (item) => {
                return `${noNull(item.huvudoperationskod_display.code)} ${noNull(
                    item.huvudoperationskod_display.displayName
                )}`;
            },
        },
    ];

    const TABLE_ACTIONS = [
        {
            name: "edit",
            title: "Redigera",
            img: "edit.svg",
            condition: () => {
                return true;
            },
        },
    ];

    export default {
        extends: BaseTab,
        name: "Typoperationer",
        tabName: "Typoperationer",
        icon: tabIcon,

        components: {
            AdminLockBorder,
            HollowButton,
            CustomActionTable,
        },

        data() {
            return {
                PlusCircleBlue,
                TableColumns: TABLE_COLUMNS,
                TableActions: TABLE_ACTIONS,
            };
        },

        computed: {
            ...mapGetters("operation/typoperationer", ["typoperationer", "count", "next"]),
        },

        methods: {
            ...mapActions("operation/typoperationer", ["load", "loadNext"]),
            async createtypoperation() {
                await this.$store.dispatch("tabs/openTab", { component: TypoperationForm });
            },
            async updatetypoperation(typoperation) {
                await this.$store.dispatch("tabs/closeTab", TypoperationForm);
                await this.$store.dispatch("tabs/openTab", {
                    component: TypoperationForm,
                    parameters: { typoperation: typoperation },
                });
            },
        },
        async created() {
            await this.load();
            this.$on("edit", async (typoperation) => {
                await this.updatetypoperation(typoperation);
            });
        },
    };
</script>

<template>
    <div class="pad">
        <admin-lock-border />
        <h2>
            Typoperationer
            <hollow-button
                class="hollow-button"
                @click="createtypoperation"
                :icon="PlusCircleBlue"
                label="Skapa ny typoperation"
            />
        </h2>
        <custom-action-table
            class="no-top"
            :selectableRows="false"
            :items="typoperationer"
            :columns="TableColumns"
            :actions="TableActions"
        />
        <button class="load-next" v-if="next" :onclick="loadNext">Visa nästa X rader</button>
    </div>
</template>

<style lang="scss" scoped>
    .load-next {
        float: right;
    }
</style>
