<script>
export default {
    name: "CalenderToolTip",
    props: {
        tipBorder: {
            type: String,
            default: "",
        },
        dateBooked: {
            type: String,
        },
        salstid: {
            type: String,
        },
        patient: {
            type: String,
            default: "",
        },
        huvudoperationkod: {
            type: String,
            default: "",
        },
        operatorer: {
            type: String,
            default: "",
        },
        operationsal: {
            type: String,
            default: "",
        },
    },
    methods: {
        showRow(property = "") {
            return property.length > 0 ? true : false;
        },
    },
};
</script>

<template>
    <div class="tool-tip-wrapper">
        <div class="tool-tip-content-border" :style="{ borderTop: `8px solid ${tipBorder}` }" />
        <div class="tool-tip-content">
            <h1>{{ dateBooked }}</h1>
            <p v-if="salstid">Salstid {{ salstid }}</p>
        </div>
        <div class="tool-tip-patient-info">
            <div class="tool-tip-patient-wrapper">
                <div v-if="showRow(patient)">
                    <img src="@/assets/actionIcons/add_patient.svg" alt="Image of person and + icon beside it" />
                    <span>{{ patient }}</span>
                </div>
                <div v-if="showRow(huvudoperationkod)">
                    <img src="@/assets/actionIcons/opstart_i_opjournal.svg" alt="Image of person lying in a bed" />
                    <span>{{ huvudoperationkod }} </span>
                </div>
                <div v-if="showRow(operatorer)">
                    <img src="@/assets/actionIcons/add_operator.svg" alt="Image of an profile" />
                    <span>{{ operatorer }}</span>
                </div>
                <div v-if="showRow(operationsal)">
                    <img src="@/assets/actionIcons/location_pin.svg" alt="Image of location pin" />
                    <span>{{ operationsal }}</span>
                </div>
            </div>
        </div>
        <hr />
        <div class="style-slot">
            <slot></slot>
        </div>

        <div class="style-slot-inline">
            <slot name="inline-elements"></slot>
        </div>
    </div>
</template>

<style lang="scss">
.tool-tip-wrapper {
    width: 523px;
    min-height: 407px;
    background-color: #ffffff;
    border: 1px solid #d3d8da;
    border-radius: 4px;

    .tool-tip-content-border {
        margin-top: 24px;
        margin-bottom: 12px;
        margin-left: 16px;
        width: 65px;
        border-radius: 4px;
    }

    .tool-tip-content {
        margin-left: 16px;

        h1 {
            font-family: Roboto Medium, sans-serif;
            font-size: 18px;
            line-height: 30px;
            color: #55676d;
            margin: 0px;
            padding: 0px;
            margin-bottom: 2px;
            font-weight: 900;
        }
        p {
            font-family: Roboto, sans-serif;
            font-size: 18px;
            line-height: 30px;
            color: #55676d;
            margin: 0px;
            padding: 0px;
        }
    }

    .tool-tip-patient-info {
        .tool-tip-patient-wrapper {
            margin-top: 26px;
            margin-left: 26px;

            img {
                width: 18px;
                height: 18px;
            }
            span {
                font-family: Roboto, sans-serif;
                font-size: 16px;
                color: #55676d;
                margin: 0px;
                padding: 0px;
                margin-left: 8px;
            }
            div {
                margin-bottom: 14px;
                display: flex;
            }
        }
    }

    hr {
        margin-top: 40px;
        border-top: 2px solid #d3d8da;
        margin-left: 15px;
        margin-right: 15px;
    }
    .style-slot {
        margin-left: 16px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .style-slot-inline {
        margin-left: 16px;
        margin-top: 16px;
        margin-bottom: 16px;

        display: flex;
        flex-direction: row;
        column-gap: 16px;

        button {
            img {
                position: relative;
                right: 4px;
                filter: invert(65%) sepia(18%) saturate(1690%) hue-rotate(154deg) brightness(87%) contrast(84%);
            }
        }

        button:last-child {
            border: 1px solid #dba7aa;
            background-color: #dba7aa14;
        }
    }
}
</style>
