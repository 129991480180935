<template>
    <div class="makuleraBox">
        <h2>Orsak till makulering receptrad {{ index + 1 }} av {{ total }}</h2>

        <div class="warningText">
            <img
                class="warningImg"
                style="height: 15px; width: 15px; line-height: 1rem"
                src="@/assets/alert_dark.svg"
            />
            <p class="textWarning">
                &nbsp; Observera att efter att receptraden makulerats finns den inte längre tillgänglig i systemet
            </p>
        </div>

        <div class="form-group k3-form">
            <div class="colspan c4">
                <span class="label">Orsak till makulering</span>
                <select-widget
                    @input="updateCause"
                    v-model="causeCode"
                    autocomplete="off"
                    :options="[
                        { text: '', value: 0 },
                        { text: 'Fel patient', value: 1 },
                        {
                            text: 'Fel läkemedel / vara / styrka / dosering / ändamål',
                            value: 2,
                        },
                        {
                            text: 'Utsatt läkemedel/ Inaktuell medicinering',
                            value: 3,
                        },
                        { text: 'Annan orsak', value: 4 },
                    ]"
                    :name="'causeCode-' + id"
                />
            </div>
            <div class="colspan c2">
                <span>&nbsp;</span>
                <checkbox-widget
                    :name="'consent-' + id"
                    v-model="checked"
                    :value="checked"
                    :label="causeCode >= 2 ? 'Patient informerad *' : 'Patient informerad'"
                    :required="causeCode >= 2"
                    :disabled="causeCode === 0"
                />
            </div>

            <div v-if="causeCode === 4" class="colspan c4 otherBox">
                <label
                    ><span class="label">Annan orsak: {{ causeCode === 4 ? "*" : "" }}</span></label
                >
                <text-area-widget :disabled="causeCode !== 4" :required="causeCode === 4" :name="'cause-' + id" />
            </div>
        </div>
    </div>
</template>
<script>
import widgets from "@/components/widgets/inputs";
import linkEvents from "@/linkEvents";

export default {
    name: "PrescriptionDeleteForm",
    props: {
        id: undefined, // Number, but could be String?
        index: null,
        total: null,
    },
    data: function () {
        return {
            causeCode: 0,
            checked: false,
        };
    },
    components: {
        ...widgets,
    },
    methods: {
        updateCauseCode(causeCode) {
            this.causeCode = causeCode;
        },
        updateCause($event) {
            this.$emit("input", $event !== 0);
            if (this.causeCode === 0) {
                this.checked = false;
            }
        },
    },
    created() {
        linkEvents.$on("causeCode-" + this.id + "_changed", this.updateCauseCode);
    },
};
</script>
<style lang="sass" scoped>
.makuleraBox
    position: relative
    padding: 34px 30px 13px 30px

.expanded-warning-icon
    padding-left: 5.5px
    padding-top: 2px
    color: #FFF
    font-size: 12px
    line-height: 12px
    font-weight: bold
    height: 14px
    width: 14px
    border-radius: 7px
    border: none
    background-color: #354052
    margin-top: 5px
    margin-left: 5px
    margin-right: 10px

.warningText
    display: flex
    flex-direction: row
    line-height: 1rem

.textWarning,
.warningImg
    height: 19px
    margin: 17px 0 27px 0
    width: 734px
    color: #354052
    font-family: Roboto Medium
    font-size: 16px
    font-weight: 500
    letter-spacing: 0
    line-height: 19px

.label
    height: 21px
    width: 141px
    color: #728296
    font-family: Roboto
    font-size: 16px
    font-weight: 500
    letter-spacing: 0
    line-height: 21px

.otherBox
    margin-top: 25px
</style>
