<template>
    <form @submit.prevent="submit">
        <admin-lock-border />
        <h2 v-if="parameters">Redigera ICDKod</h2>
        <h2 v-else>Skapa ny ICDKod</h2>
        <div class="form-group k3-form">
            <input-wrapper
                :component="widgets.TextWidget"
                label="Code"
                name="code"
                v-model="icdkod.code"
                @input="updateFormHasBeenEdited"
            />
            <input-wrapper
                :component="widgets.TextWidget"
                label="displayName"
                name="displayname"
                required="true"
                v-model="icdkod.displayName"
                @input="updateFormHasBeenEdited"
            />
        </div>
        <div class="btn-group form-group">
            <button class="btn btn-primary" role="button" type="submit" :disabled="!formHasBeenEdited">
                Spara och stäng
            </button>
            <button @click="cancel()" class="btn btn-secondary" type="button">Avbryt</button>
        </div>
    </form>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import dialogEvents from "@/dialogEvents";
import tabIcon from "@/assets/receptforskrivning.svg";
import widgets from "@/components/widgets/inputs";
import { cloneDeep } from "lodash";
import { flashMessage } from "@/utils";
import { mapActions } from "vuex";
import AdminLockBorder from "@/components/AdminLockBorder.vue";

export default {
    extends: BaseTab,
    name: "ICDKod",
    tabName: "ICDKoder",
    icon: tabIcon,
    components: {
        AdminLockBorder,
        ...widgets,
        InputWrapper,
    },
    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            widgets: widgets,
            formHasBeenEdited: false,
            icdkod: {
                id: "",
                code: "",
                displayname: "",
            },
        };
    },
    computed: {
        hasUnsavedData() {
            return this.formHasBeenEdited;
        },
    },
    methods: {
        ...mapActions("icdkod", ["save", "update", "load"]),
        updateFormHasBeenEdited() {
            this.formHasBeenEdited = true;
        },
        async submit() {
            let response, msg, status;

            if (this.icdkod.id) {
                response = await this.update({ icdkod: this.icdkod });
                msg = "ICDKoden har uppdaterats";
                status = 200;
            } else {
                response = await this.save({ icdkod: this.icdkod });
                msg = "ICDKoden har sparats";
                status = 201;
            }

            if (!response) return;
            if (response.status === status) {
                await this.$store.dispatch("icdkod/load");
                this.$root.$emit("saveOrEdit");
                await this.$store.dispatch("tabs/closeTab", this.tabId);
                flashMessage(msg);
            }
        },
        cancel() {
            this.closeTab(this.hasUnsavedData);
        },
    },
    async created() {
        if (this.parameters) this.icdkod = cloneDeep(this.parameters);
    },
};
</script>
<style lang="sass" scoped>
@import "../../style/variables"

/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

@import "bootstrap/scss/buttons"
@import "bootstrap/scss/utilities"
/* Bootstrap - End */

@import "../../style/deprecated_main"

.k3-lock-border
    height: 26px
    width: 350px
    background: linear-gradient(134.72deg, #C2E6E6 0%, rgba(194, 230, 228, 0) 100%)
    color: #277692
    font-family: Roboto
    font-size: 13px
    font-weight: 500
    letter-spacing: 0.46px
    line-height: 26px
    margin-bottom: 18px
    margin-left: 10px

    img
        margin: -2px 6px 0px 17px
</style>
