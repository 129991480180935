<template>
    <editor-content class="rich-text-editor" :editor="editor" />
</template>

<script setup>
    import { Editor, EditorContent } from "@tiptap/vue-2";
    import StarterKit from "@tiptap/starter-kit";
    import { onMounted, onUnmounted, ref, toRefs, watch } from "vue";
    import Commands from "@/components/Editor/commands";
    import frasSuggestion from "@/components/Editor/frasSuggestion";
    import FrasNode from "@/components/Editor/frasNode";

    const props = defineProps({
        content: {
            type: [Object, String],
        },
    });

    const { content } = toRefs(props);

    const editor = ref();

    const emit = defineEmits(["input"]);
    
    function onUpdate({ eventType, editor: overrideEditor }) {
        if (eventType === "update") {
            emitUpdate({ editor: editor.value });

            return;
        }

        if (eventType === "create") {
            emitUpdate({ editor: overrideEditor });
        }
    }

    function emitUpdate({ editor }) {
        emit("input", editor.getJSON());
    }

    defineExpose({
        editor,
    });

    watch([content], ([newContent]) => {
        if (newContent.content) {
            editor.value?.commands.setContent(newContent.content, false);
            emitUpdate({ editor: editor.value });
        }
    });

    onMounted(async () => {
        editor.value = new Editor({
            extensions: [
                StarterKit.configure({}),
                FrasNode,
                Commands.configure({
                    frasSuggestion: await frasSuggestion(),
                }),
            ],
            content: content.value,
            onUpdate: () => onUpdate({ eventType: "update" }),
            onCreate: (props) => {
                if (content.value) {
                    onUpdate({ eventType: "create", editor: props.editor });
                }
            },
        });
    });

    onUnmounted(() => {
        editor.value?.destroy();
    });
</script>

<style scoped lang="scss">
    @import "@/style/variables";

    .rich-text-editor {
        ::v-deep(.fras) {
            background-color: $color-readonly-background;

            &.ProseMirror-selectednode {
                background-color: $color-hover;
            }
        }

        ::v-deep(.ProseMirror) {
            height: 100%;
            padding: 3rem;

            &.ProseMirror-focused {
                border: none;
                outline: none;
            }
        }
    }
</style>
