<template>
    <div class="kallelse-send">
        <div class="header">
            <h1>Skapa kallelse</h1>
            <button type="button" @click="openKallelsemallarTab">
                <img src="@/assets/icons/gear_icon.svg" alt="Hantera kallelsemallar" />
                Hantera kallelsemallar
            </button>
        </div>
        <div class="container-select-kallelse">
            <FormulateInput
                label="Kallelsemall *"
                type="vueselect"
                :options="kallelsemallar"
                @option:selected="onKallelsemallOptionSelected"
                @input="onKallelsemallInput"
            />
            <Button
                variant="standard"
                size="secondary"
                type="button"
                @click="isPreviewDisplayed = true"
                :disabled="!selectedKallelsemall"
            >
                Skapa förhandsvisning
            </Button>
        </div>

        <div v-if="isPreviewDisplayed" class="preview-kallelse">
            <h3>Förhandsvisning kallelse</h3>

            <PrintableContent ref="printableContent">
                <template #default>
                    <div>
                        <AdvancedEditor
                            v-if="selectedKallelsemall.body"
                            :content="selectedKallelsemall.body"
                            @update:content="updateContent"
                        />
                        <p v-else>Finns ingen text att visa</p>
                    </div>
                </template>
            </PrintableContent>
            <template>
                <ButtonGroup>
                    <Button type="button" @click="sendKallelse" size="primary" class="print">
                        Skapa och skriv ut kallelse
                    </Button>
                    <Button type="button" size="secondary" variant="contour" @click="closeTab" class="cancel">
                        Avbryt
                    </Button>
                </ButtonGroup>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        tabName: "Skapa kallelse",
    };
</script>

<script setup>
    import { ref, toRefs, onUnmounted, computed } from "vue";
    import isEqual from "lodash/isEqual";
    import Button from "@/components/Button.vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import Kallelsemallar from "@/tabs/Operation/Kallelser/Kallelsemallar.vue";
    import { getConfig, getErrorMessage, klinikenApi } from "@/api";
    import PrintableContent from "@/components/PrintableContent.vue";
    import emitter from "tiny-emitter/instance";
    import AdvancedEditor from "@/components/Editor/AdvancedEditor.vue";
    import { useStore } from "@/store";
    import { flashMessage, openDialog } from "@/utils/index.js";

    const store = useStore();

    const printableContent = ref(null);
    const kallelsemallar = ref([]);
    const isPreviewDisplayed = ref(false);
    const selectedKallelsemall = ref(null);
    const content = ref({});
    const defaultContent = ref({});

    const props = defineProps({
        tabId: Number,
        parameters: Object,
    });

    const { tabId, parameters } = toRefs(props);

    const hasUnsavedData = computed({
        get: () => {
            return !isEqual(defaultContent.value, content.value);
        },
    });

    defineExpose({
        hasUnsavedData,
    });

    // TODO convert to useTabs in v3
    function closeTab({ override }) {
        if (!override && hasUnsavedData.value) {
            emitter.emit("open-dialog", {
                type: "warning",
                description: "Du har osparad data. Vill du stänga utan att spara?",
                buttons: [
                    {
                        title: "Fortsätt redigera",
                        type: "secondary",
                    },
                    {
                        title: "Ja, stäng utan att spara",
                        type: "primary",
                        action: () => {
                            store.dispatch("tabs/closeTab", tabId.value);
                        },
                    },
                ],
            });

            return;
        }

        store.dispatch("tabs/closeTab", tabId.value);
    }

    function onKallelsemallOptionSelected(selectedOption = {}) {
        selectedKallelsemall.value = selectedOption;
    }

    function openKallelsemallarTab() {
        store.dispatch("tabs/openTab", {
            component: Kallelsemallar,
        });
    }

    function onKallelsemallInput(input) {
        if (!input) {
            resetValues();
        }
    }

    function resetValues() {
        selectedKallelsemall.value = null;
        isPreviewDisplayed.value = false;
    }

    async function fetchMallar() {
        try {
            await store.dispatch("tabs/displaySpinner", { id: tabId.value, display: true });

            const response = await klinikenApi.get("/kallelser/mallar/", getConfig());

            if (response.status === 200) {
                kallelsemallar.value =
                    response.data.results?.map((item) => ({
                        value: item.id,
                        label: item.namn,
                        body: item.body,
                        ...item,
                    })) ?? [];

                return;
            }

            throw new Error("Fel vid hämtning av kallelsemallar");
        } catch (error) {
            openDialog(getErrorMessage(error), "error");
        } finally {
            store.dispatch("tabs/displaySpinner", { id: tabId.value, display: false });
        }
    }

    function updateContent({ json }) {
        content.value = json;
    }

    async function sendKallelse() {
        const patient = parameters.value?.receiver?.patient ?? null;
        const formData = {
            patient,
            mall: selectedKallelsemall.value.id,
            body: content.value,
        };

        await store.dispatch("tabs/displaySpinner", { id: tabId.value, display: true });

        try {
            const { id: anmalanId } = parameters.value?.receiver ?? {};

            if (!anmalanId) {
                throw new Error("Ingen anmälan hittad");
            }

            const response = await klinikenApi.post(`/operation/anmalan/${anmalanId}/kallelse/`, formData, getConfig());

            if (response.status === 201) {
                const { id: kallelseId } = response.data;

                try {
                    const pdfResponse = await klinikenApi.get(
                        `/kallelser/${kallelseId}/pdf/`,
                        getConfig({ responseType: "blob" }),
                    );

                    if (pdfResponse.status === 200) {
                        const objectUrl = URL.createObjectURL(pdfResponse.data);
                        window.open(objectUrl, "_blank");

                        emitter.emit("kallelse");
                        flashMessage("Kallelse utskriven");

                        closeTab({ override: true });
                        resetValues();

                        return;
                    }

                    throw new Error("Fel vid skapande av PDF");
                } catch (error) {
                    openDialog(getErrorMessage(error), "error");
                }

                return;
            }

            throw new Error("Fel vid skapande av kallelse");
        } catch (error) {
            openDialog(getErrorMessage(error), "error");
        } finally {
            store.dispatch("tabs/displaySpinner", { id: tabId.value, display: false });
        }
    }

    onUnmounted(() => {
        resetValues();
    });

    (async () => {
        await fetchMallar();
        emitter.on("kallelsemall", fetchMallar);
    })();
</script>

<style lang="scss" scoped>
    @import "@/style/variables";

    .kallelse-send {
        width: $content-width;
        max-width: 100%;

        .header {
            display: flex;
            align-items: center;
            gap: 40px;
            margin-bottom: 26px;

            h1 {
                margin: 0 !important;
            }

            .button-hollow:hover {
                background-color: transparent;
            }

            button {
                background: transparent;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    margin-right: 8px;
                }
            }
        }

        .container-select-kallelse {
            background-color: $color-blue-light;
            padding-top: 16px;
            padding-bottom: 25px;
            padding-left: 21px;
            padding-right: 21px;
            display: flex;
            column-gap: 40px;
            justify-content: center;
            align-items: center;

            button {
                flex-grow: 0;
                margin-top: 18px;
            }
        }

        .container-select-kallelse > * {
            flex-grow: 1;
        }

        .sms-receiver-container {
            margin-top: 10px;
            margin-bottom: 20px;

            div {
                margin-top: 10px;
                margin-bottom: 10px;

                display: flex;
                align-items: center;

                img {
                    margin-right: 8px;
                }
            }
        }

        .preview-kallelse {
            margin-top: 16px;

            div {
                margin: 16px 0px;

                p {
                    white-space: pre-line;
                }
            }

            .button-group {
                margin-top: 34px;
            }
        }

        @media print {
            h1,
            .filter,
            .count,
            .header,
            .container-select-kallelse {
                display: none;
            }

            .button.print,
            .button.cancel {
                display: none;
                visibility: hidden;
            }

            .preview-kallelse {
                width: 100vw;
            }
        }
    }
</style>
