<template>
    <FormulateForm @submit="submit" :errors="errors">
        <FormulateInput type="text" name="username" label="Användarnamn" error-behavior="submit" autofocus />
        <FormulateInput type="password" name="password" label="Lösenord" error-behavior="submit" />
        <ButtonGroup align="center">
            <Button type="submit" role="button" size="secondary">{{ submitButton }}</Button>
            <Button type="button" @click="cancel" size="secondary" variant="contour">Avbryt</Button>
        </ButtonGroup>
    </FormulateForm>
</template>

<script setup>
    import { toRefs } from "vue";
    import ButtonGroup from "@/components/ButtonGroup.vue";
    import Button from "@/components/Button.vue";

    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({}),
        },
        submitButton: {
            type: String,
            default: "Logga in",
        },
    });

    const { errors, submitButton } = toRefs(props);

    const emit = defineEmits(["submit", "cancel"]);

    const submit = async (formData) => {
        emit("submit", formData);
    };

    const cancel = () => {
        emit("cancel");
    };
</script>
