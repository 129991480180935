import { mergeAttributes, Node } from "@tiptap/vue-2";

export default Node.create({
    name: "fras",
    group: "inline",
    content: "text*",
    marks: "_",
    isolating: true,
    inline: true,
    atom: true,
    topNode: true,
    addOptions() {
        return {
            HTMLAttributes: {
                class: "fras",
                "data-type": "fras",
            },
        };
    },
    renderHTML({ HTMLAttributes, node }) {
        return [
            "span",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            node.textContent,
        ];
    },
});