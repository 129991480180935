<template>
    <div class="preanestesibedomningar">
        <kliniken-table
            :columns="COLUMNS"
            :dropdownActions="DROPDOWN_ACTIONS"
            :items="preanestesibedomningar"
            :count="preanestesibedomningarCount"
            :hasDropdown="true"
            @tableRowClicked="tableRowClicked"
            @redigera="redigera"
        />
    </div>
</template>

<script setup>
    import KlinikenTable from "@/components/Table/KlinikenTable.vue";
    import { getConfig, klinikenApi } from "@/api";
    import { onMounted, ref } from "vue";
    import { useStore } from "@/store";
    import Preanestesibedomning from "./Preanestesibedomning.vue";
    import EditIcon from "@/assets/actionIcons/edit.svg";

    const store = useStore();

    const COLUMNS = [
        {
            name: "created_at",
            title: "Datum skapad",
            type: "datetime",
        },
        {
            name: "created_by_display.user_display.name",
            title: "Skapad av",
        },
        {
            name: "godkannandpreanestesibedomning_godkannande",
            title: "Preanestesibedömning",
        },
        {
            name: "giltighetstid",
            title: "Giltig t.om.",
        },
    ];

    const DROPDOWN_ACTIONS = [
        {
            name: "redigera",
            title: "Redigera",
            icon: EditIcon,
            show: () => {
                return true;
            },
        },
    ];

    const preanestesibedomningar = ref([]);
    const preanestesibedomningarCount = ref(0);

    function tableRowClicked(item) {
        store.dispatch("patientData/loadFromBackend", {
            id: item.patient,
            failSilently: false,
        });
    }

    function redigera(item) {
        store.dispatch("tabs/openTab", {
            component: Preanestesibedomning,
            parameters: { preanestesibedomning: item },
        });
    }

    const fetchPreanestesibedomningar = async () => {
        try {
            const response = await klinikenApi.get("operation/preanestesibedomningar/", getConfig());
            preanestesibedomningar.value = response.data.results;
            preanestesibedomningarCount.value = response.data.count;
        } catch (error) {
            console.log(error);
        }
    };

    onMounted(() => {
        fetchPreanestesibedomningar();
    });
</script>

<style lang="scss">
    .preanestesibedomningar {
    }
</style>
