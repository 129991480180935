<template>
    <!-- Added CSS support for empty label -->
    <label class="checkbox-container" @click.stop="">
        <span v-if="labelHtml" v-html="labelHtml" />
        <span v-else>{{ label }}</span>
        <input
            type="checkbox"
            :checked="value_"
            :required="required"
            :name="name"
            @change.prevent="handle($event)"
            :disabled="disabled"
        />
        <!-- v-model="value_" value="true"  -->
        <span :class="{ checkmark: true, disabled: disabled }"></span>
    </label>
</template>
<script>
import linkEvents from "@/linkEvents";

export default {
    props: {
        value: Boolean,
        name: undefined,
        label: String,
        labelHtml: String,
        handler: Function,
        disabled: Boolean,
        required: Boolean,
        q: String,
    },
    data() {
        return {
            value_: this.value,
        };
    },
    methods: {
        handle(event) {
            if (this.handler) this.handler(event);
        },
        updateValue({ value }) {
            if (value === "on") this.value_ = true;
        },
    },
    created() {
        linkEvents.$on("update_" + this.name, this.updateValue);
    },
    watch: {
        value() {
            this.value_ = this.value;
        },
    },
};
</script>
<style lang="sass" scoped>
.checkbox-container
    display: inline-block
    position: relative
    padding-left: 35px /* Distance between text and left side of checkbox */
    margin: 0
    min-width: 0px !important /* Needs to be defined and !important, otherwise overwritten by .product-selection .group > button [role=presentation] with min-width 160px */
    min-height: 21px /* Needs to be set in case label is empty */
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    input
        position: absolute
        opacity: 0
        cursor: pointer
        height: 0
        width: 0

    input:checked ~ .checkmark
        background-color: #CA346F

    input:checked ~ .checkmark:after
        display: block

    .checkmark
        position: absolute
        top: 0
        left: 0
        height: 24px
        width: 24px
        border: 1px solid #728296
        border-radius: 4px
        background-color: #FFFFFF

        &.disabled
            //background-color: #F0F3F8
            //border: 1px solid #CFD5DD
            opacity: 0.4

    &[role="presentation"] .checkmark
        margin-top: 4px

    .checkmark:after
        content: ""
        position: absolute
        display: none
        left: 9px
        top: 5px
        width: 5px
        height: 10px
        border: solid white
        border-width: 0 3px 3px 0
        -webkit-transform: rotate(45deg)
        -ms-transform: rotate(45deg)
        transform: rotate(45deg)
</style>
