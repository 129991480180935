<template>
    <label class="checkbox-container" @click.stop=""
        ><span v-if="labelHtml" v-html="labelHtml" /><span v-else>{{ label }}</span>
        <input
            type="checkbox"
            @change.prevent="handle"
            :checked="checked"
            :name="name"
            :required="required"
            :disabled="disabled"
        />
        <span :class="{ checkmark: true, disabled: disabled }"></span>
    </label>
</template>

<script>
export default {
    name: "SimpleCheckboxWidget",
    props: {
        value: undefined, // String or Boolean
        checked_value: {
            type: String,
            default: "Ja",
        },
        label: String,
        labelHtml: String,
        disabled: Boolean,
        required: Boolean,
        handler: Function,
    },
    data() {
        return {
            checked: false,
        };
    },
    methods: {
        handle(event) {
            this.$emit("input", event.target.checked ? this.checked_value : "");
        },
    },
    watch: {
        value() {
            this.checked = this.value === this.checked_value ? true : false;
        },
    },
};
</script>

<style lang="sass">
.checkbox-container
    display: inline-block
    position: relative
    padding-left: 35px
    /* Distance between text and left side of checkbox */
    margin: 0
    min-width: 0px !important
    /* Needs to be defined and !important, otherwise overwritten by .product-selection .group > button [role=presentation] with min-width 160px */
    min-height: 21px
    /* Needs to be set in case label is empty */
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    mark
        padding: 0px

    input
        position: absolute
        opacity: 0
        cursor: pointer
        height: 0
        width: 0

    input:checked ~ .checkmark
        background-color: #CA346F

    input:checked ~ .checkmark:after
        display: block

    .checkmark
        position: absolute
        top: 0
        left: 0
        height: 24px
        width: 24px
        border: 1px solid #728296
        border-radius: 4px
        background-color: #FFFFFF

        &.disabled
            //background-color: #F0F3F8
            //border: 1px solid #CFD5DD
            opacity: 0.4

    &[role="presentation"] .checkmark
        margin-top: 4px

    .checkmark:after
        content: ""
        position: absolute
        display: none
        left: 9px
        top: 5px
        width: 5px
        height: 10px
        border: solid white
        border-width: 0 3px 3px 0
        -webkit-transform: rotate(45deg)
        -ms-transform: rotate(45deg)
        transform: rotate(45deg)
</style>
